/**
 * Komponent wyświetlający informacje o możliwości pobrania aplikacji ze sklepów
 */
import Container from "../components/Container";
import logo from '../assets/images/defaults/logo.png';
import googleplay from '../assets/images/defaults/google-play-badge.png';
import appstore from '../assets/images/defaults/appstore-badge.png';
import appgallery from '../assets/images/defaults/appgallery-badge.png';
import styles from '../styles/screens/Store.module.css';

const Store = () => {
    /**
     * Funkcja przekierowywuje do wybranego sklepu
     * @param {string} _name nazwa sklepu
     */
    const gotoStore = (_name:string) => {
        if(_name==='google') window.location.href = 'https://play.google.com/store/apps/details?id=pl.mobilems.npexplorer';
        else if(_name==='apple') window.location.href = 'https://apps.apple.com/us/app/odkrywca-park%C3%B3w-narodowych/id6444285637';
        else window.location.href = 'https://appgallery.huawei.com/#/app/C107350201';
    }
//FIXMEE
    return(
        <Container>
            <div className={styles.container}>
                <img className={styles.logo} src={logo} alt="Logo aplikacji"></img>
                <h1>Odkrywca Parków Narodowych</h1>
                <p>Drogi użytkowniku, na urządzenia mobilne przygotowaliśmy oddzielną wersję gry, dostępną w poniższych sklepach.</p>
                <div className={styles.logos}>
                    <img className={styles.store} src={googleplay} alt="Pobranie aplikacji ze sklepu Google Play" onClick={()=>gotoStore('google')}></img>
                    <img className={styles.store} src={appstore} alt="Pobranie aplikacji ze sklepu App Store" onClick={()=>gotoStore('apple')}></img>
                    <img className={styles.store} src={appgallery} alt="Pobranie aplikacji ze sklepu App Gallery" onClick={()=>gotoStore('huawei')}></img>
                </div>
            </div>
        </Container>
    )
}

export default Store;