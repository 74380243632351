/**
 * Dostępne pytania w aplikacji
 */
 const questions = [
    /** 1. Słowiński Park Narodowy */
    {
        idquestion:1,
        title:'Wydmy w Słowińskim Parku Narodowym potrafią...',
        answers:[{
            id: '1',
            label: 'przesuwać się nawet do kilkunastu metrów w ciągu roku',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rozrastać się w promieniu kilkuset metrów rocznie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'znikać na kilka lat i pojawiać się w tym samym miejscu',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zamieniać się w ruchome piaski',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'znikać na noc i pojawiać na dzień',
            value: 'option5',
            color:'#f08733',
            selected:false 
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:2,
        title:'Samica foki szarej podczas karmienia...',
        answers:[{
            id: '1',
            label: 'może utracić prawie połowę masy ciała',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'potrafi nie ruszać się z miejsca nawet przez okres sześciu miesięcy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'zapada w sen który może trwać do ok. 1 miesiąca',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'musi zjadać ok 200 kg ryb w ciągu tygodnia',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'karmi również szczenięta innych samic',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:3,
        title:'Jak nazywa się roślina, która dzięki długim korzeniom potrafi „przeżyć” na wydmach?',
        answers:[{
            id: '1',
            label: 'mikołajek nadmorski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pęcherznica kalinolistna',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'żmijowiec zwyczajny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'ogórecznik lekarski',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'drżączka średnia',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:4,
        title:'Przy jakiej prędkości wiatru rozpoczyna się ruch piasku, co powoduje przemieszczanie się wydm?',
        answers:[{
            id: '1',
            label: '5m/s',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '7m/s',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '12m/s',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '3m/s',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '18m/s',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:5,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/foka_szara.png'),
        alt:'Ilustracja foki szarej',
        answersconfig:[
            {answer:'foka szara',correct:true},
            {answer:'foka',correct:true},
            {answer:'szara foka',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Inna nazwa tego ssaka to szarytka morska.'},
            {levelgame:1, hint:'Podpowiedź: Pozostałe gatunki tego ssaka występujące w Morzu Bałtyckim to pospolita i obrączkowana.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 2. Wigierski Park Narodowy */
    {
        idquestion:6,
        title:'W Wigierskim Parku Narodowym widoczne są miejsca, gdzie topiły się bryły  lodu pod koniec okresu zlodowacenia. Te śródleśne jeziora zwane są...',
        answers:[{
            id: '1',
            label: 'sucharami',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'lodowodami',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'prajeziorami',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'jeziolodami',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'parajeziorami',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:7,
        title:'Sieja i sielawa, ryby licznie występujące w Wigierskim Parku Narodowym,  odznaczają się dodatkową płetwą blisko ogona po stronie grzbietowej. Płetwę tą  nazywamy...',
        answers:[{
            id: '1',
            label: 'tłuszczową',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'karkową',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'przyogonową',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'małą',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'grzbietową',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:8,
        title:'Jak nazywa się roślina przypominającą watę nałożoną na łodyżkę, którą  można spotkać na torfowiskach i podmokłych terenach?',
        answers:[{
            id: '1',
            label: 'wełnianka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wata baziowa cukrowa',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wacianka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'kożuchnica',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'pajęczanka',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:9,
        title:'Jaki gatunek ptaka wykonuje tańce na wodzie podczas godów?',
        answers:[{
            id: '1',
            label: 'perkoz',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'gęś zbożowa',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'zięba',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'czapla',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'nurogęś',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:10,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/bobr.png'),
        alt:'Ilustracja bobra',
        answersconfig:[
            {answer:'bóbr',correct:true},
            {answer:'bóbr europejski',correct:true},
            {answer:'europejski bóbr',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Znane są z budowania tam oraz żeremi na rzekach.'},
            {levelgame:1, hint:'Podpowiedź: To największy gryzoń Eurazji.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 3. Woliński Park Narodowy */
    {
        idquestion:11,
        title:'Bielik należy do rodziny...',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'jastrzębiowatych',correct:true}
        ],
        hints:[
            {levelgame:0, hint:''},
            {levelgame:1, hint:''},
            {levelgame:2, hint:''}
        ], 
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:12,
        title:'Kolor wody w jeziorze Turkusowym spowodowany jest...',
        answers:[{
            id: '1',
            label: 'załamaniem światła',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'zabarwieniem wody',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'zabarwieniem długości fal światła',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żyjącymi w nim algami',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'żyjącymi w nim glonami',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:13,
        title:'W Wolińskim Parku Narodowym można spotkać kuzyna delfina – jest nim...',
        answers:[{
            id: '1',
            label: 'morświn',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'orka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'foka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'waleń',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'mors',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:14,
        title:'Jaki rodzaj unikalnej gleby można spotkać w Wolińskim Parku Narodowym?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'naspa przyklifowa', correct: true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Nazwa gleby związana jest z klifami.'},
            {levelgame:1, hint:'Podpowiedź: Powstaje w wyniku przemiennego osadzania się materiału organicznego i materiału mineralnego, nawiewanego z powierzchni klifów przez silne wiatry w głąb lasu.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:15,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/orzel_bielik.png'),
        alt:'Ilustracja bielika',
        answersconfig:[
            {answer:'bielik',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Często do jego nazewnictwa wykorzystywany jest również przedrostek “orzeł …”.'},
            {levelgame:1, hint:'Nazwa odnosi się do białej barwy piór ogona.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 4. Park Narodowy „Bory Tucholskie” */
    {
        idquestion:16,
        title:'Na terenie Parku Narodowego „Bory Tucholskie” znajduje się unikalne  miejsce w skali kraju – jedna rzeka przepływająca przez wiele jezior. Miejsce to  znane jest jako...',
        answers:[{
            id: '1',
            label: 'Struga Siedmiu Jezior',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Struga Trzech Jezior',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Struga Pięciu Jezior',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Struga Dziewięciu Jezior',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'Struga Jedenastu Jezior',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:17,
        title:'Która z podanych roślin jest trująca?',
        answers:[{
            id: '1',
            label: 'cis (poza osnówką nasionka)',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'chruśniak',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'leszczyna',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'czosnek niedźwiedzi',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'jarząb pospolity',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:18,
        title:'Na terenie Parku Narodowego „Bory Tucholskie” występują jeziora, które  wzięły swoją nazwę od rośliny. Są to...',
        answers:[{
            id: '1',
            label: 'jeziora lobeliowe',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'jeziora mniszkowe',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jeziora rojnikowe',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'jeziora turzycowe',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'jeziora tojadowe',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:19,
        title:'Jak nazywa się jedyny jadowity wąż wystepujący w Polsce?',
        answers:[{
            id: '1',
            label: 'żmija zygzakowata',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'zaskroniec zwyczajny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'padalec zwyczajny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'gniewosz plamisty',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'wąż Eskulapa',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:20,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/gluszec.png'),
        alt:'Ilustracja głuszca',
        answersconfig:[
            {answer:'głuszec',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Ptak ten znajduje się w logo Parku Narodowego “Bory Tucholskie”'},
            {levelgame:1, hint:'Podpowiedź: Jego nazwa wzięła się od ostatniego fragmentu tokowania, w którego to trakcie samiec przestaje słyszeć.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 5. Biebrzański Park Narodowy */
    {
        idquestion:21,
        title:'Ptak, będący symbolem Biebrzańskiego Parku Narodowego, to...',
        answers:[{
            id: '1',
            label: 'batalion',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'perkoz',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'czapla siwa',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żuraw koroniasty',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'rycyk zwyczajny',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:22,
        title:'W Biebrzańskim Parku Narodowym znajduje się największa w Polsce populacja...',
        answers:[{
            id: '1',
            label: 'obuwika',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'maliny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'gorczyki',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'okrzynu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'tojadu',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:23,
        title:'Uszereguj parki według powierzchni jaką zajmują od największego do najmniejszego:',
        question:[
            {
                name:'Biebrzański Park Narodowy',
                order:1
            },
            {
                name:'Tatrzański Park Narodowy',
                order:2
            },
            {
                name:'Wigierski Park Narodowy',
                order:3
            },
            {
                name:'Woliński Park Narodowy (razem z Bałtykiem)',
                order:4
            },
            {
                name:'Ojcowski Park Narodowy',
                order:5
            },
        ],
        type:'sort',
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        answersconfig:[
            {answer:'',correct:true},
        ],
        addPoints:true,
    },
    {
        idquestion:25,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/derkacz.png'),
        alt:'Ilustracja derkacza',
        answersconfig:[
            {answer:'derkacz',correct:true},
            {answer:'derkacz zwyczajny',correct:true},
            {answer:'zwyczajny derkacz',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Odzywa się rytmicznie, podwójnymi sylabami derr-derr.'},
            {levelgame:1, hint:'Podpowiedź: Mickiewicz pisał o nim “pierwszy skrzypek łąki”.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 6. Białowieski Park Narodowy */
    {
        idquestion:26,
        title:'Połącz w pary odgłosy z ich właścicielami',
        question:{
            left:[
                {
                    name:'Żubr',
                    order:1
                },
                {
                    name:'Wilk',
                    order:2
                },
                {
                    name:'Jeleń',
                    order:3
                },
                {
                    name:'Koń',
                    order:4
                },
                {
                    name:'Sowa',
                    order:5
                }
            ],
            right:[
                {
                    name:'chruczenie',
                    order:1
                },
                {
                    name:'wycie',
                    order:2
                },
                {
                    name:'ryk',
                    order:3
                },
                {
                    name:'rżenie',
                    order:4
                },
                {
                    name:'huczenie',
                    order:5
                }
            ]
        },
        type:'pairs',
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        answersconfig:{answer:'',correct:true},
        addPoints:true,
    },
    {
        idquestion:27,
        title:'...dzięcioł trójpalczasty, jak wskazuje jego nazwa, posiada tylko trzy palce. Natomiast inne gatunki dzięciołów posiadają...',
        answers:[{
            id: '1',
            label: 'cztery',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dwa',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'również trzy',
            value: 'option3',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:28,
        title:'Na łąkach w Białowieskim Parku Narodowym można spotkać:',
        answers:[{
            id: '1',
            label: 'storczyki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'bratki',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'żonkile',
            value: 'option3',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:29,
        title:'Spośród mieszkańców Rezerwatu Pokazowego Żubrów, w Puszczy Białowieskiej naturalnie nie występuje:',
        answers:[{
            id: '1',
            label: 'żubroń',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'żubr',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jeleń szlachetny',
            value: 'option3',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:30,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/zubr.png'),
        alt:'Ilustracja żubra',
        answersconfig:[
            {answer:'żubr',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to symbol Białowieskiego Parku Narodowego.'},
            {levelgame:1, hint:'Podpowiedź: Jest to największy ssak lądowy w Europie.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 7. Drawieński Park Narodowy */
    {
        idquestion:31,
        title:'Zwierzęta, które najchętniej bawią się z innymi przedstawicielami swojego  gatunku, to...',
        answers:[{
            id: '1',
            label: 'wydry',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'borsuki',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'łosie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'nietoperze',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'bobry',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:32,
        title:'Który gatunek ptaków buduje swoje gniazda w stromych brzegach rzek?',
        answers:[{
            id: '1',
            label: 'zimorodek',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'zięba',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wilga',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'kos',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'dzięcioł',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:33,
        title:'W Drawieńskim Parku Narodowym występuje najmniejsza europejska ważka,  mająca zaledwie 3 cm długości. Jest to...',
        answers:[{
            id: '1',
            label: 'iglica mała',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'lecicha pospolita',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ważka czteroplama',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żagnica sina',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'trzepla zielona',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:34,
        title:'Które z podanych niżej zwierząt uważano dawniej za rybę?',
        answers:[{
            id: '1',
            label: 'bobra',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wydrę',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kunę',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'borsuka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'łasicę',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:35,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/wydra.png'),
        alt:'Ilustracja wydry',
        answersconfig:[
            {answer:'wydra',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest symbolem Drawieńskiego Parku Narodowego.'},
            {levelgame:1, hint:'Podpowiedź: Gatunek ten znany jest z częstych zabaw.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 8. Narwiański Park Narodowy */
    {
        idquestion:36,
        title:'Narew jest typem rzeki anastomozującej, oznacza to, że nie posiada jednego koryta, ale jej wody płyną jakby warkoczami (wieloma przecinającymi się korytami). Co przypomina z lotu ptaka?',
        answers:[{
            id: '1',
            label: 'labirynt',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wstążkę',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'prostą linię',
            value: 'option3',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/36.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:37,
        title:'Jakie ubarwienie mają kaczki żyjące nad Narwią w okresie godowym:',
        answers:[{
            id: '1',
            label: 'samica szarobrązowa, a samiec jest kolorowy, aby wzbudzić jej zainteresowanie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'samiec jest szarobrązowy, a samica kolorowa, aby wzbudzić jego zainteresowanie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'samiec i samica są jednakowo ubarwione',
            value: 'option3',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:38,
        title:'W sytuacji zagrożenia ptak bąk potrafi ukryć się w...',
        answers:[{
            id: '1',
            label: 'trzcinie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'chabrach',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'storczykach',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'szczawiu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'pokrzywach',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:39,
        title:'Jak nazywa się ptak o czerwonych nogach, którego spotykamy na terenie Narwiańskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'bocian',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'boleń',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'bogatka',
            value: 'option3',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:40,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/blotniak_stawowy.png'),
        alt:'Ilustracja błotniaka stawowego',
        answersconfig:[
            {answer:'błotniak stawowy',correct:true},
            {answer:'stawowy błotniak',correct:true},
            {answer:'błotniak',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to symbol Narwiańskiego Parku Narodowego.'},
            {levelgame:1, hint:'Podpowiedź: Jest to drapieżnik, charakteryzujący się różnicą w ubarwieniu samca i samicy.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 9. Park Narodowy „Ujście Warty” */
    {
        idquestion:41,
        title:'Park Narodowy „Ujście Warty” jest doskonałym miejscem dla ornitologów, ponieważ występuje tu około:',
        answers:[{
            id: '1',
            label: '280 gatunków ptaków',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '160 gatunków ptaków',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '360 gatunków ptaków',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '480 gatunków ptaków',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '60 gatunków ptaków',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:42,
        title:'Ptaki te przylatują do Parku Narodowego „Ujście Warty” z północy. Spędzają tu zimę w dużych stadach, które mogą liczyć nawet 3 000 osobników. O jaki gatunek chodzi?',
        answers:[{
            id: '1',
            label: 'łabędź krzykliwy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'błotniak stawowy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'makolągwa',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'bocian czarny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'żuraw',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:43,
        title:'Liczba nocujących w Parku Narodowym „Ujście Warty” gęsi różnych gatunków (w tym gęsi tundrowej) może sięgać nawet...',
        answers:[{
            id: '1',
            label: '200 000 osobników',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '100 000 osobników',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '50 000 osobników',
            value: 'option3',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:44,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/9_logo.webp'),
        alt:'Logo Parku Narodowego „Ujście Warty”',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:45,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/ges_tundrowa.png'),
        alt:'Ilustracja gęsi tundrowej',
        answersconfig:[
            {answer:'gęś tundrowa',correct:true},
            {answer:'gęś',correct:true},
            {answer:'tundrowa gęś',correct:true},
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to ptak znajdujący się w logo Parku Narodowego “Ujście Warty”.'},
            {levelgame:1, hint:'Podpowiedź: W Parku Narodowym “Ujście Warty” spotkać można je jesienią.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 10. Kampinoski Park Narodowy */
    {
        idquestion:46,
        title:'Kampinoski Park Narodowy częściowo położony jest na...',
        answers:[{
            id: '1',
            label: 'wydmie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pustyni',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'skale',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wzgórzach',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'wyżynie',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:47,
        title:'W Kampinoskim Parku Narodowym znajdują się najdalej na południe wysunięte stanowiska chamedafne północnej. Ta krzewinka to relikt okresu borealnego. Obecnie występuje w miejscach o...',
        answers:[{
            id: '1',
            label: 'cieplejszym klimacie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'chłodniejszym klimacie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'klimacie podzwrotnikowym',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zmiennym klimacie',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'klimacie zwrotnikowym',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:48,
        title:'Największym przedstawicielem rodziny jeleniowatych występujących w Polsce  jest...',
        answers:[{
            id: '1',
            label: 'łoś europejski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'jeleń szlachetny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jeleń wschodni',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'daniel europejski',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'sarna europejska',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:49,
        title:'W ktorym roku, w okolice Kampinoskiego Parku Narodowego poprowadzona została  pierwsza zbiorowa wycieczka Polskiego Towarzystwa Krajoznawczego?',
        answers:[{
            id: '1',
            label: '1907',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '1905',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '1898',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '1917',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '1932',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:50,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/los.png'),
        alt:'Ilustracja łosia',
        answersconfig:[
            {answer:'łoś',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Znajduje się w logo Kampinoskiego Parku Narodowego.'},
            {levelgame:1, hint:'Podpowiedź: Ich okres godowy nazywany jest bukowiskiem.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 11. Wielkopolski Park Narodowy */
    {
        idquestion:51,
        title:'Za ich bezszelestny lot odpowiedzialny jest puch porastający skrzydła? O jakim ptaku mowa?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'sowa',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Mądry jak…'},
            {levelgame:1, hint:'Podpowiedź: Dominującym gatunkiem tego zwierzęcia w Wielkopolskim Parku Narodowym jest puszczyk.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:52,
        title:'Na terenie Wielkopolskiego Parku Narodowego można spotkać liczne  pamiątki po lodowcu. Są to...',
        answers:[{
            id: '1',
            label: 'głazy narzutowe',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'graniaki',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'skały wulkaniczne',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'skały osadowe',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'sandry',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:53,
        title:'Jak nazywa się pająk występujący na terenie Wielkopolskiego Parku  Narodowego, który całe życie spędza pod wodą?',
        answers:[{
            id: '1',
            label: 'pająk topik',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pająk wodnik',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'pająk pływak',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'pająk nurek',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'Pająk wałęsak',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:54,
        title:'Czaszka jakiego ptaka jest specjalnie wzmocniona przed uszkodzeniem?',
        answers:[{
            id: '1',
            label: 'dzięcioła',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'jastrzębia',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'sowy uszatki',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wróbla',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'gawrona',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:55,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/puszczyk.png'),
        alt:'Ilustracja puszczyka',
        answersconfig:[
            {answer:'puszczyk',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to najczęściej spotykany gatunek sowy w Europie.'},
            {levelgame:1, hint:'Podpowiedź: W Wielkopolskim Parku Narodowym znajduje się około 50 par lęgowych tych sów.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 12. Poleski Park Narodowy */
    {
        idquestion:56,
        title:'Donośne okrzyki wydawane przez żurawie to tzw...',
        answers:[{
            id: '1',
            label: 'klangor',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'klekot',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'gruchanie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'czyhitanie',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'ćwierkanie',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:57,
        title:'Jak nazywa się kożuch roślinności torfowiskowej pokrywający powierzchnię niektórych jezior Poleskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'pło',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rojst',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'dambo',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'młaka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'ols',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:58,
        title:'Jak nazywa się roślina mięsożerna występująca w Poleskim Parku Narodowym?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'rosiczka',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Nazwa związana jest z kropelkami płynu na włoskach gruczołowych rośliny, przypominającymi rosę.'},
            {levelgame:1, hint:'Podpowiedź: W Polsce występują trzy gatunki tej rośliny - długolistna, okrągłolistna i pośrednia.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:59,
        title:'Na terenie Poleskiego Parku Narodowego znajduje się kilkaset osobników...',
        answers:[{
            id: '1',
            label: 'żółwi błotnych',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'żółwi morskich',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'żółwi skórzastych',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żółwi lądowych',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'żółwi mułowcowatych',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:60,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/zuraw.png'),
        alt:'Ilustracja żurawia',
        answersconfig:[
            {answer:'żuraw',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to największy krajowy ptak.'},
            {levelgame:1, hint:'Podpowiedź: Ptak ten znany jest z tego, że tworzy pary na całe życie.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!', 
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 13. Świętokrzyski Park Narodowy */
    {
        idquestion:61,
        title:'Jaką nazwą określa się obszary bezleśne znajdujące się na stokach pasm Świętokrzyskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'gołoborza',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'łysowisko',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'pustolas',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wyrwidąby',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'kamienisko',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:62,
        title:'Jelonek rogacz należy do rzędu...',
        answers:[{
            id: '1',
            label: 'chrząszczy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'parzystokopytnych',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'łożyskowych',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'muchówek',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'nieparzystokopytnych',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:63,
        title:'Jelenie zrzucają poroże...',
        answers:[{
            id: '1',
            label: 'raz w roku',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '2 do 3 razy w roku',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'raz na 2 lata',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'raz na 4 do 5 lat',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'raz na 10 lat',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:64,
        title:'Najwyższa jodła rosnąca w Świętokrzyskim Parku Narodowym ma ponad...',
        answers:[{
            id: '1',
            label: '40 metrów',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '20 metrów',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '30 metrów',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '15 metrów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '100 metrów',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:65,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/jelen.png'),
        alt:'Ilustracja jelenia',
        answersconfig:[
            {answer:'jeleń',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to symbol Świętokrzyskiego Parku Narodowego.'},
            {levelgame:1, hint:'Podpowiedź: Ich okres godowy nazywany jest rykowiskiem.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 14. Roztoczański Park Narodowy */
    {
        idquestion:66,
        title:'W Roztoczański Parku Narodowym można spotkać...',
        answers:[{
            id: '1',
            label: 'konika polskiego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'konika roztoczańskiego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'konika lubelskiego',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'konika europejskiego',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'konika wschodniego',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:67,
        title:'Szyszki na jodłach wyrastają w pozycji...',
        answers:[{
            id: '1',
            label: '„stojącej”, skierowane pionowo w górę',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '„wiszącej”, zwisając z gałęzi',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '„leżącej”, poziomo wyrastając z gałęzi',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'na jodle nie rosną szyszki',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'na jodle nie rosną szyszki',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:68,
        title:'Buki owocują...',
        answers:[{
            id: '1',
            label: 'raz na kilka lat',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'raz na kilka miesięcy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'raz w roku',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'raz na kilkanaście lat',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'raz na kilkadziesiąt lat',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:69,
        title:'Sen zimowy popielicy może trwać nawet...',
        answers:[{
            id: '1',
            label: '9 miesięcy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '5 miesięcy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '12 miesięcy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '18 miesięcy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '10 miesięcy',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:70,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/konik_polski.png'),
        alt:'Ilustracja konika polskiego',
        answersconfig:[
            {answer:'konik polski',correct:true},
            {answer:'polski konik',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Charakteryzuje go czarna pręga grzbietowa.'},
            {levelgame:1, hint:'Podpowiedź: Wykazuje duże podobieństwo do tarpana, wymarłego gatunku dzikiego konia.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 15. Karkonoski Park Narodowy */
    {
        idquestion:71,
        title:'Jaki jest jeden z najpospolitszych dużych ssaków w Karkonoszach?',
        answers:[{
            id: '1',
            label: 'jeleń szlachetny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'łoś',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'dzik',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'muflon',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'żubr',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:72,
        title:'Jak zwyczajowo nazywa się Mokrą Przełęcz na terenie Karkonoskiego Parku Narodowego, nad którą kumulują się przeloty ptaków w okresie jesiennym?',
        answers:[{
            id: '1',
            label: 'Ptasie wrota',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Ptasia przełęcz',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Ptasi korytarz',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Ptasi pasaż',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'Ptasia autostrada',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:73,
        title:'Wodospad Kamieńczyka, najwyższy wodospad w polskich Sudetach, który znajduje się na terenie Karkonoskiego Parku Narodowego, mierzy...',
        answers:[{
            id: '1',
            label: '27 metrów',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '35 metrów',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '44 metry',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '56 metrów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '15 metrów',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:74,
        title:'Niepylak apollo z rodziny paziowatych to?',
        answers:[{
            id: '1',
            label: 'motyl',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'grzyb',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'roślina',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'pszczoła',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'owoc',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:75,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/podrozniczek.png'),
        alt:'Ilustracja podróżniczka',
        answersconfig:[
            {answer:'podróżniczek',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jego nazwa ma dużo wspólnego z podróżowaniem.'},
            {levelgame:1, hint:'Podpowiedź: Jeden z trzech występujących w Polsce gatunków słowika.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 16. Park Narodowy Gór Stołowych */
    {
        idquestion:76,
        title:'„Świecący” na zielono mech, który najliczniej można spotkać w szczelinach Szczelińca w Parku Narodowym Gór Stołowych, to...',
        answers:[{
            id: '1',
            label: 'świetlanka długoszowata',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'gajnik lśniący',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'płonnik pospolity',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'mech torfowiec',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'chrobotek alpejski',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:77,
        title:'Niezwykły krajobraz Gór Stołowych został ukształtowany przez...',
        answers:[{
            id: '1',
            label: 'erozję i wietrzenie skał',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'silne wiatry',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'szczególny skład chemiczny skał',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'przejście lądolodu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'czynnik ludzki',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:78,
        title:'W Parku Narodowym Gór Stołowych można spotkać zwierzę z epoki lodowcowej. Jest to...',
        answers:[{
            id: '1',
            label: 'pająk bathyphantes eumenis',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'ważka iglica',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'traszka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'jaszczurka zwinka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'kumak górski',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:79,
        title:'Który z gatunków sów jest największy?',
        answers:[{
            id: '1',
            label: 'puchacz',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'uszatka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'puszczyk',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'płomykówka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'włochatka',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:80,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/puchacz.png'),
        alt:'Ilustracja puchacza',
        answersconfig:[
            {answer:'puchacz',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to największa sowa świata.'},
            {levelgame:1, hint:'Podpowiedź: Jego nazwa wzięła się od wydawanego przez niego dźwięku “pu-hu”.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 17. Ojcowski Park Narodowy */
    {
        idquestion:81,
        title:'W Dolinie Sąspowskiej na terenie Ojcowskiego Parku Narodowego można  spotkać gatunek rośliny, który „pamięta” ocieplenie klimatu sprzed 7000 lat. Roślina  ta nazywa się...',
        answers:[{
            id: '1',
            label: 'ułudka leśna',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'iksja mała',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'paproć górska',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zdrojek karłowaty',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'mniszek lekarski',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:82,
        title:'Która z podanych nazw nie jest nazwą nacieku jaskiniowego?',
        answers:[{
            id: '1',
            label: 'stalakryk',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'stalagmit',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'stalaktyt',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'stalagnat',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'perły jaskiniowe',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:83,
        title:'Jak nazywa się kilkunastometrowa skała przypominająca igłę, którą można spotkać w okolicach Ojcowskiego Parku Narodowego?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'igła deotymy',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Nazwano ją na cześć Jadwigi Łuszczewskiej.'},
            {levelgame:1, hint:'Podpowiedź: Wznosi się pomiędzy Panieńskimi Skałami i Skałami Kawalerskimi.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:84,
        title:'Ile gatunków nietoperzy można spotkać w Ojcowskim PN?',
        answers:[{
            id: '1',
            label: '21',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '25',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '10',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '12',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '17',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:85,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/nietoperz.png'),
        alt:'Ilustracja nietoperza',
        answersconfig:[
            {answer:'nietoperz',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to symbol Ojcowskiego Parku Narodowego.'},
            {levelgame:1, hint:'Podpowiedź: W Polsce istnieją takie gatunki tego zwierzęcia jak gacek, nocek, mroczek czy borowiaczek.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 18. Babiogórski Park Narodowy */
    {
        idquestion:86,
        title:'Wpisz nazwę najwyższego szczytu w Babiogórskim Parku Narodowym.',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'diablak',correct:true},
            {answer:'babia góra',correct:true},
            {answer:'góra babia',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Dawniej zwany był także Diablim Zamkiem.'},
            {levelgame:1, hint:'Podpowiedź: Mierzy 1725 m'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:87,
        title:'Jaka roślina jest symbolem Babiogórskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'okrzyn jeleni',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rogownica alpejska',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'tojad mocny morawski',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wiciokrzew czarny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'wyblin jednolistny',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:88,
        title:'W Babiogórskim Parku Narodowym piętra klimatyczno-roślinne podobne są  do tych, występujących w ...',
        answers:[{
            id: '1',
            label: 'Tatrach',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Himalajach',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'na Kilimandżaro',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'w Pirenejach',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'w Górach Sierra Nevada',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:89,
        title:'Jak nazywa się schronienie, w którym niedźwiedź spędza zimę?',
        answers:[{
            id: '1',
            label: 'gawra',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'nora',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kopiec',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'ostoja',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'żeremie',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:90,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/gluszec.png'),
        alt:'Ilustracja głuszca',
        answersconfig:[
            {answer:'głuszec',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Nazwa tego ptaka powiązana jest ze słowem "głuchy".'},
            {levelgame:1, hint:'Podpowiedź: Jest to największy ptak grzebiący Europy.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 19. Gorczański Park Narodowy */
    {
        idquestion:91,
        title:'Nazwa Gorce pochodzi od słowa „gorzeć”, czyli palić, płonąć. Nazwa jest nawiązaniem do...',
        answers:[{
            id: '1',
            label: 'gospodarki żarowej, w efekcie której powstały liczne polany',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wysokich temperatur występujących na terenie parku',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'rozległego pożaru na terenie parku mającego miejsce na początku XIX w.',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'plemienia żyjącego na tych terenach i czczącego ogień',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'obrzędów na cześć boga ognia',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:93,
        title:'W Gorczańskim Parku Narodowym największą wartość przyrodniczą posiadają rozległe naturalne lasy:',
        answers:[{
            id: '1',
            label: 'jodłowo-bukowe',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'bukowe',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'świerkowe',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'dębowe',
            value: 'option4',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:94,
        title:'W Gorcach znajdują się najliczniejsze stanowiska rzadkiej w naszym kraju rośliny. Rośnie na wilgotnym podłożu, w sąsiedztwie strumieni i górskich potoków, zwykle powyżej 1000 m n.p.m. Ta roślina to...',
        answers:[{
            id: '1',
            label: 'zarzyczka górska',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'okrzyn jeleni ',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'chaber miękkowłosy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'modrzyk górski',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'tojad mocny morawski ',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:95,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/salamandra_plamista.png'),
        alt:'Ilustracja salamandry plamistej',
        answersconfig:[
            {answer:'salamandra plamista',correct:true},
            {answer:'salamandra',correct:true},
            {answer:'plamista salamandra',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Znajduje się on w logo Gorczańskiego Parku Narodowego.'},
            {levelgame:1, hint:'Podpowiedź: Jest jedynym tego rodzaju płazem w Polsce, a do jej rodziny należą równiez traszki.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 20. Magurski Park Narodowy */
    {
        idquestion:96,
        title:'Jak nazywany jest przez Łemków chaber miękkowłosy, którego liście od  spodu są aksamitne w dotyku?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'królicze uszy',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Organ ten pomaga popularnym domowym zwierzętom z rzędu zajęczaków w m.in. chłodzeniu organizmu.'},
            {levelgame:1, hint:'Podpowiedź: Są krótsze niż u zajęcy.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:97,
        title:'Zwykle składa dwa jaja, ale najczęściej tylko jedno pisklę dorasta w gnieździe. Młode konkurują między sobą i zazwyczaj wygrywa silniejsze z nich. Ptak ten to...',
        answers:[{
            id: '1',
            label: 'orlik krzykliwy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'bielik',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'myszołów',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'sokół wędrowny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'rybołów',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:98,
        title:'Orientacyjna powierzchnia terenu zajmowanego przez wilczą rodzinę wynosi  około...',
        answers:[{
            id: '1',
            label: '150-200 km2',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '5-10 km2',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '25-50 km2',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '50-100 km2',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '200-250 km2',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:99,
        title:'Na terenie Magurskiego Parku Narodowego można spotkać wszystkie  gatunki traszek występujące w Polsce. Jest ich łącznie...',
        answers:[{
            id: '1',
            label: '4',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '3',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '5',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '6',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '7',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:100,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/orlik_krzykliwy.png'),
        alt:'Ilustracja orlika krzykliwego',
        answersconfig:[
            {answer:'orlik krzykliwy',correct:true},
            {answer:'orlik',correct:true},
            {answer:'krzykliwy orlik',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to symbol Magurskiego Parku Narodowego.'},
            {levelgame:1, hint:'Podpowiedź: Drugim pod względem liczebności ptakiem drapieżnym w Magurskim Parku Narodowym.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 21. Pieniński Park Narodowy */
    {
        idquestion:101,
        title:'Szczyt będący symbolem Pieniń, na którym rośnie charakterystyczna ponad  500-letnia sosna, to...',
        answers:[{
            id: '1',
            label: 'Okrąglica',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Sokolica',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Łysica',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Mogielnica',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'Skalica',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:102,
        title:'Pieniński Park Narodowy jest...',
        answers:[{
            id: '1',
            label: 'najstarszym parkiem narodowym w Polsce',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'największym parkiem narodowym w Polsce',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'najwyżej położonym parkiem narodowym w Polsce',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'najbardziej zalesionym parkiem narodowym w Polsce',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'najmniejszym parkiem narodowym w Polsce',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:103,
        title:'Zagrożony gatunek niepylak apollo, którego można spotkać w Pieninach, to rodzaj...',
        answers:[{
            id: '1',
            label: 'motyla',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'ćmy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'trzmiela',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'pszczoły',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'bąka',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:104,
        title:'Charakterystyczny biały kolor skał pienińskich pochodzi od rodzaju kamienia znanego jako...',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'wapień',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Skała osadowa zbudowana głównie z węglanu wapnia.'},
            {levelgame:1, hint:'Podpowiedź: Znanymi regionami z jego występowania są również Góry Świętokrzyskie oraz Jura Krakowsko-Częstochowska.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:105,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/pomurnik.png'),
        alt:'Ilustracja pomurnika',
        answersconfig:[
            {answer:'pomurnik',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest to bardzo rzadki ptak i w Polsce znajduje się pod ochroną ścisłą.'},
            {levelgame:1, hint:'Podpowiedź: W Pienińskim Parku Narodowym zamieszkuje wapienne skalne ściany.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 22. Bieszczadzki Park Narodowy */
    {
        idquestion:106,
        title:'We wszystkich pasmach górskich w Polsce górną granicę lasu tworzą lasy  świerkowe. Natomiast w Bieszczadzkim Parku Narodowym są to lasy...',
        answers:[{
            id: '1',
            label: 'bukowe',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'również świerkowe',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'sosnowe',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'olchowe',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'brzozowe',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:107,
        title:'Obszary powyżej górnej granicy lasu w Bieszczadach nazywamy...',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'połoniny',correct:true},
            {answer:'połoninami',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Od nich wzięły się nazwy gór w bieszczadach, np. … Caryńska, … Wetlińska, … Bukowska.'},
            {levelgame:1, hint:'Podpowiedź: Piętro roślinności nieleśnej o charakterze subalpejskim i alpejskim.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:108,
        title:'Zdobienia na futrze rysia nazywamy...',
        answers:[{
            id: '1',
            label: 'cętkami',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'plamkami',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'łatkami',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'prążkami',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'kropkami',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:109,
        title:'W bieszczadzkiej krainie dolin możemy spotkać rośliny, które rosną w  miejscu dawnych ludzkich siedzib. Te kwitnące na żółto rośliny to...',
        answers:[{
            id: '1',
            label: 'smotrawy okazałe',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'modrzyk górski',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ciemiężyca zielona',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'goździk skupiony',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'śnieżyca wiosenna',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:110,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/niedzwiedz.png'),
        alt:'Ilustracja niedźwiedzia brunatnego',
        answersconfig:[
            {answer:'niedźwiedź brunatny',correct:true},
            {answer:'niedźwiedź',correct:true},
            {answer:'brunatny niedźwiedź',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jego gatunek znany jest ze swojego snu zimowego.'},
            {levelgame:1, hint:'Podpowiedź: W Polsce występuje jedynie w Tatrach i Beskidach – głównie w Bieszczadach.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 23. Tatrzański Park Narodowy */
    {
        idquestion:111,
        title:'Jakie zwierzę nazywano dawniej „ognistym jaszczurem”?',
        answers:[{
            id: '1',
            label: 'salamandrę plamistą',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'traszkę grzebieniastą',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'gniewosza plamistego',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żmiję zygzakowatą',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'jaszczurkę zwinkę',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:112,
        title:'Jak na Podhalu nazywa się rogi kozicy...',
        answers:[{
            id: '1',
            label: 'hakami',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'chwytakami',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ciosami',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'kindżałami',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'parostkami',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:113,
        title:'Wpisz nazwę najwyższego szczytu w Tatrzańskim Parku Narodowym.',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'rysy',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Mierzy on 2499 metrów.'},
            {levelgame:1, hint:'Podpowiedź: Ma trzy wierzchołki, a najwyższy z nich znajduje się całkowicie po stronie słowackiej.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:114,
        title:'Podczas snu zimowego świstaki...',
        answers:[{
            id: '1',
            label: 'tracą ponad 60% masy ciała',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'potrafią obniżyć temperaturę ciała do ok. 1-2 stopni Celsjusza',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'śpią tylko ok. 4 miesięcy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'świstaki nie zapadają w sen zimowy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'śpią samotnie',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:115,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/kozica.png'),
        alt:'Ilustracja kozicy',
        answersconfig:[
            {answer:'kozica',correct:true}
        ],
        hints:[
            {levelgame:0, hint:'Podpowiedź: Jest symbolem zarówno polskiego, jaki i słowackiego Tatrzańskiego Parku Narodowego.'},
            {levelgame:1, hint:'Podpowiedź: Jej stada, tak jak stada owiec, nazywa się kierdlem.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    /** QUESTY do wszystkich parków */
    
    /** 1. Słowiński Park Narodowy */
    /** QUEST 1 PRZYRODNIK*/ 
    {
        idquestion:116,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:118, type:'multiselection', correct:true},
            {answer:'option2', idquestion:117, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:117,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:118,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'bielik',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'puchacz',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'uszatka',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'derkacz',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'bocian',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/118.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:119, type:'hint'},
            {answer:'option2', correct:true, idquestion:119, type:'hint'},
            {answer:'option3', correct:true, idquestion:119, type:'hint'},
            {answer:'option4', correct:false, idquestion:120, type:'find'},
            {answer:'option5', correct:false, idquestion:120, type:'find'},
            {answer:'option6', correct:false, idquestion:120, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się!',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:119,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>foka</strong>.',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Mam dla Ciebie jeszcze jedno wyzwanie!',
        answersconfig: {answer: '', correct: true, idquestion:120, type:'find'},
    },
    {
        idquestion:120,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukryła się foka szara... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f1.jpg'),
        alt:'Ilustracja foki szarej, lisa i dzięcioła na tle plaży i morza',
        x1:832,
        y1:508,
        x2:987,
        y2:588,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a10',
        addPoints:true,
    },
    /** QUEST 2 TURYSTA*/
    {
        idquestion:121,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:123, type:'open', correct:true},
            {answer:'option2', idquestion:122, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:122,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:123,
        title:'Oto pierwsza zagadka:<br/><br/><i>Wprawdzie niezgrabnie trochę chodzę,</i><br/><i>lecz bardzo żwawo pływam w wodzie,</i><br/><i>wśród lodów się najlepiej czuję,</i><br/><i>czasem też w cyrku występuję.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'foka', idquestion:124, type:'hint', correct:true},
            {answer:'foka szara', idquestion:124, type:'hint', correct:true},
            {answer:'szara foka', idquestion:124, type:'hint', correct:true},
            {answer:'', idquestion:125, type:'closed', correct:false}
        ],
        goodanswermessage:'Brawo! To poprawna odpowiedź!',
        badanswermessage:'Niestety to nie jest poprawna odpowiedź. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:124,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>tylko „łebski” gracz zna prawidłową odpowiedź</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Mam dla Ciebie jeszcze jedno zadanie!',
        answersconfig: {answer: '', correct: true, idquestion:125, type:'closed'},
    },
    {
        idquestion:125,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/A1.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option1', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 STRAŻNIK*/
    {
        idquestion:126,
        title:'<strong>Spotykasz Strażnika Parku.<strong><br/><br/>Dzień dobry! Przeprowadzam ankietę wśród osób odwiedzających park. Czy zechciałbyś poświęcić chwilę czasu i odpowiedzieć na dwa proste pytania?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        answersconfig: [
            {answer:'option1', idquestion:128, type:'trash', correct:true},
            {answer:'option2', idquestion:127, type:'hint', correct:false},
        ],
        goodanswermessage:'Doskonale, dziękuję bardzo. Oto pierwsza kwestia.',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:127,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:128,
        title:'Część osób odwiedzających nasz park nie potrafi odpowiednio segregować odpadów. Czy wiesz, do których pojemników powinny one trafić?<br/><br/><i class="fontsize">Wyrzuć wszystkie odpadki wskazując i przeciągając je do odpowiedniego kontenera. Nastepnie naciśnij przycisk "SPRAWDŹ/DALEJ"</i>',
        type:'trash',
        question:[
            {
                id:'t1',
                src:require('../assets/images/trash/bio/b2.png'),
                alt:'Odpadek z grupy bio',
                type:'bio'
            },
            {
                id:'t2',
                src:require('../assets/images/trash/paper/p1.png'),
                alt:'Odpadek z grupy papier',
                type:'paper'
            },
            {
                id:'t3',
                src:require('../assets/images/trash/plastic/p2.png'),
                alt:'Odpadek z grupy plastik',
                type:'plastic'
            },
            {
                id:'t4',
                src:require('../assets/images/trash/glass/g1.png'),
                alt:'Odpadek z grupy szkło',
                type:'glass'
            },
            {
                id:'t5',
                src:require('../assets/images/trash/confused/c1.png'),
                alt:'Odpadek z grupy zmieszane',
                type:'confused'
            },
        ],
        time: [30,25,15],
        answersconfig: [
            {answer:'',correct:true, idquestion:130, type:'open'},
            {answer:'',correct:false, idquestion:130,type:'open'},
        ],
        goodanswermessage:'Doskonale! Znasz się na segregacji odpadków.',
        badanswermessage:'Niestety, nie udało Ci się... powinieneś powtórzyć swoją wiedzę o segregacji odpadów. Może kolejna zagadka pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:130,
        title:'A oto drugie pytanie z ankiety. Jak nazywa się największe jezioro na terenie Słowińskiego Parku Narodowego, w którym żyją między innymi słonowodne gatunki ryb?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'łebsko',correct:true},
            {answer:'grodno',correct:true}
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 2. Wigierski Park Narodowy 131 - 145*/
    /** QUEST 1 POSZUKIWACZ*/
    {
        idquestion:131,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:133, type:'puzzle', correct:true},
            {answer:'option2', idquestion:132, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:132,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:133,
        title:'Dziękuję Ci! Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/5.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:134, type:'hint'},
            {answer:'', correct:false, idquestion:135, type:'open'},
        ],
    },
    {
        idquestion:134,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>dzięcioł czarny</strong>.',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale, ale – to jeszcze nie koniec...',
        answersconfig: {answer: '', correct: true, idquestion:135, type:'open'},
    },
    {
        idquestion:135,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/1.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'SIELAWA', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Wigierskiego Parku Narodowego jest Sielawa! Dziękuję Ci za pomoc! Idę zobaczyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 PRACOWNIK*/
    {
        idquestion:136,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:138, type:'memo', correct:true},
            {answer:'option2', idquestion:137, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:137,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:138,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "bobr",
                image: require('../assets/images/animals/bobr.png'),
                alt:'Bóbr europejski'
            },
            {
                type: "wilk",
                image: require('../assets/images/animals/wilk.png'),
                alt:'Wilk szary'
            },
            {
                type: "puszczyk",
                image: require('../assets/images/animals/puszczyk.png'),
                alt:'Puszczyk'
            },
            {
                type: "borsuk",
                image: require('../assets/images/animals/borsuk.png'),
                alt:'Borsuk'
            },
            {
                type: "los",
                image: require('../assets/images/animals/los.png'),
                alt:'Łoś'
            },
            {
                type: "dzieciol", 
                image: require('../assets/images/animals/dzieciol_trojpalczasty.png'),
                alt:'Dzięcioł trójpalczasty'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:139, type:'hint'},
            {answer:'',correct:false, idquestion:140,type:'identifyvoice'},
        ],
    },
    {
        idquestion:139,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>bóbr</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do drugiego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:140, type:'identifyvoice'},
    },
    {
        idquestion:140,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'dzięcioł czarny',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'kos',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'puszczyk',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/dzieciol_czarny.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/dzieciol_czarny.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/dzieciol_czarny.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true}, 
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 3 TURYSTA*/
    {
        idquestion:141,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:143, type:'open', correct:true},
            {answer:'option2', idquestion:142, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:142,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:143,
        title:'Oto pierwsza zagadka:<br/><br/><i>Ma futro i ogon płaski jak płetwa.</i><br/><i>Pływa jak ryba i ma zęby mocne jak siekiery.</i><br/><i>Buduje tamy, a jego dom nazywa się "żeremie".</i><br/><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'bóbr', idquestion:145, type:'closed', correct:true},
            {answer:'bóbr europejski', idquestion:145, type:'closed', correct:true},
            {answer:'europejski bóbr', idquestion:145, type:'closed', correct:true},
            {answer:'', idquestion:145, type:'closed', correct:false}
        ],
        goodanswermessage:'Świetnie, to jest poprawna odpowiedź!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:145,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/B2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option2', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 3. Woliński Park Narodowy 146 - 160*/
    /** QUEST 1 STRAŻNIK*/
    {
        idquestion:146,
        title:'<strong>Spotykasz Strażnika Parku.<strong><br/><br/>Dzień dobry! Przeprowadzam ankietę wśród osób odwiedzających park. Czy zechciałbyś poświęcić chwilę czasu i odpowiedzieć na dwa proste pytania?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        answersconfig: [
            {answer:'option1', idquestion:148, type:'trash', correct:true},
            {answer:'option2', idquestion:147, type:'hint', correct:false},
        ],
        goodanswermessage:'Doskonale, dziękuję bardzo. Oto pierwsza kwestia.',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:147,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:148,
        title:'Część osób odwiedzających nasz park nie potrafi odpowiednio segregować odpadów. Czy wiesz, do których pojemników powinny one trafić?<br/><br/><i class="fontsize">Wyrzuć wszystkie odpadki wskazując i przeciągając je do odpowiedniego kontenera. Nastepnie naciśnij przycisk "SPRAWDŹ/DALEJ"</i>',
        type:'trash',
        question:[
            {
                id:'t1',
                src:require('../assets/images/trash/bio/b2.png'),
                alt:'Odpadek z grupy bio',
                type:'bio'
            },
            {
                id:'t2',
                src:require('../assets/images/trash/paper/p1.png'),
                alt:'Odpadek z grupy papier',
                type:'paper'
            },
            {
                id:'t3',
                src:require('../assets/images/trash/plastic/p1.png'),
                alt:'Odpadek z grupy plastik',
                type:'plastic'
            },
            {
                id:'t4',
                src:require('../assets/images/trash/glass/g2.png'),
                alt:'Odpadek z grupy szkło',
                type:'glass'
            },
            {
                id:'t5',
                src:require('../assets/images/trash/confused/c2.png'),
                alt:'Odpadek z grupy zmieszane',
                type:'confused'
            },
        ],
        time: [30,25,15],
        answersconfig: [
            {answer:'',correct:true, idquestion:149, type:'hint'},
            {answer:'',correct:false, idquestion:150, type:'open'},
        ],
        goodanswermessage:'Doskonale! Znasz się na segregacji odpadków.',
        badanswermessage:'Niestety, nie udało Ci się... powinieneś powtórzyć swoją wiedzę o segregacji odpadów. Przejdźmy do drugiego pytania!',
        addPoints:true,
    },
    {
        idquestion:149,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>bielik</strong>.',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Przejdźmy do drugiego pytania!',
        answersconfig: {answer: '', correct: true, idquestion:150, type:'closed'},
    },
    {
        idquestion:150,
        title:'A oto drugie pytanie z ankiety. Która z zasad nie figuruje w regulaminie Parku Narodowego? ',
        answers:[{
            id: '1',
            label: 'nie biegaj',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'nie śmieć',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'nie hałasuj, nie zakłócaj ciszy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'nie zbieraj minerałów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'nie używaj odbiorników radiowych',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie i powodzenia na szlaku!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 POSZUKIWACZ*/
    {
        idquestion:151,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:153, type:'puzzle', correct:true},
            {answer:'option2', idquestion:152, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:152,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:153,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/11.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:154, type:'hint'},
            {answer:'', correct:false, idquestion:155, type:'open'},
        ],
    },
    {
        idquestion:154,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>żaba</strong>.',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale, ale – to jeszcze nie koniec...',
        answersconfig: {answer: '', correct: true, idquestion:155, type:'open'},
    },
    {
        idquestion:155,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/2.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'BIELIK', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Wolińskiego Parku Narodowego jest Bielik! Dziękuję Ci za pomoc! Idę zobaczyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 TURYSTA*/
    {
        idquestion:156,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:158, type:'open', correct:true},
            {answer:'option2', idquestion:157, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:157,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:158,
        title:'Oto pierwsza zagadka:<br/><br/><i>Zaczęła życie od kijanki,</i><br/><i>A teraz skacze, choć nie ma skakanki.</i><br/><i>Cały czas kumka, ma zielony brzuch,</i><br/><i>Językiem łapie całkiem sporo much.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'żaba', idquestion:160, type:'closed', correct:true},
            {answer:'', idquestion:160, type:'closed', correct:false}
        ],
        goodanswermessage:'Świetnie, to jest poprawna odpowiedź! Ale to jeszcze nie koniec! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy...',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:160,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/C2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option3', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 4. Park Narodowy „Bory Tucholskie” 161 - 175*/
    /** QUEST 1 TURYSTA*/
    {
        idquestion:161,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:163, type:'open', correct:true},
            {answer:'option2', idquestion:162, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:162,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:163,
        title:'Oto pierwsza zagadka:<br/><br/><i>Przybył z ciepłych krajów</i><br/><i>w czerwonych trzewikach.</i><br/><i>Gdy ujrzy go żaba,</i><br/><i>do wody umyka.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'bocian', idquestion:164, type:'hint', correct:true},
            {answer:'bocian biały', idquestion:164, type:'hint', correct:true},
            {answer:'biały bocian', idquestion:164, type:'hint', correct:true},
            {answer:'', idquestion:165, type:'closed', correct:false}
        ],
        goodanswermessage:'Brawo! To poprawna odpowiedź!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy. Może tym razem pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:164,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>jednym z płazów jest rzekotka</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy...',
        answersconfig: {answer: '', correct: true, idquestion:165, type:'closed'},
    },
    {
        idquestion:165,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/D2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option4', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 PRZYRODNIK*/
    {
        idquestion:166,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:168, type:'multiselection', correct:true},
            {answer:'option2', idquestion:167, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:167,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:168,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy płazów. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'traszka',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'ropucha',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'rzekotka',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'kumak',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'żaba',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'grzebiuszka',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/168.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:169, type:'hint'},
            {answer:'option2', correct:true, idquestion:169, type:'hint'},
            {answer:'option3', correct:true, idquestion:169, type:'hint'},
            {answer:'option4', correct:false, idquestion:170, type:'find'},
            {answer:'option5', correct:false, idquestion:170, type:'find'},
            {answer:'option6', correct:false, idquestion:170, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się!',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:169,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>czajka</strong>.',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
        goodanswermessage:'A teraz... czy jesteś gotów na kolejne wyzwanie?',
        answersconfig: {answer: '', correct: true, idquestion:170, type:'find'},
    },
    {
        idquestion:170,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się głuszec... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f4.jpg'),
        alt:'Ilustracja dzięcioła czarnego i głuszca na tle lasu',
        x1:673,
        y1:780,
        x2:851,
        y2:943,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a12',
        addPoints:true,
    },
    /** QUEST 3 PRACOWNIK PARKU*/
    {
        idquestion:171,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:173, type:'memo', correct:true},
            {answer:'option2', idquestion:172, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:172,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:173,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "puchacz",
                image: require('../assets/images/animals/puchacz.png'),
                alt:'Puchacz'
            },
            {
                type: "jelen",
                image: require('../assets/images/animals/jelen.png'),
                alt:'Jeleń'
            },
            {
                type: "puszczyk",
                image: require('../assets/images/animals/puszczyk.png'),
                alt:'Puszczyk'
            },
            {
                type: "dzieciol",
                image: require('../assets/images/animals/dzieciol_czarny.png'),
                alt:'Dzięcioł czarny'
            },
            {
                type: "wydra",
                image: require('../assets/images/animals/wydra.png'),
                alt:'Wydra'
            },
            {
                type: "sosna",
                image: require('../assets/images/animals/sosna_pospolita.png'),
                alt:'Sosna pospolita'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:175, type:'identifyvoice'},
            {answer:'',correct:false, idquestion:175,type:'identifyvoice'},
        ],
    },
    {
        idquestion:175,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'czajka',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'głuszec',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'pomurnik',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'żuraw',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/czajki.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/czajki.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/czajki.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** 5. Biebrzański Park Narodowy 176 - 190*/
    /** QUEST 1 PRZYRODNIK*/ 
    {
        idquestion:176,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:178, type:'multiselection', correct:true},
            {answer:'option2', idquestion:177, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:177,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:178,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'dzięcioł',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'derkacz',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'batalion',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'orzeł',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'bocian',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/178.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:179, type:'hint'},
            {answer:'option2', correct:true, idquestion:179, type:'hint'},
            {answer:'option3', correct:true, idquestion:179, type:'hint'},
            {answer:'option4', correct:false, idquestion:180, type:'find'},
            {answer:'option5', correct:false, idquestion:180, type:'find'},
            {answer:'option6', correct:false, idquestion:180, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się!',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:179,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>myszołów</strong>.',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Mam dla Ciebie jeszcze jedno zadanie!',
        answersconfig: {answer: '', correct: true, idquestion:180, type:'find'},
    },
    {
        idquestion:180,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się derkacz... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f5.jpg'),
        alt:'Ilustracja bataliona i derkacza na tle rzeki',
        x1:867,
        y1:792,
        x2:924,
        y2:856,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a5',
        addPoints:true,
    },
    /** QUEST 2 TURYSTA*/
    {
        idquestion:181,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:183, type:'open', correct:true},
            {answer:'option2', idquestion:182, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:182,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:183,
        title:'Oto pierwsza zagadka:<br/><br/><i>Rybołów ”łowi” ryby,</i><br/><i>trzmielojad ”jada” trzmiele,</i><br/><i>a myszy łowi ...</i><br/>',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'myszołów', idquestion:184, type:'hint', correct:true},
            {answer:'', idquestion:185, type:'closed', correct:false}
        ],
        goodanswermessage:'Świetnie, to jest poprawna odpowiedź!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy. Może tym razem pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:184,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>rowerem jeździmy tylko po wyznaczonych szlakach</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Mam dla Ciebie jeszcze jedno zadanie! Czy umiesz dobrze korzystać z mapy?',
        answersconfig: {answer: '', correct: true, idquestion:185, type:'closed'},
    },
    {
        idquestion:185,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/E2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option5', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 STRAŻNIK*/
    {
        idquestion:186,
        title:'<strong>Spotykasz Strażnika Parku.<strong><br/><br/>Dzień dobry! Przeprowadzam ankietę wśród osób odwiedzających park. Czy zechciałbyś poświęcić chwilę czasu i odpowiedzieć na dwa proste pytania?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        answersconfig: [
            {answer:'option1', idquestion:188, type:'trash', correct:true},
            {answer:'option2', idquestion:187, type:'hint', correct:false},
        ],
        goodanswermessage:'Doskonale, dziękuję bardzo. Oto pierwsza kwestia.',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:187,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:188,
        title:'Część osób odwiedzających nasz park nie potrafi odpowiednio segregować odpadów. Czy wiesz, do których pojemników powinny one trafić?<br/><br/><i class="fontsize">Wyrzuć wszystkie odpadki wskazując i przeciągając je do odpowiedniego kontenera. Nastepnie naciśnij przycisk "SPRAWDŹ/DALEJ"</i>',
        type:'trash',
        question:[
            {
                id:'t1',
                src:require('../assets/images/trash/bio/b1.png'),
                alt:'Odpadek z grupy bio',
                type:'bio'
            },
            {
                id:'t2',
                src:require('../assets/images/trash/paper/p2.png'),
                alt:'Odpadek z grupy papier',
                type:'paper'
            },
            {
                id:'t3',
                src:require('../assets/images/trash/plastic/p1.png'),
                alt:'Odpadek z grupy plastik',
                type:'plastic'
            },
            {
                id:'t4',
                src:require('../assets/images/trash/glass/g2.png'),
                alt:'Odpadek z grupy szkło',
                type:'glass'
            },
            {
                id:'t5',
                src:require('../assets/images/trash/confused/c1.png'),
                alt:'Odpadek z grupy zmieszane',
                type:'confused'
            },
        ],
        time: [30,25,15],
        answersconfig: [
            {answer:'',correct:true, idquestion:190, type:'closed'},
            {answer:'',correct:false, idquestion:190,type:'closed'},
        ],
        goodanswermessage:'Doskonale! Znasz się na segregacji odpadków.',
        badanswermessage:'Niestety, nie udało Ci się... powinieneś powtórzyć swoją wiedzę o segregacji odpadów. Może kolejna zagadka pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:190,
        title:'A oto drugie pytanie z ankiety. Zaznacz proszę właściwą odpowiedź. W Parku Narodowym:',
        answers:[{
            id: '1',
            label: 'można jeździć rowerem po specjalnych, wyznaczonych szlakach',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'można jeździć rowerem w dowolnych miejscach',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'można jeździć wyłącznie rowerami MTB po specjalnych, wyznaczonych  szlakach',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'nie można jeździć rowerem',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'można jeździć rowerem w dowolnych miejscach',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 6. Białowieski Park Narodowy 191 - 205*/
    /** QUEST 1 POSZUKIWACZ*/
    {
        idquestion:191,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Czy zechciałbyś mi pomóc? Szukam skarbu ukrytego na terenie Parku Pałacowego i nie potrafię poradzić sobie z zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:193, type:'puzzle', correct:true},
            {answer:'option2', idquestion:192, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:192,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:193,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/10.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:195, type:'open'},
            {answer:'', correct:false, idquestion:195, type:'open'},
        ],
    },
    {
        idquestion:195,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/6.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'PUSZCZA', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Białowieskiego Parku Narodowego jest Puszcza! Dziękuję Ci za pomoc! Idę zdobyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 PRACOWNIK*/
    {
        idquestion:196,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:198, type:'memo', correct:true},
            {answer:'option2', idquestion:197, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:197,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:198,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "zubr",
                image: require('../assets/images/animals/zubr.png'),
                alt:'Żubr'
            },
            {
                type: "dzieciol",
                image: require('../assets/images/animals/dzieciol_trojpalczasty.png'),
                alt:'Dzięcioł trójpalczasty'
            },
            {
                type: "rys",
                image: require('../assets/images/animals/rys.png'),
                alt:'Ryś'
            },
            {
                type: "jez",
                image: require('../assets/images/animals/jez.png'),
                alt:'Jeż'
            },
            {
                type: "konik",
                image: require('../assets/images/animals/konik_polski.png'),
                alt:'Konik polski'
            },
            {
                type: "zaskroniec",
                image: require('../assets/images/animals/zaskroniec_zwyczajny.png'),
                alt:'Zaskroniec zwyczajny'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:199, type:'hint'},
            {answer:'',correct:false, idquestion:200,type:'identifyvoice'},
        ],
    },
    {
        idquestion:199,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>puszcza</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:200, type:'identifyvoice'},
    },
    {
        idquestion:200,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'zimorodek',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'gęś tundrowa',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'bocian czarny',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'dzięcioł',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'podróżniczek',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/zimorodek.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/zimorodek.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/zimorodek.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 3 TURYSTA*/
    {
        idquestion:201,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:203, type:'open', correct:true},
            {answer:'option2', idquestion:202, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:202,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:203,
        title:'Oto pierwsza zagadka:<br/><br/><i>Ma z pędzelkami uszy</i><br/><i>i uwielbia sobie hasać.</i><br/><i>Ten kot nocą na łowy ruszy,</i><br/><i>po niektórych polskich lasach.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'ryś', idquestion:204, type:'hint', correct:true},
            {answer:'', idquestion:205, type:'closed', correct:false}
        ],
        goodanswermessage:'Świetnie, to jest poprawna odpowiedź!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:204,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>zimorodek</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:205, type:'closed'},
    },
    {
        idquestion:205,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/A2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option1', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 7. Drawieński Park Narodowy 206 - 220*/
    /** QUEST 1 PRACOWNIK*/
    {
        idquestion:206,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:208, type:'memo', correct:true},
            {answer:'option2', idquestion:207, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:207,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:208,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "wydra",
                image: require('../assets/images/animals/wydra.png'),
                alt:'Wydra'
            },
            {
                type: "zimo",
                image: require('../assets/images/animals/zimorodek.png'),
                alt:'Zimorodek'
            },
            {
                type: "borsuk",
                image: require('../assets/images/animals/borsuk.png'),
                alt:'Borsuk'
            },
            {
                type: "gniewosz",
                image: require('../assets/images/animals/gniewosz_plamisty.png'),
                alt:'Gniewosz plamisty'
            },
            {
                type: "bocian",
                image: require('../assets/images/animals/bocian_czarny.png'),
                alt:'Bocian czarny'
            },
            {
                type: "nietoperz",
                image: require('../assets/images/animals/nietoperz.png'),
                alt:'Nietoperz'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:209, type:'hint'},
            {answer:'',correct:false, idquestion:210,type:'identifyvoice'},
        ],
    },
    {
        idquestion:209,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>dzięcioł</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:210, type:'identifyvoice'},
    },
    {
        idquestion:210,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'bielik',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'kos',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'derkacz',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/bielik.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/bielik.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/bielik.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 TURYSTA*/
    {
        idquestion:211,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:213, type:'open', correct:true},
            {answer:'option2', idquestion:212, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:212,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:213,
        title:'Oto pierwsza zagadka:<br/><br/><i>Co to za ptak tak stuka i puka,</i><br/><i>ciągle pod korą szkodnika szuka?</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'dzięcioł', idquestion:214, type:'hint', correct:true},
            {answer:'', idquestion:215, type:'closed', correct:false}
        ],
        goodanswermessage:'Brawo! To poprawna odpowiedź!',
        badanswermessage:'Niestety to nie jest poprawna odpowiedź. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:214,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>zimorodek</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Mam dla Ciebie jeszcze jedno zadanie!',
        answersconfig: {answer: '', correct: true, idquestion:215, type:'closed'},
    },
    {
        idquestion:215,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/B2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option2', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 POSZUKIWACZ*/
    {
        idquestion:216,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:218, type:'puzzle', correct:true},
            {answer:'option2', idquestion:217, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:217,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:218,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/9.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:220, type:'open'},
            {answer:'', correct:false, idquestion:220, type:'open'},
        ],
    },
    {
        idquestion:220,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/7.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'ZIMORODEK', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Drawieńskiego Parku Narodowego jest Zimorodek! Dziękuję Ci za pomoc! Idę zobaczyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 8. Narwiański Park Narodowy 221 - 235*/
    /** QUEST 1 PRACOWNIK poziom podstawowy*/
    {
        idquestion:221,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:223, type:'memo', correct:true},
            {answer:'option2', idquestion:222, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:222,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:223,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "ropucha_zielona",
                image: require('../assets/images/animals/ropucha_zielona.png'),
                alt:'Ropucha zielona'
            },
            {
                type: "blotniak",
                image: require('../assets/images/animals/blotniak_stawowy.png'),
                alt:'Błotniak stawowy'
            },
            {
                type: "derkacz",
                image: require('../assets/images/animals/derkacz.png'),
                alt:'Derkacz'
            },
            {
                type: "lis",
                image: require('../assets/images/animals/lis.png'),
                alt:'Lis'
            },
            {
                type: "wydra",
                image: require('../assets/images/animals/wydra.png'),
                alt:'Wydra'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:224, type:'hint'},
            {answer:'',correct:false, idquestion:225,type:'identifyvoice'},
        ],
    },
    {
        idquestion:224,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>wilk</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:225, type:'identifyvoice'},
    },
    {
        idquestion:225,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'batalion',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'gęś tundrowa',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'błotniak stawowy',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'puszczyk',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/batalion.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/batalion.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/batalion.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 TURYSTA poziom podstawowy*/
    {
        idquestion:226,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:228, type:'open', correct:true},
            {answer:'option2', idquestion:227, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:227,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:228,
        title:'Oto pierwsza zagadka:<br/><br/><i>Jest podobny do psa, ale nim nie jest.</i><br/><i>Choć nim straszą, unika ludzi.</i><br/><i>Ma futro, zęby, a jego rodzina to wataha.</i><br/><br/>Co to takiego?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'wilk', idquestion:229, type:'hint', correct:true},
            {answer:'wilk szary', idquestion:229, type:'hint', correct:true},
            {answer:'szary wilk', idquestion:229, type:'hint', correct:true},
            {answer:'', idquestion:230, type:'closed', correct:false}
        ],
        goodanswermessage:'Brawo! To poprawna odpowiedź!',
        badanswermessage:'Niestety to nie jest poprawna odpowiedź. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:229,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>Narew</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Mam dla Ciebie jeszcze jedno zadanie!',
        answersconfig: {answer: '', correct: true, idquestion:230, type:'closed'},
    },
    {
        idquestion:230,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/C2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option3', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 POSZUKIWACZ poziom podstawowy*/
    {
        idquestion:231,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:233, type:'puzzle', correct:true},
            {answer:'option2', idquestion:232, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:232,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:233,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/8.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:235, type:'open'},
            {answer:'', correct:false, idquestion:235, type:'open'},
        ],
    },
    {
        idquestion:235,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/8.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'NAREW', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Narwiańskiego Parku Narodowego jest Narew! Dziękuję Ci za pomoc! Idę zdobyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 1 PRACOWNIK poziom sredniozaawansowany*/
    {
        idquestion:2221,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:2223, type:'memo', correct:true},
            {answer:'option2', idquestion:2222, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:2222,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:2223,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "ropucha_zielona",
                image: require('../assets/images/animals/ropucha_zielona.png'),
                alt:'Ropucha zielona'
            },
            {
                type: "blotniak",
                image: require('../assets/images/animals/blotniak_stawowy.png'),
                alt:'Błotniak stawowy'
            },
            {
                type: "derkacz",
                image: require('../assets/images/animals/derkacz.png'),
                alt:'Derkacz'
            },
            {
                type: "lis",
                image: require('../assets/images/animals/lis.png'),
                alt:'Lis'
            },
            {
                type: "wydra",
                image: require('../assets/images/animals/wydra.png'),
                alt:'Wydra'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:2224, type:'hint'},
            {answer:'',correct:false, idquestion:2225,type:'identifyvoice'},
        ],
    },
    {
        idquestion:2224,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>tatarak</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:2225, type:'identifyvoice'},
    },
    {
        idquestion:2225,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'batalion',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'gęś tundrowa',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'błotniak stawowy',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'puszczyk',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/batalion.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/batalion.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/batalion.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 TURYSTA poziom sredniozaawansowany*/
    {
        idquestion:2226,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:2228, type:'open', correct:true},
            {answer:'option2', idquestion:2227, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:2227,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:2228,
        title:'Oto pierwsza zagadka:<br/><br/><i>Pierwsze – to nie mama.</i><br/><i>Drugie to nie ryba.</i><br/><i>Całość to roślina, już odgadłeś chyba?</i><br/><br/>Co to takiego?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'tatarak', idquestion:2229, type:'hint', correct:true},
            {answer:'', idquestion:2230, type:'closed', correct:false}
        ],
        goodanswermessage:'Brawo! To poprawna odpowiedź!',
        badanswermessage:'Niestety to nie jest poprawna odpowiedź. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:2229,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>mokradła</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Mam dla Ciebie jeszcze jedno zadanie!',
        answersconfig: {answer: '', correct: true, idquestion:2230, type:'closed'},
    },
    {
        idquestion:2230,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/C2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option3', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 POSZUKIWACZ poziom sredniozaawansowany*/
    {
        idquestion:2231,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:2233, type:'puzzle', correct:true},
            {answer:'option2', idquestion:2232, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:2232,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:2233,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/8.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:2235, type:'open'},
            {answer:'', correct:false, idquestion:2235, type:'open'},
        ],
    },
    {
        idquestion:2235,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/2235.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'mokradła', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Narwiańskiego Parku Narodowego jest Narew! Dziękuję Ci za pomoc! Idę zdobyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 1 PRACOWNIK poziom zaawansowany*/
    {
        idquestion:3221,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:3223, type:'memo', correct:true},
            {answer:'option2', idquestion:3222, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:3222,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:3223,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "ropucha_zielona",
                image: require('../assets/images/animals/ropucha_zielona.png'),
                alt:'Ropucha zielona'
            },
            {
                type: "blotniak",
                image: require('../assets/images/animals/blotniak_stawowy.png'),
                alt:'Błotniak stawowy'
            },
            {
                type: "derkacz",
                image: require('../assets/images/animals/derkacz.png'),
                alt:'Derkacz'
            },
            {
                type: "lis",
                image: require('../assets/images/animals/lis.png'),
                alt:'Lis'
            },
            {
                type: "wydra",
                image: require('../assets/images/animals/wydra.png'),
                alt:'Wydra'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:3224, type:'hint'},
            {answer:'',correct:false, idquestion:3225,type:'identifyvoice'},
        ],
    },
    {
        idquestion:3224,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>borsuk</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:3225, type:'identifyvoice'},
    },
    {
        idquestion:3225,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'batalion',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'gęś tundrowa',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'błotniak stawowy',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'puszczyk',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/batalion.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/batalion.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/batalion.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 TURYSTA poziom zaawansowany*/
    {
        idquestion:3226,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:3228, type:'open', correct:true},
            {answer:'option2', idquestion:3227, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:3227,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:3228,
        title:'Oto pierwsza zagadka:<br/><br/><i>Czarną maską kryje oczy, na wyprawy rusza w nocy.</i><br/><i>Za to we dnie siedzi w norze, bo go ktoś rozpoznać może.</i><br/><br/>Co to takiego?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'borsuk', idquestion:3229, type:'hint', correct:true},
            {answer:'', idquestion:3230, type:'closed', correct:false}
        ],
        goodanswermessage:'Brawo! To poprawna odpowiedź!',
        badanswermessage:'Niestety to nie jest poprawna odpowiedź. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:3229,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>rzeka anastomozująca</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Mam dla Ciebie jeszcze jedno zadanie!',
        answersconfig: {answer: '', correct: true, idquestion:3230, type:'closed'},
    },
    {
        idquestion:3230,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/C2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option3', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 POSZUKIWACZ poziom zaawansowany*/
    {
        idquestion:3231,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:3233, type:'puzzle', correct:true},
            {answer:'option2', idquestion:3232, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:3232,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:3233,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/8.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:3235, type:'open'},
            {answer:'', correct:false, idquestion:3235, type:'open'},
        ],
    },
    {
        idquestion:3235,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/3235.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'rzeka anastomozująca', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Narwiańskiego Parku Narodowego jest Narew! Dziękuję Ci za pomoc! Idę zdobyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 9. Park Narodowy „Ujście Warty” 236 - 250 */
    /** QUEST 1 TURYSTA*/
    {
        idquestion:236,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:238, type:'open', correct:true},
            {answer:'option2', idquestion:237, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:237,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:238,
        title:'Oto pierwsza zagadka:<br/><br/><i>Skóra węża jest zielona.</i><br/><i>Czasem szarym podbarwiona.</i><br/><i>Pociągają je moczary.</i><br/><i>Czyli błoto i szuwary.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'zaskroniec', idquestion:239, type:'hint', correct:true},
            {answer:'zaskroniec zwyczajny', idquestion:239, type:'hint', correct:true},
            {answer:'zwyczajny zaskroniec', idquestion:239, type:'hint', correct:true},
            {answer:'', idquestion:240, type:'closed', correct:false}
        ],
        goodanswermessage:'Brawo! To poprawna odpowiedź!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:239,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>Jednym z ptaków jest czapla</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy...',
        answersconfig: {answer: '', correct: true, idquestion:240, type:'closed'},
    },
    {
        idquestion:240,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/D2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option4', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 PRZYRODNIK*/
    {
        idquestion:241,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:243, type:'multiselection', correct:true},
            {answer:'option2', idquestion:242, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:242,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:243,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'czapla',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'derkacz',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'kropiatka',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'drozd',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'kos',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'zięba',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/243.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:244, type:'hint'},
            {answer:'option2', correct:true, idquestion:244, type:'hint'},
            {answer:'option3', correct:true, idquestion:244, type:'hint'},
            {answer:'option4', correct:false, idquestion:245, type:'find'},
            {answer:'option5', correct:false, idquestion:245, type:'find'},
            {answer:'option6', correct:false, idquestion:245, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się!',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:244,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>nazwa tego ptaka brzmi trochę jak nazwa specjalnego ubrania dla konia</strong>.',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
        goodanswermessage:'A teraz... czy jesteś gotów na kolejne wyzwanie?',
        answersconfig: {answer: '', correct: true, idquestion:245, type:'find'},
    },
    {
        idquestion:245,
        title:'Doskonale! Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukryła się gęś tundrowa... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f9_1.jpg'),
        alt:'Ilustracja gęsi tundrowej, derkacza i zaskrońca zwyczajnego na tle mokradeł',
        x1:420,
        y1:570,
        x2:615,
        y2:670,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a11',
        addPoints:true,
    },
    /** QUEST 3 PRACOWNIK PARKU*/
    {
        idquestion:246,
        title:'Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:248, type:'memo', correct:true},
            {answer:'option2', idquestion:247, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:247,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:248,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "ges",
                image: require('../assets/images/animals/ges_tundrowa.png'),
                alt:'Gęś tundrowa'
            },
            {
                type: "derkacz",
                image: require('../assets/images/animals/derkacz.png'),
                alt:'Derkacz'
            },
            {
                type: "zaskroniec_zwyczajny",
                image: require('../assets/images/animals/zaskroniec_zwyczajny.png'),
                alt:'Zaskroniec zwyczajny'
            },
            {
                type: "wydra",
                image: require('../assets/images/animals/wydra.png'),
                alt:'Wydra'
            },
            {
                type: "bobr",
                image: require('../assets/images/animals/bobr.png'),
                alt:'Bóbr'
            },
            {
                type: "bocian",
                image: require('../assets/images/animals/bocian_czarny.png'),
                alt:'Bocian czarny'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:250, type:'identifyvoice'},
            {answer:'',correct:false, idquestion:250,type:'identifyvoice'},
        ],
    },
    {
        idquestion:250,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'błotniak stawowy',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'bielik',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'dzięcioł czarny',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/blotniak_stawowy.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/blotniak_stawowy.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/blotniak_stawowy.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** 10. Kampinoski Park Narodowy 251 - 265*/
    /** QUEST 1 POSZUKIWACZ*/
    {
        idquestion:251,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:253, type:'puzzle', correct:true},
            {answer:'option2', idquestion:252, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:252,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:253,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/7.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:254, type:'hint'},
            {answer:'', correct:false, idquestion:255, type:'open'},
        ],
    },
    {
        idquestion:254,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>bocian czarny</strong>.',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:255, type:'open'},
    },
    {
        idquestion:255,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/10.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'KRZYWA GÓRA', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Kampinoskiego Parku Narodowego jest Krzywa Góra! Dziękuję Ci za pomoc! Idę zdobyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 PRACOWNIK*/
    {
        idquestion:256,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:258, type:'memo', correct:true},
            {answer:'option2', idquestion:257, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:257,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:258,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "los",
                image: require('../assets/images/animals/los.png'),
                alt:'Łoś'
            },
            {
                type: "bocian",
                image: require('../assets/images/animals/bocian_czarny.png'),
                alt:'Bocian czarny'
            },
            {
                type: "nietoperz",
                image: require('../assets/images/animals/nietoperz.png'),
                alt:'Nietoperz'
            },
            {
                type: "bobr",
                image: require('../assets/images/animals/bobr.png'),
                alt:'Bóbr'
            },
            {
                type: "jez",
                image: require('../assets/images/animals/jez.png'),
                alt:'Jeż'
            },
            {
                type: "blotniak",
                image: require('../assets/images/animals/blotniak_stawowy.png'),
                alt:'Błotniak stawowy'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:259, type:'hint'},
            {answer:'',correct:false, idquestion:260,type:'identifyvoice'},
        ],
    },
    {
        idquestion:259,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>dzik</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:260, type:'identifyvoice'},
    },
    {
        idquestion:260,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'bocian czarny',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'gęś tundrowa',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'batalion',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'pomurnik',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/bocian_czarny.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/bocian_czarny.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/bocian_czarny.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 3 TURYSTA*/
    {
        idquestion:261,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:263, type:'open', correct:true},
            {answer:'option2', idquestion:262, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:262,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:263,
        title:'Oto pierwsza zagadka:<br/><br/><i>Ma dzieci w paski, ale nie jest zebrą.</i><br/><i>Ryje w ziemi, ale nie jest ryjówką.</i><br/><i>Mieszka w lesie, a jego przysmakiem są żołędzie.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'dzik', idquestion:265, type:'closed', correct:true},
            {answer:'', idquestion:265, type:'closed', correct:false}
        ],
        goodanswermessage:'Świetnie, to jest poprawna odpowiedź! Ale to jeszcze nie koniec! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy...',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:265,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/E2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option5', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 11. Wielkopolski Park Narodowy 266 - 280*/
    /** QUEST 1 PRACOWNIK*/
    {
        idquestion:266,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:268, type:'memo', correct:true},
            {answer:'option2', idquestion:267, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:267,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:268,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "puszczyk",
                image: require('../assets/images/animals/puszczyk.png'),
                alt:'Puszczyk'
            },
            {
                type: "myszolow",
                image: require('../assets/images/animals/myszolow.png'),
                alt:'Myszołów'
            },
            {
                type: "zaskroniec_zwyczajny",
                image: require('../assets/images/animals/zaskroniec_zwyczajny.png'),
                alt:'Zaskroniec zwyczajny'
            },
            {
                type: "ges",
                image: require('../assets/images/animals/ges_tundrowa.png'),
                alt:'Gęś tundrowa'
            },
            {
                type: "nietoperz",
                image: require('../assets/images/animals/nietoperz.png'),
                alt:'Nietoperz'
            },
            {
                type: "jelen",
                image: require('../assets/images/animals/jelen.png'),
                alt:'Jeleń'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:269, type:'hint'},
            {answer:'',correct:false, idquestion:270,type:'identifyvoice'},
        ],
    },
    {
        idquestion:269,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>jeleń</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:270, type:'identifyvoice'},
    },
    {
        idquestion:270,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'puszczyk',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'derkacz',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'zimorodek',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'sokół wędrowny',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'drozd',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/puszczyk.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/puszczyk.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/puszczyk.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 TURYSTA*/
    {
        idquestion:271,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:273, type:'open', correct:true},
            {answer:'option2', idquestion:272, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:272,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:273,
        title:'Oto pierwsza zagadka:<br/><br/><i>Kopytne zwierzę,</i><br/><i>na głowie ma poroże,</i><br/><i>jest mężem łani,</i><br/><i>w lesie spotkać go możesz.</i><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'jeleń', idquestion:274, type:'hint', correct:true},
            {answer:'', idquestion:275, type:'closed', correct:false}
        ],
        goodanswermessage:'Świetnie, to jest poprawna odpowiedź!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy. Może tym razem pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:274,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>dzięcioł</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Mam dla Ciebie jeszcze jedno zadanie!',
        answersconfig: {answer: '', correct: true, idquestion:275, type:'closed'},
    },
    {
        idquestion:275,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/A2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option1', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 POSZUKIWACZ*/
    {
        idquestion:276,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:278, type:'puzzle', correct:true},
            {answer:'option2', idquestion:277, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:277,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:278,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/1.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc! Ale, ale – to jeszcze nie koniec...',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:280, type:'open'},
            {answer:'', correct:false, idquestion:280, type:'open'},
        ],
    },
    {
        idquestion:280,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/11.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'DZIĘCIOŁ', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Wielkopolskiego Parku Narodowego jest Dzięcioł! Dziękuję Ci za pomoc! Idę zobaczyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 12. Poleski Park Narodowy 281 - 295*/
    /** QUEST 1 POSZUKIWACZ*/
    {
        idquestion:281,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:283, type:'puzzle', correct:true},
            {answer:'option2', idquestion:282, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:282,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:283,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/13.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:284, type:'hint'},
            {answer:'', correct:false, idquestion:285, type:'open'},
        ],
    },
    {
        idquestion:284,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>kos</strong>.',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale, ale – to jeszcze nie koniec...',
        answersconfig: {answer: '', correct: true, idquestion:285, type:'open'},
    },
    {
        idquestion:285,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/12.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'ŻURAW', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Poleskiego Parku Narodowego jest Żuraw! Dziękuję Ci za pomoc! Idę zobaczyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 PRACOWNIK*/
    {
        idquestion:286,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:288, type:'memo', correct:true},
            {answer:'option2', idquestion:287, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:287,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:288,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "zuraw",
                image: require('../assets/images/animals/zuraw.png'),
                alt:'Żuraw'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            },
            {
                type: "kos",
                image: require('../assets/images/animals/kos.png'),
                alt:'Kos'
            },
            {
                type: "zimorodek",
                image: require('../assets/images/animals/zimorodek.png'),
                alt:'Zimorodek'
            },
            {
                type: "los",
                image: require('../assets/images/animals/los.png'),
                alt:'Łoś'
            },
            {
                type: "zmija",
                image: require('../assets/images/animals/zmija_zygzakowata.png'),
                alt:'Żmija zygzakowata'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:289, type:'hint'},
            {answer:'',correct:false, idquestion:290,type:'identifyvoice'},
        ],
    },
    {
        idquestion:289,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>żuraw</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:290, type:'identifyvoice'},
    },
    {
        idquestion:290,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'kos',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'błotniak stawowy',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'derkacz',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'puszczyk',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/kos.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/kos.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/kos.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 3 TURYSTA*/
    {
        idquestion:291,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:293, type:'open', correct:true},
            {answer:'option2', idquestion:292, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:292,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:293,
        title:'Oto pierwsza zagadka:<br/><br/><i>Czarno-szary duży ptak</i><br/><i>wszystkożerny jest jak człowiek,</i><br/><i>a nazywa się on tak</i><br/><i>jak dźwig wielki na budowie.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'żuraw', idquestion:295, type:'closed', correct:true},
            {answer:'', idquestion:295, type:'closed', correct:false}
        ],
        goodanswermessage:'Świetnie, to jest poprawna odpowiedź! Ale to jeszcze nie koniec! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy...',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:295,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/B2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option2', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 13. Świętokrzyski Park Narodowy 296 - 310*/
    /** QUEST 1 STRAŻNIK*/
    {
        idquestion:296,
        title:'<strong>Spotykasz Strażnika Parku.<strong><br/><br/>Dzień dobry! Przeprowadzam ankietę wśród osób odwiedzających park. Czy zechciałbyś poświęcić chwilę czasu i odpowiedzieć na dwa proste pytania?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        answersconfig: [
            {answer:'option1', idquestion:298, type:'trash', correct:true},
            {answer:'option2', idquestion:297, type:'hint', correct:false},
        ],
        goodanswermessage:'Doskonale, dziękuję bardzo. Oto pierwsza kwestia.',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:297,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:298,
        title:'Część osób odwiedzających nasz park nie potrafi odpowiednio segregować odpadów. Czy wiesz, do których pojemników powinny one trafić?<br/><br/><i class="fontsize">Wyrzuć wszystkie odpadki wskazując i przeciągając je do odpowiedniego kontenera. Nastepnie naciśnij przycisk "SPRAWDŹ/DALEJ"</i>',
        type:'trash',
        question:[
            {
                id:'t1',
                src:require('../assets/images/trash/bio/b1.png'),
                alt:'Odpadek z grupy bio',
                type:'bio'
            },
            {
                id:'t2',
                src:require('../assets/images/trash/paper/p2.png'),
                alt:'Odpadek z grupy papier',
                type:'paper'
            },
            {
                id:'t3',
                src:require('../assets/images/trash/plastic/p1.png'),
                alt:'Odpadek z grupy plastik',
                type:'plastic'
            },
            {
                id:'t4',
                src:require('../assets/images/trash/glass/g1.png'),
                alt:'Odpadek z grupy szkło',
                type:'glass'
            },
            {
                id:'t5',
                src:require('../assets/images/trash/confused/c1.png'),
                alt:'Odpadek z grupy zmieszane',
                type:'confused'
            },
        ],
        time: [30,25,15],
        answersconfig: [
            {answer:'',correct:true, idquestion:299, type:'hint'},
            {answer:'',correct:false, idquestion:300,type:'closed'},
        ],
        goodanswermessage:'Doskonale! Znasz się na segregacji odpadków.',
        badanswermessage:'Niestety, nie udało Ci się... powinieneś powtórzyć swoją wiedzę o segregacji odpadów. Może kolejna zagadka pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:299,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>jodła</strong>.',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego pytania.',
        answersconfig: {answer: '', correct: true, idquestion:300, type:'closed'},
    },
    {
        idquestion:300,
        title:'A oto drugie pytanie z ankiety. Najwyższa jodła rosnąca w Świętokrzyskim Parku Narodowym ma ponad...',
        answers:[{
            id: '1',
            label: '50 metrów',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '40 metrów',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '30 metrów',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '20 metrów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '100 metrów',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true}
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 POSZUKIWACZ*/
    {
        idquestion:301,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:303, type:'puzzle', correct:true},
            {answer:'option2', idquestion:302, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:302,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:303,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/12.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:304, type:'hint'},
            {answer:'', correct:false, idquestion:305, type:'open'},
        ],
    },
    {
        idquestion:304,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>do parku nie musisz brać 5 metrów liny</strong>.',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec!',
        answersconfig: {answer: '', correct: true, idquestion:305, type:'open'},
    },
    {
        idquestion:305,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/13.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'JODŁA', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Świętokrzyskiego Parku Narodowego jest Jodła! Dziękuję Ci za pomoc! Idę zobaczyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 RATOWNIK*/
    {
        idquestion:306,
        title:'<strong>Spotykasz ratownika.<strong><br/><br/>Witaj na szlaku! Jako ratownik chciałbym sprawdzić, czy jesteś odpowiednio przygotowany do wędrówki po górskim parku narodowym. Czy zechcesz odpowiedzieć na kilka pytań?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/rat_2.jpg'),
        alt:'Zdjęcie ratownika',
        answersconfig: [
            {answer:'option1', idquestion:308, type:'closed', correct:true},
            {answer:'option2', idquestion:307, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego pytania.',
        badanswermessage:'Ok, rozumiem, że wiesz jak bezpiecznie zachować się w parku. W takim razie powodzenia i uważaj na siebie!',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:307,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/rat_2.jpg'),
        alt:'Zdjęcie ratownika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:308,
        title:'Przed wycieczką do Parku Narodowego bardzo ważne jest, aby... (zaznacz niepoprawną odpowiedź)',
        answers:[{
            id: '1',
            label: 'wziąć ze sobą min. 5 metrów liny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'oszacować swoje siły',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'mieć naładowany telefon',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zabrać tylko najpotrzebniejszy ekwipunek',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'zapisać numery telefonów ratunkowych',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', idquestion:310, type:'closed', correct:true},
            {answer:'option2', idquestion:310, type:'closed', correct:false},
            {answer:'option3', idquestion:310, type:'closed', correct:false},
            {answer:'option4', idquestion:310, type:'closed', correct:false},
            {answer:'option5', idquestion:310, type:'closed', correct:false},
        ],
        goodanswermessage:'Dobrze! Przejdźmy do pytania numer 2.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Ale to nic, może kolejne pytanie będzie łatwiejsze.',
        addPoints:true,
    },
    {
        idquestion:310,
        title:'Zaznacz proszę właściwą odpowiedź. W Parku Narodowym można poruszać się:',
        answers:[{
            id: '1',
            label: 'po wyznaczonych szlakach turystycznych',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dowolnie, o ile nie ma oznakowanego zakazu wstępu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'po nieoznakowanych ścieżkach',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'po wyznaczonych szlakach turystycznych i nieoznakowanych ścieżkach',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'nie można poruszać się po parku narodowym',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true},
            
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Tymczasem spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 14. Roztoczański Park Narodowy 311 - 325*/
    /** QUEST 1 PRACOWNIK*/
    {
        idquestion:311,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:313, type:'memo', correct:true},
            {answer:'option2', idquestion:312, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:312,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:313,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "konik_polski",
                image: require('../assets/images/animals/konik_polski.png'),
                alt:'Konik Polski'
            },
            {
                type: "obuwik_pospolity",
                image: require('../assets/images/animals/obuwik_pospolity.png'),
                alt:'Obuwik Pospolity'
            },
            {
                type: "nietoperz",
                image: require('../assets/images/animals/nietoperz.png'),
                alt:'Nietoperz'
            },
            {
                type: "gniewosz_plamisty",
                image: require('../assets/images/animals/gniewosz_plamisty.png'),
                alt:'Gniewosz plamisty'
            },
            {
                type: "bocian",
                image: require('../assets/images/animals/bocian_czarny.png'),
                alt:'Bocian czarny'
            },
            {
                type: "rys",
                image: require('../assets/images/animals/rys.png'),
                alt:'Ryś'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:314, type:'hint'},
            {answer:'',correct:false, idquestion:315,type:'identifyvoice'},
        ],
    },
    {
        idquestion:314,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>w Parku Narodowym można biwakować wyłącznie w miejscach wyznaczonych przez Dyrektora Parku Narodowego, a w rezerwacie przyrody - przez Regionalnego Dyrektora Ochrony Środowiska</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do drugiego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:315, type:'identifyvoice'},
    },
    {
        idquestion:315,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'żuraw',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'gęś tundrowa',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'bocian czarny',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'derkacz',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/zuraw.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/zuraw.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/zuraw.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 STRAŻNIK*/
    {
        idquestion:316,
        title:'<strong>Spotykasz Strażnika Parku.<strong><br/><br/>Dzień dobry! Przeprowadzam ankietę wśród osób odwiedzających park. Czy zechciałbyś poświęcić chwilę czasu i odpowiedzieć na dwa proste pytania?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        answersconfig: [
            {answer:'option1', idquestion:318, type:'trash', correct:true},
            {answer:'option2', idquestion:317, type:'hint', correct:false},
        ],
        goodanswermessage:'Doskonale, dziękuję bardzo. Oto pierwsza kwestia.',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:317,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:318,
        title:'Część osób odwiedzających nasz park nie potrafi odpowiednio segregować odpadów. Czy wiesz, do których pojemników powinny one trafić?<br/><br/><i class="fontsize">Wyrzuć wszystkie odpadki wskazując i przeciągając je do odpowiedniego kontenera. Nastepnie naciśnij przycisk "SPRAWDŹ/DALEJ"</i>',
        type:'trash',
        question:[
            {
                id:'t1',
                src:require('../assets/images/trash/bio/b1.png'),
                alt:'Odpadek z grupy bio',
                type:'bio'
            },
            {
                id:'t2',
                src:require('../assets/images/trash/paper/p1.png'),
                alt:'Odpadek z grupy papier',
                type:'paper'
            },
            {
                id:'t3',
                src:require('../assets/images/trash/plastic/p1.png'),
                alt:'Odpadek z grupy plastik',
                type:'plastic'
            },
            {
                id:'t4',
                src:require('../assets/images/trash/glass/g1.png'),
                alt:'Odpadek z grupy szkło',
                type:'glass'
            },
            {
                id:'t5',
                src:require('../assets/images/trash/confused/c1.png'),
                alt:'Odpadek z grupy zmieszane',
                type:'confused'
            },
        ],
        time: [30,25,15],
        answersconfig: [
            {answer:'',correct:true, idquestion:319, type:'hint'},
            {answer:'',correct:false, idquestion:320,type:'closed'},
        ],
        goodanswermessage:'Doskonale! Znasz się na segregacji odpadków.',
        badanswermessage:'Niestety, nie udało Ci się... powinieneś powtórzyć swoją wiedzę o segregacji odpadów. Może kolejna zagadka pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:319,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>nazwa jednego z ptaków to bocian</strong>.',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:320, type:'closed'},
    },
    {
        idquestion:320,
        title:'A oto drugie pytanie z ankiety. Zaznacz proszę właściwą odpowiedź. W Parku Narodowym:',
        answers:[{
            id: '1',
            label: 'można biwakować wyłącznie w miejscach wyznaczonych przez Dyrektora Parku Narodowego, a w rezerwacie przyrody - przez Regionalnego Dyrektora Ochrony Środowiska',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'nie można biwakować',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'można biwakować tylko w wyznaczonych miejscach, wyłącznie do zachodu słońca',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'można biwakować na polanach, jeśli nie ma znaku zakazu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'można biwakować tylko w wyznaczonych miejscach, wyłącznie po zachodzie słońca',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true}
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 PRZYRODNIK*/
    {
        idquestion:321,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:323, type:'multiselection', correct:true},
            {answer:'option2', idquestion:322, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:322,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:323,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'bielik',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'orlik',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'bocian',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'drozd',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'głuszec',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'kowalik',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/323.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:325, type:'find'},
            {answer:'option2', correct:true, idquestion:325, type:'find'},
            {answer:'option3', correct:true, idquestion:325, type:'find'},
            {answer:'option4', correct:false, idquestion:325, type:'find'},
            {answer:'option5', correct:false, idquestion:325, type:'find'},
            {answer:'option6', correct:false, idquestion:325, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się! A teraz... czy jesteś gotów na kolejne wyzwanie?',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:325,
        title:'Doskonale! Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się konik polski... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f14.jpg'),
        alt:'Ilustracja konika polskiego na tle drzew i rozciągającej się łąki',
        x1:160,
        y1:540,
        x2:370,
        y2:740,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a16',
        addPoints:true,
    },
    /** 15. Karkonoski Park Narodowy 326 - 340*/
    /** QUEST 1 TURYSTA*/
    {
        idquestion:326,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:328, type:'open', correct:true},
            {answer:'option2', idquestion:327, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:327,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:328,
        title:'Oto pierwsza zagadka:<br/><br/><i>Ma on z pędzelkami uszy</i><br/><i>i uwielbia sobie hasać.</i><br/><i>Dziki kot, co sierść swą puszy</i><br/><i>po niektórych polskich lasach.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'ryś', idquestion:329, type:'hint', correct:true},
            {answer:'', idquestion:330, type:'closed', correct:false}
        ],
        goodanswermessage:'Świetnie, to jest poprawna odpowiedź!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:329,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/>Wskazówka brzmi: <strong>jednym z ptaków jest puchacz</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy...',
        answersconfig: {answer: '', correct: true, idquestion:330, type:'closed'},
    },
    {
        idquestion:330,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/C1.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option3', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 PRZYRODNIK*/
    {
        idquestion:331,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:333, type:'multiselection', correct:true},
            {answer:'option2', idquestion:332, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:332,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:333,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'orzeł',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'puchacz',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'dzięcioł',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'orlik',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'pomurnik',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'zięba',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/333.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:334, type:'hint'},
            {answer:'option2', correct:true, idquestion:334, type:'hint'},
            {answer:'option3', correct:true, idquestion:334, type:'hint'},
            {answer:'option4', correct:false, idquestion:335, type:'find'},
            {answer:'option5', correct:false, idquestion:335, type:'find'},
            {answer:'option6', correct:false, idquestion:335, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się!',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:334,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>podróżniczek</strong>.',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
        goodanswermessage:'A teraz... czy jesteś gotów na kolejne wyzwanie?',
        answersconfig: {answer: '', correct: true, idquestion:335, type:'find'},
    },
    {
        idquestion:335,
        title:'Doskonale! Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się podróżniczek... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f15.jpg'),
        alt:'Ilustracja podróżniczka i zaskrońca zwyczajnego na tle karkonoskiej doliny',
        x1:442,
        y1:480, 
        x2:475,
        y2:543,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a27',
        addPoints:true,
    },
    /** QUEST 3 PRACOWNIK PARKU*/
    {
        idquestion:336,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:338, type:'memo', correct:true},
            {answer:'option2', idquestion:337, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:337,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:338,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "podrozniczek",
                image: require('../assets/images/animals/podrozniczek.png'),
                alt:'Podróżniczek'
            },
            {
                type: "goryczka",
                image: require('../assets/images/animals/goryczka_tojesciowa.png'),
                alt:'Goryczka trojeściowa'
            },
            {
                type: "zaskroniec",
                image: require('../assets/images/animals/zaskroniec_zwyczajny.png'),
                alt:'Zaskroniec zwyczajny'
            },
            {
                type: "dzik",
                image: require('../assets/images/animals/dzik.png'),
                alt:'Dzik'
            },
            {
                type: "lis",
                image: require('../assets/images/animals/lis.png'),
                alt:'Lis'
            },
            {
                type: "borsuk",
                image: require('../assets/images/animals/borsuk.png'),
                alt:'Borsuk'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:339, type:'identifyvoice'},
            {answer:'',correct:false, idquestion:339,type:'identifyvoice'},
        ],
    },
    {
        idquestion:339,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'podróżniczek',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'derkacz',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'bielik',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'pomurnik',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/podrozniczek.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/podrozniczek.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/podrozniczek.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** 16. Park Narodowy Gór Stołowych 341 - 355*/
    /** QUEST 1 RATOWNIK*/
    {
        idquestion:341,
        title:'<strong>Spotykasz ratownika GOPR.<strong><br/><br/>Witaj na szlaku! Jako ratownik GOPR chciałbym sprawdzić, czy jesteś odpowiednio przygotowany do wędrówki po górskim parku narodowym. Czy zechcesz odpowiedzieć na kilka pytań?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        answersconfig: [
            {answer:'option1', idquestion:343, type:'closed', correct:true},
            {answer:'option2', idquestion:342, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego pytania.',
        badanswermessage:'Ok, rozumiem, że wiesz jak bezpiecznie zachować się w parku. W takim razie powodzenia i uważaj na siebie!',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:342,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:343,
        title:'Niezwykły krajobraz Gór Stołowych został ukształtowany przez...',
        answers:[{
            id: '1',
            label: 'morze, kilkadziesiąt milionów lat temu',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'silne wiatry',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'szczególny skład chemiczny skał',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'przejście lądolodu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'czynnik ludzki',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', idquestion:344, type:'hint', correct:true},
            {answer:'option2', idquestion:345, type:'closed', correct:false},
            {answer:'option3', idquestion:345, type:'closed', correct:false},
            {answer:'option4', idquestion:345, type:'closed', correct:false},
            {answer:'option5', idquestion:345, type:'closed', correct:false},
        ],
        goodanswermessage:'Dobrze! Przejdźmy do pytania numer 2.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Ale to nic, może kolejne pytanie będzie łatwiejsze.',
        addPoints:true,
    },
    {
        idquestion:344,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>szczeliniec</strong>.',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        type:'hint',
        addPoints:false,
        goodanswermessage:'A teraz... czy jesteś gotów na kolejne pytanie?',
        answersconfig: {answer: '', correct: true, idquestion:345, type:'closed'},
    },
    {
        idquestion:345,
        title:'Zaznacz proszę właściwą odpowiedź. Który z podanych rodzajów Parków Narodowych nie występuje w Polsce?',
        answers:[{
            id: '1',
            label: 'równinny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'nadmorski',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'pojezierny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'nizinny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'górski',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true},
            
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę parkach narodowych. Tymczasem spróbuj jeszcze raz.',
        addPoints:true,
    },
    /** QUEST 2 POSZUKIWACZ*/
    {
        idquestion:346,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:348, type:'puzzle', correct:true},
            {answer:'option2', idquestion:347, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:347,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:348,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/6.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:349, type:'hint'},
            {answer:'', correct:false, idquestion:350, type:'open'},
        ],
    },
    {
        idquestion:349,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>niedźwiedź</strong>.',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Dowiedzmy się czegoś o skarbie.',
        answersconfig: {answer: '', correct: true, idquestion:350, type:'open'},
    },
    {
        idquestion:350,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/16.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'SZCZELINIEC', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Parku Narodowego Gór Stołowych jest Szczeliniec! Dziękuję Ci za pomoc! Idę zobaczyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 TURYSTA*/
    {
        idquestion:351,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:353, type:'open', correct:true},
            {answer:'option2', idquestion:352, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:352,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:353,
        title:'Oto pierwsza zagadka:<br/><br/><i>Kiedy ciepła wiosna</i><br/><i>stopi śnieg i lód,</i><br/><i>on budzi się ze snu</i><br/><i>i pyta o miód.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'niedźwiedź', idquestion:355, type:'closed', correct:true},
            {answer:'', idquestion:355, type:'closed', correct:false}
        ],
        goodanswermessage:'Brawo! To poprawna odpowiedź!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:355,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/D1.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option4', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 17. Ojcowski Park Narodowy 356 - 370*/
    /** QUEST 1 STRAŻNIK*/
    {
        idquestion:356,
        title:'<strong>Spotykasz Strażnika Parku.<strong><br/><br/>Dzień dobry! Przeprowadzam ankietę wśród osób odwiedzających park. Czy zechciałbyś poświęcić chwilę czasu i odpowiedzieć na dwa proste pytania?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        answersconfig: [
            {answer:'option1', idquestion:358, type:'trash', correct:true},
            {answer:'option2', idquestion:357, type:'hint', correct:false},
        ],
        goodanswermessage:'Doskonale, dziękuję bardzo. Oto pierwsza kwestia.',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:357,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:358,
        title:'Część osób odwiedzających nasz park nie potrafi odpowiednio segregować odpadów. Czy wiesz, do których pojemników powinny one trafić?<br/><br/><i class="fontsize">Wyrzuć wszystkie odpadki wskazując i przeciągając je do odpowiedniego kontenera. Nastepnie naciśnij przycisk "SPRAWDŹ/DALEJ"</i>',
        type:'trash',
        question:[
            {
                id:'t1',
                src:require('../assets/images/trash/bio/b2.png'),
                alt:'Odpadek z grupy bio',
                type:'bio'
            },
            {
                id:'t2',
                src:require('../assets/images/trash/paper/p1.png'),
                alt:'Odpadek z grupy papier',
                type:'paper'
            },
            {
                id:'t3',
                src:require('../assets/images/trash/plastic/p1.png'),
                alt:'Odpadek z grupy plastik',
                type:'plastic'
            },
            {
                id:'t4',
                src:require('../assets/images/trash/glass/g2.png'),
                alt:'Odpadek z grupy szkło',
                type:'glass'
            },
            {
                id:'t5',
                src:require('../assets/images/trash/confused/c2.png'),
                alt:'Odpadek z grupy zmieszane',
                type:'confused'
            },
        ],
        time: [30,25,15],
        answersconfig: [
            {answer:'',correct:true, idquestion:359, type:'hint'},
            {answer:'',correct:false, idquestion:360, type:'closed'},
        ],
        goodanswermessage:'Doskonale! Znasz się na segregacji odpadków.',
        badanswermessage:'Niestety, nie udało Ci się... powinieneś powtórzyć swoją wiedzę o segregacji odpadów. Może kolejna zagadka pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:359,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>jaskinia</strong>.',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct:true, idquestion:360, type:'closed'},
    },
    {
        idquestion:360,
        title:'A oto drugie pytanie z ankiety. Zaznacz proszę właściwą odpowiedź. Nietoperze unikają przeszkód podczas lotu dzięki zdolności...',
        answers:[{
            id: '1',
            label: 'echolokacji',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'teletropizmu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'agorakinezy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'audiorecepcji',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'magnetorecepcji',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true}
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 POSZUKIWACZ*/
    {
        idquestion:361,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:363, type:'puzzle', correct:true},
            {answer:'option2', idquestion:362, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:362,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:363,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/4.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:364, type:'hint'},
            {answer:'', correct:false, idquestion:365, type:'open'},
        ],
    },
    {
        idquestion:364,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>nietoperz</strong>.',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec!',
        answersconfig: {answer: '', correct: true, idquestion:365, type:'open'},
    },
    {
        idquestion:365,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/17.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'jaskinia', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Ojcowskiego Parku Narodowego są jaskinie! Dziękuję Ci za pomoc! Idę zobaczyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 TURYSTA*/
    {
        idquestion:366,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:368, type:'open', correct:true},
            {answer:'option2', idquestion:367, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:367,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:368,
        title:'Oto pierwsza zagadka:<br/><br/><i>Cały dzień śpi, głową w dół celuję.</i><br/><i>Choć nie ma piórek, po nocy szybuje.</i><br/><br/>Kto to taki?',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'nietoperz', idquestion:370, type:'closed', correct:true},
            {answer:'', idquestion:370, type:'closed', correct:false}
        ],
        goodanswermessage:'Świetnie, to jest poprawna odpowiedź! Ale to jeszcze nie koniec! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy...',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:370,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/E2.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [
            {answer:'option5', correct:true}
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 18. Babiogórski Park Narodowy 371 - 385*/
    /** QUEST 1 PRACOWNIK*/
    {
        idquestion:371,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:373, type:'memo', correct:true},
            {answer:'option2', idquestion:372, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:372,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:373,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "okrzyn",
                image: require('../assets/images/animals/okrzyn_jeleni.png'),
                alt:'Okrzyn jeleni'
            },
            {
                type: "borsuk",
                image: require('../assets/images/animals/borsuk.png'),
                alt:'Borsuk'
            },
            {
                type: "rys",
                image: require('../assets/images/animals/rys.png'),
                alt:'Ryś'
            },
            {
                type: "jelen",
                image: require('../assets/images/animals/jelen.png'),
                alt:'Jeleń'
            },
            {
                type: "jez",
                image: require('../assets/images/animals/jez.png'),
                alt:'Jeż'
            },
            {
                type: "kuna",
                image: require('../assets/images/animals/kuna.png'),
                alt:'Kuna'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:374, type:'hint'},
            {answer:'',correct:false, idquestion:375,type:'identifyvoice'},
        ],
    },
    {
        idquestion:374,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>nie można karmić zwierząt</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:375, type:'identifyvoice'},
    },
    {
        idquestion:375,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'głuszec',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'batalion',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'sokół wędrowny',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'kos',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/gluszec.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/gluszec.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/gluszec.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 STRAŻNIK*/
    {
        idquestion:376,
        title:'<strong>Spotykasz Strażnika Parku.<strong><br/><br/>Dzień dobry! Przeprowadzam ankietę wśród osób odwiedzających park. Czy zechciałbyś poświęcić chwilę czasu i odpowiedzieć na dwa proste pytania?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        answersconfig: [
            {answer:'option1', idquestion:378, type:'trash', correct:true},
            {answer:'option2', idquestion:377, type:'hint', correct:false},
        ],
        goodanswermessage:'Doskonale, dziękuję bardzo. Oto pierwsza kwestia.',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:377,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:378,
        title:'Część osób odwiedzających nasz park nie potrafi odpowiednio segregować odpadów. Czy wiesz, do których pojemników powinny one trafić?<br/><br/><i class="fontsize">Wyrzuć wszystkie odpadki wskazując i przeciągając je do odpowiedniego kontenera. Nastepnie naciśnij przycisk "SPRAWDŹ/DALEJ"</i>',
        type:'trash',
        question:[
            {
                id:'t1',
                src:require('../assets/images/trash/bio/b2.png'),
                alt:'Odpadek z grupy bio',
                type:'bio'
            },
            {
                id:'t2',
                src:require('../assets/images/trash/paper/p1.png'),
                alt:'Odpadek z grupy papier',
                type:'paper'
            },
            {
                id:'t3',
                src:require('../assets/images/trash/plastic/p2.png'),
                alt:'Odpadek z grupy plastik',
                type:'plastic'
            },
            {
                id:'t4',
                src:require('../assets/images/trash/glass/g1.png'),
                alt:'Odpadek z grupy szkło',
                type:'glass'
            },
            {
                id:'t5',
                src:require('../assets/images/trash/confused/c2.png'),
                alt:'Odpadek z grupy zmieszane',
                type:'confused'
            },
        ],
        time: [30,25,15],
        answersconfig: [
            {answer:'',correct:true, idquestion:379, type:'hint'},
            {answer:'',correct:false, idquestion:380,type:'closed'},
        ],
        goodanswermessage:'Doskonale! Znasz się na segregacji odpadków.',
        badanswermessage:'Niestety, nie udało Ci się... powinieneś powtórzyć swoją wiedzę o segregacji odpadów. Może kolejna zagadka pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:379,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>jednym z ptaków jest głuszec</strong>.',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:380, type:'closed'},
    },
    {
        idquestion:380,
        title:'A oto drugie pytanie z ankiety. Zaznacz proszę właściwą odpowiedź. W Parku Narodowym:',
        answers:[{
            id: '1',
            label: 'nie można karmić zwierząt',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'można karmić zwierzęta',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'można karmić zwierzęta, ale wyłącznie przeznaczoną do tego celu certyfikowaną karmą',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'można karmić wyłącznie ptaki, wyłącznie przeznaczoną do tego celu certyfikowaną karmą',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'można dokarmiać zwierzęta wyłącznie w okresie zimowym',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'option1', correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 PRZYRODNIK*/
    {
        idquestion:381,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:383, type:'multiselection', correct:true},
            {answer:'option2', idquestion:382, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:382,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:383,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'pomurnik',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'bielik',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'puchacz',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'jarząbek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'kos',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'głuszec',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/383.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:385, type:'find'},
            {answer:'option2', correct:true, idquestion:385, type:'find'},
            {answer:'option3', correct:true, idquestion:385, type:'find'},
            {answer:'option4', correct:false, idquestion:385, type:'find'},
            {answer:'option5', correct:false, idquestion:385, type:'find'},
            {answer:'option6', correct:false, idquestion:385, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się! A teraz... czy jesteś gotów na kolejne wyzwanie?',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:385,
        title:'Doskonale! Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrywa się borsuk... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f18.jpg'),
        alt:'Ilustracja rysia i borsuka na tle lasu',
        x1:321,
        y1:560,
        x2:386,
        y2:636,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a4',
        addPoints:true,
    },
    /** 19. Gorczański Park Narodowy 386 - 400*/
    /** QUEST 1 PRACOWNIK*/
    {
        idquestion:386,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:388, type:'memo', correct:true},
            {answer:'option2', idquestion:387, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:387,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:388,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "salamandra",
                image: require('../assets/images/animals/salamandra_plamista.png'),
                alt:'Salamandra plamista'
            },
            {
                type: "goryczka",
                image: require('../assets/images/animals/goryczka_tojesciowa.png'),
                alt:'Goryczka trojeściowa'
            },
            {
                type: "dzieciol",
                image: require('../assets/images/animals/dzieciol_trojpalczasty.png'),
                alt:'Dzięcioł trójpalczasty'
            },
            {
                type: "gluszec",
                image: require('../assets/images/animals/gluszec.png'),
                alt:'Głuszec'
            },
            {
                type: "rys",
                image: require('../assets/images/animals/rys.png'),
                alt:'Ryś'
            },
            {
                type: "wilk",
                image: require('../assets/images/animals/wilk.png'),
                alt:'Wilk'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:389, type:'hint'},
            {answer:'',correct:false, idquestion:390,type:'identifyvoice'},
        ],
    },
    {
        idquestion:389,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>rozróg</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:390, type:'identifyvoice'},
    },
    {
        idquestion:390,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'głuszec',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'gęś tundrowa',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'podróżniczek',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'zimorodek',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'puszczyk',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/gluszec.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/gluszec.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/gluszec.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 RATOWNIK*/
    {
        idquestion:391,
        title:'<strong>Spotykasz ratownika GOPR.<strong><br/><br/>Witaj na szlaku! Jako ratownik GOPR chciałbym sprawdzić, czy jesteś odpowiednio przygotowany do wędrówki po górskim parku narodowym. Czy zechcesz odpowiedzieć na kilka pytań?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        answersconfig: [
            {answer:'option1', idquestion:393, type:'closed', correct:true},
            {answer:'option2', idquestion:392, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego pytania.',
        badanswermessage:'Ok, rozumiem, że wiesz jak bezpiecznie zachować się w parku. W takim razie powodzenia i uważaj na siebie!',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:392,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:393,
        title:'Gorce wyróżniają się wyjątkowym układem grzbietów górskich – oglądane z lotu ptaka przypominają ogromną rozgwiazdę. Centrum, czyli „głowę” tego tworu stanowi Turbacz, a od niego promieniście, jak ramiona rozgwiazdy, rozchodzą się poszczególne grzbiety górskie rozdzielone głębokimi dolinami. Takie ukształtowanie nazywamy...',
        answers:[{
            id: '1',
            label: 'rozrogiem',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'szpicrogiem',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'spirogiem',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'szczytrogiem',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'wielorogiem',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        answersconfig: [
            {answer:'option1', idquestion:394, type:'hint', correct:true},
            {answer:'option2', idquestion:395, type:'closed', correct:false},
            {answer:'option3', idquestion:395, type:'closed', correct:false},
            {answer:'option4', idquestion:395, type:'closed', correct:false},
            {answer:'option5', idquestion:395, type:'closed', correct:false},
        ],
        goodanswermessage:'Świetnie! To poprawna odpowiedź!',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Ale to nic, może kolejne pytanie będzie łatwiejsze.',
        addPoints:true,
    },
    {
        idquestion:394,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>jednym z ptaków jest zięba</strong>.',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Przejdźmy teraz do pytania numer 2.',
        answersconfig: {answer: '', correct: true, idquestion:395, type:'closed'},
    },
    {
        idquestion:395,
        title:'Zaznacz proszę właściwą odpowiedź. Wstęp do parku narodowego jest możliwy:',
        answers:[{
            id: '1',
            label: 'to zależy od regulaminu danego parku',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'tylko w ciągu dnia',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'tylko w dni robocze',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'tylko w weekendy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'tylko latem',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true},
            
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie!',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Tymczasem spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 PRZYRODNIK*/
    {
        idquestion:396,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:398, type:'multiselection', correct:true},
            {answer:'option2', idquestion:397, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:397,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:398,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'zięba',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'kowalik',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'dzięcioł',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'derkacz',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'kos',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'czapla',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/398.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:400, type:'find'},
            {answer:'option2', correct:true, idquestion:400, type:'find'},
            {answer:'option3', correct:true, idquestion:400, type:'find'},
            {answer:'option4', correct:false, idquestion:400, type:'find'},
            {answer:'option5', correct:false, idquestion:400, type:'find'},
            {answer:'option6', correct:false, idquestion:400, type:'find'}
        ],
        goodanswermessage:'Doskonale! Zobaczymy, czy masz dobre oko.',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:400,
        title:'Gdzieś nieopodal ukryła się salamandra plamista... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f19.jpg'),
        alt:'Ilustracja salamandry plamistej i dzięcioła trójpalczastego na tle lasu',
        x1:333,
        y1:818,
        x2:454,
        y2:881,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a33',
        addPoints:true,
    },
    /** 20. Magurski Park Narodowy 401 - 415*/
    /** QUEST 1 PRACOWNIK*/
    {
        idquestion:401,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:403, type:'memo', correct:true},
            {answer:'option2', idquestion:402, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:402,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:403,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "orlik_krzykliwy",
                image: require('../assets/images/animals/orlik_krzykliwy.png'),
                alt:'Orlik krzykliwy'
            },
            {
                type: "rys",
                image: require('../assets/images/animals/rys.png'),
                alt:'Ryś'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            },
            {
                type: "zaskroniec_zwyczajny",
                image: require('../assets/images/animals/zaskroniec_zwyczajny.png'),
                alt:'Zaskroniec zwyczajny'
            },
            {
                type: "niedzwiedz",
                image: require('../assets/images/animals/niedzwiedz.png'),
                alt:'Niedźwiedź brunatny'
            },
            {
                type: "kuna",
                image: require('../assets/images/animals/kuna.png'),
                alt:'Kuna'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:404, type:'hint'},
            {answer:'',correct:false, idquestion:405,type:'identifyvoice'},
        ],
    },
    {
        idquestion:404,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>od 150 do 200 km</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:405, type:'identifyvoice'},
    },
    {
        idquestion:405,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'puszczyk',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'bocian czarny',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'kos',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'podróżniczek',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/puszczyk.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/puszczyk.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/puszczyk.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 RATOWNIK*/
    {
        idquestion:406,
        title:'<strong>Spotykasz ratownika GOPR.<strong><br/><br/>Witaj na szlaku! Jako ratownik GOPR chciałbym sprawdzić, czy jesteś odpowiednio przygotowany do wędrówki po górskim parku narodowym. Czy zechcesz odpowiedzieć na kilka pytań?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        answersconfig: [
            {answer:'option1', idquestion:408, type:'closed', correct:true},
            {answer:'option2', idquestion:407, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego pytania.',
        badanswermessage:'Ok, rozumiem, że wiesz jak bezpiecznie zachować się w parku. W takim razie powodzenia i uważaj na siebie!',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:407,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:408,
        title:'Orientacyjna powierzchnia terenu zajmowanego przez wilczą rodzinę wynosi około...',
        answers:[{
            id: '1',
            label: '150-200 km2',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '5-10 km2',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '25-50 km2',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '50-100 km2',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '200-250 km2',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', idquestion:409, type:'hint', correct:true},
            {answer:'option2', idquestion:410, type:'closed', correct:false},
            {answer:'option3', idquestion:410, type:'closed', correct:false},
            {answer:'option4', idquestion:410, type:'closed', correct:false},
            {answer:'option5', idquestion:410, type:'closed', correct:false},
        ],
        goodanswermessage:'Dobrze! Przejdźmy do pytania numer 2.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Ale to nic, może kolejne pytanie będzie łatwiejsze.',
        addPoints:true,
    },
    {
        idquestion:409,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>jeden z ptaków to sokół</strong>.',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        type:'hint',
        addPoints:false,
        goodanswermessage:'A teraz... czy jesteś gotów na kolejne wyzwanie?',
        answersconfig: {answer: '', correct: true, idquestion:410, type:'closed'},
    },
    {
        idquestion:410,
        title:'Zaznacz proszę właściwą odpowiedź. W Parku Narodowym można poruszać się:',
        answers:[{
            id: '1',
            label: 'po wyznaczonych szlakach turystycznych',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dowolnie, o ile nie ma oznakowanego zakazu wstępu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'po nieoznakowanych ścieżkach',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'po wyznaczonych szlakach turystycznych i nieoznakowanych ścieżkach',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'nie można poruszać się po parku narodowym',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true},
            
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Tymczasem spbóbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 PRZYRODNIK*/
    {
        idquestion:411,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:413, type:'multiselection', correct:true},
            {answer:'option2', idquestion:412, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:412,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:413,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'bielik',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'puchacz',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'sokół',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'drozd',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'pomurnik',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'batalion',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/413.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:415, type:'find'},
            {answer:'option2', correct:true, idquestion:415, type:'find'},
            {answer:'option3', correct:true, idquestion:415, type:'find'},
            {answer:'option4', correct:false, idquestion:415, type:'find'},
            {answer:'option5', correct:false, idquestion:415, type:'find'},
            {answer:'option6', correct:false, idquestion:415, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się! A teraz... czy jesteś gotów na kolejne wyzwanie?',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:415,
        title:'Doskonale! Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się orlik krzykliwy... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f20.jpg'),
        alt:'Ilustracja orlika krzykliwego, rysia i traszki zwyczajnej na tle łagodnych wzniesień Magurskiego Parku Narodowego',
        x1:663,
        y1:190,
        x2:759,
        y2:245,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a25',
        addPoints:true,
    },
    /** 21. Pieniński Park Narodowy 416 - 430*/
    /** QUEST 1 PRACOWNIK*/
    {
        idquestion:416,
        title:'<strong>Spotykasz pracownika Parku.<strong><br/><br/>Witam Cię serdecznie! Cieszę się, że odwiedzasz nasz Park! Szukam śmiałka, który chciałby podjąć się rozwiązania kilku zagadek... Może chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        answersconfig: [
            {answer:'option1', idquestion:418, type:'memo', correct:true},
            {answer:'option2', idquestion:417, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego zadania.',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny się odważy.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:417,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:418,
        title: 'Oto pierwsze zadanie – sprawdźmy twoją pamięć. Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "pomurnik",
                image: require('../assets/images/animals/pomurnik.png'),
                alt:'Pomurnik'
            },
            {
                type: "sosna",
                image: require('../assets/images/animals/sosna_pospolita.png'),
                alt:'Sosna pospolita'
            },
            {
                type: "zmija",
                image: require('../assets/images/animals/zmija_zygzakowata.png'),
                alt:'Żmija zygzakowata'
            },
            {
                type: "jelen",
                image: require('../assets/images/animals/jelen.png'),
                alt:'Jeleń'
            },
            {
                type: "wydra",
                image: require('../assets/images/animals/wydra.png'),
                alt:'Wydra'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Ale to nic! Może kolejna zagadka pójdzie Ci lepiej!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true, idquestion:419, type:'hint'},
            {answer:'',correct:false, idquestion:420,type:'identifyvoice'},
        ],
    },
    {
        idquestion:419,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>Sokolica</strong>.',
        src:require('../assets/images/quest/pra_1.jpg'),
        alt:'Zdjęcie pracownika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:420, type:'identifyvoice'},
    },
    {
        idquestion:420,
        title:'A oto drugie wyzwanie. W Polsce występuje wiele różnych gatunków ptaków. Czy potrafisz rozpoznać, do którego z nich należy ten odgłos?',
        answers:[{
            id: '1',
            label: 'derkacz',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'sokół wędrowny',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'bocian czarny',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'zimorodek',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }],
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/identity/derkacz.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/identity/derkacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/identity/derkacz.mp3')},
        ],
        type:'identifyvoice',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w wyzwaniach.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true
    },
    /** QUEST 2 RATOWNIK*/
    {
        idquestion:421,
        title:'<strong>Spotykasz ratownika GOPR.<strong><br/><br/>Witaj na szlaku! Jako ratownik GOPR chciałbym sprawdzić, czy jesteś odpowiednio przygotowany do wędrówki po górskim parku narodowym. Czy zechcesz odpowiedzieć na kilka pytań?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        answersconfig: [
            {answer:'option1', idquestion:423, type:'closed', correct:true},
            {answer:'option2', idquestion:422, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego pytania.',
        badanswermessage:'Ok, rozumiem, że wiesz jak bezpiecznie zachować się w parku. W takim razie powodzenia i uważaj na siebie!',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:422,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:423,
        title:'Szczyt będący symbolem Pieniń, na którym rośnie charakterystyczna ponad 500-letnia sosna, to...',
        answers:[{
            id: '1',
            label: 'Sokolica',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Okrąglica',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Łysica',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Mogielnica',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'Skalica',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', idquestion:424, type:'hint', correct:true},
            {answer:'option2', idquestion:425, type:'closed', correct:false},
            {answer:'option3', idquestion:425, type:'closed', correct:false},
            {answer:'option4', idquestion:425, type:'closed', correct:false},
            {answer:'option5', idquestion:425, type:'closed', correct:false},
        ],
        goodanswermessage:'Dobrze! Przejdźmy do pytania numer 2.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Ale to nic, może kolejne pytanie będzie łatwiejsze.',
        addPoints:true,
    },
    {
        idquestion:424,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>jeden z ptaków to dzięcioł</strong>.',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        type:'hint',
        addPoints:false,
        goodanswermessage:'A teraz... czy jesteś gotów na kolejne wyzwanie?',
        answersconfig: {answer: '', correct: true, idquestion:425, type:'closed'},
    },
    {
        idquestion:425,
        title:'Zaznacz proszę właściwą odpowiedź. W Parku Narodowym można:',
        answers:[{
            id: '1',
            label: 'nie wolno zbierać roślin, grzybów ani owoców, chyba, że regulamin parku stanowi inaczej',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'zbierać rośliny, grzyby i owoce',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'zbierać rośliny, grzyby i owoce, o ile nie należą do gatunków chronionych',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zbierać wyłącznie owoce, o ile nie należą do gatunków chronionych',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'zbierać wyłącznie grzyby',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true},
            
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Ale to nic, może kolejne pytanie będzie łatwiejsze.',
        addPoints:true,
    },
    /** QUEST 3 PRZYRODNIK*/
    {
        idquestion:426,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:428, type:'multiselection', correct:true},
            {answer:'option2', idquestion:427, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:427,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:428,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'dzięcioł',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'bocian',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'drozd',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'bielik',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'puchacz',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'orlik',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/428.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:430, type:'find'},
            {answer:'option2', correct:true, idquestion:430, type:'find'},
            {answer:'option3', correct:true, idquestion:430, type:'find'},
            {answer:'option4', correct:false, idquestion:430, type:'find'},
            {answer:'option5', correct:false, idquestion:430, type:'find'},
            {answer:'option6', correct:false, idquestion:430, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się! A teraz... czy jesteś gotów na kolejne wyzwanie?',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:430,
        title:'Doskonale! Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się pomurnik... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f21.jpg'),
        alt:'Ilustracja pomurnika i żmii zygzakowatej na tle Pienin',
        x1:623,
        y1:132,
        x2:765,
        y2:264,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a28',
        addPoints:true,
    },
    /** 22. Bieszczadzki Park Narodowy 431 - 445*/
    /** QUEST 1 STRAŻNIK*/
    {
        idquestion:431,
        title:'<strong>Spotykasz Strażnika Parku.<strong><br/><br/>Dzień dobry! Przeprowadzam ankietę wśród osób odwiedzających park. Czy zechciałbyś poświęcić chwilę czasu i odpowiedzieć na dwa proste pytania?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        answersconfig: [
            {answer:'option1', idquestion:433, type:'trash', correct:true},
            {answer:'option2', idquestion:432, type:'hint', correct:false},
        ],
        goodanswermessage:'Doskonale, dziękuję bardzo. Oto pierwsza kwestia.',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:432,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:433,
        title:'Część osób odwiedzających nasz park nie potrafi odpowiednio segregować odpadów. Czy wiesz, do których pojemników powinny one trafić?<br/><br/><i class="fontsize">Wyrzuć wszystkie odpadki wskazując i przeciągając je do odpowiedniego kontenera. Nastepnie naciśnij przycisk "SPRAWDŹ/DALEJ"</i>',
        type:'trash',
        question:[
            {
                id:'t1',
                src:require('../assets/images/trash/bio/b1.png'),
                alt:'Odpadek z grupy bio',
                type:'bio'
            },
            {
                id:'t2',
                src:require('../assets/images/trash/paper/p1.png'),
                alt:'Odpadek z grupy papier',
                type:'paper'
            },
            {
                id:'t3',
                src:require('../assets/images/trash/plastic/p2.png'),
                alt:'Odpadek z grupy plastik',
                type:'plastic'
            },
            {
                id:'t4',
                src:require('../assets/images/trash/glass/g2.png'),
                alt:'Odpadek z grupy szkło',
                type:'glass'
            },
            {
                id:'t5',
                src:require('../assets/images/trash/confused/c1.png'),
                alt:'Odpadek z grupy zmieszane',
                type:'confused'
            },
        ],
        time: [30,25,15],
        answersconfig: [
            {answer:'',correct:true, idquestion:434, type:'hint'},
            {answer:'',correct:false, idquestion:435,type:'closed'},
        ],
        goodanswermessage:'Doskonale! Znasz się na segregacji odpadków.',
        badanswermessage:'Niestety, nie udało Ci się... powinieneś powtórzyć swoją wiedzę o segregacji odpadów. Może kolejna zagadka pójdzie Ci lepiej!',
        addPoints:true,
    },
    {
        idquestion:434,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>połonina</strong>.',
        src:require('../assets/images/quest/str_1.jpg'),
        alt:'Zdjęcie strażnika parku',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy do kolejnego wyzwania.',
        answersconfig: {answer: '', correct: true, idquestion:435, type:'closed'},
    },
    {
        idquestion:435,
        title:'A oto drugie pytanie z ankiety. Zaznacz proszę właściwą odpowiedź. W Parku Narodowym:',
        answers:[{
            id: '1',
            label: 'wolno robić zdjęcia na własny użytek',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'nie wolno robić zdjęć',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wolno robić zdjęcia z wyjątkiem fotografowania zwierząt',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wolno robić zdjęcia wyłącznie w miejscach wskazanych przez dyrekcję parku',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'nie potrzeba zgody na wykonanie zdjęć komercyjnych',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true},
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 POSZUKIWACZ*/
    {
        idquestion:436,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:438, type:'puzzle', correct:true},
            {answer:'option2', idquestion:437, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:437,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:438,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/3.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:439, type:'hint'},
            {answer:'', correct:false, idquestion:440, type:'open'},
        ],
    },
    {
        idquestion:439,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>bukowe</strong>.',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec!',
        answersconfig: {answer: '', correct: true, idquestion:440, type:'open'},
    },
    {
        idquestion:440,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/22.png'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'POŁONINA', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Bieszczadzkiego Parku Narodowego jest połonina! Dziękuję Ci za pomoc! Idę zdobyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 3 RATOWNIK*/
    {
        idquestion:441,
        title:'<strong>Spotykasz ratownika GOPR Grupa Bieszczady.<strong><br/><br/>Witaj na szlaku! Jako ratownik chciałbym sprawdzić, czy jesteś odpowiednio przygotowany do wędrówki po górskim parku narodowym. Czy zechcesz odpowiedzieć na kilka pytań?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        answersconfig: [
            {answer:'option1', idquestion:443, type:'closed', correct:true},
            {answer:'option2', idquestion:442, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy do pierwszego pytania.',
        badanswermessage:'Ok, rozumiem, że wiesz jak bezpiecznie zachować się w parku. W takim razie powodzenia i uważaj na siebie!',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:442,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/rat_1.jpg'),
        alt:'Zdjęcie ratownika GOPR',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:443,
        title:'Turystyka konna dozwolona jest na terenie Bieszczadzkiego Parku Narodowego po oznakowanych szlakach turystycznych i na koniach należących do:',
        answers:[{
            id: '1',
            label: 'Bieszczadzkiego Parku Narodowego oraz innych stadnin spoza terenu BdPN po uzgodnieniu z Dyrekcją',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dowolnej osoby fizycznej',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'dowolnej stadniny konnej',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'turystyka konna nie jest w ogóle dozwolona',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'dowolnej osoby fizycznej, ale wyłącznie z certyfikowanym przewodnikiem',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', idquestion:445, type:'closed', correct:true},
            {answer:'option2', idquestion:445, type:'closed', correct:false},
            {answer:'option3', idquestion:445, type:'closed', correct:false},
            {answer:'option4', idquestion:445, type:'closed', correct:false},
            {answer:'option5', idquestion:445, type:'closed', correct:false},
        ],
        goodanswermessage:'Dobrze! Przejdźmy do pytania numer 2.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Ale to nic, może kolejne pytanie będzie łatwiejsze.',
        addPoints:true,
    },
    {
        idquestion:445,
        title:'Zaznacz proszę właściwą odpowiedź. Ile Parków Narodowych istnieje w Polsce?',
        answers:[{
            id: '1',
            label: '23',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '27',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '32',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '35',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '17',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1', correct:true},
            
        ],
        goodanswermessage:'Wspaniale! To jest poprawna odpowiedź! Dziękuję za wzięcie udziału w ankiecie.',
        badanswermessage:'Niestety... powinieneś powtórzyć swoją wiedzę o naszym parku. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** 23. Tatrzański Park Narodowy 446 - 460*/
    /** QUEST 1 TURYSTA*/
    {
        idquestion:446,
        title:'<strong>Spotykasz turystkę.<strong><br/><br/>Witaj, miło Cię widzieć! Lubisz wyzwania? Jeśli tak, mam coś specjalnie dla Ciebie. Czy chcesz spróbować swoich sił?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        answersconfig: [
            {answer:'option1', idquestion:448, type:'open', correct:true},
            {answer:'option2', idquestion:447, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda, zapowiadała się fajna przygoda ...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:447,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:448,
        title:'Oto pierwsza zagadka:<br/><br/><i>Mądre oczy, duża głowa,</i><br/><i>w nocy łowi w dzień się chowa.</i><br/><i>Zgadniesz po tych paru słowach,</i><br/><i>kto to taki? Może...</i>',
        type:'open',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig:[
            {answer:'sowa', idquestion:449, type:'hint', correct:true},
            {answer:'', idquestion:450, type:'closed', correct:false}
        ],
        goodanswermessage:'Brawo! To poprawna odpowiedź!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź... Ale nie przejmuj się! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy.',
        addPoints:true,
    },
    {
        idquestion:449,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>jednym z ptaków jest orzeł</strong>.',
        src:require('../assets/images/quest/tur_1.jpg'),
        alt:'Zdjęcie turystki',
        type:'hint',
        addPoints:false,
        goodanswermessage:'Ale to jeszcze nie koniec! Przejdźmy dalej i sprawdźmy, czy umiesz dobrze korzystać z mapy...',
        answersconfig: {answer: '', correct: true, idquestion:450, type:'closed'},
    },
    {
        idquestion:450,
        title:'Oto mapa przedstawiająca czas przejścia okolicznych szlaków. Czy potrafisz wskazać szlak, który najszybciej doprowadzi mnie do celu?',
        answers:[{
            id: '1',
            label: 'A',
            value: 'option1',
            color:'#f08733',
            selected:false,
        }, 
        {
            id: '2',
            label: 'B',
            value: 'option2',
            color:'#f08733',
            selected:false,
        },
        {
            id: '3',
            label: 'C',
            value: 'option3',
            color:'#f08733',
            selected:false,
        },
        {
            id: '4',
            label: 'D',
            value: 'option4',
            color:'#f08733',
            selected:false,
        },
        {
            id: '5',
            label: 'E',
            value: 'option5',
            color:'#f08733',
            selected:false,
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/A1.png'),
        alt:'Ilustracja mapy przedstawiającej 5 okolicznych szlaków',
        answersconfig: [ 
            {answer:'option1', correct:true}  
        ],
        goodanswermessage:'Doskonale, udało Ci się! To jest prawidłowa odpowiedź! Na mnie już czas, ruszam dalej. Dzięki i do zobaczenia na szlaku!',
        badanswermessage:'Niestety, to nie jest najkrótsza trasa do celu... Spróbuj jeszcze raz!',
        addPoints:true,
    },
    /** QUEST 2 PRZYRODNIK*/
    {
        idquestion:451,
        title:'<strong>Spotykasz przyrodniczkę.<strong><br/><br/>Witaj wędrowcze! Szukam śmiałka, który rozwiąże moje zagadki przyrodnicze. Czy chciałbyś spróbować?',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        answersconfig: [
            {answer:'option1', idquestion:453, type:'multiselection', correct:true},
            {answer:'option2', idquestion:452, type:'hint', correct:false},
        ],
        goodanswermessage:'Świetnie! Przejdźmy zatem do zagadek!',
        badanswermessage:'Szkoda! No nic, może ktoś inny będzie zainteresowany.',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:452,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:453,
        title:'Oto pierwsza z nich.<br/><br/>Na wykreślance ukryte są trzy nazwy ptaków. Odnajdź je i zaznacz trzy poprawne odpowiedzi.',
        answers:[{
            id: '1',
            label: 'orzeł',
            value: 'option1',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }, 
        {
            id: '2',
            label: 'puchacz',
            value: 'option2',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '3',
            label: 'sokół',
            value: 'option3',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '4',
            label: 'bocian',
            value: 'option4',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '5',
            label: 'orlik',
            value: 'option5',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        },
        {
            id: '6',
            label: 'jarząbek',
            value: 'option6',
            color:'#f08733',
            borderColor:'#3d836d',
            selected:false,
            labelStyle:{fontFamily:'Comfortaa-Regular'}
        }
        ],
        type:'multiselection',
        src:require('../assets/images/quest/453.png'),
        alt:'Ilustracja wykreślanki do zadania',
        answersconfig: [
            {answer:'option1', correct:true, idquestion:454, type:'hint'},
            {answer:'option2', correct:true, idquestion:454, type:'hint'},
            {answer:'option3', correct:true, idquestion:454, type:'hint'},
            {answer:'option4', correct:false, idquestion:455, type:'find'},
            {answer:'option5', correct:false, idquestion:455, type:'find'},
            {answer:'option6', correct:false, idquestion:455, type:'find'}
        ],
        goodanswermessage:'Brawo! Udało Ci się!',
        badanswermessage:'Niestety nie udało Ci się poprawnie wskazać wszystkich nazw ptaków. Ale to nic, mam dla Ciebie jeszcze jedno zadanie! Czy jesteś gotów?',
        addPoints:true,
    },
    {
        idquestion:454,
        title:'W nagrodę mam dla Ciebie wskazówkę, która może Ci się przydać w przyszłości.<br/><br/> Wskazówka brzmi: <strong>Giewont</strong>.',
        src:require('../assets/images/quest/prz_1.jpg'),
        alt:'Zdjęcie przyrodnika',
        type:'hint',
        addPoints:false,
        goodanswermessage:'A teraz... czy jesteś gotów na kolejne wyzwanie?',
        answersconfig: {answer: '', correct: true, idquestion:455, type:'find'},
    },
    {
        idquestion:455,
        title:'Doskonale! Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukryła się kozica... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f23.jpg'),
        alt:'Ilustracja niedźwiedzia, zaskrońca zwyczajnego i kozicy na tle tatrzańskiego zbocza',
        x1:97,
        y1:159,
        x2:290,
        y2:237,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a18',
        addPoints:true,
    },
    /** QUEST 3 POSZUKIWACZ*/
    {
        idquestion:456,
        title:'<strong>Spotykasz poszukiwaczkę.<strong><br/><br/>Przepraszam, czy nie zechciałbyś pomóc mi rozwiązać pewnej kwestii? Szukam skarbu ukrytego na terenie parku i nie potrafię poradzić sobie z pewną zagadką, która pomoże mi go odnaleźć...',
        answers:[{
            id: '1',
            label: 'Tak',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Nie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        answersconfig: [
            {answer:'option1', idquestion:458, type:'puzzle', correct:true},
            {answer:'option2', idquestion:457, type:'hint', correct:false},
        ],
        goodanswermessage:'Dziękuję Ci! Przejdźmy zatem do szczegółów!',
        badanswermessage:'Szkoda... w takim razie ruszam dalej, może ktoś inny będzie wiedział o co tu chodzi...',
        addPoints:false,
        noshuffle:true
    },
    {
        idquestion:457,
        title:'Do zobaczenia na szlaku!',
        src:require('../assets/images/quest/pos_1.jpg'),
        alt:'Zdjęcie poszukiwacza skarbów',
        type:'hint',
        addPoints:false,
    },
    {
        idquestion:458,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta, a ja nie potrafię we właściwy sposób ułożyć jej elementów. Może Tobie się uda?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/2.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Dziękuję Ci za pomoc!',
        badanswermessage:'Ech, trudno... jak widzisz nie jest to prosta sprawa. Ale to nic, może uda nam się w inny sposób dowiedzieć czegoś o skarbie.',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true, idquestion:460, type:'open'},
            {answer:'', correct:false, idquestion:460, type:'open'},
        ],
    },
    {
        idquestion:460,
        title:'Aby dowiedzieć się, co to za skarb, trzeba złamać poniższy szyfr. Podobno jest w nim ukryta nazwa skarbu, ale ja nigdy nie byłam dobra z ułamków... Dopiero wtedy, gdy poznamy jego nazwę, będziemy w stanie zlokalizować go na mapie. Może Tobie się uda?',
        type:'open',
        src:require('../assets/images/quest/szyfry/23.jpg'),
        alt:'Ilustracja zagadki matematycznej',
        answersconfig:[
            {answer:'giewont', correct:true},
        ],
        goodanswermessage:'Doskonale! Oczywiście! Skarbem Tatrzańskiego Parku Narodowego jest Giewont! Dziękuję Ci za pomoc! Idę zdobyć skarb!',
        badanswermessage:'Niestety, to nie jest poprawna odpowiedź. Spróbuj jeszcze raz!',
        addPoints:true,
    },
    
    /** Druga tura zadan do quizow */
    /** 1. Słowiński Park Narodowy 461 - 465*/
    {
        idquestion:461,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/1_logo.webp'),
        alt:'Logo Słowińskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:462,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się lis... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f1.jpg'),
        alt:'Ilustracja foki szarej, lisa i dzięcioła na tle plaży i morza',
        x1:168,
        y1:643,
        x2:270,
        y2:719,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a20',
        addPoints:true,
    },
    {
        idquestion:463,
        title: 'Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "gluszec",
                image: require('../assets/images/animals/gluszec.png'),
                alt:'Głuszec'
            },
            {
                type: "jelen",
                image: require('../assets/images/animals/jelen.png'),
                alt:'Jeleń'
            },
            {
                type: "puszczyk",
                image: require('../assets/images/animals/puszczyk.png'),
                alt:'Puszczyk'
            },
            {
                type: "zaskroniec_zwyczajny",
                image: require('../assets/images/animals/zaskroniec_zwyczajny.png'),
                alt:'Zaskroniec zwyczajny'
            },
            {
                type: "foka_szara",
                image: require('../assets/images/animals/foka_szara.png'),
                alt:'Foka szara'
            },
            {
                type: "batalion",
                image: require('../assets/images/animals/batalion.png'),
                alt:'Batalion'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Spróbuj jeszcze raz!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:464,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się dzięcioł czarny... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f1.jpg'),
        alt:'Ilustracja foki szarej, lisa i dzięcioła na tle plaży i morza',
        x1:228,
        y1:90,
        x2:310,
        y2:210,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a7',
        addPoints:true,
    },
    {
        idquestion:465,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę dzięcioła czarnego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/dzieciol_czarny.png'),
        alt:'Ilustracja dzięcioła czarnego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/dzieciol_czarny.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/dzieciol_czarny_puchacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/dzieciol_czarny_puchacz_derkacz.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'dzięcioł czarny',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    /** 2. Wigierski Park Narodowy 466 - 470*/
    {
        idquestion:466,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się bóbr... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f2.jpg'),
        alt:'Ilustracja bobra, borsuka i wilka na tle rzeki płynącej przez las',
        x1:322,
        y1:673,
        x2:405,
        y2:763,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a2',
        addPoints:true,
    },
    {
        idquestion:467,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę borsuka... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/borsuk.png'),
        alt:'Ilustracja borsuka',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/borsuk.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/borsuk_gluszec.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/borsuk_gluszec_myszolow.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'borsuk',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:468,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się wilk... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f2.jpg'),
        alt:'Ilustracja bobra, borsuka i wilka na tle rzeki płynącej przez las',
        x1:653,
        y1:523,
        x2:700,
        y2:593,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a38',
        addPoints:true,
    },
    {
        idquestion:469,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/2_logo.webp'),
        alt:'Logo Wigierskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:470,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się borsuk... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f2.jpg'),
        alt:'Ilustracja bobra, borsuka i wilka na tle rzeki płynącej przez las',
        x1:241,
        y1:569,
        x2:272,
        y2:603,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a4',
        addPoints:true,
    },
    /** 3. Woliński Park Narodowy 471 - 475*/
    {
        idquestion:471,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się bielik... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f3.jpg'),
        alt:'Ilustracja bielika, zimorodka i nietoperza na tle mokradeł',
        x1:675,
        y1:340,
        x2:930,
        y2:515,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a26',
        addPoints:true,
    },
    {
        idquestion:472,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/3_logo.webp'),
        alt:'Logo Wolińskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:473,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się zimorodek... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f3.jpg'),
        alt:'Ilustracja bielika, zimorodka i nietoperza na tle mokradeł',
        x1:225,
        y1:550,
        x2:335,
        y2:650,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a42',
        addPoints:true,
    },
    {
        idquestion:474,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę bielika... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/orzel_bielik.png'),
        alt:'Ilustracja bielika',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/bielik.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/bielik_podrozniczek.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/bielik_podrozniczek_wilk.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'bielik',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:475,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się nietoperz... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f3.jpg'),
        alt:'Ilustracja bielika, zimorodka i nietoperza na tle mokradeł',
        x1:215,
        y1:825,
        x2:420,
        y2:910,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a24',
        addPoints:true,
    },
    /** 4. Park Narodowy „Bory Tucholskie” 476 - 480*/
    {
        idquestion:476,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/4_logo.webp'),
        alt:'Logo Parku Narodowego „Bory Tucholskie”',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:477,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się dzięcioł czarny... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f4.jpg'),
        alt:'Ilustracja dzięcioła czarnego i głuszca na tle lasu',
        x1:140,
        y1:140,
        x2:260,
        y2:280,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a7',
        addPoints:true,
    },
    {
        idquestion:478,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę głuszca... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/gluszec.png'),
        alt:'Ilustracja głuszca',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/gluszec.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/gluszec_puchacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/gluszec_puchacz_zuraw.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'głuszec',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:479,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/13.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:480,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę dzięcioła czarnego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/dzieciol_czarny.png'),
        alt:'Ilustracja dzięcioła czarnego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/dzieciol_czarny.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/dzieciol_czarny_puchacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/dzieciol_czarny_puchacz_derkacz.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'dzięcioł czarny',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    /** 5. Biebrzański Park Narodowy 481 - 485*/
    {
        idquestion:481,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się batalion... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f5.jpg'),
        alt:'Ilustracja bataliona i derkacza na tle rzeki',
        x1:180,
        y1:890,
        x2:310,
        y2:990,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a47',
        addPoints:true,
    },
    {
        idquestion:482,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę derkacza... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/derkacz.png'),
        alt:'Ilustracja derkacza',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/derkacz.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/derkacz_batalion.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/derkacz_batalion_czapla.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'derkacz',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:483,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/5.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:484,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę bataliona... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/batalion.png'),
        alt:'Ilustracja bataliona',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/batalion.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/batalion_derkacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/batalion_derkacz_czapla.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'batalion',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:485,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/5_logo.webp'),
        alt:'Logo Biebrzańskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    /** 6. Białowieski Park Narodowy 486 - 490*/
    {
        idquestion:486,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się żubr... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f6.jpg'),
        alt:'Ilustracja żubra, dzięcioła trójpalczastego i rysia, na tle lasu',
        x1:245,
        y1:455,
        x2:535,
        y2:755,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a44',
        addPoints:true,
    },
    {
        idquestion:487,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę dzięcioła trójpalczastego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/dzieciol_trojpalczasty.png'),
        alt:'Ilustracja dzięcioła trójpalczastego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/dzieciol_trojpalczasty.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/dziecioltrojpalczasty_puchacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/dziecioltrojpalczasty_puchacz_batalion.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'dzięcioł trójpalczasty',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:488,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się dzięcioł trójpalczasty... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f6.jpg'),
        alt:'Ilustracja żubra, dzięcioła trójpalczastego i rysia, na tle lasu',
        x1:865,
        y1:440,
        x2:930,
        y2:568,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a8',
        addPoints:true,
    },
    {
        idquestion:489,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/6_logo.webp'),
        alt:'Logo Białowieskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:490,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się ryś... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f6.jpg'),
        alt:'Ilustracja żubra, dzięcioła trójpalczastego i rysia, na tle lasu',
        x1:580,
        y1:712,
        x2:784,
        y2:920,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a32',
        addPoints:true,
    },
    /** 7. Drawieński Park Narodowy 491 - 495*/
    {
        idquestion:491,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukryła się wydra... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f7.jpg'),
        alt:'Ilustracja wydry, zimorodka i borsuka na tle rzeki płynącej przez las',
        x1:820,
        y1:570,
        x2:990,
        y2:710,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a39',
        addPoints:true,
    },
    {
        idquestion:492,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę borsuka... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/borsuk.png'),
        alt:'Ilustracja borsuka',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/borsuk.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/borsuk_gluszec.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/borsuk_gluszec_myszolow.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'borsuk',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:493,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się zimorodek... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f7.jpg'),
        alt:'Ilustracja wydry, zimorodka i borsuka na tle rzeki płynącej przez las',
        x1:139,
        y1:509,
        x2:185,
        y2:562,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a42',
        addPoints:true,
    },
    {
        idquestion:494,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę zimorodka... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/zimorodek.png'),
        alt:'Ilustracja zimorodka',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/zimorodek.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/zimorodek_batalion.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/zimorodek_batalion_puchacz.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'zimorodek',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:495,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się borsuk... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f7.jpg'),
        alt:'Ilustracja wydry, zimorodka i borsuka na tle rzeki płynącej przez las',
        x1:635,
        y1:540,
        x2:740,
        y2:640,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a4',
        addPoints:true,
    },
    /** 8. Narwiański Park Narodowy 496 - 500*/
    {
        idquestion:496,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się błotniak stawowy... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f8.jpg'),
        alt:'Ilustracja błotniaka stawowego, derkacza i ropuchy zielonej na tle mokradeł',
        x1:180,
        y1:220,
        x2:560,
        y2:450,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a1',
        addPoints:true,
    },
    {
        idquestion:497,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę derkacza... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/derkacz.png'),
        alt:'Ilustracja derkacza',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/derkacz.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/derkacz_batalion.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/derkacz_batalion_czapla.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'derkacz',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:498,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się derkacz... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f8.jpg'),
        alt:'Ilustracja błotniaka stawowego, derkacza i ropuchy zielonej na tle mokradeł',
        x1:803,
        y1:357,
        x2:864,
        y2:423,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a5',
        addPoints:true,
    },
    {
        idquestion:499,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę błotniaka stawowego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/blotniak_stawowy.png'),
        alt:'Ilustracja błotniaka stawowego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/blotniak_stawowy.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/blotniak_podrozniczek.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/blotniak_podrozniczek_kos.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'błotniak stawowy',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:500,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukryła się ropucha zielona... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f8.jpg'),
        alt:'Ilustracja błotniaka stawowego, derkacza i ropuchy zielonej na tle mokradeł',
        x1:105,
        y1:685,
        x2:171,
        y2:746,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a31',
        addPoints:true,
    },
    /** 9. Park Narodowy „Ujście Warty” 501 - 505 */
    {
        idquestion:501,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę zaskrońca zwyczajnego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/zaskroniec_zwyczajny.png'),
        alt:'Ilustracja zaskrońca zwyczajnego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/zaskroniec.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/zaskroniec_myszolow.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/zasrkoniec_myszolow_puszczyk.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], //interwal
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'zaskroniec zwyczajny',//nazwa wyświetlana na ekranie "Odgłosy"
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:502,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się derkacz... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f9.jpg'),
        alt:'Ilustracja gęsi tundrowej, derkacza i zaskrońca zwyczajnego na tle mokradeł',
        x1:57,
        y1:746,
        x2:120,
        y2:851,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a5',
        addPoints:true,
    },
    {
        idquestion:503,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę gęś tundrową... czy uda Ci się nagrać jej odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/ges_tundrowa.png'),
        alt:'Ilustracja gęsi tundrowej',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/ges_tundrowa.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/ges_tundrowa_puchacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/ges_tundrowa_puchacz_batalion.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], //interwal
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'gęś tundrowa',//nazwa wyświetlana na ekranie "Odgłosy"
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:504,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę derkacza... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/derkacz.png'),
        alt:'Ilustracja derkacza',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/derkacz.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/derkacz_batalion.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/derkacz_batalion_czapla.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'derkacz',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:505,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się zaskroniec zwyczajny... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f9.jpg'),
        alt:'Ilustracja gęsi tundrowej, derkacza i zaskrońca zwyczajnego na tle mokradeł',
        x1:884,
        y1:849,
        x2:966,
        y2:891,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a40',
        addPoints:true,
    },
    /** 10. Kampinoski Park Narodowy 506 - 510*/
    {
        idquestion:506,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się łoś... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f10.jpg'),
        alt:'Ilustracja łosia, bociana czarnego i nietoperza',
        x1:187,
        y1:375,
        x2:421,
        y2:603,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a21',
        addPoints:true,
    },
    {
        idquestion:507,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę bociana czarnego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/bocian_czarny.png'),
        alt:'Ilustracja bociana czarnego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/bocian_czarny.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/bocian_czarny_kos.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/bocian_czarny_kos_gluszec.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'bocian czarny',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:508,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył bocian czarny... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f10.jpg'),
        alt:'Ilustracja łosia, bociana czarnego i nietoperza',
        x1:634,
        y1:210,
        x2:803,
        y2:268,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a3',
        addPoints:true,
    },
    {
        idquestion:509,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę łosia... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/los.png'),
        alt:'Ilustracja łosia',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/los.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/los_sokol_wedrowny.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/los_sokol_wedrowny_puszczyk.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'łoś',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:510,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się nietoperz... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f10.jpg'),
        alt:'Ilustracja łosia, bociana czarnego i nietoperza',
        x1:811,
        y1:810,
        x2:904,
        y2:888,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a24',
        addPoints:true,
    },
    /** 11. Wielkopolski Park Narodowy 511 - 515*/
    {
        idquestion:511,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się puszczyk... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f11.jpg'),
        alt:'Ilustracja puszczynka, myszołowa i zaskrońca zwyczajnego na tle zalesionego brzegu jeziora',
        x1:820,
        y1:522,
        x2:874,
        y2:607,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a30',
        addPoints:true,
    },
    {
        idquestion:512,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę zaskrońca zwyczajnego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/zaskroniec_zwyczajny.png'),
        alt:'Ilustracja zaskrońca zwyczajnego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/zaskroniec.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/zaskroniec_myszolow.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/zasrkoniec_myszolow_puszczyk.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'zaskroniec zwyczajny',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:513,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się myszołów... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f11.jpg'),
        alt:'Ilustracja puszczynka, myszołowa i zaskrońca zwyczajnego na tle zalesionego brzegu jeziora',
        x1:50,
        y1:740,
        x2:161,
        y2:856,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a22',
        addPoints:true,
    },
    {
        idquestion:514,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę myszołowa... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/myszolow.png'),
        alt:'Ilustracja myszołowa',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/myszolow.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/myszolow_puszczyk.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/myszolow_puszczyk_zaskroniec.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'myszołów',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:515,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się zaskroniec zwyczajny... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f11.jpg'),
        alt:'Ilustracja puszczynka, myszołowa i zaskrońca zwyczajnego na tle zalesionego brzegu jeziora',
        x1:775,
        y1:950,
        x2:874,
        y2:980,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a40',
        addPoints:true,
    },
    /** 12. Poleski Park Narodowy 516 - 520*/
    {
        idquestion:516,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się żuraw... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f12.jpg'),
        alt:'Ilustracja żurawia, traszki zwyczajnej i kosa na tle mokreadeł',
        x1:421,
        y1:462,
        x2:621,
        y2:702,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a45',
        addPoints:true,
    },
    {
        idquestion:517,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę kosa... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/kos.png'),
        alt:'Ilustracja kosa',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/kos.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/kos_zuraw.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/kos_zuraw_bielik.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'kos',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:518,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukryła się traszka zwyczajna... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f12.jpg'),
        alt:'Ilustracja żurawia, traszki zwyczajnej i kosa na tle mokreadeł',
        x1:771,
        y1:907,
        x2:881,
        y2:961,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a37',
        addPoints:true,
    },
    {
        idquestion:519,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę żurawia... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/zuraw.png'),
        alt:'Ilustracja żurawia',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/zuraw.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/zuraw_kos.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/zuraw_kos_bielik.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'żuraw',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:520,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się kos... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f12.jpg'),
        alt:'Ilustracja żurawia, traszki zwyczajnej i kosa na tle mokreadeł',
        x1:823,
        y1:138,
        x2:888,
        y2:198,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a17',
        addPoints:true,
    },
    /** 13. Świętokrzyski Park Narodowy 521 - 525*/
    {
        idquestion:521,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się jeleń szlachetny... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f13.jpg'),
        alt:'Ilustracja jelenia szlachetnego, ropuchy zielonej i dzika na zalesionym, kamienistym zboczu',
        x1:116,
        y1:437,
        x2:287,
        y2:640,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a14',
        addPoints:true,
    },
    {
        idquestion:522,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/13_logo.webp'),
        alt:'Logo Świętokrzyskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:523,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukryła się ropucha zielona... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f13.jpg'),
        alt:'Ilustracja jelenia szlachetnego, ropuchy zielonej i dzika na zalesionym, kamienistym zboczu',
        x1:855,
        y1:435,
        x2:936,
        y2:503,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a31',
        addPoints:true,
    },
    {
        idquestion:524,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę jelenia... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/jelen.png'),
        alt:'Ilustracja jelenia',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/jelen.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/jelen_derkacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/jelen_derkacz_puchacz.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'jeleń',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:525,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się dzik... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f13.jpg'),
        alt:'Ilustracja jelenia szlachetnego, ropuchy zielonej i dzika na zalesionym, kamienistym zboczu',
        x1:290,
        y1:633,
        x2:390,
        y2:732,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a9',
        addPoints:true,
    },
    /** 14. Roztoczański Park Narodowy 526 - 530*/
    {
        idquestion:526,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę zaskrońca zwyczajnego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/zaskroniec_zwyczajny.png'),
        alt:'Ilustracja zaskrońca zwyczajnego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/zaskroniec.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/zaskroniec_myszolow.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/zasrkoniec_myszolow_puszczyk.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], //interwal
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'zaskroniec zwyczajny',//nazwa wyświetlana na ekranie "Odgłosy"
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:528,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę gęś tundrową... czy uda Ci się nagrać jej odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/ges_tundrowa.png'),
        alt:'Ilustracja gęsi tundrowej',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/ges_tundrowa.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/ges_tundrowa_puchacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/ges_tundrowa_puchacz_batalion.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], //interwal
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'gęś tundrowa',//nazwa wyświetlana na ekranie "Odgłosy"
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:529,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę derkacza... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/derkacz.png'),
        alt:'Ilustracja derkacza',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/derkacz.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/derkacz_batalion.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/derkacz_batalion_czapla.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'derkacz',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    /** 15. Karkonoski Park Narodowy 531 - 535*/
    {
        idquestion:531,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/15_logo.webp'),
        alt:'Logo Karkonoskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:532,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się zaskroniec zwyczajny... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f15.jpg'),
        alt:'Ilustracja podróżniczka i zaskrońca zwyczajnego na tle karkonoskiej doliny',
        x1:640,
        y1:892,
        x2:817,
        y2:976,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a40',
        addPoints:true,
    },
    {
        idquestion:533,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę podróżniczka... czy uda Ci się nagrać jej odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/podrozniczek.png'),
        alt:'Ilustracja podróżniczka',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/podrozniczek.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/podrozniczek_lis.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/podrozniczek_lis_zaskroniec.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'podróżniczek',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:534,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/f7.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:535,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę zaskrońca zwyczajnego... czy uda Ci się nagrać jej odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/zaskroniec_zwyczajny.png'),
        alt:'Ilustracja zaskrońca zwyczajnego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/zaskroniec.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/zaskroniec_myszolow.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/zasrkoniec_myszolow_puszczyk.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'zaskroniec zwyczajny',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    /** 16. Park Narodowy Gór Stołowych 536 - 540*/
    {
        idquestion:536,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się puchacz... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f16.jpg'),
        alt:'Ilustracja puchacza, sokoła wędrownego i wilka na tle widoku Gór Stołowych',
        x1:234,
        y1:400,
        x2:311,
        y2:500,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a29',
        addPoints:true,
    },
    {
        idquestion:537,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę puchacza... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/puchacz.png'),
        alt:'Ilustracja puchacza',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/puchacz.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/puchacz_batalion.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/puchacz_batalion_pomurnik.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'puchacz',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:538,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się sokół wędrowny... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f16.jpg'),
        alt:'Ilustracja puchacza, sokoła wędrownego i wilka na tle widoku Gór Stołowych',
        x1:752,
        y1:321,
        x2:826,
        y2:415,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a35',
        addPoints:true,
    },
    {
        idquestion:539,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę sokoła wędrownego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/sokol_wedrowny.png'),
        alt:'Ilustracja sokoła wędrownego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/sokol_wedrowny.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/sokol_wedrowny_puszczyk.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/sokol_wedrowny_puszczyk_wilki.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'sokół wędrowny',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:540,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się wilk... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f16.jpg'),
        alt:'Ilustracja puchacza, sokoła wędrownego i wilka na tle widoku Gór Stołowych',
        x1:146,
        y1:599,
        x2:316,
        y2:785,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a38',
        addPoints:true,
    },
    /** 17. Ojcowski Park Narodowy 541 - 545*/
    {
        idquestion:541,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się gniewosz plamisty... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f17.jpg'),
        alt:'Ilustracja gniewosza plamistego i nietoperza na tle gęsto porośniętych zboczy Ojcowskiego Parku Narodowego',
        x1:125,
        y1:715,
        x2:219,
        y2:765,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a13',
        addPoints:true,
    },
    {
        idquestion:542,
        title: 'Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "gniewosz",
                image: require('../assets/images/animals/gniewosz_plamisty.png'),
                alt:'Gniewosz plamisty'
            },
            {
                type: "nietoperz",
                image: require('../assets/images/animals/nietoperz.png'),
                alt:'Nietoperz'
            },
            {
                type: "obuwik",
                image: require('../assets/images/animals/obuwik_pospolity.png'),
                alt:'Obuwik pospolity'
            },
            {
                type: "dzik",
                image: require('../assets/images/animals/dzik.png'),
                alt:'Dzik'
            },
            {
                type: "lis",
                image: require('../assets/images/animals/lis.png'),
                alt:'Lis'
            },
            {
                type: "bocian",
                image: require('../assets/images/animals/bocian_czarny.png'),
                alt:'Bocian czarny'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Spróbuj jeszcze raz!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:543,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się nietoperz... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f17.jpg'),
        alt:'Ilustracja gniewosza plamistego i nietoperza na tle gęsto porośniętych zboczy Ojcowskiego Parku Narodowego',
        x1:838,
        y1:380,
        x2:932,
        y2:467,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a24',
        addPoints:true,
    },
    {
        idquestion:544,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/17_logo.webp'),
        alt:'Logo Ojcowskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:545,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/f1.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    /** 18. Babiogórski Park Narodowy 546 - 550*/
    {
        idquestion:546,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę borsuka... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/borsuk.png'),
        alt:'Ilustracja borsuka',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/borsuk.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/borsuk_gluszec.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/borsuk_gluszec_myszolow.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'Borsuk',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:547,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się ryś... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f18.jpg'),
        alt:'Ilustracja rysia i borsuka na tle lasu',
        x1:796,
        y1:323,
        x2:826,
        y2:390,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a32',
        addPoints:true,
    },
    {
        idquestion:548,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/18_logo.webp'),
        alt:'Logo Babiogórskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:549,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę ziębę... czy uda Ci się nagrać jej odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/zieba.png'),
        alt:'Ilustracja zięby',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/zieba.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/zieba_gluszec.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/zieba_gluszec_kos.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'Zięba',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:550,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/2.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    /** 19. Gorczański Park Narodowy 551 - 555*/
    {
        idquestion:551,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/f3.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:552,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się dzięcioł trójpalczasty... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f19.jpg'),
        alt:'Ilustracja salamandry plamistej i dzięcioła trójpalczastego na tle lasu',
        x1:433,
        y1:227,
        x2:456,
        y2:298,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a8',
        addPoints:true,
    },
    {
        idquestion:553,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/19_logo.webp'),
        alt:'Logo Gorczańskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:554,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę dzięcioła trójpalczastego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/dzieciol_trojpalczasty.png'),
        alt:'Ilustracja dzięcioła trójpalczastego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/dzieciol_trojpalczasty.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/dziecioltrojpalczasty_puchacz.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/dziecioltrojpalczasty_puchacz_batalion.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'Dzięcioł trójpalczasty',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:555,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę ziębę... czy uda Ci się nagrać jej odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/zieba.png'),
        alt:'Ilustracja zięby',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/zieba.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/zieba_gluszec.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/zieba_gluszec_kos.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'Zięba',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    /** 20. Magurski Park Narodowy 556 - 560*/
    {
        idquestion:556,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/f3.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:557,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się ryś... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f20.jpg'),
        alt:'Ilustracja orlika krzykliwego, rysia i traszki zwyczajnej na tle łagodnych wzniesień Magurskiego Parku Narodowego',
        x1:199,
        y1:490,
        x2:291,
        y2:638,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a32',
        addPoints:true,
    },
    {
        idquestion:558,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukryła się traszka zwyczajna... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f20.jpg'),
        alt:'Ilustracja orlika krzykliwego, rysia i traszki zwyczajnej na tle łagodnych wzniesień Magurskiego Parku Narodowego',
        x1:713,
        y1:859,
        x2:838,
        y2:932,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a37',
        addPoints:true,
    },
    {
        idquestion:559,
        title: 'Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "orlik_krzykliwy",
                image: require('../assets/images/animals/orlik_krzykliwy.png'),
                alt:'Orlik krzykliwy'
            },
            {
                type: "rys",
                image: require('../assets/images/animals/rys.png'),
                alt:'Ryś'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            },
            {
                type: "zaskroniec_zwyczajny",
                image: require('../assets/images/animals/zaskroniec_zwyczajny.png'),
                alt:'Zaskroniec zwyczajny'
            },
            {
                type: "niedzwiedz",
                image: require('../assets/images/animals/niedzwiedz.png'),
                alt:'Niedźwiedź brunatny'
            },
            {
                type: "kuna",
                image: require('../assets/images/animals/kuna.png'),
                alt:'Kuna'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Spróbuj jeszcze raz!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:560,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/12.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    /** 21. Pieniński Park Narodowy 561 - 565*/
    {
        idquestion:561,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę pomurnika... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/pomurnik.png'),
        alt:'Ilustracja pomurnika',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/pomurnik.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/pomurnik_dzieciol_trojpalczasty.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/pomurnik_dzieciol_trojpalczasty_batalion.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'Pomurnik',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:562,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukryła się żmija zygzakowata... czy uda Ci się zrobić jej zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f21.jpg'),
        alt:'Ilustracja pomurnika i żmii zygzakowatej na tle Pienin',
        x1:203,
        y1:474,
        x2:399,
        y2:925,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a43',
        addPoints:true,
    },
    {
        idquestion:563,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/21_logo.webp'),
        alt:'Logo Pienińskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:564,
        title: 'Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "pomurnik",
                image: require('../assets/images/animals/pomurnik.png'),
                alt:'Pomurnik'
            },
            {
                type: "sosna",
                image: require('../assets/images/animals/sosna_pospolita.png'),
                alt:'Sosna pospolita'
            },
            {
                type: "zmija",
                image: require('../assets/images/animals/zmija_zygzakowata.png'),
                alt:'Żmija zygzakowata'
            },
            {
                type: "jelen",
                image: require('../assets/images/animals/jelen.png'),
                alt:'Jeleń'
            },
            {
                type: "wydra",
                image: require('../assets/images/animals/wydra.png'),
                alt:'Wydra'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Spróbuj jeszcze raz!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:565,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/3.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    /** 22. Bieszczadzki Park Narodowy 566 - 570*/
    {
        idquestion:566,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się niedźwiedź... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f22.jpg'),
        alt:'Ilustracja rysia i niedźwiedzia na tle lasu',
        x1:1,
        y1:380,
        x2:240,
        y2:615,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a23',
        addPoints:true,
    },
    {
        idquestion:567,
        title: 'Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "niedzwiedz",
                image: require('../assets/images/animals/niedzwiedz.png'),
                alt:'Niedźwiedź brunatny'
            },
            {
                type: "rys",
                image: require('../assets/images/animals/rys.png'),
                alt:'Ryś'
            },
            {
                type: "dzieciol",
                image: require('../assets/images/animals/dzieciol_czarny.png'),
                alt:'Dzięcioł czarny'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            },
            {
                type: "salamandra_plamista",
                image: require('../assets/images/animals/salamandra_plamista.png'),
                alt:'Salamandra plamista'
            },
            {
                type: "puchacz",
                image: require('../assets/images/animals/puchacz.png'),
                alt:'Puchacz'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Spróbuj jeszcze raz!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:568,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się ryś... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f22.jpg'),
        alt:'Ilustracja rysia i niedźwiedzia na tle lasu',
        x1:728,
        y1:614,
        x2:811,
        y2:716,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a32',
        addPoints:true,
    },
    {
        idquestion:569,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/22_logo.webp'),
        alt:'Logo Bieszczadzkiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:570,
        title: 'Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "niedzwiedz",
                image: require('../assets/images/animals/niedzwiedz.png'),
                alt:'Niedźwiedź brunatny'
            },
            {
                type: "rys",
                image: require('../assets/images/animals/rys.png'),
                alt:'Ryś'
            },
            {
                type: "dzieciol",
                image: require('../assets/images/animals/dzieciol_czarny.png'),
                alt:'Dzięcioł czarny'
            },
            {
                type: "traszka",
                image: require('../assets/images/animals/traszka_zwyczajna.png'),
                alt:'Traszka zwyczajna'
            },
            {
                type: "salamandra_plamista",
                image: require('../assets/images/animals/salamandra_plamista.png'),
                alt:'Salamandra plamista'
            },
            {
                type: "puchacz",
                image: require('../assets/images/animals/puchacz.png'),
                alt:'Puchacz'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Spróbuj jeszcze raz!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    /** 23. Tatrzański Park Narodowy 571 - 570*/
    {
        idquestion:571,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę zaskrońca zwyczajnego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/zaskroniec_zwyczajny.png'),
        alt:'Ilustracja zaskrońca zwyczajnego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/zaskroniec.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/zaskroniec_myszolow.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/zasrkoniec_myszolow_puszczyk.mp3')},
        ],
        type:'recordsound',
        interval:[4,15], //interwal
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'Zaskroniec zwyczajny',//nazwa wyświetlana na ekranie "Odgłosy"
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:572,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się niedźwiedź... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f23.jpg'),
        alt:'Ilustracja niedźwiedzia, zaskrońca zwyczajnego i kozicy na tle tatrzańskiego zbocza',
        x1:760,
        y1:100,
        x2:999,
        y2:560,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a23',
        addPoints:true,
    },
    {
        idquestion:573,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/23_logo.webp'),
        alt:'Logo Tatrzańskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:574,
        title:'Zobaczymy, czy masz dobre oko. Gdzieś nieopodal ukrył się zaskroniec zwyczajny... czy uda Ci się zrobić mu zdjęcie?<br/><br/><i class="fontsize">Ustaw kadr w odpowiednim miejscu, zablokuj go klikając dwukrotnie lewy przycisk myszy i zrób zdjęcie.</i>',
        src:require('../assets/images/quest/f23.jpg'),
        alt:'Ilustracja niedźwiedzia, zaskrońca zwyczajnego i kozicy na tle tatrzańskiego zbocza',
        x1:240,
        y1:840,
        x2:380,
        y2:950,
        width:1000,
        height:1000,
        type:'find',
        goodanswermessage:'Brawo! Doskonałe zdjęcie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        animalID:'a40',
        addPoints:true,
    },
    {
        idquestion:575,
        title: 'Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "kozica",
                image: require('../assets/images/animals/kozica.png'),
                alt:'Kozica'
            },
            {
                type: "niedzwiedz",
                image: require('../assets/images/animals/niedzwiedz.png'),
                alt:'Niedźwiedź brunatny'
            },
            {
                type: "zaskroniec",
                image: require('../assets/images/animals/zaskroniec_zwyczajny.png'),
                alt:'Zaskroniec zwyczajny'
            },
            {
                type: "dzieciol",
                image: require('../assets/images/animals/dzieciol_trojpalczasty.png'),
                alt:'Dzięcioł trójpalczasty'
            },
            {
                type: "wilk",
                image: require('../assets/images/animals/wilk.png'),
                alt:'Wilk'
            },
            {
                type: "puchacz",
                image: require('../assets/images/animals/puchacz.png'),
                alt:'Puchacz'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Spróbuj jeszcze raz!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },

    /** Kolejne dodatkowe */
    {
        idquestion:576,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę wilka... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/wilk.png'),
        alt:'Ilustracja wilka',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/wilk.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/wilk_sokol_wedrowny.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/wilk_sokol_wedrowny_puszczyk.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'Wilk',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:577,
        title:'Kwiatowym zwiastunem wiosny na gorczańskich polanach jest:',
        answers:[{
            id: '1',
            label: 'krokus, czyli szafran spiski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'sasanka zwyczajna',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'przylaszczka pospolit',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'szarotka alpejska',
            value: 'option4',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:578,
        title:'Salamandra plamista w odróżnieniu od innych krajowych płazów:',
        answers:[{
            id: '1',
            label: 'gody odbywa na lądzie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'posiada cztery kończyny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'żywi się bezkręgowcami takimi jak: dżdżownice, nagie ślimaki i larwy owadów',
            value: 'option3',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:579,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/20_logo.webp'),
        alt:'Logo Magurskiego Parku Narodowego',
        time: [30,40,30], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:580,
        title:'Jaką nazywę nosi rzeka, płynąca po terenach Pienińskiego Parku Narodowym, po której możemy spłynąć tratwą?',
        answers:[{
            id: '1',
            label: 'Dunajec',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Wisła',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Biały Potok',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Odra',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'Krośnica',
            value: 'option5',
            color:'#f08733',
            selected:false 
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:581,
        title:'Bieszczadzki Park Narodowy, jest największym górskim parkiem narodowym w Polsce. Jaka jest powierzchnia parku?',
        answers:[{
            id: '1',
            label: 'dokładnie 29 200 ha',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'mniej niż 28 000 ha',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'dokładnie 35 000 ha',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'więcej niż 40 000 ha',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'dokładnie 15 500 ha',
            value: 'option5',
            color:'#f08733',
            selected:false 
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:582,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/8.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:583,
        title:'Budowę słynnej Carskiej Drogi w Biebrzańskim PN rozpoczęto w:',
        answers:[{
            id: '1',
            label: 'XIX wieku',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'XVIII wieku',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'XX wieku',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'XXI  wieku',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:584,
        title:'Zabytkowa brama do Rezerwatu Ścisłego:',
        answers:[{
            id: '1',
            label: 'oddziela ludzki świat kultury od świata rządzonego wyłącznie przez przyrodę',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'oddziela tereny przemysłowe od półnaturalnych (łąk)',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'została wykonana z drewna świerkowego',
            value: 'option3',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:585,
        title:'Zobaczymy, czy masz dobre ucho. Gdzieś nieopodal słyszę bociana czarnego... czy uda Ci się nagrać jego odgłos?<br/><br/><i class="fontsize">Odtwórz nagranie i nasłuchuj konkretnego zwierzęcia. Gdy je usłyszysz, rozpocznij nagrywanie.</i>',
        srcImage:require('../assets/images/animals/bocian_czarny.png'),
        alt:'Ilustracja bociana czarnego',
        srcSounds:[
            {levelgame:0,src:require('../assets/sounds/quest/record/bocian_czarny.mp3')},
            {levelgame:1,src:require('../assets/sounds/quest/record/bocian_czarny_kos.mp3')},
            {levelgame:2,src:require('../assets/sounds/quest/record/bocian_czarny_kos_gluszec.mp3')},
        ],
        type:'recordsound',
        interval:[4,15],//interwal 
        goodanswermessage:'Brawo! Doskonałe nagranie!',
        badanswermessage:'Niestety, tym razem nie udało się... Spróbuj jeszcze raz!',
        name:'bocian czarny',
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:586,
        title:'Buki i Jodły w Roztoczańskim Parku Narodowym osiągają imponujące rozmiary. Nierzadko dochodzące do...',
        answers:[{
            id: '1',
            label: '50 metrów wysokości',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '60 metrów wysokości',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '30 metrów wysokości',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '80 metrów wysokości',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: '64 metrów wysokości',
            value: 'option5',
            color:'#f08733',
            selected:false 
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:587,
        title:'Oto mapa, na której podobno zaznaczony jest skarb... niestety jak widzisz jest pocięta. Potrafisz ją poukładać?',
        type:'puzzle',
        src:require('../assets/images/quest/puzzle/5.webp'),
        alt:'Ilustracja mapy do ułożenia',
        time: [30,40,120], 
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:588,
        title:'Pamiętasz jak wygląda logo parku, w którym się znajdujesz? Jeżeli tak, to teraz przyda Ci się ta wiedza.<br/>Twoim zadaniem jest ułożyć z puzzli logo parku.',
        type:'puzzle',
        src:require('../assets/images/park/14_logo.webp'),
        alt:'Logo Roztoczańskiego Parku Narodowego',
        time: [30,40,30],
        goodanswermessage:'Znakomicie! Udało się!',
        badanswermessage:'Niestety nie udało się. Spróbuj jeszcze raz!',
        addPoints:true,
        answersconfig: [
            {answer:'', correct:true},
        ],
    },
    {
        idquestion:589,
        title: 'Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "konik_polski",
                image: require('../assets/images/animals/konik_polski.png'),
                alt:'Konik Polski'
            },
            {
                type: "obuwik_pospolity",
                image: require('../assets/images/animals/obuwik_pospolity.png'),
                alt:'Obuwik Pospolity'
            },
            {
                type: "nietoperz",
                image: require('../assets/images/animals/nietoperz.png'),
                alt:'Nietoperz'
            },
            {
                type: "gniewosz_plamisty",
                image: require('../assets/images/animals/gniewosz_plamisty.png'),
                alt:'Gniewosz plamisty'
            },
            {
                type: "bocian",
                image: require('../assets/images/animals/bocian_czarny.png'),
                alt:'Bocian czarny'
            },
            {
                type: "rys",
                image: require('../assets/images/animals/rys.png'),
                alt:'Ryś'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Spróbuj jeszcze raz!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },
    {
        idquestion:590,
        title: 'Pod kartami znajdują się wizerunki różnych zwierząt lub roślin występujących w polskich parkach narodowych. Czy potrafisz odnaleźć takie same ilustracje?',
        type:'memo',
        tiles:[
            {
                type: "jelen",
                image: require('../assets/images/animals/jelen.png'),
                alt:'Jeleń szlachetny'
            },
            {
                type: "ropucha_zielona",
                image: require('../assets/images/animals/ropucha_zielona.png'),
                alt:'Ropucha zielona'
            },
            {
                type: "dzik",
                image: require('../assets/images/animals/dzik.png'),
                alt:'Dzik'
            },
            {
                type: "bocian",
                image: require('../assets/images/animals/bocian_czarny.png'),
                alt:'Bocian czarny'
            },
            
            {
                type: "puchacz",
                image: require('../assets/images/animals/puchacz.png'),
                alt:'Puchacz'
            },
            {
                type: "jez",
                image: require('../assets/images/animals/jez.png'),
                alt:'Jeż'
            }
        ],
        goodanswermessage:'Świetnie Ci poszło!',
        badanswermessage:'Niestety, nie udało Ci się... Spróbuj jeszcze raz!',
        time: [60,50,40], //czas podawany w sekundach
        addPoints:true,
        answersconfig: [
            {answer:'',correct:true},
        ],
    },

    /** pytania na poziom sredniozaawansowany i zaawansowany */

    /** 1. Słowiński Park Narodowy */
    {
        idquestion:701,
        title:'Brodaczka to:',
        answers:[{
            id: '1',
            label: 'gatunek porostu wrażliwego na zanieczyszczenie dwutlenkiem siarki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'gatunek niewielkiego ptaka, posiadającego kępki piór przypominających "brodę"',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'gatunek owada z rodziny kózkowatych o rozbudowanych żuwaczkach',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'gatunek rośliny jednorocznej o liściach posiadających dużą ilość "włosków"',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:702,
        title:'Relikt glacjalny to określenie organizmu, który:',
        answers:[{
            id: '1',
            label: 'jest pozostałością po epoce lodowej',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'jest mikroorganizmem tworzącym przetrwalniki w arktycznym lodzie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'do rozrodu potrzebuje obniżonej temperatury środowiska',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żyje w cienkiej warstwie wody, która tworzy się na lodzie w wyniku roztapiania lodowców',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:703,
        title:'Bardzo charakterystyczną cechą ze względu na unikatowy charakter Słowińskiego Parku Narodowego jest obecność halofitów. Są to:',
        answers:[{
            id: '1',
            label: 'rośliny odporne na zasolenie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rośliny porastające wydmowe hale',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'drobne skorupiaki spotykane na plaży',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zwierzęta, które są odporne na zasoloną wodę',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:704,
        title:'Bażyny czarnej na pewno nie spotkamy w zbiorowisku roślinnym:',
        answers:[{
            id: '1',
            label: 'łęgu jesionowego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'torfowiska bałtyckiego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'boru nadmorskiego',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wydmy szarej',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:705,
        title:'Deflacja to:',
        answers:[{
            id: '1',
            label: 'wywiewanie, wynoszenie piasku przez wiatr',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'nawiewanie, zbieranie piasku przez wiatr',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'niszczenie przez uderzające ziarna piasku niesione przez wiatr',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'unieruchomienie nawianego materiału na wilgotnym podłożu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:706,
        title:'Przykładem ekotonu w Słowińskim Parku Narodowym jest:',
        answers:[{
            id: '1',
            label: 'wybrzeże',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'las',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wydmy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'łąka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:707,
        title:'Malina moroszka występująca w Słowińskim Parku Narodowym jest przykładem:',
        answers:[{
            id: '1',
            label: 'reliktu epoki lodowej',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rośliny ruderalnej',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'gatunku pionierskiego',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'efemerofita',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:708,
        title:'Bałtyk jest najsłodszym morzem świata, jego niskie zasolenie ma wpływ na występujące w nim organizmy, jedną z ich cech jest:',
        answers:[{
            id: '1',
            label: 'zmniejszenie rozmiarów ciała organizmów żyjących w środowisku o obniżonym zasoleniu',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'występowanie tych samych gatunków w różnych strefach zasolenia ekosystemu morskiego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'brak występowania gatunków pełnosłonych w ekosystemie morskim o niższym zasoleniu',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zwiększenie rozmiarów ciała organizmów żyjących w środowisku o obniżonym zasoleniu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:709,
        title:'Sieweczka obrożna podobnie jak rybitwa białoczelna zakładają gniazda bezpośrednio na piasku. Jej jaja charakteryzują się:',
        answers:[{
            id: '1',
            label: 'kryptycznym (maskującym) ubarwieniem',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'niezwykle wydłużonym kształtem',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'zgrubiałą skorupką',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'większą odpornością na uszkodzenia mechaniczne',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:710,
        title:'Sosny porastające wybrzeża czasem są określane jako "tańczący las" z uwagi na wygięte korony drzew, czynnik kształtujący ich pokrój to:',
        answers:[{
            id: '1',
            label: 'silne i porywiste wiatry sztormowe',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'długotrwałe łagodne wiatry od morza',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'niestabilne podłoże, którego główną część stanowi piasek',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'duże różnice temperatur w okresie letnim, kiedy temperatura piasku może przekraczać kilkaset stopni C, a w nocy intensywnie się ochładza',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 2. Wigierski Park Narodowy */
    {
        idquestion:711,
        title:'Skąd pochodzi nazwa jeziora Wigry i co oznacza?',
        answers:[{
            id: '1',
            label: 'wywodzi się z języka litewskiego i oznacza „kręte”',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wywodzi się z łaciny (nadana przez Kamedułów) i oznacza „Pełne uroku”',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wywodzi się z języka staropolskiego, od legendarnej Wigry',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wywodzi się z mowy Jaćwingów i oznacza „głębokie”',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:712,
        title:'Które rzeki przepływają przez WPN?',
        answers:[{
            id: '1',
            label: 'Czarna Hańcza, Kamionka, Wiatrołuża',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Czarna Hańcza, Szeszupa, Marycha',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Kamionka, Wiatrołuża, Rospuda',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Szeszupa, Czarna Hańcza, Rospuda',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:713,
        title:'Ptaki wodne występujące w Wigierskim Parku Narodowym to:',
        answers:[{
            id: '1',
            label: 'łyska, perkoz, kormoran',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'perkoz, zięba, łabędź',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'gągoł, kowalik, perkoz',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'perkoz, mysikrólik, lelek',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:714,
        title:'W Wigierskim Parku na torfowiskach nad Sucharami można zobaczyć następujące rośliny:',
        answers:[{
            id: '1',
            label: 'rosiczka, wełnianka, bagno',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'bagno, jastrzębiec, bobrek',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wełnianka, przylaszczka, kopytnik',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zawilec, bagno, grążel',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:715,
        title:'Sieja i sielawa to ryby żyjące w specyficznych warunkach:',
        answers:[{
            id: '1',
            label: 'lubią wody głębokie, zimne, o dużej ilości tlenu',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'lubią wody głębokie, ciepłe, o dużej ilości tlenu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'lubią wody płytkie, ciepłe, o małej ilości tlenu',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'lubią wody płytkie, zimne, o małej ilości tlenu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:716,
        title:'Jezioro Wigry jest, w stosunku do pozostałych jezior Polski:',
        answers:[{
            id: '1',
            label: 'dziesiąte pod względem powierzchni i piąte pod względem głębokości',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'ósme pod względem powierzchni i dziewiąte pod względem głębokości',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'trzecie pod względem powierzchni i pierwsze pod względem głębokości',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'szóste pod względem powierzchni i siódme pod względem głębokości',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:717,
        title:'Ile jest jezior w granicach WPN?',
        answers:[{
            id: '1',
            label: '42, w tym  Wigry, Białe, Suchary',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '17, w tym Wigry, Pierty, Długie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '38, w tym Wigry, Suchary, Płaskie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '8, w tym Wigry, Okrągłe, Leszczewek',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:718,
        title:'Występująca licznie na Wigrach łyska jest blisko spokrewniona z',
        answers:[{
            id: '1',
            label: 'żurawiem',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'gągołem',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kaczką krzyżówka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'czaplą',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:719,
        title:'Wody sucharów mają charakterystyczną barwę i pH',
        answers:[{
            id: '1',
            label: 'są ciemne, brunatne i mają pH kwaśne',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'są przejrzyste niebieskie i mają pH kwaśne',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'są ciemne, brunatne i mają pH zasadowe',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'są turkusowe i mają pH obojętne',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:720,
        title:'Sieja i sielawa należą do ryb:',
        answers:[{
            id: '1',
            label: 'łososiowatych',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'stynkowatych',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'szczupakowatych',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'karpiowatych',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 3. Woliński Park Narodowy */
    {
        idquestion:721,
        title:'Zielony szlak turystyczny Wolińskiego Parku Narodowego nie doprowadzi nas do:',
        answers:[{
            id: '1',
            label: 'Jeziora Turkusowego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Zagrody Pokazowej Żubrów',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Jeziora Czajczego',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wsi Warnowo',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:722,
        title:'Część ciała bielika, która odróżnia go od orła to:',
        answers:[{
            id: '1',
            label: 'nieopierzone skoki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'brązowe lotki na skrzydłach',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'zagięty haczykowato dziób',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'masywne szpony',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:723,
        title:'Wzgórze Zielonka położone jest we wsi:',
        answers:[{
            id: '1',
            label: 'Lubin',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Wapnica',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Wisełka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Warnowo',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:724,
        title:'Rośliną, która nie występuje na plaży jest:',
        answers:[{
            id: '1',
            label: 'babka zwyczajna',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'piaskownica zwyczajna',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'rukwiel nadmorska',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'piaskownica zwyczajna',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:725,
        title:'Największy, parzystokopytny, roślinożerny ssak występujący w lasach wyspy Wolin to:',
        answers:[{
            id: '1',
            label: 'żubr',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'łoś',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jeleń',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'sarna',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:726,
        title:'Zwyczajowa nazwa węglanu wapnia występującego w Jeziorze Turkusowym to:',
        answers:[{
            id: '1',
            label: 'kalcyt',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'krzemian',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'gips',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'dolomit',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:727,
        title:'Obszar Wolińskiego Parku Narodowego, na którym występuje blisko 240 gatunków ptaków to:',
        answers:[{
            id: '1',
            label: 'wyspy wstecznej delty Świny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'jeziora Czajcze i Warnowskie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'lasy bukowe i mieszane Wolina',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wody Zatoki Pomorskiej',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:728,
        title:'Obok buka zwyczajnego jest głównym gatunkiem drzewa liściastego  występującym w wolińskich buczynach:',
        answers:[{
            id: '1',
            label: 'dąb bezszypułkowy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dąb szypułkowy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'brzoza brodawkowata',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'klon zwyczajny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:729,
        title:'Gatunek morskiego małża o ciemnej prawie czarnej muszli, będącego przysmakiem kaczek i łysek to:',
        answers:[{
            id: '1',
            label: 'omułek jadalny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'sercówka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'małgiew piaskołaz',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'rogowiec bałtycki',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:730,
        title:'Wzgórze, skąd widać zarówno Zatokę Pomorską, jak i rozlewiska wstecznej Delty Świny, Zalew Szczeciński i wody Jeziora Wicko Wielkie to:',
        answers:[{
            id: '1',
            label: 'Zielonka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Gosań',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Grzywacz',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Piaskowa Góra',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 4. Park Narodowy „Bory Tucholskie” */
    {
        idquestion:731,
        title:'Park Narodowy „Bory Tucholskie” został utworzony ze względu na:',
        answers:[{
            id: '1',
            label: 'siedliska oligotroficzne',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dziko żyjące łosie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'liczne stanowiska szarotki alpejskiej',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żadna z odpowiedzi nie jest prawidłowa',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:732,
        title:'Park Narodowy „Bory Tucholskie” stanowi strefę rdzenną Rezerwatu Biosfery Bory Tucholskie, który jest jednym z:',
        answers:[{
            id: '1',
            label: '11 rezerwatów biosfery w Polsce',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '9 rezerwatów biosfery w Polsce',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '10 rezerwatów biosfery w Polsce',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '12 rezerwatów biosfery w Polsce',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:733,
        title:'Najgłębszym jeziorem położonym na terenie Parku Narodowego „Bory Tucholskie” jest jezioro:',
        answers:[{
            id: '1',
            label: 'Ostrowite',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Charzykowskie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Nierybno',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Gacno Wielkie',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:734,
        title:'Kaszubska Marszruta to:',
        answers:[{
            id: '1',
            label: 'system ścieżek rowerowych znajdujących się na terenie powiatu chojnickiego i przebiegających przez Park Narodowy „Bory Tucholskie”',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'nazwa rajdu odbywającego się cyklicznie w Parku Narodowym „Bory Tucholskie”',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'tradycyjna kaszubska potrawa',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'tradycyjna kaszubska nalewka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:735,
        title:'Ile jezior występuje na terenie Parku Narodowym „Bory Tucholskie”:',
        answers:[{
            id: '1',
            label: '21',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '17',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '19',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '25',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:736,
        title:'85,2% powierzchni Parku Narodowego „Bory Tucholskie” stanowią lasy, w tym największy udział mają:',
        answers:[{
            id: '1',
            label: 'bory świeże i suche',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'grądy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'buczyny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'dąbrowy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:737,
        title:'Obszar na terenie Parku Narodowego „Bory Tucholskie”, na którym obowiązuje zasada całkowitego zaniechania ingerencji człowieka w stan ekosystemów i składników przyrody to:',
        answers:[{
            id: '1',
            label: 'obszar ochrony ścisłej',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rezerwat przyrody',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'obszar ochrony częściowej',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'obszar ochrony krajobrazowej',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:738,
        title:'W Parku Narodowym „Bory Tucholskie” szczególnie bogaty jest świat porostów. Do tej grupy organizmów nie zalicza się:',
        answers:[{
            id: '1',
            label: 'nasięźrzał pospolity',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'płucnica islandzka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'chrobotek reniferowy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'brodaczka zwyczajna',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:739,
        title:'W Parku Narodowym „Bory Tucholskie” występują również jeziora dystroficzne. Są to z reguły niewielkie bezodpływowe zbiorniki wodne, położone najczęściej w borach w bezpośrednim sąsiedztwie torfowisk wysokich lub przejściowych. Charakterystyczną cechą tych jezior jest:',
        answers:[{
            id: '1',
            label: 'obecność w ich wodach kwasów humusowych',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'duża przeźroczystość',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'występowanie lobelii jeziornej',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żadna z odpowiedzi nie jest prawidłowa',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:740,
        title:'Na terenie Parku Narodowego „Bory Tucholskie” znajdują się również siedliska antropogeniczne mające duże znaczenia dla zachowania różnorodności biologicznej, są to:',
        answers:[{
            id: '1',
            label: 'wrzosowiska',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'jeziora dystroficzne',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jeziora lobeliowe',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'torfowiska',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 5. Biebrzański Park Narodowy */
    {
        idquestion:741,
        title:'Obuwik pospolity, kukułka krwista, buławnik czerwony to:',
        answers:[{
            id: '1',
            label: 'storczyki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'grzyby',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'paprotniki',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'mszaki',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:742,
        title:'Jak brzmi oficjalne hasło promujące Biebrzański Park Narodowy?',
        answers:[{
            id: '1',
            label: 'Biebrzański Park Narodowy – w trosce o bagna i ludzi',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Biebrzański Park Narodowy – w trosce o bagna',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Biebrzański Park Narodowy – mokradła dla przyszłości',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Biebrzański Park Narodowy – Bagna są Wielkie',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:743,
        title:'Największe latające owady drapieżne występujące na bagnach biebrzańskich to...',
        answers:[{
            id: '1',
            label: 'ważki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'chrząszcze',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'szerszenie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'gzy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:744,
        title:'Biebrzański Park Narodowy chroni 3 rodzaje torfowisk. Powierzchniowo dominują tu torfowiska:',
        answers:[{
            id: '1',
            label: 'niskie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wysokie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'przejściowe',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wysokie i przejściowe',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:745,
        title:'Nazywany jest rycerzem bagien. Ozdobą samca jest ogon w kształcie liry. Dawniej był często widywany na biebrzańskich bagnach, obecnie jego liczebność gwałtownie spada. Opisany gatunek to:',
        answers:[{
            id: '1',
            label: 'cietrzew',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'batalion',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'głuszec',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'bażant',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:746,
        title:'Jednym z najistotniejszych drapieżników zagrażającym rzadkim ptakom lęgowym w dolinie Biebrzy, należącym do gatunków inwazyjnych jest:',
        answers:[{
            id: '1',
            label: 'norka amerykańska',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'norka europejska',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'borsuk',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'piżmak',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:747,
        title:'Rzeka Biebrza ma długość około:',
        answers:[{
            id: '1',
            label: '150 km',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '50 km',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '300 km',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '500 km',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:748,
        title:'Największy z terenów bagiennych w Obrębie Ochronnym Basenu Dolnego nosi nazwę:',
        answers:[{
            id: '1',
            label: 'Bagno Ławki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Wilcze Bagno',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Czerwone Bagno',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Biebrzańskie Błota',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:749,
        title:'Słowo kryza oznacza:',
        answers:[{
            id: '1',
            label: 'barwny kołnierz z piór wokół szyi samców batalionów w porze godowej',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'kolorowe upierzenie piersi u samca podróżniczka w okresie wiosennym',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'fragment wałów na Biebrzy, chroniących przed wiosennymi rozlewiskami',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'chłodny powiew wiatru znad bagien',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:750,
        title:'Liczbę łosi żyjących w Biebrzańskim PN szacuje się na około:',
        answers:[{
            id: '1',
            label: '600',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '100',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '900',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '400',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 6. Białowieski Park Narodowy */
    {
        idquestion:751,
        title:'Śmiertelnie trującym grzybem jest:',
        answers:[{
            id: '1',
            label: 'muchomor zielonawy (sromotnikowy)',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'sromotnik bezwstydny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'gołąbek zielonawy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'pieczarka łąkowa',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:752,
        title:'Łąki w dolinach rzecznych i na polanach śródleśnych są środowiskiem życia:',
        answers:[{
            id: '1',
            label: 'wszystkich wymienionych stworzeń',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'żubrów',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wielu gatunków ptaków',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'motyli, chrząszczy i wielu innych owadów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:753,
        title:'Białowieski Park Narodowy charakteryzuje się:',
        answers:[{
            id: '1',
            label: 'dużą różnorodnością gatunków zwierząt zagrożonych wyginięciem',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dużą różnorodnością pospolitych gatunków zwierząt',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'dużą różnorodnością obcych gatunków zwierząt',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'małą różnorodnością gatunków zwierząt zagrożonych wyginięciem',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:754,
        title:'Rezerwaty hodowlane:',
        answers:[{
            id: '1',
            label: 'służą rozmnażaniu żubrów o znanym pochodzeniu',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'funkcjonują od 1970 roku',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'służą rozmnażaniu żubroni i innych mieszańców międzygatunkowych',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'są otwarte dla wszystkich zwiedzających',
            value: 'option4',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:755,
        title:'Martwe i dziuplaste drzewa są:',
        answers:[{
            id: '1',
            label: 'niezbędnym środowiskiem życia licznych organizmów',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'oznaką choroby lasu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'elementem nie mającym znaczenia dla zwierząt',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'elementem nie mającym znaczenia dla roślin i grzybów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:756,
        title:'Koszenie łąk w dolinach rzecznych i na polanach śródleśnych:',
        answers:[{
            id: '1',
            label: 'zapobiega ich zarastaniu i utrzymuje „bycie” łąką',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wykonuje się w terenach objętych zarówno ochroną czynną jak i ścisłą',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wykonuje się raz w tygodniu',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wykonuje się wyłącznie zimą',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:757,
        title:'Bogactwo gatunkowe organizmów w Białowieskim Parku Narodowym jest związane z:',
        answers:[{
            id: '1',
            label: 'dużą ilością drzewostanów naturalnych z martwymi drzewami',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'zagospodarowaniem lasu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wprowadzeniem przez człowieka wielu obcych gatunków zwierząt',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'licznymi terenami otwartymi występującymi w lesie',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:758,
        title:'Obecność martwego drewna w lesie jest konieczna dla:',
        answers:[{
            id: '1',
            label: 'zachowania bogactwa gatunkowego zwierząt, roślin i grzybów',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'utrzymania wysokiego poziomu wód gruntowych',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wypełnienia przestrzeni',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'stworzenia warstwowej budowy lasu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:759,
        title:'Wybierz prawidłową odpowiedź:',
        answers:[{
            id: '1',
            label: 'głos żubrów nazywamy chruczeniem',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'żubry to największe i naj głośniejsze zwierzęta Puszczy Białowieskiej',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'okres godowy żubrów nazywamy rykowiskiem',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'rogi żubrów występują wyłącznie u samców',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:760,
        title:'Najmniejszą sową Puszczy Białowieskiej jest:',
        answers:[{
            id: '1',
            label: 'sóweczka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'puchacz',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'puszczyk',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'włochatka',
            value: 'option4',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 7. Drawieński Park Narodowy */
    {
        idquestion:761,
        title:'Jezioro Czarne należy do nielicznych w Polsce, w których zachodzi zjawisko meromiksji. Ile jest w Polsce takich jezior?',
        answers:[{
            id: '1',
            label: '3',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '2',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '5',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '4',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:762,
        title:'Największy Głaz na terenie DPN (tzw. Wydrzy Głaz) waży:',
        answers:[{
            id: '1',
            label: '45,5 tony',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '54,4 tony',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '29 ton',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '33,5 tony',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:763,
        title:'W czasie ostatniego zlodowacenia lądolód na terenie DPN przyjął niezwykle imponujące rozmiary. Pokrywa lodu osiągnęła wysokość:',
        answers:[{
            id: '1',
            label: '1500 m',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '1350 m',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '950 m',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '150 m',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:764,
        title:'Wydrzy Głaz jest:',
        answers:[{
            id: '1',
            label: 'głazem narzutowym',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'głazem wyrzutowym',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'głazem odrzutowym',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'głazem podrzutowym',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:765,
        title:'Teren DPN to krajobraz:',
        answers:[{
            id: '1',
            label: 'młodoglacjalny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'staro polodowcowy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'młodo pomorski',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wczesno pojezierny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:766,
        title:'Stara Węgornia na rzece Płocicznej powstała około roku:',
        answers:[{
            id: '1',
            label: '1820',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '1918',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '1974',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '1990',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:767,
        title:'Unikatowe na terenie DPN zjawisko zanikania wody pod ziemię to:',
        answers:[{
            id: '1',
            label: 'ponor',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'parka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kromp',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'karpa',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:768,
        title:'Stare pnie „drzew życia” na terenie DPN to:',
        answers:[{
            id: '1',
            label: 'zabytkowe nagrobki na dawnych cmentarzach',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pnie, z których pobiera się materiał DNA do badań',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'stare pnie rzadkich gatunków drzew',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'pnie, na których żyją rzadkie gatunki grzybów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:769,
        title:'Elektrownia Wodna „Kamienna” leży przy:',
        answers:[{
            id: '1',
            label: 'Ścieżce dydaktycznej „Głusko”',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Ścieżce dydaktycznej „Jezioro Ostrowieckie”',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Szlaku historycznym „Kaskada Drawy”',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Ścieżce „Szlak historii Drawy”',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:770,
        title:'Tragankowe Urwisko wzięło swą nazwą od gatunków roślin. Których?',
        answers:[{
            id: '1',
            label: 'traganek piaskowy i traganek duński',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'traganek północny i traganek polski',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'traganek drawski i traganek południowy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'traganek zwyczajny i traganek wodny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 8. Narwiański Park Narodowy */
    {
        idquestion:771,
        title:'Narew jest typem rzeki anostomozującej. Oznacza to, że nie posiada jednego koryta, ale jej wody płyną jakby warkoczami (wieloma korytami przecinającymi się). Do jakiej rzeki jest często porównywana...',
        answers:[{
            id: '1',
            label: 'Amazonki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Missisipi',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Wołgi',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Gangesu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'Nilu',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:772,
        title:'Jakie cechy charakterystyczne mają ptaki brodzące?',
        answers:[{
            id: '1',
            label: 'długie dzioby i długie nogi',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'haczykowate dzioby i szpony',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'płaskie dzioby i palce spięte błoną pławną',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'krótkie, mocne dzioby i nogi o palcach zakończonych tępymi pazurami',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:773,
        title:'W sytuacji zagrożenia ptak bąk potrafi ukryć się w trzcinie. Jak się wówczas zachowuje:',
        answers:[{
            id: '1',
            label: 'przyjmuje wyprostowaną pozycję z szyją pionowo wyciągniętą ku górze',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'kuli się',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ucieka pieszo',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zamiera w bezruchu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:774,
        title:'Jak nazywa się ptak o zielonych nogach, który żyje na terenie Narwiańskiego  Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'zielonka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'zielnik',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'zieleniak',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zielonek',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'zielik',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:775,
        title:'Gdzie gniazduje zwierzę widoczne na ilustracji?',
        type:'open',
        src:require('../assets/images/animals/blotniak_stawowy.png'),
        alt:'Ilustracja błotniaka stawowego',
        answersconfig:[
            {answer:'w trzcinowiskach',correct:true},
            {answer:'trzcinowiskach',correct:true},
            {answer:'trzcinie',correct:true},
            {answer:'trzcinowisko',correct:true},
            {answer:'trzciny',correct:true},
            {answer:'w trzcinach',correct:true},
            {answer:'w trzcinie',correct:true},
        ],
        hints:[
            {levelgame:0, hint:''},
            {levelgame:1, hint:'Podpowiedź: Ptak ten jest spotykany na terenach podmokłych.'},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:776,
        title:'Narew jest typem rzeki anastomozującej. Z podanych odpowiedzi wybierz jej cechy charakterystyczne:',
        answers:[{
            id: '1',
            label: 'rzeka z wieloma łączącymi się i przecinającymi korytami',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rzeka płynąca prostym korytem',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'rzeka o krętym korycie, dużej liczbie zakoli i starorzeczy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'rzeka o wielu dopływach',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'rzeka okresowa występująca w sezonie letnim',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:777,
        title:'Tokowanie u ptaków oznacza...',
        answers:[{
            id: '1',
            label: 'zmiana ubarwienia, wydawanie specyficznych dźwięków i wykonywanie charakterystycznych ruchów przez samca w okresie godowym',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wicie przez samice gniazda w okresie lęgowym',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'nawoływanie przez samice samca w sytuacji zagrożenia piskląt',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wicie przez samca gniazda w okresie lęgowym',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'podkładanie swoich jaj do gniazd innych gatunków',
            value: 'option5',
            color:'#f08733',
            selected:false
        }
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:778,
        title:'Do jakiej rodziny należy bąk:',
        answers:[{
            id: '1',
            label: 'czaplowate',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'żurawiowe',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jastrzębiowate',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'kaczkowate',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'bekasowate',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:779,
        title:'Jak nazywa się ptak o żółtych nogach, którego spotykamy na terenie Narwiańskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'bielik',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'biel',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'bielmo',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'bielak',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'biedronka',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:780,
        title:'Błotniak stawowy charakteryzuje się różnicą w ubarwieniu samca i samicy. Brązowy tułów, jasna głowa, popielatoszare skrzydła i ogon występują u:',
        type:'open',
        src:require('../assets/images/animals/blotniak_stawowy.png'),
        alt:'Ilustracja błotniaka stawowego',
        answersconfig:[
            {answer:'samca',correct:true},
            {answer:'samiec',correct:true}
        ],
        hints:[
            {levelgame:0, hint:''},
            {levelgame:1, hint:''},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 9. Park Narodowy „Ujście Warty” */
    {
        idquestion:781,
        title:'Powierzchnia Parku Narodowego „Ujście Warty” wynosi około 8 000 ha. Ile to km²?',
        answers:[{
            id: '1',
            label: '80 km²',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '8 km²',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '160 km²',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '8000 km²',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:782,
        title:'Gęś tundrową – symbol Parku Narodowego „Ujście Warty” można zobaczyć tutaj:',
        answers:[{
            id: '1',
            label: 'podczas jesienniej i wiosennej migracji',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'tylko podczas sezonu lęgowego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'cały rok',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'ten ptak nie występuje w Parku',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:783,
        title:'Zaskroniec to:',
        answers:[{
            id: '1',
            label: 'gad',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'płaz',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ptak',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'ssak',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:784,
        title:'Do jakiej rzeki uchodzi Warta, w okolicach Parku Narodowego „Ujście Warty”?',
        answers:[{
            id: '1',
            label: 'Odry',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Wisły',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Narwi',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Warta wpada bezpośrednio do Bałtyku',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:785,
        title:'Park Narodowy „Ujście Warty” powstał aby chronić przede wszystkim:',
        answers:[{
            id: '1',
            label: 'podmokłe tereny, ważne dla ptaków wodno-błotnych',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'stare dęby – miejsce lęgowe puszczyka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'deltę Warty, wpadającej do Bałtyku',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'górskie źródło Warty',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:786,
        title:'Park Narodowy „Ujście Warty” został wpisany na listę obszarów Konwencji Ramsar ponieważ:',
        answers:[{
            id: '1',
            label: 'tutejsze mokradła są ważnym miejscem dla ptaków podczas lęgów i migracji',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'tutejsze liczne drzewa są ważnym miejscem lęgowym dla ptaków',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ptaki podczas migracji zatrzymują się na tutejszych, odsłoniętych stokach górskich',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'tereny podmokłe Parku są ważną ostoją łosia',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:787,
        title:'Punkt widokowy „Strefa” to świetne miejsce na obserwacje migrujących gęsi i żurawi. Kiedy najlepiej się tam wybrać aby zobaczyć te ptaki?',
        answers:[{
            id: '1',
            label: 'Jesienią o wschodzie słońca – wtedy, po nocy spędzonej w Parku, wylatują na łąki aby się najeść.',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Latem w samo południe – wtedy wygrzewają się w gorących promieniach słońca.',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Zimą kiedy leży śnieg – wtedy chętnie wychodzą ze swoich nor.',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Wiosną w środku nocy – wtedy trzeba podejść do nich z latarką aby je obudzić.',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:788,
        title:'Derkacze to ptaki, które gniazdują na wilgotnych łąkach. Są skryte i bardzo trudno je zobaczyć. Jednak ich obecność zdradza głos, który składa się z dwóch sylab „der-der”. Derkacze odzywają się aby:',
        answers:[{
            id: '1',
            label: 'poinformować inne derkacze o swoim terytorium',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'zwabić małe gryzonie, na które polują',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wprowadzić w drgania wodę z gleby, aby mogły się napić',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'odstraszyć drapieżniki',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:789,
        title:'„Ekologiczne kosiarki” czyli stada krów i koni, wypasają się w Parku Narodowym „Ujście Warty” aby:',
        answers:[{
            id: '1',
            label: 'roślinność tutejszych łąk była odpowiednio niska dla gniazdujących tu ptaków',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'tutejsze nadmorskie plaże nie zarastały trawą',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'tutejsze stoki górskie pozostały gołe',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'udeptywać podłoże aby było idealnie płaskie',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:790,
        title:'Gęsi tundrowe zakładają gniazda:',
        answers:[{
            id: '1',
            label: 'na podmokłych terenach północnej Azji',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'w polskich górach',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'na wybrzeżach południowej Europy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'na wyspach Ameryki Środkowej',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 10. Kampinoski Park Narodowy */
    {
        idquestion:791,
        title:'Podaj powierzchnię Kampinoskiego Parku Narodowego:',
        answers:[{
            id: '1',
            label: '38,5 tys. ha',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '8,5 tys. ha',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '65,5 tys. ha',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '105,5 ha',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:792,
        title:'Jaki gatunek drzewa jest najliczniej reprezentowany na terenie Puszczy Kampinoskiej?',
        answers:[{
            id: '1',
            label: 'sosna pospolita',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dąb szypułkowy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'brzoza brodawkowata',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'buk',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:793,
        title:'Zaskroniec, który uwielbia kampinoskie bagna, łąki i lasy jest:',
        answers:[{
            id: '1',
            label: 'gadem',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'płazem',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ssakiem',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'owadem',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:794,
        title:'Jak nazywają się dwa największe drapieżniki w Kampinoskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'ryś, wilk',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'kuna, ryś',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ryś, niedźwiedź',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'borsuk, wilk',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:795,
        title:'Kampinoski Park Narodowy charakteryzuje się krajobrazem:',
        answers:[{
            id: '1',
            label: 'bagien i wydm',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rzek i bagien',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wydm i jezior',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'gór i bagien',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:796,
        title:'W Puszczy Kampinoskiej reintrodukowano następujące gatunki zwierząt:',
        answers:[{
            id: '1',
            label: 'łoś, ryś, bóbr',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'łoś, pustułka, ryś',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'pliszka, bocian czarny, łoś',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wilk, łoś, niedźwiedź',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:797,
        title:'W pradolinie jakiej rzeki znajduje się Kampinoski Park Narodowy?',
        answers:[{
            id: '1',
            label: 'Wisły',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Odry',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Narwi',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Bugu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:798,
        title:'Jaka grupa zwierząt jest najliczniej reprezentowana na terenie Kampinoskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'bezkręgowce',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'ssaki',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ptaki',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'gady',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:799,
        title:'Jaki procent powierzchni Kampinoskiego Parku Narodowego stanowią lasy?',
        answers:[{
            id: '1',
            label: 'ok. 75 %',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'ok. 95%',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ok. 45%',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'ok. 20%',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:800,
        title:'Gdzie na terenie KPN znajduje się cmentarz – mauzoleum ofiar akcji AB przeprowadzonej przez nazistów podczas II wojny światowej?',
        answers:[{
            id: '1',
            label: 'Palmiry',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Lipków',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Kampinos',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Zaborów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 11. Wielkopolski Park Narodowy */
    {
        idquestion:801,
        title:'Symbolem Wielkopolskiego Parku Narodowego jest sowa:',
        answers:[{
            id: '1',
            label: 'puszczyk zwyczajny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'puchacz',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'sóweczka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'puszczyk uralski',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:802,
        title:'Wielkopolski Park Narodowy został utworzony ze względu na:',
        answers:[{
            id: '1',
            label: 'rozmaite formy krajobrazu polodowcowego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'bliskość aglomeracji Poznania',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'nagromadzenie pomników przyrody',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'liczne atrakcje turystyczne',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:803,
        title:'Inicjatorem założenia Wielkopolskiego Parku Narodowego był:',
        answers:[{
            id: '1',
            label: 'Adam Wodziczko',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Adam Mickiewicz',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Ignacy Mościcki',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Adam Loret',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:804,
        title:'Wizytówką Wielkopolskiego Parku Narodowego jest:',
        answers:[{
            id: '1',
            label: 'rynnowe Jezioro Góreckie z Wyspą Zamkową',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'ruchoma wydma Łącka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jezioro lobeliowe Gacno Wielkie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'połonina Szerokiego Wierchu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:805,
        title:'Która rzeka na terenie Parku odpowiedzialna jest za utworzenie starorzecza:',
        answers:[{
            id: '1',
            label: 'Warta',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Odra',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Wisła',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Noteć',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:806,
        title:'Ile kilometrów posiada najdłuższy w Polsce Oz Bukowsko-Mosiński, którego zakończenie stanowią tzw. Szwedzkie Góry:',
        answers:[{
            id: '1',
            label: '37 km',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '2 km',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '12 km',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '137 km',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:807,
        title:'Kiedy lądolód skandynawski znajdował się na terenie dzisiejszego Wielkopolskiego Parku Narodowego:',
        answers:[{
            id: '1',
            label: 'ok. 20 000 lat temu',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '65 lat temu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ok. 1000 lat temu',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'ok. 5000 lat temu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:808,
        title:'Wskaż dwie cenne rośliny występujące naturalnie na terenie Wielkopolskiego Parku Narodowego:',
        answers:[{
            id: '1',
            label: 'lilia złotogłów, jarząb brekinia',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'warzucha polska, pełnik europejski',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'brzoza karłowata,  lilia złotogłów',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'lobelia jeziorna, jarząb brekinia',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:809,
        title:'Wskaż rejon, z którego pochodzą głazy narzutowe, nazywane eratykami, znajdujące się na terenie Wielkopolskiego Parku Narodowego:',
        answers:[{
            id: '1',
            label: 'Półwysep Skandynawski oraz dno morza Bałtyckiego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Półwysep Bałkański oraz dno morza Egejskiego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Półwysep Iberyjski oraz dno morza Śródziemnego',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Półwysep Apeniński oraz dno morza Adriatyckiego',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:810,
        title:'Jak nazywają się talerzowato ułożone pióra wokół dzioba i oczu u puszczyka:',
        answers:[{
            id: '1',
            label: 'szlara',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'kołnierz',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'pióropusz',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'pokrywa',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 12. Poleski Park Narodowy */
    {
        idquestion:811,
        title:'Jak nazywa się ścieżka przyrodniczo-historyczna na terenie PPN?',
        answers:[{
            id: '1',
            label: 'Obóz Powstańczy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Czahary',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Spławy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Dąb Dominik',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:812,
        title:'Do najcenniejszych przyrodniczo motyli PPN zaliczamy:',
        answers:[{
            id: '1',
            label: 'przeplatkę aurinię',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rusałkę ceik',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'perłowca większego',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'rusałkę admirał',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:813,
        title:'Bóbr, często spotykany na terenie PPN, należy do:',
        answers:[{
            id: '1',
            label: 'gryzoni',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'przeżuwaczy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kopytnych',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'owadożernych',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:814,
        title:'Samiec jelenia szlachetnego to:',
        answers:[{
            id: '1',
            label: 'byk',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'kozioł',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'odyniec',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'basior',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:815,
        title:'Polski Park Narodowy leży w:',
        answers:[{
            id: '1',
            label: 'części wschodniej Polski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'części południowej Polski',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'części północnej Polski',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'części zachodniej Polski',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:816,
        title:'Do kompleksu Ośrodka Dydaktyczno-Muzealnego nie należy:',
        answers:[{
            id: '1',
            label: 'Ośrodek Rehabilitacji Zwierząt w Zawadówce',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wiata z powozami',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'oczko wodne z żółwiami błotnymi',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zadaszenie',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:817,
        title:'Jedne z łąk na terenie PPN nazywają się:',
        answers:[{
            id: '1',
            label: 'Pociągi',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Lokomotywy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Drezyny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Koleje',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:818,
        title:'Ścieżka Czahary prowadzi przez:',
        answers:[{
            id: '1',
            label: 'Bagno Bubnów',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Bagno Staw',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Łąkę Dudek',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Spleję Jeziora Długiego',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:819,
        title:'Do storczyków rosnących na terenie PPN nie należy:',
        answers:[{
            id: '1',
            label: 'dendrobium sp.',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'buławnik czerwony',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'obuwik pospolity',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'lipiennik loesela',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:820,
        title:'Faunistyczną perłą PPN jest:',
        answers:[{
            id: '1',
            label: 'żółw błotny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'bocian biały',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'czajka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'padalec zwyczajny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 13. Świętokrzyski Park Narodowy */
    {
        idquestion:821,
        title:'Jakich ssaków drapieżnych nie spotkamy w Świętokrzyskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'żbik, ryś, niedźwiedź',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'lis, wilk, borsuk',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kuna leśna, wilk, niedźwiedź',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'lis, wilk, borsuk',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:822,
        title:'Które nazwy określają jeden ze szczytów Świętokrzyskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'Łysiec i Łysa Góra',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Łysica i Łysa Góra',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Łasiczka i Łysiec',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Łysa Góra i Bukowa Góra',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:823,
        title:'Pachnica dębowa i zagłębek bruzdkowany to występujące w Świętokrzyskim Parku Narodowym gatunki chronionych:',
        answers:[{
            id: '1',
            label: 'chrząszczy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'motyli',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ślimaków',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'płazów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:824,
        title:'Powolne zjawisko zanikania gołoborzy związane jest z procesem:',
        answers:[{
            id: '1',
            label: 'sukcesji',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'erozji',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'mineralizacji',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wietrzenia',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:825,
        title:'Skąd pochodzi określenie „Góry Świętokrzyskie”?',
        answers:[{
            id: '1',
            label: 'od Relikwii Drzewa Krzyża Świętego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'od wielu krzyży, które można spotkać przy drogach',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'od patrona – Świętego Krzysztofa',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'od specyficznego ułożenia gór w kształcie krzyża',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:826,
        title:'Chełmowa Góra słynie między innymi z:',
        answers:[{
            id: '1',
            label: 'modrzewi polskich i mrowisk',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'cisów pospolitych i gniazd bocianów czarnych',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'olbrzymich jodeł i nor borsuczych',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wielkich sosen i gniazd myszołowów',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:827,
        title:'Co to jest soplówka jodłowa?',
        answers:[{
            id: '1',
            label: 'grzyb występujący na martwym drewnie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'biały kwiat rosnący na łąkach, objęty ochroną',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'owad który żeruje na jodle, a rójka jego odbywa się w lutym',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'motyl, którego larwy żerują w korze jodły',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:828,
        title:'Teren Świętokrzyskiego Parku Narodowego jest jedną z niewielu w Polsce ostoi:',
        answers:[{
            id: '1',
            label: 'modrzewnika lekarskiego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'zawilca gajowego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'szarotki alpejskiej',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'dwulistnika muszego',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:829,
        title:'Czyjego imienia jest gołoborze na Łysej Górze?',
        answers:[{
            id: '1',
            label: 'prof. R. Kobendzy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'prof. M. Raciborskiego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'prof. W. Szafera',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Stefana Żeromskiego',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:830,
        title:'Jak nazywają się skały, z których zbudowane są Góry Świętokrzyskie?',
        answers:[{
            id: '1',
            label: 'piaskowce kwarcytowe',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'gnejsy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'marmury',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'krzemienie pasiaste',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 14. Roztoczański Park Narodowy */
    {
        idquestion:831,
        title:'W Roztoczański Parku Narodowym można spotkać:',
        answers:[{
            id: '1',
            label: 'konika polskiego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'fokę',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'tarpana',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'niedźwiedzia',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:832,
        title:'Roztoczański PN jest parkiem położonym:',
        answers:[{
            id: '1',
            label: 'na wyżynie w Polsce południowo-wschodniej',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'w górach, na południu Polski',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'nad morzem, na północy Polski',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'na nizinach w zachodniej Polsce',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:833,
        title:'Największym gryzoniem w Roztoczańskim PN jest:',
        answers:[{
            id: '1',
            label: 'bóbr europejski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wiewiórka pospolita',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'mysz leśna',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'popielica szara',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:834,
        title:'Największym drapieżnym ssakiem w Roztoczańskim PN jest:',
        answers:[{
            id: '1',
            label: 'wilk szary',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'łasica',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kuna leśna',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'lis',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:835,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/animals/rys.png'),
        alt:'Ilustracja foki szarej',
        answersconfig:[
            {answer:'ryś europejski',correct:true},
            {answer:'europejski ryś',correct:true},
            {answer:'ryś',correct:true}
        ],
        hints:[
            {levelgame:0, hint:''},
            {levelgame:1, hint:''},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    

    {
        idquestion:836,
        title:'W Roztoczański Parku Narodowym można spotkać:',
        answers:[{
            id: '1',
            label: 'konika polskiego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'konia przewalskiego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'tarpana',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'hucuły',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:837,
        title:'Które z zestawów roślin występuje w Roztoczańskim PN?',
        answers:[{
            id: '1',
            label: 'żywiec gruczołowaty, obuwik pospolity',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'mikołajek nadmorski, rokitnik zwyczajny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'krokus wiosenny, szarotka alpejska',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'pelargonia, surfinia',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:838,
        title:'Który z zestawów zwierząt nie występuje w Roztoczańskim PN?',
        answers:[{
            id: '1',
            label: 'żbik, niedźwiedź brunatny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'ryś euroazjatycki, wilk szary',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'popielica szara, wydra europejska',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'puszczyk uralski, dzięcioł białogrzbiety',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:839,
        title:'Najwyższe wyniesienia w Roztoczańskim PN osiągają:',
        answers:[{
            id: '1',
            label: 'ponad 300 m',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'ponad 550 m',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ponad 600 m',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'ponad 750 m',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:840,
        title:'Wpisz nazwę zwierzęcia, które widnieje na ilustracji.',
        type:'open',
        src:require('../assets/images/quest/840.jpg'),
        alt:'Popielica szara wspinająca się na drzewo',
        answersconfig:[
            {answer:'popielica szara',correct:true},
            {answer:'szara popielica',correct:true},
            {answer:'popielica',correct:true}
        ],
        hints:[
            {levelgame:0, hint:''},
            {levelgame:1, hint:''},
            {levelgame:2, hint:''}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 15. Karkonoski Park Narodowy */
    {
        idquestion:841,
        title:'W którym piętrze roślinnym znajduje się Szrenica?',
        answers:[{
            id: '1',
            label: 'piętro subalpejskie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'piętro pogórza',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'regiel dolny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'regiel górny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'piętro alpejskie',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:842,
        title:'Wierzba zielna rosnąca przy Końskich Łbach to:',
        answers:[{
            id: '1',
            label: 'roślina arktyczno - alpejska',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'endemit',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'roślina łąkowa',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'roślina inwazyjna',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:843,
        title:'Jakim szlakiem można dojść na szczyt Góry Chojnik przez Zbójeckie Skały?',
        answers:[{
            id: '1',
            label: 'czarnym',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'żółtym',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'niebieskim',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'czerwonym',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        {
            id: '5',
            label: 'zielonym',
            value: 'option5',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:844,
        title:'Jakie gatunki drzew liściastych rosną w otoczeniu grupy skalnej Pielgrzymy?',
        answers:[{
            id: '1',
            label: 'jarzębiny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dęby',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'buki',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'brzozy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:845,
        title:'Kaplica św. Wawrzyńca na Śnieżce zlokalizowana jest po stronie?',
        answers:[{
            id: '1',
            label: 'polskiej',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'czeskiej',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'po obu stronach granicy państwowej',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'taki obiekt nie istnieje',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:846,
        title:'Relikt polodowcowy występujący w Kotle Małego Stawu to:',
        answers:[{
            id: '1',
            label: 'wierzba lapońska',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'kosodrzewina',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'gnidosz sudecki',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wełnianka wąskolistna',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:847,
        title:'Jak długo w Małym Śnieżnym Kotle może zalegać pokrywa śnieżna?',
        answers:[{
            id: '1',
            label: 'do lipca',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'do maja',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'do czerwca',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'do sierpnia',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:848,
        title:'Wiek Żyły Bazaltowej w Małym Śnieżnym Kotle określa się na około:',
        answers:[{
            id: '1',
            label: '26 mln lat',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '10 mln lat',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '5 tys. lat',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '300 mln lat',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:849,
        title:'Wielki Śnieżny Kocioł powstał w wyniku :',
        answers:[{
            id: '1',
            label: 'działalności lodowca górskiego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'działalności człowieka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'długotrwałych obrywów skalnych',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'erozji wodnej',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:850,
        title:'Hornfelsy budujące Śnieżkę to przeobrażone termicznie:',
        answers:[{
            id: '1',
            label: 'łupki łuszczykowe',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'aplity',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'piaskowce',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'skały bazaltowe',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 16. Park Narodowy Gór Stołowych */
    {
        idquestion:851,
        title:'Labirynt skalny Błędne Skały znajduje się:',
        answers:[{
            id: '1',
            label: 'w masywie Skalniaka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'w rejonie Pielgrzyma',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'przy Urwisku Batorowskim',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'przy Skalnych Grzybach',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:852,
        title:'Schronisko na Szczelińcu Wielkim zostało wybudowane w roku:',
        answers:[{
            id: '1',
            label: '1845',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '1730',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '1790',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '1800',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:853,
        title:'Łąki Gór Stołowych zaliczamy do ekosystemów:',
        answers:[{
            id: '1',
            label: 'nieleśnych',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'błotnych',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wodnych',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'torfowiskowych',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:854,
        title:'Ptaki związane ze środowiskiem wodnym żyjące w pobliżu strumieni w Górach Stołowych to:',
        answers:[{
            id: '1',
            label: 'pluszcz, pliszka górska',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pierwiosnek, sikora bogatka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kos, sójka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'derkacz, pokrzywnica',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:855,
        title:'Gatunki płazów występujące na obszarze Parku Narodowego Gór Stołowych to:',
        answers:[{
            id: '1',
            label: 'żaba trawna, salamandra plamista, ropucha szara',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'żaba moczarowa, ropucha szara, traszka górska',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kumak nizinny, żaba jeziorowa, salamandra plamista',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'grzebiuszka ziemna, ropucha paskówka, traszka grzebieniasta',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:856,
        title:'Jak nazywa się zbiorowisko leśne, które docelowo powinno być częste w Górach Stołowych?',
        answers:[{
            id: '1',
            label: 'buczyna sudecka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'świerczyna',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jaworzyna górska',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'las zboczowy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:857,
        title:'W szczytowych partiach bastionów skalnych Szczelińca Wielkiego oraz na piaskowcowych wychodniach skalnych rośnie:',
        answers:[{
            id: '1',
            label: 'sosnowy bór naskalny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'żyzna buczyna sudecka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jaworzyna górska',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'las łęgowy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:858,
        title:'Jodła pospolita jest gatunkiem drzewa, który w Górach Stołowych obecnie:',
        answers:[{
            id: '1',
            label: 'występuje rzadko',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'występuje często',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'nie występuje',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'jest gatunkiem inwazyjnym',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:859,
        title:'W jakim okresie obszar dzisiejszych Gór Stołowych stanowił zatokę morza zajmującego prawie cały obszar współczesnych Czech?',
        answers:[{
            id: '1',
            label: 'w kredzie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'w jurze',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'w karbonie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'w gipsie',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:860,
        title:'Torfowiska Gór Stołowych powstawały głównie w zagłębieniach terenu, zasilanych wodami odpadowymi. Ten typ torfowisk to:',
        answers:[{
            id: '1',
            label: 'torfowiska wysokie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'torfowiska przejściowe',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'torfowiska niskie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żadne z powyższych',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 17. Ojcowski Park Narodowy */
    {
        idquestion:861,
        title:'Jak nazywa się forma naciekowa w jaskini, która powstaje poprzez zrośnięcie się stalaktytu ze stalagmitem?',
        answers:[{
            id: '1',
            label: 'stalagnat',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'żebro naciekowe',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'belka kalcytowa',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'kalcopot',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:862,
        title:'Jedna z najoryginalniejszych form skalnych w Ojcowie utworzona z dwóch filarów skalnych, między którymi wiedzie niebieski szlak do Jaskini Łokietka, nazywa się:',
        answers:[{
            id: '1',
            label: 'Brama Krakowska',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Czarcie Wrota',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Wrota Ojcowskie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Brama Polska',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:863,
        title:'Największy masyw skalny w Dolinie Prądnika znajduje się naprzeciwko Bramy Krakowskiej. W jego wnętrzu skrywa się Jaskinia Ciemna. Jak nazywa się ów masyw?',
        answers:[{
            id: '1',
            label: 'Góra Koronna',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Góra Okopy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Skały Wernyhory',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Skały Panieńskie',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:864,
        title:'Nietoperze żywią się owadami. Z tego powodu, że zimą owady nie są aktywne nietoperze na czas zimy zapadają w głęboki sen. Jak nazywa się zimowy sen nietoperzy?',
        answers:[{
            id: '1',
            label: 'Hibernacja',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Hipotermia',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Hiposomia',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Śmierć pozorna',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:865,
        title:'W XIX wieku Ojców był jednym z najpopularniejszych uzdrowisk polskich. Powstało w tamtym czasie wiele ciekawych budynków zdrojowych, które do dziś budzą zainteresowanie turystów. Jednym z nich jest niewielki, niezwykle oryginalny kościółek. Jak się on nazywa?',
        answers:[{
            id: '1',
            label: 'Kaplica na Wodzie',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Kościół w Jaskini',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Kaplica na Skale',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Kościół przy Rzece',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:866,
        title:'W 1787 rok Stanisław August Poniatowski, ostatni król polski, zwiedzał jedną z ojcowskich jaskiń. Jak nazywa się ta jaskinia?',
        answers:[{
            id: '1',
            label: 'Jaskinia Ciemna',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Jaskinia Łokietka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Jaskinia Krowia',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Jaskinia Mamutowa',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:867,
        title:'Kazimierz Wielki, budowniczy zamków w Ojcowie i Pieskowej Skale był synem:',
        answers:[{
            id: '1',
            label: 'Władysława Łokietka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Leszka Białego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Leszka Czarnego',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Władysława Laskonogiego',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:868,
        title:'Murawy kserotermiczne to zbiorowiska roślin rosnących na nasłonecznionych skałach gdzie jest wybitnie sucho i ciepło. Mimo tych skrajnych warunków zbiorowiska te gromadzą ogrom gatunków. Jedną z najciekawszych roślin, które tam można znaleźć jest:',
        answers:[{
            id: '1',
            label: 'ostnica piórkowata',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'języcznik zwyczajny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'przetacznik bobowniczek',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'lilia wodna',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:869,
        title:'Na początku I wojny światowej, pod koniec 1914 roku w granicach dzisiejszego Ojcowskiego Parku Narodowego, trwały krwawe walki pomiędzy armiami:',
        answers:[{
            id: '1',
            label: 'Austro-Węgierską a Rosyjską',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Pruską a Rosyjską',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Rosyjską a Francuską',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Pruską a Węgierską',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:870,
        title:'Jaki gatunek człowieka w plejstocenie zamieszkiwał przez wiele tysięcy lat Jaskinie Ciemną, czyniąc ją bardzo ważnym stanowiskiem archeologicznym?',
        answers:[{
            id: '1',
            label: 'Człowiek Neandertalski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Australopitek',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Aborygen',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Yeti',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 18. Babiogórski Park Narodowy */
    {
        idquestion:871,
        title:'Pasmo Babiej Góry dzieli się na kilka kulminacji. Najwyższa to Diablak wznoszący się na 1725 m n.p.m. Z poniższych odpowiedzi zaznacz jedną z kulminacji, która również wchodzi w skład pasma.',
        answers:[{
            id: '1',
            label: 'Cyl (1517 m n.p.m.)',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Magurka (1218 m n.p.m.)',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Kiczora (908 m n.p.m.)',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Mędralowa (1180 m n.p.m.)',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:872,
        title:'Najwyżej położonym źródłem na Babiej Górze jest:',
        answers:[{
            id: '1',
            label: 'Głodna Woda',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Siarkowe Źródło',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Zbójnicka Studzienka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Źródełko św. Jana',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:873,
        title:'Pierwsze wzmianki o Babiej Górze pochodzą z II poł. XV wieku i zawarte są w dziele „Chorographia Regni Poloniae” autorstwa:',
        answers:[{
            id: '1',
            label: 'Jana Długosza',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Galla Anonima',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Jakuba Lubbe',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Augustyna Mieleskiego Rotundusa',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:874,
        title:'Łacińska nazwa tego ptaka występującego na Babiej Górze to Prunella collaris. Jaką nazwę nosi ten ptak w języku polskim:',
        answers:[{
            id: '1',
            label: 'płochacz halny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pluszcz zwyczajny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'siwerniak',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'głuszec zwyczajny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:875,
        title:'Jedyna w Polsce jaszczurka beznoga, którą można spotkać na Babiej Górze nosi nazwę:',
        answers:[{
            id: '1',
            label: 'padalec zwyczajny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'salamandra plamista',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'zaskroniec',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'jaszczurka żyworodna',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:876,
        title:'Ryjówka aksamitna to mały ssak z rodziny ryjówkowatych. Prowadzi nocny tryb życia a jej podstawowym pożywieniem są:',
        answers:[{
            id: '1',
            label: 'owady, pająki, ślimaki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'nasiona, bulwy, korzenie krzewów',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'nasiona buka, jaja ptaków, pajęczaki',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'padlina, grzyby, dżdżownice',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:877,
        title:'Wilk to jeden z dużych drapieżników również żyjących na terenie BgPN. Dorosły samiec (basior) potrafi osiągnąć sporą masę ciała. Zaznacz prawidłowy przedział wagowy.',
        answers:[{
            id: '1',
            label: '45 - 60 kg',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '25 – 40 kg',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '20 – 28 kg',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '35 - 45 kg',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:878,
        title:'Babia Góra z uwagi na swoje cenne walory przyrodnicze, została objęta międzynarodowym programem ochrony noszącym miano „Man and the Biosphere”, realizowanym przez UNESCO. Stało się to w roku:',
        answers:[{
            id: '1',
            label: '1976',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '1982',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '2020',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '1965',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:879,
        title:'Rzeźba Babiej Góry jest bardzo zróżnicowana. Budowę geologiczną można zobaczyć dokładniej w szczytowych partiach góry, gdzie występują wychodnie:',
        answers:[{
            id: '1',
            label: 'piaskowca magurskiego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'granitu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wapienia',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'łupku ilastego',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:880,
        title:'Charakterystyczny dla Babiej Góry jest piętrowy układ roślinności. Składa się z czterech pięter z których regiel dolny mieści się w granicach:',
        answers:[{
            id: '1',
            label: '700 - 1150 m n.p.m.',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '1250 - 1300 m n.p.m.',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '1400 – 1650 m n.p.m.',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '800 – 1100 m n.p.m.',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 19. Gorczański Park Narodowy */
    {
        idquestion:881,
        title:'Które z wymienionych gatunków ssaków drapieżnych występują w Gorczańskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'wilk, ryś, niedźwiedź',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wyłącznie wilk',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wyłącznie ryś',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żaden z wymienionych',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:882,
        title:'Które z wymienionych drzew liściastych występuje w Gorcach najczęściej i jest tzw. gatunkiem lasotwórczym?',
        answers:[{
            id: '1',
            label: 'buk zwyczajny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dąb szypułkowy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'klon zwyczajny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'brzoza',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:883,
        title:'Które z wymienionych ssaków kopytnych są stałymi mieszkańcami Gorczańskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'jeleń, sarna, dzik',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'jeleń, łoś, żubr',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'żubr, jeleń, daniel',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'daniel, łoś, żubr',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:884,
        title:'Herbowe zwierzę Gorczańskiego Parku Narodowego jest przedstawicielem:',
        answers:[{
            id: '1',
            label: 'płazów',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'gadów',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ssaków',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'ryb',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:885,
        title:'Co jest przedmiotem ochrony w Gorczańskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'lasy o cechach puszczy karpackiej',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rozlewiska rzek i tereny bagienne',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'naturalne lasy nizinne',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wydmy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:886,
        title:'Uszereguj w odpowiedniej kolejności następujące po sobie części pieśni godowej samca głuszca:',
        question:[
            {
                name:'klapanie',
                order:1
            },
            {
                name:'trelowanie',
                order:2
            },
            {
                name:'korkowanie',
                order:3
            },
            {
                name:'szlifowanie',
                order:4
            }
        ],
        type:'sort',
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        answersconfig: [
            {answer:'',correct:true}
        ],
        addPoints:true,
    },
    {
        idquestion:887,
        title:'Który z wymienionych ptaków jest gatunkiem lęgowym w Gorczańskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'puszczyk uralski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'czapla biała',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'żuraw',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'dudek',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:888,
        title:'Który z wymienionych płazów występuje w Gorczańskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'traszka karpacka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'rzekotka drzewna',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ropucha paskówka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'żaba śmieszka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:889,
        title:'Gorce zbudowane są ze skał:',
        answers:[{
            id: '1',
            label: 'osadowych',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'organicznych',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'magmowych (wulkanicznych)',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'przeobrażonych (metamorficznych)',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:890,
        title:'Które z wymienionych ptaków są gatunkami lęgowymi w Gorczańskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'puszczyk uralski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'czapla biała',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'żuraw',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'gęś tundrowa',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 20. Magurski Park Narodowy */
    {
        idquestion:891,
        title:'W jakim paśmie górskim położony jest Magurski Park Narodowy?',
        answers:[{
            id: '1',
            label: 'Beskid Niski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Beskid Mały',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Beskid Wyspowy',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Beskid Magurski',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:892,
        title:'Największa rzeka przepływająca przez Magurski Park Narodowy to:',
        answers:[{
            id: '1',
            label: 'Wisłoka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Jasiołka',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Ropa',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Wisłok',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:893,
        title:'Najliczniejszą sową Magurskiego Parku Narodowego jest:',
        answers:[{
            id: '1',
            label: 'puszczyk uralski',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'puchacz',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'włochatka',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'pójdźka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:894,
        title:'Jednym z najcenniejszych owadów występujących w Magurskim Parku Narodowym jest nadobnica alpejska. Ma ona jaskrawe ubarwienie. W jakim jest ono kolorze (za wyjątkiem czarnych plam)?',
        answers:[{
            id: '1',
            label: 'niebieskim',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pomarańczowym',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'różowym',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'seledynowym',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:895,
        title:'Magurski Park Narodowy położony jest w zasięgu dwóch pięter roślinnych. Są to:',
        answers:[{
            id: '1',
            label: 'Pogórze i regiel dolny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Regiel dolny i regiel górny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Regiel dolny i hale',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Pogórze i hale',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:896,
        title:'Które z wymienionych skał nie budują fliszu karpackiego?',
        answers:[{
            id: '1',
            label: 'gnejsy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'łupki',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'piaskowce',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'zlepieńce',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:897,
        title:'Najwyższe szczyty w Magurskim Parku Narodowym nie przekraczają wysokości:',
        answers:[{
            id: '1',
            label: '900 m n.p.m.',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '1100 m n.p.m.',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '1000 m n.p.m.',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '800 m n.p.m.',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:898,
        title:'Spośród roślin posiadających fioletowe kwiaty, dla lasów bukowych charakterystyczny jest',
        answers:[{
            id: '1',
            label: 'żywiec gruczołowaty',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wrzos pospolity',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'tojad mołdawski',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'barwinek pospolity',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:899,
        title:'Na magurskich łąkach można spotkać rzadkie motyle – modraszki. Większość z nich żyje w ścisłym związku z innymi owadami i bez nich ich cykl rozwojowy zostałby przerwany. Tymi owadami są:',
        answers:[{
            id: '1',
            label: 'mrówki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'ważki',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'chrząszcze',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'mszyce',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:900,
        title:'Magurski Park Narodowy jest miejscem życia wielu gatunków dzięciołów. Wśród nich występuje jeden z najrzadszych, związany z zamierającymi drzewami iglastymi. Jest to:',
        answers:[{
            id: '1',
            label: 'dzięcioł trójpalczasty',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'dzięcioł duży',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'dzięcioł czarny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'dzięciołek',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 21. Pieniński Park Narodowy */
    {
        idquestion:901,
        title:'Spływ przełomem Dunajca odbywa się na granicznym odcinku rzeki, należącym do dwóch państw:',
        answers:[{
            id: '1',
            label: 'Polski i Słowacji',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Polski i Niemiec',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Polski i Czech',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Polski i Ukrainy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:902,
        title:'Jakie dwa zamki znajdują się na terenie Pienińskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'Zamek Czorsztyn i Zamek Pieniny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Zamek w Pieskowej Skale i Zamek w Ojcowie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Zamek Czocha i Zamek Ogrodzieniec',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Zamek Królewski na Wawelu i Zamek Dunajec w Niedzicy',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:903,
        title:'Reliktowe laski sosnowe występują wyłącznie w Pieninach i Tatrach. Jaki gatunek sosny je tworzy?',
        answers:[{
            id: '1',
            label: 'sosna zwyczajna',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'sosna limba',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'kosodrzewina',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'sosna czarna',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:904,
        title:'Rośliną żywicielską gąsienic motyla niepylaka apollo jest:',
        answers:[{
            id: '1',
            label: 'rozchodnik wielki',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pszonak pieniński',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'aster alpejski',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'koniczyna pogięta',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:905,
        title:'Jakie dwa gatunki drzew można najczęściej spotkać na terenie Pienińskiego Parku Narodowego?',
        answers:[{
            id: '1',
            label: 'buk i jodła',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'sosna zwyczajna i cis',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'brzoza i jawor',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'olsza szara i wierzba krucha',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:906,
        title:'Jakie piętra roślinne występują w Pienińskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'tylko regiel dolny',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'regiel dolny, regiel górny, piętro kosodrzewiny i piętro hal',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'tylko regiel górny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'tylko piętro hal',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:907,
        title:'Ochrona łąk polega na:',
        answers:[{
            id: '1',
            label: 'regularnym koszeniu i zbieraniu siana',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'regularnym wypalaniu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'pozostawieniu naturalnym procesom przyrodniczym',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wypasaniu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:908,
        title:'Który szczyt nie znajduje się w Pienińskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'Wysoka',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Trzy Korony',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Sokolica',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Nowa Góra',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:909,
        title:'Które z poniższych roślin występują w Pienińskim Parku Narodowym?',
        answers:[{
            id: '1',
            label: 'aster alpejski, chryzantema Zawadzkiego, pszonak pieniński, smagliczka skalna',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'czosnek syberyjski, szarotka drobna, skalnica śnieżna, dzwonek karkonoski',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'okrzyn jeleni, rogownica alpejska, kosodrzewina, ciemiężyca zielona',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'aster alpejski, szarotka alpejska, sosna limba, skalnica tatrzańska',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:910,
        title:'Jaką wysokość ma najwyższy szczyt Pienińskiego Parku Narodowego – Trzy Korony?',
        answers:[{
            id: '1',
            label: '982 m n.p.m.',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '543 m n.p.m.',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '689 m n.p.m.',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '747 m n.p.m.',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 22. Bieszczadzki Park Narodowy */
    {
        idquestion:911,
        title:'Która rocznica utworzenia Bieszczadzkiego Parku Narodowego wypada w 2023r.',
        answers:[{
            id: '1',
            label: '50',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '25',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '15',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '75',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:912,
        title:'Najwyższym szczytem Bieszczadzkiego Parku Narodowego jest:',
        answers:[{
            id: '1',
            label: 'Tarnica',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Połonina Caryńska',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Bukowe Berdo',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Wielka Rawka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:913,
        title:'Największym zwierzęciem występującym w Parku jest:',
        answers:[{
            id: '1',
            label: 'żubr',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'łoś',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'jeleń',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'niedźwiedź brunatny',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:914,
        title:'Na terenie Bieszczadzkiego Parku Narodowego ...',
        answers:[{
            id: '1',
            label: 'zbiór roślin jest całkowicie zabroniony',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'wolno zbierać rośliny tylko w celach leczniczych',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wolno zbierać rośliny jedynie na łąkach w dolinach',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wolno zbierać rośliny tylko na połoninach',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:915,
        title:'W Bieszczadzkim Parku Narodowym, podczas wędrówki, swoje śmieci:',
        answers:[{
            id: '1',
            label: 'chowamy do plecaka i zabieramy ze sobą',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pozostawiamy w 1 miejscu na szczycie połoniny',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wyrzucamy do kosza na śmieci na szlaku turystycznym',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'oddajemy strażnikowi parku',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:916,
        title:'Bieszczadzki Park Narodowy prowadzi hodowlę zachowawczą:',
        answers:[{
            id: '1',
            label: 'konia huculskiego',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'konika polskiego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'tarpana',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'konia przewalskiego',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:917,
        title:'Z jakich skał zbudowane są wychodnie na połoninach:',
        answers:[{
            id: '1',
            label: 'piaskowców',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'granitów',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'bazaltów',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wapieni',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:918,
        title:'Największą rzeką płynąca przez Park jest:',
        answers:[{
            id: '1',
            label: 'San',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'Osława',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'Wisłok',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'Dunajec',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:919,
        title:'Które zabiegi sprzyjają ochronie łąk w Bieszczadzkim Parku Narodowym:',
        answers:[{
            id: '1',
            label: 'wszystkie wymienione',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'koszenie',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'wypas koni',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'wypas owiec',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:920,
        title:'W Bieszczadzkim Parku Narodowym wolno wędrować:',
        answers:[{
            id: '1',
            label: 'tylko od świtu do zmierzchu',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'o każdej porze dnia i nocy',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'nocą, ale ze sztucznym oświetleniem',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'nocą, wyłącznie w okresie letnim',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    /** 23. Tatrzański Park Narodowy */
    {
        idquestion:921,
        title:'W Morskim Oku żyją:',
        answers:[{
            id: '1',
            label: 'pstrągi potokowe',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'pstrągi tęczowe',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'karpie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'szczupaki',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:922,
        title:'Wagonik kolejki linowej na Kasprowy Wierch może zabrać maksymalnie:',
        answers:[{
            id: '1',
            label: '60 osób',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '45 osób',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '25 osób',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '15 osób',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:923,
        title:'Największe jeziora tatrzańskie są dziełem:',
        answers:[{
            id: '1',
            label: 'plejstoceńskich lodowców górskich',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'procesów krasowych',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'bobrów',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'człowieka',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:924,
        title:'W Dolinie Białego w pierwszych latach po II Wojnie Światowej działała kopalnia:',
        answers:[{
            id: '1',
            label: 'rudy uranu',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'węgla kamiennego',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'rudy żelaza',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'rudy cynku i ołowiu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:925,
        title:'Atrakcją dla turystów odwiedzających wczesną wiosną Polanę Chochołowską są:',
        answers:[{
            id: '1',
            label: 'obficie kwitnące tu szafrany spiskie czyli krokusy',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'łany kwitnących zimowitów jesiennych',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'ryczące wtedy w okolicznych lasach jelenie',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'kwitnące na skałach otaczających polanę szarotki',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },

    {
        idquestion:926,
        title:'Maksymalna głębokość Morskiego Oka to:',
        answers:[{
            id: '1',
            label: '51 m',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '26 m',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '78 m',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '104 m',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:927,
        title:'Obserwatorium meteorologiczne na Kasprowym Wierchu działa od roku:',
        answers:[{
            id: '1',
            label: '1938',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: '1914',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: '1928',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: '1948',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:928,
        title:'Hala Gąsienicowa nosi nazwę pochodzącą od:',
        answers:[{
            id: '1',
            label: 'nazwiska osób, które wypasały tam kiedyś owce',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'gąsienic cyklicznie niszczących rosnące tam drzewostany',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'krętego przebiegu doliny',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'śladów gąsienic ratraków ubijających zimą śnieg na stokach Kasprowego Wierchu',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:929,
        title:'Dolina Białego swoją nazwę wzięła od:',
        answers:[{
            id: '1',
            label: 'nazwy płynącego nią potoku',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'długo zalegającej pokrywy śniegu',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'koloru widocznych na jej stokach turniczek',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'nazwiska osób, do których kiedyś należała',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
    {
        idquestion:930,
        title:'Kaplica Św. Jana Chrzciciela na Polanie Chochołowskiej:',
        answers:[{
            id: '1',
            label: 'została wybudowana specjalnie dla serialu telewizyjnego „Janosik”',
            value: 'option1',
            color:'#f08733',
            selected:false
        }, 
        {
            id: '2',
            label: 'została wybudowana w XIX wieku przez zbójników',
            value: 'option2',
            color:'#f08733',
            selected:false
        },
        {
            id: '3',
            label: 'została wybudowana w 1901 roku przez Księdza Walentego Gadowskiego, twórcę Orlej Perci',
            value: 'option3',
            color:'#f08733',
            selected:false
        },
        {
            id: '4',
            label: 'została wybudowana z okazji wizyty Papieża Jana Pawła II w roku 1983',
            value: 'option4',
            color:'#f08733',
            selected:false
        },
        ],
        type:'closed',
        src:require('../assets/images/quest/default.jpg'),
        alt:'',
        answersconfig: [
            {answer:'option1',correct:true}
        ],
        goodanswermessage:'Brawo! To dobra odpowiedź!',
        badanswermessage:'Niestety, to zła odpowiedź.',
        addPoints:true,
    },
]

export default questions;