import { useEffect, useMemo, useState, KeyboardEvent } from "react";
import Container from "../components/Container";
import LeftTabBar from "../components/LeftTabBar";
import styles from '../styles/screens/Location.module.css';
import Header from "../components/Header";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Locations from '../data/locations';
import LocationsChallenges from '../data/locationschallenges';
import { LocationPointsProps, LocationProps } from "../types";
import next from '../assets/icons/next.png';
import LevelGameAlert from "../components/LevelGameAlert";
import SoundEffect from "../components/SoundEffect";
import Sizes from '../constans/sizes';

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

const Location = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
     const locationspoints = useSelector((state:any) => state.points.locationspoints);
    /**
     * Funkcja zwraca zapamiętane ustawienie poziomu trudności
     */
     const levelgame = useSelector((state:any) => state.levelgame.value);
     /**
      * Ustawienie stanów początkowych zmienych
      */
     const params:any = useLocation();
     const navigate = useNavigate();
     const [name, setName] = useState<string | undefined>('');
     const [description, setDescription] = useState<any | undefined>('');
     const [img, setImg] = useState<any | undefined>('');
     const [alt, setAlt] = useState<string | undefined>('');
     const [locationPassed, setLocationPassed] = useState(false);
     const [locationPoints, setLocationPoints] = useState(0);
     const [showLevelGameAlert, setShowLevelGameAlert] = useState(false);
 
     const idpark:number = useMemo(()=>(params.state.idpark),[params.state.idpark]);
     const idlocation:number = useMemo(()=>(params.state.idlocation),[params.state.idlocation]);
     const locationchallenges:any = useMemo<any>(()=>(LocationsChallenges.find(item=>item.idlocation===idlocation)),[idlocation]);
     const parkName:string = useMemo<any>(()=>(params.state.parkName),[params.state.parkName]);
     const logo:any = useMemo<any>(()=>(params.state.parkLogo),[params.state.parkLogo]);
     /**
      * Funkcja ustawiąjąca parametry dla wybranej lokacji
      */
     useEffect(()=>{
         const location:LocationProps | undefined = Locations.find((item:LocationProps)=>item.idlocation===idlocation);
         setName(location?.name);
         setDescription(location?.description);
         setImg(location?.src);
         setAlt(location?.alt);
     },[idlocation]);
     /**
     * Funkcja ustawiająca widoczność guzika WYZWANIE
     */
    useEffect(()=>{
        if(locationspoints!==undefined){
            const location:LocationPointsProps | undefined = locationspoints.find((item:LocationPointsProps)=>item.idlocation===idlocation);
            if(location!==undefined){
                setLocationPassed(true);
                setLocationPoints(location.points);
            }
            else{
                 setLocationPassed(false);
                 setLocationPoints(0);
            }
        }
        else{
            setLocationPassed(false);
            setLocationPoints(0);
        }
    },[locationspoints,idlocation]);
    /**
     * Funkcja wywoływana po naciśnięciu guzika WYZWANIE
     */
    const onClick = () => {
        if(sound!==undefined && sound==='on') SoundEffect('challengeButton');
        setShowLevelGameAlert(true);
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key==='Enter'){
            event.preventDefault();
            onClick();
        }
    }
    /**
     * Funkcja uruchamia proces Wyzwania
     */
    const challenge = () => {
        setShowLevelGameAlert(false);
        if(locationchallenges!==undefined){
            const levelquestions = locationchallenges.level[levelgame]!==undefined?locationchallenges.level[levelgame]:locationchallenges.level[0];
            let firstquestion:{idquestion:number,type:string} = levelquestions.questions[0];
            let nextquestion:{idquestion:number,type:string}[] = [];
            levelquestions.questions.forEach((item:{idquestion:number,type:string},index:number)=>{
                if(index!==0) nextquestion.push(item);
            });
            if(firstquestion!==undefined && firstquestion!==null) navigate(firstquestion.type,{
                state:{idquestion:firstquestion.idquestion,nextquestion:nextquestion,idlocation:idlocation,idpark:idpark,parkName:parkName,parkLogo:logo}
            });
        }
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
             {showLevelGameAlert && <LevelGameAlert show={showLevelGameAlert} 
            cancel={()=>{setShowLevelGameAlert(false)}} confirm={challenge} 
            title={'Chcesz podjąć wyzwanie w punkcie: '+name+'?'}  buttonCancel='Anuluj' buttonConfirm='Potwierdź'/>}
            <LeftTabBar idpark={idpark} logo={logo}/>
            <div className={styles.locationContainer}>  
                <Header name={parkName}/>
                <div className={styles.locationContent}>
                    <div className={styles.locationContentContainer}>
                        <div className={styles.imgContainer}>
                            <img className={contrast==='on'?styles.imgContrast:styles.img} src={img} alt={alt}/>
                        </div>
                        <div className={styles.challangeContainer}>
                            <div className={styles.descriptionContainer}>
                                <p className={styles.name} style={{fontSize:getFontSize()+10,color:contrast==='on'?'#fff':'#000'}}>{name}</p>
                                <div className={styles.description} style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : parser(description) }}/>
                            </div>
                            {!locationPassed?<h2 className={styles.heading}>
                                    <button className={contrast==='on'?styles.challengeContrast:styles.challenge} onClick={onClick} onKeyDown={onKeyDown}>
                                        <span className="sronly">PRZEJDŹ DO: </span>
                                        <span></span>
                                        <p className={styles.challangeText} style={{fontSize:getFontSize()+10}}>WYZWANIE</p>
                                        <img className={styles.next} src={next} alt=''/>
                                    </button>
                                </h2>
                            :
                            <div className={contrast==='on'?styles.passChallengeContrast:styles.passChallenge} >
                                <p className={styles.challangeText} style={{fontSize:getFontSize()+10}}>Uzyskano {locationPoints} punktów</p>
                            </div>}
                        </div>
                    </div>    
                </div>
            </div>
        </Container>
    )
}

export default Location;