import { KeyboardEvent } from "react";
import { useNavigate } from "react-router-dom";
import styles from '../styles/components/MenuHeader.module.css';
import menu from '../assets/icons/menu_green.png';
import menu_contrast from '../assets/icons/menu_white.png';
import close from '../assets/icons/close_green.png';
import close_contrast from '../assets/icons/close_white.png';
import logo from '../assets/images/defaults/logo.png';
import { useDispatch, useSelector } from "react-redux";
import SoundEffect from "./SoundEffect";
import volumn_on_green from "../assets/icons/volume_on_green.svg";
import volumn_on_white from "../assets/icons/volume_on_white.svg";
import volumn_off_green from "../assets/icons/volume_off_green.svg";
import volumn_off_white from "../assets/icons/volume_off_white.svg";
import { setSound } from "../store/redux/sound";

const MenuHeader = (props:{hamburger?:boolean}) => {
    const dispatch = useDispatch();
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
     const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych
     */
    const navigate = useNavigate();
    /**
     * Funkcja wywołyjąca strone poprzednią
     */
    const goBack = () => {
        if(sound!==undefined && sound==='on') SoundEffect('menuButton');
        navigate(-1);
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            if(sound!==undefined && sound==='on') SoundEffect('menuButton');
            event.preventDefault(); 
            navigate(-1);    
        } 
    }
    /**
     * Funkcja przechodzi do ekranu menu
     */
    const goMenu = () => {
        if(sound!==undefined && sound==='on') SoundEffect('menuButton');
        navigate('menu'); 
    }
    const onKeyDownMenu = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            if(sound!==undefined && sound==='on') SoundEffect('menuButton');
            event.preventDefault(); 
            navigate('menu');   
        } 
    }
    /**
     * Funkcja włącza | wyłącza dźwięk
     */
    const onoffSound = () => {
        if(sound!==undefined && sound==='on') SoundEffect('menuButton');
        dispatch(setSound({value:sound==='on'?'off':'on'}));
    }
    const onKeyDownOnOffSound = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            if(sound!==undefined && sound==='on') SoundEffect('menuButton');
            event.preventDefault(); 
            dispatch(setSound({value:sound==='on'?'off':'on'}));
        } 
    }
    return(
        <div className={styles.conatiner}>
            <img className={contrast==='on'?styles.logoContrast:styles.logo} src={logo} alt="Logo aplikacji"></img>
            <div>
                {sound==='on'?
                <button className={contrast==='on'?styles.closeContrast:styles.close} style={{marginRight:10}} onClick={onoffSound} onKeyDown={onKeyDownOnOffSound}> 
                    <h2 style={{margin:0}}>
                        <img className={styles.closeImage} src={contrast==='on'?volumn_on_white:volumn_on_green} alt="WYŁĄCZ DŹWIĘK"></img>
                    </h2>
                </button>
                :
                <button className={contrast==='on'?styles.closeContrast:styles.close} style={{marginRight:10}} onClick={onoffSound} onKeyDown={onKeyDownOnOffSound}> 
                    <h2 style={{margin:0}}>
                        <img className={styles.closeImage} src={contrast==='on'?volumn_off_white:volumn_off_green} alt="WŁĄCZ DŹWIĘK"></img>
                    </h2>
                </button>
                }
                {
                    !props.hamburger?<button className={contrast==='on'?styles.closeContrast:styles.close} onClick={goBack} onKeyDown={onKeyDown}> 
                                        <h2 style={{margin:0}}>
                                            <img className={styles.closeImage} src={contrast==='on'?close_contrast:close} alt="WYJDŹ"></img>
                                        </h2>
                                </button>
                    :<button className={contrast==='on'?styles.closeContrast:styles.close} onClick={goMenu} onKeyDown={onKeyDownMenu}> 
                        <h2 style={{margin:0}}>
                            <img className={styles.closeImage} src={contrast==='on'?menu_contrast:menu} alt="PRZEJDŹ DO MENU"></img>
                        </h2>
                    </button>
                }
            </div>
        </div>
    )
}

export default MenuHeader;