/**
 * Komponent flagi lokacji
 */
import { KeyboardEvent } from "react";
import { useSelector } from "react-redux";
import styles from '../styles/components/LocationMarkerLeaf.module.css';
import { useNavigate } from "react-router-dom";
import SoundEffect from "./SoundEffect";

const LocationMarker = (props:{idlocation:number,left:number,top:number,idpark:number,parkName:string,parkLogo:any,disabled:boolean,locationName:string,src:any,width:number,height:number}) => {
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    const navigate = useNavigate();
    /**
     * Funkcja wywoływana po naciśnięciu flagi lokacji
     */
    const onClick = () => {
        if(!props.disabled){
            if(sound!==undefined && sound==='on') SoundEffect('gotoLocation');
            navigate('location',{
                state:{idlocation:props.idlocation,idpark:props.idpark,parkName:props.parkName,parkLogo:props.parkLogo}
            });
        }
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter' && !props.disabled){
            event.preventDefault(); 
            onClick(); 
        }        
    }

    return (
        <li>
            <button className={contrast==='on'?styles.containerContrast:styles.container} 
            style={{left:props.left,
                top:props.top,
                pointerEvents:props.disabled?'none':'auto',
                opacity:props.disabled?'.4':'1',
                width:props.width,
                height:props.height}} 
                onClick={onClick} onKeyDown={onKeyDown}>
                <h3 style={{margin:0}}>
                    <img style={{width:'100%',height:'100%'}} src={props.src} alt=''/>
                    <span className="sronly">{props.locationName}</span>
                </h3>
            </button>
        </li>
    )
}

export default LocationMarker;