/**
 * Obiekt przechowujący odznaki zdobyte przez gracza
 */
 import { createSlice } from "@reduxjs/toolkit";
 
 const badgesSlice = createSlice({
     name: 'badges',
     initialState: { 
         values: []
     },
     reducers: {
         setBadges: (state, action) => {
            state.values = action.payload.values;
         }
     }
 });
 
 export const setBadges = badgesSlice.actions.setBadges;
 export default badgesSlice.reducer;