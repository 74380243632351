/**
 * Obiekt przechowujący informację o pokazywaniu alertu początkowego aplikacji
 */
import { createSlice } from "@reduxjs/toolkit";

const infoSlice = createSlice({
    name: 'info',
    initialState: { 
        value: undefined
    },
    reducers: {
        setInfo: (state, action) => {
            state.value = action.payload.value;
        }
    }
});

export const setInfo = infoSlice.actions.setInfo;
export default infoSlice.reducer;
