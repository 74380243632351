import React from "react";
import { Route, Routes } from "react-router-dom";
import Animal from "../screens/Animal";
import Atlas from "../screens/Atlas";
import Badge from "../screens/Badge";
import Guide from "../screens/Guide";
import Location from "../screens/Location";
import Menu from "../screens/Menu";
import AboutApplication from "../screens/menu/AboutApplication";
import AboutGame from "../screens/menu/AboutGame";
import Character from "../screens/menu/Character";
import FAQ from "../screens/menu/FAQ";
import FAQDescription from "../screens/menu/FAQDescription";
import GameSettings from "../screens/menu/GameSettings";
import PrivatePolicy from "../screens/menu/PrivatePolicy";
import ResetGame from "../screens/menu/ResetGame";
import Statute from "../screens/menu/Statute";
import ParkCuriosities from "../screens/ParkCuriosities";
import ParkLeaf from "../screens/ParkLeaf";
import Parks from "../screens/Parks";
import ParkStatute from "../screens/ParkStatute";
import PrivacyPolice from "../screens/PrivacyPolice";
import Closed from "../screens/questions/Closed";
import Find from "../screens/questions/Find";
import Hint from "../screens/questions/Hint";
import IdentifyVoice from "../screens/questions/IdentifyVoice";
import Memo from "../screens/questions/Memo";
import MultiSelection from "../screens/questions/MultiSelection";
import Open from "../screens/questions/Open";
import Pairs from "../screens/questions/Pairs";
import Puzzle from "../screens/questions/Puzzle";
import RecordSound from "../screens/questions/RecordSound";
import Sort from "../screens/questions/Sort";
import Trash from "../screens/questions/Trash";
import Shop from "../screens/Shop";

const AppRouter = () => {

    return (
        <Routes>
            <Route path="/" element={<Parks />}/>
            {/*<Route path="park" element={<Park />}/>*/}
            <Route path="park" element={<ParkLeaf />}/>
            <Route path="park/location" element={<Location />}/>
            {['menu', 'park/menu','park/location/menu','park/guide/menu','park/guide/parkstatute/menu','park/guide/parkcuriosities/menu','park/atlas/menu',
            'park/location/guide/menu','park/location/guide/parkstatute/menu','park/location/guide/parkcuriosities/menu','park/location/atlas/menu','park/shop/menu','park/location/shop/menu'].map((path,index) => (
                <Route key={index} path={path} element={<Menu />} />
            ))}
            {['menu/aboutapplication', 'park/menu/aboutapplication','park/location/menu/aboutapplication',
            'park/guide/menu/aboutapplication','park/guide/parkstatute/menu/aboutapplication','park/guide/parkcuriosities/menu/aboutapplication','park/atlas/menu/aboutapplication',
            'park/location/guide/menu/aboutapplication','park/location/guide/parkstatute/menu/aboutapplication','park/location/guide/parkcuriosities/menu/aboutapplication','park/location/atlas/menu/aboutapplication',
            'park/shop/menu/aboutapplication','park/location/shop/menu/aboutapplication'].map((path,index) => (
                <Route key={index} path={path} element={<AboutApplication />} />
            ))}
            {['menu/character', 'park/menu/character','park/location/menu/character','park/guide/menu/character','park/guide/parkstatute/menu/character','park/guide/parkcuriosities/menu/character','park/atlas/menu/character',
            'park/location/guide/menu/character','park/location/guide/parkstatute/menu/character','park/location/guide/parkcuriosities/menu/character','park/location/atlas/menu/character',
            'park/shop/menu/character','park/location/shop/menu/character'].map((path,index) => (
                <Route key={index} path={path} element={<Character />} />
            ))}
            {['menu/statute', 'park/menu/statute','park/location/menu/statute','park/guide/menu/statute','park/guide/parkstatute/menu/statute','park/guide/parkcuriosities/menu/statute','park/atlas/menu/statute',
            'park/location/guide/menu/statute','park/location/guide/parkstatute/menu/statute','park/location/guide/parkcuriosities/menu/statute','park/location/atlas/menu/statute',
            'park/shop/menu/statute','park/location/shop/menu/statute'].map((path,index) => (
                <Route key={index} path={path} element={<Statute />} />
            ))}
            {['menu/gamesettings', 'park/menu/gamesettings','park/location/menu/gamesettings','park/guide/menu/gamesettings','park/guide/parkstatute/menu/gamesettings','park/guide/parkcuriosities/menu/gamesettings','park/atlas/menu/gamesettings',
            'park/location/guide/menu/gamesettings','park/location/guide/parkstatute/menu/gamesettings','park/location/guide/parkcuriosities/menu/gamesettings','park/location/atlas/menu/gamesettings',
            'park/shop/menu/gamesettings','park/location/shop/menu/gamesettings'].map((path,index) => (
                <Route key={index} path={path} element={<GameSettings />} />
            ))}
            {['menu/resetgame', 'park/menu/resetgame','park/location/menu/resetgame','park/guide/menu/resetgame','park/guide/parkstatute/menu/resetgame','park/guide/parkcuriosities/menu/resetgame','park/atlas/menu/resetgame',
            'park/location/guide/menu/resetgame','park/location/guide/parkstatute/menu/resetgame','park/location/guide/parkcuriosities/menu/resetgame','park/location/atlas/menu/resetgame',
            'park/shop/menu/resetgame','park/location/shop/menu/resetgame'].map((path,index) => (
                <Route key={index} path={path} element={<ResetGame />} />
            ))}
            {['menu/privatepolicy', 'park/menu/privatepolicy','park/location/menu/privatepolicy','park/guide/menu/privatepolicy','park/guide/parkstatute/menu/privatepolicy','park/guide/parkcuriosities/menu/privatepolicy','park/atlas/menu/privatepolicy',
            'park/location/guide/menu/privatepolicy','park/location/guide/parkstatute/menu/privatepolicy','park/location/guide/parkcuriosities/menu/privatepolicy','park/location/atlas/menu/privatepolicy',
            'park/shop/menu/privatepolicy','park/location/shop/menu/privatepolicy'].map((path,index) => (
                <Route key={index} path={path} element={<PrivatePolicy />} />
            ))}
            {['menu/faq', 'park/menu/faq','park/location/menu/faq','park/guide/menu/faq','park/guide/parkstatute/menu/faq','park/guide/parkcuriosities/menu/faq','park/atlas/menu/faq',
            'park/location/guide/menu/faq','park/location/guide/parkstatute/menu/faq','park/location/guide/parkcuriosities/menu/faq','park/location/atlas/menu/faq',
            'park/shop/menu/faq','park/location/shop/menu/faq'].map((path,index) => (
                <Route key={index} path={path} element={<FAQ />} />
            ))}
            {['menu/faq/description', 'park/menu/faq/description','park/location/menu/faq/description','park/guide/menu/faq/description','park/guide/parkstatute/menu/faq/description','park/guide/parkcuriosities/menu/faq/description','park/atlas/menu/faq/description',
            'park/location/guide/menu/faq/description','park/location/guide/parkstatute/menu/faq/description','park/location/guide/parkcuriosities/menu/faq/description','park/location/atlas/menu/faq/description',
            'park/shop/menu/faq/description','park/location/shop/menu/faq/description'].map((path,index) => (
                <Route key={index} path={path} element={<FAQDescription />} />
            ))}
            {['menu/aboutgame', 'park/menu/aboutgame','park/location/menu/aboutgame','park/guide/menu/aboutgame','park/guide/parkstatute/menu/aboutgame','park/guide/parkcuriosities/menu/aboutgame','park/atlas/menu/aboutgame',
            'park/location/guide/menu/aboutgame','park/location/guide/parkstatute/menu/aboutgame','park/location/guide/parkcuriosities/menu/aboutgame','park/location/atlas/menu/aboutgame',
            'park/shop/menu/aboutgame','park/location/shop/menu/aboutgame'].map((path,index) => (
                <Route key={index} path={path} element={<AboutGame />} />
            ))}
            {['park/guide', 'park/location/guide'].map((path,index) => (
                <Route key={index} path={path} element={<Guide />} />
            ))}
            {['park/guide/parkstatute', 'park/location/guide/parkstatute'].map((path,index) => (
                <Route key={index} path={path} element={<ParkStatute />} />
            ))}
            {['park/guide/parkcuriosities', 'park/location/guide/parkcuriosities'].map((path,index) => (
                <Route key={index} path={path} element={<ParkCuriosities />} />
            ))}
            {['park/shop', 'park/location/shop'].map((path,index) => (
                <Route key={index} path={path} element={<Shop />} />
            ))}
            {['park/atlas', 'park/location/atlas'].map((path,index) => (
                <Route key={index} path={path} element={<Atlas />} />
            ))}
            <Route path="park/location/open" element={<Open/>}/>
            <Route path="park/location/closed" element={<Closed/>}/>
            <Route path="park/location/pairs" element={<Pairs/>}/>
            <Route path="park/location/sort" element={<Sort/>}/>
            <Route path="park/location/identifyvoice" element={<IdentifyVoice/>}/>
            <Route path="park/location/recordsound" element={<RecordSound/>}/>
            <Route path="park/location/find" element={<Find/>}/>
            <Route path="park/location/trash" element={<Trash/>}/>
            <Route path="park/location/puzzle" element={<Puzzle/>}/>
            <Route path="park/location/memo" element={<Memo/>}/>
            <Route path="park/location/hint" element={<Hint/>}/>
            <Route path="park/location/multiselection" element={<MultiSelection/>}/>
            <Route path="dousuniecia/badge" element={<Badge/>}/>
            <Route path="/privacypolice" element={<PrivacyPolice/>}/>
            {['park/atlas/spieces', 'park/location/atlas/spieces'].map((path,index) => (
                <Route key={index} path={path} element={<Animal />} />
            ))}
        </Routes>
    );
}

export default AppRouter;