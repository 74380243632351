/**
 * Rozmiary używane w aplikacji
 */
const sizes = {
    mapWidth:1024,//szerokość obrazka mapy parków
    mapHeight:1024,//wysokość obrazka mapy parków
    shopWidth:1125,//szerokość obrazka sklepu
    shopHeight:2436,//wysokość obrazka sklepu
    /**
     * Parametry określają w którym miejsu powinna zaczynać się lista wyświetlająca pocztówki na obrazku sklepu
     */
    shopPX1:140,//współrzędna X pierwszego punktu na obrazku sklepu - lewy górny róg
    shopPY1:706,//współrzędna Y pierwszego punktu na obrazku sklepu - lewy górny róg
    shopPX2:983,//współrzędna X drugiego punktu na obrazku sklepu - prawy dolny róg
    shopPY2:1906,//współrzędna Y drugiego punktu na obrazku sklepu - prawy dolny róg
    defaultNormalFontSize:12,
    defaultMediumFontSize:14,
    defaultBigFontSize:16
}

export default sizes; 