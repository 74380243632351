/**
 * Ekran sklepu
 */
import { useMemo, useState, KeyboardEvent } from "react";
import Container from "../components/Container";
import LeftTabBar from "../components/LeftTabBar";
import styles from '../styles/screens/Shop.module.css';
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import shop from "../assets/images/defaults/shop.png";
import { LocationPointsProps, ParkProps, PostCardsProps } from "../types";
import Parks from '../data/parks';
import badge from '../assets/images/defaults/badge.png';
import sizes from "../constans/sizes";
import PostCards from '../data/postcards';
import CustomAlert from "../components/CustomAlert";
import { setPointsSpent } from "../store/redux/points";
import { setPostcards } from "../store/redux/postcards";
import Sizes from '../constans/sizes';

const Shop = (props:any) => {
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zdobyte przez gracza punkty
     */
    const points:number = useSelector((state:any) => state.points.points);
    /**
     * Funkcja zwraca wydane przez gracza punkty
     */
    const pointsspent:number = useSelector((state:any) => state.points.pointsspent);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
     const locationspoints:LocationPointsProps[] = useSelector((state:any) => state.points.locationspoints);
    /**
     * Funkcja zwraca zapamiętane ilości punktów dla parków
     */
    const mypostcards:PostCardsProps[] = useSelector((state:any) => state.postcards.values);
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const dispatch = useDispatch();
    const params:any = useLocation();
    const park:ParkProps | undefined = useMemo(()=>(Parks.find((item:ParkProps) => item.idpark===params.state.idpark)),[params.state.idpark]);
    const [px1, setPX1] = useState(0);
    const [py1, setPY1] = useState(0);
    const [px2, setPX2] = useState(0);
    const [py2, setPY2] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [postcard, setPostCard] = useState<PostCardsProps>();
    const [showLackAlert, setShowLackAlert] = useState(false);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja wyzanczająca granice listy pocztówek
     */
    const onLoadShopContent = () => {
        const _path:HTMLImageElement = document.getElementById('pathElement') as HTMLImageElement;
        if(_path!==undefined && _path!==null){
            _path.style.width = "50%";
            _path.style.height = "100%";
            if(_path.offsetWidth>=_path.offsetHeight){
                _path.style.width = _path.offsetWidth.toString()+"px";
                _path.style.height = _path.offsetWidth.toString()+"px";
            }
            else{
                _path.style.width = _path.offsetHeight.toString()+"px";
                _path.style.height = _path.offsetHeight.toString()+"px";
            }
            _path.style.visibility = 'visible';
        }

        const _top = (_path.offsetHeight*sizes.shopPY1)/sizes.shopHeight + _path.offsetTop;
        const _left = (_path.offsetWidth*sizes.shopPX1)/sizes.shopWidth + _path.offsetLeft;
        const _bottom = (_path.offsetHeight*sizes.shopPY2)/sizes.shopHeight + _path.offsetTop;
        const _right = (_path.offsetWidth*sizes.shopPX2)/sizes.shopWidth + _path.offsetLeft;
        setPX1(_left);
        setPY1(_top);
        setPX2(_right);
        setPY2(_bottom);
    }
    /**
     * Funkcja tworząca pocztówki
     */
    const _postcards:any[] = useMemo(()=>PostCards.map((item:PostCardsProps)=>{
        const exist:PostCardsProps = mypostcards.find((p:PostCardsProps)=>p===item) as PostCardsProps;
        if(exist===undefined){
            const find:LocationPointsProps | undefined = locationspoints!==undefined?locationspoints.find((element:LocationPointsProps)=>element.parkID===item.parkID) as LocationPointsProps:undefined;
            return  <li key={item.id}>
                        <h3 style={{margin:0}}>
                            <button className={contrast==='on'?styles.postcardContrast:styles.postcard} style={{opacity:find===undefined?'.4':'1',pointerEvents:find===undefined?'none':'auto'}} onClick={()=>onClick(item)} onKeyDown={(event)=>onKeyDown(event,item)}>
                                <img className={styles.postcardImage} src={item.src} alt={item.alt}/>
                                <div className={styles.points} style={{justifyContent:'center',alignItems:'center'}}>
                                    <img className={styles.badge} style={{width:30,height:30}} src={badge} alt=""/>
                                    <p style={{fontSize:getFontSize()-1,color:'#fff'}}>{item.price}</p>
                                </div>
                            </button>
                        </h3>
                    </li>
        }
        else return null;
    /*eslint-disable */
    }),[locationspoints,mypostcards]);
    /*eslint-disable */
    /**
     * Funkcja wywoływana po kliknięciu w pocztówkę
     */
    const onClick = (_postcard:PostCardsProps) => {
        setPostCard(_postcard);
        setMessageAlert('Czy chcesz kupić wybraną pocztówkę, za '+_postcard.price+' punktów?');
        setShowAlert(true);
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>,_postcard:PostCardsProps) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            onClick(_postcard);
        }
    }
    /**
     * Funkcja umoliwiająca zakup pocztówki
     */
    const buy = () => {
        if(postcard!==undefined){
            const _pointsspent: number = pointsspent!==undefined?pointsspent:0;
            setShowAlert(false);
            if(points-_pointsspent-postcard.price<0) setShowLackAlert(true);
            else{
                 dispatch(setPointsSpent({pointsspent:_pointsspent+postcard.price}));
                 const _mypostcards:PostCardsProps[] = mypostcards!==undefined?[...mypostcards]:[];
                 _mypostcards.push(postcard);
                dispatch(setPostcards({values:_mypostcards}));
            }
        }
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            {showAlert && <CustomAlert show={showAlert} cancel={()=>{setShowAlert(false)}} confirm={buy} 
            title='Potwierdź' message={messageAlert} buttonCancel='Anuluj' buttonConfirm='Potwierź'/>}
            {showLackAlert && <CustomAlert show={showLackAlert} cancel={()=>{setShowLackAlert(false)}}
            title='Komunikat' message='Brak wystarczających ilości punktów na zakup pocztówki' buttonCancel='Zamknij'/>}
            <LeftTabBar idpark={park?.idpark} logo={park?.logo}/>
            <div className={styles.shopContainer}>
                <Header name="SKLEP"/>
                <div className={styles.shopContent}>
                    <div>
                        <img id="pathElement" className={contrast==='on'?styles.shopImgContrast:styles.shopImg} src={shop} alt="Obrazek sklepu" onLoad={onLoadShopContent}/>
                        <div id="postcardList" className={styles.postcardsContainer} style={{width:px2-px1,height:py2-py1,top:py1,left:px1}}>
                        <h2 className="sronly">W sklepie możesz zakupić pocztówki z wizerunkami zwierząt i roślin odkrytych podczas zwiedzania parków. Aby odblokować pocztówki z danego Parku, należy ukończyć w nim dowolny liść, na dowolnym poziomie trudności. Aby kupić pocztówkę, należy wybrać ją z listy odblokowanych. Pocztówką możesz pochwalić się przed znajomymi w social mediach (np. na portalu Facebook). Wszystkie kupione pocztówki dostępne są do ściągnięcia w Atlasie, w zakładce POCZTÓWKI.</h2>
                            <ul className={styles.postcardList}>
                                {
                                    _postcards
                                }
                            </ul>
                        </div>
                    </div>
                    <div className={styles.pointsContainer}>
                        <p className={styles.info} style={{fontSize:getFontSize()+6,color:contrast==='on'?'#fff':'#000'}}>{'W sklepie możesz zakupić pocztówki z wizerunkami zwierząt i roślin odkrytych podczas zwiedzania parków. Aby odblokować pocztówki z danego Parku, należy ukończyć w nim dowolny liść, na dowolnym poziomie trudności. Aby kupić pocztówkę, należy wybrać ją z listy odblokowanych. Pocztówką możesz pochwalić się przed znajomymi w social mediach (np. na portalu Facebook). Wszystkie kupione pocztówki dostępne są do ściągnięcia w Atlasie, w zakładce POCZTÓWKI.'}</p>
                        <div className={styles.points} style={{flexDirection:'column'}}>
                            <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>TWOJE PUNKTY</p>
                            <div className={styles.points}>
                                <img className={contrast==='on'?styles.badgeContrast:styles.badge} src={badge} alt="Ikona odznaki"/>
                                <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>{points===undefined?0:pointsspent===undefined?points:points-pointsspent<0?points:points-pointsspent}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Shop;