/**
 * Komponent wyświetlający listę przestawną
 */
import { CSSProperties, DragEvent, useEffect, useState, TouchEvent, useCallback } from "react";
import { useSelector } from "react-redux";
import styles from '../styles/components/DraggableList.module.css'; 
import { PairsQuestionItemProps } from "../types";

/**
 * Ustawienie stanów początkowych zmienych
 */
var dragElement:HTMLDivElement;
var clone:HTMLDivElement;
var offsetLeft:number;
var offsetTop:number;
var dragData:string;

const DraggableList = (props:{elements:PairsQuestionItemProps[],style:CSSProperties,id:string,fontSize:number}) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [dragItems, setDragItems] = useState([]);
    /**
     * Funkcja rozpoczynająca przesuwanie elementu
     * @param {DragEvent} event 
     */
    const handleDragStart = (event:DragEvent<HTMLDivElement>) => {
       const element:HTMLDivElement = event?.target as HTMLDivElement;
       if(element!==null){
            element.style.opacity = '0.8';
            dragElement = element;
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('text/html', element.innerHTML);
            dragData = dragElement.getAttribute('data-neworder') ?? '';
       }  
    }
    /**
     * Funkcja rozpoczynająca przesuwanie elementu
     * @param {TouchEvent} event 
     */
    const handleTouchStart = useCallback((event:TouchEvent<HTMLDivElement>) => {
        const element:HTMLDivElement = event?.target as HTMLDivElement;
        if(element!==null){
             element.style.opacity = '0.8';
             dragElement = element;
             dragData = dragElement.getAttribute('data-neworder') ?? '';
             clone = element.cloneNode(true) as HTMLDivElement;
             clone.style.position = 'absolute';
             document.getElementById(props.id)!.appendChild(clone); 
             var touchLocation = event.targetTouches[0];
             clone.style.left = touchLocation.pageX + 'px';
             clone.style.top = touchLocation.pageY + 'px';
             clone.style.width = element.offsetWidth + "px";
        }
        document.getElementById('backgroundContainer')!.style.overflow = "hidden";  
    },[props.id]);
    /**
     * Funkcja przesuwająca element
     * @param {TouchEvent} event 
     */
    const handleTouchMove = useCallback((event:TouchEvent<HTMLDivElement>) => {
        var touchLocation = event.targetTouches[0];
        clone.style.left = touchLocation.pageX + 'px';
        clone.style.top = touchLocation.pageY + 'px';
        const _list:HTMLDivElement = document.getElementById(props.id) as HTMLDivElement;
        var _change:boolean = true;
        for (let i = 0; i < _list.children.length; i++){
            const element:HTMLDivElement = _list.children[i] as HTMLDivElement;
            if(element!==clone && element.offsetLeft<=clone.offsetLeft && (element.offsetLeft+element.offsetWidth)>=clone.offsetLeft
                && element.offsetTop<=clone.offsetTop 
                && (element.offsetTop+element.offsetHeight)>=clone.offsetTop){
                     element.style.border = '2px dotted #fff';
                     offsetLeft = clone.offsetLeft;
                     offsetTop = clone.offsetTop;
                     _change = false;
            }
            else{
                element.style.border = contrast==='on'?'2px solid #fff':'2px solid #f08733';
                if(_change){
                    offsetLeft = -1;
                    offsetTop = -1;
                }
            }
        }
    },[props.id,contrast]);
    /**
     * Funkcja wywoływana po zakończeniu przsuwania elementu
     * @param {TouchEvent} event 
     */
    const handleTouchEnd = useCallback((event:TouchEvent<HTMLDivElement>) => {
        document.getElementById(props.id)!.removeChild(clone); 
        dragElement.style.opacity = '1';
        const _list:HTMLDivElement = document.getElementById(props.id) as HTMLDivElement;
        for (let i = 0; i < _list.children.length; i++){
            const element:HTMLDivElement = _list.children[i] as HTMLDivElement;
            element.style.border = contrast==='on'?'2px solid #fff':'2px solid #f08733';
            if(element.offsetLeft<=offsetLeft && (element.offsetLeft+element.offsetWidth)>=offsetLeft
                && element.offsetTop<=offsetTop 
                && (element.offsetTop+element.offsetHeight)>=offsetTop){
                    const tmp:HTMLDivElement = element.cloneNode(true) as HTMLDivElement;
                    element.innerHTML = dragElement.innerHTML;
                    dragElement.innerHTML = tmp.innerHTML;
                    element.setAttribute('data-neworder',dragData);
                    dragElement.setAttribute('data-neworder',tmp.getAttribute('data-neworder') ?? '');
                    return;
                }
        }
        document.getElementById('backgroundContainer')!.style.overflow = "visible"; 
    },[props.id,contrast]);
    /**
     * Funkcja wywoływana po zakończeniu przsuwania elementu
     * @param {DragEvent} event 
     */
    const handleDragEnd = useCallback((event:DragEvent<HTMLDivElement>) => {
        const element:HTMLDivElement = event?.target as HTMLDivElement;
        if(element!==null) {
            element.style.opacity = '1';
        }
        const _list:HTMLDivElement = document.getElementById(props.id) as HTMLDivElement;
        for(let i = 0; i < _list.children.length; i++) (_list.children[i] as HTMLDivElement).style.border = contrast==='on'?'2px solid #fff':'2px solid #f08733';
    },[props.id,contrast]);
    /**
     * Funkcja wywoływana podczas przeciągania elementu
     * @param {DragEvent} event 
     */
    const handleDragOver = (event:DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        return false;
    }
    /**
     * Funkcja wywoływana podczas przeciągania elementu nad innym elementem listy
     * @param {DragEvent} event 
     */
    const handleDragEnter = (event:DragEvent<HTMLDivElement>) => {
        const element:HTMLDivElement = event?.target as HTMLDivElement;
        if(element!==null) element.style.border = '2px dotted #fff';
    }
    /**
     * Funkcja wywoływana podczas przerwania przeciągania
     * @param {DragEvent} event 
     */
    const handleDragLeave = useCallback((event:DragEvent<HTMLDivElement>) => {
        const element:HTMLDivElement = event?.target as HTMLDivElement;
        if(element!==null) element.style.border = contrast==='on'?'2px solid #fff':'2px solid #f08733';
    },[contrast]);
    /**
     * Funkcja wywoływana po opuszczeniu elementu
     * @param {DragEvent} event 
     */
    const handleDrop = (event:DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        const element:HTMLDivElement = event?.target as HTMLDivElement;
        if (element !==null && dragElement !== undefined && dragElement !== element) {
            const droporder:string = element.getAttribute('data-neworder') ?? '';
            dragElement.innerHTML = element.innerHTML;
            element.innerHTML = event.dataTransfer.getData('text/html');
            element.setAttribute('data-neworder',dragData);
            dragElement.setAttribute('data-neworder',droporder);
        }
        return false;
    }
    /**
     * Funkcja ustawiąjąca parametry dla listy
     */
    useEffect(()=>{
        const _dragItems:any = props.elements.map((item:PairsQuestionItemProps,index)=>{
            return  <div key={index} data-order={item.order} data-neworder={item.order} draggable="true" className={contrast==='on'?styles.itemContrast:styles.item} style={{fontSize:props.fontSize}}
                        onDragStart={handleDragStart} 
                        onDragEnd={handleDragEnd} 
                        onDragOver={handleDragOver} 
                        onDragEnter={handleDragEnter} 
                        onDragLeave={handleDragLeave} 
                        onDrop={handleDrop}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}>
                            {item.name}
                    </div>
        });
        setDragItems(_dragItems);
    },[props.elements,handleDragEnd, handleTouchEnd, handleTouchMove, handleTouchStart,props.fontSize,contrast,handleDragLeave])
    return (
        <div className={styles.container} id={props.id} style={props.style}>
            {
                dragItems
            }
        </div>
    );
}

export default DraggableList;