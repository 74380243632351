/**
 * Obiekt przechowujący zdjęcia zwierząt zrobione przez gracza
 */
import { createSlice } from "@reduxjs/toolkit";
import Animals from '../../data/animals';
import { AnimalPhotoProps } from "../../types";

const animalsSlice = createSlice({
    name: 'animals',
    initialState: { 
        values: []
    },
    reducers: {
        setAnimals: (state, action) => {
            const animal:AnimalPhotoProps | undefined = Animals.find(item=>item.id===action.payload.value);
            if(animal!==undefined){
                const stateAnimals:AnimalPhotoProps[] = state.values!==undefined?[...state.values]:[];
                if(stateAnimals!==undefined){
                    const exist:AnimalPhotoProps | undefined = stateAnimals.find((item:AnimalPhotoProps)=>item.id===animal.id);
                    if(exist===undefined) stateAnimals.push(animal);
                    state.values = stateAnimals as never;
                }
            }
        },
        setLocalStorageAnimals: (state, action) => {
            state.values = action.payload.values;
        }
    }
});

export const setAnimals = animalsSlice.actions.setAnimals;
export const setLocalStorageAnimals = animalsSlice.actions.setLocalStorageAnimals;
export default animalsSlice.reducer;
