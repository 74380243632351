/**
 * Komponent elementu listy Parków Narodowych
 */
import { useEffect, useState, KeyboardEvent } from "react";
import { useSelector } from "react-redux";
import { ParkPointsProps } from "../types";
import Colors from '../constans/colors';
import ParkStatusSettings from '../constans/parkstatussettings';
import styles from '../styles/components/ListItem.module.css';
import SoundEffect from "./SoundEffect";
import Sizes from '../constans/sizes';

const ListItem = (props:{idpark:number,maxpoints:number,name:string,navigation:any,setPark:Function,idx:number}) => {
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane ilości punktów dla parków
     */
    const parkspoints:ParkPointsProps[] = useSelector((state:any) => state.points.parkspoints);
     /**
      * Ustawienie stanów początkowych zmienych
      */
    const [circleColor,setCircleColor] = useState(Colors.notPassed);
     /**
      * Funkcja ustawiająca status parku na podstawie zdobytych punktów
      */
    useEffect(()=>{
         if(parkspoints!==undefined){
             const find:ParkPointsProps | undefined = parkspoints.find((item:ParkPointsProps)=>item.idpark===props.idpark);
             if(find!==undefined){
                 const status = ParkStatusSettings.find((item:any)=>(item.scale[0]*props.maxpoints)/100<find.points&&(item.scale[1]*props.maxpoints)/100>=find.points);
                 if(status!==undefined) setCircleColor(status.color);
             }
             else setCircleColor(Colors.notPassed);
         }
         else setCircleColor(Colors.notPassed);
    },[parkspoints,props.idpark,props.maxpoints]);
    /**
     * Funkcja wywoływana po naciśnięciu znaku parku
     */
    const onClick = () => {
        if(sound!==undefined && sound==='on') SoundEffect('gotoPark');
        props.setPark();
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            if(sound!==undefined && sound==='on') SoundEffect('gotoPark');
            event.preventDefault(); 
            props.setPark();  
        }        
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <li>
            <button className={contrast==='on'?styles.listcontainerContrast:styles.listcontainer} onClick={onClick} onKeyDown={onKeyDown}>
                <div className={contrast==='on'?styles.circlecontainerContrast:styles.circlecontainer} style={{backgroundColor:circleColor}}>
                    <p className={contrast==='on'?styles.idcolorContrast:styles.idcolor} style={{color:circleColor==='#facc3d'||circleColor==='#ffffff'?'#000':'#fff',fontSize:getFontSize()+4}}>{props.idx}</p>
                </div>
                <h3 className={contrast==='on'?styles.parkNameContrast:styles.parkName} style={{fontSize:getFontSize()+4}}>{props.name}</h3>
            </button>
        </li>
    )
}

export default ListItem;
