/**
 * Statusy lokacji,
 * scale[40,60] - 40*{max_ilość_punktów_w_lokacji}/100<{aktualna_ilość_punktów_w_lokacji} && 60*{max_ilość_punktów_w_lokacji}/100>={aktualna_ilość_punktów_w_lokacji}
 */
const locationstatussettings = [
    {
        name:'nieodwiedzona',
        color:'#c0070d',
        scale:[0,40],
        locationleaf:[
            {src:require('../assets/images/defaults/tree/c0070d/leaf1.png'),x:95,y:490,width:262,height:155},
            {src:require('../assets/images/defaults/tree/c0070d/leaf2.png'),x:100,y:320,width:226,height:175},
            {src:require('../assets/images/defaults/tree/c0070d/leaf3.png'),x:110,y:130,width:182,height:264},
            {src:require('../assets/images/defaults/tree/c0070d/leaf4.png'),x:255,y:130,width:139,height:326},
            {src:require('../assets/images/defaults/tree/c0070d/leaf5.png'),x:410,y:100,width:189,height:354},
            {src:require('../assets/images/defaults/tree/c0070d/leaf6.png'),x:470,y:305,width:150,height:257},
            {src:require('../assets/images/defaults/tree/c0070d/leaf7.png'),x:600,y:240,width:171,height:231},
            {src:require('../assets/images/defaults/tree/c0070d/leaf8.png'),x:570,y:414,width:243,height:174}
        ]
    },
    {
        name:'brązowy',
        color:'#facc3d',
        scale:[40,60],
        locationleaf:[
            {src:require('../assets/images/defaults/tree/facc3d/leaf1.png'),x:95,y:490,width:262,height:155},
            {src:require('../assets/images/defaults/tree/facc3d/leaf2.png'),x:100,y:320,width:226,height:175},
            {src:require('../assets/images/defaults/tree/facc3d/leaf3.png'),x:110,y:130,width:182,height:264},
            {src:require('../assets/images/defaults/tree/facc3d/leaf4.png'),x:255,y:130,width:139,height:326},
            {src:require('../assets/images/defaults/tree/facc3d/leaf5.png'),x:410,y:100,width:189,height:354},
            {src:require('../assets/images/defaults/tree/facc3d/leaf6.png'),x:470,y:305,width:150,height:257},
            {src:require('../assets/images/defaults/tree/facc3d/leaf7.png'),x:600,y:240,width:171,height:231},
            {src:require('../assets/images/defaults/tree/facc3d/leaf8.png'),x:570,y:414,width:243,height:174}
        ]
    },
    {
        name:'srebrny',
        color:'#2c7187',
        scale:[60,80],
        locationleaf:[
            {src:require('../assets/images/defaults/tree/2c7187/leaf1.png'),x:95,y:490,width:262,height:155},
            {src:require('../assets/images/defaults/tree/2c7187/leaf2.png'),x:100,y:320,width:226,height:175},
            {src:require('../assets/images/defaults/tree/2c7187/leaf3.png'),x:110,y:130,width:182,height:264},
            {src:require('../assets/images/defaults/tree/2c7187/leaf4.png'),x:255,y:130,width:139,height:326},
            {src:require('../assets/images/defaults/tree/2c7187/leaf5.png'),x:410,y:100,width:189,height:354},
            {src:require('../assets/images/defaults/tree/2c7187/leaf6.png'),x:470,y:305,width:150,height:257},
            {src:require('../assets/images/defaults/tree/2c7187/leaf7.png'),x:600,y:240,width:171,height:231},
            {src:require('../assets/images/defaults/tree/2c7187/leaf8.png'),x:570,y:414,width:243,height:174}
        ]
    },
    {
        name:'złoty',
        color:'#2b604a',
        scale:[80,1000],
        locationleaf:[
            {src:require('../assets/images/defaults/tree/2b604a/leaf1.png'),x:95,y:490,width:262,height:155},
            {src:require('../assets/images/defaults/tree/2b604a/leaf2.png'),x:100,y:320,width:226,height:175},
            {src:require('../assets/images/defaults/tree/2b604a/leaf3.png'),x:110,y:130,width:182,height:264},
            {src:require('../assets/images/defaults/tree/2b604a/leaf4.png'),x:255,y:130,width:139,height:326},
            {src:require('../assets/images/defaults/tree/2b604a/leaf5.png'),x:410,y:100,width:189,height:354},
            {src:require('../assets/images/defaults/tree/2b604a/leaf6.png'),x:470,y:305,width:150,height:257},
            {src:require('../assets/images/defaults/tree/2b604a/leaf7.png'),x:600,y:240,width:171,height:231},
            {src:require('../assets/images/defaults/tree/2b604a/leaf8.png'),x:570,y:414,width:243,height:174}
        ]
    }
];

export default locationstatussettings;