/**
 * Komponent umożliwiający odtwarzanie dźwięku nagranego przez gracza
 */
import { useCallback, useEffect, useMemo, useState } from "react";
import styles from '../styles/components/SoundItem.module.css';
import playIcon from "../assets/icons/play.svg";
import pauseIcon from "../assets/icons/pause.svg";
import playIcon_contrast from "../assets/icons/play_white.svg";
import pauseIcon_contrast from "../assets/icons/pause_white.svg";
import { useSelector } from "react-redux";

const SoundItem = (props:{name:string,src:any,fontSize:number}) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [playStatus, setPlayStatus] = useState(false);
    const audio = useMemo(()=>new Audio(props.src),[props.src]);
    /**
     * Funkcja odtwarzająca dźwięk 
     */
    const play = () => {
        setPlayStatus(true);
        audio.play();
        audio.addEventListener('ended',()=>setPlayStatus(false));
    }
    /**
     * Funkcja zatrzymująca odtwrzanie dźwięku
     */
    const pause = useCallback(() => {
        setPlayStatus(false);
        audio.pause();
    },[audio]);

    useEffect(()=>{
        return()=>{
            pause();
            audio.removeEventListener('ended',()=>setPlayStatus(false));
        }
    },[pause,audio]);

    return <li>
                <h3 style={{margin:0}}>
                    <button className={contrast==='on'?styles.cardContrast:styles.card} onClick={playStatus?pause:play} style={{fontSize:props.fontSize}}>
                        <img className={contrast==='on'?styles.buttonContrast:styles.button} src={playStatus?contrast==='on'?pauseIcon_contrast:pauseIcon:contrast==='on'?playIcon_contrast:playIcon} alt=''/>
                        {props.name}
                    </button>
                </h3>
            </li>
    
}

export default SoundItem;