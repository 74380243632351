/**
 * Ekran wyświetlający pytanie typu memo
 */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '../../components/Container';
import Header from '../../components/Header';
import LeftTabBar from '../../components/LeftTabBar';
import MemoCard from '../../components/MemoCard';
import SoundEffect, { pause } from '../../components/SoundEffect';
import Timer, { clearTimer } from '../../components/Timer';
import LevelPoints from '../../constans/levelpoints';
import Questions from '../../data/questions';
import { setPoints } from '../../store/redux/points';
import styles from '../../styles/screens/questions/Memo.module.css';
import { AnswersConfigProps, LocationPointsProps, MemoQuestionProps } from '../../types';
import Sizes from '../../constans/sizes';
import AlertNoTitle from '../../components/AlertNoTitle';

function shuffle(array:any) {
    const length = array.length;
    for (let i = length; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * i);
        const currentIndex = i - 1;
        const temp = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temp;
    }
    return array;
}

const parser = (str:string) => {
  [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
      str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
      str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
  });
  return str;
}

const Memo = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie poziomu trudności
     */
    const levelgame = useSelector((state:any) => state.levelgame.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie trybu gry
     */
    const gamemode = useSelector((state:any) => state.gamemode.value);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
    const locationspoints:LocationPointsProps[] = useSelector((state:any) => state.points.locationspoints);
    const dispatch = useDispatch();
    const params:any = useLocation();
    const navigate = useNavigate();
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [title, setTitle] = useState('');
    const [cards, setCards] = useState<any>([]);
    const [openCards, setOpenCards] = useState<any>([]);
    const [clearedCards, setClearedCards] = useState<any>({});
    const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
    const timeout:any = useRef(null);
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState<any>('');
    const [resultAnswer, setResultAnswer] = useState(false);
    const memoquestion:MemoQuestionProps | undefined = useMemo(()=>Questions.find(item=>item.idquestion===params.state.idquestion),[params.state.idquestion]) as MemoQuestionProps | undefined;
    const _time = useMemo(()=>memoquestion?.time[levelgame],[memoquestion,levelgame]);
    const badAnswerMessage = useMemo(()=>memoquestion?.badanswermessage,[memoquestion]);
    const goodAnswerMessage = useMemo(()=>memoquestion?.goodanswermessage,[memoquestion]);
    const [startTimer, setStartTimer] = useState(false);
    const [showBackAlert, setShowBackAlert] = useState(false);
    const [showEndAlert, setShowEndAlert] = useState(false);
    const [leavePage, setLeavePage] = useState(false);
    const [addPoints, setAddPoints] = useState(true);
    const [answersconfig, setAnswersconfig] = useState<AnswersConfigProps[] | undefined>([]);
    const [nextquestion, setNextQuestion] = useState<{idquestion:number | undefined, type:string | undefined} | undefined>();
    const totalPoints:number = useMemo(()=>(params.state.points!==undefined?params.state.points:0),[params.state.points]);
    const nextquestions:{idquestion:number,type:string}[] = useMemo(()=>(params.state.nextquestion),[params.state.nextquestion]);
    const idpark:number = useMemo(()=>(params.state.idpark),[params.state.idpark]);
    const idlocation:number = useMemo(()=>(params.state.idlocation),[params.state.idlocation]);
    const parkName:string = useMemo(()=>(params.state.parkName),[params.state.parkName]);
    const logo:any = useMemo<any>(()=>(params.state.parkLogo),[params.state.parkLogo]);
    const disable = () => {
      setShouldDisableAllCards(true);
    };

    const enable = () => {
      setShouldDisableAllCards(false);
    };
      /**
       * Funkcja sprawdzająca typ odsłoniętych kafli
       */
      const evaluate = useCallback(() => {
        const [first, second] = openCards;
        enable();
        if (cards[first].type === cards[second].type) {
          setClearedCards((prev:any) => ({ ...prev, [cards[first].type]: true }));
          setOpenCards([]);
          return;
        }
        timeout.current = setTimeout(() => {
          setOpenCards([]);
        }, 500);
      },[cards,openCards]);
      /**
       * Funkcja wywoływana przy kliknięciu w kafel
       * @param {number} index indeks wybranego kafla
       */
      const handleCardClick = (index:number) => {
        if (openCards.length === 1) {
          setOpenCards((prev:any) => [...prev, index]);
          disable();
        } else {
          clearTimeout(timeout.current);
          setOpenCards([index]);
        }
      };
      /**
       * Funkcja wywoływana w przypadku zmiany zawartości tabeli openCards
       */
      useEffect(() => {
        let timeout:any = null;
        if (openCards.length === 2) {
          timeout = setTimeout(evaluate, 1000);
        }
        return () => {
          clearTimeout(timeout);
        };
      }, [openCards,evaluate]);
      /**
       * Funkcja sprawdza czy kafel jest odwrócony
       * @param {number} index 
       * @returns zwraca true | false
       */
      const checkIsFlipped = (index:number) => {
        return openCards.includes(index);
      };
      /**
       * Funkcja sprawdza czy kafel jest aktywny
       * @param {number} index 
       * @returns zwraca true | false
       */
      const checkIsInactive = (card:any) => {
        return Boolean(clearedCards[card.type]);
      };
      /**
       * Funkcja tworzy kafle memo
       */
      const createMemo = useCallback(() => {
        setClearedCards({});
        setOpenCards([]);
        setShouldDisableAllCards(false);
        setTitle(memoquestion?.title!==undefined?memoquestion.title:'');
        if(memoquestion!==undefined) setCards(shuffle(memoquestion.tiles.concat(memoquestion.tiles)));
        pause();
        setStartTimer(true);
        if(memoquestion!==undefined) setAnswersconfig(memoquestion.answersconfig);
        setAddPoints(memoquestion?.addPoints===undefined?true:memoquestion.addPoints);
        /*eslint-disable */
      },[memoquestion?.tiles,memoquestion?.answersconfig]);
      /*eslint-disable */

      /**
     * Funkcja ustawiąjąca parametry dla wybranego pytania
     */
    useEffect(()=>{
      createMemo();
    },[createMemo]);
    /**
     * Funkcja wywoływana po potwierdzeniu wyjścia z pytania, przekierowywuje do ekranu lokacji 
     */
    const back = () => {
        setLeavePage(true);
        setShowBackAlert(false);
        navigate(-1);
    }
    /**
     * Funkcja wywoływana po naciśnięciu guzika "Sprawdź/Dalej", sprawdza czy odpowiedź jest prawidłowa
     */
    const check = useCallback(() => {
        setStartTimer(false);
        clearTimer();
        const result:boolean | undefined = Object.keys(clearedCards).length === memoquestion?.tiles.length && Object.keys(clearedCards).length !== 0
        const answerconfig:AnswersConfigProps | undefined = answersconfig?.find((item:AnswersConfigProps)=>item.correct===result);
        const _nextquestion = {idquestion:answerconfig?.idquestion,type:answerconfig?.type};
        setNextQuestion(_nextquestion);
        setResultAnswer(result);
        if(result!==undefined) setMessageAlert(result?goodAnswerMessage:badAnswerMessage);
        if(result!==undefined && sound!==undefined && sound==='on') SoundEffect(result?'good':'bad');
        setShowAlert(true);
    },[badAnswerMessage, clearedCards, goodAnswerMessage, memoquestion?.tiles.length,sound]);
    /**
     * Funkcja sprawdzająca kompletne ułożenie memo
     */
     const checkCompletion = useCallback(() => {
      if (Object.keys(clearedCards).length === memoquestion?.tiles.length && Object.keys(clearedCards).length !== 0) {
        check();
      }
    },[clearedCards,memoquestion?.tiles.length,check]);
    /**
     * Funkcja wywoływana w przypadku zmiany zawartości tabeli clearedCards
     */
    useEffect(() => {
      checkCompletion();
    }, [checkCompletion]);
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu poprawnej lub błędnej odpowiedzi
     */
    const confirmResult = () => {
        setShowAlert(false);
        if(resultAnswer) nextStep();
        else if(!resultAnswer && nextquestion?.idquestion!==undefined) nextStep(false);
        else createMemo();
    }
    /**
     * Funkcja ustawia kolejne pytania lub wywołuje funkcję zapisująca punkty i wychodzącą z wyzwania
     */
     const nextStep = (_goodanswer:boolean=true) => {
      const points = LevelPoints && LevelPoints.length>0 && levelgame!==undefined?addPoints?_goodanswer?LevelPoints[levelgame]+totalPoints:totalPoints:totalPoints:totalPoints;
      if(nextquestion!==undefined && nextquestion.idquestion!==undefined && nextquestion.type!==undefined) gotoNextQuestion(nextquestion.idquestion,nextquestion.type,nextquestions,points);
      else if(nextquestions!==undefined){
          let firstquestion:{idquestion:number,type:string} = nextquestions[0];
          let _nextquestions:{idquestion:number,type:string}[] = [];
          nextquestions.forEach((item:{idquestion:number,type:string},index:number)=>{
              if(index!==0) _nextquestions.push(item);
          });
          if(firstquestion!==undefined && firstquestion!==null) gotoNextQuestion(firstquestion.idquestion,firstquestion.type,_nextquestions,points);
          else savePoints(points);
      }
      else savePoints(points);
    }
    /**
     * Funkcja przekierowywuje do następnego pytania
     * @param {number} _idquestion ID pytania
     * @param {string} _type typ pytania
     * @param {{idquestion:number,type:string}[]} _nextquestions następne pytania
     * @param {number} _points zdobyte punkty
     */
     const gotoNextQuestion = (_idquestion:number,_type:string,_nextquestions:{idquestion:number,type:string}[],_points:number) => {
      if(sound!==undefined && sound==='on') SoundEffect('nextQuestion');
      const pathname = params.pathname.replace("memo","") + _type;
      navigate(pathname,{
          replace:true,
          state:{
              idquestion:_idquestion,nextquestion:_nextquestions,idlocation:idlocation,points:_points,idpark:idpark,parkName:parkName,parkLogo:logo
          }
      });
  }
    /**
     * Funkcja aktualizuje zdobyte przez gracza punkty
     * @param {number} points punkty zdobyte dotej pory przez gracza 
     */
     const savePoints = (points:number) => {
        setShowEndAlert(true);
        if(points){
            const _locationpoints:LocationPointsProps[] = locationspoints!==undefined?[...locationspoints]:[];
            const index:number = _locationpoints.findIndex((item:LocationPointsProps) => item.idlocation === idlocation);
            if (index !== -1) _locationpoints[index] = { idlocation:idlocation, points:_locationpoints[index].points+points, parkID:idpark,gamemode:gamemode};
            else _locationpoints.push({idlocation,points,parkID:idpark,gamemode})
            dispatch(setPoints({locationspoints:_locationpoints}));
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu o ukończeniu wyzwania, przekierowywuje do ekranu parku
     */
    const goPark = () => {
      setLeavePage(true);
      setShowEndAlert(false);
      navigate(-2);
    }
    /**
     * Funkcja przechwytująca naciśnięcie przycisku wstecz w przeglądarce
     */
    window.onpopstate = () => {
        if(!leavePage && params.pathname==='/park/location/memo'){
             navigate(1);
             setShowBackAlert(true);
        }
    };
    /**
     * Funkcja wywołana po anulowaniu opuszczenia strony
     */
    const cancelBack = () => {
        setShowBackAlert(false);
        createMemo();
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
      var value:number = 12;
      switch(_fontsize){
          case 'normal':
              value = Sizes.defaultNormalFontSize;
              break;
          case 'medium':
              value = Sizes.defaultMediumFontSize;
              break;
          case 'big':
              value = Sizes.defaultBigFontSize;
              break;
      }
      return value;
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            {showBackAlert && <AlertNoTitle show={showBackAlert} cancel={cancelBack} confirm={back} 
             message='Chcesz opuścić wyzwanie?' buttonCancel='Anuluj' buttonConfirm='Opuść'/>}
            {showAlert && <AlertNoTitle show={showAlert} cancel={()=>{confirmResult()}} message={messageAlert} buttonCancel='OK'/>}
            {showEndAlert && <AlertNoTitle show={showEndAlert} confirm={goPark} message='Gratulacje! Wyzwanie zakończone!' buttonConfirm='Wróć na szlak'/>}
            <LeftTabBar idpark={idpark} logo={logo} visibilityButtons='hidden'/>
            <div className={styles.questionContainer}>  
                <Header name="MEMO" menuVisibility='hidden'/>
                <div className={styles.questionContent}>
                    {_time!==undefined?<Timer namePage='memo' start={startTimer} time={_time} result={check} ></Timer>:null}
                    {title && title!==''?<div className={styles.title} style={{fontSize:getFontSize()+10,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : parser(title) }}/>:null}
                    <div className={styles.memoContainer}>
                        { cards!==null && cards!==undefined?
                            cards.map((card: any,index:any) => {
                                return (
                                    <MemoCard
                                        key={index}
                                        card={card}
                                        index={index}
                                        isDisabled={shouldDisableAllCards}
                                        isInactive={checkIsInactive(card)}
                                        isFlipped={checkIsFlipped(index)}
                                        onClick={handleCardClick}
                                    />
                                );
                            })
                            :null
                        }
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Memo;
