import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from '../styles/components/PointsItem.module.css';
import { LocationPointsProps, LocationProps, LocationStatusSettingsProps, ParksBadgesProps } from '../types';
import Locations from '../data/locations';
import LocationStatusSettings from '../constans/locationstatussettings';
import Sizes from '../constans/sizes';
import Colors from '../constans/colors';

const PointsItem = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ilości punktów dla parków
     */
    const badges:ParksBadgesProps[] = useSelector((state:any) => state.badges.values);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
    const locationspoints:LocationPointsProps[] = useSelector((state:any) => state.points.locationspoints);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = useCallback(() => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    },[_fontsize]);
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const _badges:any[] = useMemo(()=>badges.map((item:ParksBadgesProps)=>{
        if(item.idpark===props.idpark){
            return  <div key={'badge'+item.color} className={styles.containerLogo}>
                        <div className={styles.circle} style={{backgroundColor:item.color}}>
                            <img className={contrast==='on'?styles.logoContrast:styles.logo} src={props.logo} alt='Logo parku'/>
                        </div>
                        <div className={styles.ribbon}>
                        <div className={styles.ribbonLeft} style={{borderColor:`${item.color} ${item.color} transparent ${item.color}`}}></div>
                            <div className={styles.ribbonRight} style={{borderColor:`${item.color} ${item.color} transparent ${item.color}`}}></div>
                        </div>
                    </div>
        }
        return null;
        
     }),[badges,props.idpark,props.logo,contrast]);
    var idx:number = 0;
    const _locations:any[] = useMemo(()=>locationspoints.map((item:LocationPointsProps)=>{
        if(item.parkID===props.idpark){
            const location:LocationProps = Locations.find((element:LocationProps)=>element.idlocation===item.idlocation) as LocationProps;
            if(location!==undefined){
                idx++;
                const status:LocationStatusSettingsProps = LocationStatusSettings.find((_status:LocationStatusSettingsProps)=>(_status.scale[0]*location.maxpoints)/100<item.points&&(_status.scale[1]*location.maxpoints)/100>=item.points) as LocationStatusSettingsProps;
                return (
                    <div key={'location'+idx} className={styles.listcontainer}>
                        <div className={styles.circlecontainer} style={{backgroundColor:contrast==='on'?'#000':status!==undefined?status.color:Colors.notPassed,borderWidth:2,borderStyle:'solid',borderColor:contrast==='on'?'#fff':'#000'}}>
                            <p className={styles.idcolor} style={{color:contrast==='on'?'#fff':status!==undefined&&status.color==='#facc3d'?'#000':'#fff',fontSize:getFontSize()+4}}>{idx}</p>
                        </div>
                        <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>{location.name} - zdobyto <span className={styles.pz}>{item.points} PZ</span></p>
                    </div>
                )
            }
            return null;
        }
        return null;
    }),[locationspoints,props.idpark,idx,getFontSize,contrast]);
    return (
        <div className={styles.container}>
            <p className={styles.name} style={{fontSize:getFontSize()+14,color:contrast==='on'?'#fff':'#000'}}>{props.name}</p>
            <div className={styles.badges}>
                {
                    _badges
                }
            </div>
            <div className={styles.locations}>
            {
                _locations
            }
            </div>
        </div>
    );
}

export default PointsItem;