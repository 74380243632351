/**
 * Ekran regulaminu Parku
 */
import { useMemo } from "react";
import Container from "../components/Container";
import LeftTabBar from "../components/LeftTabBar";
import styles from '../styles/screens/ParkStatute.module.css';
import Header from "../components/Header";
import { ParkProps } from "../types";
import Parks from '../data/parks';
import { useLocation } from "react-router-dom";
import colors from "../constans/colors";
import Sizes from '../constans/sizes';
import { useSelector } from "react-redux";

const description = `<ul>
<li>1. Poruszaj się wyłącznie po znakowanych szlakach. Mogą to być m.in. szlaki piesze, narciarskie, rowerowe lub ścieżki dydaktyczne. Nie wszystkie miejsca na obszarze Parku Narodowego są udostępnione turystom, ponieważ pozostawiamy tą przestrzeń dzikiej przyrodzie. Sieć znakowanych szlaków jest na tyle rozbudowana, że umożliwia poznanie walorów danego parku z jednoczesną troską o ich zachowanie. Nie wszędzie musisz być, Twoja świadoma rezygnacja z zejścia ze szlaku to wyraz szacunku do przyrody.<br/><br/></li>
<li>2. Jeśli wędrujesz szlakiem, to nie schodź z niego na postojach. Zwłaszcza w miejscach widokowych i zachęcających do odpoczynku nie oddalaj się od ścieżki. Wydeptywanie okolic szlaków lub ścieżek powoduje nie tylko erozję, która niszczy glebę i ścieżki, ale także może doprowadzić do zaniku wielu cennych gatunków, których stanowiska znajdują się w tych miejscach.<br/><br/></li>
<li>3. Zanim wyruszysz na wędrówkę - przygotuj się do niej! Przeczytaj informacje o Parku Narodowym na jego stronie internetowej, przestudiuj mapę, sprawdź prognozę pogody. Obszar Parku jest udostępniany dzięki szlakom i ścieżkom, ale warunki terenowe i atmosferyczne mogą się różnić od Twoich wyobrażeń. Nie daj się zaskoczyć!<br/><br/></li>
<li>4. Przed wyruszeniem w trasę określ precyzyjnie cel swojej wędrówki uwzględniając fizyczne możliwości (zarówno swoje jak i twoich współtowarzyszy, a także ich stan zdrowia). Kontroluj swoje możliwości i czas przebytej już drogi. Jeśli jest on dużo dłuższy od planowanego - skróć trasę lub zawróć. Zawsze miej przy sobie mapę terenu, po którym wędrujesz. Dzięki temu będziesz wiedzieć gdzie jesteś, jak daleko znajduje się cel Twojej wędrówki i jakie trudności terenowe czekają Cię po drodze. Będziesz bezpieczny, a w przypadku wzywania pomocy dużo łatwiej określisz swoje położenie.<br/><br/></li>
<li>5. Nie zrywaj i nie niszcz żadnych roślin i grzybów na obszarze Parku Narodowego. Cała przyroda w tym miejscu podlega ochronie. Nawet jeśli niektóre rośliny wydają Ci się niepozorne lub pospolite, to nie jest powód do ich zrywania. Ciesz się ich widokiem w naturze!<br/><br/></li>
<li>6. Nie płosz zwierząt, które spotkasz podczas swojej wędrówki. Nie stwarzaj także sytuacji, w których Twoja obecność mogłaby przestraszyć dzikich mieszkańców Parku Narodowego. Szanuj wszystkie zwierzęta, przede wszystkim te, które są dużo mniejsze od Ciebie.<br/><br/></li>
<li>7. Poruszanie się po Parku Narodowym nocą jest zabronione. Czas po zmroku to często okres większej aktywności niektórych zwierząt. Pozostaw go im do dyspozycji. Dla Ciebie także będzie to bardziej bezpieczne.<br/><br/></li>
<li>8. Zachowuj ciszę wędrując po Parku Narodowym. Nie krzycz i nie hałasuj. Nie włączaj głośników, które ingerują w naturalny krajobraz dźwiękowy miejsca, w którym jesteś. Przyroda, która Cię otacza, pełna jest różnych dźwięków. Wsłuchaj się w odgłosy przyrody. Spróbuj je usłyszeć!<br/><br/></li>
<li>9. Fotografuj tylko z wykorzystaniem aparatu fotograficznego lub telefonu. Nie używaj dronów. Mogą one płoszyć ptaki i inne zwierzęta z miejsc ich odpoczynku.<br/><br/></li>
<li>10. Jeśli chcesz biwakować w Parku Narodowym, wybieraj wyłącznie miejsca przeznaczone do tego. Są one wyznaczone w lokalizacjach bezpiecznych zarówno dla Ciebie jak i dla otaczającej Cię przyrody. Nie w każdym Parku spotkasz takie miejsce. Wtedy wybierz teren poza obszarem chronionym.<br/><br/></li>
<li>11. Nie rozpalaj ognisk poza miejscami wyznaczonymi. Zawsze zwracaj uwagę na to, czy warunki są odpowiednie do zapalenia ognia i czy nie spowoduje on pożaru. Kontroluj na bieżąco palące się ognisko i nie pozostawiaj go bez nadzoru. Jeśli na obszarze Parku Narodowego nie ma miejsca do rozpalenia ogniska, to pamiętaj, że pod żadnym pozorem nie możesz go rozpalić!<br/><br/></li>
<li>12. Korzystaj z informacji i aplikacji przygotowanych przez Park Narodowy który
odwiedzasz lub do którego się wybierasz. Są one przygotowywane z myślą o
odwiedzających, aby w jak najpełniejszy sposób przybliżyć im to miejsce. Dzięki temu
będziesz na bieżąco z komunikatami dotyczącymi np. dostępności lub utrudnień na
szlakach, a także wzbogacisz swoje wędrówki i poznawanie Parku o wiedzę na temat
jego dziedzictwa przyrodniczego i kulturowego.<br/><br/></li>
<li>13. Zapłać za bilet wstępu lub za udostępnienie obszarów Parku Narodowego. To realne wsparcie dla ochrony przyrody tego miejsca i Twój współudział w docenianiu naszego wspólnego dziedzictwa. Opłaty za wstęp do Parków Narodowych pozwalają chociażby na wykonywanie remontów infrastruktury turystycznej, a także wspierają działania organizacji ratownictwa górskiego działających na obszarach danego parku (np. GOPR i TOPR).<br/><br/></li>
<li>14. Jeżeli na szlaku turystycznym spotkasz wielu turystów, to poruszaj się jego prawą
stroną. Ułatwi to zdecydowanie sprawną wędrówkę w obu kierunkach. Ten
zwyczajowo przyjęty kierunek marszu ma duże znaczenie zwłaszcza w szczycie
sezonu na najczęściej uczęszczanych trasach.<br/><br/></li>
<li>15. W górach zwyczajowo przyjmuje się, że pierwszeństwo mają osoby idące pod górę. Jeśli znajdujesz się na szlaku w jego wąskim miejscu, to przepuść tych turystów,
którzy zmierzają coraz wyżej.<br/><br/></li>
<li>16. Pamiętaj, żeby zabrać ze sobą wszystko to, co wniosłeś do Parku Narodowego.
Dotyczy to także pustych opakowań oraz resztek jedzenia m.in. ogryzki i obierki z
owoców. Nie przyzwyczajaj zwierząt do żywienia się pokarmem spożywanym przez
ludzi. Jeśli byłeś w stanie wnieść pełną butelkę wody, to na pewno poradzisz sobie z
wyniesieniem opróżnionej. Nie zostawiaj także w Parku Narodowym ulotek, które
zabrałeś z punktów informacyjnych. Jeśli nie ma koszy na śmieci, to jest tak celowo,
aby nie przyciągały zwierząt. Swoje śmieci zabierz ze sobą. Zawsze!!!<br/><br/></li>
<li>17. Nie pal tytoniu ani e-papierosów. Dym zawiera szkodliwe substancje, które są
niebezpieczne zarówno dla ludzi jak i dla otaczającej je przyrody. Odetchnij pełną
piersią i czystym powietrzem, o jakie trudno np. w zatłoczonych miastach. Pamiętaj,
że niedopałki mogą być przyczyną pożaru, o zaprószenie ognia wcale nie jest trudno.<br/><br/></li>
<li>18. Przyglądaj się przyrodzie, która Cię otacza. Pamiętaj, że Parki Narodowe zajmują w
naszym kraju zaledwie około 1% powierzchni i są najwyższą formą ochrony. Oznacza
to, że przyroda jest tam wyjątkowa. Skorzystaj z materiałów edukacyjnych
tworzonych przez Parki Narodowe lub aplikacji do rozpoznawania roślin i zwierząt.
To wyjątkowe doświadczenie przebywania w tak cennym przyrodniczo miejscu
możesz wzbogacić angażując wiele zmysłów. Słuchaj, obserwuj, wąchaj!<br/><br/></li>
<li>19. Śledź na bieżąco komunikaty udostępniane na stronach i w mediach
społecznościowych Parków Narodowych. Nie zaskoczy Cię w ten sposób zmiana
przebiegu szlaku turystycznego lub jego tymczasowe zamknięcia oraz nie ominie
informacja o ciekawych wydarzeniach
w okolicy.<br/><br/></li>
<li>20. Jeśli jest to możliwe w danym Parku, to korzystaj z możliwości zwiedzania Parku w
grupie zorganizowanej, prowadzonej przez przewodnika. Są to osoby posiadające
wiedzę na temat walorów i wartości danego miejsca oraz umiejętności jej
przekazywania. Dzięki ich doświadczeniu będziesz mógł czuć się
bezpiecznie.<br/><br/></li>
<li>21. Jeśli spotkasz dzikie zwierzę, to nie wchodź z nim w interakcję. Nawet jeśli lis
wygląda na zagubionego, a łania chciałaby jeść z ręki. Pamiętaj, że to dzikie
zwierzęta i zarówno Ty jak i one będą bezpieczniejsze, jeśli nie będziesz ich karmił,
głaskał, przywoływał czy zbliżał się. Oswajanie dzikich zwierząt żyjących w naturze
nie służy niczemu dobremu. W Parku Narodowym mają one wszytko to, czego
potrzebują, aby przeżyć.<br/><br/></li>
<li>22. Nie niszcz infrastruktury turystycznej. Jeśli przy szlaku znajdują się ławki, to mogą
one posłużyć tym, którzy potrzebują odpocząć. Nikt jednak nie skorzysta z
połamanych lub ubrudzonych. Nie podpisuj się na elementach infrastruktury i nie
zaklejaj ich naklejkami, nawet z najbardziej mądrymi hasłami. To nie słupy reklamowe.
Pamiętaj także, żeby zdejmować raki lub raczki, jeśli podłoże nie jest pokryte
śniegiem lub lodem.<br/><br/></li>
<li>23. Nie zabieraj niczego z Parku Narodowego, nawet jeśli to mała rzecz. Kamyczki, liście
czy szyszki to także część przyrody, a ona jako całość podlega ochronie na tym
obszarze. Wiele z tych drobnych rzeczy jest bardzo ważnych dla całego ekosystemu
i niezbędnych do jego funkcjonowania. Nie niszcz bezsensownie nawet
najdrobniejszej cząstki przyrody. Pamiętaj, że najcenniejsze, co możesz zabrać z
pobytu w danym Parku Narodowym, to wspomnienia, które zostaną w Twojej pamięci
na całe życie!<br/><br/></li>
<li>24. Korzystaj z przewodników i materiałów opracowywanych przez Parki Narodowe.
Odwiedzaj także ich ośrodki edukacyjne. To rzetelne źródło wiedzy i informacji na
temat unikatowych miejsc, w których się znajdujesz.<br/><br/></li>
<li>25. Nie wprowadzaj do Parku zwierząt domowych. Parki Narodowe to jedynie 1%
powierzchni naszego kraju. Są one ostoją różnorodności biologicznej i dzikości. Ten
niewielki skrawek pozostaw dzikim zwierzętom. Nie niepokój ich obecnością i
zapachem zwierząt domowych, nie narażaj na zarażenie się chorobami typowymi dla
zwierząt domowych. Nawet najgrzeczniejszy pies może zareagować na
przebiegającą sarnę lub zająca pogonią.<br/><br/></li>
<li>26. Zaufaj pracownikom Parku Narodowego. Wielu z nich poświęciło swoje życie badaniom i obserwacji przyrody Parku Narodowego. Znają doskonale wartość przyrodniczą i wiedzą, jak ją chronić. Podejmowane przez nich działania, mają na celu zachowanie naszego wspólnego dziedzictwa dla przyszłych pokoleń. Możesz mieć w tym swój udział! Pamiętaj, że pracownicy Parków Narodowych swoje działania opierają o lata doświadczeń i rzetelną wiedzę przyrodniczą.<br/><br/></li>
<li>27. Przed wyruszeniem na szlak sprawdź prognozę pogody. Nie planuj wędrówek kiedy
zapowiadane są burze. Jeśli pogoda zaczyna się psuć - zawróć. Kiedy jednak spotka
Cię burza - w zależności od miejsca, gdzie jesteś - jak najszybciej zejdź ze szczytu
lub wyjdź z wody. Nie pozostawaj na otwartym terenie, nie szukaj schronienia pod
drzewami.<br/><br/></li>
<li>28. Miej przy sobie w pełni naładowany telefon z wpisanymi numerami ratunkowymi
właściwymi dla terenu, w którym się znajdujesz. Jeśli znajdziesz się w sytuacji, w
której będziesz potrzebować pomocy, Twoja pamięć może zawieść.<br/><br/></li>
<li>29. Pamiętaj, że ratownicy górscy ratują ludzkie życie w trudnych sytuacjach, nie działają
jednak jak prywatny transport. Przed wyruszeniem w góry zainstaluj aplikację
Ratunek, które ułatwi zlokalizowanie poszkodowanego. Ale pamiętaj o
najważniejszym żadna aplikacja ani żaden telefon nie zastąpi tego, co najistotniejsze
w górach czyli zdrowego rozsądku!<br/><br/></li>
<li>30. Zabieraj ze sobą na wędrówkę tylko potrzebne rzeczy. Pamiętaj, że wraz ze
wzrostem wysokości spada temperatura, a pogoda może niespodziewanie ulec
zmianie. Wówczas będziesz polegać tylko na tym, co masz ze sobą. Zbyt duża ilość
rzeczy zdecydowanie utrudni Ci jednak poruszanie się. Pamiętaj o wodzie i
dodatkowym ciepłym okryciu.<br/><br/></li>
<li>31. Przed wyruszeniem na szlak wodny w Parku Narodowym zawsze sprawdź w jakim okresie dopuszczalne jest poruszanie się po nim. Niektóre miejsca m.in. ze względu na lęgi ptaków wodnych, są wyłączone z udostępniania w pewnych okresach roku. Zawsze poruszaj się tylko po wyznaczonych szlakach wodnych!<br/><br/></li>
<li>32. Korzystając ze szlaków wodnych zawsze używaj kamizelki asekuracyjnej. Nałóż ją
we właściwy sposób i noś przez cały czas przebywania na wodzie.<br/><br/></li>
<li>33. Płynąc kajakiem przemieszczaj się tylko po wyznaczonych szlakach wodnych.
Uważnie obserwuj powierzchnię wody. Jeśli zauważysz niebezpieczne miejsce lub
przeszkodę, postaraj się ją ominąć z daleka lub przenieść kajak. Nie wpływaj
w miejsca, w których tworzą się zawirowania wody.<br/><br/></li>
<li>34. Zawsze sprawdź regulamin udostępniania Parku Narodowego. Określa on sposób,
w jaki bezpiecznie i z poszanowaniem przyrody turyści mogą się po nim poruszać.
Zwróć uwagę na udostępnione do zwiedzania miejsca oraz terminy, w których można
z nich korzystać. Pamiętaj, że regulamin jest najlepszym kompromisem
zapewniającym zarówno turystom możliwość doświadczenia dzikiej przyrody, a także
troskliwie dbającym o zapewnienie najlepszej ochrony przyrodzie Parku Narodowego.<br/><br/></li>
</ul>`

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

const ParkStatute = (props:any) => {
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Ustawienie stanów początkowych, zmienych wykorzystywanych w ekranie
     */
    const params:any = useLocation();
    /**
    * Funkcja ustawia parametry dla wybranego parku
    */
    const park:ParkProps | undefined = useMemo(()=>(Parks.find((item:ParkProps) => item.idpark===params.state.idpark)),[params.state.idpark]);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            <LeftTabBar idpark={park?.idpark} logo={park?.logo} guide={true}/>
            <div className={styles.statuteContainer}>  
                <Header name="ZASADY W PARKU" color={colors.headerParkStatute}/>
                <div className={styles.statuteContent}>
                    <div className={styles.description} style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html :  parser(description)}}/>
                </div>
            </div>
        </Container>
    )
}

export default ParkStatute;