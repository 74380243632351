/**
 * Obiekt przechowujący informacje o wybranym trybie gry
 */
import { createSlice } from "@reduxjs/toolkit";

const gameModeSlice = createSlice({
    name: 'gamemode',
    initialState: { 
        value: 2
    },
    reducers: {
        setGameMode: (state, action) => {
            state.value = action.payload.value;
        }
    }
});

export const setGameMode = gameModeSlice.actions.setGameMode;
export default gameModeSlice.reducer;
