import { useEffect, useState, KeyboardEvent } from "react";
import styles from '../styles/components/ParkLogoMarker.module.css';
import Colors from '../constans/colors';
import { ParkPointsProps } from "../types";
import ParkStatusSettings from '../constans/parkstatussettings';
import { useSelector } from "react-redux";
import SoundEffect from "./SoundEffect";

const ParkLogoMarker = (props:{idpark:number,maxpoints:number,left:number,top:number,setPark:Function,size:number,logo:any,name:string}) => {
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane ilości punktów dla parków
     */
    const parkspoints:ParkPointsProps[] = useSelector((state:any) => state.points.parkspoints);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [flagColor, setFlagColor] = useState(Colors.notPassed);
    /**
     * Funkcja ustawiająca status parku na podstawie zdobytych punktów
     */
    useEffect(()=>{
        if(parkspoints!==undefined){
            const find:ParkPointsProps | undefined = parkspoints.find((item:ParkPointsProps)=>item.idpark===props.idpark);
            if(find!==undefined){
                const status = ParkStatusSettings.find(item=>(item.scale[0]*props.maxpoints)/100<find.points&&(item.scale[1]*props.maxpoints)/100>=find.points);
                if(status!==undefined) setFlagColor(status.color);
            }
            else setFlagColor(Colors.notPassed);
        }
        else setFlagColor(Colors.notPassed);
    },[parkspoints,props.idpark,props.maxpoints]);
    /**
     * Funkcja wywoływana po naciśnięciu znaku parku
     */
    const onClick = () => {
        if(sound!==undefined && sound==='on') SoundEffect('gotoPark');
        props.setPark();
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            if(sound!==undefined && sound==='on') SoundEffect('gotoPark');
            event.preventDefault(); 
            props.setPark();
        }         
    }
    return (
        <li>
            <button id={"parkSign"+props.idpark} className={contrast==='on'?styles.containermarkerContrast:styles.containermarker} 
                style={{backgroundColor:flagColor,
                    width:props.size,
                    height:props.size,
                    borderRadius:props.size,
                    left:props.left-props.size/2,
                    top:props.top-props.size/2}} 
                onClick={onClick} onKeyDown={onKeyDown}>
                    <h3 className={styles.circle}>
                        <img className={styles.logo} src={props.logo} alt={`Logo ${props.name}`}/>
                    </h3>
            </button>
        </li>
        
    )
}

export default ParkLogoMarker;

