/**
 * Ekran Ciekawostek Parku
 */
import { useMemo } from "react";
import Container from "../components/Container";
import LeftTabBar from "../components/LeftTabBar";
import styles from '../styles/screens/ParkCuriosities.module.css';
import Header from "../components/Header";
import { CuriositiesProps, ParkProps } from "../types";
import Parks from '../data/parks';
import { useLocation } from "react-router-dom";
import colors from "../constans/colors";
import Sizes from '../constans/sizes';
import { useSelector } from "react-redux";

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

const ParkCuriosities = (props:any) => {
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Ustawienie stanów początkowych, zmienych wykorzystywanych w ekranie Parków
     */
    const params:any = useLocation();
    /**
    * Funkcja ustawia parametry dla wybranego parku
    */
    const park:ParkProps | undefined = useMemo(()=>(Parks.find((item:ParkProps) => item.idpark===params.state.idpark)),[params.state.idpark]);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }

    const CuriositiesItem = (item:CuriositiesProps) => {
        return (
            <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                <div className={styles.curiositiesContentContainer}>
                    {item.src!==undefined?<div className={styles.imgContainer}>
                        <img className={contrast==='on'?styles.imgContrast:styles.img} src={item.src} alt={item.alt}/>
                    </div>:null}
                    <div className={styles.descriptionContainer} style={{width:item.src!==undefined?'50%':'100%'}}>
                        <div className={styles.description} style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : item.description!==undefined?parser(item.description):'' }}/>
                    </div>
                </div> 
                <div className={styles.hr}></div>
            </div>
            
        );
    }

    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            <LeftTabBar idpark={park?.idpark} logo={park?.logo} guide={true}/>
            <div className={styles.curiositiesContainer}>  
                <Header name="CIEKAWOSTKI" color={colors.headerCuriosities}/>
                <div className={styles.curiositiesContent}>
                    <p style={{fontSize:getFontSize()+10,fontWeight:'bold',textAlign:'left',color:contrast==='on'?'#fff':'#000'}}>CZY WIESZ, ŻE...?</p>
                    {
                        park?.curiosities!==undefined?park?.curiosities.map((item:CuriositiesProps)=><CuriositiesItem key={(Math.random() + 1).toString(36).substring(7)} description={item.description} src={item?.src} alt={item?.alt}/>):null
                    }
                </div>
            </div>
        </Container>
    )
}

export default ParkCuriosities;