/**
 * Komponent karty memo
 */
import { useSelector } from 'react-redux';
import styles from '../styles/components/MemoCard.module.css';

const MemoCard = ({ onClick, card, index, isInactive, isFlipped, isDisabled }:{ onClick:any, card:any, index:number, isInactive:Boolean, isFlipped:Boolean, isDisabled:Boolean }) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
      * Funkcja wywoływana po kliknięciu w kartę memo
      */
    const handleClick = () => {
       !isFlipped && !isDisabled && onClick(index);
    };

    return (
        <div onClick={handleClick} className={styles.container}
       style={{transform:isFlipped?'rotateY(180deg)':'rotateY(0deg)',opacity:isInactive?0:1,borderWidth:1,borderStyle:'solid',borderColor:contrast==='on'?'#fff':'#f08733'}} >
            <div className={styles.card} style={{opacity:isFlipped?0:1}}>
                <img src={require('../assets/images/defaults/logo.png')} className={styles.img} alt='logo aplikacji'/>
            </div>
            <div className={styles.card} style={{transform:isFlipped?'rotateY(180deg)':'rotateY(0deg)'}}>
                <img src={card.image} className={styles.img} style={{opacity:isFlipped?1:0}} alt={card.alt}/>
            </div>
        </div>
    )
}

export default MemoCard;