/**
 * Ekran wyświetlający informacje o regulaminie
 */
import Container from "../../components/Container";
import styles from "../../styles/screens/menu/AboutGame.module.css";
import MenuHeader from "../../components/MenuHeader";
import { useSelector } from "react-redux";
import Sizes from '../../constans/sizes';
import { useNavigate } from "react-router-dom";
import _img_badge_l from "../../assets/images/badges/odznaka_l.webp";
import _img_badge_s from "../../assets/images/badges/odznaka_s.webp";
import _img_badge_t from "../../assets/images/badges/odznaka_t.webp";
import _img_mapa_z_logo from "../../assets/images/defaults/mapa_z_logo.webp";

const AboutGame = () => {
    const navigate = useNavigate();
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja wychodzi z ekranu
     */
     const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}> 
            <div className={styles.statueContainer}>
                <MenuHeader/>
                <div className={styles.statueContent}>
                    <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>O GRZE</p>
                    <div style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}} className={styles.description}>
                        <p>Zostań Odkrywcą Parków Narodowych!</p>
                        <p>Podczas gry czeka na Ciebie mnóstwo rozwijających wyzwań i ciekawych zadań. Jeśli uda Ci się stawić im czoła (a nie mamy wątpliwości, że tak właśnie będzie), wyjdziesz stąd bogatszy o kilka przygód i ogrom bardzo praktycznej wiedzy, która pozwoli Ci czerpać jeszcze więcej przyjemności z wędrówek po lesie.</p>
                        <p>Na początek zadecyduj, gdzie chcesz postawić swoje pierwsze kroki jako odkrywca - wybierz na mapie park, a następnie przygotuj się do podjęcia wyzwania.</p>
                        <br/>
                        <img src={_img_mapa_z_logo} style={{width:'600px'}} alt="mapa Polski z naniesionymi logami Parkow Narodowych"/>
                        <br/><br/>
                        <p>Na szlak wkraczasz wyposażony w przewodnik, dzięki któremu poznasz charakterystyczne cechy parku, zasady w nim panujące oraz przydatne ciekawostki. To jednak nie koniec Twojego ekwipunku. Do dyspozycji masz także atlas, to nieoceniony atrybut każdego odkrywcy, dlatego koniecznie zapoznaj się nim przed rozpoczęciem swojej przygody! W atlasie skatalogujesz również nagrane odgłosy i wymienione pocztówki, a ilustracje zwierząt i roślin umożliwią Ci kolejne wędrówki w rozszerzonej rzeczywistości (AR), ale pamiętaj - tylko tych napotkanych podczas zwiedzania Parków!</p>
                        <p>Po takim przygotowaniu śmiało możesz przystąpić do wyzwań. Przed Tobą aż osiem wyjątkowych miejsc w każdym z parków, a na każdym ze szlaków czekają na Ciebie przeróżne quizy, gry i questy, za które zdobędziesz punkty. Możesz je później wymienić w naszym sklepie na wirtualne pocztówki i pochwalić się nimi znajomym w mediach społecznościowych! Podejmując się wyzwań pogłębisz swoją wiedzę na temat Parków oraz ich wyjątkowych mieszkańców, a potem od razu sprawdzisz ją w praktyce. Gwarantujemy, że złapiesz bakcyla i z zaciekawieniem zrealizujesz wszystkie fotograficzne zadania, nawet jeśli do tej pory nie po drodze Ci było z aparatem. Przez chwilę poczujesz się  jak leśniczy, ornitolog, a nawet dendrolog.</p>
                        <p>W nagrodę otrzymasz ważne tytuły i odznaki, a przede wszystkim poczujesz wielką satysfakcję!</p>
                        <p>W każdym parku masz możliwość zdobyć aż trzy odznaki. Odznaka żółta to poziom podstawowy, odznaka niebieska to średniozaawansowany, z kolei zielona – a osiągają ją tylko prawdziwi znawcy przyrody – zaawansowany. Za poprawne rozwiązanie na poziomie podstawowym otrzymasz 60 punktów, 80 na poziomie średniozaawansowanym i okrągłe 100 punktów na zaawansowanym. Sam zdecyduj, o jaką stawkę zagrasz. Pamiętaj, że możesz zresetować swoje wyniki i ponownie wyruszyć w drogę.</p>
                        <br/>
                        <div>
                        <img src={_img_badge_l} style={{width:'200px'}} alt="odznaka podstawowa"/>
                        <img src={_img_badge_s} style={{width:'200px'}} alt="odznaka średniozaawansowana"/>
                        <img src={_img_badge_t} style={{width:'200px'}} alt="odznaka zaawansowana"/>
                        </div>
                        <br/><br/>
                        <p>Do zobaczenia na szlaku!</p>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default AboutGame;

