/**
 * Ekran atlasu
 */
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Container from "../components/Container";
import Header from "../components/Header";
import LeftTabBar from "../components/LeftTabBar";
import Photos from "../components/Photos";
import Points from "../components/Points";
import Postcards from "../components/Postcards";
import Sounds from "../components/Sounds";
import Parks from '../data/parks';
import styles from '../styles/screens/Atlas.module.css';
import { ParkProps } from "../types";
import Sizes from '../constans/sizes';
import { useSelector } from "react-redux";

const Atlas = (props:any) => {
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const params:any = useLocation();
    const park:ParkProps | undefined = useMemo(()=>(Parks.find((item:ParkProps) => item.idpark===params.state.idpark)),[params.state.idpark]);
    /**
     * Funkcja ustawią zakładkę ZDJĘCIA jako aktywną
     */
    useEffect(()=>{
        showPage('photos');
    },[]);
    /**
     * Funkcja wywoływana po naciśnięciu zakładki - ZDJĘCIA | ODGŁOSY | POCZTÓWKI | PUNKTY
     * @param {string} page nazwa strony 
     */
    const showPage = (page:string) => {
        const photos:HTMLDivElement = document.getElementById("photos") as HTMLDivElement;
        photos.style.display = "none";
        const sounds:HTMLDivElement = document.getElementById("sounds") as HTMLDivElement;
        sounds.style.display = "none";
        const postcards:HTMLDivElement = document.getElementById("postcards") as HTMLDivElement;
        postcards.style.display = "none";
        const points:HTMLDivElement = document.getElementById("points") as HTMLDivElement;
        points.style.display = "none";

        const _page:HTMLDivElement = document.getElementById(page) as HTMLDivElement;
        _page.style.display = "flex";
        const _bt_page:HTMLButtonElement = document.getElementById("bt_"+page) as HTMLButtonElement;
        _bt_page.style.borderBottom = "2px solid #3d836d";


        const tabs:HTMLDivElement = document.getElementById('tabs') as HTMLDivElement;
        for(let i = 0; i < tabs.children.length; i++){
            const tab = tabs.children[i] as HTMLButtonElement;
            if(tab.id !== "bt_"+page) tab.style.borderBottom = "none";
        }
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }

    const escFunction = useCallback((event:any) => {
        if(event.key === "ArrowLeft"){
            const tabs:HTMLDivElement = document.getElementById('tabs') as HTMLDivElement;
            for(let i = 0; i < tabs.children.length; i++){
                const tab = tabs.children[i] as HTMLButtonElement;
                if(tab===document.activeElement){
                     let idx = i-1<0?tabs.children.length-1:i-1;
                     const nexttab = tabs.children[idx] as HTMLButtonElement;
                     nexttab.focus();
                     nexttab.click();
                     return;
                }
            }
        }
        if(event.key === "ArrowRight"){
            const tabs:HTMLDivElement = document.getElementById('tabs') as HTMLDivElement;
            for(let i = 0; i < tabs.children.length; i++){
                const tab = tabs.children[i] as HTMLButtonElement;
                if(tab===document.activeElement){
                     let idx = i+1>=tabs.children.length?0:i+1;
                     const nexttab = tabs.children[idx] as HTMLButtonElement;
                     nexttab.focus();
                     nexttab.click();
                     return;
                }
            }
        }
        if(event.key === "Home"){
            const tabs:HTMLDivElement = document.getElementById('tabs') as HTMLDivElement;
            for(let i = 0; i < tabs.children.length; i++){
                const tab = tabs.children[i] as HTMLButtonElement;
                if(i===0){
                     tab.focus();
                     tab.click();
                }
                else tab.tabIndex = -1;
            }
        }
        if(event.key === "End"){
            const tabs:HTMLDivElement = document.getElementById('tabs') as HTMLDivElement;
            for(let i = 0; i < tabs.children.length; i++){
                const tab = tabs.children[i] as HTMLButtonElement;
                if(i===tabs.children.length-1){
                     tab.focus();
                     tab.click();
                }
                else tab.tabIndex = -1;
            }
        }
    }, []);
    
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            <LeftTabBar idpark={park?.idpark} logo={park?.logo}/>
            <div className={styles.atlasContainer}>
                <Header name="ATLAS"/>
                <div className={styles.atlasContent}>
                    <div id="tabs" className={styles.tabs}>
                        <button id="bt_photos" className={contrast==='on'?styles.tablinkContrast:styles.tablink} style={{fontSize:getFontSize()+4}} onClick={()=>showPage('photos')}>ZDJĘCIA</button>
                        <button tabIndex={-1} id="bt_sounds" className={contrast==='on'?styles.tablinkContrast:styles.tablink} style={{fontSize:getFontSize()+4}} onClick={()=>showPage('sounds')}>ODGŁOSY</button>
                        <button tabIndex={-1} id="bt_postcards" className={contrast==='on'?styles.tablinkContrast:styles.tablink} style={{fontSize:getFontSize()+4}} onClick={()=>showPage('postcards')}>POCZTÓWKI</button>
                        <button tabIndex={-1} id="bt_points" className={contrast==='on'?styles.tablinkContrast:styles.tablink} style={{fontSize:getFontSize()+4}} onClick={()=>showPage('points')}>PUNKTY</button>
                    </div>
                    <Photos id="photos" className={styles.tabcontent} idpark={park?.idpark} logo={park?.logo}/>
                    <Sounds id="sounds" className={styles.tabcontent} fontSize={getFontSize()+4} />
                    <Postcards id="postcards" className={styles.tabcontent} />
                    <Points id="points" className={styles.tabcontent} />
                </div>
            </div>
        </Container>
    )
}

export default Atlas;