import React from 'react';

import styles from '../styles/components/ParkBadgeAlert.module.css';

import logo from '../assets/images/park/1_logo.webp';

const brazowy = '#facc3d';
const srebrny = '#2c7187';
const zloty = '#2b604a';

const Badge = () => {
    return (
        <div>
            <div className={styles.containerLogo}>
                <div className={styles.circle} style={{backgroundColor:brazowy}}>
                    <img className={styles.logo} src={logo} alt='Logo parku'/>
                </div>
                <div className={styles.ribbon}>
                    <div className={styles.ribbonLeft} style={{borderColor:`${brazowy} ${brazowy} transparent ${brazowy}`}}></div>
                    <div className={styles.ribbonRight} style={{borderColor:`${brazowy} ${brazowy} transparent ${brazowy}`}}></div>
                </div>
            </div>
            <div className={styles.containerLogo}>
                <div className={styles.circle} style={{backgroundColor:srebrny}}>
                    <img className={styles.logo} src={logo} alt='Logo parku'/>
                </div>
                <div className={styles.ribbon}>
                    <div className={styles.ribbonLeft} style={{borderColor:`${srebrny} ${srebrny} transparent ${srebrny}`}}></div>
                    <div className={styles.ribbonRight} style={{borderColor:`${srebrny} ${srebrny} transparent ${srebrny}`}}></div>
                </div>
            </div>
            <div className={styles.containerLogo}>
                <div className={styles.circle} style={{backgroundColor:zloty}}>
                    <img className={styles.logo} src={logo} alt='Logo parku'/>
                </div>
                <div className={styles.ribbon}>
                    <div className={styles.ribbonLeft} style={{borderColor:`${zloty} ${zloty} transparent ${zloty}`}}></div>
                    <div className={styles.ribbonRight} style={{borderColor:`${zloty} ${zloty} transparent ${zloty}`}}></div>
                </div>
            </div>
        </div>
    )
}

export default Badge;