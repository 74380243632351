/**
 * Obiekt przechowujący zakupione pocztówki przez gracza
 */
 import { createSlice } from "@reduxjs/toolkit";
 
 const postcardsSlice = createSlice({
     name: 'postcards',
     initialState: { 
         values: []
     },
     reducers: {
         setPostcards: (state, action) => {
            state.values = action.payload.values;
         }
     }
 });
 
 export const setPostcards = postcardsSlice.actions.setPostcards;
 export default postcardsSlice.reducer;