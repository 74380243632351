import { KeyboardEvent, useEffect, useMemo, useState, MouseEvent, TouchEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import next from '../../assets/icons/next.png';
import camera_img from '../../assets/images/defaults/camera_green.svg';
import camera_img_contrast from '../../assets/images/defaults/camera_white.svg';
import Container from '../../components/Container';
import Header from '../../components/Header';
import LeftTabBar from '../../components/LeftTabBar';
import SoundEffect from '../../components/SoundEffect';
import LevelPoints from '../../constans/levelpoints';
import Questions from '../../data/questions';
import { setAnimals } from '../../store/redux/animals';
import { setPoints } from '../../store/redux/points';
import styles from '../../styles/screens/questions/Find.module.css';
import { AnswersConfigProps, FindQuestionProps, LocationPointsProps } from '../../types';
import Sizes from '../../constans/sizes';
import AlertNoTitle from '../../components/AlertNoTitle';

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

const Find = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie poziomu trudności
     */
    const levelgame = useSelector((state:any) => state.levelgame.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie trybu gry
     */
    const gamemode = useSelector((state:any) => state.gamemode.value);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
    const locationspoints:LocationPointsProps[] = useSelector((state:any) => state.points.locationspoints);
    const dispatch = useDispatch();
    const params:any = useLocation();
    const navigate = useNavigate();
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [title, setTitle] = useState('');
    const [_img, setImg] = useState<any>('');
    const [alt, setAlt] = useState<any>('');
    const [[imgWidth,imgHeight],setWH] = useState([0,0]);
    const [[x,y], setXY] = useState([0,0]);
    const [[magnifierWidth,magnifierHeight],setSizeMagnifier] = useState([150,150]);
    const [[questionImageWidth,questionImageHeight],setQuestionImageSize] = useState([0,0]);
    const [[questionPointX1,questionPointY1],setQuestionPointXY1] = useState([0,0]);
    const [[questionPointX2,questionPointY2],setQuestionPointXY2] = useState([0,0]);
    const [findElement,setFindElement] = useState(false);
    const [takedPhoto,setTakedPhoto] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [goodAnswerMessage, setGoodAnswerMessage] = useState('');
    const [badAnswerMessage, setBadAnswerMessage] = useState('');
    const [resultAnswer, setResultAnswer] = useState(false);
    const [animalID, setAnimalID] = useState<string | undefined>(undefined);
    const [showBackAlert, setShowBackAlert] = useState(false);
    const [showEndAlert, setShowEndAlert] = useState(false);
    const [stopMagnifier, setStopMagnifier] = useState(false);
    const [leavePage, setLeavePage] = useState(false);
    const [addPoints, setAddPoints] = useState(true);
    const [answersconfig, setAnswersconfig] = useState<AnswersConfigProps[] | undefined>([]);
    const [nextquestion, setNextQuestion] = useState<{idquestion:number | undefined, type:string | undefined} | undefined>();
    const totalPoints:number = useMemo(()=>(params.state.points!==undefined?params.state.points:0),[params.state.points]);
    const nextquestions:{idquestion:number,type:string}[] = useMemo(()=>(params.state.nextquestion),[params.state.nextquestion]);
    const idpark:number = useMemo(()=>(params.state.idpark),[params.state.idpark]);
    const idlocation:number = useMemo(()=>(params.state.idlocation),[params.state.idlocation]);
    const parkName:string = useMemo(()=>(params.state.parkName),[params.state.parkName]);
    const logo:any = useMemo<any>(()=>(params.state.parkLogo),[params.state.parkLogo]);
    /**
     * Funkcja ustawiąjąca parametry dla wybranego pytania
     */
     useEffect(()=>{
        const findquestion:FindQuestionProps | undefined = Questions.find(item=>item.idquestion===params.state.idquestion) as FindQuestionProps | undefined;
        if(findquestion!==undefined){
            setTitle(findquestion.title!==undefined?findquestion.title:'');
            setQuestionImageSize([findquestion.width,findquestion.height]);
            setQuestionPointXY1([findquestion.x1,findquestion.y1]);
            setQuestionPointXY2([findquestion.x2,findquestion.y2]);
            setImg(findquestion.src);
            setAlt(findquestion.alt);
            if(levelgame===0) setSizeMagnifier([150,150]);
            else if(levelgame===1) setSizeMagnifier([125,125]);
            else setSizeMagnifier([100,100]);
            setGoodAnswerMessage(findquestion.goodanswermessage);
            setBadAnswerMessage(findquestion.badanswermessage);
            setAnimalID(findquestion.animalID);
            setAnswersconfig(findquestion.answersconfig);
            setAddPoints(findquestion.addPoints===undefined?true:findquestion.addPoints);
        }
        
    },[params.state.idquestion,levelgame]);
    /**
     * Funkcja wywoływana po potwierdzeniu wyjścia z pytania, przekierowywuje do ekranu lokacji 
     */
    const back = () => {
        setLeavePage(true);
        setShowBackAlert(false);
        navigate(-1);
    }
    /**
     * Funkcja wywoływana po naciśnięciu guzika "Sprawdź/Dalej", sprawdza czy odpowiedź jest prawidłowa
     */
    const check = () => {
        const result:boolean = takedPhoto;
        const answerconfig:AnswersConfigProps | undefined = answersconfig?.find((item:AnswersConfigProps)=>item.correct===result);
        const _nextquestion = {idquestion:answerconfig?.idquestion,type:answerconfig?.type};
        setNextQuestion(_nextquestion);
        setResultAnswer(result);
        setMessageAlert(result?goodAnswerMessage:badAnswerMessage);
        if(sound!==undefined && sound==='on') SoundEffect(result?'good':'bad');
        setShowAlert(true);
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            check();
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu poprawnej lub błędnej odpowiedzi
     */
     const confirmResult = () =>{
        setShowAlert(false);
        if(resultAnswer){
             if(animalID!==undefined) dispatch(setAnimals({value:animalID}));
             nextStep();
        }
        else if(!resultAnswer && nextquestion?.idquestion!==undefined) nextStep(false);
    }
    /**
     * Funkcja ustawia kolejne pytania lub wywołuje funkcję zapisująca punkty i wychodzącą z wyzwania
     */
     const nextStep = (_goodanswer:boolean=true) => {
        const points = LevelPoints && LevelPoints.length>0 && levelgame!==undefined?addPoints?_goodanswer?LevelPoints[levelgame]+totalPoints:totalPoints:totalPoints:totalPoints;
        if(nextquestion!==undefined && nextquestion.idquestion!==undefined && nextquestion.type!==undefined) gotoNextQuestion(nextquestion.idquestion,nextquestion.type,nextquestions,points);
        else if(nextquestions!==undefined){
            let firstquestion:{idquestion:number,type:string} = nextquestions[0];
            let _nextquestions:{idquestion:number,type:string}[] = [];
            nextquestions.forEach((item:{idquestion:number,type:string},index:number)=>{
                if(index!==0) _nextquestions.push(item);
            });
            if(firstquestion!==undefined && firstquestion!==null) gotoNextQuestion(firstquestion.idquestion,firstquestion.type,_nextquestions,points);
            else savePoints(points);
        }
        else savePoints(points);
    }
    /**
     * Funkcja przekierowywuje do następnego pytania
     * @param {number} _idquestion ID pytania
     * @param {string} _type typ pytania
     * @param {{idquestion:number,type:string}[]} _nextquestions następne pytania
     * @param {number} _points zdobyte punkty
     */
     const gotoNextQuestion = (_idquestion:number,_type:string,_nextquestions:{idquestion:number,type:string}[],_points:number) => {
        if(sound!==undefined && sound==='on') SoundEffect('nextQuestion');
        const pathname = params.pathname.replace("find","") + _type;
        navigate(pathname,{
            replace:true,
            state:{
                idquestion:_idquestion,nextquestion:_nextquestions,idlocation:idlocation,points:_points,idpark:idpark,parkName:parkName,parkLogo:logo
            }
        });
    }
    /**
     * Funkcja aktualizuje zdobyte przez gracza punkty
     * @param {number} points punkty zdobyte dotej pory przez gracza 
     */
     const savePoints = (points:number) => {
        setShowEndAlert(true);
        if(points){
            const _locationpoints:LocationPointsProps[] = locationspoints!==undefined?[...locationspoints]:[];
            const index:number = _locationpoints.findIndex((item:LocationPointsProps) => item.idlocation === idlocation);
            if (index !== -1) _locationpoints[index] = { idlocation:idlocation, points:_locationpoints[index].points+points, parkID:idpark,gamemode:gamemode};
            else _locationpoints.push({idlocation,points,parkID:idpark,gamemode})
            dispatch(setPoints({locationspoints:_locationpoints}));
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu o ukończeniu wyzwania, przekierowywuje do ekranu parku
     */
    const goPark = () => {
        setLeavePage(true);
        setShowEndAlert(false);
        navigate(-2);
    }
    /**
     * Funkcja przechwytująca naciśnięcie przycisku wstecz w przeglądarce
     */
    window.onpopstate = () => {
        if(!leavePage && params.pathname==='/park/location/find'){
             navigate(1);
             setShowBackAlert(true);
        }
    };

    /**
     * Funkcja umożliwiająca przemieszczanie obiektywu po zdjęciu
     * @param {MouseEvent} event 
     */
     const handleMouseMove = (event:MouseEvent<HTMLImageElement>) => {
        const element = event.currentTarget;
        const { top, left } = element.getBoundingClientRect();
        const x = event.pageX - left - window.pageXOffset;
        const y = event.pageY - top - window.pageYOffset;
        setXY([x, y]);

        const x1 = (imgWidth*questionPointX1)/questionImageWidth;
        const y1 = (imgHeight*questionPointY1)/questionImageHeight;
        const x2 = (imgWidth*questionPointX2)/questionImageWidth;
        const y2 = (imgHeight*questionPointY2)/questionImageHeight;

        if(x+(magnifierWidth/8)>=x1&&x-(magnifierWidth/8)<=x2&&y+(magnifierHeight/8)>=y1&&y-(magnifierHeight/8)<=y2) setFindElement(true);
        else setFindElement(false);
    }
    /**
     * Funkcja umożliwiająca przemieszczanie obiektywu po zdjęciu
     * @param {TouchEvent} event 
     */
     const handleTouchMove = (event:TouchEvent<HTMLImageElement>) => {
        const element = event.currentTarget;
        const { top, left } = element.getBoundingClientRect();
        const x = event.targetTouches[0].pageX - left - window.pageXOffset;
        const y = event.targetTouches[0].pageY - top - window.pageYOffset;
        setXY([x, y]);

        const x1 = (imgWidth*questionPointX1)/questionImageWidth;
        const y1 = (imgHeight*questionPointY1)/questionImageHeight;
        const x2 = (imgWidth*questionPointX2)/questionImageWidth;
        const y2 = (imgHeight*questionPointY2)/questionImageHeight;

        if(x+(magnifierWidth/8)>=x1&&x-(magnifierWidth/8)<=x2&&y+(magnifierHeight/8)>=y1&&y-(magnifierHeight/8)<=y2) setFindElement(true);
        else setFindElement(false);
    }
    /**
     * Funkcja zmieniająca paramerty zdjęcia
     * @param {MouseEvent} event 
     */
     const handleMouseEnter = (event:MouseEvent<HTMLImageElement>) => {
        const element = event.currentTarget;
        const { width, height } = element.getBoundingClientRect();
        setWH([width, height]);
    }
    /**
     * Funkcja zmieniająca paramerty zdjęcia
     * @param {TouchEvent} event 
     */
     const handleTouchStart = (event:TouchEvent<HTMLImageElement>) => {
        document.getElementById('backgroundContainer')!.style.overflow = "hidden"; 
        const element = event.currentTarget;
        const { width, height } = element.getBoundingClientRect();
        setWH([width, height]);
    }
    /**
     * Funkcja wywoływana po zakończeniu przeciągania obiektywu
     */
    const handleTouchEnd = (event:TouchEvent<HTMLImageElement>) => {
        document.getElementById('backgroundContainer')!.style.overflow = "visible"; 
    }
    /**
     * Funkcja wywoływana po naciśnięciu przycisku "ZRÓB ZDJĘCIE"
     */
    const takePhoto = () => {
        if(sound!==undefined && sound==='on') SoundEffect('takePhoto');
        setTakedPhoto(findElement);
    }
    const onKeyDownPhoto = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            takePhoto();
        }
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            {showBackAlert && <AlertNoTitle show={showBackAlert} cancel={()=>{setShowBackAlert(false)}} confirm={back} 
             message='Chcesz opuścić wyzwanie?' buttonCancel='Anuluj' buttonConfirm='Opuść'/>}
            {showAlert && <AlertNoTitle show={showAlert} cancel={()=>{confirmResult()}} message={messageAlert} buttonCancel='OK'/>}
            {showEndAlert && <AlertNoTitle show={showEndAlert} confirm={goPark} message='Gratulacje! Wyzwanie zakończone!' buttonConfirm='Wróć na szlak'/>}
            <LeftTabBar idpark={idpark} logo={logo} visibilityButtons='hidden'/>
            <div className={styles.questionContainer}>  
                <Header name="ODNAJDŹ ZWIERZĘ" menuVisibility='hidden'/>
                <div className={styles.questionContent}>
                    <div className={styles.imgContainer}>
                        <img src={_img} alt={alt} className={styles.img} 
                        onDoubleClick={()=>setStopMagnifier(!stopMagnifier)} 
                        onMouseEnter={!stopMagnifier?handleMouseEnter:undefined} 
                        onMouseMove={!stopMagnifier?handleMouseMove:undefined}
                        onTouchStart={!stopMagnifier?handleTouchStart:undefined}
                        onTouchEnd={handleTouchEnd}
                        onTouchMove={!stopMagnifier?handleTouchMove:undefined}/>
                        <div className={styles.magnifier}
                        style={{
                            pointerEvents:"none",
                            height: `${magnifierHeight}px`,
                            width: `${magnifierWidth}px`,
                            top: `${y - magnifierHeight / 2}px`,
                            left: `${x - magnifierWidth / 2}px`,
                            backgroundImage: `url('${_img}')`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: `${imgWidth}px ${imgHeight}px`,
                            backgroundPositionX: `${-x + magnifierWidth / 2}px`,
                            backgroundPositionY: `${-y + magnifierHeight / 2}px`
                        }}
                        />
                    </div>
                    <div className={styles.findQuestionContainer}>
                         {title && title!==''?<div className={styles.title} style={{fontSize:getFontSize()+10,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : parser(title) }}/>:null}
                         <div className={styles.photoContainer}>
                            <button className={styles.button} style={{outlineColor:contrast==='on'?'#ffff00':'#f08733'}} onClick={takePhoto} onKeyDown={onKeyDownPhoto}>
                                <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>ZRÓB ZDJĘCIE</p>
                                <img className={styles.photo} src={contrast==='on'?camera_img_contrast:camera_img} alt='Przycisk umożliwiający zrobienie zdjęcia' />
                            </button>
                        </div>
                        <button className={contrast==='on'?styles.checkContrast:styles.check} onClick={check} onKeyDown={onKeyDown}>
                            <p className={styles.checkText} style={{fontSize:getFontSize()+10}}>SPRAWDŹ / DALEJ</p>
                            <img className={styles.next} src={next} alt=""/>
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Find;
