/**
 * Ekran wyświetlający pytanie typu puzzle
 */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '../../components/Container';
import Header from '../../components/Header';
import LeftTabBar from '../../components/LeftTabBar';
import SoundEffect, { pause } from '../../components/SoundEffect';
import Timer, { clearTimer } from '../../components/Timer';
import LevelPoints from '../../constans/levelpoints';
import Questions from '../../data/questions';
import { setPoints } from '../../store/redux/points';
import styles from '../../styles/screens/questions/Puzzle.module.css';
import { AnswersConfigProps, LocationPointsProps, PuzzleQuestionProps } from '../../types';
import Sizes from '../../constans/sizes';
import AlertNoTitle from '../../components/AlertNoTitle';

function shuffle(array:any) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

var imagePieces:any[];
var puzzleWidth:number = 0;
var puzzleHeight:number = 0;
var dragElement:HTMLImageElement;
var clone:HTMLImageElement;

const Puzzle = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie poziomu trudności
     */
    const levelgame = useSelector((state:any) => state.levelgame.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie trybu gry
     */
    const gamemode = useSelector((state:any) => state.gamemode.value);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
    const locationspoints:LocationPointsProps[] = useSelector((state:any) => state.points.locationspoints);
    const dispatch = useDispatch();
    const params:any = useLocation();
    const navigate = useNavigate();
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [title, setTitle] = useState('');
    const [image,setImage] = useState<any>('');
    const [alt,setAlt] = useState<any>('');
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState<any>('');
    const [resultAnswer, setResultAnswer] = useState(false);
    const numTiles = useMemo(()=>{
        if(levelgame===0) return 2;
        else if(levelgame===1) return 3;
        else return 4;
    },[levelgame]);
    const puzzlequestion:PuzzleQuestionProps | undefined = useMemo(()=>Questions.find(item=>item.idquestion===params.state.idquestion),[params.state.idquestion]) as PuzzleQuestionProps | undefined;
    const _time = useMemo(()=>puzzlequestion?.time[levelgame],[puzzlequestion,levelgame]);
    const badAnswerMessage = useMemo(()=>puzzlequestion?.badanswermessage,[puzzlequestion]);
    const goodAnswerMessage = useMemo(()=>puzzlequestion?.goodanswermessage,[puzzlequestion]);
    const [startTimer, setStartTimer] = useState(false);
    const [showBackAlert, setShowBackAlert] = useState(false);
    const [showEndAlert, setShowEndAlert] = useState(false);
    const [leavePage, setLeavePage] = useState(false);
    const [addPoints, setAddPoints] = useState(true);
    const [answersconfig, setAnswersconfig] = useState<AnswersConfigProps[] | undefined>([]);
    const [nextquestion, setNextQuestion] = useState<{idquestion:number | undefined, type:string | undefined} | undefined>();
    const totalPoints:number = useMemo(()=>(params.state.points!==undefined?params.state.points:0),[params.state.points]);
    const nextquestions:{idquestion:number,type:string}[] = useMemo(()=>(params.state.nextquestion),[params.state.nextquestion]);
    const idpark:number = useMemo(()=>(params.state.idpark),[params.state.idpark]);
    const idlocation:number = useMemo(()=>(params.state.idlocation),[params.state.idlocation]);
    const parkName:string = useMemo(()=>(params.state.parkName),[params.state.parkName]);
    const logo:any = useMemo<any>(()=>(params.state.parkLogo),[params.state.parkLogo]);
    /**
     * Funkcja ustawiąjąca parametry dla wybranego pytania
     */
     useEffect(()=>{
        setTitle(puzzlequestion?.title!==undefined?puzzlequestion.title:'');
        setImage(puzzlequestion?.src);
        setAlt(puzzlequestion?.alt);
        setAnswersconfig(puzzlequestion?.answersconfig);
        setAddPoints(puzzlequestion?.addPoints===undefined?true:puzzlequestion?.addPoints);
    },[puzzlequestion]);
    /**
     * Funkcja tworząca puzzle
     */
    const createPuzzle = () => {
        pause();
        setStartTimer(true);
        const puzzleContainer = document.getElementById('puzzleContainer') as HTMLDivElement;
        puzzleContainer.style.width = puzzleWidth + "px";
        puzzleContainer.style.height = puzzleHeight + "px";
        while (puzzleContainer.firstChild) {
            puzzleContainer.removeChild(puzzleContainer.firstChild);
        }
        var _image;
        shuffle(imagePieces);
        var _count:number = 0;
        imagePieces.forEach((item,index)=>{
            if(item.idx===index) _count++;
        });
        if(_count===imagePieces.length) shuffle(imagePieces); 
        for(var i = 0; i < imagePieces.length; i++){
            _image = new Image();
            _image.src = imagePieces[i].src;
            _image.setAttribute('data-idx',imagePieces[i].idx); 
            _image.addEventListener('dragstart', handleDragStart);
            _image.addEventListener('dragover', handleDragOver);
            _image.addEventListener('dragend', handleDragEnd);
            _image.addEventListener('drop', handleDrop);
            _image.addEventListener('touchstart', handleTouchStart);
            _image.addEventListener('touchmove', handleTouchMove);
            _image.addEventListener('touchend', handleTouchEnd);
            puzzleContainer.appendChild(_image);
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu wyjścia z pytania, przekierowywuje do ekranu lokacji 
     */
    const back = () => {
        setLeavePage(true);
        setShowBackAlert(false);
        navigate(-1);
    }
    /**
     * Funkcja wywoływana po naciśnięciu guzika "Sprawdź/Dalej", sprawdza czy odpowiedź jest prawidłowa
     */
     const check = (complete:boolean) => {
        setStartTimer(false);
        clearTimer();
        const result:boolean = complete;
        const answerconfig:AnswersConfigProps | undefined = answersconfig?.find((item:AnswersConfigProps)=>item.correct===result);
        const _nextquestion = {idquestion:answerconfig?.idquestion,type:answerconfig?.type};
        setNextQuestion(_nextquestion);
        setResultAnswer(result);
        if(result!==undefined) setMessageAlert(result?goodAnswerMessage:badAnswerMessage);
        if(sound!==undefined && sound==='on') SoundEffect(result?'good':'bad');
        setShowAlert(true);
    };
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu poprawnej lub błędnej odpowiedzi
     */
    const confirmResult = () => {
        setShowAlert(false);
        if(resultAnswer) nextStep();
        else if(!resultAnswer && nextquestion?.idquestion!==undefined) nextStep(false);
        else createPuzzle();
    }
    /**
     * Funkcja ustawia kolejne pytania lub wywołuje funkcję zapisująca punkty i wychodzącą z wyzwania
     */
     const nextStep = (_goodanswer:boolean=true) => {
        const points = LevelPoints && LevelPoints.length>0 && levelgame!==undefined?addPoints?_goodanswer?LevelPoints[levelgame]+totalPoints:totalPoints:totalPoints:totalPoints;
        if(nextquestion!==undefined && nextquestion.idquestion!==undefined && nextquestion.type!==undefined) gotoNextQuestion(nextquestion.idquestion,nextquestion.type,nextquestions,points);
        else if(nextquestions!==undefined){
            let firstquestion:{idquestion:number,type:string} = nextquestions[0];
            let _nextquestions:{idquestion:number,type:string}[] = [];
            nextquestions.forEach((item:{idquestion:number,type:string},index:number)=>{
                if(index!==0) _nextquestions.push(item);
            });
            if(firstquestion!==undefined && firstquestion!==null) gotoNextQuestion(firstquestion.idquestion,firstquestion.type,_nextquestions,points);
            else savePoints(points);
        }
        else savePoints(points);
    }
    /**
     * Funkcja przekierowywuje do następnego pytania
     * @param {number} _idquestion ID pytania
     * @param {string} _type typ pytania
     * @param {{idquestion:number,type:string}[]} _nextquestions następne pytania
     * @param {number} _points zdobyte punkty
     */
     const gotoNextQuestion = (_idquestion:number,_type:string,_nextquestions:{idquestion:number,type:string}[],_points:number) => {
        if(sound!==undefined && sound==='on') SoundEffect('nextQuestion');
        const pathname = params.pathname.replace("puzzle","") + _type;
        navigate(pathname,{
            replace:true,
            state:{
                idquestion:_idquestion,nextquestion:_nextquestions,idlocation:idlocation,points:_points,idpark:idpark,parkName:parkName,parkLogo:logo
            }
        });
    }
    /**
     * Funkcja aktualizuje zdobyte przez gracza punkty
     * @param {number} points punkty zdobyte dotej pory przez gracza 
     */
     const savePoints = (points:number) => {
        setShowEndAlert(true);
        if(points){
            const _locationpoints:LocationPointsProps[] = locationspoints!==undefined?[...locationspoints]:[];
            const index:number = _locationpoints.findIndex((item:LocationPointsProps) => item.idlocation === idlocation);
            if (index !== -1) _locationpoints[index] = { idlocation:idlocation, points:_locationpoints[index].points+points, parkID:idpark,gamemode:gamemode};
            else _locationpoints.push({idlocation,points,parkID:idpark,gamemode})
            dispatch(setPoints({locationspoints:_locationpoints}));
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu o ukończeniu wyzwania, przekierowywuje do ekranu parku
     */
    const goPark = () => {
        setLeavePage(true);
        setShowEndAlert(false);
        navigate(-2);
    }
    /**
     * Funkcja przechwytująca naciśnięcie przycisku wstecz w przeglądarce
     */
    window.onpopstate = () => {
        if(!leavePage && params.pathname==='/park/location/puzzle'){
             navigate(1);
             setShowBackAlert(true);
        }
    };
    /**
     * Funkcja wywołana po anulowaniu opuszczenia strony
     */
    const cancelBack = () => {
        setShowBackAlert(false);
        createPuzzle();
    }
    /**
     * Funkcja wyznaczająca szerokość i wysokość obrazka puzzli
     */
    const onLoadPuzzleImage = () => {
        const _pimage:HTMLImageElement = document.getElementById('puzzleImage') as HTMLImageElement;
        _pimage.style.visibility = 'visible';
        const tmpImageWidth = Math.floor(_pimage.offsetWidth / numTiles);
        const tmpImageHeight = Math.floor(_pimage.offsetHeight / numTiles);
        const canvasWidth = tmpImageWidth * numTiles;
        const canvasHeight = tmpImageHeight * numTiles;

        const tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = canvasWidth;
        tmpCanvas.height = canvasHeight;
        const tmpContext = tmpCanvas.getContext('2d');
        if(tmpContext !== null) tmpContext.drawImage(_pimage, 0, 0, canvasWidth, canvasHeight);
        const tmpimage = new Image();
        tmpimage.id = 'tmpimage';
        tmpimage.src = tmpCanvas.toDataURL();
        tmpimage.onload = (event:any) => {
            if(event.target !== null){
                const pieceWidth = Math.floor(event.target.width / numTiles);
                const pieceHeight = Math.floor(event.target.height / numTiles);
                const _puzzleWidth = pieceWidth * numTiles;
                const _puzzleHeight = pieceHeight * numTiles;
                imagePieces = [];
                var idx = 0;
                for(var x = 0; x < numTiles; x++) {
                    for(var y = 0; y < numTiles; y++) {
                        idx = y * numTiles + x;
                        var canvas = document.createElement('canvas');
                        canvas.width = pieceWidth;
                        canvas.height = pieceHeight;
                        var context = canvas.getContext('2d');
                        if(context!==null) context.drawImage(event.target, x * pieceWidth, y * pieceHeight, pieceWidth, pieceHeight, 0, 0, pieceWidth, pieceHeight);
                        imagePieces.push({src:canvas.toDataURL(),idx:idx});
                    }
                }

                puzzleWidth = _puzzleWidth;
                puzzleHeight = _puzzleHeight;

                createPuzzle();
            }
        }
    }
    /**
     * Funkcja rozpoczynająca przesuwanie elementu
     * @param {any} event 
     */
    const handleDragStart = (event:any) => {
        if(event.target !== null){
            event.target.style.opacity = '.4';
            dragElement = event.target;
            dragElement.getAttribute('data-idx');
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('text/plain', event.target.src);
        }
    }
    /**
     * Funkcja wywoływana po zakończeniu przsuwania elementu
     * @param {any} event 
     */
    const handleDragEnd = (event:any) => {
        if(event.target !== null) event.target.style.opacity = '1';
    }
    /**
     * Funkcja wywoływana podczas przeciągania elementu
     * @param {any} event 
     */
     const handleDragOver = (event:any) => {
        event.preventDefault();
        return false;
    }
    /**
     * Funkcja wywoływana po opuszczeniu elementu
     * @param {any} event 
     */
    const handleDrop = (event:any) => {
        event.stopPropagation();
        const element:HTMLImageElement = event?.target as HTMLImageElement;
        if (element !==null && dragElement !== undefined && dragElement !== element) {
            var drop_idx = element.getAttribute('data-idx');
            var drag_idx = dragElement.getAttribute('data-idx');
            dragElement.src = element.src;
            dragElement.setAttribute('data-idx',drop_idx!==null?drop_idx:'-1');
            element.src = event.dataTransfer.getData('text');
            element.setAttribute('data-idx',drag_idx!==null?drag_idx:'-1');
            checkArrangement();

        }
        return false;
    }
    /**
     * Funkcja sprawdza ułożenie puzzli 
     */
    const checkArrangement = () => {
        const box:HTMLDivElement = document.getElementById('puzzleContainer') as HTMLDivElement;
        var complete:boolean = true;
        for(var i = 0; i < box.children.length; i++){
            const idx:string = box.children[i].getAttribute('data-idx') ?? '-1';
            if(parseInt(idx) !== i) complete = false;
        }
        if(complete) check(true);
    }
    /**
     * Funkcja rozpoczynająca przesuwanie elementu
     * @param {any} event 
     */
    const handleTouchStart = (event:any) => {
        const element:HTMLImageElement = event?.target as HTMLImageElement;
        if(element !== null){
            clone = element.cloneNode(true) as HTMLImageElement;
            element.style.opacity = '.4';
            clone.style.position = 'absolute';
            document.getElementById('backgroundContainer')!.appendChild(clone); 
            var touchLocation = event.targetTouches[0];
            clone.style.left = touchLocation.pageX - element.offsetWidth/2 + 'px';
            clone.style.top = touchLocation.pageY - element.offsetHeight/2 + 'px';
            clone.style.width = element.offsetWidth + "px";
            if(props.getDraggabledElement !== undefined) props.getDraggabledElement(element);
        }
        document.getElementById('backgroundContainer')!.style.overflow = "hidden";   
    }
    /**
     * Funkcja przesuwająca element
     * @param {any} event 
     */
     const handleTouchMove = (event:any) => {
        var touchLocation = event.targetTouches[0];
        clone.style.left = touchLocation.pageX - clone.offsetWidth/2  + 'px';
        clone.style.top = touchLocation.pageY - clone.offsetHeight/2 + 'px';
    }
    /**
     * Funkcja wywoływana po zakończeniu przsuwania elementu
     * @param {any} event 
     */
     const handleTouchEnd = (event:any) => {
        const element:HTMLImageElement = event?.target as HTMLImageElement;
        const clonX = clone.offsetLeft + (clone.offsetWidth/2);
        const clonY = clone.offsetTop + (clone.offsetHeight/2);

        const box:HTMLDivElement = document.getElementById('puzzleContainer') as HTMLDivElement;
        for(var i = 0; i < box.children.length; i++){
            const _img = box.children[i] as HTMLImageElement;
            if(clonX!==undefined && clonY!==undefined && clonX>=_img.offsetLeft && clonX<=_img.offsetWidth+_img.offsetLeft
                && clonY>_img.offsetTop && clonY<=_img.offsetTop+_img.offsetHeight){
                var drop_idx = _img.getAttribute('data-idx');
                var drag_idx = clone.getAttribute('data-idx');
                element.src = _img.src;
                _img.src = clone.src;
                element.setAttribute('data-idx',drop_idx!==null?drop_idx:'-1');
                _img.setAttribute('data-idx',drag_idx!==null?drag_idx:'-1');
                checkArrangement();
            }
        }
        element.style.opacity = '1';
        document.getElementById('backgroundContainer')!.removeChild(clone); 
        document.getElementById('backgroundContainer')!.style.overflow = "visible"; 
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            {showBackAlert && <AlertNoTitle show={showBackAlert} cancel={cancelBack} confirm={back} 
             message='Chcesz opuścić wyzwanie?' buttonCancel='Anuluj' buttonConfirm='Opuść'/>}
            {showAlert && <AlertNoTitle show={showAlert} cancel={()=>{confirmResult()}} message={messageAlert} buttonCancel='OK'/>}
            {showEndAlert && <AlertNoTitle show={showEndAlert} confirm={goPark} message='Gratulacje! Wyzwanie zakończone!' buttonConfirm='Wróć na szlak'/>}
            <LeftTabBar idpark={idpark} logo={logo} visibilityButtons='hidden'/>
            <div className={styles.questionContainer}>  
                <Header name="PUZZLE" menuVisibility='hidden'/>
                <div className={styles.questionContent}>
                    {_time!==undefined?<Timer namePage='puzzle' start={startTimer} time={_time} result={()=>check(false)}></Timer>:null}
                    {title && title!==''?<div className={styles.title} style={{fontSize:getFontSize()+10,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : parser(title) }}/>:null}
                    <div id='puzzleContainer' className={styles.puzzleContainer}>
                        <img id="puzzleImage" className={styles.img} src={image} alt={alt} onLoad={onLoadPuzzleImage}/>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Puzzle;
