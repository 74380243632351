/**
 * Ekran Menu
 */
import { useCallback, useEffect, useState } from "react";
import Container from "../../components/Container";
import styles from '../../styles/screens/menu/FAQ.module.css';
import FAQItem from "../../components/FAQItem";
import MenuHeader from "../../components/MenuHeader";
import { useSelector } from "react-redux";
import Sizes from '../../constans/sizes';
import { useNavigate } from "react-router-dom";

const dataList:{id:number,name:string,description:string}[] = [
    {
        id:1,
        name:'Czy usunięcie aplikacji spowoduje utratę osiągnięć?',
        description:'Tak, wszystkie osiągnięcia zapisywane są na urządzeniu i tylko na nim. Usunięcie aplikacji bądź wyczyszczenie pamięci cache aplikacji/przeglądarki spowoduje utratę wszystkich postepów. '
    },
    {
        id:2,
        name:'Czy aplikacja przechowuje i/lub przetwarza  dane osobowe?',
        description:'Aplikacja nie przechowuje i nie przetwarza danych osobowych.'
    },
    {
        id:4,
        name:'Jak kupić e-pocztówkę w sklepie?',
        description:'W grze, w zakładce sklep dostępne są różnorodne pocztówki. Jeżeli Gracz posiada odpowiednią liczbę punktów, wówczas może kupić pocztówkę. Liczba punktów niezbędna do zakupu konkretnej e-pocztówki znajduje się bezpośrednio pod pocztówką.'
    },
    {
        id:5,
        name:'Jak udostępnić e-pocztówkę?',
        description:'Uwaga! Udostępnić można tylko wcześniej zakupione pocztówki. Aby udostępnić e-pocztówkę należy wybrać z menu Atlas, następnie przejść do zakładki Pocztówki, kliknąć w wybraną pocztówkę i zapisać ją na urządzeniu. Tak zapisaną pocztówkę można udostępnić za pośrednictwem dowolnie wybranych mediów społecznościowych lub przekazać jako załącznik wiadomości e-mail.'
    },
];

const FAQ = () => {
    const navigate = useNavigate(); 
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
     const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Ustawienie stanów początkowych, zmienych wykorzystywanych w ekranie Parków
     */
    const [menuList, setMenuList] = useState<any>([]);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = useCallback(() => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    },[_fontsize]);
    /**
     * Funkcja tworzy listę FAQ
     */
    useEffect(()=>{
        const _m:any[] = dataList.map(item=>
            <FAQItem key={item.id} id={item.id} name={item.name} description={item.description} fontSize={getFontSize()+4}/>
        )
        setMenuList(_m);
    },[getFontSize]);
    /**
     * Funkcja wychodzi z ekranu
     */
    const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}>
            <div className={styles.menuContainer}>
                <MenuHeader/>
                <div className={styles.menuContent}>
                    <h2 style={{marginBottom:30,color:contrast==='on'?'#fff':'#000'}}>FAQ</h2>
                    <ul className={styles.list}>
                        {
                            menuList
                        }
                    </ul>
                </div>
            </div>
        </Container>
    )
}

export default FAQ;