import { useEffect } from "react";
import { CustomAlertProps } from "../types";
import styles from '../styles/components/CustomAlert.module.css';
import { useSelector } from "react-redux";
import Sizes from '../constans/sizes';
/**
 * Komponent okna Alertu
 * @param {CustomAlertProps} props
 * @returns zwraca okno Alertu
 */
const CustomAlert = (props:CustomAlertProps) => {
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja ustawia focus na guziku zatwierdzającym lub anulujący
     */
    useEffect(()=>{
        const bt_confirm:HTMLButtonElement = document.getElementById('confirm') as HTMLButtonElement;
        if(bt_confirm!==undefined && bt_confirm!==null) bt_confirm.focus();
        else{
            const bt_cancel:HTMLButtonElement = document.getElementById('cancel') as HTMLButtonElement;
            if(bt_cancel!==undefined && bt_cancel!==null) bt_cancel.focus();
        }
    },[]);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <div>
            <div className={styles.containerModal}>
                <div className={styles.modal} style={{outlineColor:contrast==='on'?'#ffff00':'#f08733',background:contrast==='on'?'#000':'#fff',borderWidth:contrast==='on'?2:0,borderStyle:'solid',borderColor:contrast==='on'?'#fff':'#000'}}
                    role='dialog' aria-labelledby="dialog_label" aria-modal="true" aria-describedby="dialog_description" tabIndex={0}>
                    <div id="dialog_description" className="sronly">
                        Okno wyświetla aktualne informacje przebiegu gry.
                    </div>
                    <h2 className={styles.containerTitle} style={{background:contrast==='on'?'#000':'#fff'}}>
                        <p id="dialog_label" className={styles.textTitle} style={{fontSize:getFontSize()+6,color:contrast==='on'?'#FFF':'#000'}}>{props.title}</p>
                    </h2>
                    <div className={styles.containerMessage} style={{fontSize:getFontSize()+2,color:contrast==='on'?'#FFF':'#000'}}>
                        {props.message}
                    </div>
                    <div className={styles.containerActions}>
                        <div className={styles.containerButtons}>
                            {props.buttonConfirm!==undefined && <button id="confirm" className={contrast==='on'?styles.confirmButtonContrast:styles.confirmButton} style={{fontSize:getFontSize()}} onClick={()=>props.confirm?.()}>
                                {props.buttonConfirm}
                            </button>}
                            {props.buttonCancel!==undefined && <button id="cancel" className={contrast==='on'?styles.cancelButtonContrast:styles.cancelButton} style={{fontSize:getFontSize()}} onClick={()=>props.cancel?.()}>
                                {props.buttonCancel}
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.undermodal} onClick={()=>props.cancel?.()} />
        </div>
    )
}

export default CustomAlert;