/**
 * Dostępne zwierzęta w aplikacji
 */
const animals = [
    {
        id:'a1',
        name:'błotniak stawowy',
        description:'<b>Błotniak stawowy</b><br/><br/>Drapieżny ptak o długich skrzydłach (rozpiętość ok. 1,2 m) i okazałym ogonie jest smuklejszy od myszołowa. Nietrudno rozróżnić płeć przedstawicieli tego gatunku ze względu na silny dymorfizm. Samica jest nieco większa, ma brunatne upierzenie z jasną głową. Samiec jest natomiast brązowy z szarym ogonem i szaro-czarnymi skrzydłami. Błotniaki jako jedyne opierzone drapieżniki zakładają gniazda na ziemi, w gęstych trzcinach. Z tego względu narwiańskie tereny stanowią dla nich idealne miejsce bytowania. Para dorosłych ptaków oblatuje na zmianę swoje terytorium z charakterystycznym uniesieniem skrzydeł w kształcie litery V, co można zauważyć obserwując na logo Narwiańskiego Parku Narodowego. Błotniak odżywia się drobnymi ssakami, jajami i pisklętami innych ptaków.',
        src: require('../assets/images/animals/blotniak_stawowy.png'),
        alt:'Jasnobrązowo ubarwiony błotniak stawowy z łbem skierowanym w prawo.',
        available:false
    },
    {
        id:'a2',
        name:'bóbr',
        description:'<b>Bóbr</b><br/><br/>Bóbr to największy gryzoń, żyjący na pograniczu dwóch środowisk wodnego i lądowego. Jego ciało pokrywa gęste, ciepło futro, a ogon łuska, taka jak u ryb. Bobry żywią się wyłącznie roślinami. Ścinają drzewa, żeby zbudować tamy do piętrzenia wody i żeremia, w których mieszkają. Jesienią świeżo ścięte gałązki drzew wciągają pod wodę, żeby zrobić zapasy na zimę.<br/><br/>W połowie XX wieku był w Polsce niemal doszczętnie wytępiony. Jednym z niewielu miejsc, gdzie gatunek przetrwał, były okolice jeziora Wigry i rzeki Czarnej Hańczy. Dziś bobry żyją niemal w całej Polsce, ale Wigierski Park Narodowy zasługuje na miano ich matecznika. Obecnie w Parku mieszka 40 rodzin bobrowych.',
        src: require('../assets/images/animals/bobr.png'),
        alt:'Bóbr w siedzącej pozie, patrzący na wprost.',
        available:false
    },
    {
        id:'a3',
        name:'bocian czarny',
        description:'<b>Bocian czarny</b><br/><br/>Bocian czarny jest kuzynem bociana białego. W jego upierzeniu dominuje  kolor czarny, o metalicznym, zielononiebieskim i fioletowym połysku, a biały jest jedynie brzuch i pierś. W przeciwieństwie do białego krewniaka, bocian czarny stroni od ludzi. Swoje gniazdo zakłada w rozłożystych konarach wysokich drzew, w gęstym lesie sąsiadującym często z bagnami, wilgotnym łąkami i rzekami. Tam właśnie, szuka dla siebie pokarmu. W wodzie są to ryby, a na łąkach dżdżownicami, owadami, jaszczurkami, zaskrońcami, małymi gryzoniami, a także płazami. Bociany wracają do nas z Afryki wiosną i budują nowe lub porządkują stare gniazdo. Rodzice na zmianę wysiadują jaja i wspólnie dokarmiają młode.',
        src: require('../assets/images/animals/bocian_czarny.png'),
        alt:'Lecący bocian czarny z białym brzuchem i czarnymi skrzydłami.',
        available:false
    },
    {
        id:'a4',
        name:'borsuk',
        description:'<strong>Borsuk</strong><br/><br/>Zamieszkuje lasy liściaste i mieszane. Aktywny nocą i o zmierzchu. W ziemie borsuk przechodzi w spoczynek zimowy, podczas którego znacznie traci na wadze. Nie zapada w sen zimowy. Nory borsucze, szczególnie głębokie i rozległe, zamieszkują kolejne pokolenia borsuków, nierzadko wraz z sublokatorami – lisami, norkami amerykańskimi, rzadziej kunami, a wyjątkowo nawet wydrami. Zaniepokojony ostrzegawczo „kwiczy”. Żywi się dżdżownicami, ślimakami, małymi ssakami, jajami ptasimi, pisklętami, padliną, owocami, grzybami, a także korzeniami. Borsuk jak mało które zwierzę bardzo dobrze chodzi tyłem. Podczas znoszenia ściółki do nory, przygarnia on znaleziska przednimi łapami i tyłem przesuwa się w kierunku swojego domu –taki styl przemieszczania się nazywany jest włóczeniem.',
        src: require('../assets/images/animals/borsuk.png'),
        alt:'Maszerujący w prawo borsuk z głową skierowaną ku ziemi.',
        available:false
    },
    {
        id:'a5',
        name:'derkacz',
        description:'<b>Derkacz</b><br/><br/>Derkacz zwyczajny (Crex crex) – gatunek średniej wielkości ptaka z rodziny chruścieli (Rallidae). Większość obserwacji dotyczy jedynie jego charakterystycznego głosu, ponieważ jest to ptak bardzo płochliwy, o skrytym trybie życia.<br/>Wilgotne łąki z wysoką roślinnością zielną i kępami krzewów, pola uprawne oraz suchsze miejsca na bagnach. Gniazda buduje na ziemi, pod osłoną roślin zielnych lub wewnątrz krzewu.<br/>W ciągu roku wyprowadza jeden lęg, składając w kwietniu–sierpniu 8 do 14 jaj. Jego pożywienie stanowią owady i inne małe zwierzęta oraz nasiona i części zielone roślin.',
        src: require('../assets/images/animals/derkacz.png'),
        alt:'Jasnobrązowy derkacz o smukłej posturze i krótkim kremowym dziobie.',
        available:false
    },
    {
        id:'a6',
        name:'drozd śpiewak',
        description:'<b>Drozd śpiewak</b><br/><br/>Śpiewakiem został nazwany nie bez powodu. Drozd śpiewak to po łacinie Turdus philomelos, a ten drugi człon w nazwie oznacza tyle, co lubiący muzykę, śpiew i pochodzi z mitu o zamienionej w ptaka dziewczynie o imieniu Philomela. Drozd śpiewak jest jednym z kilku gatunków drozdów spotykanych w naszym kraju. Jego kuzynem jest dobrze wszystkim znany czarny kos. Śpiewak jednak w przeciwieństwie do niego jest bardziej skryty. Ale wraz z kosem należy do pierwszych wiosennych wirtuozów. Po powrocie z zimowisk w okolicach Morza Śródziemnego już w początkach marca samce o poranku i zmierzchu wykonują swoje koncerty. Ich śpiew bardzo się wyróżnia z tła niewielu, jeszcze wtedy ptasich głosów.<br/>Śpiewak, to ptak związany z zadrzewieniami, lasami i parkami. Samiec i samica są ubarwione podobnie. Ich grzbiet jest brązowy, boki ciała ochrowe, brzuch kremowo-żółty z brązowymi cętkami. Gniazdo ukryte wśród gęstych gałęzi śpiewak wylepia podobną do tektury masą, mieszanką śliny, błota i próchna. Śpiewak chętnie zjada ślimaki muszlowe. Jednak najpierw musi rozbić twardą skorupkę mięczaka. Uderza ślimakiem o coś twardego, kamień, by po takiej obróbce zjeść smakowitego mięczaka z resztkami muszli. A muszla, to źródło wapnia, szczególnie cenne dla samicy śpiewaka do budowy skorupki jej własnych błękitnych jaj. Chętnie zjadają miękkie owoce, np. jemioły przyczyniając się tym samym, do ich rozsiewania.',
        src: require('../assets/images/animals/drozd.png'),
        alt:'Siedzący na gałęzi drozd, z białym brzuszkiem w brązowe kropki.',
        available:true
    },
    {
        id:'a7',
        name:'dzięcioł czarny',
        description:'<b>Dzięcioł czarny</b><br/><br/>Choć najskromniej ubarwiony, to jest największym spośród polskich dzięciołów. Dorosłe ptaki mają czarne, połyskujące pióra. Samce posiadają dużą czerwoną czapeczkę, u samic natomiast na głowie jest tylko wąska czerwona przepaska. Przysmakiem dzięcioła są larwy, poczwarki oraz dorosłe owady żyjące w drewnie. W ich poszukiwaniu ptak rozkuwa dziobem obumarłe pnie, a następnie wyciąga je ze szczelin językiem - długim, lepkim i zakończonym zadziorkami. Dziuple służą nie tylko dzięciołom. W lesie korzysta z nich kilkadziesiąt różnych gatunków owadów, ptaków i małych ssaków. Dzięcioł czarny jest bardzo płochliwy, dlatego spotkanie z nim to nie lada gratka!',
        src: require('../assets/images/animals/dzieciol_czarny.png'),
        alt:'Dzięcioł czarny z czerwonym łebkiem, szykujący się do uderzenia w konar drzewa.',
        available:false
    },
    {
        id:'a8',
        name:'dzięcioł trójpalczasty',
        description:'<b>Dzięcioł Trójpalczasty</b><br/><br/>Dzięcioł trójpalczasty jest wyspecjalizowanym gatunkiem borealno-górskim, ściśle związanym z obumierającymi lub martwymi świerkami. Odżywia się głównie larwami i poczwarkami korników. Cechą rozpoznawczą tego dzięcioła są trzy palce nóg; dwa zwrócone naprzód, a tylko jeden w tył. Obrączkuje świerki, dziurkując je dookoła, by spijać sok. Wierzch głowy u samca jest żółty, samicy srebrny. Ma bardzo ciemne skrzydła i rozmyte szare prążkowanie boków. Na bokach głowy szerokie czarne pasy. Białe pole od karku wzdłuż całego grzbietu. Głosy: krótkie kjok kjok lub też ciche gregregre. Dziuplę wykuwa na znacznych wysokościach w pniu świerka.',
        src: require('../assets/images/animals/dzieciol_trojpalczasty.png'),
        alt:'Dzięcioł trójpalczasty o szarp-białym umaszczeniu dłubiący w drzewie swoim krótkim szarym dziobem.',
        available:false
    },
    {
        id:'a9',
        name:'dzik',
        description:'<b>Dzik</b><br/><br/>To przodek naszej świni domowej. To typowe zwierzę leśne, ale żeruje także na polach, zwłaszcza w uprawach kukurydzy, ziemniaków i zbóż. Jest zwierzęciem wszystkożernym. Żywi się głównie bulwami, korzeniami, cebulami, owocami i pędami roślin zielnych, zjada także zwierzęta glebowe takiej jak: dżdżownice, larwy i poczwarki owadów, a niekiedy i małych gryzoni. Młode, zwane warchlakami rodzą się często już pod koniec zimy. Należy zachować szczególną ostrożność, gdy napotka się lochę (samicę) z młodymi. Dziki mogą żyć nawet do 10 lat!',
        src: require('../assets/images/animals/dzik.png'),
        alt:'Szary dzik stojący na liściach, korpus ma zwrócony w prawą stronę.',
        available:false
    },
    {
        id:'a10',
        name:'foka szara',
        description:'<b>Foka szara</b><br/><br/>Nazwa tego płetwonogiego ssaka morskiego pochodzi od jego ubarwienia. Dorosłe foki są szare z ciemnymi plamami, osobniki młode mają zaś białe futerko zwane lanugo. To właśnie szarytkę morską spośród trzech gatunków bałtyckich fok można najczęściej obserwować na naszym wybrzeżu. Równie często widzimy ją w wodzie jak i odpoczywającą na plaży. Upodobała sobie głównie ryby i skorupiaki, za którymi nurkuje w wodzie. W tym czasie jej serce bije nawet do piętnastu razy wolniej, dzięki czemu zużycie tlenu maleje, a foka może nurkować dłużej! Nawigację pod wodą ułatwiają jej wibrysy, czyli włosy czuciowe na pysku.',
        src: require('../assets/images/animals/foka_szara.png'),
        alt:'Wystający z wody łeb foki szarej.',
        available:false
    },
    {
        id:'a11',
        name:'gęś tundrowa',
        description:'<b>Gęś tundrowa</b><br/><br/>Gęś tundrową najłatwiej spotkać jesienią i wiosną. Wraz z towarzyszkami: gęsiami białoczelnymi, zbożowymi i gęgawami tworzy wielkie stada. Ptaki te wspólnie wędrują na i z zimowisk, a Parkowe rozlewiska są dla nich miejscem odpoczynku w podróży. Skubią roślinność łąk i pastwisk aby nabrać sił do dalszej drogi. Rozdzielają się dopiero na wiosnę, gdy dolatują na północ Europy i Azji. Tam zakładają gniazda i wychowują pisklęta.<br/>Gęś tundrowa to dość duży, brązowo upierzony ptak. Ma czarny dziób z małą pomarańczową przepaską. Łatwo ją pomylić z gęsią zbożową, z którą jeszcze do niedawna tworzyła jeden gatunek.',
        src: require('../assets/images/animals/ges_tundrowa.png'),
        alt:'Gęś tundrowa zwrócona w lewą stronę o jasnobrązowym łebku, pomarańczowych szczupłych nogach i szarawym kuprze.',
        available:false
    },
    {
        id:'a12',
        name:'głuszec',
        description:'<b>Głuszec</b><br/><br/>Duży ptak grzebiący wielkości mniej więcej gęsi. Bardzo dobrze widoczne są różnice w wyglądzie samca i samicy (dymorfizm płciowy). Samiec posiada charakterystyczne ubarwienie z którego wyróżnia się czerwona plama wokół oka. Również ogon jest bardzo widowiskową cechą tych zwierząt – kogut podczas toków rozkłada go w charakterystyczny wachlarz. Toki mają cztery fazy i zaczynają się na wiosnę w połowie marca. Samice są bardziej niepozorne a ich ubarwienie nosi cechy kamuflażu. Ptak ten najczęściej spotykany jest w starych lasach o gęstym podszycie. Gniazduje na ziemi pod wykrotami w gęstych zaroślach. Rodzice nie łączą się w pary. Samica znosi od 6 do 11 jaj. Sama wychowuje młode, które bardzo szybko nabierają samodzielności. Głuszce żywią się owadami a w okresie zimowym igłami świerka.  Nazwa głuszec wywodzi się od tego iż samiec podczas toków w jednej z faz głuchnie. Niegdyś pozwalało to myśliwym na bliskie podejście do bardzo płochliwego ptaka. Na Babiej Górze występują w strefie przejściowej między reglem górnym a piętrem kosodrzewiny. W chwili obecnej jego populację szacuje się na kilkanaście sztuk.',
        src: require('../assets/images/animals/gluszec.png'),
        alt:'Puszący się Głuszec, skierowany jest w lewą stronę a łeb ma podniesiony do góry.',
        available:false
    },
    {
        id:'a13',
        name:'gniewosz plamisty',
        description:'<b>Gniewosz plamisty</b><br/><br/>Gniewosz plamisty jest pięknym, niejadowitym wężem o barwie brązowej lub szarej. Lubi wygrzewać się pośród skał i zarośli, a na noc wracać do tej samej kryjówki. Jest powolny i łatwo go złapać, ale nigdy tego nie rób, bo wówczas „gniewa się” kąsając wielokrotnie rękę i wyrzucając cuchnącą ciecz z gruczołów znajdujących się na końcu ciała. Poluje głównie na jaszczurki, ale zjada też pisklęta i myszy. Wiosną gniewosze mają gody, a pod koniec lata rodzą się młode. Młode gniewosze wyglądają jak krótkie sznurówki do butów. Mogą żyć do 20 lat.',
        src: require('../assets/images/animals/gniewosz_plamisty.png'),
        alt:'Jasnobrązowy wąż, zwinięty na kamieniu.',
        available:false
    },
    {
        id:'a14',
        name:'jeleń szlachetny',
        description:'<b>Jeleń szlachetny</b><br/><br/>Jeleń szlachetny, król Puszczy Jodłowej jest jednym z największych zwierząt spotykanych w Świętokrzyskim Parku Narodowym. Samiec zwany bykiem nosi na głowie poroże, zrzuca je co roku i nakłada nowe, bardziej okazałe. Łanie czyli samice, nie mają na głowie poroża i są mniejsze od byków. Okres godowy jeleni zwany rykowiskiem, to prawdziwa gratka dla pasjonatów przyrody. Byki wtedy dumnie się prężą i ryczą, aby zwabić łanie i dać znać konkurentom o swojej obecności. Wtedy też łatwo zobaczyć jelenia lecz dla własnego bezpieczeństwa, warto trzymać się z dala od podekscytowanego zwierzęcia.',
        src: require('../assets/images/animals/jelen.png'),
        alt:'Skierowany w lewą stronę jeleń, o jasnym błyszczącym futrze i bujnym porożu.',
        available:false
    },
    {
        id:'a15',
        name:'jeż',
        description:'<b>Jeż</b><br/><br/>Jeż jest największym ssakiem owadożernym w Polsce. Wierzch ciała pokrywają szarobrunatne kolce, a brzuch biało-szare futerko. Ma malutkie oczy i krótkie zaokrąglone uszy, które praktycznie w całości są ukryte w futrze, oraz króciutki 20 milimetrowy ogonek. Zasiedla łąki, ogrody, pola, lasy liściaste. Aktywny jest głównie w ciągu dnia. Żyje samotnie. Wygrzebuje rozgałęzione korytarze i formuje kopce. Pływa bardzo dobrze. Nie zapada w sen zimowy. Zjada dżdżownice, które obezwładnia ukąszeniem. W menu jeża znajduje się także owady, wije i ślimaki.',
        src: require('../assets/images/animals/jez.png'),
        alt:'Zwrócony w prawo jeż, stoi na kamieniu, a łeb ma zwrócony w dół.',
        available:true
    },
    {
        id:'a16',
        name:'konik polski',
        description:'<b>Konik polski (Equus caballus gmelini)</b><br/><br/>Ten niewielki mierzący zaledwie ok. 130-140 cm wysokości konik jest jedyną prymitywną Polską rasą koni zbliżoną do tarpanów żyjących dawniej m.in. na terenie dawnych puszcz i terenów otwartych Polski, Litwy i Prus. Charakterystyczne myszate umaszczenie z czarną pręgą biegnącą przez grzbiet oraz czarną grzywą i ogonem to cechy rozpoznawcze tej rasy koni. Koniki polskie doskonale przystosowane są do trudnych warunków środowiskowych panujących w prowadzonej w Roztoczańskim Parku Narodowym hodowli rezerwatowej gdzie przebywają na wygrodzonym obszarze ok. 200 ha praktycznie bez ingerencji człowieka. Mogą odżywiać się nawet małowartościowym pokarmem i nie straszne są im siarczyste mrozy i gruba pokrywa śniegowa zimą gdyż w tym okresie ich sierść staje się gęstsza i może mierzyć nawet kilkanaście cm długości. Nierzadko w styczniu lub lutym przy mrozie -20 °C na świat przychodzą źrebaki, które już po 20-30 minutach dzielnie podążają za klaczą. O ich żywotności świadczy fakt, że najstarsza klacz w RPN przeżyła 33 lata, a druga pod względem wieku przeżyła 29 lat.',
        src: require('../assets/images/animals/konik_polski.png'),
        alt:'Biało umaszczony konik polski z nalotami brązowej sierści na kłębie, zwrócony jest w prawą stronę.',
        available:false
    },
    {
        id:'a17',
        name:'kos',
        description:'<b>Kos</b><br/><br/>Przyjemna dla ucha „fletowa” melodia, usłyszana w parku czy ogrodzie, to zasługa kosa – niedużego ptaka z rodziny drozdowatych. Swoją obecność zaznacza już od wczesnych godzin porannych, gdy słońce jeszcze skrywa się za horyzontem. W Polsce pozwala się obserwować przez cały rok. Spotkamy go zarówno na wsi, jak i w mieście, zwłaszcza w sąsiedztwie drzewek owocowych. Uwielbia jabłka i jagody, ale nie pogardzi także dżdżownicami czy owadami. Poszukując pożywienia, wesoło podskakuje na obu nogach, prezentując długi ogon. Samiec ma czarne upierzenie i żółty dziób, a samice są brązowe.',
        src: require('../assets/images/animals/kos.png'),
        alt:'Czarny kos z pomarańczowym dziobem i obwódkami wokół oczu, chwytająca się gałęzi swoimi rachitycznymi ciemnopomarańczowymi nóżkami.',
        available:false
    },
    {
        id:'a18',
        name:'kozica',
        description:'<b>Kozica</b><br/><br/>Kozica to symbol tatrzańskich parków narodowych (polskiego i słowackiego). Jest reliktem epoki lodowcowej.  Samiec kozicy to cap, samica koza, a stado to kierdel. Jest doskonale przystosowana do życia w górach. Jej racice mają bardzo twarde krawędzie i miękki środek, dlatego nie ślizga się przy skokach i biegu po górskich skałach. Jej krew zawiera więcej czerwonych krwinek niż krew innych zwierząt, ma też wyjątkowo duże serce, co pozwala jej na ekstremalny wysiłek na przykład podczas ucieczki. Na łbie zarówno capy, jak i kozy noszą rogi zwane hakami. Kozice (oraz świstaki) objęto w Tatrzańskim Parku Narodowym ochroną gatunkową już w 1868 roku.',
        src: require('../assets/images/animals/kozica.png'),
        alt:'Odwrócona tyłem kozica z uszami postawionymi na sztorc, obserwuje otoczenie z krawędzi skały.',
        available:false
    },
    {
        id:'a19',
        name:'kuna leśna',
        description:'<b>Kuna leśna</b><br/><br/>Kuna leśna, nazywana także tumakiem. Żółtokremowe futro na szyi i brzuchu umożliwia łatwe odróżnienie od jej bliskiej kuzynki – kuny domowej. Stara się unikać kontaktu z ludźmi i zawsze znajdować się w pobliżu jakiegoś schronienia. Przeważnie jeden osobnik dysponuje kilkoma schronieniami w obrębie własnego terytorium. Przy niższych temperaturach otoczenia w zimie, kuny szukają schronienia pod ziemią. Podstawę ich diety stanowią małe ssaki, ptaki, węże, płazy, owady, ślimaki i owoce. W sezonie, leśne jagody mogą stanowić nawet 30% ich diety. Mogą również żywić się padliną.',
        src: require('../assets/images/animals/kuna.png'),
        alt:'Skierowana w lewą stronę kuna, o jasno-brązowym futerku z charakterystycznym białym szalem okrywającym jej szyję.',
        available:true
    },
    {
        id:'a20',
        name:'lis',
        description:'<b>Lis</b><br/><br/>Lis rudy jest największym z przedstawicieli rodzaju Vulpes, jak i najbardziej rozprzestrzenionym przedstawicielem drapieżnych (Carnivora). Rozprzestrzeniony na całej półkuli północnej, od koła podbiegunowego poprzez Amerykę Północną, Europę i Azję. Zasięg jego występowania wzrasta wraz z ekspansją człowieka, dzięki któremu dotarł m.in. na Australię, gdzie jest uważany za szkodnika zagrażającego życiu przedstawicieli tamtejszych ssaków i ptaków.<br/><br/>Obecnie wyróżnia się 45 podgatunków lisa rudego, które można podzielić na dwie grupy – rasy duże, zamieszkujące tereny północne, i małe, rezydujące głównie w Azji, przeważnie w jej środkowo-wschodniej części. Lisy rude są zwierzętami społecznymi, których stada przewodzone są przez parę alfa mającą jako jedyna prawo do rozmnażania. Pozostałymi członkami stada są zwykle potomkowie pary alfa, które zostają z rodzicami, by pomóc w opiece nad szczeniętami.<br/><br/>Lis rudy poluje głównie na małe gryzonie, lecz żywi się również zającowatymi, kurowatymi, małymi gadami, bezkręgowcami oraz młodymi kopytnymi.',
        src: require('../assets/images/animals/lis.png'),
        alt:'Zwrócony w lewo rudy lis, z białym szalikiem z futra owiniętym wokół szyi.',
        available:false
    },
    {
        id:'a21',
        name:'łoś',
        description:'<b>Łoś</b><br/><br/>Łoś jest herbowym zwierzęciem parku. Żyje na podmokłych łąkach, bagnach i w rozległych borach sosnowych. Żywi się roślinami błotnymi, pąkami, pędami i liśćmi krzewów i drzew, a zimą igłami i korą sosen. Łoś potrafi świetnie pływać i nurkować. Porusza się powoli i niezgrabnie, ale wystraszony może biec nawet z prędkością 60 km/h. Nigdy nie należy do niego podchodzić, głaskać i karmić. Samiec to byk, a samica – klępa. Głowę samca zdobi poroże w kształcie badyli lub łopat. Samice nie posiadają poroży. Łosie mogą ważyć nawet ponad 0,5 tony. Żyją od 20 do 50 lat.',
        src: require('../assets/images/animals/los.png'),
        alt:'Ciemnoszary łoś idący powoli w prawo o bujnym porożu.',
        available:false
    },
    {
        id:'a22',
        name:'myszołów',
        description:'<b>Myszołów</b><br/><br/>Myszołów to nasz najliczniejszy szponiasty ptak lęgowy w Margurskim Parku Narodowym. Jego ubarwienie jest dosyć zmienne, jednak charakterystyczna jest jasna plama na piersi. Występuje w zróżnicowanym środowisku, tam, gdzie tereny otwarte z dużą ilością śródpolnych zadrzewień przeplatają się z lasami. Wiele myszołowów migruje na zimowiska na południe i zachód Europy, jednak część z nich zostaje w naszym kraju na zimę. Spotykane są także takie osobniki, które na zimę przylatują do nas terenów oddalonych na północny wschód. Gatunek objęty ochroną.',
        src: require('../assets/images/animals/myszolow.png'),
        alt:'Myszołów szykujący się do porwania swoimi ostrymi szponami ofiary.',
        available:false
    },
    {
        id:'a23',
        name:'niedźwiedź brunatny',
        description:'<b>Niedźwiedź brunatny</b><br/><br/>Sierść niedźwiedzia brunatnego ma barwę ciemnobrązową, choć u niektórych podgatunków może być jaśniejsza. W pozycji wyprostowanej Ursus arctos mierzy – w zależności od płci – od 1,8 do 3 m. Masa ciała poszczególnych osobników waha się między 80 a 780 kg. Niedźwiedzica wydaje na świat co dwa lata dwoje-troje młodych. Ciąża trwa około 8 miesięcy. Poród następuje zwykle między grudniem a lutym. Niedźwiedzie brunatne najchętniej zjadają łososie i inne ryby, a także miód. Żywią się też ssakami, gryzoniami, ptakami, ptasimi jajami, owocami, nasionami, grzybami, dżdżownicami, ślimakami, a w przypadku braku innego pożywienia także trawą. Na zimę przygotowuje sobie legowisko zwane gawrą. Przebywa w nim od końca listopada do końca lutego. Niedźwiedź brunatny jest ściśle chroniony na obszarze całej Polski.',
        src: require('../assets/images/animals/niedzwiedz.png'),
        alt:'Duży szary niedźwiedź, leciutko skąpany w promieniach słońca zwrócony w lewą stronę.',
        available:false
    },
    {
        id:'a24',
        name:'podkowiec mały',
        description:'<b>Podkowiec mały</b><br/><br/>Podkowiec mały ma wielkość kciuka. Zwisając głową w dół otula się skrzydłami, co upodabnia go do dużego kokonu owada. Dni przesypia, a nocami poluje na owady. Nazwa pochodzi od fałdu skóry w kształcie podkówki okalającej jego nos, fałd ten  ma także duże znaczenie w echolokacji. Podkowiec „widzi” uszami: wysyła dźwięki o wysokiej częstotliwości i odbiera echo odbite od swojego otoczenia. Umożliwia mu to omijanie przeszkód, a nawet polowanie na małe motyle nocne! Podkowce na zimę zlatują się do jaskiń i zapadają w głęboki sen. W Ojcowskim Parku Narodowym w jaskini Ciemnej co roku zimuje nawet kilkaset podkowców.',
        src: require('../assets/images/animals/nietoperz.png'),
        alt:'Jasnobrązowy nietoperz szybujący na swoich w pełni rozwiniętych skrzydłach.',
        available:false
    },
    {
        id:'a25',
        name:'orlik krzykliwy',
        description:'<b>Orlik krzykliwy</b><br/><br/>Jest ptakiem drapieżnym, jednym z mniejszych orłów występujących w Europie. W naszym gniazduje nielicznie, głównie w jego wschodniej części. Największe zagęszczenie jednak notuje się w Polsce południowo – wschodniej. To miejsce,  gdzie położony jest MPN, którego orlik krzykliwy jest symbolem. Jest on ptakiem wędrownym, zimę spędza w Afryce, natomiast wiosną przylatuje na lęgowiska, gdzie rodzą są młode. Orliki potrzebują do życia zarówno starych lasów, gdzie zakładają gniazda, ale także otwartej, urozmaiconej przestrzeni, która zapewnia im możliwość polowania i dostęp do pokarmu.',
        src: require('../assets/images/animals/orlik_krzykliwy.png'),
        alt:'Uchwycony w locie, duży, brązowy orlik krzykliwy, z ostrym wygiętym żółtym dziobem.',
        available:false
    },
    {
        id:'a26',
        name:'bielik',
        description:'<b>Bielik</b><br/><br/>Mówimy orzeł bielik, bo to brzmi dumnie. Ale czy bielik jest orłem? I jaki ptak jest w naszym godle? Orzeł, czy bielik? Bielik nie jest orłem, a w godle jest właśnie bielik z racji tego, że ptak przedstawiony na symbolu naszego kraju ma nieopierzone nogi, tzw. skoki. Orły, np. orzeł przedni, mają skoki opierzone. Łacińska nazwa bielika Haliaeetus albicilla znaczy tyle, co morski orzeł z białym ogonem. Bo to od bieli piór w ogonie (sterówek) dorosłych ptaków, bielik zawdzięcza swą nazwę. Nasz bielik, to wielki ptak, iście królewski. Rozpiętość skrzydeł dorosłych samic sięga 2,5 m. Żaden z naszych ptaków nie ma tak imponujących rozmiarów. Młode bieliki są brązowe i mają ciemne dzioby, a bieli na ogonie mają niewiele. Dopiero w 6 roku życia ich upierzenie jest w pełni dojrzałe. Biały wachlarzowato rozłożony podczas lotu ogon jest widoczny z daleka. Do tego jasna głowa i żółty, potężny dziób, od którego niegdyś zwano bielika łamignatem. Polskę zamieszkuje jedna z największych populacji bielika na świecie, po norweskiej i rosyjskiej. I jest go coraz więcej. Dorosłe ptaki związują się w pary na całe życie i są terytorialne, czyli trzymają się jednego obszaru. Tam, w bezpiecznym dla siebie lesie, w pobliżu dużych zbiorników, budują gniazdo (czasem nawet kilka), które jak przystało na wielkość bielika jest równie imponujące. Największe gniazda, zajmowane przez dziesięciolecia, mogą mieć 2,5 m średnicy, wysokość do 5 m i wagę 600 kg! Jak widzimy u bielika wszystko jest największe. Co jedzą nasze herbowe ptaki? Głównie ryby, ptaki wodne, pisklęta i podloty innych ptaków, nawet ich drapieżnych kuzynów. Zimą chętnie zjadają padlinę. Ale to, mamy nadzieję, nie umniejsza ich godności herbowego ptaka Polski i Wolińskiego Parku Narodowego.',
        src: require('../assets/images/animals/orzel_bielik.png'),
        alt:'Bielik o szeroko rozpostartych, wielkich skrzydłach oraz żółtych łapach oraz dziobie.',
        available:false
    },
    {
        id:'a27',
        name:'podróżniczek',
        description:'<b>Podróżniczek</b><br/><br/>Podróżniczek to rzadki krewny słowika, gnieżdżący się w zaroślach kosodrzewiny na subalpejskich torfowiskach i podmokłych łąkach. Wyróżnia się niebieskim „śliniakiem” z kasztanową plamką pośrodku i pomarańczowymi plamami po bokach ogona. Kasztanowa barwa plamki jest dowodem, że w Karkonoszach gnieździ się północny podgatunek podróżniczka. Stanowisko występowania podróżniczka możemy określić jako reliktowe – jest pamiątką po epoce lodowej.',
        src: require('../assets/images/animals/podrozniczek.png'),
        alt:'Siedzący na gałęzi podróżniczek o rozdziawionym dziubku oraz brzuszku o niebiesko-pomarańczowym upierzeniu.',
        available:false
    },
    {
        id:'a28',
        name:'pomurnik',
        description:'<b>Pomurnik</b><br/><br/>Pomurnik jest niewielkim górskim ptakiem. Gniazda zakłada wśród tatrzańskich skał, a zimę spędza głównie w Pieninach. Ze względu na szarą barwę gdy złoży skrzydła nie zwraca na siebie uwagi i może być nawet pomylony z myszą. Jednak przemykając po skałach trzepocząc skrzydłami dzięki czerwonym lotkom przypomina ogromnego i pięknego motyla. Poluje na niewielkie owady skutecznie wykorzystując swój długi dziób. W Polsce jest niezwykle rzadkim ptakiem lęgowym. Głównym zagrożeniem dla tego ptaka są wspinacze skalni pojawiający się w jego potencjalnych miejscach rozrodu już wczesną wiosną.',
        src: require('../assets/images/animals/pomurnik.png'),
        alt:'Długodzioby pomurnik o rozpiętych w pełnym locie skrzydełkach o czerwonym upierzeniu.',
        available:false
    },
    {
        id:'a29',
        name:'puchacz',
        description:'<b>Puchacz, </b><i>Bubo bubo</i><br/><br/>Biotop lęgowy puchacza w górach stanowią ściany skalne i skały na stoku w prześwietlonym starodrzewie, często liściastym. Na nizinach olsy, ale też wyspy, brzegi jezior, starodrzewa różnego typu w pobliżu otwartej przestrzeni, czasami małe fragmenty środowisk, np. kamieniołom, zrąb czy łąka ze stertami siana. Unika bliskości człowieka, choć czasem gniazduje w czynnych kamieniołomach i blisko zabudowań. Poluje na otwartych przestrzeniach lub w prześwietlonych lasach. To gatunek osiadły prowadzący intensywne toki na przełomie lutego i marca. Gniazdo stanowi dołek wygrzebany w ziemi pod skałą, pniem, na kępie w olsie. Chętnie zajmuje opuszczone gniazda na drzewach dużych ptaków (takich jak np.: bocian czarna czy bielika) jak również sztuczne platformy. Gniazdo z czasem wypełnia się resztkami ofiar, które można też spotkać w pobliżu gniazda. Jaja 2–3 (1–4) składa zwykle w marcu, a wysiaduje je wyłącznie samica przez 32–36 dni. Po miesiącu pisklęta opuszczają gniazdo ale dopiero po kolejnych 2 tygodniach uzyskują zdolność do lotu. Zajęte terytorium samiec znaczy głosem i odchodami w widocznych punktach. Tu też liczne ślady ofiar: pióra ptaków szponiastych i sów, skalpy i kolce jeży.',
        src: require('../assets/images/animals/puchacz.png'),
        alt:'Puchacz o intensywnie żółtych oczach.',
        available:false
    },
    {
        id:'a30',
        name:'puszczyk',
        description:'<b>Puszczyk</b><br/><br/>Puszczyk jest najliczniejszą sową zarówno Wielkopolskiego Parku Narodowego, jak i Polski a nawet i całej Europy! Czarne oczy, świadczące o nocnej aktywności, dają sowie ok. 100-krotnie lepszy wzrok niż człowiekowi. Typowy głos samca to pohukiwanie (długie „huuu”, krótka przerwa, kilka urwanych, przyciszonych dźwięków „hu” i na sam koniec znów przeciągłe, dźwięczne „huuu”). Spełnia funkcję głosu terytorialnego, wabiącego podczas zalotów oraz kontaktowego przy przynoszeniu jedzenia dla samicy. Ubarwienie maskujące, bezszelestny lot, szlara oraz możliwość obrotu głowy nawet o 270° sprawia, że puszczyki to łowcy doskonali!',
        src: require('../assets/images/animals/puszczyk.png'),
        alt:'Siedzący na gałęzi puszczyk o jasno-szarych piórach i brunatnych skrzydłach.',
        available:false
    },
    {
        id:'a31',
        name:'ropucha zielona',
        description:'<b>Ropucha zielona</b><br/><br/>Jeden z 3 gatunków ropuch w Polsce. Mniejsza od ropuchy szarej, ale nieco większa, od najrzadszej w kraju, ropuchy paskówki. Najłatwiej odróżnić ją po ubarwieniu. Niemal cała jest w zielonych, nieregularnych plamach, przypominających maskujący strój wojskowy tzw. „moro”. Spód ciała jest jasny. Ponadto, ropucha ta wyróżnia się przepięknym głosem, podobnym do śpiewu kanarka. Zamieszkuje różnorodne środowiska, ale bardziej niż inne ropuchy unika terenów wilgotnych, preferując raczej suche obszary i osiedla ludzkie, nawet duże miasta. Tak jak wszystkie płazy jest drapieżnikiem o aktywności nocnej.',
        src: require('../assets/images/animals/ropucha_zielona.png'),
        alt:'Przycupnięta na skale ropucha o plamistym, zielonym ciele.',
        available:false
    },
    {
        id:'a32',
        name:'ryś euroazjatycki',
        description:'<b>Ryś euroazjatycki</b><br/><br/>Ryś euroazjatycki, <i>Lynx Lynx</i> jest herbowym gatunkiem Bieszczadzkiego Parku Narodowego. Ten największy w Europie przedstawiciel kotowatych prowadzi samotniczy tryb życia z wyjątkiem okresu godowego, który trwa od stycznia do marca. Przez około rok młodymi rysiami opiekuje się tylko samica. Ryś poluje głównie na sarny, młode jelenie i dziki oraz mniejsze ssaki, a także ptaki leśne. Na terenie Bieszczadzkiego PN przemieszcza się około 6 dorosłych rysi, żyjących w obrębie terytoriów przekraczających 100 km<sup>2</sup>.',
        src: require('../assets/images/animals/rys.png'),
        alt:'Siedzący wśród traw ryś o postawionych uszach.',
        available:false
    },
    {
        id:'a33',
        name:'salamandra plamista',
        description:'<b>Salamandra plamista</b><br/><br/>Salamandra plamista to największy europejski przedstawiciel płazów ogoniastych; osiąga długość 10–24 cm (maksymalnie 32 cm). Wilgotna i błyszcząca skóra salamandry plamistej ubarwiona jest jaskrawo. Czarny i błyszczący wierzch ciała kontrastuje z żółtymi lub pomarańczowymi plamami bądź pasami. Prowadzi lądowy tryb życia. Siedliska dorosłych płazów nie ograniczają się do miejsc, w których występuje woda; można je spotkać w wilgotnych lasach liściastych, mieszanych, a rzadziej iglastych, na polanach, w obrębie skalistych zboczy, w buszu, a nawet na obszarach rolniczych oraz dolinach ze strumieniami.',
        src: require('../assets/images/animals/salamandra_plamista.png'),
        alt:'Salamandra plamista o niebiesko-zielonym umaszczeniu, pełnym żółtych kropek.',
        available:false
    },
    {
        id:'a34',
        name:'sarna',
        description:'<b>Sarna</b><br/><br/>Sarny spotykamy w lasach, na polach uprawnych i łąkach. Aktywne są o zmierzchu i w nocy, w ciągu dnia ukrywają się zazwyczaj w leśnej gęstwinie lub w łanach zbóż. Żyją pojedynczo lub w niewielkich grupach i tylko w okresie zimy łączą się w duże stada. Odżywiają się głównie urozmaiconym pokarmem roślinnym (trawy, zioła, pędy jeżyn i malin) i  grzybami. Lubią lizać sól w tzw. lizawkach. W ciągu roku samica sarny rodzi od jednego do trzech młodych. Samce na głowie mają poroże, które zrzucają jesienią, a zimą odrasta im na nowo. Odgłosy wydawane przez samce do złudzenia przypominają szczekanie psa.',
        src: require('../assets/images/animals/sarna.png'),
        alt:'Odwrócona tyłem sarenka, wykręcająca głowę, by obejrzeć się za siebie.',
        available:true
    },
    {
        id:'a35',
        name:'sokół wędrowny',
        description:'<b>Sokół wędrowny</b>, <i>Falco peregrinus</i><br/><br/>W okresie lęgowym związany ze ścianami skalnymi w górach, oraz lasami i terenami zurbanizowanymi na nizinach. W poszukiwaniu pokarmu przelatuje na duże odległości. Ofiary stanowią ptaki chwytane w powietrzu w trakcie błyskawicznego lotu nurkowego. Zimuje w pobliżu lęgowisk, koczuje. Toki rozpoczynają już w lutym. Loty godowe odbywają się bardzo wysoko, ale ptaki tokują też przy samym gnieździe.<br/>Gniazda nie buduje. Jaja składa w niszy skalnej, w dużym gnieździe na drzewie lub na wysokiej, sztucznej konstrukcji (np. wieża obserwacyjna, wodna, dach wieżowca). Po raz pierwszy lęgnie się w 3. roku życia. Składa 2–4 jaja w marcu. Młode po lęgach przemieszczają się na dużą odległość. Niepokojone latają wokół wydając głośne zawołania.<br/>Sokół wędrowny odbudowuje obecnie populacje po latach nieobecności spowodowanej zatruciem środowiska (pestycydy, głównie DDT). W ataku może osiągać prędkość nawet do 320 km/h.',
        src: require('../assets/images/animals/sokol_wedrowny.png'),
        alt:'Sokół w pełnym locie, łapiący powietrze w rozłożone szare, skrzydła.',
        available:false
    },
    {
        id:'a36',
        name:'sowa uszata',
        description:'<b>Sowa uszata</b><br/><br/>Sowa uszata lub inaczej uszatka zwyczajna swoją nazwę wzięła od charakterystycznych piór na głowie, które jako żywo przypominają uszy a jednak uszami nie są. Sądzi się iż ułatwiają one maskowanie się, oraz być może stanowią narzędzie ułatwiające komunikację pomiędzy poszczególnymi osobnikami. Sam ptak jest sową o średnim rozmiarze a duża głowa i skrzydła jak również bujna pokrywa piór sprawia iż uszatka wydaje się większa niż jest w rzeczywistości. Samo upierzenie posiada kolor rdzawobrązowy a niekiedy szary. Sowa uszata najczęściej występuje w lasach iglastych oraz mieszanych. Niekiedy uszatki wybierają sąsiedztwo ludzkie, gdzie gniazdują na starych drzewach obok kościołów i cmentarzy. Pożywieniem uszatki są głównie gryzonie, jednak w przypadku niedoboru takiego pokarmu potrafi zastąpić go owadami a nawet płazami. Poluje na otwartym terenie. Samice składają zazwyczaj do 6 jaj i troskliwie opiekują się młodymi, które po 2 miesiącach opuszczają gniazdo. Sowa uszata objęta jest w Polsce ochroną ścisłą.',
        src: require('../assets/images/animals/sowa_uszata.png'),
        alt:'Puchata sowa o gęstych piórach, żółtych oczach i długich szponach, chwytających się gałęzi.',
        available:true
    },
    {
        id:'a37',
        name:'traszka zwyczajna',
        description:'<b>Traszka zwyczajna</b><br/><br/>Choć z kształtu przypomina jaszczurkę, to więcej wspólnego ma z żabami i ropuchami, bo tak jak one jest płazem. Posiada maskującą barwę. Samiec jest większy od samicy. W okresie godowym dodatkowo możemy płcie odróżnić po tym, że samiec posiada grzebień biegnący od głowy do końca ogona. Podczas godów samce traszki wabią partnerki zmysłowym tańcem, wykorzystując swój imponujący grzebień. W okresie godowym traszki silnie związane są ze środowiskiem wodnym. Pozostałą część roku spędzają na lądzie, prowadząc nocny tryb życia. Zimą hibernują ukryte w norkach, pod korzeniami drzew lub w piwnicach. Resztę roku, dorosłe traszki spędzają na lądzie. W dzień ukrywają się np. pod kamieniami, nocą polują na dżdżownice, pająki, ślimaki i owady.',
        src: require('../assets/images/animals/traszka_zwyczajna.png'),
        alt:'Traszka o żółtych oczach i ogonie niemal tak długim jak reszta ciała.',
        available:false
    },
    {
        id:'a38',
        name:'wilk',
        description:'<b>Wilk</b><br/><br/>Wilki żyją w grupach rodzinnych złożonych z trwałej pary rodziców oraz ich dzieci. Młode wilki zostają z rodzicami zwykle do drugiego roku życia, ponieważ dopiero w tym wieku stają się w pełni samodzielne. Wcześniej muszą się wiele nauczyć od swoich rodziców i pomóc w wychowywaniu młodszego rodzeństwa. Wilki należą do gatunków tworzących jedne z najbardziej rozbudowanych relacji społecznych. Posiadają skomplikowany system komunikacji.<br/><br/>Oczywiście jako drapieżniki aby przeżyć, muszą polować. W Polsce podstawą ich diety są jelenie, sarny i dziki. Wbrew bajkom wilki nie atakują ludzi i nie traktują nas jako potencjalnej zdobyczy.',
        src: require('../assets/images/animals/wilk.png'),
        alt:'Uważnie stąpający wilk o nieco pochylonym łbie i uniesionej przedniej łapie.',
        available:false
    },
    {
        id:'a39',
        name:'wydra',
        description:'<b>Wydra</b><br/><br/>To największy przedstawiciel z rodziny łasicowatych. Wydra prowadzi ziemno-wodny tryb życia i wykształciła szereg przystosowań, umożliwiających jej życie na pograniczu tych dwóch środowisk. W sprawnym poruszaniu się w wodzie pomaga wydrze wydłużone ciało, długi ogon, mała głowa oraz palce obu kończyn spięte błoną pławną. Oko osłonięte jest trzecią powieką, która zabezpiecza gałkę oczną przed uszkodzeniem podczas nurkowania oraz umożliwia dobre widzenie pod wodą. Drapieżnik ten może przebywać pod wodą do 1 minuty, a gdy czuje się zagrożony – nawet do 4 minut. Rzadko kopie sama kopie nory i zazwyczaj wykorzystuje schronienia wykopane przez inne zwierzęta (np. bobry). Podstawę pokarmu wydry stanowią ryby, ale zjada również raki i płazy (głownie żaby trawne).Dietę jej uzupełniają ptaki wodne, ssaki i bezkręgowce. Są aktywne głownie o zmierzchu i w nocy, ale żerują także o świcie. Znaczną część doby zwierzęta te spędzają w miejscu odpoczynku  (w norach, wykrotach czy żeremiach bobrów).',
        src: require('../assets/images/animals/wydra.png'),
        alt:'Wydra o szarej sierści, krótkich łapkach i wydłużonej szyi, przechodzącej w główkę.',
        available:false
    },
    {
        id:'a40',
        name:'zaskroniec zwyczajny',
        description:'<b>Zaskroniec zwyczajny</b><br/><br/>Zaskroniec to niegroźny dla człowieka wąż, łatwy do rozpoznania po charakterystycznych żółtych plamach po obu stronach głowy. Żyje w pobliżu wód, jest doskonałym pływakiem, potrafi nurkować, co wykorzystuje polując na ryby. Żywi się także płazami i gryzoniami. Swoje ofiary połyka jeszcze żywe. Sam staje się pokarmem innych drapieżników, między innymi ptaków. W Tatrach występuje w dolnej części regla dolnego, najwyżej spotykany był nad Toporowym Stawem Niżnym, 1089 m n.p.m. Nie bójmy się go, ale nie bierzmy do rąk, bo w obronie pobrudzi nas cuchnącą wydzieliną obronną.',
        src: require('../assets/images/animals/zaskroniec_zwyczajny.png'),
        alt:'Zwinięty na ziemi zaskroniec o brunatnej łusce.',
        available:false
    },
    {
        id:'a41',
        name:'zięba',
        description:'<b>Zięba</b><br/><br/>Zięba to jeden z najliczniejszych ptaków występujących w całym kraju, jest obecny również w Świętokrzyskim Parku Narodowym. Zamieszkuje niewielkie zadrzewienia jak i zwarte tereny leśne. Samce mają szarą czapeczkę, rdzawe kolory po bokach głowy, zaś skrzydła czarne z kilkunastoma białymi piórkami. Samice są brązowo-szare bez żadnych rdzawych piórek. Trudno dostrzegalne gniazda zrobione z mchu i suchej trawy zakładają na gałęziach, gdzie samica składa 3-6 jaj. W czasie wysiadywania jaj, samiec karmi swoją partnerkę. Zięby to łuszczaki, żywią się ziarnami, drobnymi owocami i owadami.',
        src: require('../assets/images/animals/zieba.png'),
        alt:'Zwrócona w lewo zięba o niebiesko-pomarańczowych piórkach i biało-żółto-czarnych skrzydłach.',
        available:true
    },
    {
        id:'a42',
        name:'zimorodek',
        description:'<b>Zimorodek</b><br/><br/>Występuje zwykle nad wodami, zwłaszcza strumykami o wysokich urwistych brzegach, gdzie zakłada norki. Przesiaduje nieruchomo, wypatrując małych ryb, łowionych po pikowaniu do wody. Ma nieproporcjonalnie długi i mocny dziób oraz bardzo krótkie nogi i ogon. Pomimo barwnego upierzenia (a zwłaszcza dzięki niemu, gdyż u nieruchomego ptaka jest zaskakująco kamuflażowe) niełatwo go wypatrzeć w terenie. Spłoszony leci szybko, w linii prostej, jak „strzała”, tuż nad powierzchnią wody, wydając donośny, wysoki w tonie gwizd. Cechą, która pozwala odróżnić samca od samicy jest kolor dzioba. Samica w okresie lęgowym ma żuchwę w ⅔ ubarwioną na różowo-pomarańczowo, a dziób samca jest w całości czarny.',
        src: require('../assets/images/animals/zimorodek.png'),
        alt:'Stojący na gałązce zimorodek o jaskrawym niebiesko-pomarańczowym upierzeniu i długim dziobie.',
        available:false
    },
    {
        id:'a43',
        name:'żmija zygzakowata',
        description:'<b>Żmija zygzakowata</b><br/><br/>Żmija zygzakowata jest wężem przybierającym różne barwy, można spotkać jasno-szare lub brązowe żmije z wyraźną wstęgą na grzbiecie, jak i czarne z niemal niewidocznym zygzakiem. W ciągu dnia żmije chętnie wygrzewają się w słońcu na skraju lasów, na polanach, miedzach i piargach. Żmije nie są agresywne, zwykle starają się zostać niezauważone lub uciekają przed człowiekiem. Gdy żmija czuje się zagrożona i nie ma wyboru może ukąsić. Żmija jest jedynym jadowitym gadem występującym w Polsce.',
        src: require('../assets/images/animals/zmija_zygzakowata.png'),
        alt:'Żmija zygzakowata o uniesionym łebku, zwinięta wśród traw.',
        available:false
    },
    {
        id:'a44',
        name:'żubr',
        description:'<b>Żubr</b><br/><br/>Żubr to największy lądowy, wolno żyjący ssak roślinożerny Europy. Dorosły samiec może osiągać długość do 300 cm, wysokość do 190 cm, wagę do 1000 kg.  Ma masywny przód ciała, oraz duży garb w okolicy tułowia. Głowa, szyja i przednia część tułowia pokryte są nieco dłuższą sierścią, tylna część jest delikatniejsza i pokryta krótszymi włosami. Potężne rogi ma na głowie zarówno samiec, jak i samica.  Pożywieniem żubra w okresie wiosenno-jesiennym są głównie trawy i zioła oraz kora drzew, gałęzie, liście i żołędzie. W czasie zimy dokarmiane są sianem. Na wolności żyją w grupach złożonych z krów, młodzieży i cieląt, do których okresowo dołączają byki. Wydawany przez nie dźwięk to chruczenie.<br/>Żubr objęty jest ochroną gatunkową, figuruje w Czerwonej Księdze i podlega „Konwencji o ochronie gatunków dzikiej flory i fauny europejskiej oraz ich siedlisk”.',
        src: require('../assets/images/animals/zubr.png'),
        alt:'Stojący wśród traw żubr o pochylonym łbie.',
        available:false
    },
    {
        id:'a45',
        name:'żuraw',
        description:'<b>Żuraw</b><br/><br/>Wysoki, smukły, o stonowanym ubarwieniu – żuraw to zdecydowanie jeden z najbardziej dostojnych polskich ptaków. W zachwyt wprowadza nie tylko zgrabnym wyglądem, ale także niezwykłym sposobem bycia. Wraca z zimowisk jako jeden z pierwszych, umilając nam wiosenne poranki swoim pięknym głosem – tzw. klangorem. To prawdziwy romantyk – tworzy pary na całe życie! Partnerzy stale zabiegają o swoje względy, co roku odnawiając „więzy małżeńskie”. W tym celu wykonują skomplikowany taniec składający się z podskoków i ukłonów. Zlotowiska tych towarzyskich ptaków mogą liczyć nawet do kilku tysięcy osobników!',
        src: require('../assets/images/animals/zuraw.png'),
        alt:'Żuraw o biało-czarnym ubarwieniu, dziobem skierowany w lewą stronę.',
        available:false
    },
    {
        id:'a46',
        name:'jarząbek',
        description:'<b>Jarząbek</b><br/><br/>Większy od kuropatwy o krępej sylwetce z krótkimi nogami. Całe upierzenie gęsto plamkowane brązowo, szaro i czarno. Samiec wyróżnia się bardziej szarą tonacją ubarwienia i ma czarne gardło, podczas gdy samica jest bardziej brunatna. Ogon szary z czarną pręgą przy końcu. Zasiedla stare bory i lasy mieszane z bujnym podszytem, w górach na stokach i w dolinach potoków. Gniazdo jest dobrze ukryte pod drzewem, krzakiem lub pod osłoną roślinności. To płytkie zagłębienie wyścielone trawą, łodygami i liśćmi. Jest trudny do zaobserwowania z powodu skrytego trybu życia.',
        src: require('../assets/images/animals/jarzabek.png'),
        alt:'Jarząbek wolną kroczący w prawym kierunku, jego umaszczenie na plecach jest jasnobrązowe, a brzuch jest biały i posiada czarne kropki.',
        available:true
    },
    {
        id:'a47',
        name:'batalion',
        description:'<b>Batalion</b><br/><br/>Batalion to niewielki ptak związany z mokradłami. Jest symbolem Biebrzańskiego Parku Narodowego, który chroni największe w Polsce bagna. Wiosną tysięczne stada batalionów gromadzą się na rozlewiskach rzeki Biebrzy. Samce przybierają wówczas szatę godową z wielokolorowych piór tak, iż jeszcze nigdy dotąd nie udało się spotkać dwóch identycznie upierzonych osobników! Gromadzą się na wysepkach pośród bagien i odbywają zaciekłe walki godowe (stąd nazwa ptaka). W czerwcu udają się w dalszą wędrówkę na północ, w strefę tundry, gdzie wyprowadzają lęgi. Potomstwem opiekuje się wyłącznie samica.',
        src: require('../assets/images/animals/batalion.png'),
        alt:'Batalion o bogatym pióropuszu wokół szyi, z czerwonym dziobem zwrócony w lewą stronę.',
        available:false
    },
    
    {
        id:'r1',
        name:'wawrzynek wilczełyko',
        description:'<b>Wawrzynek wilczełyko</b><br/><br/>Łacińska nazwa wawrzynka <i>Daphne mezereum</i> nawiązuje do greckiej nimfy Dafne przemienionej w drzewo laurowe <i>Daphne</i>, którego liście są podobne do liści wawrzynka.<br/><br/>Wawrzynek jest krzewem o zdrewniałych łodygach dorastających do 2 m wysokości. Kwitnie już nawet w lutym, przed wypuszczeniem liści. Różowe, pachnące kwiaty zapylają <u>owady</u> o długich narządach gębowych. Czerwone, kuliste owoce są rozsiewane przez ptaki.<br/><br/>Owoce zjada 10 gatunków ptaków, m.in. <u>dzwoniec</u>, <u>jarząbek</u>, <u>grubodziób</u>, <u>piegża</u>. Bogate w nektar kwiaty dostarczają wczesną wiosną pokarmu <u>pszczołom</u> i motylom dziennym. Dla ludzi owoce i liście są trujące.',
        src: require('../assets/images/animals/wawrzynek_wilczelyko.png'),
        alt:'Wawrzynek wilczełyko o różowych kwiatach owiniętych wzdłuż prostej łodyżki.',
        available:true
    },
    {
        id:'r2',
        name:'sosna zwyczajna',
        description:'<b>Sosna zwyczajna</b><br/><br/>Sosna zwyczajna - to gatunek żywicorodny. W Polsce jest najpospolitszą z krajowych sosen, tworzy zespoły borów sosnowych (sośniny), występuje również często w borach mieszanych. W lasach mieszanych zajmuje bardziej nasłonecznione i suchsze zbocza. Jej naturalny zasięg występowania nie obejmuje jedynie Bieszczadów. Rosnące samotnie sosny mają rozłożyste, dosyć gęste korony. Rosnąc w zwarciu w skupiskach leśnych drzewa tracą dolne gałęzie i wykształcają prosty pień o wysokiej koronie. Sosny występujące na terenach nizinnych mają grubsze konary i korony lekko zaokrąglone, natomiast występujące na obszarach wyżynnych mają konary cieńsze i pokrój bardziej stożkowaty.',
        src: require('../assets/images/animals/sosna_pospolita.png'),
        alt:'Sosna o pozbawionej liści podstawie i rozłożystych gałęziach u szczytu.',
        available:true
    },
    {
        id:'r3',
        name:'okrzyn jeleni',
        description:'<b>Okrzyn jeleni</b><br/><br/>Okrzyn jeleni to okazała roślina z rodziny selerowatych, której rozmiary dochodzą w umiarkowanych warunkach do 1,30 wysokości. Posiada grubą bruzdkowaną łodygę pokrytą włoskami. Liście tej rośliny są trójkątne w zarysie i pierzaste. Dolne liście są bardzo duże. Kwiatostan w formie baldachu również jest okazały i może składać się z 20-40 promieni a jego średnica dochodzić może do 20 cm. Kwiaty najczęściej białe. Roślina kwitnie na przełomie lipca i sierpnia dopiero po osiągnięciu min. 5 lat.<br/><br/>Roślina ta z uwagi na jej rzadkie występowanie a w Polsce jedynie na Babiej Górze i na jednym stanowisku w Beskidzie Śląskim została uznana za symbol BgPN. Jako miejsca swojego występowania upodobała sobie strome zbocza w strefie górnej granicy lasu do wysokości około 1350 m n.p.m.',
        src: require('../assets/images/animals/okrzyn_jeleni.png'),
        alt:'Zielone cienkie łodygi zakończone białymi kwiatami, u podnóży których wyrastają małe zielone listki.',
        available:true
    },
    {
        id:'r4',
        name:'obuwik pospolity',
        description:'<b>Obuwik pospolity (Cypripedium calceolus)</b><br/><br/>W Roztoczańskim Parku Narodowym można spotkać jednego z najbardziej spektakularnych polskich storczyków? Obuwik pospolity, którego liczebność na Roztoczu jest jedną z najwyższych w całym kraju, swoją nazwę zawdzięcza rozdętej w specyficzny sposób warżce, która przypomina bucik. To swego rodzaju pułapka, gdyż zwabione barwą i zapachem kwiatów owady wpadają do rozdętej warżki tworzącej wspomniany bucik i aby się wydostać zabierają na swym ciele pyłek lub  ocierając się zostawiają pyłek z innego kwiatu.<br/>Jego wygląd jest na tyle egzotyczny, że stał się pożądanym kwiatem ogrodowym. Między innymi właśnie nielegalne pozyskanie z naturalnych stanowisk jest jednym z największych zagrożeń dla tej rzadkiej rośliny.',
        src: require('../assets/images/animals/obuwik_pospolity.png'),
        alt:'Obuwik pospolity z czerwonymi liśćmi, z których wyrasta żółty pąk, usytuowane na zielonej łodydze.',
        available:true
    },
    {
        id:'r5',
        name:'goryczka tojeściowa',
        description:'<b>Goryczka tojeściowa</b><br/><br/>Roślina górska. Rośnie na obrzeżach lasów, na polanach śródleśnych, halach górskich, na piargach, brzegach potoków, w kosówce, pomiędzy skałami. Jest częstym składnikiem ziołorośli górskich.<br/>Jest to roślina trująca. Ziele zawiera trującą gencjopikrynę, korzenie trujące alkaloidy – gencjaninę i gencjanidynę. U ludzi powodują one przekrwienie błony śluzowej i wzmożenie kurczliwości żołądka, nudności i wymioty. Zwierzęta nie jedzą tej rośliny, ze względu na jej gorzki smak.',
        src: require('../assets/images/animals/goryczka_tojesciowa.png'),
        alt:'Fioletowy kwiat z grubą zieloną łodygą i zielonymi, ostro zakończonymi, liśćmi.',
        available:true
    },
    {
        id:'r6',
        name:'dzwonek karkonoski',
        description:'<b>Dzwonek karkonoski</b><br/><br/>Dzwonek karkonoski jest ozdobą górskich łąk w reglu górnym, traworośli w piętrze subalpejskim oraz wysokogórskich, alpejskich muraw. Posiada duże, ciemnoniebieskie kwiaty. Jest to gatunek endemiczny, co oznacza, że w swym występowaniu ograniczony jest jedynie do Karkonoszy. Dzwonek karkonoski widnieje na wspólnym logo Karkonoskiego Parku Narodowego i Krkonošský Národní Park (KRNAP).',
        src: require('../assets/images/animals/dzwonek_karkonoski.png'),
        alt:'Dzwonek karkonoski o fioletowych kwiatach, usytuowany na cienkiej łodydze.',
        available:true
    },
]

export default animals;