/**
 * Ekran prezentuje mapę z zaznaczonymi Parkami Narodowymi. 
 * Za pomocą przycisku "Lista parków" jest możliwość przełączenia widoku mapy na listę.
 */
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import map from '../assets/images/defaults/map.png';
import Container from "../components/Container";
import CustomAlert from "../components/CustomAlert";
import Info from "../components/Info";
import ListItem from "../components/ListItem";
import MenuHeader from "../components/MenuHeader";
import ParkInfoAlert from "../components/ParkInfoAlert";
import ParkLogoMarker from "../components/ParkLogoMarker";
import Sizes from '../constans/sizes';
import ParksData from '../data/parks';
import { setShowAlert } from "../store/redux/endgame";
import { setInfo } from "../store/redux/info";
import styles from '../styles/screens/Parks.module.css';
import { ParkProps } from "../types";

const Parks = (props:any) => {
    /**
     * Funkcja zwraca zapamiętaną informacje o pokazywaniu okna informacyjnego
     */
    const info = useSelector((state:any) => state.info.value);
    /**
     * Funkcja zwraca zakończone parki
     */
    const endgame = useSelector((state:any) => state.endgame.values);
    /**
     * Funkcja zwraca status zakończenia gry
     */
    const endgamealert = useSelector((state:any) => state.endgame.showAlert);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych, zmienych wykorzystywanych w ekranie Parków
     */
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const [mapVisible, setMapVisible] = useState(true);
    const [mapContainerWidth, setMapContainerWidth] = useState(0.0);
    const [mapContainerHeight, setMapContainerHeight] = useState(0.0);
    const [mapOffsetLeft, setMapOffsetLeft] = useState(0.0);
    const [mapOffsetTop, setMapOffsetTop] = useState(0.0);
    const [showGotoAlert, setShowGotoAlert] = useState(false);
    const [parkName, setParkName] = useState('');
    const [parkLogo, setParkLogo] = useState('');
    const [idpark, setIDpark] = useState(0);   
    const [showEndAlert, setShowEndAlert] = useState(false);
    const parksSortList = useMemo(()=>{
        const _parksdata = [...ParksData];
        const tmp = _parksdata.sort((a:ParkProps,b:ParkProps) => a.name.replace(/„/g,'').replace(/”/g,'').toLowerCase()>b.name.replace(/„/g,'').replace(/”/g,'').toLowerCase() ? 1 : -1);
        const localtmp = tmp.sort((a:ParkProps,b:ParkProps) => a.name.replace(/„/g,'').replace(/”/g,'').localeCompare(b.name.replace(/„/g,'').replace(/”/g,'')));
        return localtmp;
    },[]);
    /**
     * Funkcja wyznaczająca szerokość i wysokość mapy Parków Narodowych
     */
    const onLoadMapContainer = () => {
        const _map:HTMLImageElement = document.getElementById('mapElement') as HTMLImageElement;
        if(_map!==undefined && _map!==null){
            _map.style.width = "100%";
            _map.style.height = "100%";
            if(_map.offsetWidth>=_map.offsetHeight){
                setMapContainerWidth(_map.offsetHeight);
                setMapContainerHeight(_map.offsetHeight);
                _map.style.width = _map.offsetHeight.toString()+"px";
                _map.style.height = _map.offsetHeight.toString()+"px";
            }
            else{
                setMapContainerWidth(_map.offsetWidth);
                setMapContainerHeight(_map.offsetWidth);
                _map.style.width = _map.offsetWidth.toString()+"px";
                _map.style.height = _map.offsetWidth.toString()+"px";
            }
            setMapOffsetLeft(_map.offsetLeft);
            setMapOffsetTop(_map.offsetTop);
            _map.style.visibility = 'visible';
        }
    }
    /**
     * Ustawienie markerów na mapie
     */
    const markers:any = ParksData.map((park:ParkProps,index) => {
        const _top = ((mapContainerHeight*park.y)/Sizes.mapHeight) + mapOffsetTop;
        const _left = ((mapContainerWidth*park.x)/Sizes.mapWidth) + mapOffsetLeft;
        return (
                <ParkLogoMarker key={park.idpark} idpark={park.idpark} maxpoints={park.maxpoints} top={_top} left={_left} setPark={()=>setPark(park.idpark)} 
                                size={park.size} logo={park.logo} name={park.name}/>
            )
    });
    /**
     * Ustawienie listy parków
     */
     const lists = parksSortList.map((park:ParkProps,index) => {
        return (
                <ListItem idx={index+1} key={park.idpark} idpark={park.idpark} name={park.name} maxpoints={park.maxpoints} navigation={props.navigation} setPark={()=>setPark(park.idpark)}/>
            );
    });
    /**
     * Funkcja ustawia paramerty dla wybranego parku
     * @param {number} idpark ID parku
     */
     const setPark = (idpark:number) => {
        const park:any = ParksData.find(item=>item.idpark===idpark);
        setParkName(park.name);
        setParkLogo(park.logo);
        setShowGotoAlert(true);
        setIDpark(idpark);
    }
    /**
     * Funkcja umożliwiająca przejście do wybranego parku
     */
    const gotoPark = () => {
        setShowGotoAlert(false);
        navigate('/park',{
            state: {
                idpark: idpark,
            }
        });
    }
    /**
     * Funkcja przypinająca event wywołujący się po każdej zmianie rozmiaru okna
     */
    useEffect(() => {
        window.addEventListener('resize',onLoadMapContainer);
        return () => {
            window.removeEventListener('resize',onLoadMapContainer);
        };
    }, []);
    /**
     * Funkcja zarządzająca alertem końca gry
     */
    useEffect(()=>{
        if(endgame!==undefined){
            if(markers.length === endgame.length && endgamealert===1){
                 setShowEndAlert(true);
                 dispatch(setShowAlert({showAlert:0}));
            }
        }
    /*eslint-disable */
    },[markers.length,endgame,endgamealert]);
    /*eslint-disable */
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja wychodzi z widoku listy parków
     * @param {KeyboardEvent} event 
     */
    const showMap = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Escape' && !mapVisible) setMapVisible(true);
    }
    /**
     * Funkcja zamykająca okno informacyjne
     */
    const closeInfo = () => {
        dispatch(setInfo({value:0}));
    }
    return ( 
        <Container onKeyDown={showMap} changeSize={onLoadMapContainer}>
            {info===1 && <Info show={info===1} close={closeInfo}/>}
             {showEndAlert && <CustomAlert show={showEndAlert} cancel={()=>setShowEndAlert(false)} 
                                title='Gratulacje' message='Wszystkie parki zostały odwiedzone, gra została ukończona.' buttonCancel='Zamknij'/>}
            {showGotoAlert && <ParkInfoAlert show={showGotoAlert} 
            cancel={()=>{setShowGotoAlert(false)}} confirm={gotoPark} 
            name={parkName} logo={parkLogo} buttonCancel='Zamknij' buttonConfirm='Przejdź'/>}
            <MenuHeader hamburger={true}/>
            <h2 className="sronly">{mapVisible?'MAPA PARKÓW NARODOWYCH':'LISTA PARKÓW NARODOWYCH'}</h2>
            <div className={styles.container}>
                {mapVisible?<img id="mapElement" className={styles.map} src={map} alt="Mapa Parków Narodowych" onLoad={onLoadMapContainer} style={{opacity:contrast==='on'?1:1}}></img>
                    :<ul className={styles.parklist}>
                        {
                            lists
                        }
                    </ul>
                }
                <ul>
                {
                    mapVisible?markers:null
                }
                </ul>
            </div>
            <h2 className={styles.heading}>
                <button className={contrast==='on'?styles.buttonContrast:styles.button} style={{fontSize:getFontSize()+6}} onClick={()=>setMapVisible(!mapVisible)}>
                    {mapVisible?'LISTA PARKÓW':'MAPA PARKÓW'}
                </button>
            </h2>
        </Container>
    )
}

export default Parks;
