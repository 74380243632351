/**
 * Obiekt przechowujący informację o dźwięku w aplikacji (włączony|wyłączony)
 */
import { createSlice } from "@reduxjs/toolkit";

const soundSlice = createSlice({
    name: 'sound',
    initialState: { 
        value: 'on'
    },
    reducers: {
        setSound: (state, action) => {
            state.value = action.payload.value;
        }
    }
});

export const setSound = soundSlice.actions.setSound;
export default soundSlice.reducer;
