/**
 * Komponent wyświetlający czas
 */
import { useEffect, useState } from 'react';
import styles from '../styles/components/Timer.module.css';
import { TimerProps } from '../types';
import Sizes from '../constans/sizes';
import { useSelector } from 'react-redux';

var _timer:any;
/**
 * Funkcja czyszcząca timer
 */
export const clearTimer = () => {
    if(_timer!==undefined){
        clearInterval(_timer);
        _timer = undefined;
    }
}
/**
 * Funkcja tworząca timer
 * @param {numebr} _time czas podawany w sekundach
 * @param {Function} _setTime funkcja ustawiająca aktualny czas
 * @param {Function} _result funkcja zwracana po upływie czasu
 */
const createTimer = (_time:number,_setTime:Function,_result:Function) => {
    if(_time!==undefined && _timer===undefined){
        let _t = _time;
        _setTime(_t);
        _timer = setInterval(()=>{
            _t--;
            _setTime(_t);
            if(_t===0){
                clearInterval(_timer);
                _timer = undefined;
                _result();
            }
        },1000);
    }
}

const Timer = (props:TimerProps) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [timer, setTimer] = useState(0);
    /**
     * Funkcja wywoływana w przypadku zmiany props.start
     */
    /*eslint-disable */
    useEffect(()=>{
        clearTimer();
        if(props.start===true) createTimer(props.time,setTimer,props.result);
    },[props.start]);
    /*eslint-disable */
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <p className={styles.timer} style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#f08733'}}>{timer} sekund</p>
    );
}

export default Timer;