/**
 * Ekran Przewodnika
 */
import Container from "../components/Container";
import LeftTabBar from "../components/LeftTabBar";
import styles from '../styles/screens/Guide.module.css';
import { useLocation } from "react-router-dom";
import Sizes from '../constans/sizes';
import { useSelector } from "react-redux";
import HeaderWithCloseButton from "../components/HeaderWithCloseButton";

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

const Animal = (props:any) => {
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
    * Funkcja zwraca zapamiętana czcionke
    */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Ustawienie stanów początkowych, zmienych wykorzystywanych w ekranie Parków
     */
    const params:any = useLocation();
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            <LeftTabBar idpark={params.state.idpark} logo={params.state.logo}/>
            <div className={styles.guideContainer}>  
                <HeaderWithCloseButton name={params.state.name}/>
                <div className={styles.guideContent}>
                    <div className={styles.guideContentContainer}>
                        <div className={styles.imgContainer}>
                            <img className={contrast==='on'?styles.imgContrast:styles.img} src={params.state.src} alt={params.state.alt}/>
                        </div>
                        <div className={styles.descriptionContent}>
                            <div className={styles.descriptionContainer}>
                                <div className={styles.description} style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : params.state.description!==undefined?parser(params.state.description):'' }}/>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </Container>
    )
}

export default Animal;