/**
 * Komponent wyświetlający śmieć
 */
import { DragEvent, TouchEvent } from 'react';
import { TrashMarkerProps, TrashPoistionProps } from '../types';
import styles from '../styles/components/TrashMarker.module.css';

var clone:HTMLImageElement;

const TrashMarker = (props:TrashMarkerProps) => {
    /**
     * Funkcja rozpoczynająca przesuwanie elementu
     * @param {DragEvent} event 
     */
    const handleDragStart = (event:DragEvent<HTMLImageElement>) => {
        const element:HTMLImageElement = event?.target as HTMLImageElement;
        if(element !== null){
            element.style.opacity = '0';
            
            if(navigator.userAgent.indexOf("Firefox")){
                var ctx = document.createElement("canvas").getContext("2d") as any;
                ctx.canvas.width = element.width*2;
                ctx.canvas.height = element.height*2;
                ctx.drawImage(element, 0, 0, element.width*2, element.height*2);
                event.dataTransfer.setDragImage(ctx.canvas, element.width/2, element.height/2);
            }
            
            if(props.getDraggabledElement !== undefined) props.getDraggabledElement(element);
        }  
    }
    /**
     * Funkcja rozpoczynająca przesuwanie elementu
     * @param {TouchEvent} event 
     */
    const handleTouchStart = (event:TouchEvent<HTMLImageElement>) => {
        const element:HTMLImageElement = event?.target as HTMLImageElement;
        if(element !== null){
            clone = element.cloneNode(true) as HTMLImageElement;
            element.style.opacity = '0';
            clone.style.position = 'absolute';
            document.getElementById('backgroundContainer')!.appendChild(clone); 
            var touchLocation = event.targetTouches[0];
            clone.style.left = touchLocation.pageX - element.offsetWidth/2 + 'px';
            clone.style.top = touchLocation.pageY - element.offsetHeight/2 + 'px';
            clone.style.width = element.offsetWidth + "px";
            if(props.getDraggabledElement !== undefined) props.getDraggabledElement(element);
        }
        document.getElementById('backgroundContainer')!.style.overflow = "hidden";    
    }
    /**
     * Funkcja przesuwająca element
     * @param {TouchEvent} event 
     */
     const handleTouchMove = (event:TouchEvent<HTMLDivElement>) => {
        var touchLocation = event.targetTouches[0];
        clone.style.left = touchLocation.pageX - clone.offsetWidth/2  + 'px';
        clone.style.top = touchLocation.pageY - clone.offsetHeight/2 + 'px';
    }
    /**
     * Funkcja wywoływana po zakończeniu przsuwania elementu
     * @param {DragEvent} event 
     */
    const handleDragEnd = (event:DragEvent<HTMLDivElement>) => {
        const element:HTMLDivElement = event?.target as HTMLDivElement;
        if(element!==null) element.style.opacity = '1';
    }
    /**
     * Funkcja wywoływana po zakończeniu przsuwania elementu
     * @param {TouchEvent} event 
     */
     const handleTouchEnd = (event:TouchEvent<HTMLDivElement>) => {
        const element:HTMLImageElement = event?.target as HTMLImageElement;
        const positiontrash = props.trashPosition.find((item:TrashPoistionProps)=>item.type===props.type);
        const clonX = clone.offsetLeft + (clone.offsetWidth/2);
        const clonY = clone.offsetTop + (clone.offsetHeight/2);
        if(positiontrash!==undefined && clonX!==undefined && clonY!==undefined && clonX>=positiontrash.x && clonX<=positiontrash.width+positiontrash.x
            && clonY>positiontrash.y && clonY<=positiontrash.height+positiontrash.y){
            element.style.visibility = 'hidden';
            props.dropedTrash();
        }
        else element.style.opacity = '1';
        document.getElementById('backgroundContainer')!.removeChild(clone); 
        document.getElementById('backgroundContainer')!.style.overflow = "visible"; 
    }
    return (
        <div className={styles.container}>
            <img className={styles.img} src={props.src} alt={props.alt} draggable="true" data-type={props.type}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}/>
        </div>
    )
}

export default TrashMarker;