import { useEffect, useState } from "react";
import styles from '../styles/components/Info.module.css';
import { useDispatch, useSelector } from "react-redux";
import Sizes from '../constans/sizes';
import close from '../assets/icons/close_green.png';
import { KeyboardEvent } from "react";
import img_fe from "../assets/logo/fe_is.jpeg";
import img_ppn from "../assets/logo/ppn.png";
import img_fs from "../assets/logo/ue_fs.png";
import img_me from "../assets/logo/mkis.png";
import Statute from "./Statute";
import { setRegulamin } from "../store/redux/regulamin";
import AlertNoTitle from "./AlertNoTitle";
/**
 * Komponent okna informacyjnego pojawia się przy pierwszym uruchomieniu
 * @param {any} props
 * @returns zwraca okno informacyjne
 */

const description = `<p class='infomargin'>Grę wydano w ramach Projektu POIS. 02.04.00-00-0001/15 „Promocja Parków Narodowych jako marki” współfinansowanego ze środków Unii Europejskiej w ramach Programu Operacyjnego Infrastruktura i Środowisko 2014-2020.</p>`;

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

const Info = (props:any) => {
    const dispatch = useDispatch();
    /**
     * Funkcja zwraca zapamiętaną informacje o zaakceptowaniu regulaminu
     */
     const regulamin = useSelector((state:any) => state.regulamin.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    const [showstatute,setShowStatute] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    /**
     * Funkcja ustawia focus na guziku zatwierdzającym lub anulujący
     */
    useEffect(()=>{
        const bt_confirm:HTMLButtonElement = document.getElementById('confirm') as HTMLButtonElement;
        if(bt_confirm!==undefined && bt_confirm!==null) bt_confirm.focus();
        else{
            const bt_cancel:HTMLButtonElement = document.getElementById('cancel') as HTMLButtonElement;
            if(bt_cancel!==undefined && bt_cancel!==null) bt_cancel.focus();
        }
    },[]);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja zamykająca okno
     */
    const onClose = () => {
        if(regulamin===1) props.close();
        else setShowAlert(true);
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            if(regulamin===1) props.close();
            else setShowAlert(true);
        }
    }
    /**
     * Funkcja zmieniająca ustawienie akceptacji regulaminu
     */
    const onChangeStatute = (_value:number) => {
        dispatch(setRegulamin({value:_value}));
    }
    return (
        <div>
            {showstatute && <Statute show={showstatute} close={()=>setShowStatute(false)}/>}
            {showAlert && <AlertNoTitle show={showAlert} cancel={()=>{setShowAlert(false)}} borderColor='#000000'
            message='Nie zaakceptowano regulaminu.' buttonCancel='OK'/>}
            <div className={styles.containerModal}>
                <div className={styles.modal}
                    role='dialog' aria-labelledby="dialog_label" aria-modal="true" aria-describedby="dialog_description" tabIndex={0}>
                    <div id="dialog_description" className="sronly">
                        Okno wyświetlające informacje o aplikacji
                    </div>
                    <div className={styles.closeContainer}>
                        <button className={styles.close} onClick={onClose} onKeyDown={onKeyDown}> 
                            <h2 className={styles.heading}>
                                <img className={styles.closeImage} src={close} alt="ZAMKNIJ OKNO"></img>
                            </h2>
                        </button>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.mksContainer}>
                            <img className={styles.partner} src={img_me} alt="Minister Klimatu i Środowiska"/>
                        </div>
                        <div className={styles.description} style={{fontSize:getFontSize()+4}} dangerouslySetInnerHTML = {{ __html : parser(description) }}/>
                        <div className={styles.partners}>
                            <img className={styles.partner} src={img_fe} alt="Fundusze Europejskie"/>
                            <img className={styles.partnerppn} src={img_ppn} alt="Polskie Parki Narodowe"/>
                            <img className={styles.partner} src={img_fs} alt="Unia Europejska"/>
                        </div>
                    </div>
                    <div className={styles.statute}>
                        <label className={styles.statutelabel} style={{fontSize:getFontSize()}}><input type='checkbox' defaultChecked={regulamin===1} onChange={(event)=>onChangeStatute(regulamin===1?0:1)}/> Akceptuję<button onClick={()=>setShowStatute(true)}><ins>regulamin</ins></button></label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info;