/**
 * Użyte kolory w aplikacji
 */
const colors = {
    primary:'#3d836d',
    background: '#ffffff',
    linerBackground:['#ffffff','#e1e1e1'],
    grey:'#e6e6e6',
    orange:'#f08733',
    headerCuriosities:'#ffde53',
    headerParkStatute:'#2c7187',
    notPassed:'#ffffff'//'#c0070d'
}

export default colors;