/**
 * Przypisanie pytań do lokacji
 */
 const locationschallenges = [
    /** 1. Słowiński Park Narodowy */
    {
        idlocation:1,
        level:[
            {questions:[
                {
                    idquestion:1,
                    type:'closed'
                },
                {
                    idquestion:461,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:701,
                    type:'closed'
                },
                {
                    idquestion:461,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:706,
                    type:'closed'
                },
                {
                    idquestion:461,
                    type:'puzzle'
                },
            ]},
        ]
        
    },
    /* QUEST 1 */
    {
        idlocation:2,
        level:[{
            questions:[
                {
                    idquestion:116,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:3,
        level:[
            {questions:[
                {
                    idquestion:2,
                    type:'closed'
                },
                {
                    idquestion:462,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:702,
                    type:'closed'
                },
                {
                    idquestion:462,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:707,
                    type:'closed'
                },
                {
                    idquestion:462,
                    type:'find'
                },
            ]},
        ]
    },
    {
        idlocation:4,
        level:[
            {questions:[
                {
                    idquestion:3,
                    type:'closed'
                },
                {
                    idquestion:463,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:703,
                    type:'closed'
                },
                {
                    idquestion:463,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:708,
                    type:'closed'
                },
                {
                    idquestion:463,
                    type:'memo'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:5,
        level:[{
            questions:[
                {
                    idquestion:121,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:6,
        level:[
            {questions:[
                {
                    idquestion:4,
                    type:'closed'
                },
                {
                    idquestion:464,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:704,
                    type:'closed'
                },
                {
                    idquestion:464,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:709,
                    type:'closed'
                },
                {
                    idquestion:464,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:7,
        level:[{
            questions:[
                {
                    idquestion:126,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:8,
        level:[
            {questions:[
                {
                    idquestion:5,
                    type:'open'
                },
                {
                    idquestion:465,
                    type:'recordsound'
                }
            ]},
            {questions:[
                {
                    idquestion:705,
                    type:'closed'
                },
                {
                    idquestion:465,
                    type:'recordsound'
                }
            ]},
            {questions:[
                {
                    idquestion:710,
                    type:'closed'
                },
                {
                    idquestion:465,
                    type:'recordsound'
                }
            ]},
        ]
    },

    /** 2. Wigierski Park Narodowy */
    {
        idlocation:9,
        level:[
            {questions:[
                {
                    idquestion:6,
                    type:'closed'
                },
                {
                    idquestion:466,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:711,
                    type:'closed'
                },
                {
                    idquestion:466,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:716,
                    type:'closed'
                },
                {
                    idquestion:466,
                    type:'find'
                },
            ]}
        ]
    },
    /* QUEST 1 */
    {
        idlocation:10,
        level:[{
            questions:[
                {
                    idquestion:131,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:11,
        level:[
            {questions:[
                {
                    idquestion:7,
                    type:'closed'
                },
                {
                    idquestion:467,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:712,
                    type:'closed'
                },
                {
                    idquestion:467,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:717,
                    type:'closed'
                },
                {
                    idquestion:467,
                    type:'recordsound'
                },
            ]},
        ]
    },
    {
        idlocation:12,
        level:[
            {questions:[
                {
                    idquestion:8,
                    type:'closed'
                },
                {
                    idquestion:468,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:713,
                    type:'closed'
                },
                {
                    idquestion:468,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:718,
                    type:'closed'
                },
                {
                    idquestion:468,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:13,
        level:[{
            questions:[
                {
                    idquestion:136,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:14,
        level:[
            {questions:[
                {
                    idquestion:9,
                    type:'closed'
                },
                {
                    idquestion:469,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:714,
                    type:'closed'
                },
                {
                    idquestion:469,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:719,
                    type:'closed'
                },
                {
                    idquestion:469,
                    type:'puzzle'
                },
            ]}
        ]
    },
    /* QUEST 3 */
    {
        idlocation:15,
        level:[{
            questions:[
                {
                    idquestion:141,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:16,
        level:[
            {questions:[
                {
                    idquestion:10,
                    type:'open'
                },
                {
                    idquestion:470,
                    type:'find'
                }
            ]},
            {questions:[
                {
                    idquestion:715,
                    type:'closed'
                },
                {
                    idquestion:470,
                    type:'find'
                }
            ]},
            {questions:[
                {
                    idquestion:720,
                    type:'closed'
                },
                {
                    idquestion:470,
                    type:'find'
                }
            ]},
        ]
    },
    /** 3. Woliński Park Narodowy */
    {
        idlocation:17,
        level:[
            {questions:[
                {
                    idquestion:11,
                    type:'open'
                },
                {
                    idquestion:471,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:721,
                    type:'closed'
                },
                {
                    idquestion:471,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:726,
                    type:'closed'
                },
                {
                    idquestion:471,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:18,
        level:[{
            questions:[
                {
                    idquestion:146,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:19,
        level:[
            {questions:[
                {
                    idquestion:13,
                    type:'closed'
                },
                {
                    idquestion:472,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:722,
                    type:'closed'
                },
                {
                    idquestion:472,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:727,
                    type:'closed'
                },
                {
                    idquestion:472,
                    type:'puzzle'
                },
            ]},
        ]
    },
    {
        idlocation:20,
        level:[
            {questions:[
                {
                    idquestion:12,
                    type:'closed'
                },
                {
                    idquestion:473,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:723,
                    type:'closed'
                },
                {
                    idquestion:473,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:728,
                    type:'closed'
                },
                {
                    idquestion:473,
                    type:'find'
                },
            ]}
        ]
    },
    /* QUEST 2 */
    {
        idlocation:21,
        level:[{
            questions:[
                {
                    idquestion:151,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:22,
        level:[
            {questions:[
                {
                    idquestion:14,
                    type:'open'
                },
                {
                    idquestion:474,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:724,
                    type:'closed'
                },
                {
                    idquestion:474,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:729,
                    type:'closed'
                },
                {
                    idquestion:474,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:23,
        level:[{
            questions:[
                {
                    idquestion:156,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:24,
        level:[
            {questions:[
                {
                    idquestion:15,
                    type:'open'
                },
                {
                    idquestion:475,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:725,
                    type:'closed'
                },
                {
                    idquestion:475,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:730,
                    type:'closed'
                },
                {
                    idquestion:475,
                    type:'find'
                },
            ]},
        ]
    },
    /** 4. Park Narodowy „Bory Tucholskie” */
    {
        idlocation:25,
        level:[
            {questions:[
                {
                    idquestion:20,
                    type:'open'
                },
                {
                    idquestion:480,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:731,
                    type:'closed'
                },
                {
                    idquestion:480,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:736,
                    type:'closed'
                },
                {
                    idquestion:480,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:26,
        level:[{
            questions:[
                {
                    idquestion:161,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:27,
        level:[
            {questions:[
                {
                    idquestion:17,
                    type:'closed'
                },
                {
                    idquestion:477,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:732,
                    type:'closed'
                },
                {
                    idquestion:477,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:737,
                    type:'closed'
                },
                {
                    idquestion:477,
                    type:'find'
                },
            ]},
        ]
    },
    {
        idlocation:28,
        level:[
            {questions:[
                {
                    idquestion:18,
                    type:'closed'
                },
                {
                    idquestion:478,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:733,
                    type:'closed'
                },
                {
                    idquestion:478,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:738,
                    type:'closed'
                },
                {
                    idquestion:478,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:29,
        level:[{
            questions:[
                {
                    idquestion:166,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:30,
        level:[
            {questions:[
                {
                    idquestion:19,
                    type:'closed'
                },
                {
                    idquestion:479,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:734,
                    type:'closed'
                },
                {
                    idquestion:479,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:739,
                    type:'closed'
                },
                {
                    idquestion:479,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:31,
        level:[{
            questions:[
                {
                    idquestion:171,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:32,
        level:[
            {questions:[
                {
                    idquestion:16,
                    type:'closed'
                },
                {
                    idquestion:476,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:735,
                    type:'closed'
                },
                {
                    idquestion:476,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:740,
                    type:'closed'
                },
                {
                    idquestion:476,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /** 5. Biebrzański Park Narodowy */
    {
        idlocation:33,
        level:[
            {questions:[
                {
                    idquestion:21,
                    type:'closed'
                },
                {
                    idquestion:481,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:741,
                    type:'closed'
                },
                {
                    idquestion:481,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:746,
                    type:'closed'
                },
                {
                    idquestion:481,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:34,
        level:[{
            questions:[
                {
                    idquestion:176,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:35,
        level:[
            {questions:[
                {
                    idquestion:22,
                    type:'closed'
                },
                {
                    idquestion:482,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:742,
                    type:'closed'
                },
                {
                    idquestion:482,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:747,
                    type:'closed'
                },
                {
                    idquestion:482,
                    type:'recordsound'
                },
            ]},
        ]
    },
    {
        idlocation:36,
        level:[
            {questions:[
                {
                    idquestion:23,
                    type:'sort'
                },
                {
                    idquestion:483,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:743,
                    type:'closed'
                },
                {
                    idquestion:483,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:748,
                    type:'closed'
                },
                {
                    idquestion:483,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:37,
        level:[{
            questions:[
                {
                    idquestion:181,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:38,
        level:[
            {questions:[
                {
                    idquestion:583,
                    type:'closed'
                },
                {
                    idquestion:484,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:744,
                    type:'closed'
                },
                {
                    idquestion:484,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:749,
                    type:'closed'
                },
                {
                    idquestion:484,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:39,
        level:[{
            questions:[
                {
                    idquestion:186,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:40,
        level:[
            {questions:[
                {
                    idquestion:25,
                    type:'open'
                },
                {
                    idquestion:485,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:745,
                    type:'closed'
                },
                {
                    idquestion:485,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:750,
                    type:'closed'
                },
                {
                    idquestion:485,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /** 6. Białowieski Park Narodowy */
    {
        idlocation:43,
        level:[
            {questions:[
                {
                    idquestion:584,
                    type:'closed'
                },
                {
                    idquestion:489,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:753,
                    type:'closed'
                },
                {
                    idquestion:489,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:757,
                    type:'closed'
                },
                {
                    idquestion:489,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:47,
        level:[{
            questions:[
                {
                    idquestion:201,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:42,
        level:[
            {questions:[
                {
                    idquestion:27,
                    type:'closed'
                },
                {
                    idquestion:487,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:755,
                    type:'closed'
                },
                {
                    idquestion:487,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:758,
                    type:'closed'
                },
                {
                    idquestion:487,
                    type:'recordsound'
                },
            ]},
        ]
    },
    {
        idlocation:44,
        level:[
            {questions:[
                {
                    idquestion:28,
                    type:'closed'
                },
                {
                    idquestion:488,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:752,
                    type:'closed'
                },
                {
                    idquestion:488,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:756,
                    type:'closed'
                },
                {
                    idquestion:488,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:46,
        level:[{
            questions:[
                {
                    idquestion:196,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:48,
        level:[
            {questions:[
                {
                    idquestion:29,
                    type:'closed'
                },
                {
                    idquestion:486,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:754,
                    type:'closed'
                },
                {
                    idquestion:486,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:759,
                    type:'closed'
                },
                {
                    idquestion:486,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:41,
        level:[{
            questions:[
                {
                    idquestion:191,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:45,
        level:[
            {questions:[
                {
                    idquestion:30,
                    type:'open'
                },
                {
                    idquestion:490,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:751,
                    type:'closed'
                },
                {
                    idquestion:490,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:760,
                    type:'closed'
                },
                {
                    idquestion:490,
                    type:'find'
                },
            ]},
        ]
    },
    /** 7. Drawieński Park Narodowy */
    {
        idlocation:49,
        level:[
            {questions:[
                {
                    idquestion:31,
                    type:'closed'
                },
                {
                    idquestion:491,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:761,
                    type:'closed'
                },
                {
                    idquestion:491,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:766,
                    type:'closed'
                },
                {
                    idquestion:491,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:50,
        level:[{
            questions:[
                {
                    idquestion:206,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:51,
        level:[
            {questions:[
                {
                    idquestion:32,
                    type:'closed'
                },
                {
                    idquestion:492,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:762,
                    type:'closed'
                },
                {
                    idquestion:492,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:767,
                    type:'closed'
                },
                {
                    idquestion:492,
                    type:'recordsound'
                },
            ]},
        ]
    },
    {
        idlocation:52,
        level:[
            {questions:[
                {
                    idquestion:33,
                    type:'closed'
                },
                {
                    idquestion:493,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:763,
                    type:'closed'
                },
                {
                    idquestion:493,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:768,
                    type:'closed'
                },
                {
                    idquestion:493,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:53,
        level:[{
            questions:[
                {
                    idquestion:211,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:54,
        level:[
            {questions:[
                {
                    idquestion:34,
                    type:'closed'
                },
                {
                    idquestion:494,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:764,
                    type:'closed'
                },
                {
                    idquestion:494,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:769,
                    type:'closed'
                },
                {
                    idquestion:494,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:55,
        level:[{
            questions:[
                {
                    idquestion:216,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:56,
        level:[
            {questions:[
                {
                    idquestion:35,
                    type:'open'
                },
                {
                    idquestion:495,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:765,
                    type:'closed'
                },
                {
                    idquestion:495,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:770,
                    type:'closed'
                },
                {
                    idquestion:495,
                    type:'find'
                },
            ]},
        ]
    },
    /** 8. Narwiański Park Narodowy */
    {
        idlocation:57,
        level:[
            {questions:[
                {
                    idquestion:36,
                    type:'closed'
                },
                {
                    idquestion:496,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:771,
                    type:'closed'
                },
                {
                    idquestion:496,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:776,
                    type:'closed'
                },
                {
                    idquestion:496,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:58,
        level:[
            {questions:[
                {
                    idquestion:221,
                    type:'closed'
                },
            ]},
            {questions:[
                {
                    idquestion:2221,
                    type:'closed'
                },
            ]},
            {questions:[
                {
                    idquestion:3221,
                    type:'closed'
                },
            ]},
        ]
    },
    {
        idlocation:59,
        level:[
            {questions:[
                {
                    idquestion:37,
                    type:'closed'
                },
                {
                    idquestion:497,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:772,
                    type:'closed'
                },
                {
                    idquestion:497,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:777,
                    type:'closed'
                },
                {
                    idquestion:497,
                    type:'recordsound'
                },
            ]},
        ]
    },
    {
        idlocation:60,
        level:[
            {questions:[
                {
                    idquestion:38,
                    type:'closed'
                },
                {
                    idquestion:498,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:773,
                    type:'closed'
                },
                {
                    idquestion:498,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:778,
                    type:'closed'
                },
                {
                    idquestion:498,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:61,
        level:[
            {questions:[
                {
                    idquestion:226,
                    type:'closed'
                }
            ]},
            {questions:[
                {
                    idquestion:2226,
                    type:'closed'
                }
            ]},
            {questions:[
                {
                    idquestion:3226,
                    type:'closed'
                }
            ]},
        ]
    },
    {
        idlocation:62,
        level:[
            {questions:[
                {
                    idquestion:39,
                    type:'closed'
                },
                {
                    idquestion:499,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:774,
                    type:'closed'
                },
                {
                    idquestion:499,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:779,
                    type:'closed'
                },
                {
                    idquestion:499,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:63,
        level:[
            {questions:[
                {
                    idquestion:231,
                    type:'closed'
                },
            ]},
            {questions:[
                {
                    idquestion:2231,
                    type:'closed'
                },
            ]},
            {questions:[
                {
                    idquestion:3231,
                    type:'closed'
                },
            ]},
        ]
    },
    {
        idlocation:64,
        level:[
            {questions:[
                {
                    idquestion:40,
                    type:'open'
                },
                {
                    idquestion:500,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:775,
                    type:'open'
                },
                {
                    idquestion:500,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:780,
                    type:'open'
                },
                {
                    idquestion:500,
                    type:'find'
                },
            ]},
        ]
    },
    /** 9. Park Narodowy „Ujście Warty” */
    {
        idlocation:65,
        level:[
            {questions:[
                {
                    idquestion:41,
                    type:'closed'
                },
                {
                    idquestion:501,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:781,
                    type:'closed'
                },
                {
                    idquestion:501,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:786,
                    type:'closed'
                },
                {
                    idquestion:501,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:66,
        level:[{
            questions:[
                {
                    idquestion:236,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:67,
        level:[
            {questions:[
                {
                    idquestion:42,
                    type:'closed'
                },
                {
                    idquestion:502,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:782,
                    type:'closed'
                },
                {
                    idquestion:502,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:787,
                    type:'closed'
                },
                {
                    idquestion:502,
                    type:'find'
                },
            ]},
        ]
    },
    {
        idlocation:68,
        level:[
            {questions:[
                {
                    idquestion:43,
                    type:'closed'
                },
                {
                    idquestion:503,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:783,
                    type:'closed'
                },
                {
                    idquestion:503,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:788,
                    type:'closed'
                },
                {
                    idquestion:503,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:69,
        level:[{
            questions:[
                {
                    idquestion:241,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:70,
        level:[
            {questions:[
                {
                    idquestion:44,  
                    type:'puzzle'
                },
                {
                    idquestion:504,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:784,  
                    type:'closed'
                },
                {
                    idquestion:504,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:789,  
                    type:'closed'
                },
                {
                    idquestion:504,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:71,
        level:[{
            questions:[
                {
                    idquestion:246,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:72,
        level:[
            {questions:[
                {
                    idquestion:45,
                    type:'open'
                },
                {
                    idquestion:505,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:785,
                    type:'closed'
                },
                {
                    idquestion:505,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:790,
                    type:'closed'
                },
                {
                    idquestion:505,
                    type:'find'
                },
            ]},
        ]
    },
    /** 10. Kampinoski Park Narodowy */
    {
        idlocation:73,
        level:[
            {questions:[
                {
                    idquestion:46,
                    type:'closed'
                },
                {
                    idquestion:506,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:791,
                    type:'closed'
                },
                {
                    idquestion:506,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:796,
                    type:'closed'
                },
                {
                    idquestion:506,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:74,
        level:[{
            questions:[
                {
                    idquestion:251,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:75,
        level:[
            {questions:[
                {
                    idquestion:47,
                    type:'closed'
                },
                {
                    idquestion:507,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:792,
                    type:'closed'
                },
                {
                    idquestion:507,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:797,
                    type:'closed'
                },
                {
                    idquestion:507,
                    type:'recordsound'
                },
            ]},
        ]
    },
    {
        idlocation:76,
        level:[
            {questions:[
                {
                    idquestion:48,
                    type:'closed'
                },
                {
                    idquestion:508,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:793,
                    type:'closed'
                },
                {
                    idquestion:508,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:798,
                    type:'closed'
                },
                {
                    idquestion:508,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:77,
        level:[{
            questions:[
                {
                    idquestion:256,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:78,
        level:[
            {questions:[
                {
                    idquestion:49,
                    type:'closed'
                },
                {
                    idquestion:509,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:794,
                    type:'closed'
                },
                {
                    idquestion:509,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:799,
                    type:'closed'
                },
                {
                    idquestion:509,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:79,
        level:[{
            questions:[
                {
                    idquestion:261,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:80,
        level:[
            {questions:[
                {
                    idquestion:50,
                    type:'open'
                },
                {
                    idquestion:510,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:795,
                    type:'closed'
                },
                {
                    idquestion:510,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:800,
                    type:'closed'
                },
                {
                    idquestion:510,
                    type:'find'
                },
            ]},
        ]
    },
    /** 11. Wielkopolski Park Narodowy */
    {
        idlocation:81,
        level:[
            {questions:[
                {
                    idquestion:51,
                    type:'open'
                },
                {
                    idquestion:511,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:801,
                    type:'closed'
                },
                {
                    idquestion:511,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:806,
                    type:'closed'
                },
                {
                    idquestion:511,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:82,
        level:[{
            questions:[
                {
                    idquestion:266,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:83,
        level:[
            {questions:[
                {
                    idquestion:52,
                    type:'closed'
                },
                {
                    idquestion:512,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:802,
                    type:'closed'
                },
                {
                    idquestion:512,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:807,
                    type:'closed'
                },
                {
                    idquestion:512,
                    type:'recordsound'
                },
            ]},
        ]
    },
    {
        idlocation:84,
        level:[
            {questions:[
                {
                    idquestion:53,
                    type:'closed'
                },
                {
                    idquestion:513,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:803,
                    type:'closed'
                },
                {
                    idquestion:513,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:808,
                    type:'closed'
                },
                {
                    idquestion:513,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:85,
        level:[{
            questions:[
                {
                    idquestion:271,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:86,
        level:[
            {questions:[
                {
                    idquestion:54,
                    type:'closed'
                },
                {
                    idquestion:514,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:804,
                    type:'closed'
                },
                {
                    idquestion:514,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:809,
                    type:'closed'
                },
                {
                    idquestion:514,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:87,
        level:[{
            questions:[
                {
                    idquestion:276,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:88,
        level:[
            {questions:[
                {
                    idquestion:55,
                    type:'open'
                },
                {
                    idquestion:515,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:805,
                    type:'closed'
                },
                {
                    idquestion:515,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:810,
                    type:'closed'
                },
                {
                    idquestion:515,
                    type:'find'
                },
            ]},
        ]
    },
    /** 12. Poleski Park Narodowy */
    {
        idlocation:89,
        level:[
            {questions:[
                {
                    idquestion:56,
                    type:'closed'
                },
                {
                    idquestion:516,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:811,
                    type:'closed'
                },
                {
                    idquestion:516,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:816,
                    type:'closed'
                },
                {
                    idquestion:516,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:90,
        level:[{
            questions:[
                {
                    idquestion:281,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:91,
        level:[
            {questions:[
                {
                    idquestion:57,
                    type:'closed'
                },
                {
                    idquestion:517,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:812,
                    type:'closed'
                },
                {
                    idquestion:517,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:817,
                    type:'closed'
                },
                {
                    idquestion:517,
                    type:'recordsound'
                },
            ]},
        ]
    },
    {
        idlocation:92,
        level:[
            {questions:[
                {
                    idquestion:58,
                    type:'open'
                },
                {
                    idquestion:518,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:813,
                    type:'closed'
                },
                {
                    idquestion:518,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:818,
                    type:'closed'
                },
                {
                    idquestion:518,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:93,
        level:[{
            questions:[
                {
                    idquestion:286,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:94,
        level:[
            {questions:[
                {
                    idquestion:59,
                    type:'closed'
                },
                {
                    idquestion:519,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:814,
                    type:'closed'
                },
                {
                    idquestion:519,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:819,
                    type:'closed'
                },
                {
                    idquestion:519,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:95,
        level:[{
            questions:[
                {
                    idquestion:291,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:96,
        level:[
            {questions:[
                {
                    idquestion:60,
                    type:'open'
                },
                {
                    idquestion:520,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:815,
                    type:'closed'
                },
                {
                    idquestion:520,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:820,
                    type:'closed'
                },
                {
                    idquestion:520,
                    type:'find'
                },
            ]},
        ]
    },
    /** 13. Świętokrzyski Park Narodowy */
    {
        idlocation:97,
        level:[
            {questions:[
                {
                    idquestion:61,
                    type:'closed'
                },
                {
                    idquestion:521,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:821,
                    type:'closed'
                },
                {
                    idquestion:521,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:826,
                    type:'closed'
                },
                {
                    idquestion:521,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:98,
        level:[{
            questions:[
                {
                    idquestion:296,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:99,
        level:[
            {questions:[
                {
                    idquestion:62,
                    type:'closed'
                },
                {
                    idquestion:522,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:822,
                    type:'closed'
                },
                {
                    idquestion:522,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:827,
                    type:'closed'
                },
                {
                    idquestion:522,
                    type:'puzzle'
                },
            ]},
        ]
    },
    {
        idlocation:100,
        level:[
            {questions:[
                {
                    idquestion:63,
                    type:'closed'
                },
                {
                    idquestion:523,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:823,
                    type:'closed'
                },
                {
                    idquestion:523,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:828,
                    type:'closed'
                },
                {
                    idquestion:523,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:101,
        level:[{
            questions:[
                {
                    idquestion:301,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:102,
        level:[
            {questions:[
                {
                    idquestion:590,
                    type:'memo'
                },
                {
                    idquestion:524,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:824,
                    type:'closed'
                },
                {
                    idquestion:524,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:829,
                    type:'closed'
                },
                {
                    idquestion:524,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:103,
        level:[{
            questions:[
                {
                    idquestion:306,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:104,
        level:[
            {questions:[
                {
                    idquestion:65,
                    type:'open'
                },
                {
                    idquestion:525,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:825,
                    type:'closed'
                },
                {
                    idquestion:525,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:830,
                    type:'closed'
                },
                {
                    idquestion:525,
                    type:'find'
                },
            ]},
        ]
    },
    /** 14. Roztoczański Park Narodowy */
    {
        idlocation:105,
        level:[
            {questions:[
                {
                    idquestion:66,
                    type:'closed'
                },
                {
                    idquestion:585,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:831,
                    type:'closed'
                },
                {
                    idquestion:585,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:836,
                    type:'closed'
                },
                {
                    idquestion:585,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:106,
        level:[{
            questions:[
                {
                    idquestion:311,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:107,
        level:[
            {questions:[
                {
                    idquestion:67,
                    type:'closed'
                },
                {
                    idquestion:586,
                    type:'closed'
                },
            ]},
            {questions:[
                {
                    idquestion:832,
                    type:'closed'
                },
                {
                    idquestion:586,
                    type:'closed'
                },
            ]},
            {questions:[
                {
                    idquestion:837,
                    type:'closed'
                },
                {
                    idquestion:586,
                    type:'closed'
                },
            ]},
        ]
    },
    {
        idlocation:108,
        level:[
            {questions:[
                {
                    idquestion:68,
                    type:'closed'
                },
                {
                    idquestion:587,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:833,
                    type:'closed'
                },
                {
                    idquestion:587,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:838,
                    type:'closed'
                },
                {
                    idquestion:587,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:109,
        level:[{
            questions:[
                {
                    idquestion:316,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:110,
        level:[
            {questions:[
                {
                    idquestion:69,
                    type:'closed'
                },
                {
                    idquestion:588,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:834,
                    type:'closed'
                },
                {
                    idquestion:588,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:839,
                    type:'closed'
                },
                {
                    idquestion:588,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:111,
        level:[{
            questions:[
                {
                    idquestion:321,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:112,
        level:[
            {questions:[
                {
                    idquestion:70,
                    type:'open'
                },
                {
                    idquestion:589,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:835,
                    type:'open'
                },
                {
                    idquestion:589,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:840,
                    type:'open'
                },
                {
                    idquestion:589,
                    type:'memo'
                },
            ]},
        ]
    },
    /** 15. Karkonoski Park Narodowy */
    {
        idlocation:113,
        level:[
            {questions:[
                {
                    idquestion:71,
                    type:'closed'
                },
                {
                    idquestion:531,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:841,
                    type:'closed'
                },
                {
                    idquestion:531,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:846,
                    type:'closed'
                },
                {
                    idquestion:531,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:114,
        level:[{
            questions:[
                {
                    idquestion:326,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:115,
        level:[
            {questions:[
                {
                    idquestion:72,
                    type:'closed'
                },
                {
                    idquestion:532,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:842,
                    type:'closed'
                },
                {
                    idquestion:532,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:847,
                    type:'closed'
                },
                {
                    idquestion:532,
                    type:'find'
                },
            ]},
        ]
    },
    {
        idlocation:116,
        level:[
            {questions:[
                {
                    idquestion:73,
                    type:'closed'
                },
                {
                    idquestion:533,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:843,
                    type:'closed'
                },
                {
                    idquestion:533,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:848,
                    type:'closed'
                },
                {
                    idquestion:533,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:117,
        level:[{
            questions:[
                {
                    idquestion:331,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:118,
        level:[
            {questions:[
                {
                    idquestion:74,
                    type:'closed'
                },
                {
                    idquestion:534,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:844,
                    type:'closed'
                },
                {
                    idquestion:534,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:849,
                    type:'closed'
                },
                {
                    idquestion:534,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:119,
        level:[{
            questions:[
                {
                    idquestion:336,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:120,
        level:[
            {questions:[
                {
                    idquestion:75,
                    type:'open'
                },
                {
                    idquestion:535,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:845,
                    type:'closed'
                },
                {
                    idquestion:535,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:850,
                    type:'closed'
                },
                {
                    idquestion:535,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /** 16. Park Narodowy Gór Stołowych */
    {
        idlocation:121,
        level:[
            {questions:[
                {
                    idquestion:76,
                    type:'closed'
                },
                {
                    idquestion:536,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:851,
                    type:'closed'
                },
                {
                    idquestion:536,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:856,
                    type:'closed'
                },
                {
                    idquestion:536,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:122,
        level:[{
            questions:[
                {
                    idquestion:341,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:123,
        level:[
            {questions:[
                {
                    idquestion:576,
                    type:'recordsound'
                },
                {
                    idquestion:537,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:852,
                    type:'closed'
                },
                {
                    idquestion:537,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:857,
                    type:'closed'
                },
                {
                    idquestion:537,
                    type:'recordsound'
                },
            ]},
        ]
    },
    {
        idlocation:124,
        level:[
            {questions:[
                {
                    idquestion:78,
                    type:'closed'
                },
                {
                    idquestion:538,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:853,
                    type:'closed'
                },
                {
                    idquestion:538,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:858,
                    type:'closed'
                },
                {
                    idquestion:538,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:125,
        level:[{
            questions:[
                {
                    idquestion:346,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:126,
        level:[
            {questions:[
                {
                    idquestion:79,
                    type:'closed'
                },
                {
                    idquestion:539,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:854,
                    type:'closed'
                },
                {
                    idquestion:539,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:859,
                    type:'closed'
                },
                {
                    idquestion:539,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:127,
        level:[{
            questions:[
                {
                    idquestion:351,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:128,
        level:[
            {questions:[
                {
                    idquestion:80,
                    type:'open'
                },
                {
                    idquestion:540,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:855,
                    type:'closed'
                },
                {
                    idquestion:540,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:860,
                    type:'closed'
                },
                {
                    idquestion:540,
                    type:'find'
                },
            ]},
        ]
    },
    /** 17. Ojcowski Park Narodowy */
    {
        idlocation:129,
        level:[
            {questions:[
                {
                    idquestion:81,
                    type:'closed'
                },
                {
                    idquestion:541,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:861,
                    type:'closed'
                },
                {
                    idquestion:541,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:866,
                    type:'closed'
                },
                {
                    idquestion:541,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:130,
        level:[{
            questions:[
                {
                    idquestion:356,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:131,
        level:[
            {questions:[
                {
                    idquestion:82,
                    type:'closed'
                },
                {
                    idquestion:542,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:862,
                    type:'closed'
                },
                {
                    idquestion:542,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:867,
                    type:'closed'
                },
                {
                    idquestion:542,
                    type:'memo'
                },
            ]},
        ]
    },
    {
        idlocation:132,
        level:[
            {questions:[
                {
                    idquestion:83,
                    type:'open'
                },
                {
                    idquestion:543,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:863,
                    type:'closed'
                },
                {
                    idquestion:543,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:868,
                    type:'closed'
                },
                {
                    idquestion:543,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:133,
        level:[{
            questions:[
                {
                    idquestion:361,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:134,
        level:[
            {questions:[
                {
                    idquestion:84,
                    type:'closed'
                },
                {
                    idquestion:544,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:864,
                    type:'closed'
                },
                {
                    idquestion:544,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:869,
                    type:'closed'
                },
                {
                    idquestion:544,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:135,
        level:[{
            questions:[
                {
                    idquestion:366,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:136,
        level:[
            {questions:[
                {
                    idquestion:85,
                    type:'open'
                },
                {
                    idquestion:545,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:865,
                    type:'closed'
                },
                {
                    idquestion:545,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:870,
                    type:'closed'
                },
                {
                    idquestion:545,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /** 18. Babiogórski Park Narodowy */
    {
        idlocation:137,
        level:[
            {questions:[
                {
                    idquestion:86,
                    type:'open'
                },
                {
                    idquestion:546,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:871,
                    type:'closed'
                },
                {
                    idquestion:546,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:876,
                    type:'closed'
                },
                {
                    idquestion:546,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:138,
        level:[{
            questions:[
                {
                    idquestion:371,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:139,
        level:[
            {questions:[
                {
                    idquestion:87,
                    type:'closed'
                },
                {
                    idquestion:547,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:872,
                    type:'closed'
                },
                {
                    idquestion:547,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:877,
                    type:'closed'
                },
                {
                    idquestion:547,
                    type:'find'
                },
            ]},
        ]
    },
    {
        idlocation:140,
        level:[
            {questions:[
                {
                    idquestion:88,
                    type:'closed'
                },
                {
                    idquestion:548,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:873,
                    type:'closed'
                },
                {
                    idquestion:548,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:878,
                    type:'closed'
                },
                {
                    idquestion:548,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:141,
        level:[{
            questions:[
                {
                    idquestion:376,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:142,
        level:[
            {questions:[
                {
                    idquestion:89,
                    type:'closed'
                },
                {
                    idquestion:549,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:874,
                    type:'closed'
                },
                {
                    idquestion:549,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:879,
                    type:'closed'
                },
                {
                    idquestion:549,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:143,
        level:[{
            questions:[
                {
                    idquestion:381,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:144,
        level:[
            {questions:[
                {
                    idquestion:90,
                    type:'open'
                },
                {
                    idquestion:550,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:875,
                    type:'closed'
                },
                {
                    idquestion:550,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:880,
                    type:'closed'
                },
                {
                    idquestion:550,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /** 19. Gorczański Park Narodowy */
    {
        idlocation:145,
        level:[
            {questions:[
                {
                    idquestion:91,
                    type:'closed'
                },
                {
                    idquestion:551,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:881,
                    type:'closed'
                },
                {
                    idquestion:551,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:886,
                    type:'sort'
                },
                {
                    idquestion:551,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:146,
        level:[{
            questions:[
                {
                    idquestion:386,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:147,
        level:[
            {questions:[
                {
                    idquestion:577,
                    type:'closed'
                },
                {
                    idquestion:552,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:882,
                    type:'closed'
                },
                {
                    idquestion:552,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:887,
                    type:'closed'
                },
                {
                    idquestion:552,
                    type:'find'
                },
            ]},
        ]
    },
    {
        idlocation:148,
        level:[
            {questions:[
                {
                    idquestion:578,
                    type:'closed'
                },
                {
                    idquestion:553,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:883,
                    type:'closed'
                },
                {
                    idquestion:553,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:888,
                    type:'closed'
                },
                {
                    idquestion:553,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:149,
        level:[{
            questions:[
                {
                    idquestion:391,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:150,
        level:[
            {questions:[
                {
                    idquestion:94,
                    type:'closed'
                },
                {
                    idquestion:554,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:884,
                    type:'closed'
                },
                {
                    idquestion:554,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:889,
                    type:'closed'
                },
                {
                    idquestion:554,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:151,
        level:[{
            questions:[
                {
                    idquestion:396,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:152,
        level:[
            {questions:[
                {
                    idquestion:95,
                    type:'open'
                },
                {
                    idquestion:555,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:885,
                    type:'closed'
                },
                {
                    idquestion:555,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:93,
                    type:'closed'
                },
                {
                    idquestion:555,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /** 20. Magurski Park Narodowy */
    {
        idlocation:153,
        level:[
            {questions:[
                {
                    idquestion:96,
                    type:'open'
                },
                {
                    idquestion:556,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:891,
                    type:'closed'
                },
                {
                    idquestion:556,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:896,
                    type:'closed'
                },
                {
                    idquestion:556,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:154,
        level:[{
            questions:[
                {
                    idquestion:401,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:155,
        level:[
            {questions:[
                {
                    idquestion:97,
                    type:'closed'
                },
                {
                    idquestion:557,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:892,
                    type:'closed'
                },
                {
                    idquestion:557,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:897,
                    type:'closed'
                },
                {
                    idquestion:557,
                    type:'find'
                },
            ]},
        ]
    },
    {
        idlocation:156,
        level:[
            {questions:[
                {
                    idquestion:579,
                    type:'puzzle'
                },
                {
                    idquestion:558,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:893,
                    type:'closed'
                },
                {
                    idquestion:558,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:898,
                    type:'closed'
                },
                {
                    idquestion:558,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:157,
        level:[{
            questions:[
                {
                    idquestion:406,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:158,
        level:[
            {questions:[
                {
                    idquestion:99,
                    type:'closed'
                },
                {
                    idquestion:559,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:894,
                    type:'closed'
                },
                {
                    idquestion:559,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:899,
                    type:'closed'
                },
                {
                    idquestion:559,
                    type:'memo'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:159,
        level:[{
            questions:[
                {
                    idquestion:411,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:160,
        level:[
            {questions:[
                {
                    idquestion:100,
                    type:'open'
                },
                {
                    idquestion:560,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:895,
                    type:'closed'
                },
                {
                    idquestion:560,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:900,
                    type:'closed'
                },
                {
                    idquestion:560,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /** 21. Pieniński Park Narodowy */
    {
        idlocation:161,
        level:[
            {questions:[
                {
                    idquestion:580,
                    type:'closed'
                },
                {
                    idquestion:561,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:901,
                    type:'closed'
                },
                {
                    idquestion:561,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:906,
                    type:'closed'
                },
                {
                    idquestion:561,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:162,
        level:[{
            questions:[
                {
                    idquestion:416,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:163,
        level:[
            {questions:[
                {
                    idquestion:102,
                    type:'closed'
                },
                {
                    idquestion:562,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:902,
                    type:'closed'
                },
                {
                    idquestion:562,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:907,
                    type:'closed'
                },
                {
                    idquestion:562,
                    type:'find'
                },
            ]},
        ]
    },
    {
        idlocation:164,
        level:[
            {questions:[
                {
                    idquestion:103,
                    type:'closed'
                },
                {
                    idquestion:563,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:903,
                    type:'closed'
                },
                {
                    idquestion:563,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:908,
                    type:'closed'
                },
                {
                    idquestion:563,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:165,
        level:[{
            questions:[
                {
                    idquestion:421,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:166,
        level:[
            {questions:[
                {
                    idquestion:104,
                    type:'open'
                },
                {
                    idquestion:564,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:904,
                    type:'closed'
                },
                {
                    idquestion:564,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:909,
                    type:'closed'
                },
                {
                    idquestion:564,
                    type:'memo'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:167,
        level:[{
            questions:[
                {
                    idquestion:426,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:168,
        level:[
            {questions:[
                {
                    idquestion:105,
                    type:'open'
                },
                {
                    idquestion:565,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:905,
                    type:'closed'
                },
                {
                    idquestion:565,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:910,
                    type:'closed'
                },
                {
                    idquestion:565,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /** 22. Bieszczadzki Park Narodowy */
    {
        idlocation:169,
        level:[
            {questions:[
                {
                    idquestion:581,
                    type:'closed'
                },
                {
                    idquestion:566,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:911,
                    type:'closed'
                },
                {
                    idquestion:566,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:916,
                    type:'closed'
                },
                {
                    idquestion:566,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:170,
        level:[{
            questions:[
                {
                    idquestion:431,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:171,
        level:[
            {questions:[
                {
                    idquestion:107,
                    type:'open'
                },
                {
                    idquestion:567,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:912,
                    type:'closed'
                },
                {
                    idquestion:567,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:917,
                    type:'closed'
                },
                {
                    idquestion:567,
                    type:'memo'
                },
            ]},
        ]
    },
    {
        idlocation:172,
        level:[
            {questions:[
                {
                    idquestion:108,
                    type:'closed'
                },
                {
                    idquestion:568,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:913,
                    type:'closed'
                },
                {
                    idquestion:568,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:918,
                    type:'closed'
                },
                {
                    idquestion:568,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:173,
        level:[{
            questions:[
                {
                    idquestion:436,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:174,
        level:[
            {questions:[
                {
                    idquestion:109,
                    type:'closed'
                },
                {
                    idquestion:569,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:914,
                    type:'closed'
                },
                {
                    idquestion:569,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:919,
                    type:'closed'
                },
                {
                    idquestion:569,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:175,
        level:[{
            questions:[
                {
                    idquestion:441,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:176,
        level:[
            {questions:[
                {
                    idquestion:110,
                    type:'open'
                },
                {
                    idquestion:570,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:915,
                    type:'closed'
                },
                {
                    idquestion:570,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:920,
                    type:'closed'
                },
                {
                    idquestion:570,
                    type:'memo'
                },
            ]},
        ]
    },
    /** 23. Tatrzański Park Narodowy */
    {
        idlocation:177,
        level:[
            {questions:[
                {
                    idquestion:111,
                    type:'closed'
                },
                {
                    idquestion:571,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:921,
                    type:'closed'
                },
                {
                    idquestion:571,
                    type:'recordsound'
                },
            ]},
            {questions:[
                {
                    idquestion:926,
                    type:'closed'
                },
                {
                    idquestion:571,
                    type:'recordsound'
                },
            ]},
        ]
    },
    /* QUEST 1 */
    {
        idlocation:178,
        level:[{
            questions:[
                {
                    idquestion:446,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:179,
        level:[
            {questions:[
                {
                    idquestion:112,
                    type:'closed'
                },
                {
                    idquestion:572,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:922,
                    type:'closed'
                },
                {
                    idquestion:572,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:927,
                    type:'closed'
                },
                {
                    idquestion:572,
                    type:'find'
                },
            ]},
        ]
    },
    {
        idlocation:180,
        level:[
            {questions:[
                {
                    idquestion:113,
                    type:'open'
                },
                {
                    idquestion:573,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:923,
                    type:'closed'
                },
                {
                    idquestion:573,
                    type:'puzzle'
                },
            ]},
            {questions:[
                {
                    idquestion:928,
                    type:'closed'
                },
                {
                    idquestion:573,
                    type:'puzzle'
                },
            ]},
        ]
    },
    /* QUEST 2 */
    {
        idlocation:181,
        level:[{
            questions:[
                {
                    idquestion:451,
                    type:'closed'
                }
            ]
        }]
    },
    {
        idlocation:182,
        level:[
            {questions:[
                {
                    idquestion:114,
                    type:'closed'
                },
                {
                    idquestion:574,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:924,
                    type:'closed'
                },
                {
                    idquestion:574,
                    type:'find'
                },
            ]},
            {questions:[
                {
                    idquestion:929,
                    type:'closed'
                },
                {
                    idquestion:574,
                    type:'find'
                },
            ]},
        ]
    },
    /* QUEST 3 */
    {
        idlocation:183,
        level:[{
            questions:[
                {
                    idquestion:456,
                    type:'closed'
                },
            ]
        }]
    },
    {
        idlocation:184,
        level:[
            {questions:[
                {
                    idquestion:115,
                    type:'open'
                },
                {
                    idquestion:575,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:925,
                    type:'closed'
                },
                {
                    idquestion:575,
                    type:'memo'
                },
            ]},
            {questions:[
                {
                    idquestion:930,
                    type:'closed'
                },
                {
                    idquestion:575,
                    type:'memo'
                },
            ]},
        ]
    },
];

export default locationschallenges;