/**
 * Obiekt przechowujący nagrane przez gracza odgłosy zwierząt
 */
import { createSlice } from "@reduxjs/toolkit";
import { AnimalSoundProps } from "../../types";

const soundsAnimalsSlice = createSlice({
    name: 'soundsanimals',
    initialState: { 
        values: []
    },
    reducers: {
        setSoundsAnimals: (state, action) => {
            const soundAnimal = action.payload.value;
            const stateSoundsAnimals:AnimalSoundProps[] = state.values!==undefined?[...state.values]:[];
            if(stateSoundsAnimals!==undefined){
                const exist = stateSoundsAnimals.find((item:AnimalSoundProps)=>item.src===soundAnimal.src);
                if(exist===undefined) stateSoundsAnimals.push(soundAnimal);
                state.values = stateSoundsAnimals as never;
            }
        },
        setLocalStorageSoundsAnimals: (state, action) => {
            state.values = action.payload.values;
        }
    }
});

export const setSoundsAnimals = soundsAnimalsSlice.actions.setSoundsAnimals;
export const setLocalStorageSoundsAnimals = soundsAnimalsSlice.actions.setLocalStorageSoundsAnimals;
export default soundsAnimalsSlice.reducer;
