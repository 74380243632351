/**
 * Komponent prezentujący listę kupionych przez gracza pocztówek
 */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { PostCardsProps } from "../types";
import styles from '../styles/components/Postcards.module.css';
import Sizes from '../constans/sizes';

const Postcards = (props:any) => {
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Kupione przez gracza pocztówki
     */
    const mypostcards:PostCardsProps[] = useSelector((state:any) => state.postcards.values);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    const _mypostcards:any[] = useMemo(()=>mypostcards.map((item:PostCardsProps)=>{
        return <li key={item.id}>
                    <h3 style={{margin:0}}>
                        <img className={contrast==='on'?styles.postcardsContrast:styles.postcards} src={item.src} alt={item.alt}/>
                    </h3>
                    <button className={contrast==='on'?styles.downloadbtContrast:styles.downloadbt} style={{color:contrast==='on'?'#fff':'#000',fontSize:getFontSize()}} onClick={()=>downloadPostcard(item.src)}>Pobierz</button>
                </li>
    /*eslint-disable */
    }),[mypostcards,contrast]);
    /*eslint-disable */
    /**
     * Funkcja pobiera pocztówkę
     * @param {any} src ścieżka do obrazka 
     */
    const downloadPostcard = async(src:any) => {
        const originalImage=src;
        const image = await fetch(originalImage);
        const nameSplit=originalImage.split("/");
        const  duplicateName=nameSplit.pop();
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)
        const link = document.createElement('a')
        link.href = imageURL;
        link.download = ""+duplicateName+"";
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)  
    };

    return (
        <div id="postcards" className={styles.postcardsContainer} style={{outlineColor:contrast==='on'?'#ffff00':'#f08733'}} tabIndex={0}>
            <ul className={styles.ul}>
             <h2 className="sronly">LISTA KUPIONYCH POCZTÓWEK</h2>
             {
                 _mypostcards
             }
             </ul>
        </div>
    );
}

export default Postcards;