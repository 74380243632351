/**
 * Komponent prezentujący listę możliwych do odtworzenia dzwięków zwierząt nagranych przez gracza 
 */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import styles from '../styles/components/Sounds.module.css';
import { AnimalSoundProps } from "../types";
import SoundItem from "./SoundItem";


const Sounds = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
     /**
      * Nagrane przez gracza odgłosy zwierzęta
      */
     const soundsanimals = useSelector((state:any) => state.soundsanimals.values);

     const _sounds:any[] = useMemo(()=>soundsanimals.map((item:AnimalSoundProps)=>{
        return <SoundItem key={item.id} name={item.name} src={item.src} fontSize={props.fontSize}/>
     }),[soundsanimals,props.fontSize]);

     return (
         <div id="sounds" className={styles.soundsContainer} style={{outlineColor:contrast==='on'?'#ffff00':'#f08733'}} tabIndex={0}>
             <ul className={styles.ul}>
             <h2 className="sronly">LISTA NAGRANYCH ODGŁOSÓW</h2>
             {
                 _sounds
             }
             </ul>
         </div>
     );
}

export default Sounds;