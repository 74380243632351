/**
 * Komponent wyświetlający boczne menu
 */
import { KeyboardEvent } from "react";
import styles from '../styles/components/LeftTabBar.module.css';
import shop from '../assets/icons/shop.png';
import map from '../assets/icons/map.png';
import atlas from '../assets/icons/atlas.png';
import guide from '../assets/icons/guide.png';
import statute from '../assets/icons/statute.png';
import curiosities from '../assets/icons/curiosities.png';
import { useLocation, useNavigate } from "react-router-dom";
import SoundEffect from "./SoundEffect";
import { useSelector } from "react-redux";
import Sizes from '../constans/sizes';

const LeftTabBar = (props:{idpark?:number,logo?:any,visibilityButtons?:DocumentVisibilityState,guide?:boolean}) => {
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke 
     */
     const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
     const sound = useSelector((state:any) => state.sound.value);
    /**
     * Ustawienie stanów początkowych
     */
    const navigate = useNavigate();
    const location = useLocation();
    /**
     * Funkcja przechodzi do wybranej strony
     * @param {string} goto nazwa strony 
     */
    const onClick = (goto:string) => {
        if(sound!==undefined && sound==='on') SoundEffect('menuButton');
        if(goto==='/guide' || goto==='/parkstatute' || goto==='/parkcuriosities' || goto==='/spieces' || goto === '/shop' || goto === '/atlas'){
            if(goto === '/parkstatute' && location.pathname.indexOf('/parkcuriosities') !== -1) goto = location.pathname.replace('/parkcuriosities',goto);
            else if(goto === '/parkstatute' && location.pathname.indexOf('/spieces') !== -1) goto = location.pathname.replace('/spieces',goto);
            else if (goto === '/parkcuriosities' && location.pathname.indexOf('/parkstatute') !== -1) goto = location.pathname.replace('/parkstatute',goto);
            else if (goto === '/parkcuriosities' && location.pathname.indexOf('/spieces') !== -1) goto = location.pathname.replace('/spieces',goto);
            else if (goto === '/spieces' && location.pathname.indexOf('/parkstatute') !== -1) goto = location.pathname.replace('/parkstatute',goto);
            else if (goto === '/spieces' && location.pathname.indexOf('/parkcuriosities') !== -1) goto = location.pathname.replace('/parkcuriosities',goto);
            else if (goto === '/shop' && location.pathname.indexOf('/atlas/spieces') !== -1) goto = location.pathname.replace('/atlas/spieces',goto);
            else if (goto === '/atlas' && location.pathname.indexOf('/atlas/spieces') !== -1) goto = location.pathname.replace('/atlas/spieces',goto);
            else if (goto === '/guide' && location.pathname.indexOf('/atlas/spieces') !== -1) goto = location.pathname.replace('/atlas/spieces',goto);
            else if (goto !== '/parkstatute' && goto !== '/parkcuriosities' && goto !== '/spieces' && location.pathname !== '/park' && location.pathname !== '/park/location'  
            && (location.pathname.indexOf('/shop') !== -1 || location.pathname.indexOf('/atlas') !== -1 || location.pathname.indexOf('guide') !== -1)){
                ['/shop','/atlas','/guide'].forEach(item => {
                    if(location.pathname.indexOf(item) !== -1) goto = location.pathname.replace(item,goto);
                });
            }
            else goto = location.pathname.indexOf(goto) === -1 ? location.pathname + goto : location.pathname;
        } 
        if(location.pathname!==goto){
            navigate(goto,{
                state: {
                    idpark: props.idpark,
                }
            });
        }
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>,goto:string) => {
        if(event.key === 'Enter'){
            event.preventDefault(); 
            onClick(goto); 
        }  
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <div className={styles.container}>
            <h2 style={{margin:0}}>
                <button className={contrast==='on'?styles.buttonContrast:styles.button} onClick={() => onClick('/park')} onKeyDown={(event)=>onKeyDown(event,'/park')}>
                    <img className={contrast==='on'?styles.logoContrast:styles.logo} src={props?.logo} alt=""/>
                    <span className="sronly">PRZEJDŹ DO EKRANU PARKU</span>
                </button>
            </h2>
            <h2 className="sronly" style={{display:props.visibilityButtons===undefined?'block':'none'}}>BOCZNE MENU</h2>
            <div className={styles.buttons} style={{visibility:props.visibilityButtons===undefined?'visible':props.visibilityButtons}}>
                <h3 style={{display:props?.guide?'none':'block',marginTop:props?.guide?0:20}}>
                    <button className={contrast==='on'?styles.buttonContrast:styles.button} onClick={() => onClick('/')} onKeyDown={(event)=>onKeyDown(event,'/')}>
                        <img src={map} alt="PRZEJDŹ DO: " className={styles.icon}/>
                        <span className={contrast==='on'?styles.spanContrast:styles.span} style={{display:props?.guide?'none':'block',fontSize:getFontSize()}}>MAPA</span>
                    </button>
                </h3>
                <h3 style={{display:props?.guide?'none':'block',marginTop:props?.guide?0:20}}>
                    <button className={contrast==='on'?styles.buttonContrast:styles.button} onClick={() => onClick('/guide')} onKeyDown={(event)=>onKeyDown(event,'/guide')}>
                        <img src={guide} alt="PRZEJDŹ DO: " className={styles.icon}/>
                        <span className={contrast==='on'?styles.spanContrast:styles.span} style={{display:props?.guide?'none':'block',fontSize:getFontSize()}}>PRZEWODNIK</span>
                    </button>
                </h3>
                <h3 style={{display:props?.guide?'none':'block',marginTop:props?.guide?0:20}}>
                    <button className={contrast==='on'?styles.buttonContrast:styles.button} onClick={() => onClick('/atlas')} onKeyDown={(event)=>onKeyDown(event,'/atlas')}>
                        <img  src={atlas} alt="PRZEJDŹ DO: " className={styles.icon}/>
                        <span className={contrast==='on'?styles.spanContrast:styles.span} style={{display:props?.guide?'none':'block',fontSize:getFontSize()}}>ATLAS</span>
                    </button>
                </h3>
                <h3 style={{display:props?.guide?'none':'block',marginTop:props?.guide?0:40}}>
                    <button className={contrast==='on'?styles.buttonContrast:styles.button} onClick={() => onClick('/shop')} onKeyDown={(event)=>onKeyDown(event,'/shop')}>
                        <img  src={shop} alt="PRZEJDŹ DO: " className={styles.icon}/>
                        <span className={contrast==='on'?styles.spanContrast:styles.span} style={{display:props?.guide?'none':'block',fontSize:getFontSize()}}>SKLEP</span>
                    </button>
                </h3>
               <h3 style={{display:props?.guide?'block':'none',marginTop:props?.guide?40:0}}>
                    <button className={contrast==='on'?styles.buttonContrast:styles.button} onClick={() => onClick('/parkstatute')} onKeyDown={(event)=>onKeyDown(event,'/parkstatute')}>
                        <img  src={statute}  alt="PRZEJDŹ DO: " className={styles.icon}/>
                        <span className={contrast==='on'?styles.spanContrast:styles.span} style={{display:props?.guide?'block':'none',fontSize:getFontSize()}}>ZASADY W PARKU</span>
                    </button>
               </h3>
                <h3 style={{display:props?.guide?'block':'none',marginTop:props?.guide?20:0}}>
                    <button className={contrast==='on'?styles.buttonContrast:styles.button} onClick={() => onClick('/parkcuriosities')} onKeyDown={(event)=>onKeyDown(event,'/parkcuriosities')}>
                        <img src={curiosities}  alt="PRZEJDŹ DO: " className={styles.icon}/>
                        <span className={contrast==='on'?styles.spanContrast:styles.span} style={{display:props?.guide?'block':'none',fontSize:getFontSize()}}>CIEKAWOSTKI</span>
                    </button>
                </h3>
            </div>
        </div>
    )
}

export default LeftTabBar;