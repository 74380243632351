/**
 * Ekran wyświetlający informacje o aplikacji
 */
import Container from "../../components/Container";
import styles from "../../styles/screens/menu/AboutApplication.module.css";
import MenuHeader from "../../components/MenuHeader";

import img_fe from "../../assets/logo/fe_is.jpeg";
import img_mksdop from "../../assets/logo/mkis.png";
import img_ppn from "../../assets/logo/ppn.png";
import img_fs from "../../assets/logo/ue_fs.png";

import img_fe_contrast from "../../assets/logo/fe_is_contrast.jpeg";
import img_ppn_contrast from "../../assets/logo/ppn_contrast.png";
import img_fs_contrast from "../../assets/logo/ue_fs_contrast.jpeg";

import { useSelector } from "react-redux";
import Sizes from '../../constans/sizes';
import { useNavigate } from "react-router-dom";

const AboutApplication = () => {
    const navigate = useNavigate(); 
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja wychodzi z ekranu
     */
     const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}>
            <div className={styles.aboutContainer}>
                <MenuHeader/>
                <div className={styles.aboutContent}>
                    <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>O PROJEKCIE</p>
                    {/*<p className={contrast==='on'?styles.titleContrast:styles.title} style={{fontSize:getFontSize()+8}}>ODKRYWCA PARKÓW NARODOWYCH</p>*/}
                    <div className={contrast==='on'?styles.mksContrast:styles.mks}>
                        <img className={contrast==='on'?styles.partnerContrast:styles.partner} src={img_mksdop} alt="Fundusze Europejskie"/>
                    </div>
                    <div className={contrast==='on'?styles.descriptionContrast:styles.description} style={{fontSize:getFontSize()+4}}>
                        <p><i>„Promocja Parków Narodowych jako marki”</i> to nazwa projektu, realizowanego od 2016 roku przez Ministerstwo Środowiska (obecnie Ministerstwo Klimatu i Środowiska), który ma na celu zbudowanie trwałego poparcia społecznego dla instytucji Parków Narodowych, a także aktywnie zaangażuje rzesze Polaków w realizowaną przez nie misji ochrony przyrody. Zadania w ramach projektu mają też zwiększyć siłę prowadzonych przez poszczególne Parki Narodowe działań edukacyjnych. Działania realizowane w ramach Projektu współfinansowane są ze środków Unii Europejskiej z Funduszu Spójności w ramach Programu Operacyjnego Infrastruktura i Środowisko 2014-2020.</p>
                        <p>Parki Narodowe tworzy się na obszarach wyróżniających się szczególnymi wartościami  przyrodniczymi, kulturowymi, na obszarze którego  ochronie podlega cała przyroda oraz walory krajobrazowe. Tworzone są w celu zachowania różnorodności biologicznej, zasobów, tworów i składników przyrody nieożywionej i walorów krajobrazowych, przywrócenia właściwego stanu zasobów i składników przyrody oraz odtworzenia zniekształconych siedlisk przyrodniczych, a także całych ekosystemów. Ponadto na terenie Parków Narodowych prowadzone są badania naukowe oraz edukacja przyrodnicza. Wszelkie działania podporządkowane są ochronie zasobów przyrody.</p>
                        <p>Obecnie w Polsce istnieją 23 parki narodowe, o łącznej powierzchni  około 317 000 hektarów, co stanowi niewiele ponad 1% powierzchni kraju. Polskie Parki Narodowe to najważniejszy element krajowego systemu ochrony dziedzictwa przyrodniczego. To ponad 80-letnia tradycja ochrony przyrody o najwyższej wartości. Jednocześnie obszary objęte tą formą ochrony w Polsce są w znacznej części udostępnione społeczeństwu. W tym celu w Parkach Narodowych zatrudnieni są specjaliści, których zadaniem jest zarówno ochrona zasobów  przyrodniczych, jak i szeroko pojęta edukacja przyrodnicza oraz udostępnianie dziedzictwa przyrodniczego. Drogi Graczu zachwyć się pięknem Parków Narodowych i stań się ich Odkrywcą!</p>
                        <p style={{textAlign:'center'}}><strong>Projekt POIS. 02.04.00-00-0001/15 „Promocja Parków Narodowych jako marki” współfinansowany ze środków Unii Europejskiej w ramach Programu Operacyjnego Infrastruktura i Środowisko 2014-2020</strong></p>
                    </div>
                    {/*<p className={contrast==='on'?styles.titleContrast:styles.title} style={{fontSize:getFontSize()+8}}>OFICJALNI PARTNERZY</p>*/}
                    
                    
                    
                    <div className={contrast==='on'?styles.partnersContrast:styles.partners}>
                        <img className={styles.partner} src={contrast==='on'?img_fe_contrast:img_fe} alt="Fundusze Europejskie"/>
                        <img className={contrast==='on'?styles.partnerppnContrast:styles.partnerppn} src={contrast==='on'?img_ppn_contrast:img_ppn} alt="Polskie Parki Narodowe"/>
                        <img className={styles.partner} src={contrast==='on'?img_fs_contrast:img_fs} alt="Unia Europejska"/>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default AboutApplication;

