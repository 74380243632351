/**
 * Ekran wyświetlający informacje o regulaminie
 */
import Container from "../../components/Container";
import styles from "../../styles/screens/menu/Statute.module.css";
import MenuHeader from "../../components/MenuHeader";
import { useSelector } from "react-redux";
import Sizes from '../../constans/sizes';
import { useNavigate } from "react-router-dom";

const statute = `<p>Obowiązuje od dnia 25 listopada 2022 r.</p>
<p class='textaligncenter'><b>§ 1. Definicje</b></p>
<p>Ilekroć w Regulaminie jest mowa o:</p>
<ul>
    <li class='decimal'><b>Aplikacji</b> –  rozumie się przez to grę  pn. „Odkrywca Parków Narodowych”, której właścicielem jest Usługodawca;</li>
    <li class='decimal'><b>Autorze</b> – rozumie się przez to autora Utworów prezentowanych w Aplikacji (zdjęcia, pliki dźwiękowe);</li>
    <li class='decimal'><b>Danych osobowych</b>  – rozumie się przez to informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej („osoba, której Dane dotyczą”). Osobą możliwą do zidentyfikowania jest osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej;</li>
    <li class='decimal'><b>Kodeksie Cywilnym</b>  – rozumie się przez to ustawę z dnia 23 kwietnia 1964 r. – Kodeks cywilny (t.j. Dz.U. z 2022 r. poz. 1360 ze zm.);</li>
    <li class='decimal'><b>Polityka Prywatności</b> – rozumie się przez to zbiór zasad, których celem jest poinformowanie Użytkownika o aspektach procesu odnoszącego się do pozyskania, przetwarzania oraz zabezpieczenia danych osobowych;</li>
    <li class='decimal'><b>Rozporządzeniu lub RODO</b>  – rozumie się przez to Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych osobowych) (Dz. Urz. UE L nr 119/1);</li>
    <li class='decimal'><b>Usługa</b>  – rozumie się przez to świadczenie usługi drogą elektroniczną w postaci gry multimedialnej „Odkrywca Parków Narodowych”;</li>
    <li class='decimal'><b>Usługodawca</b> – rozumie się przez to Ministerstwo Klimatu i Środowiska, ul. Wawelska 52/54, 00-922 Warszawa;</li>
    <li class='decimal'><b>Ustawie o ochronie danych osobowych</b> – rozumie się przez to ustawę z dnia 10 maja 2018 roku o ochronie danych osobowych (t.j. Dz.U. z 2019 r. poz. 1781 ze zm.);</li>
    <li class='decimal'><b>Ustawie o prawie autorskim i prawach pokrewnych</b> – rozumie się przez to ustawę z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz.U z 2021 poz. 1062 ze zm.);</li>
    <li class='decimal'><b>Ustawie o świadczeniu usług drogą elektroniczną</b>  – rozumie się przez to ustawę z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (t.j. Dz.U. z 2020 poz. 344 ze zm.;</li>
    <li class='decimal'><b>Utworze</b> – rozumie się przez to utwór w rozumieniu Ustawy o prawie autorskim i prawach pokrewnych prezentowany w Aplikacji, w szczególności zdjęcia;</li>
    <li class='decimal'><b>Użytkowniku Aplikacji</b> – rozumie się przez to osobę fizyczną lub prawną, korzystającą z funkcjonalności Aplikacji;</li>
    <li class='decimal'><b>Regulamin</b>  – niniejszy regulamin świadczenia usług drogą elektroniczną;</li>
</ul>
<p class='textaligncenter'><b>§ 2. Postanowienia ogólne</b></p>
<ul>
    <li class='decimal'>Regulamin określa prawa, obowiązki  oraz zakres odpowiedzialności Użytkownika Aplikacji i Usługodawcy.</li>
    <li class='decimal'>Regulamin jest dostępny w Aplikacji oraz na stronie internetowej: odkrywcaparkownarodowych.pl – w formie, która umożliwia jego pobranie, utrwalenie, wydrukowanie, jak i w wersji papierowej w siedzibie Usługodawcy.</li>
    <li class='decimal'>Usługi świadczone przez Usługodawcę świadczone są zgodnie z Regulaminem i na jego podstawie.</li>
    <li class='decimal'>Użytkownik Aplikacji jest zobowiązany do przestrzegania Regulaminu od chwili jego zaakceptowania.</li>
    <li class='decimal'>Użytkownik Aplikacji jest zobowiązany do zapoznania się z treścią Regulaminu przed rozpoczęciem korzystania z Aplikacji.</li>
    <li class='decimal'>Rozpoczęcie korzystania z Aplikacji jest równoznaczne akceptacją Regulaminu oraz Polityki prywatności do gry multimedialnej przez Użytkownika Aplikacji.</li>
    <li class='decimal'>Wszelkie prawa do Aplikacji oraz Utworów, w tym majątkowe prawa autorskie, prawa własności intelektualnej do nazwy gry, zdjęć i odgłosów zwierząt przysługują Usługodawcy, a korzystanie z nich może następować wyłącznie w sposób określony i zgodny z Regulaminem.</li>
</ul>
<p class='textaligncenter'><b>§ 3. Zasady dostępu do Aplikacji i ogólne warunki korzystania z Aplikacji</b></p>
<ul>
    <li class='decimal'>Aplikacja ma charakter informacyjny i edukacyjny.</li>
    <li class='decimal'>Aplikacja oraz zasoby Aplikacji udostępniane są Użytkownikom Aplikacji bezpłatnie.</li>
    <li class='decimal'>Warunkiem koniecznym do korzystania z Aplikacji jest posiadanie przez Użytkownika Aplikacji urządzenia mobilnego, spełniającego następujące wymagania techniczne: iOS w wersji minimum 13, Android w wersji minimum Android 10 i Harmony OS w wersji minimum 2.</li>
    <li class='decimal'>Z Aplikacji może korzystać każdy Użytkownik Aplikacji posiadający  urządzenie mobilne, spełniające warunki jak w ust. 3 oraz komunikujące się z publiczną siecią Internet i obsługujące technologie stron internetowych.</li>
    <li class='decimal'>Dla zachowania bezpieczeństwa korzystania z Aplikacji, zaleca się, aby urządzenie, z którego korzysta Użytkownik Aplikacji w szczególności posiadało zainstalowane wszystkie dostępne aktualizacje systemu operacyjnego oraz przeglądarki internetowej, które dotyczą bezpieczeństwa.</li>
    <li class='decimal'>Aplikację można pobrać korzystając z następujących sklepów internetowych: App Store (dla systemu operacyjnego iOS), Google Play (dla systemu operacyjnego Android) i  AppGallery (dla systemu operacyjnego Harmony OS).</li>
    <li class='decimal'>W celu korzystania z Aplikacji nie jest wymagane zalogowanie się albo założenie konta osobistego Użytkownika.</li>
    <li class='decimal'>Do pobrania, instalacji, uruchomienia i korzystania z Aplikacji wymagane jest połączenie z Internetem. Wszelkie koszty połączenia z Internetem, w szczególności transmisji danych, pokrywa Użytkownik Aplikacji we własnym zakresie, stosownie do umów zawartych przez Użytkownika Aplikacji z operatorami telekomunikacyjnymi lub innym dostawcą Internetu. Usługodawca nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie usług przez operatorów telekomunikacyjnych lub innych dostawców Internetu, z którymi Użytkownik Aplikacji ma zawarte umowy.</li>
    <li class='decimal'>Aplikacja nie pobiera i nie przechowuje danych osobowych Użytkownika Aplikacji (vide § 7 Regulamin).</li>
    <li class='decimal'>Jakakolwiek informacja dotycząca produktów oraz informacje wysyłane przez Usługodawcę z wykorzystaniem notyfikacji PUSH znajdujące się w zasobach Aplikacji nie stanowią oferty handlowej w rozumieniu prawa cywilnego.</li>
    <li class='decimal'>Jakiekolwiek odniesienia do innych stron internetowych (linki) nie stanowią wskazania Usługodawcy do ich użytkowania przez Użytkowników Aplikacji.</li>
    <li class='decimal'>W przypadku wystąpienia awarii lub innego rodzaju zakłócenia w świadczeniu Usługi Usługodawca zastrzega sobie prawo do każdorazowego, przejściowego zaprzestania świadczenia Usługi z przyczyn technicznych, związanych z pracą wykorzystywanych urządzeń do obsługi Aplikacji. Usługodawca oświadcza, iż podejmie wszelkie kroki zmierzające do jak najszybszego przywrócenia pełnej sprawności Aplikacji w razie awarii i w celu zminimalizowania jej skutków.</li>
    <li class='decimal'>Usługodawca ma prawo do powstrzymania się od świadczenia Usługi, w tym do jej całkowitego zaprzestania.</li>
    <li class='decimal'>Usługodawca zastrzega sobie prawo do dowolnej modyfikacji świadczonych Usług, jak i programów, systemów i aplikacji do tego wykorzystywanych bez konieczności zmiany Regulaminu.</li>
    <li class='decimal'>Użytkownik Aplikacji może korzystać z Usług świadczonych za pośrednictwem Aplikacji 7 dni w tygodniu, 24 godziny na dobę.</li>
    <li class='decimal'>Usługodawca zastrzega sobie prawo przerw w pracy Aplikacji niezbędnych do jej konserwacji lub aktualizacji.</li>
    <li class='decimal'>Użytkownik Aplikacji zobowiązany jest do korzystania z Aplikacji zgodnie z powszechnie obowiązującym prawem, zasadami współżycia społecznego oraz  warunkami korzystania z aplikacji określonymi w Regulaminie a także polityką prywatności obowiązującymi w ramach korzystania z Aplikacji.</li>
    <li class='decimal'>Użytkownik Aplikacji zobowiązany jest powiadomić Usługodawcę o każdorazowym naruszeniu jego praw w związku z korzystaniem z Aplikacji.</li>
    <li class='decimal'>Użytkownik Aplikacji ma prawo w każdej chwili zakończyć korzystanie z Aplikacji, w szczególności w sytuacji braku akceptacji przez Użytkownika Aplikacji zmiany Regulaminu, Polityki Prywatności oraz modyfikacji Aplikacji.</li>
</ul>
<p class='textaligncenter'><b>§ 4. Odpowiedzialność </b></p>
<ul>
    <li class='decimal'>Użytkownik Aplikacji zobowiązany jest do naprawienia szkody wyrządzonej Usługodawcy lub też innym Użytkownikom Aplikacji wynikającej zarówno z winy umyślnej jak i winy nieumyślnej.</li>
    <li class='decimal'>Usługodawca nie ponosi odpowiedzialności wobec Użytkowników Aplikacji ani osób trzecich za działania i zaniechania w związku ze świadczeniem Usług w Aplikacji, za wyjątkiem naruszeń spowodowanych z wyłącznej winy umyślnej Usługodawcy.</li>
    <li class='decimal'>Użytkownik Aplikacji zwolniony jest z powyższej odpowiedzialności, jeżeli niewykonanie przez niego obowiązków wynikających z Regulaminu było następstwem okoliczności, za które nie ponosi odpowiedzialności, w szczególności na skutek działania siły wyższej.</li>
    <li class='decimal'>Usługodawca nie ponosi odpowiedzialności wobec Użytkowników Aplikacji naruszających postanowienia Regulaminu za jakiekolwiek szkody powstałe na skutek zaprzestania świadczenia Usług.</li>
    <li class='decimal'>Usługodawca nie ponosi odpowiedzialności za jakiekolwiek szkody wyrządzone osobom trzecim na skutek korzystania z Aplikacji przez Użytkowników Aplikacji w sposób sprzeczny z postanowieniami niniejszego Regulaminu i przepisami prawa.</li>
    <li class='decimal'>Usługodawca nie ponosi odpowiedzialności za przeszkody w funkcjonowaniu i dostępie do Aplikacji spowodowane czynnikami zewnętrznymi, którym nie mógł przeciwdziałać ani przeszkodzić, w tym zawinionym przez Użytkowników Aplikacji lub osoby trzecie. Usługodawca nie ponosi również odpowiedzialności za szkodę wyrządzoną Użytkownikom Aplikacji na skutek okoliczności, za które odpowiedzialności nie może ponosić jak siła wyższe czy też działania i zaniechania osób trzecich, nad którymi nie sprawował bezpośredniej kontroli.</li>
</ul>
<p class='textaligncenter'><b>§ 5. Postępowanie reklamacyjne </b></p>
<ul>
    <li class='decimal'>Użytkownicy Aplikacji mają prawo składać reklamacje dotyczące świadczonych przez Usługodawcę Usług.</li>
    <li class='decimal'>Usługodawca zobowiązuje się dołożyć wszelkich starań, aby każdorazowa reklamacja, po jej prawidłowym złożeniu, została rozpoznana w terminie 14 dni od dnia jej wpływu. O rozpatrzeniu reklamacji Usługodawca zawiadomi Użytkownika Aplikacji na adres poczty elektronicznej lub poczty tradycyjnej wskazanej przez Użytkownika.</li>
    <li class='decimal'>Reklamacja musi zawierać dokładne oznaczenie osoby wnoszącej reklamację, w tym jej adres kontaktowy lub adres e-mail, opis czego reklamacja dotyczy oraz okoliczności uzasadniające reklamację.</li>
    <li class='decimal'>Reklamację należy wysłać w formie pisemnej na adres siedziby Usługodawcy: Ministerstwo Klimatu i Środowiska ul. Wawelska 52/54, 00-992 Warszawa  lub drogą elektroniczną na adres e-mail Usługodawcy <a href="mailto:info@klimat.gov.pl">info@klimat.gov.pl</a>, opatrując ją dopiskiem/tytułem “Reklamacja Gra Odkrywca Parków Narodowych”.</li>
</ul>
<p class='textaligncenter'><b>§ 6. Dane osobowe i polityka cookies </b></p>
<p>Aplikacja nie pobiera i nie przechowuje danych osobowych Użytkownika Aplikacji. Informacje dotyczące stosowania cookies w ramach Aplikacji określa Polityka Prywatności Aplikacji, stanowiąca Załącznik nr 1 do Regulaminu.</p>
<p class='textaligncenter'><b>§ 7. Licencja </b></p>
<ul>
    <li class='decimal'>Z chwilą zawarcia Umowy Usługodawca udziela Użytkownikowi licencji na korzystanie z Aplikacji wyłącznie w ramach Aplikacji. Licencja ma charakter niewyłączny, nieprzenoszalny i nieograniczony terytorialnie. Licencja na korzystanie z Aplikacji jest bezpłatna.</li>
    <li class='decimal'>Licencja udzielana jest na czas nieoznaczony, nie dłuższy jednak niż okres obowiązywania Umowy o korzystanie z Aplikacji.</li>
    <li class='decimal'>Licencja upoważnia do korzystania z Aplikacji na Urządzeniach mobilnych Użytkownika maksymalnie 5 (pięciu), bez prawa do udzielania sublicencji, na następujących polach eksploatacji:
        <ul>
            <li class='loweralpha'>wprowadzenie do pamięci (w tym pamięci RAM) Urządzenia mobilnego,</li>
            <li class='loweralpha'>utrwalenie w pamięci Urządzenia mobilnego oraz wyświetlanie w tym Urządzeniu mobilnym,</li>
            <li class='loweralpha'>tymczasowe zwielokrotnienie w pamięci Urządzenia mobilnego, niezbędne do korzystania z Aplikacji,</li>
            <li class='loweralpha'>przystosowanie Aplikacji niezbędne do korzystania z niej za pośrednictwem Urządzenia mobilnego (konfiguracja sprzętowa i programowa).</li>
        </ul>
    </li>
    <li class='decimal'>Zabronione są jakiekolwiek działania Użytkownika związane z:
        <ul>
            <li class='loweralpha'>badaniem i testowaniem funkcjonowania Aplikacji w celu poznania ich idei i zasad funkcjonowania,</li>
            <li class='loweralpha'>samodzielną modyfikacją programistyczną Aplikacji,</li>
            <li class='loweralpha'>wyodrębnieniem części składowych Aplikacji, a także jakimkolwiek ich modyfikowaniem, dekompilacją i dezasemblacją oraz ich użytkowaniem w oderwaniu od Aplikacji,</li>
            <li class='loweralpha'>uzyskiwaniem informacji, w sposób inny niż od Usługodawcy, o wewnętrznej strukturze lub zasadach działania Aplikacji,</li>
            <li class='loweralpha'>publikowaniem Aplikacji,</li>
            <li class='loweralpha'>innym niż publikowanie udostępnianiem Aplikacji osobom trzecim, w tym wynajmowaniem, wydzierżawianiem, użyczaniem lub przekazywaniem, w szczególności w celu skopiowania Aplikacji,</li>
            <li class='loweralpha'>korzystaniem z Aplikacji niezgodnie z Regulaminem, w tym wskutek podjęcia działań zakazanych Regulaminem lub obowiązującym prawem,</li>
            <li class='loweralpha'>zabronione jest reverse engineering.</li>
        </ul>
    </li>
    <li class='decimal'>Naruszenie przez Użytkownika zasad licencji uprawnia Usługodawcę do rozwiązania Umowy ze skutkiem natychmiastowym.</li>
</ul>
<p class='textaligncenter'><b>§ 8. Postanowienia końcowe </b></p>
<ul>
    <li class='decimal'>Regulamin obowiązuje bezterminowo od dnia jego wprowadzenia. Usługodawca ma prawo do zmiany Regulaminu w każdym czasie i z każdego powodu.</li>
    <li class='decimal'>Informacja o zmianie Regulaminu zostanie udostępniona Użytkownikom Aplikacji poprzez jej wyświetlenie w oknie Aplikacji. Zmiany wejdą w życie z datą wskazaną przez Usługodawcę w informacji o zmianie Regulaminu, przy czym  termin wejścia w życie zmian nie będzie krótszy niż 14 dni od dnia ich ogłoszenia.</li>
    <li class='decimal'>Regulamin korzystania z Aplikacji podlega prawu polskiemu i został sporządzony w języku polskim, który jest językiem właściwym do interpretacji jego postanowień.</li>
    <li class='decimal'>Wszelkie spory związane działaniem Aplikacji, jak i dotyczące Regulaminu rozpoznawać będzie sąd powszechny, właściwy miejscowo ze względu na siedzibę Usługodawcy.</li>
    <li class='decimal'>W sprawach nieuregulowanych Regulaminem zastosowanie mają przepisy Kodeksu cywilnego, Ustawy o świadczeniu usług drogą elektroniczną, Ustawy o prawie autorskim i prawach pokrewnych oraz inne obowiązujące przepisy prawa polskiego.</li>
    <li class='decimal'>Z Usługodawcą można się kontaktować pisemnie na adres: ul. Wawelska 52/54, 00-922 Warszawa lub drogą elektroniczną na adres e-mail: <a href="mailto:info@klimat.gov.pl">info@klimat.gov.pl</a> z dopiskiem „Gra multimedialna Odkrywca Parków Narodowych”.</li>
    <li class='decimal'>Integralną część Regulaminu stanowi Załączniki nr 1.</li>
</ul>
`;

const Statute = () => {
    const navigate = useNavigate();
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja wychodzi z ekranu
     */
     const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}>
            <div className={styles.statueContainer}>
                <MenuHeader/>
                <div className={styles.statueContent}>
                    <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000',fontWeight:'bold'}}>REGULAMIN ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ</p>
                    <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>gry pn. „Odkrywca Parków Narodowych”</p>
                    <div style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}} className={styles.description} dangerouslySetInnerHTML = {{ __html : statute }}>
                        
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Statute;

