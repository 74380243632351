/**
 * Obiekt przechowujący inofrmację o wybranej czcionce
 */
import { createSlice } from "@reduxjs/toolkit";

const fontSizeSlice = createSlice({
    name: 'fontsize',
    initialState: { 
        value: 'normal'
    },
    reducers: {
        setFontSize: (state, action) => {
            state.value = action.payload.value;
        }
    }
});

export const setFontSize = fontSizeSlice.actions.setFontSize;
export default fontSizeSlice.reducer;
