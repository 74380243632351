/**
 * Ekran prezentuje widok wybranego Parku
 */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Container from "../components/Container";
import LeftTabBar from "../components/LeftTabBar";
import styles from  '../styles/screens/ParkLeaf.module.css';
import { useDispatch, useSelector } from "react-redux";
import { LocationPointsProps, LocationProps, LocationStatusSettingsProps, ParkPointsProps, ParkProps, ParksBadgesProps, ParkStatusSettingsProps, PostCardsProps } from "../types";
import Parks from '../data/parks';
import Locations from '../data/locations';
import LocationStatusSettings from '../constans/locationstatussettings';
import Header from "../components/Header";
import ParkStatusSettings from '../constans/parkstatussettings';
import { setBadges } from "../store/redux/badges";
import ParkBadgeAlert from "../components/ParkBadgeAlert";
import CustomAlert from "../components/CustomAlert";
import { setParks } from "../store/redux/endgame";
import LocationMarkerLeaf from "../components/LocationMarkerLeaf";
import Sizes from '../constans/sizes';
import { setAllPoints, setLocationsPoints, setParksPoints, setPointsSpent } from "../store/redux/points";
import { setPostcards } from "../store/redux/postcards";

const ParkLeaf = (props:any) => {
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
     const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie trybu gry
     */
    const gamemode = useSelector((state:any) => state.gamemode.value);
    /**
     * Funkcja zwraca zapamiętane ilości punktów dla parków
     */
    const parkspoints:ParkPointsProps[] = useSelector((state:any) => state.points.parkspoints);
    /**
     * Funkcja zwraca zapamiętane ilości punktów dla parków
     */
    const badges:ParksBadgesProps[] = useSelector((state:any) => state.badges.values);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
    const locationspoints:LocationPointsProps[] = useSelector((state:any) => state.points.locationspoints);
    /**
     * Funkcja zwraca zakończone parki
     */
    const endgame = useSelector((state:any) => state.endgame.values);
    /**
     * Kupione przez gracza pocztówki
     */
    const mypostcards:PostCardsProps[] = useSelector((state:any) => state.postcards.values);
    /**
    * Funkcja zwraca wydane przez gracza punkty
    */
    const pointsspent:number = useSelector((state:any) => state.points.pointsspent);
    /**
     * Ustawienie stanów początkowych, zmienych wykorzystywanych w ekranie Parków
     */
    const dispatch = useDispatch();
    const params:any = useLocation();
    const [showResetAlert, setShowResetAlert] = useState(false);
    const [showResultAlert, setShowResultAlert] = useState(false);
    const [markers,setMarkers] = useState<any>([]);
    const [showBadgeAlert, setShowBadgeAlert] = useState(false);
    const [badgeDescription, setBadgeDescription] = useState('');
    const [badgeColor, setBadgeColor] = useState('');
    const [showEndAlert, setShowEndAlert] = useState(false);
    const idpark:number = useMemo(() => params.state.idpark,[params.state.idpark]);
    const locations:LocationProps[] = useMemo(()=>{
        const _locations:LocationProps[] = [];
        Locations.forEach((item:LocationProps) => {
            if(item.parkID===params.state.idpark) _locations.push(item);
        });
        return _locations;
    },[params.state.idpark]);
    const showResetButton = useMemo(()=>locationspoints!==undefined?locationspoints.find((item)=>item.parkID===idpark):undefined,[locationspoints,idpark]);
    const park:ParkProps | undefined = useMemo(()=>Parks.find((item:ParkProps) => item.idpark===params.state.idpark),[params.state.idpark]) as ParkProps;
    /**
     * Funkcja zarządzająca alertem kończącym zaliczenie parku
     */
     const endAlert = useCallback(() => {
        if(locationspoints!==undefined){
            var count:number = 0;
            locationspoints.forEach((item:LocationPointsProps)=>{
                if(item.parkID===params.state.idpark) count++;
            });
            if(count===locations.length){
                 const _endgame = endgame!==undefined?[...endgame]:[];
                 const find = _endgame.find(parkID=>parkID===idpark)
                 if(find===undefined){
                    setShowEndAlert(true);
                    _endgame.push(idpark);
                 } 
                dispatch(setParks({values:_endgame}));
            }
        }
    /*eslint-disable */
    },[locationspoints,locations.length,params.state.idpark,endgame,idpark]);
    /*eslint-disable */
    /**
     * Funkcja ustawia paramerty dla wybranego parku
     */
     useEffect(()=>{
        if(park!==null && park!==undefined){
            if(parkspoints!==undefined){
                const find:ParkPointsProps | undefined = parkspoints.find((item:ParkPointsProps)=>item.idpark===park.idpark);
                if(find!==undefined){
                    const status:ParkStatusSettingsProps = ParkStatusSettings.find((item:ParkStatusSettingsProps)=>(item.scale[0]*park.maxpoints)/100<find.points&&(item.scale[1]*park.maxpoints)/100>=find.points) as ParkStatusSettingsProps;
                    if(status!==undefined && status.badge){
                        const badge:ParksBadgesProps = badges.find((item:ParksBadgesProps)=>item.idpark===park.idpark && item.name===status.badgeName) as ParksBadgesProps;
                        if(badge===undefined){
                            const _badges:ParksBadgesProps[] = badges!==undefined?[...badges]:[];
                            _badges.push({idpark:park.idpark,
                                 name:status.badgeName!==undefined?status.badgeName:'',
                                 description:status.badgeDescription!==undefined?status.badgeDescription:'',
                                 color:status.color
                                }
                            );
                            dispatch(setBadges({values:_badges}));
                            setBadgeDescription(status.badgeDescription!==undefined?status.badgeDescription:'');
                            setBadgeColor(status.color);
                            setShowBadgeAlert(true);
                        }  
                    }
                    else endAlert(); 
                }
            }
        }
        
    },[params.state.idpark,gamemode,parkspoints,badges]);
    /**
     * Funkcja wyznaczająca szerokość i wysokość ścieżki lokacji
     */
     const onLoadPathContainer = useCallback(() => {
        const _path:HTMLImageElement = document.getElementById('pathElement') as HTMLImageElement;
        if(_path!==undefined && _path!==null){
            _path.style.width = "100%";
            _path.style.height = "100%";
            if(_path.offsetWidth>=_path.offsetHeight){
                _path.style.width = _path.offsetHeight.toString()+"px";
                _path.style.height = _path.offsetHeight.toString()+"px";
            }
            else{
                _path.style.width = _path.offsetWidth.toString()+"px";
                _path.style.height = _path.offsetWidth.toString()+"px";
            }
            _path.style.visibility = 'visible';
            const _markers = locations.map((location:LocationProps,index) => {
                var leaf:{src: typeof import("*.png"), x: number, y: number, width:number, height:number} | undefined;
                var status:LocationStatusSettingsProps | undefined;
                if(locationspoints!==undefined){
                    const find:LocationPointsProps | undefined = locationspoints.find((item:LocationPointsProps)=>item.idlocation===location.idlocation);
                    if(find!==undefined){
                        status = LocationStatusSettings.find(item=>(item.scale[0]*location.maxpoints)/100<find.points&&(item.scale[1]*location.maxpoints)/100>=find.points);
                        if(status!==undefined) leaf = status.locationleaf[location.order-1];
                    }
                    else {
                        status = LocationStatusSettings[0];
                        leaf = status.locationleaf[location.order-1];
                    }
                }
                else {
                    status = LocationStatusSettings[0];
                    leaf = status.locationleaf[location.order-1];
                }
                const _top = leaf!==undefined?(_path.offsetHeight*leaf.y)/park?.pathHeight + _path.offsetTop:0;
                const _left = leaf!==undefined?(_path.offsetWidth*leaf.x)/park?.pathWidth + _path.offsetLeft:0;
                const disabled:boolean = gamemode===2?false:gamemode===0 && location.roleplaying?false:gamemode===1 && location.roleplaying===false?false:true;
                const leafW:number = leaf!==undefined?(_path.offsetWidth/park?.pathWidth)*leaf.width*.65:0;
                const leafH:number = leaf!==undefined?(_path.offsetHeight/park?.pathHeight)*leaf.height*.65:0;
                return (
                    <LocationMarkerLeaf disabled={disabled} key={location.idlocation} idpark={idpark} idlocation={location.idlocation} top={_top} left={_left} parkName={park.name}
                    parkLogo={park.logo} locationName={location.name} src={leaf?.src} width={leafW} height={leafH}/>
                )
            });
            setMarkers(_markers);
        }
    },[locationspoints]);
    /**
     * Funkcja zamykająca okno odznaki
     */
    const closeBadgeAlert = () => {
        setShowBadgeAlert(false);
        endAlert();
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja odświeza drzewo
     */
    const refreshTree = (_locationspoints:LocationPointsProps[]) => {
        const _path:HTMLImageElement = document.getElementById('pathElement') as HTMLImageElement;
        if(_path!==undefined && _path!==null){
            _path.style.width = "100%";
            _path.style.height = "100%";
            if(_path.offsetWidth>=_path.offsetHeight){
                _path.style.width = _path.offsetHeight.toString()+"px";
                _path.style.height = _path.offsetHeight.toString()+"px";
            }
            else{
                _path.style.width = _path.offsetWidth.toString()+"px";
                _path.style.height = _path.offsetWidth.toString()+"px";
            }
            _path.style.visibility = 'visible';
            const _markers = locations.map((location:LocationProps,index) => {
                var leaf:{src: typeof import("*.png"), x: number, y: number, width:number, height:number} | undefined;
                var status:LocationStatusSettingsProps | undefined;
                if(_locationspoints!==undefined){
                    const find:LocationPointsProps | undefined = _locationspoints.find((item:LocationPointsProps)=>item.idlocation===location.idlocation);
                    if(find!==undefined){
                        status = LocationStatusSettings.find(item=>(item.scale[0]*location.maxpoints)/100<find.points&&(item.scale[1]*location.maxpoints)/100>=find.points);
                        if(status!==undefined) leaf = status.locationleaf[location.order-1];
                    }
                    else {
                        status = LocationStatusSettings[0];
                        leaf = status.locationleaf[location.order-1];
                    }
                }
                else {
                    status = LocationStatusSettings[0];
                    leaf = status.locationleaf[location.order-1];
                }
                const _top = leaf!==undefined?(_path.offsetHeight*leaf.y)/park?.pathHeight + _path.offsetTop:0;
                const _left = leaf!==undefined?(_path.offsetWidth*leaf.x)/park?.pathWidth + _path.offsetLeft:0;
                const disabled:boolean = gamemode===2?false:gamemode===0 && location.roleplaying?false:gamemode===1 && location.roleplaying===false?false:true;
                const leafW:number = leaf!==undefined?(_path.offsetWidth/park?.pathWidth)*leaf.width*.65:0;
                const leafH:number = leaf!==undefined?(_path.offsetHeight/park?.pathHeight)*leaf.height*.65:0;
                return (
                    <LocationMarkerLeaf disabled={disabled} key={location.idlocation} idpark={idpark} idlocation={location.idlocation} top={_top} left={_left} parkName={park.name}
                    parkLogo={park.logo} locationName={location.name} src={leaf?.src} width={leafW} height={leafH}/>
                )
            });
            setMarkers(_markers);
        }
    }
    /**
     * Funkcja resetuje osiągnięcia w parku
     */
    const reset = () => {
        const _locationpoints:LocationPointsProps[] = locationspoints!==undefined?[...locationspoints]:[];
        const remove_point = _locationpoints.filter(item=>item.parkID!==idpark);
        dispatch(setLocationsPoints({locationspoints:remove_point}));
        const _parkspoints:ParkPointsProps[] = parkspoints!==undefined?[...parkspoints]:[];
        const remove_parkspoint = _parkspoints.filter(item=>item.idpark!==idpark);
        dispatch(setParksPoints({parkspoints:remove_parkspoint}));
        const _points =  remove_parkspoint.reduce((n, {points}) => n + points, 0);
        dispatch(setAllPoints({points:_points}));
        const _badges:ParksBadgesProps[] = badges!==undefined?[...badges]:[];
        const remove_badges = _badges.filter(item=>item.idpark!==idpark);
        dispatch(setBadges({values:remove_badges}));
        const _mypostcards:PostCardsProps[] = mypostcards!==undefined?[...mypostcards]:[];
        var price:number = 0;
        _mypostcards.forEach((item:PostCardsProps)=>{
            if(item.parkID===idpark) price += item.price; 
        });
        const _pointsspent: number = pointsspent!==undefined?pointsspent:0;
        dispatch(setPointsSpent({pointsspent:_pointsspent-price}));
        const remove_mypostcards = _mypostcards.filter(item=>item.parkID!==idpark);
        dispatch(setPostcards({values:remove_mypostcards}));
        const _endgame:any[] = endgame!==undefined?[...endgame]:[];
        const remove_endgame = _endgame.filter(item=>item!==idpark);
        dispatch(setParks({values:remove_endgame}));
        setShowResetAlert(false);
        setShowResultAlert(true);
        refreshTree(remove_point);
    }

    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}} changeSize={onLoadPathContainer}>
            {showResetAlert && <CustomAlert show={showResetAlert} 
            cancel={()=>{setShowResetAlert(false)}} confirm={reset} 
            title='Uwaga!' message='CZY CHCESZ ZRESETOWAĆ OSIĄGNIĘCIA W TYM PARKU?' 
            buttonCancel='Anuluj' buttonConfirm='Potwierdź'/>}
            {showResultAlert && <CustomAlert show={showResultAlert} 
            cancel={()=>setShowResultAlert(false)}
            title='Uwaga!' message='Wszystkie dotychczasowe osiągnięcia w tym parku zostały wyczyszczone!' 
            buttonCancel='Ok'/>}
            {showBadgeAlert && <ParkBadgeAlert show={showBadgeAlert} 
            cancel={closeBadgeAlert} 
            name={badgeDescription} logo={park.logo} color={badgeColor} buttonCancel='Zamknij'/>}
            {showEndAlert && <CustomAlert show={showEndAlert} cancel={()=>setShowEndAlert(false)} 
                                title='Gratulacje' message='Wszystkie punkty w tym parku zostały zaliczone! Wróc do mapy i wybierz kolejny park.' buttonCancel='Zamknij'/>}
            <LeftTabBar idpark={idpark} logo={park.logo}/>
            <div className={styles.parkContainer}>
                <Header name={park.name}/>
                <div className={styles.pathContainer}>
                    <div>
                    <h2 style={{fontSize:20}}>Witaj w naszym parku.<br/>Zapoznaj się z przewodnikiem, a następnie kliknij w liść, aby zacząć przygodę.</h2>
                        <img id="pathElement" className={contrast==='on'?styles.pathContrast:styles.path} src={require('../assets/images/defaults/tree/tree.png')} alt="Drzewo z zaznaczonymi lokacjami w postaci liści" onLoad={onLoadPathContainer}/>
                        <h2 className="sronly">LISTA LOKACJI</h2>
                        <ul>
                            {
                                markers
                            }
                        </ul>
                    </div>
                </div>
                {showResetButton!==undefined && <h2 className={styles.heading}>
                    <button className={contrast==='on'?styles.buttonContrast:styles.button} style={{fontSize:getFontSize()+2}} onClick={()=>setShowResetAlert(true)}>
                        ZRESETUJ OSIĄGNIĘCIA
                    </button>
                </h2>}
            </div>
        </Container>
    )
}

export default ParkLeaf;