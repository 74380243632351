/**
 * Ekran wyświetlający informacje o ustawieniach gry
 */
import { useState, KeyboardEvent } from "react";
import Container from "../../components/Container";
import styles from "../../styles/screens/menu/GameSettings.module.css";
import MenuHeader from "../../components/MenuHeader";
import { useDispatch, useSelector } from "react-redux";
import { setLevelGame } from "../../store/redux/levelgame";
import CustomAlert from "../../components/CustomAlert";
import { setAllPoints, setLocationsPoints, setParksPoints, setPointsSpent } from "../../store/redux/points";
import { setLocalStorageAnimals } from "../../store/redux/animals";
import { setLocalStorageSoundsAnimals } from "../../store/redux/soundsAnimals";
import { setGameMode } from "../../store/redux/gamemode";
import { useNavigate } from "react-router-dom";
import { setSound } from "../../store/redux/sound";
import { setBadges } from "../../store/redux/badges";
import { setPostcards } from "../../store/redux/postcards";
import { setParks, setShowAlert } from "../../store/redux/endgame";
import Sizes from '../../constans/sizes';
import { setFontSize } from "../../store/redux/fontsize";
import { setContrast } from "../../store/redux/contrast";

const GameSettings = () => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie poziomu trudności
     */
    const levelgame = useSelector((state:any) => state.levelgame.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie trybu gry
     */
    const gamemode = useSelector((state:any) => state.gamemode.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [showChangeGameModeAlert, setShowChangeGameModeAlert] = useState(false);
    const [tmpGameMode, setTmpGameMode] = useState(0);
    const [sliderModeKey,setSliderModeKey] = useState((Math.random() + 1).toString(36).substring(7)); 
    const [sliderLevelKey,setSliderLevelKey] = useState((Math.random() + 1).toString(36).substring(7)); 
    /**
     * Funkcja zmieniająca ustawienie poziomy trudnośći POCZĄTKUJĄCY | NORMALNY | ZAAWANSOWANY
     * @param {number} value 
     */
    const changeLevelGame = (value:number) => {
        dispatch(setLevelGame({value}));
    }
    /**
     * Funkcja zmieniająca ustawienie trybu gry FABULARNY | EDUKACYJNY | PEŁNY
     * @param {number} value 
     */
     const changeGameMode = (value:number) => {
        setShowChangeGameModeAlert(true);
        setTmpGameMode(value);
    }
    /**
     * Funkcja wywoływana po zatwierdzeniu zmiany trybu gry, resetuje dotychczasowe osiągnięcia gracza i przechodzi do ekranu Parków
     */
    const gotoParks = () => {
        dispatch(setLocationsPoints([]));
        dispatch(setParksPoints([]));
        dispatch(setAllPoints(0));
        dispatch(setPointsSpent({pointsspent:0}));
        dispatch(setLocalStorageAnimals({values:[]}));
        dispatch(setLocalStorageSoundsAnimals({values:[]}));
        dispatch(setBadges({values:[]}));
        dispatch(setPostcards({values:[]}));
        dispatch(setParks({values:[]}));
        dispatch(setShowAlert({showAlert:1}));
        setShowChangeGameModeAlert(false);
        dispatch(setGameMode({value:tmpGameMode}));
        navigate('/');
    }
    /**
     * Funkcja anulująca zmianę trybu gry
     */
    const cancelChangeGameMode = () => {
        setShowChangeGameModeAlert(false);
        dispatch(setGameMode({value:gamemode}));
        setSliderModeKey((Math.random() + 1).toString(36).substring(7));
    }
    /**
     * Funkcja zmieniająca ustawienie poziomy trudnośći POCZĄTKUJĄCY | NORMALNY | ZAAWANSOWANY
     * @param {number} value 
     */
    const changeLevelGameTextClick = (value:number) => {
        setSliderLevelKey((Math.random() + 1).toString(36).substring(7));
        dispatch(setLevelGame({value}));
    }
    /**
     * Funkcja zmieniająca ustawienie dźwięku w grze WŁĄCZONY | WYŁĄCZONY
     */
    const onChangeSound = (event:any) => {
        if(event!==undefined && event!==null) dispatch(setSound({value:event.target.value}));
    }
    /**
     * Funkcja zmieniająca wielokść czcionki
     */
    const changeFontSize = (value:string) => {
        dispatch(setFontSize({value}));
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>,value:string) => {
        if(event.key === 'Enter'){
            event.preventDefault(); 
            changeFontSize(value); 
        }  
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja zmieniająca kontrast
     */
    const changeContrast = (value:string) => {
        dispatch(setContrast({value}));
    }
    const onKeyDownContrast = (event:KeyboardEvent<HTMLElement>,value:string) => {
        if(event.key === 'Enter'){
            event.preventDefault(); 
            changeContrast(value); 
        }  
    }
    /**
     * Funkcja wychodzi z ekranu
     */
    const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}>
            <div className={styles.settingsContainer}>
            {showChangeGameModeAlert && <CustomAlert show={showChangeGameModeAlert} cancel={cancelChangeGameMode} confirm={gotoParks} title='Uwaga!' 
            message={'Zmiana trybu gry wiąże się z utratą wszystkich dotychczasowych osiągnięć!\n\nCzy chcesz zmienić?'} buttonCancel='Anuluj' buttonConfirm='Zmień'/>}
                <MenuHeader/>
                <div className={styles.settingsContent}>
                    <p className={styles.title} style={{fontSize:getFontSize()+4}}>USTAWIENIA GRY</p>
                    <div className={styles.levelGameContainer}>
                        <p className={contrast==='on'?styles.headerContrast:styles.header} style={{fontSize:getFontSize()+2}}>Wybierz poziom trudności</p>
                        <div className={contrast==='on'?styles.sliderContainerContrast:styles.sliderContainer}>
                            <label id='levelgameLabel' htmlFor='levelgame' className={styles.sronly}>Poziom trudności</label>
                            <input id='levelgame' aria-labelledby="levelgameLabel" className={contrast==='on'?styles.rangeContrast:styles.range} key={sliderLevelKey} type="range" name="levelgame" min="0" step="1" max="2" defaultValue={levelgame} onChange={(event)=>changeLevelGame(parseInt(event.target.value))}
                            aria-valuetext="0 - podstawowy, 1 - średniozaawansowany, 2 - zaawansowany"/>
                            <div className={styles.levelgameText}>
                                <p className={contrast==='on'?styles.textContrast:styles.text} style={{fontSize:getFontSize()}} onClick={()=>changeLevelGameTextClick(0)}>podstawowy</p>
                                <p className={contrast==='on'?styles.textContrast:styles.text} style={{paddingLeft:20,fontSize:getFontSize()}} onClick={()=>changeLevelGameTextClick(1)}>średniozaawansowany</p>
                                <p className={contrast==='on'?styles.textContrast:styles.text} style={{fontSize:getFontSize()}} onClick={()=>changeLevelGameTextClick(2)}>zaawansowany</p>
                            </div>
                        </div>
                        <p className={contrast==='on'?styles.headerContrast:styles.header} style={{fontSize:getFontSize()+2}}>Wybierz tryb gry</p>
                        <div className={contrast==='on'?styles.sliderContainerContrast:styles.sliderContainer}>
                            <label id='gamemodeLabel' htmlFor='gamemode' className={styles.sronly}>Tryb gry</label>
                            <input id='gamemode' aria-labelledby="gamemodeLabel" className={contrast==='on'?styles.rangeContrast:styles.range} key={sliderModeKey} type="range" name="gamemode" min="0" step="1" max="2" defaultValue={gamemode} onChange={(event)=>changeGameMode(parseInt(event.target.value))}
                            aria-valuetext="0 - fabularny, 1 - edukacyjny, 2 - pełny"/>
                            <div className={styles.levelgameText}>
                                <p className={contrast==='on'?styles.textContrast:styles.text} style={{fontSize:getFontSize()}} onClick={()=>changeGameMode(0)}>fabularny</p>
                                <p className={contrast==='on'?styles.textContrast:styles.text} style={{paddingRight:20,fontSize:getFontSize()}} onClick={()=>changeGameMode(1)}>edukacyjny</p>
                                <p className={contrast==='on'?styles.textContrast:styles.text} style={{fontSize:getFontSize()}} onClick={()=>changeGameMode(2)}>pełny</p>
                            </div>
                        </div>
                        <p className={contrast==='on'?styles.headerContrast:styles.header} style={{fontSize:getFontSize()+2}}>Dźwięk w grze</p>
                        <div className={styles.radioGroup}>
                            <label className={styles.radio}>
                                <input className={contrast==='on'?styles.circleContrast:styles.circle}  type="radio" id="1" value="on" name="sound" checked={sound==='on'} onChange={onChangeSound}/>
                                <span className={contrast==='on'?styles.spanContrast:styles.span} style={{fontSize:getFontSize()}}>Włączony</span>
                            </label>
                            <label className={styles.radio}>
                                <input className={contrast==='on'?styles.circleContrast:styles.circle}  type="radio" id="2" value="off" name="sound" checked={sound==='off'} onChange={onChangeSound}/>
                                <span className={contrast==='on'?styles.spanContrast:styles.span} style={{fontSize:getFontSize()}}>Wyłączony</span>
                            </label>
                        </div>
                        <p className={contrast==='on'?styles.headerContrast:styles.header} style={{fontSize:getFontSize()+2}}>Czcionka</p>
                        <div className={styles.fonts}>
                            <button className={contrast==='on'?styles.aContrast:styles.a} onClick={()=>changeFontSize('normal')} onKeyDown={(event)=>onKeyDown(event,'normal')}><span className="sronly">Ustaw domyślną czcionkę</span>A<sup></sup></button>
                            <button className={contrast==='on'?styles.apContrast:styles.ap} onClick={()=>changeFontSize('medium')} onKeyDown={(event)=>onKeyDown(event,'medium')}><span className="sronly">Ustaw większą czcionkę</span>A<sup>+</sup></button>
                            <button className={contrast==='on'?styles.appContrast:styles.app} onClick={()=>changeFontSize('big')} onKeyDown={(event)=>onKeyDown(event,'big')}><span className="sronly">Ustaw największą czcionkę</span>A<sup>++</sup></button>
                        </div>
                        <p className={contrast==='on'?styles.headerContrast:styles.header} style={{fontSize:getFontSize()+2}}>Kontrast</p>
                        <div className={styles.fonts}>
                            <button className={contrast==='on'?styles.contrastoffContrast:styles.contrastoff} style={{fontSize:getFontSize()+2}} onClick={()=>changeContrast('off')} onKeyDown={(event)=>onKeyDownContrast(event,'off')}><span className="sronly">Ustaw kontrast domyślny</span>A</button>
                            <button className={contrast==='on'?styles.contrastonContrast:styles.contraston} style={{fontSize:getFontSize()+2}} onClick={()=>changeContrast('on')} onKeyDown={(event)=>onKeyDownContrast(event,'on')}><span className="sronly">Ustaw kontrast, biały tekst na czarnym</span>A</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </Container>
    )
}

export default GameSettings;

