import { useEffect, useMemo, useState, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../styles/screens/questions/Open.module.css';
import { AnswersConfigProps, LocationPointsProps, OpenQuestionProps } from '../../types';
import Questions from '../../data/questions';
import SoundEffect from '../../components/SoundEffect';
import LevelPoints from '../../constans/levelpoints';
import { setPoints } from '../../store/redux/points';
import Container from '../../components/Container';
import LeftTabBar from '../../components/LeftTabBar';
import Header from '../../components/Header';
import next from '../../assets/icons/next.png';
import Sizes from '../../constans/sizes';
import AlertNoTitle from '../../components/AlertNoTitle';
import _imgchat from '../../assets/images/defaults/chat.png';

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

const Open = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie poziomu trudności
     */
    const levelgame = useSelector((state:any) => state.levelgame.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie trybu gry
     */
    const gamemode = useSelector((state:any) => state.gamemode.value);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
    const locationspoints:LocationPointsProps[] = useSelector((state:any) => state.points.locationspoints);
    const dispatch = useDispatch();
    const params:any = useLocation();
    const navigate = useNavigate();
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [title, setTitle] = useState('');
    const [questiondescription, setQuestionDescription] = useState('');
    const [_img, setImg] = useState<any>('');
    const [alt, setAlt] = useState<any>('');
    const [answer, setAnswer] = useState<string>('');
    const [answersconfig, setAnswersconfig] = useState<AnswersConfigProps[]>();
    const [hint, setHint] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [badAnswerMessage, setBadAnswerMessage] = useState('');
    const [goodAnswerMessage, setGoodAnswerMessage] = useState('');
    const [resultAnswer, setResultAnswer] = useState(false);
    const [showBackAlert, setShowBackAlert] = useState(false);
    const [showEndAlert, setShowEndAlert] = useState(false);
    const [leavePage, setLeavePage] = useState(false);
    const [addPoints, setAddPoints] = useState(true);
    const [nextquestion, setNextQuestion] = useState<{idquestion:number | undefined, type:string | undefined} | undefined>();
    const totalPoints:number = useMemo(()=>(params.state.points!==undefined?params.state.points:0),[params.state.points]);
    const nextquestions:{idquestion:number,type:string}[] = useMemo(()=>(params.state.nextquestion),[params.state.nextquestion]);
    const idpark:number = useMemo(()=>(params.state.idpark),[params.state.idpark]);
    const idlocation:number = useMemo(()=>(params.state.idlocation),[params.state.idlocation]);
    const parkName:string = useMemo(()=>(params.state.parkName),[params.state.parkName]);
    const logo:any = useMemo<any>(()=>(params.state.parkLogo),[params.state.parkLogo]);
    /**
     * Funkcja ustawiąjąca parametry dla wybranego pytania
     */
    useEffect(()=>{
        const openquestion:OpenQuestionProps | undefined = Questions.find(item=>item.idquestion===params.state.idquestion) as OpenQuestionProps | undefined;
        if(openquestion!==undefined){
            setTitle(openquestion.title);
            setQuestionDescription(openquestion?.questiondescription!==undefined?openquestion.questiondescription:'');
            setImg(openquestion.src);
            setAlt(openquestion.alt);
            setAnswersconfig(openquestion.answersconfig);
            if(openquestion.hints!==undefined){
                const element:any = openquestion.hints.find((item:{levelgame:number,hint:string})=>item.levelgame===levelgame);
                if(element !== undefined) setHint(element.hint)
            }
            setBadAnswerMessage(openquestion.badanswermessage);
            setGoodAnswerMessage(openquestion.goodanswermessage);
            setAddPoints(openquestion.addPoints===undefined?true:openquestion.addPoints);
        }
    },[params.state.idquestion,levelgame]);
    /**
     * Funkcja wywoływana po potwierdzeniu wyjścia z pytania, przekierowywuje do ekranu lokacji 
     */
    const back = () => {
        setLeavePage(true);
        setShowBackAlert(false);
        navigate(-1);
    }
    /**
     * Funkcja wywoływana po naciśnięciu guzika "Sprawdź/Dalej", sprawdza czy odpowiedź jest prawidłowa
     */
    const check = () => {
        if(answersconfig && answersconfig.length>0){
            const result:AnswersConfigProps | undefined = answersconfig.find((item:AnswersConfigProps)=>item.answer!==undefined?item.answer.toLowerCase()===answer.trim().toLowerCase() && item.correct===true:false);
            var answerconfig;
            if(result===undefined){
                 answerconfig = answersconfig?.find((item:AnswersConfigProps)=>item.correct===false);
            }
            else answerconfig = result;
            const _nextquestion = {idquestion:answerconfig?.idquestion,type:answerconfig?.type};
            setNextQuestion(_nextquestion);
            setResultAnswer(result!==undefined && result.correct);
            setMessageAlert(result!==undefined && result.correct?goodAnswerMessage:badAnswerMessage);
            if(sound!==undefined && sound==='on') SoundEffect(result!==undefined && result.correct?'good':'bad');
            setShowAlert(true);
        }
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            check();
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu poprawnej lub błędnej odpowiedzi
     */
     const confirmResult = () =>{
        setShowAlert(false);
        if(resultAnswer) nextStep();
        else if(!resultAnswer && nextquestion?.idquestion!==undefined) nextStep(false);
    }
    /**
     * Funkcja ustawia kolejne pytania lub wywołuje funkcję zapisująca punkty i wychodzącą z wyzwania
     */
     const nextStep = (_goodanswer:boolean=true) => {
        const points = LevelPoints && LevelPoints.length>0 && levelgame!==undefined?addPoints?_goodanswer?LevelPoints[levelgame]+totalPoints:totalPoints:totalPoints:totalPoints;
        if(nextquestion!==undefined && nextquestion.idquestion!==undefined && nextquestion.type!==undefined) gotoNextQuestion(nextquestion.idquestion,nextquestion.type,nextquestions,points);
        else if(nextquestions!==undefined){
            let firstquestion:{idquestion:number,type:string} = nextquestions[0];
            let _nextquestions:{idquestion:number,type:string}[] = [];
            nextquestions.forEach((item:{idquestion:number,type:string},index:number)=>{
                if(index!==0) _nextquestions.push(item);
            });
            if(firstquestion!==undefined && firstquestion!==null) gotoNextQuestion(firstquestion.idquestion,firstquestion.type,_nextquestions,points);
            else savePoints(points);
        }
        else savePoints(points);
    }
    /**
     * Funkcja przekierowywuje do następnego pytania
     * @param {number} _idquestion ID pytania
     * @param {string} _type typ pytania
     * @param {{idquestion:number,type:string}[]} _nextquestions następne pytania
     * @param {number} _points zdobyte punkty
     */
    const gotoNextQuestion = (_idquestion:number,_type:string,_nextquestions:{idquestion:number,type:string}[],_points:number) => {
        if(sound!==undefined && sound==='on') SoundEffect('nextQuestion');
        const pathname = params.pathname.replace("open","") + _type;
        navigate(pathname,{
            replace:true,
            state:{
                idquestion:_idquestion,nextquestion:_nextquestions,idlocation:idlocation,points:_points,idpark:idpark,parkName:parkName,parkLogo:logo
            }
        });
    }
    /**
     * Funkcja aktualizuje zdobyte przez gracza punkty
     * @param {number} points punkty zdobyte dotej pory przez gracza 
     */
     const savePoints = (points:number) => {
        setShowEndAlert(true);
        if(points){
            const _locationpoints:LocationPointsProps[] = locationspoints!==undefined?[...locationspoints]:[];
            const index:number = _locationpoints.findIndex((item:LocationPointsProps) => item.idlocation === idlocation);
            if (index !== -1) _locationpoints[index] = { idlocation:idlocation, points:_locationpoints[index].points+points, parkID:idpark,gamemode:gamemode};
            else _locationpoints.push({idlocation,points,parkID:idpark,gamemode})
            dispatch(setPoints({locationspoints:_locationpoints}));
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu o ukończeniu wyzwania, przekierowywuje do ekranu parku
     */
    const goPark = () => {
        setLeavePage(true);
        setShowEndAlert(false);
        navigate(-2);
    }
    /**
     * Funkcja przechwytująca naciśnięcie przycisku wstecz w przeglądarce
     */
    window.onpopstate = () => {
        if(!leavePage && params.pathname==='/park/location/open'){
             navigate(1);
             setShowBackAlert(true);
        }
        
    };
    /**
         * Funkcja zwraca rozmiar czcionki
         */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            {showBackAlert && <AlertNoTitle show={showBackAlert} cancel={()=>{setShowBackAlert(false)}} confirm={back} 
            message='Chcesz opuścić wyzwanie?' buttonCancel='Anuluj' buttonConfirm='Opuść'/>}
            {showAlert && <AlertNoTitle show={showAlert} cancel={()=>{confirmResult()}} message={messageAlert} buttonCancel='OK'/>}
            {showEndAlert && <AlertNoTitle show={showEndAlert} confirm={goPark} message='Gratulacje! Wyzwanie zakończone!' buttonConfirm='Wróć na szlak'/>}
            <LeftTabBar idpark={idpark} logo={logo} visibilityButtons='hidden'/>
            <div className={styles.questionContainer}>  
                <Header name="ODPOWIEDŹ" menuVisibility='hidden'/>
                <div className={styles.questionContent}>
                    <div className={styles.questionContentContainer}>
                        <div className={styles.imgContainer}>
                            <img className={contrast==='on'?styles.imgContrast:styles.img} src={_img} alt={alt}/>
                        </div>
                        <div className={styles.openQuestionContainer}>
                            <div className={styles.openQuestionContentContainer}>
                                {title && title!==''?<div className={styles.title} style={{fontSize:getFontSize()+10,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : parser(title) }}/>:null}
                                {questiondescription && questiondescription!==''?<div className={styles.imessage} style={{fontSize:getFontSize()+10,color:contrast==='on'?'#fff':'#000'}}>
                                    <p className={styles.fromthem}><img src={_imgchat} style={{width:'40px'}} alt="rozmowa"/><br/>{parser(questiondescription)}</p>
                                    </div>
                                :null
                                }
                                {hint && hint!==''?<div className={styles.hint} style={{fontSize:getFontSize()+2,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : parser(hint) }}/>:null}
                                <label htmlFor="answeropen" className="sronly">Podaj odpowiedź</label>
                                <input id='answeropen' className={contrast==='on'?styles.answerContrast:styles.answer} placeholder='Podaj odpowiedź...' style={{fontSize:getFontSize()+8,marginBottom:5}} value={answer} 
                                onChange={(event)=>setAnswer(event.target.value)} onKeyDown={onKeyDown} maxLength={50}/>
                            </div>
                            <button className={contrast==='on'?styles.checkContrast:styles.check} onClick={check} onKeyDown={onKeyDown}>
                                <p className={styles.checkText} style={{fontSize:getFontSize()+10}}>SPRAWDŹ / DALEJ</p>
                                <img className={styles.next} src={next} alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Open;
