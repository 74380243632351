/**
 * Punkty przyznawane za pytanie 
 * [0] - początkujący
 * [1] - średniozaawansowany
 * [2] - zaawansowany
 */
const levelpoints = [
    60,
    80,
    100
]

export default levelpoints;