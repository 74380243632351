/**
 * Komponent nagłowka menu
 */
import { KeyboardEvent } from 'react';
import styles from '../styles/components/Header.module.css';
import menu from '../assets/icons/menu_white.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SoundEffect from './SoundEffect';
import Sizes from '../constans/sizes';
import volumn_on_white from "../assets/icons/volume_on_white.svg";
import volumn_off_white from "../assets/icons/volume_off_white.svg";
import { setSound } from "../store/redux/sound";

const Header = (props:{name:string,color?:string,menuVisibility?:DocumentVisibilityState}) => {
    const dispatch = useDispatch();
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
     const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych
     */
    const navigate = useNavigate();
    /**
     * Funkcja wywołyjąca strone menu
     */
    const gotoMenu = () => {
        if(sound!==undefined && sound==='on') SoundEffect('menuButton');
        navigate('menu');
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            if(sound!==undefined && sound==='on') SoundEffect('menuButton');
            event.preventDefault(); 
            navigate('menu');
        }        
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja włącza | wyłącza dźwięk
     */
     const onoffSound = () => {
        if(sound!==undefined && sound==='on') SoundEffect('menuButton');
        dispatch(setSound({value:sound==='on'?'off':'on'}));
    }
    const onKeyDownOnOffSound = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            if(sound!==undefined && sound==='on') SoundEffect('menuButton');
            event.preventDefault(); 
            dispatch(setSound({value:sound==='on'?'off':'on'}));
        } 
    }
    return (
        <div className={styles.conatiner} style={{backgroundColor:contrast==='on'?'#1a1919':props?.color===undefined?'#3d836d':props.color}}>
           <p className={styles.name} style={{fontSize:getFontSize()+10,color:contrast==='on'||props.color==='#2c7187'?'#fff':'#000'}}>{props.name}</p>
           <div>
                {sound==='on'?
                    <button className={contrast==='on'?styles.closeContrast:styles.close} style={{marginRight:10,visibility:props.menuVisibility===undefined?'visible':props.menuVisibility}} onClick={onoffSound} onKeyDown={onKeyDownOnOffSound}> 
                        <h2 style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img className={styles.closeImage} src={volumn_on_white} alt="WYŁĄCZ DŹWIĘK"></img>
                        </h2>
                    </button>
                    :
                    <button className={contrast==='on'?styles.closeContrast:styles.close} style={{marginRight:10,visibility:props.menuVisibility===undefined?'visible':props.menuVisibility}} onClick={onoffSound} onKeyDown={onKeyDownOnOffSound}> 
                        <h2 style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img className={styles.closeImage} src={volumn_off_white} alt="WŁĄCZ DŹWIĘK"></img>
                        </h2>
                    </button>
                }
                <button className={contrast==='on'?styles.menuContrast:styles.menu} style={{visibility:props.menuVisibility===undefined?'visible':props.menuVisibility}} onClick={gotoMenu} onKeyDown={onKeyDown}>
                    <h2 style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <img className={styles.menuImage} src={menu} alt="PRZEJDŹ DO MENU"/>
                    </h2>
                </button>
           </div>
        </div>
    );
}

export default Header;