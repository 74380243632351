/**
 * Komponent wyświetlający element FAQ
 */
import { useNavigate } from "react-router-dom";
import styles from '../styles/components/FAQItem.module.css';
import { FAQItemProps } from "../types";
import next from '../assets/icons/next_orange.svg';
import next_contrast from '../assets/icons/next_white.svg';
import { useSelector } from "react-redux";
/**
 * 
 * @param {FAQItemProps} props 
 * @returns zwraca element FAQ listy
 */
const FAQItem = (props:FAQItemProps) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
     const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych
     */
    const navigate = useNavigate();
    /**
     *Funkcja przekierowuje do wybranego ekranu menu
     */ 
    const onClick = () => {
        navigate('description',{
            state:{
                name:props.name,
                description:props.description
            }
        });
    }

    return (
        <li>
            <h3 style={{margin:0}}>
                <button className={contrast==='on'?styles.containerContrast:styles.container} onClick={onClick}>
                    <p className={contrast==='on'?styles.itemNameContrast:styles.itemName} style={{fontSize:props.fontSize}}>{props.name}</p>
                    <img className={styles.next} src={contrast==='on'?next_contrast:next} alt=""/>
                </button>
            </h3>
        </li>
    )
}

export default FAQItem;