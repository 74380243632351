/**
 * Ekran resetujący dotychczasowe osiągnięcia gracza w grze
 */
import { useState } from "react";
import Container from "../../components/Container";
import styles from "../../styles/screens/menu/ResetGame.module.css";
import MenuHeader from "../../components/MenuHeader";
import { useDispatch, useSelector } from "react-redux";
import { setAllPoints, setLocationsPoints, setParksPoints, setPointsSpent } from "../../store/redux/points";
import { setLocalStorageAnimals } from "../../store/redux/animals";
import { setLocalStorageSoundsAnimals } from "../../store/redux/soundsAnimals";
import CustomAlert from "../../components/CustomAlert";
import { setBadges } from "../../store/redux/badges";
import { setPostcards } from "../../store/redux/postcards";
import { setParks, setShowAlert } from "../../store/redux/endgame";
import Sizes from '../../constans/sizes';
import { useNavigate } from "react-router-dom";

const ResetGame = () => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
     const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [showResetAlert, setShowResetAlert] = useState(false);
    const [showResultAlert, setShowResultAlert] = useState(false);
    /**
     * Funkcja resetuje dotychczasowe osiągnięcia gracza
     */
    const resetGame = () => {
        dispatch(setLocationsPoints([]));
        dispatch(setParksPoints([]));
        dispatch(setAllPoints(0));
        dispatch(setLocalStorageAnimals({values:[]}));
        dispatch(setLocalStorageSoundsAnimals({values:[]}));
        dispatch(setBadges({values:[]}));
        dispatch(setPostcards({values:[]}));
        dispatch(setParks({values:[]}));
        dispatch(setShowAlert({showAlert:1}));
        dispatch(setPointsSpent({pointsspent:0}));
        setShowResetAlert(false);
        setShowResultAlert(true);
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja przekierowywuje do głównego okna
     */
    const gotoParks = () =>{
        setShowResultAlert(false);
        navigate('/');
    }
    /**
     * Funkcja wychodzi z ekranu
     */
     const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}>
            {showResetAlert && <CustomAlert show={showResetAlert} 
            cancel={()=>{setShowResetAlert(false)}} confirm={resetGame} 
            title='Uwaga!' message='CZY CHCESZ ZRESETOWAĆ GRĘ?' 
            buttonCancel='Anuluj' buttonConfirm='Potwierdź'/>}
            {showResultAlert && <CustomAlert show={showResultAlert} 
            cancel={gotoParks}
            title='Uwaga!' message='Gra została zresetowana, wszystkie dotychczasowe osiągnięcia zostały wyczyszczone!' 
            buttonCancel='Ok'/>}
            <div className={styles.resetContainer}>
                <MenuHeader/>
                <div className={styles.resetContent}>
                    <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>RESETOWANIE GRY</p>
                    <div className={styles.description}>
                        <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>Uwaga! Zresetowanie gry spowoduje utratę wszystkich dotychczasowych osiągnięć!</p>
                        <button className={contrast==='on'?styles.buttonContrast:styles.button} style={{fontSize:getFontSize()+6}} onClick={()=>setShowResetAlert(true)}>
                            ZRESETUJ GRĘ
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ResetGame;

