import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import './App.css';
import AppRouter from './routes/AppRouter';
import AppStore from './screens/Store';
import { store } from './store/redux/store';
import CookieConsent from "react-cookie-consent";
import { useLocation } from 'react-router-dom';

function App() {
  
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(/*Math.min(window.screen.width, window.screen.height) < 768 || */navigator.userAgent.indexOf("Mobi") > -1);
  const handleWindowResize = () => {
    setIsMobile(/*Math.min(window.screen.width, window.screen.height) < 768 || */navigator.userAgent.indexOf("Mobi") > -1);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className="App">
        <h1 className="sronly">ODKRYWCA PARKÓW NARODOWYCH</h1>
        <Provider store={store}>
          {!isMobile?<AppRouter/>:<AppStore/>}
        </Provider> 
        <CookieConsent visible={location.pathname.indexOf('/privacypolice') !== -1?'hidden':'byCookieValue'} buttonText="Rozumiem" buttonStyle={{ color: "#000",borderRadius:5,backgroundColor:"#3d836d" }}
        >Strona odkrywcaparkownarodowych.pl używa plików cookies, niezbędnych do prawidłowego działania. Strona odkrywcaparkownarodowych.pl nie zbiera, i nie przetwarza żadnych danych osobowych. Kontynuując jej przeglądanie wyrażasz zgodę na ich używanie.
          <br/>Przejdź do strony 
					<a href="https://odkrywcaparkownarodowych.pl/menu/privatepolicy" style={{color:'#fff',marginLeft:5}}>
						polityka prywatności
					</a>
        </CookieConsent>
    </div>
  );
}

export default App;
