/**
 * Komponent prezentujący listę zwierząt odnalezionych przez gracza
 */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AnimalPhotoProps } from "../types";
import styles from '../styles/components/Photos.module.css';
import animals from '../data/animals';
import { useNavigate } from "react-router-dom";
import Sizes from '../constans/sizes';
import Colors from "../constans/colors";

const Photos = (props:any) => {
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
     const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Ustawienie stanów początkowych
     */
    const navigate = useNavigate();
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Odnalezione przez gracza zwierzęta
     */
     //const myanimals = useSelector((state:any) => state.animals.values);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja wywoływana po kliknięciu w zwierzę/roślinę
     */
    const onClick = (item:AnimalPhotoProps) => {
        navigate('spieces',{
            state:{
                name:item.name,
                description:item.description,
                src:item.src,
                alt:item.alt,
                idpark:props.idpark,
                logo:props.logo
            }
        });
    }
    /*const available = (item:AnimalPhotoProps) =>{
        const find = myanimals.find((element:any)=>element.id===item.id);
        return item.available || find!==undefined;
    }*/
    const available = (item:AnimalPhotoProps) =>{
        return true;
    }
    const _animals:any[] = useMemo(()=>animals.map((item:AnimalPhotoProps)=>{
    return <li key={item.id}>
                <h3 style={{margin:0}}>
                    <button onClick={()=>onClick(item)} className={contrast==='on'?styles.btContrast:styles.bt} >
                        <img className={contrast==='on'?styles.photoContrast:styles.photo} src={item.src} alt={item.alt} style={{filter:available(item)?undefined:'grayscale(1)',
                        }}/>
                        <p style={{color:contrast==='on'?'#fff':available(item)?Colors.primary:'#808080',fontSize:getFontSize()}}>{item.name}</p>
                    </button>
                </h3>
            </li> 
    /*eslint-disable */
    }),[contrast]);
    /*eslint-disable */
     return (
         <div id="photos" className={styles.photoContainer} style={{outlineColor:contrast==='on'?'#ffff00':'#f08733'}} tabIndex={0}>
             <ul className={styles.ul}>
             <h2 className="sronly">LISTA ZROBIONYCH ZDJĘĆ</h2>
             {
                 _animals
             }
             </ul>
         </div>
     );
}

export default Photos;