
/**
 * Pamięć lokalna
 */
/**
 * Funkcja zapisująca stany zmienyych w pamięci lokalnej
 * @param {any} state 
 */
export const saveToLocalStorage = (state:any) => {
    try {
      const serialisedState = JSON.stringify(state);
      localStorage.setItem("appState", serialisedState);
    } catch (e) {
      console.log(e);
    }
}
/**
 * Funkcja odczytująca stany zmiennych z pamięci lokalnej
 * @returns zwraca aktualne stany zmiennych
 */
export const loadFromLocalStorage = async () => {
    try {
      const serialisedState:any = await localStorage.getItem("appState");
      if (serialisedState === null) return undefined;
      return JSON.parse(serialisedState);
    } catch (e) {
      console.log(e);
      return undefined;
    }
}