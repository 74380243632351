import { KeyboardEvent, useEffect, useMemo, useState, DragEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import next from '../../assets/icons/next.png';
import trashcan_plastic from '../../assets/images/trash/plastic/trashcan_plastic.png';
import trashcan_paper from '../../assets/images/trash/paper/trashcan_paper.png';
import trashcan_glass from '../../assets/images/trash/glass/trashcan_glass.png';
import trashcan_bio from '../../assets/images/trash/bio/trashcan_bio.png';
import trashcan_confused from '../../assets/images/trash/confused/trashcan_confused.png';
import Container from '../../components/Container';
import Header from '../../components/Header';
import LeftTabBar from '../../components/LeftTabBar';
import SoundEffect, { pause } from '../../components/SoundEffect';
import Timer, { clearTimer } from '../../components/Timer';
import LevelPoints from '../../constans/levelpoints';
import Questions from '../../data/questions';
import { setPoints } from '../../store/redux/points';
import styles from '../../styles/screens/questions/Trash.module.css';
import { AnswersConfigProps, LocationPointsProps, TrashPoistionProps, TrashQuestionItemProps, TrashQuestionProps } from '../../types';
import TrashMarker from '../../components/TrashMarker';
import Sizes from '../../constans/sizes';
import AlertNoTitle from '../../components/AlertNoTitle';

function shuffle(array:any) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

var draggabledElement:HTMLImageElement;

const Trash = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie poziomu trudności
     */
    const levelgame = useSelector((state:any) => state.levelgame.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie trybu gry
     */
    const gamemode = useSelector((state:any) => state.gamemode.value);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
    const locationspoints:LocationPointsProps[] = useSelector((state:any) => state.points.locationspoints);
    const dispatch = useDispatch();
    const params:any = useLocation();
    const navigate = useNavigate();
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [title, setTitle] = useState('');
    const [trash,setTrash] = useState<any>([]);
    const [[plasticX,plasticY,plasticWidth,plasticHeight], setImagePlasticPosition] = useState([0,0,0,0]);
    const [[paperX,paperY,paperWidth,paperHeight], setImagePaperPosition] = useState([0,0,0,0]);
    const [[glassX,glassY,glassWidth,glassHeight], setImageGlassPosition] = useState([0,0,0,0]);
    const [[bioX,bioY,bioWidth,bioHeight], setImageBioPosition] = useState([0,0,0,0]);
    const [[confusedX,confusedY,confusedWidth,confusedHeight], setImageConfusedPosition] = useState([0,0,0,0]);
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState<any>('');
    const [resultAnswer, setResultAnswer] = useState(false);
    const dropedTrashes = useMemo<any>(()=>[],[]);
    const trashquestion:TrashQuestionProps | undefined = useMemo(()=>Questions.find(item=>item.idquestion===params.state.idquestion),[params.state.idquestion]) as TrashQuestionProps | undefined;
    const _time = useMemo(()=>trashquestion?.time[levelgame],[trashquestion,levelgame]);
    const badAnswerMessage = useMemo(()=>trashquestion?.badanswermessage,[trashquestion]);
    const goodAnswerMessage = useMemo(()=>trashquestion?.goodanswermessage,[trashquestion]);
    const [startTimer, setStartTimer] = useState(false);
    const [showBackAlert, setShowBackAlert] = useState(false);
    const [showEndAlert, setShowEndAlert] = useState(false);
    const [leavePage, setLeavePage] = useState(false);
    const [addPoints, setAddPoints] = useState(true);
    const [answersconfig, setAnswersconfig] = useState<AnswersConfigProps[] | undefined>([]);
    const [nextquestion, setNextQuestion] = useState<{idquestion:number | undefined, type:string | undefined} | undefined>();
    const totalPoints:number = useMemo(()=>(params.state.points!==undefined?params.state.points:0),[params.state.points]);
    const nextquestions:{idquestion:number,type:string}[] = useMemo(()=>(params.state.nextquestion),[params.state.nextquestion]);
    const idpark:number = useMemo(()=>(params.state.idpark),[params.state.idpark]);
    const idlocation:number = useMemo(()=>(params.state.idlocation),[params.state.idlocation]);
    const parkName:string = useMemo(()=>(params.state.parkName),[params.state.parkName]);
    const logo:any = useMemo<any>(()=>(params.state.parkLogo),[params.state.parkLogo]);
    /**
     * Funkcja ustawiająca współrzędna X, Y szerokość i wysokość kosza na śmieci plastikowe
     */
     const onLayoutImagePlastic = () => {
        const element:HTMLImageElement = document.getElementById('plastic') as HTMLImageElement;
        if(element!==null && element!==undefined) setImagePlasticPosition([element.offsetLeft,element.offsetTop,element.offsetWidth,element.offsetHeight]);
    }
    /**
     * Funkcja ustawiająca współrzędna X, Y szerokość i wysokość kosza na śmieci papierowe
     */
    const onLayoutImagePaper = () => {
        const element:HTMLImageElement = document.getElementById('paper') as HTMLImageElement;
        if(element!==null && element!==undefined) setImagePaperPosition([element.offsetLeft,element.offsetTop,element.offsetWidth,element.offsetHeight]);
    }
    /**
     * Funkcja ustawiająca współrzędna X, Y szerokość i wysokość kosza na śmieci szklanych
     */
    const onLayoutImageGlass = () => {
        const element:HTMLImageElement = document.getElementById('glass') as HTMLImageElement;
        if(element!==null && element!==undefined) setImageGlassPosition([element.offsetLeft,element.offsetTop,element.offsetWidth,element.offsetHeight]);
    }
    /**
     * Funkcja ustawiająca współrzędna X, Y szerokość i wysokość kosza na śmieci bio
     */
    const onLayoutImageBio = () => {
        const element:HTMLImageElement = document.getElementById('bio') as HTMLImageElement;
        if(element!==null && element!==undefined) setImageBioPosition([element.offsetLeft,element.offsetTop,element.offsetWidth,element.offsetHeight]);
    }
    /**
     * Funkcja ustawiająca współrzędna X, Y szerokość i wysokość kosza na śmieci zmieszane
     */
    const onLayoutImageConfused = () => {
        const element:HTMLImageElement = document.getElementById('confused') as HTMLImageElement;
        if(element!==null && element!==undefined) setImageConfusedPosition([element.offsetLeft,element.offsetTop,element.offsetWidth,element.offsetHeight]);
    }
    /**
     * Funkcja wywołująca po wrzuceniu śmiecia do prawidłowego kosza
     */
    const dropedTrash = useCallback(() => {
        dropedTrashes.push(1);
    },[dropedTrashes]);
    /**
     * Funkcja tworząca kontener ze śmiećmi
     */
    const createTrashes = useCallback(() => {
        setTrash([]);
        dropedTrashes.length = 0;
        let trashPosition:TrashPoistionProps[] = [];
        if(plasticX!==0 && plasticY!==0 && plasticWidth!==0 && plasticHeight!==0  && trashPosition.find(item=>item.type==='plastic')===undefined) trashPosition.push({type:'plastic',x:plasticX,y:plasticY,width:plasticWidth,height:plasticHeight});
        if(paperX!==0 && paperY!==0 && paperWidth!==0 && paperHeight!==0  && trashPosition.find(item=>item.type==='paper')===undefined) trashPosition.push({type:'paper',x:paperX,y:paperY,width:paperWidth,height:paperHeight});
        if(glassX!==0 && glassY!==0 && glassWidth!==0 && glassHeight!==0  && trashPosition.find(item=>item.type==='glass')===undefined) trashPosition.push({type:'glass',x:glassX,y:glassY,width:glassWidth,height:glassHeight});
        if(bioX!==0 && bioY!==0 && bioWidth!==0 && bioHeight!==0  && trashPosition.find(item=>item.type==='bio')===undefined) trashPosition.push({type:'bio',x:bioX,y:bioY,width:bioWidth,height:bioHeight});
        if(confusedX!==0 && confusedY!==0 && confusedWidth!==0 && confusedHeight!==0  && trashPosition.find(item=>item.type==='confused')===undefined) trashPosition.push({type:'confused',x:confusedX,y:confusedY,width:confusedWidth,height:confusedHeight});
        
        setTitle(trashquestion?.title!==undefined?trashquestion.title:'');
        shuffle(trashquestion?.question);
        setAnswersconfig(trashquestion?.answersconfig);
        setAddPoints(trashquestion?.addPoints===undefined?true:trashquestion.addPoints);
        if(trashPosition.length===5){
            const t = trashquestion?.question.map((item:TrashQuestionItemProps,index:number)=>{
                if(levelgame===2){
                    return (
                        <TrashMarker key={item.id+(Math.random() + 1).toString(36).substring(7)} src={item.src} alt={item.alt} idtrash={item.id} type={item.type} 
                        trashPosition={trashPosition} dropedTrash={dropedTrash} getDraggabledElement={(element:HTMLImageElement)=>getDraggabledElement(element)}/>
                    );
                }
                else{
                    return (
                        levelgame+3>index?<TrashMarker key={item.id+(Math.random() + 1).toString(36).substring(7)} src={item.src} alt={item.alt} idtrash={item.id} type={item.type} 
                        trashPosition={trashPosition}  dropedTrash={dropedTrash} getDraggabledElement={(element:HTMLImageElement)=>getDraggabledElement(element)}/>:null
                    );
                }
                
            });
            pause();
            setTrash(t);
            setStartTimer(true);
        }
    },[dropedTrash,dropedTrashes,plasticX,plasticY,plasticWidth,plasticHeight,paperX,paperY,paperWidth,paperHeight,glassX,glassY,glassWidth,glassHeight,bioX,bioY,bioWidth,bioHeight,confusedX,confusedY,confusedWidth,confusedHeight,trashquestion,levelgame]);
    /**
     * Funkcja wywołująca tworzenie zawartości pytania 
     */
    useEffect(()=>{
        if(!showBackAlert) createTrashes();
    },[showBackAlert,createTrashes]);
    /**
     * Funkcja wywoływana po potwierdzeniu wyjścia z pytania, przekierowywuje do ekranu lokacji 
     */
    const back = () => {
        setLeavePage(true);
        setShowBackAlert(false);
        navigate(-1);
    }
    /**
     * Funkcja wywoływana po naciśnięciu guzika "Sprawdź/Dalej", sprawdza czy odpowiedź jest prawidłowa
     */
     const check = () => {
        setStartTimer(false);
        clearTimer();
        const result:boolean = levelgame===2?dropedTrashes.length===trashquestion?.question.length:levelgame+3===dropedTrashes.length;
        const answerconfig:AnswersConfigProps | undefined = answersconfig?.find((item:AnswersConfigProps)=>item.correct===result);
        const _nextquestion = {idquestion:answerconfig?.idquestion,type:answerconfig?.type};
        setNextQuestion(_nextquestion);
        setResultAnswer(result);
        setMessageAlert(result?goodAnswerMessage:badAnswerMessage);
        if(sound!==undefined && sound==='on') SoundEffect(result?'good':'bad');
        setShowAlert(true);
    };
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            check();
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu poprawnej lub błędnej odpowiedzi
     */
    const confirmResult = () => {
        setShowAlert(false);
        if(resultAnswer) nextStep();
        else if(!resultAnswer && nextquestion?.idquestion!==undefined) nextStep(false);
        else createTrashes();
    }
    /**
     * Funkcja ustawia kolejne pytania lub wywołuje funkcję zapisująca punkty i wychodzącą z wyzwania
     */
     const nextStep = (_goodanswer:boolean=true) => {
        const points = LevelPoints && LevelPoints.length>0 && levelgame!==undefined?addPoints?_goodanswer?LevelPoints[levelgame]+totalPoints:totalPoints:totalPoints:totalPoints;
        if(nextquestion!==undefined && nextquestion.idquestion!==undefined && nextquestion.type!==undefined) gotoNextQuestion(nextquestion.idquestion,nextquestion.type,nextquestions,points);
        else if(nextquestions!==undefined){
            let firstquestion:{idquestion:number,type:string} = nextquestions[0];
            let _nextquestions:{idquestion:number,type:string}[] = [];
            nextquestions.forEach((item:{idquestion:number,type:string},index:number)=>{
                if(index!==0) _nextquestions.push(item);
            });
            if(firstquestion!==undefined && firstquestion!==null) gotoNextQuestion(firstquestion.idquestion,firstquestion.type,_nextquestions,points);
            else savePoints(points);
        }
        else savePoints(points);
    }
    /**
     * Funkcja przekierowywuje do następnego pytania
     * @param {number} _idquestion ID pytania
     * @param {string} _type typ pytania
     * @param {{idquestion:number,type:string}[]} _nextquestions następne pytania
     * @param {number} _points zdobyte punkty
     */
     const gotoNextQuestion = (_idquestion:number,_type:string,_nextquestions:{idquestion:number,type:string}[],_points:number) => {
        if(sound!==undefined && sound==='on') SoundEffect('nextQuestion');
        const pathname = params.pathname.replace("trash","") + _type;
        navigate(pathname,{
            replace:true,
            state:{
                idquestion:_idquestion,nextquestion:_nextquestions,idlocation:idlocation,points:_points,idpark:idpark,parkName:parkName,parkLogo:logo
            }
        });
    }
    /**
     * Funkcja aktualizuje zdobyte przez gracza punkty
     * @param {number} points punkty zdobyte dotej pory przez gracza 
     */
     const savePoints = (points:number) => {
        setShowEndAlert(true);
        if(points){
            const _locationpoints:LocationPointsProps[] = locationspoints!==undefined?[...locationspoints]:[];
            const index:number = _locationpoints.findIndex((item:LocationPointsProps) => item.idlocation === idlocation);
            if (index !== -1) _locationpoints[index] = { idlocation:idlocation, points:_locationpoints[index].points+points, parkID:idpark,gamemode:gamemode};
            else _locationpoints.push({idlocation,points,parkID:idpark,gamemode})
            dispatch(setPoints({locationspoints:_locationpoints}));
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu o ukończeniu wyzwania, przekierowywuje do ekranu parku
     */
    const goPark = () => {
        setLeavePage(true);
        setShowEndAlert(false);
        navigate(-2);
    }
    /**
     * Funkcja przechwytująca naciśnięcie przycisku wstecz w przeglądarce
     */
    window.onpopstate = () => {
        if(!leavePage && params.pathname==='/park/location/trash'){
             navigate(1);
             setShowBackAlert(true);
        }
    };
    /**
     * Funkcja ustawia element przeciągany
     * @param {HTMLDivElement} element 
     */
    const getDraggabledElement = (element:HTMLImageElement) => {
        draggabledElement = element;
    }
    /**
     * Funkcja wywoływana podczas przeciągania elementu (śmiecia) nad koszem
     * @param {DragEvent} event 
     */
    const handleDragOver = (event:DragEvent<HTMLDivElement>,type:string) => {
        if(draggabledElement!==null && draggabledElement.getAttribute('data-type') === type) event.preventDefault();
        else return false;
    }
    /**
     * Funkcja wywoływana po opuszczeniu elementu
     * @param {DragEvent} event 
     */
    const handleDrop = (event:DragEvent<HTMLDivElement>) => {
       event.preventDefault();
       if (draggabledElement !==null){
           draggabledElement.style.visibility = 'hidden';
           dropedTrash();
       }
       return false;
    }
    /**
     * Funkcja wywołana po anulowaniu opuszczenia strony
     */
    const cancelBack = () => {
        setShowBackAlert(false);
        createTrashes();
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            {showBackAlert && <AlertNoTitle show={showBackAlert} cancel={cancelBack} confirm={back} 
             message='Chcesz opuścić wyzwanie?' buttonCancel='Anuluj' buttonConfirm='Opuść'/>}
            {showAlert && <AlertNoTitle show={showAlert} cancel={()=>{confirmResult()}} message={messageAlert} buttonCancel='OK'/>}
            {showEndAlert && <AlertNoTitle show={showEndAlert} confirm={goPark} message='Gratulacje! Wyzwanie zakończone!' buttonConfirm='Wróć na szlak'/>}
            <LeftTabBar idpark={idpark} logo={logo} visibilityButtons='hidden'/>
            <div className={styles.questionContainer}>  
                <Header name="POZBIERAJ ODPADKI" menuVisibility='hidden'/>
                <div className={styles.questionContent}>
                    <div className={styles.trashesContainer}>
                        {
                            trash
                        }
                    </div>
                    <div className={styles.trashQuestionContainer}>
                        {_time!==undefined?<Timer namePage='trash' start={startTimer} time={_time} result={check}></Timer>:null}
                        {title && title!==''?<div className={styles.title} style={{fontSize:getFontSize()+10,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : parser(title) }}/>:null}
                         <div className={styles.trashcansContainer}>
                            <div className={styles.trashcanContainer} onDragOver={(event)=>handleDragOver(event,'plastic')}  onDrop={handleDrop}>
                                <img id='plastic' className={styles.trashcan} src={trashcan_plastic} alt='Kosz na śmieci plastikowe' onLoad={onLayoutImagePlastic}/>
                                <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>PLASTIK</span>
                            </div>
                            <div className={styles.trashcanContainer} onDragOver={(event)=>handleDragOver(event,'paper')}  onDrop={handleDrop}>
                                <img id='paper' className={styles.trashcan} src={trashcan_paper} alt='Kosz na śmieci papierowe' onLoad={onLayoutImagePaper}/>
                                <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>PAPIER</span>
                            </div>
                            <div className={styles.trashcanContainer} onDragOver={(event)=>handleDragOver(event,'glass')} onDrop={handleDrop}>
                                <img id='glass' className={styles.trashcan} src={trashcan_glass} alt='Kosz na śmieci szklane' onLoad={onLayoutImageGlass}/>
                                <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>SZKŁO</span>
                            </div>
                            <div className={styles.trashcanContainer} onDragOver={(event)=>handleDragOver(event,'bio')} onDrop={handleDrop}>
                                <img id='bio' className={styles.trashcan} src={trashcan_bio} alt='Kosz na śmieci bio' onLoad={onLayoutImageBio}/>
                                <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>BIO</span>
                            </div>
                            <div className={styles.trashcanContainer} onDragOver={(event)=>handleDragOver(event,'confused')} onDrop={handleDrop}>
                                <img id='confused' className={styles.trashcan} src={trashcan_confused} alt='Kosz na śmieci zmieszane' onLoad={onLayoutImageConfused}/>
                                <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>ZMIESZANE</span>
                            </div>
                        </div>
                        <button className={contrast==='on'?styles.checkContrast:styles.check} onClick={check} onKeyDown={onKeyDown}>
                            <p className={styles.checkText} style={{fontSize:getFontSize()+10}}>SPRAWDŹ / DALEJ</p>
                            <img className={styles.next} src={next} alt=""/>
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Trash;
