/**
 * Dostępne lokacje w aplikacji
 */
 const locations = [
    /** 1. Słowiński Park Narodowy */
    {
        idlocation:1,
        parkID:1,
        name:'Diabelski Kamień',
        description:'Diabelski kamień nad jeziorem Gardno to głaz o ciekawej przeszłości. Według legendy znajduje się na nim odcisk kopyta diabła, któremu pewien rybak podpisał cyrograf. Rybak zgodził się oddać diabłu duszę pod warunkiem, że bies ukończy do rana budowę kościoła w Rowach nim zapieje pierwszy kogut w Gardnie. Rybak był przekonany, że diabeł nie zdąży na czas. Gdy zobaczył budowlę już prawie ukończoną postanowił zapiać jak kogut, ratując w ten sposób swoją duszę. Wściekły diabeł zburzył kamienny kościół, a z głazów powstała Wyspa Kamienna na jeziorze Gardno.',
        src:require('../assets/images/location/1.jpg'),
        alt:'Duży głaz z położoną na nim zardzewiałą kotwicą. Dookoła rośnie sucha, wysoka trawa. W tle jasna woda jeziora.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:2,
        parkID:1,
        name:'Latarnia morska w Czołpinie',
        description:'Latarnię morską w Czołpinie zbudowano w 1875 roku w odległości 1000 m od brzegu Bałtyku, na wysokiej wydmie, którą wcześniej zalesiono. Początkowo źródłem światła w laternie była lampa zasilana olejem  umieszczona w ogromnej soczewce o średnicy blisko 2 m. Obecnie w latarni znajduje się urządzenie optyczne z 1926 roku z soczewką Fresnela zbudowaną z 43 szlifowanych pierścieni pryzmatycznych, wewnątrz której znajdują się dwie żarówki halogenowe. Zasięg światła latarni zwiększył się dzięki temu z 12 do 22 mil morskich (Mm). Latarnię w Czołpinie udostępniono do zwiedzania, prowadzi do niej szlak turystyczny.',
        src:require('../assets/images/location/2.jpg'),
        alt:'Gęsta mgła zakrywająca częściowo wzgórze porośnięte drzewami. Ponad koronami drzew wystaje górna część latarni morskiej.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:3,
        parkID:1,
        name:'Muzeum SPN w Czołpinie',
        description:'Muzeum SPN w Czołpinie znajduje się w zabytkowej „Osadzie Latarników” z 1871 r. W jej skład wchodzą cztery budynki. W największym, gdzie teraz jest ekspozycja stała przedstawiająca wartości przyrodnicze i kulturowe Regionu, znajdowały się w przeszłości mieszkania latarników i ich rodzin. W historycznej stodole obecnie prezentowane są wystawy czasowe. Z kolei dawny budynek gospodarczy został zaadaptowany na pomieszczenia administracyjne. Ekspozycję Muzeum przystosowano dla osób z różnymi dysfunkcjami i wyposażono w nowoczesne środki przekazu m. in. projekcje holograficzne, 3D, technologię VR.',
        src:require('../assets/images/location/3.jpg'),
        alt:'Pomieszczenie z jasnymi ścianami i drewnianą podłogą. Na środku znajduje się duża makieta latarnii morskiej. Po bokach ustawione są dwie przeszklone gabloty z żarówkami.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:4,
        parkID:1,
        name:'Pnie na plaży',
        description:'Baśniowy krajobraz kopalnych pni i gleb na plaży cieszy się niesłabnącym zainteresowaniem turystów. Pnie, zakonserwowane słoną wodą morską, są pozostałością drzewostanu sprzed ok. 3000 lat, który uległ zniszczeniu w wyniku naturalnych procesów (pożarów) lub celowych działań mieszkańców. Zniszczoną puszczę bukowo - dębową stopniowo zasypał piasek. Kopalne gleby, torf i fragmenty drzew najbardziej odsłaniane są podczas sztormów jesienno-zimowych, ale także i latem można oglądać ten ciekawy spektakl.',
        src:require('../assets/images/location/4.jpg'),
        alt:'Fragment nadmorskiej plaży. Z piasku wystają zakonserwowane pnie drzew. W tle widać spokojną taflę morza oraz spacerujących po plaży ludzi.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:5,
        parkID:1,
        name:'Rowokół',
        description:'Rowokół to jedno z najbardziej tajemniczych miejsc na mapie SPN. Wzgórze, o wysokości aż 115 m n.p.m., widoczne jest z miejsc odległych o kilkadziesiąt kilometrów, dlatego od dawna było doskonałym punktem orientacyjnym i oznaczano je na morskich mapach nawigacyjnych. Nazwa Rowokołu prawdopodobnie ma pochodzenie skandynawskie. Na szczycie wzgórza dokonano odkrycia kamiennych fundamentów średniowiecznej kaplicy, a na południowo-wschodnim stoku odkryto dobrze zachowane grodzisko wczesnośredniowieczne, w którym oddawano kult bóstwu – Swarożycowi.',
        src:require('../assets/images/location/5.jpg'),
        alt:'Na samym dole zdjęcia znajduje się pas wysokiej, nadbrzeżnej trawy, za nim tafla wody. Na drugim brzegu widać wysokie wzgórze, porośnięte lasem, a nad nim błękitne, prawie bezchmurne niebo.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:6,
        parkID:1,
        name:'Wielkie Bagno',
        description:'Na południe od jezior Gardno i Łebsko rozciągają się rozległe obszary podmokłe. W ich obrębie występują cenne przyrodniczo torfowiska wysokie, bagienne bory sosnowe i lasy brzozowe. W przeszłości znaczne tereny torfowisk zostały zniszczone w wyniku wydobycia torfu i obniżenia poziomu wód związanego z przeprowadzonymi melioracjami. Charakterystycznym elementem krajobrazu na torfowisku Wielkie Bagno są duże i płytkie zbiorniki poeksploatacyjne będące pozostałością kopalni torfu. Obecnie w tych torfiankach następuje regeneracja torfowisk mszarnych, a jesienią można obserwować zlotowiska żurawi.',
        src:require('../assets/images/location/6.jpg'),
        alt:'Wode bagna przecinają połacie niskiej, zielono-czerwonej roślinności. Z trzech stron polana otaczają drzewa, a nad nią, na bezchmurnym niebie świeci słońce.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:7,
        parkID:1,
        name:'Wydma Czołpińska',
        description:'Wydmy zostały uruchomione wskutek działalności człowieka, który chciał dostosować ten fragment wybrzeża do swoich potrzeb. Działania te zbiegły się z czynnikami naturalnymi, takimi jak huraganowe wiatry, zmiany klimatu, itp. W ten sposób piasek wyrzucany przez fale morskie na brzeg, osuszony i wywiewany w głąb lądu, nie napotykając żadnych przeszkód, utworzył w przeszłości rozległe pola wydm ruchomych. Wydma Czołpińska to najdłuższy fragment „polskiej Sahary” udostępniony turystom. Na szlaku można obserwować wydmy o różnym kształcie, w różnym stopniu porośnięte przez roślinność piaskolubną.',
        src:require('../assets/images/location/7.jpg'),
        alt:'Piaszczysta wydma rozciąga się na tle lasu. Porastają ją niskie trawy i porosty. Po prawej stronie znajduje się wyznaczona niskimi palikami ścieżka.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:8,
        parkID:1,
        name:'Wydma Łącka',
        description:'Wydma Łącka to najbardziej znana wydma ruchoma i fragment najrozleglejszego pola wydm ruchomych Mierzei Łebskiej. Położona jest pomiędzy Morzem Bałtyckim a jeziorem Łebsko, na zachód od miejscowości Łeba. Nazwę zawdzięcza zasypanej przez siebie osadzie Łączka. Fragmenty byłych zabudowań wsi od czasu do czasu odsłaniane są u podnóża wydmy. Wydmy ruchome przemieszczają się pod wpływem wiatru nawet do kilkunastu metrów na rok. Do ruchu piasku wystarczy wiatr wiejący z prędkością około 5 m/s.',
        src:require('../assets/images/location/8.jpg'),
        alt:'Piaszczysta, wysoka wydma rozciąga się na tle błękitnego, bezchmurnego nieba. Przecina ją wyznaczona niskimi palikami ścieżka, którą spacerują turyści. Górne części wydm porośnięte są niską roślinnością.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 2. Wigierski Park Narodowy */
    {
        idlocation:9,
        parkID:2,
        name:'Platforma widokowa na Półwyspie Klasztornym',
        description:'Jest to najnowszy punkt widokowy, powstały w 2020 roku. Znajduje się we wsi Wigry na Półwyspie Klasztornym, tuż obok szlaku rowerowego „Wokół Wigier”. Z platformy widać pokamedulski klasztor w Wigrach oraz malowniczy widok na północną część jeziora Wigry. Przy platformie na pulpicie znajduje się mapa parku i rysunki wybranych ptaków. Tutaj można obserwować bieliki, największe ptaki szponiaste w Polsce  kormorany, perkozy, mewy i rybitwy, łyski, kaczki oraz wiele innych i słuchać ptaków śpiewających w trzcinach, z których najczęstszy i najgłośniejszy jest trzciniak. Warto tu być o wschodzie słońca, gdy są najpiękniejsze ptasie koncerty oraz wieczorem, żeby oglądać zachody słońca.',
        src:require('../assets/images/location/9.jpg'),
        alt:'Widok rozciąga się z góry na taflę jeziora, po którym pływają żaglówki. Na bliższym brzegu znajduje się platforma widokowa z kilkoma turystami. Drugi brzeg rozciągający się na horyzoncie porasta las.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:10,
        parkID:2,
        name:'Platforma widokowa na Słupiu',
        description:'Platforma znajduje się nad Zatoką Słupiańską jeziora Wigry, na ścieżce edukacyjnej „Jeziora”, na szlaku rowerowym „Wokół Wigier”, nieopodal Ośrodka Edukacji Środowiskowej. Na pulpicie przy platformie znajduje się opis genezy geologicznej jeziora Wigry. Z platformy widać fragment południowej części Wigier, Zatokę Słupiańską zamkniętą przez Półwysep Łysocha po prawej i Półwysep Dąbek po lewej, ogromną taflę wody Plosa Bryzglowskiego, wyspy Ostrów i Ordów a pomiędzy nimi południowy brzeg jeziora z zabudowaniami wsi Bryzgiel. Wiosną chętnie żerują tu czaple białe, łabędzie, łyski, perkozy i tracze. Późnym latem i jesienią przelatują tędy ogromne stada kormoranów.',
        src:require('../assets/images/location/10.jpg'),
        alt:'Drewniana platforma widokowa, otoczona drzewami, wychodzi na taflę jeziora. Po jej bokach, w wodzie rośnie wysoka trawa. W tle rozciąga się drugi brzeg jeziora oraz zachmurzone niebo.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:11,
        parkID:2,
        name:'Muzeum Wigier w Starym Folwarku',
        description:'Muzeum Wigier mieści się w budynku dawnej Stacji Hydrobiologicznej w miejscowości Stary Folwark na północnym brzegu jeziora Wigry. Założył ją i pracował tu w latach 1927-1939 limnolog Alfred Lityński. Zwiedzając Muzeum Wigier wyruszamy w podróż i&nbsp;w&nbsp;czasie, i&nbsp;w&nbsp;przestrzeni. Od epoki lodowcowej, przez siedzibę łowców reniferów i bramę klasztoru kamedułów docieramy do współczesnych szlaków turystycznych WPN-u. Poznajemy park i bogactwo jego różnorodności biologicznej: od jeziora Wigry po najmniejsze suchary, Czarną Hańczę, pola i łąki krajobrazu rolniczego oraz Puszczę Augustowską. Muzeum organizuje też zajęcia edukacyjne.',
        src:require('../assets/images/location/11.jpg'),
        alt:'Widok rozciąga się z góry na jasny, jednopiętrowy budynek muzeum pośród zadbanego trawnika i drzew. Budynek posiada prostą konstrukcję, otynkowaną na beżowo i spadzisty, szary dach. W tle znajduje się jezioro oraz półwysep na którym dostrzec można budynek klasztoru.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:12,
        parkID:2,
        name:'Klasztor Pokamedulski w Wigrach',
        description:'Klasztor znajduje się na półwyspie, na wzgórzu. Jego charakterystyczną sylwetkę widać z wielu miejsc w Parku. Został założony przez kamedułów, którzy byli nad Wigrami w latach 1667 – 1800. Kompleks klasztorny tworzą: kościół, dawne eremy zakonników, wieża zegarowa, dom królewski, kaplica kanclerska z apartamentami papieskimi, ogrody i przystań wodna. Są tu liczne wystawy, restauracja i kawiarnia. Miejsce można zwiedzać bez ograniczeń. Budynki rozmieszczone bardzo blisko siebie tworzą swoisty klimat.  Między nimi prowadzą kamieniste uliczki, które przenoszą turystów w inne czasy. Wieża zegarowa umożliwia podziwianie panoramy całego kompleksu oraz przecudnie rozlewającego się wokół jeziora.',
        src:require('../assets/images/location/12.jpg'),
        alt:'Budynki klasztoru znajdują się na wąskim półwyspie. Otacza go spokojna woda, po której pływają żaglówki. Większość półwyspu porośnięta jest lasem.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:13,
        parkID:2,
        name:'Jezioro Gałęziste',
        description:'Jezioro Gałęziste znajduje się w północnej części parku, na ścieżce edukacyjnej Samle. Jest to niewielkie jezioro o powierzchni 3,9 ha, max. głębokości 14,3 m. Zasobna w wapń woda często przyjmuje kolor szmaragdowy. Na wysokim brzegu znajduje się miejsce odpoczynku, z którego roztacza się widok na to bardzo malownicze jeziorko o regularnej linii brzegowej, otoczone lasem. W niewielkim pasie szuwarów rośnie trzcina pospolita, oczeret, pałki, a dno jeziora, w którym znajdują się źródliska, porastają ramienice. Nazwa jeziora pochodzi od zalegających na brzegach połamanych pni i gałęzi, co czyniło je niedostępnym dla rybaków.',
        src:require('../assets/images/location/13.jpg'),
        alt:'Niewielkie jezioro ze wszystkich stron otoczone jest gęstym, iglastym lasem. Tafla jeziora jest spokojna i odbijają się w niej nadbrzeżne drzewa.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:14,
        parkID:2,
        name:'Czerwony Krzyż',
        description:'Krzyż znajduje się nad Zatoką Krzyżańską jeziora Wigry, na szlaku rowerowym „Wokół Wigier”, tuż przy wsi Czerwony Krzyż. Obecnie niewielka wieś z kilkoma gospodarstwami powstała w XVIII wieku. W 1944 roku została spacyfikowana przez hitlerowców za pomoc oddziałom Armii Krajowej. W 1994 roku ustawiono ten krzyż autorstwa Gustawa Zemły, aby upamiętnić tragiczne losy mieszkańców wsi Czerwony Krzyż. Wokół krzyża znajdują się wytwory kultury materialnej z Suwalszczyzny, głównie żarna i łańcuchy. W pobliżu, przy skrzyżowaniu dróg, stoi pomnik na wspólnej mogile powstańców z 1863 roku oraz żołnierzy radzieckich poległych w 1944 roku.',
        src:require('../assets/images/location/14.jpg'),
        alt:'Wysoki, drewniany krzyż na którym wisi nieduża figura Jezusa. Dookoła krzyża ustawione są drewniane pale, na których stoją żarna i łańcuchy. W tle rośnie las i rozciąga się bezchmurne niebo.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:15,
        parkID:2,
        name:'Kładki na Czarnej Hańczy',
        description:'Na wschód od Wigier znajduje się system drewnianych kładek, dzięki którym suchą stopą przyjdziemy przez dolinę Czarnej Hańczy. Jest to jedyna możliwość zobaczenia tej rzeki w parku na odcinku przed ujściem do jeziora. Cała dolina wraz z rzeką jest tutaj zamknięta dla turystyki, ze względu na cenne torfowiska niskie i przejściowe z rzadkimi gatunkami roślin. Okolice te upodobały sobie wilki. Przez podmokły teren prowadzi łącznie 700 m kładek. Znajdują się one na trasie szlaku rowerowego „Wokół Wigier” (przez kładki należy prowadzić rower). Można też tędy spacerować robiąc pętlę ze wsi Sobolewo.',
        src:require('../assets/images/location/15.jpg'),
        alt:'Drewniana kładka, umieszczona nisko nad ziemią prowadzi przez las. Drzewa są wysokie i&nbsp;w&nbsp;większości iglaste. Pomiędzy nimi rosną niższe drzewka liściaste i zarośla.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:16,
        parkID:2,
        name:'Wieża widokowa w Kruszniku',
        description:'Z drewnianej, 15-metrowej wieży roztacza się rozległa panorama południowej części Wigier. Widać też jeziora Mulaczysko i Krusznik, zatem z jednej wieży podziwiamy trzy akweny. Patrząc na północ zobaczymy m.in. podmokłą wyspę Krowę, której nazwa związana jest z tutejszą tradycją wypasu bydła na wyspach Wigier. Co roku wiosną krowy przywiązane do łódki płynęły przez jezioro na miejsce wypasu. Cielęta przeprawiano łodziami. Wieża stoi na trasie popularnego szlaku rowerowego „Wokół Wigier”. Można do niej dojechać także samochodem (we wsi Krusznik należy zjechać z asfaltu w drogę szutrową).',
        src:require('../assets/images/location/16.jpg'),
        alt:'Drewniana wieża widokowa znajduje się na niskim pagórku porośniętym trawą. Za nią rośnie las. Do pagórka prowadzi ścieżka otoczona kamieniami.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 3. Woliński Park Narodowy */
    {
        idlocation:17,
        parkID:3,
        name:'Zagroda Pokazowa Żubrów',
        description:'Zagroda Pokazowa Żubrów usytuowana jest przy zielonym szlaku turystycznym. Spacer do niej z centrum Międzyzdrojów zajmuje około 20 minut (1,5 km). Zagroda  zajmuje 28 ha, z czego żubry mają do swojej dyspozycji wybieg o powierzchni 20 ha, w tym 0.5 ha stanowi zagroda pokazowa. Chociaż sama zagroda została stworzona w ramach programu hodowli zachowawczej żubra w zagrodzie przebywają również wyleczone, bądź wychowane przez człowieka zwierzęta, które są niezdolne do samodzielnego bytowania w swoim środowisku naturalnym m.in. : dziki, sarny,  jelenie i bieliki.',
        src:require('../assets/images/location/17.jpg'),
        alt:'Małe stado żubrów znajdujące się w zagrodzie pokazowej parku. Obejmuje ono dwa dorosłe i dwa młode żubry na tle lasu.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:18,
        parkID:3,
        name:'Muzeum Przyrodnicze Wolińskiego Parku Narodowego',
        description:'Muzeum Przyrodnicze Wolińskiego Parku Narodowego położone jest w Międzyzdrojach przy ul. Niepodległości 3. Ekspozycja muzealna prezentuje różnorodność przyrody Wolińskiego Parku Narodowego w pięciu salach wystawienniczych. bogaty świat przyrody – roślin i zwierząt wyspy Wolin. Polujący bielik niosący upolowaną ofiarę, atakujące bataliony czy też odpoczywające na brzegu morza foki szare to tylko niektóre atrakcje. Duża część przestrzeni wystawienniczej poświęcona jest fotografii przyrodniczej.',
        src:require('../assets/images/location/18.jpg'),
        alt:'Budynek Muzeum Przyrodniczego. Do wejścia prowadzą schody, ściany są koloru żółtego, a spadzisty dach czerwonego. Teren przed budynkiem porasta trawa.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:19,
        parkID:3,
        name:'Wzgórze Gosań',
        description:'Wzgórze Gosań to wzniesienie, położone około 4 kilometrów na północny-wschód od Międzyzdrojów. Jego wysokość to 93 m. n.p.m. Wzniesienie to stanowi nadmorski klif abrazyjny, który jest najwyższym tego typu klifem na polskim wybrzeżu. Na skutek abrazji co roku morze zabiera średnio 1 m lądu. Na szczycie znajdują się resztki artyleryjskiej wieży obserwacyjnej i bunkry. Najpiękniejszy jest jednak zapierający dech w piersi widok. Można stąd podziwiać panoramę zatoki pomorskiej.',
        src:require('../assets/images/location/19.jpg'),
        alt:'Widok z klifu na Morze Bałtyckie. Niebo jest czyste, a woda spokojna z niedużą falą. Górną część klifu porasta las.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:20,
        parkID:3,
        name:'Jezioro Turkusowe',
        description:'Jezioro Turkusowe jest zbiornikiem sztucznym. Powstało na podłożu marglisto-wapiennym w miejscu wyrobiska kredy. Zbiornik stanowi zalaną pozostałość przedwojennej kopalni kredy, funkcjonującej jeszcze do 1954 roku. Po zakończonej eksploatacji wody deszczowe i podskórne zaczęły powoli zalewać wyrobisko. Jezioro położone jest w otoczeniu malowniczych pagórków Lubińsko – Wapnickich na wysokości 2,6 m n.p.m. Głębokość jego wynosi 21,2 m. Nazwa jeziora pochodzi od niebieskawo-zielonej barwy lustra wody wywołanej rozszczepieniem światła słonecznego w czystej wodzie i odbiciem refleksów od białego podłoża kredowego z zalegającymi na dnie związkami węglanu wapnia.',
        src:require('../assets/images/location/20.jpg'),
        alt:'Widok na taflę i przeciwny brzeg Jeziora Turkusowego. Woda ma głęboki zielony odcień. Przeciwny brzeg jest stromy i wysoki, porośnięty żywo zielonym lasem liściastym.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:21,
        parkID:3,
        name:'Wzgórze Zielonka',
        description:'Wzgórze Zielonka osiąga wysokość 81 m n.p.m. Powstało w wyniku cofania się lądolodu ponad 10 tys. lat temu. Ze szczytu rozpościera się niesamowity widok. Jest to bowiem jedyne miejsce, skąd widać zarówno Morze Bałtyckie, jak i rozlewiska wstecznej Delty Świny, Zalew Szczeciński i wody Jeziora Wicko Wielkie. O wyjątkowości tego miejsca świadczy fakt, że Międzynarodowe Towarzystwo Przyjaciół Przyrody uznało panoramę, roztaczającą się ze wzgórza za "Krajobraz roku 1993/94”. Warto zaznaczyć, że ze Wzgórza Zielonka podziwiać można także "krainę 44 wysp" Wstecznej Delty Świny. Jest ona zaliczana do ostoi ptaków o znaczeniu europejskim. Tym samym stanowi jeden z ważniejszych obszarów Wolińskiego Parku Narodowego.',
        src:require('../assets/images/location/21.jpg'),
        alt:'Szczyt wzgórza otoczony drewnianym płotem. W dole zabudowania wsi oraz delta rzeki. Woda poprzecinana jest licznymi, płaskimi wysepkami.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:22,
        parkID:3,
        name:'Kawcza Góra',
        description:'Punkt widokowy Kawcza Góra wznosi się na 61 m. n.p.m. Wejście na Kawczą Górę, rozpoczyna ozdobna brama WPN, zlokalizowana w końcowej, wschodniej części promenady. Następnie drogą leśną poprzez las bukowy docieramy na szczyt wzniesienia. Umiejscowiony jest tutaj punkt widokowy na Zatokę Pomorską oraz głazy i krzyże upamiętniające leśników. Dalej szlak odbija na nadmorską linię umocnień dawnej baterii artylerii stałej, a kolejna jego odnoga idzie w kierunku zagrody żubrów.',
        src:require('../assets/images/location/22.jpg'),
        alt:'Stromy stok niewysokiej góry. Prowadzą na niego schody dwubiegowe. Zbok porastają drzewa iglaste, w niektórych miejscach przebijają się połacie piasku. Na samym dole rozciąga się plaża.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:23,
        parkID:3,
        name:'BAS',
        description:'Bateria artylerii stałej na Białej Górze położona jest na szczytowych partiach Pasma Wolińskiego, który obecnie mieści się na terenie Wolińskiego Parku Narodowego. Kompleks fortyfikacji zlokalizowano na zachodnich zboczach Białej Góry i składa się on z kilku bunkrów magazynowych, stanowisk ogniowych oraz wieży obserwacyjnej. Fortyfikacje powstały po II wojnie światowej, jako 17. Bateria Artylerii Stałej (BAS) w Międzyzdrojach.',
        src:require('../assets/images/location/23.jpg'),
        alt:'Ścieżka przez las, prowadząca do betonowego budynku, ogrodzona drewnianym płotem. Na ziemi leżą opadłe liście. Budynek zbudowany z szarego betonu o kształcie zbliżonym do trójkąta.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:24,
        parkID:3,
        name:'Wsteczna Delta Świny',
        description:'Wsteczna Delta Świny to stanowiący fragment Zalewu Szczecińskiego obszar ponad 3000 ha bagnistych, podmokłych wysepek, szuwarów i płytkich wód. Unikalny w skali kraju archipelag wodno-błotnych wysp poprzecinanych cieśninami i kanałami. Oryginalne nazwy poszczególnych wysp jak np.: Zajęcze Łęgi, Wydrza Kępa, Koński Smug, Warne Kępy, Trzcinice, Gęsia Kępa, Wielki Krzek czy Karsiborska Kępa mają pochodzenie historyczne. Delta Świny to jedno z ważniejszych naturalnych siedlisk ptaków w Polsce, uznane przez BirdLife International  za Europejską Ostoję Ptaków (IBA). Na jej terenie stwierdzono występowanie około 240 gatunków ptaków, w tym 160 gatunków, które odbywają tu lęgi. Jako fragment Zalewu Szczecińskiego kompleks wodno-błotnych rozlewisk Delty Świny stanowi w skali Europy ogromnie cenne zimowisko, jak i miejsce pierzenia się ptaków. W okresie przelotów: wiosną i jesienią można tutaj stwierdzić kumulacje wielotysięcznych stad ptaków wodnych migrujących wzdłuż dorzecza Odry i wybrzeża Bałtyku.',
        src:require('../assets/images/location/24.jpg'),
        alt:'Widok z powietrza na meandrującą rzekę. Brzegi i teren dookoła jest płaski, porośnięty niską roślinnością. Rzeka wpływa do rozległego zalewu. Zachodzące słońce przebija się przez chmury.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 4. Park Narodowy „Bory Tucholskie” */
    {
        idlocation:25,
        parkID:4,
        name:'Dąb „Bartuś”',
        description:'W Polsce znajduje się ok. 37 tys. pomników przyrody (dane z 2015 roku), z czego najwięcej jest pojedynczych drzew i grup drzew. Również na obecnym terenie Parku Narodowego „Bory Tucholskie” było ich kilkadziesiąt. W chwili utworzenia Parku, gdy cała przyroda podlega ochronie, nie ma potrzeby ich tworzenia. Jednak ze względów historycznych pozostawiono na nich tabliczki z nazwą „pomnik przyrody”. Najstarszym pomnikiem przyrody w Parku Narodowym „Bory Tucholskie” jest dąb szypułkowy „Bartuś”. Obwód jego pnia wynosi ponad 7 m.',
        src:require('../assets/images/location/25.jpg'),
        alt:'Potężny dąb wyrasta pośród lasu. Jego pień jest bardzo gruby, a korona rozłożysta. Część liści zmieniła już kolor na złoty i brązowy. Po prawej stronie wyrasta rozgałęzienie drzewa.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:26,
        parkID:4,
        name:'Jeziora lobeliowe',
        description:'Jeziora lobeliowe to jedne z najcenniejszych ekosystemów wodnych w Europie. Te niezwykle malownicze zbiorniki charakteryzują się krystalicznie czystą wodą i unikatową roślinnością. Wyróżnia się je na podstawie kryterium florystycznego, czyli obecności przynajmniej jednego z trzech gatunków wskaźnikowych, jakimi są: lobelia jeziorna, poryblin jeziorny i brzeżyca jednokwiatowa. Są to zbiorniki polodowcowe, które wykształciły się na bezwapiennym, mało żyznym podłożu. W Parku Narodowym „Bory Tucholskie” znajduje się aż 8 tego typu jezior. Zaliczamy do nich m.in. jezioro Gacno Wielkie przy którym urządzono pomost umożliwiający oglądanie strefy przybrzeżnej.',
        src:require('../assets/images/location/26.jpg'),
        alt:'Niewielkie jezioro otoczone jest zielonym lasem. Niebieską wodę porastają niskie trawy.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:27,
        parkID:4,
        name:'Jezioro dystroficzne Kacze Oko',
        description:'Jezioro Kacze Oko powstało w wyniku wytopienia się niewielkiej bryły martwego lodu. Pod względem typologii zaliczane jest do jezior dystroficznych (zwanych inaczej sucharami). Charakteryzuje się słabą przezroczystością wody, dużą ilością zawiesin i związków humusowych oraz małą zawartością wapnia. To śródleśne oczko ubogie jest w organizmy zwierzęce i roślinne. Spowodowane jest to występowaniem tu znacznej ilości kwasów organicznych mających zdolność wiązania soli mineralnych w nieprzyswajalne dla roślin kompleksy chemiczne. Duży wpływ, na rozwijające się tutaj życie, mają również często występujące niedobory tlenu.',
        src:require('../assets/images/location/27.jpg'),
        alt:'Małe jezioro otoczone jest mieszanym lasem. Woda zbiornika jest ciemna, pokryta cienką warstwą osadu. Nad drzewami rozciąga się błękitne niebo z kilkoma chmurami.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:28,
        parkID:4,
        name:'Łąki Józefowskie',
        description:'Łąki Józefowskie to 40-hektarowy kompleks łąk świeżych oraz wilgotnych łąk trzęślicowych do których zaprowadzi nas czerwona ścieżka dydaktyczna. Obserwujemy tu wyraźną sezonowość krajobrazu. Wczesną wiosną łąki są zalewane i przez kilka tygodni stagnuje na nich woda pochodząca z topniejącego śniegu. Gdy woda opada do życia budzą się rośliny. Łąka nabiera barw. Takie wilgotne łąki są rajem dla ptaków, które wiosną znajdują tu pożywienie i miejsce rozrodu. Spotykamy tu m.in. czajki, drozdy, bieliki, błotniaki stawowe i żurawie. Tu także pożywienia i odpoczynku szukają sarny, jelenie, lisy i dziki.',
        src:require('../assets/images/location/28.jpg'),
        alt:'Rozległą łąkę porasta niska trawa, pokryta szronem. Otaczają je lasy, oświetlone wschodzącym słońcem. Niewielkie, poszarpane chmury zakrywają błękitne niebo.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:29,
        parkID:4,
        name:'„Pętla Lipnickiego”',
        description:'„Pętla Lipnickiego” to dawne wyrobisko torfu. W wyniku prowadzenia tam prac jeszcze w latach 80, powstało zagłębienie, które wypełniło się wodą. Obecnie możemy tu obserwować stopniowe ponowne zarastanie zbiornika, czyli sukcesję wtórną. Ostatecznie wykształci się tu prawdopodobnie bór bagienny. Tymczasem z otwartej tafli wody korzystają licznie występujące tu płazy, które ciepłymi letnimi wieczorami urządzają koncerty. Zwiedzanie tego malowniczego zakątka ułatwia drewniana kładka. Miejsce to swoją nazwę zawdzięcza prof. Ludwikowi Lipnickiemu, znanemu lichenologowi, wieloletniemu badaczowi i miłośnikowi Borów Tucholskich.',
        src:require('../assets/images/location/29.jpg'),
        alt:'Podmokły teren porasta gęsta roślinność - trawy i lilie wodne. Pomiędzy nimi przebija się tafla wody. Na granicy brzegu rośnie las na tle błękitnego nieba.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:30,
        parkID:4,
        name:'Zagroda Pokazowa Zwierząt w Chocińskim Młynie',
        description:'W Zagrodzie Pokazowej Zwierząt można zobaczyć zwierzęta należące do starych ras zwierząt gospodarskich objętych Programem Ochrony Zasobów Genetycznych: kury zielononóżki kuropatwiane, owce świniarki, bydło białogrzbiete i koniki polskie. Przebywają tu również zwierzęta dzikie rodzimych gatunków jak np.: bielik, puszczyk, gołębie, kruki, kawki, sroka, myszołowy i pustułki. Przywożone są tutaj stopniowo, po odbyciu leczenia w specjalistycznych ośrodkach. Są niezdolne do samodzielnego życia, dlatego też nie mogą zostać wypuszczone na wolność. Spędzą tutaj resztę swojego życia.',
        src:require('../assets/images/location/30.jpg'),
        alt:'Metalowa siatka otacza dwie zagrody dla zwierząt. W jednej zagrodzie stoją dwa nieduże, beżowe konie z czarnymi grzywami i ogonem. W drugiej wypasa się biało-brązowy cielak. W tle rosną drzewa.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:31,
        parkID:4,
        name:'Muzeum przyrodnicze w Chocińskim Młynie',
        description:'Ponad godzinny spacer po przestrzeni wystawy to tak naprawdę niezwykła podróż przez “Torfowiska”, “Lasy” i “Jeziora”. To przygoda, podczas której podglądamy “Nietoperze”, “Ptaki”, “Płazy” i “Ważki”. To wyprawa w nieznane, do “Królestwa Grzybów”. Spotkań z przyrodą na wystawie jest o wiele więcej. Otwierająca ekspozycję strefa “O Parku” przenosi zwiedzających w daleką przeszłość sprzed tysięcy lat, gdy tereny Borów Tucholskich kształtował nie człowiek, a działalność lądolodu. Ekspozycja wyjaśnia czym jest park narodowy i jaka jest jego rola w pojmowaniu wartości posiadanych przez nasz kraj.',
        src:require('../assets/images/location/31.jpg'),
        alt:'Biały budynek muzeum, pokryty czerwoną dachówka, przysłaniają nieliczne drzewa. Wyrastają one z równo przystrzyżonego trawnika. Do muzeum prowadzi ścieżka w kształcie ronda z rosnącymi po środku różami.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:32,
        parkID:4,
        name:'Struga Siedmiu Jezior',
        description:'Struga Siedmiu Jezior to niewielka rzeka (13,9 km) łącząca siedem jezior rynnowych. Swój początek bierze z jeziora Ostrowitego a wpada do Jeziora Charzykowskiego (jeziora leżącego już poza granicami Parku). Poszczególne zbiorniki wodne połączone są krótkimi odcinkami rzecznymi, których łączna długość wynosi 1,9 km. Struga Siedmiu Jezior jest nie tylko wyjątkowym zjawiskiem hydrologicznym, ale także miejscem życia wielu cennych, chronionych, nierzadko ginących gatunków roślin i zwierząt jak np. kruszczyka rdzawoczerwonego, szczeżui wielkiej, bąka czy nocka Natterera.',
        src:require('../assets/images/location/32.jpg'),
        alt:'Niewielka rzeka przecina gęsty las. Na ziemi oraz drzewach leży gruba warstwa śniegu.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 5. Biebrzański Park Narodowy */
    {
        idlocation:33,
        parkID:5,
        name:'Osowiec-Twierdza',
        description:'W Osowcu-Twierdzy znajduje się siedziba Biebrzańskiego Parku Narodowego. Można tu odpocząć, uzyskać informację turystyczną, zakupić pamiątki, zwiedzić ekspozycję przyrodniczą jak również przejść  kilka ścieżek edukacyjnych w najbliższej okolicy. Do dyspozycji turystów jest też pole namiotowe o wdzięcznej nazwie „Bóbr”. Ponadto, przy siedzibie Parku znajduje się jeden z największych zabytków Podlasia – twierdza Osowiec, wybudowana przez Rosjan w XIX w. Zwiedzanie najlepiej zachowanych fragmentów twierdzy oraz jej muzeum możliwe jest wyłącznie z przewodnikiem.',
        src:require('../assets/images/location/33.jpg'),
        alt:'Nieduże pomieszczenie pomalowany jest na zielono. W jego dalszej części stoją figury zwierząt zamieszkujących park - ptaki, łosie, sarny i bobry. Na ścianach wiszą zdjęcia i tablice z opisami ekspozycji.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:34,
        parkID:5,
        name:'Burzyn',
        description:'Wieś, która według legendy jako osada funkcjonowała już w XIII w. Z punktu widokowego w Burzynie roztacza się przepiękna panorama na rozległą dolinę Biebrzy. Imponujący widok zapiera dech w piersiach zwłaszcza wiosną, kiedy rozlewiska rzeki tworzą wraz z lasami, wysepkami i szuwarami trzcin niezwykłą mozaikę wody, lądu i roślinności. Szczególnie mistycznie wygląda w tym miejscu wschód słońca nad doliną. Z wysokiego brzegu rzeki dostrzec można łosie i wiele gatunków ptaków odbywających wiosną swoją migrację z zimowisk na lęgowiska.',
        src:require('../assets/images/location/34.jpg'),
        alt:'Widok z powietrza na dolinę Biebrzy. Rzeka meandruje i tworzy liczne rozlewiska. Tereny dookoła porasta niska roślinność z niewielkimi połaciami drzew. Na horyzoncie rozciąga się las.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:35,
        parkID:5,
        name:'Biały Grąd',
        description:'To urokliwa rozległa wyspa pośród bagien, położona blisko głównego nurtu rzeki Biebrzy. Jest jednym z najlepszych miejsc do obserwacji ptaków w Biebrzańskim Parku Narodowym. Na Biały Grąd prowadzi droga (grobla) od pobliskiej wsi Mścichy. Trasę wyznacza ścieżka edukacyjna. Na jej końcu, przy rzece, znajdują się wieża widokowa i pole namiotowe. Wędrując na Biały Grąd wiosną spotykamy tokujące wprost na drodze bataliony, rozkrzyczane rycyki i mewy śmieszki oraz wiele innych ptaków, w tym rzadkie bociany czarne i kuliki wielkie. To również miejsce godowe żab zielonych: żaby wodnej, żaby jeziorkowej i żaby śmieszki.',
        src:require('../assets/images/location/35.jpg'),
        alt:'Płytka woda rozlewiska porośnięta jest niską trawą. Po środku idzie droga, częściowo zalana. W tle widać niewielkie zarośla krzewów i drzew.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:36,
        parkID:5,
        name:'Bagno Ławki',
        description:'Największy obszar torfowisk niskich w dolinie Biebrzy, dorównujący swoją powierzchnią niemal całemu Białowieskiemu Parkowi Narodowemu. Otwarty obszar turzycowisk, z rzadka porośnięty krzewami i drzewami. Miejsce udostępnione do zwiedzania dzięki wieży widokowej oraz ścieżce edukacyjnej „Długa Luka”, biegnącej drewnianą kładką w głąb bagien. Dogodne miejsce do obserwacji niektórych rzadkich ptaków np. wodniczki, dubelta czy sowy błotnej. Na kładce wygrzewają się często jaszczurki i zaskrońce. Można tu również zobaczyć najmniejszą europejską ważkę – iglicę małą.',
        src:require('../assets/images/location/36.jpg'),
        alt:'Krajobraz torfowiska zakrywa gęsta mgła. Przebijają się zza niej sylwetki nielicznych drzew oraz pomarańczowe promienie słońca.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:37,
        parkID:5,
        name:'Goniądz',
        description:'Goniądz to urokliwe miasteczko położone niemal w samym sercu Biebrzańskiego Parku Narodowego. Znajduje się tu wiele kwater agroturystycznych, zajazd, hotele i sklepy. Blisko rzeki Biebrzy do dyspozycji turystów są: ośrodek rekreacyjny, plaża, wieża i punkt widokowy na dolinę Biebrzy. Funkcjonuje tu również jedyny w Polsce szlak podwodny dla nurków. Przez Goniądz przebiega  Wschodni Szlak Rowerowy Green Velo , łączący na odcinku 2 000 km pięć wschodnich województw Polski. W pobliżu Goniądza leży jeden z najdzikszych obszarów Parku – Brzeziny Kapickie, które są udostępnione do zwiedzania.',
        src:require('../assets/images/location/37.jpg'),
        alt:'Widok z powietrza. Po prawej stronie meandrując płynie rzeka. Po lewej rozciągają się zabudowania niewielkiego miasteczka.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:38,
        parkID:5,
        name:'Rzeka Biebrza',
        description:'Rzeka Biebrza jest “kręgosłupem” Biebrzańskiego Parku Narodowego. Jej źródła znajdują się przy granicy z Białorusią, w okolicach Nowego Dworu. Po pokonaniu 165 km wpada do rzeki Narew. Biebrza płynie leniwie tworząc liczne zakola i meandry. Można je podziwiać spływając rzeką kajakiem, łodzią lub tratwą, ewentualnie wyruszyć wzdłuż brzegów rzeki pieszo, rowerem lub samochodem. Najlepszą trasą do tego jest przyrzeczna droga z Dolistowa Starego do Dębowa. Kończy się przy śluzie Kanału Augustowskiego – zabytku z XIX w. Na trasie wiele pięknych widoków oraz możliwość obserwacji rzadkich gatunków ptaków.',
        src:require('../assets/images/location/38.jpg'),
        alt:'Rzeka Biebrza meandruje wśród podmokłych terenów. Porośnięte są one niską roślinnością i drzewami.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:39,
        parkID:5,
        name:'Grzędy i Czerwone Bagno',
        description:'Odludny, dziki obszar w środkowej części Biebrzańskiego PN. Dawniej funkcjonowały tu, jedne z pierwszych w Polsce, rezerwaty przyrody: Grzędy i Czerwone Bagno. Obecnie są częścią Parku. Grzędy są mozaiką wielu siedlisk, od bagiennych torfowisk, przez olsy, bory, grądy po suche murawy i piaszczyste wydmy. Czerwone Bagno to głównie bory bagienne. Na Grzędach funkcjonuje punkt informacji turystycznej, liczne ścieżki edukacyjne, pole namiotowe, ośrodek rehabilitacji zwierząt i hodowla koników polskich. Szczególną uwagę warto poświęcić wieży widokowej na Wilczej Górze i kładce „Czerwone Bagno”.',
        src:require('../assets/images/location/39.jpg'),
        alt:'Piaszczysta droga prowadzi do drewnianej wieży widokowej. W tle rozciąga się las.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:40,
        parkID:5,
        name:'Ścieżka „Szuszalewo-Nowy Lipsk”',
        description:'Najdłuższa ścieżka edukacyjna Biebrzańskiego PN (długość 5 km). Przebiega w poprzek doliny Biebrzy, przez rozległe mokradła. Rozpoczyna się na wyniesieniu ponad bagnami, z krawędzi którego można podziwiać panoramę doliny. Następnie drewnianą kładką dochodzi do rzeki Biebrzy, którą pokonuje się z pomocą samoobsługowego pomostu pływającego. Dalej ścieżka biegnie strefą szuwarów, szybko ustępującą miejsca bagiennym lasom. Trudno dostępny teren to ostoja wielu cennych gatunków flory i fauny. Na skraju lasu stoi czatownia, która umożliwia obserwacje różnych ptaków oraz łosi, jeleni, a nawet wilków.',
        src:require('../assets/images/location/40.jpg'),
        alt:'Drewniana ścieżka ułożona jest na podmokłym terenie. Porasta go niska trawa i drobne, żółte kwiatki. W tle rozciąga się rzadki, brzozowy las.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 6. Białowieski Park Narodowy */
    {
        idlocation:41,
        parkID:6,
        name:'Dęby w Parku Pałacowym BPN',
        description:'Widoczna na wzgórzu kępa drzew jest pozostałością ogrodu saskiego. Dęby, posadzone wokół osiemnastowiecznego dworu władców, dziś mają ponad 300 lat. W 1925 r. opisano 21 dębów, a do dnia dzisiejszego przetrwało 14. Obfite owocowanie w 1996 roku stworzyło okazję do wyhodowania następców. Pomiędzy starymi dębami wygrodzono powierzchnie z młodymi dąbkami. Obecnie to kilkumetrowe drzewa. Wśród dębów na wzgórzu obserwujemy zmienność fenologiczną. Drzewa wczesne wypuszczające liście na wiosnę 2 tygodnie wcześniej niż rosnące tuż obok drzewa tego samego gatunku, ale późne. Jesienią drzewa wczesne, jako pierwsze owocują i gubią liście. Okazy późne dłużej utrzymują żołędzie i liście.',
        src:require('../assets/images/location/41.jpg'),
        alt:'Krajobraz zasłania gęsta mgła. Na przedzie rosną dwa potężne dęby. W tle rośnie zagajnik z mniejszych drzew. Wszystkie drzewa są bez liści, które to leżą na ziemi.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:42,
        parkID:6,
        name:'„Dąb Jagiełły” w Rezerwacie Ścisłym BPN',
        description:'Dąb Jagiełły (ok. 450 lat, 550 cm obwodu i 40 m wysokości) miał potężne konary, rozbudowaną koronę. Wielka dziupla w dolnej części pnia prawdopodobnie przyczyniła się do jego wywrócenia podczas wichury w 1974 r. Tempo rozkładu martwych drzew i ich znikania z powierzchni gleby jest zróżnicowane i zależne od gatunku drzewa. Na przykładzie dębu Jagiełły – po blisko 50 latach widzimy w dalszym ciągu, jak wielki był jego pień. W tym samym czasie najgrubsze powalone świerki znikają bez śladu. Śmierć drzewa jest naturalnym epizodem w życiu lasu. Proces rozkładu martwego drzewa i towarzyszące mu organizmy uznawane są za wyznacznik naturalności lasu.',
        src:require('../assets/images/location/42.jpg'),
        alt:'Potężny powalony pień dębu leży na leśnej ściółce. Częściowo jest już rozłożony i porasta go mech. Dookoła rośnie las liściasty.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:43,
        parkID:6,
        name:'Brama do Rezerwatu Ścisłego',
        description:'Zabytkowa Brama do Rezerwatu Ścisłego Białowieskiego Parku Narodowego została zaprojektowana i&nbsp;w&nbsp;całości wykonana z drewna dębowego ok. 1930 r. Symbolicznie oddziela świat natury od świata kultury (Polany Białowieskiej). Las naturalny jest kształtowany przez procesy przyrodnicze. Istnieją tu niezmienione przez człowieka układy wód, gleb i wykształcające się na nich drzewostany. Różnorodność zbiorowisk i obecność wielu martwych drzew, których występowanie jest konsekwencją naturalnych procesów, tłumaczy niezwykłe bogactwo królestwa: roślin (paprotników, mchów, wątrobowców), zwierząt (owadów, ptaków, dużych ssaków kopytnych i drobnych ssaków drapieżnych) i grzybów (w tym porostów).',
        src:require('../assets/images/location/43.jpg'),
        alt:'Drewniana brama prowadzi do ogrodzonej części parku. W jej górnej części umieszczono napis “Park Narodowy”. Po lewej znajduje się tablica informacyjna.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:44,
        parkID:6,
        name:'Składnica Szypulowa',
        description:'W 1916 roku Niemcy przystąpili do rabunkowej eksploatacji Puszczy Białowieskiej. Do wywozu drewna wybudowali sieć torów kolejki wąskotorowej. Wycięte drewno przeznaczone do wywozu gromadzono na składnicach przy torach. Lasy dookoła składnicy zostały wycięte. Z czasem odnowiły się w wyniku naturalnej sukcesji, bądź zostały posadzone. Jest to pierwsze pokolenie lasów zmienionych przez człowieka, dlatego ich naturalizacja zachodzić będzie łatwo – szybko staną się prawdziwą puszczą.<br/>W okresie międzywojennym dozorcą tej składnicy była osoba o nazwisku Szypul.',
        src:require('../assets/images/location/44.jpg'),
        alt:'Jasno zielona łąka rozciąga się wśród lasu. Porasta ją niska trawa i drobne kwiatki.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:45,
        parkID:6,
        name:'Muzeum',
        description:'Muzeum Przyrodniczo–Leśne im. Jana Miklaszewskiego BPN jest najstarszym muzeum w polskich parkach narodowych, a także najstarszym muzeum województwa podlaskiego. Dziś to największa stała ekspozycja przyrodnicza w Polsce. W realistycznie zaaranżowanych scenach prezentowane są najbardziej charakterystyczne dla Puszczy zbiorowiska roślinne, procesy ekologiczne, zjawiska przyrodnicze, a także niezwykłe bogactwo gatunków roślin, zwierząt i grzybów. Część ekspozycji poświęcona jest sposobom korzystania z Puszczy na przestrzeni wieków.',
        src:require('../assets/images/location/45.jpg'),
        alt:'Ekspozycja w muzeum przedstawia rodzinę żubrów. Po prawej stronie stoją dwa młode, po lewej dwa dorosłe zwierzęta. W tle znajduje się fototapeta lasu.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    {
        idlocation:46,
        parkID:6,
        name:'Platforma widokowa Kosy Most',
        description:'Obserwacje dzikich zwierząt w ich naturalnym środowisku są ekscytujące, ale wymagają skupienia i cierpliwości. Dobrym miejscem do ich prowadzenia jest Kosy Most. Znajduje się tam platforma widokowa, w której z ukrycia można obserwować zwierzęta. To wyjątkowe miejsce: niezanieczyszczone światłem i hałasem cywilizacji. Najlepszym momentem prowadzenia obserwacji są pory świtu i zmierzchu. Wtedy zwierzęta szukają pożywienia i są aktywniejsze. Uważni obserwatorzy fotografowali w tym miejscu: wilki, żubry, jelenie, sarny, lisy. Nawet jeżeli nie uda Wam się wypatrzeć zwierząt, to samo nasłuchiwanie tętna naturalnego lasu jest niezwykła przygodą. Jeśli zobaczysz zwierzę, pamiętaj aby go nie dotykać. Wiele gatunków (niektóre ptaki, sarny, jelenie, łosie) zostawia swoje młode w bezpiecznym miejscu, ale często wracają do malców żeby je karmić.',
        src:require('../assets/images/location/46.jpg'),
        alt:'Niewielka łąka rozciąga się wśród lasu. Na jej brzegu znajduje się platforma widokowa. Po lewo stoi tablica informacyjna.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:47,
        parkID:6,
        name:'Ols na granicy z grądem',
        description:'Na terenie Parku zachował się charakterystyczny układ przestrzenny i strefowość zbiorowisk leśnych. Najczęstszym zbiorowiskiem jest grąd, czyli wielogatunkowy las z dębem, grabem, lipą, klonem, świerkiem, jesionem i wiązem. W miejscach gdzie obniża się teren i zmienia gleba możemy obserwować przejście grądu w ols. Ols to las bagienny na żyznych siedliskach o charakterystycznej kępiastej i mozaikowej strukturze. Drzewa i krzewy rosną tu na kępach, których rusztowanie tworzą korzenie centralnie rosnącego drzewa. Wysokość kęp może dochodzić do 1,5 m! Dolinki między kępami są przez dużą część roku podtopione i porośnięte gatunkami bagiennymi. Drzewostan olsów tworzy głównie olsza czarna, oprócz niej występuje świerk i brzoza omszona.',
        src:require('../assets/images/location/47.jpg'),
        alt:'Z wód bagna wyrastają drzewa. Woda jest mętna i ciemna. Z tafli wystają opadłe gałęzie i konary.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:48,
        parkID:6,
        name:'Rezerwat Pokazowy Żubrów (RPŻ) i rezerwaty hodowlane',
        description:'RPŻ został wybudowany w 1936 r. w celu prowadzenia hodowli koników typu tarpana. Po II wojnie światowej wydzielono w nim także zagrodę dla żubrów i udostępniono do zwiedzania turystom. Po przebudowie eksponowane są tu także inne zwierzęta: jelenie, sarny, dziki, łosie, wilki, ryś, żbik. Uzupełnieniem ekspozycji są żubronie – mieszańce (hybrydy) żubra z bydłem domowym.<br/>Rezerwaty hodowlane służą rozmnażaniu żubrów posiadających znane pochodzenie. Hodowla rezerwatowa prowadzona jest od 1929 roku. Rezerwaty te nie są udostępniane turystom. Różnorodność siedlisk leśnych zapewnia tu żubrom dostępność naturalnego żeru od wiosny do jesieni. W obszernych zagrodach przebywa około 30 żubrów.<br/>Mimo niewątpliwych osiągnięć w restytucji żubrów należy pamiętać, że są one ciągle gatunkiem zagrożonym ze względu na bliskie pokrewieństwo i różne choroby.',
        src:require('../assets/images/location/48.jpg'),
        alt:'Stado żubrów stoi w ogrodzonej zagrodzie. Składa się z kilku młodych i kilku dorosłych osobników. Na ziemi leży śnieg.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    /** 7. Drawieński Park Narodowy */
    {
        idlocation:49,
        parkID:7,
        name:'Jezioro Czarne i tajemniczy ponor',
        description:'Niezwykle czyste i najgłębsze (29 metrów) z pośród dwudziestu ogółem jezioro w Drawieńskim Parku Narodowym. Jego przejrzyste na 7 metrów, oglądane z brzegu wody mają niezwykły, seledynowy kolor. To jedno z zaledwie 3 w całej Polsce jezior meromiktycznych. Od głębokości 18 metrów poszczególne warstwy jego wód nie mieszają się ze sobą. Jezioro wraz z położonym tuz obok ponorem tworzą parę niezwykłych obiektów. Ponor to naturalne i tajemnicze oczko wodne jest unikatowym na obszarze Pomorza zjawiskiem zanikania wody pod ziemię. Oglądany o różnych porach roku ponor zaskakuje nie tylko widokami drzew odbijającymi się od jego wód ale też zmienną ilością wody wypełniającej jego misę. Wraz z Jeziorem Czarnym stanowi jedną z największych ciekawostek czekających na odkrycie podczas wędrówki Ścieżką dydaktyczną ,,Jezioro Ostrowieckie’’.',
        src:require('../assets/images/location/49.jpg'),
        alt:'Niewielkie oczko wodne otoczone z każdej strony gęstym lasem. Drzewa nie mają liści, a te zalegają na ziemi. W tle widać wody większego jeziora.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:50,
        parkID:7,
        name:'Stara Węgornia na Płocicznej',
        description:'Miejsce leżące około 2 kilometrów od Osady Ostrowite uważane przez wielu za najpiękniejsze w całym Drawieńskim Parku Narodowym. Stara Węgornia to relikty młyna wodnego, mostu i pułapki na węgorze, których rekordowe połowy tutaj to ponad 900 kg w ciągu jednej nocy. Budowla powstała ok. 1820 roku a w wiek później straciła swe znaczenie i powoli niszczała stając się z czasem atrakcją turystyczną przy której kończy swój bieg Ścieżka dydaktyczna „Jezioro Ostrowieckie”.',
        src:require('../assets/images/location/50.jpg'),
        alt:'Rwąca rzeka płynie przez las. Woda jest wzburzona i spieniona. Na porośniętych drzewami brzegach leżą omszałe głazy i powalone gałęzie.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:51,
        parkID:7,
        name:'Elektrownia Wodna Kamienna',
        description:'Jedna z najstarszych elektrowni wodnych w Europie i na Świecie to największy, nie tylko pod względem rozmiarów zabytek techniki na terenie Drawieńskiego Parku Narodowego. Imponuje wiekiem, malowniczym położeniem oraz stanem zachowania oryginalnych urządzeń i wyposażenia. Dolną część elektrowni opasa szara betonowa konstrukcja - przepławka umożliwiająca organizmom żywym przedostanie się zarówno w górę jak i w dół rzeki.  Niezwykle cenny obiekt podziwiamy przemierzając Ścieżkę dydaktyczną „Głusko”.',
        src:require('../assets/images/location/51.jpg'),
        alt:'Woda wypływa z betonowej zapory. W prawej części konstrukcji ustawione są dwa niskie, ceglane budynki. Teren po bokach jest wykoszony, a dookoła rośnie las.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:52,
        parkID:7,
        name:'Wydrzy Głaz',
        description:'Kolos leżący w nurcie Drawy na wysokości osady Sitnica ma ok. 14,2 m średnicy i waży ok. 45,5 tony. Jest największym na terenie Drawieńskiego Parku Narodowego głazem narzutowym przybyłym z terenu Skandynawii. Podziwiać go możemy płynąc kajakiem lub idąc czerwonym szlakiem pieszym na wysokości Sitnicy.',
        src:require('../assets/images/location/52.jpg'),
        alt:'Szeroka rzeka płynie przez las. Po lewej stronie brzegi są strome i wysokie, po prawej niskie. Przy lewym brzegu na wodzie znajduje się duży, omszały głaz.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:53,
        parkID:7,
        name:'Osada Ostrowite',
        description:'W niezwykle malowniczej osadzie, której obecna zabudowa to domy z tzw. muru pruskiego wzniesione dla robotników leśnych w II połowie XIX wieku znajdziemy także relikty opuszczonego kościoła stojącego na nieczynnym dziś cmentarzu ewangelickim. Najpiękniejsza na terenie Drawieńskiego Parku Narodowego zabytkowa nekropolia, na której pochowano też dawnych właścicieli tych ziem urzeka nie tylko położeniem na skraju osady ale też stanem zachowania nagrobków w rozmaitych formach, wśród których na szczególną uwagę zasługuje nagrobek z insygniami kowala czy te w formie ściętych pni „drzew życia”. Niezwykle ciekawe obiekty są jednym z przystanków podczas wędrówki Ścieżką dydaktyczną „Jezioro Ostrowieckie”.',
        src:require('../assets/images/location/53.jpg'),
        alt:'Niski budynek stoi wśród drzew. Jego ściany są otynkowane na biało i przecina je krata z ciemniejszych listew. Od piaszczystej drogi odgradza go drewniany płot.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:54,
        parkID:7,
        name:'Tragankowe urwisko',
        description:'Wysokość tego urwiska o charakterze klifu dochodzi do 30 metrów. Jego południowy stok pełen jest roślin lubiących ciepło. Najciekawsze gatunki to traganek piaskowy, traganek duński czy jastrzębiec żmijowcowaty.',
        src:require('../assets/images/location/54.jpg'),
        alt:'Wysoki brzeg góruje nad Drawą. Rzeka płynie leniwie. Na jej wodzie leżą powalone pnie i gałęzie. Dookoła rosną drzewa i niskie zarośla.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:55,
        parkID:7,
        name:'Ekspozycja Wodny Świat',
        description:'Będąca częścią Punktu Informacji Turystycznej Drawieńskiego Parku Narodowego w „Głusku” ekspozycja pozwala poznać wybrane, pływające w akwariach gatunki ryb wód Parku czy udać się na wirtualny spływ kajakowy. Tutaj dowiemy się też o najciekawszych miejscach na terenie DPN i zakupimy pamiątki.',
        src:require('../assets/images/location/55.jpg'),
        alt:'Ekspozycja znajduje się w jasno oświetlonym pomieszczeniu. Wzdłuż ścian i na środku stoją liczne akwaria. W środku pływają różnokolorowe rybki.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:56,
        parkID:7,
        name:'Ścieżki poznawcze po Pozycji Pomorskiej (Wał Pomorski)',
        description:'Rozsiane w pięciu lokalizacjach we wschodniej części Drawieńskiego Parku Narodowego Ścieżki poznawcze, biorące swe nazwy od miejscowości lub charakterystycznych, lokalnych nazw geograficznych to gratka dla miłośników fortyfikacji. Na Ścieżkach pełnych tablic z tekstami i grafiką poznamy cały przekrój żelbetowych obiektów wzniesionych przez Niemców w połowie lat trzydziestych XX wieku w pobliżu ówczesnej granicy z Polską w ramach budowy fortyfikacji Pozycji Pomorskiej znanej w Polsce pod potoczna nazwą Wał Pomorski. Szczególnie polecane są leżące obok siebie Ścieżki „Mostniki” i „Panzerwerk Mostniki”.',
        src:require('../assets/images/location/56.jpg'),
        alt:'Ruiny żelbetowej konstrukcji wyrastają spomiędzy drzew. Pokryte są grubą warstwą mchu. W niektórych miejscach wystają grube pręty.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 8. Narwiański Park Narodowy */
    {
        idlocation:57,
        parkID:8,
        name:'Ośrodek Edukacji Przyrodniczej Młynarzówka',
        description:'W pochodzącym w 1925 roku dawnym domu młynarza znajduje się ekspozycja przyrodnicza przedstawiająca w przystępny, ciekawy sposób walory przyrodnicze i kulturowe Narwiańskiego Parku Narodowego. Odwiedzający mają możliwość zapoznania się z genezą powstania doliny, cechami systemu rzeki anastomozującej, poznają gatunki roślin i zwierząt spotykanych w Parku oraz dowiadują się o dawnym użytkowaniu. Dużą atrakcją jest akwarium w rybami zamieszkującymi wody Narwi. W Ośrodku znajduje się informacja turystyczna, punkt sprzedaży wydawnictw. Prowadzone są zajęcia edukacyjne.',
        src:require('../assets/images/location/57.jpg'),
        alt:'Budynek wyłożony jest pomarańczową cegłą. Do wejścia prowadzi ganek z łukowatym portalem. Nad portalem wisi godło Polski i nazwa Narwiańskiego Parku Narodowego.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:58,
        parkID:8,
        name:'Dwór w Kurowie',
        description:'Budynek, w którym znajduje się siedziba Dyrekcji Parku powstał pod koniec XIX w., w 1920 r. został rozbudowany. Pierwsze wzmianki o majątku Kurowo pochodzą z 1425 roku, gdy był częścią dóbr Waniewskich. Do II wojny światowej był własnością prywatną, potem znajdował się tu PGR. W 1988 roku stał się siedzibą Narwiańskiego Parku Krajobrazowego, a od 1996 r. Parku Narodowego. Założenie dworsko – ogrodowe w Kurowie objęte jest ochroną konserwatorską. O charakterze parku decyduje duży udział lipy drobnolistnej tworzącej aleje parkowe. Na jego terenie rośnie wiele drzew pomnikowych np. dąb szypułkowy, świerk pospolity, czerwonolistna odmiana klonu.',
        src:require('../assets/images/location/58.jpg'),
        alt:'Nieduży, parterowy dworek otoczony jest niskim żywopłotem. Ściany otynkowane zostały na biało. Spadzisty dach pokrywa czerwona dachówka. Po prawej znajduje się piętrowe skrzydło.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:59,
        parkID:8,
        name:'Kładka Waniewo – Śliwno',
        description:'Ścieżka biegnąca w poprzek doliny Narwi łącząca ze sobą obie miejscowości ma ponad 1 km długości. Jej największą atrakcją są przeprawy przez 4 koryta rzeczne za pomocą pływających pomostów. Na środku kładki znajduje się wieża widokowa, są tu też dwie czatownie do obserwacji ptaków. Trasa prowadzi przez najczęściej występujące w Parku ekosystemy. Jest to miejsce występowania wielu gatunków ptaków wodno-błotnych, które można obserwować w szczególności wiosną.',
        src:require('../assets/images/location/59.jpg'),
        alt:'Widok z powietrza na dolinę Narwi. Rzeka rozlewa się i tworzy niewielkie wysepki. Poprzez podmokły teren prowadzi kładka. Wszystko pokryte jest śniegiem.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:60,
        parkID:8,
        name:'„Zerwany most”',
        description:'Miejsce dawnej przeprawy przez Narew na trasie Białystok – Starosielce - Jeżewo. Pierwszy drewniany most o długości 365 m ukończono w 1903 roku po wielu latach budowy na trudnym, bagiennym terenie. Podczas I wojny światowej w 1915 został podpalony przez Rosjan, aby opóźnić wojska niemieckie. Odbudowano go w 1928 roku, jednak nowa budowla miała wady konstrukcyjne i nie była zbyt stabilna. Most został zniszczony ponownie w 1939 roku w wyniku działań wojennych. Od strony Kurowa i Kruszewa pozostały groble i przyczółki, które pełnią rolę punktów widokowych.',
        src:require('../assets/images/location/60.jpg'),
        alt:'Widok z powietrza na rozlewisko Narwi. Woda pokryta jest licznymi, płaskimi wysepkami. Po prawej i lewej stronie znajdują się zarośnięte groble.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:61,
        parkID:8,
        name:'Reduta obronna „Koziołek”',
        description:'Fortyfikacja ziemna położona w widłach Narwi i Kurówki. Ma kształt nieregularnego siedmioboku, na załamaniach kurtyn znajdowały się 2,5 metrowe bastiony. W stronę Narwi wysunięta jest ostroga umożliwiająca lepszą orientację. Miejscowa historia głosi, że budowla pochodzi z czasów „potopu” szwedzkiego. Przeważa teoria, że powstała wcześniej i jest związana z walkami litewskich rodów Gasztołdów i Radziwiłłów. Obecnie na Koziołku można spotkać stanowiska cennych gatunków roślin min. irysa syberyjskiego, mieczyka dachówkowatego i wielosiła błękitnego. Najłatwiej dotrzeć tam od strony wody.',
        src:require('../assets/images/location/61.jpg'),
        alt:'Widok z powietrza na zarośnięte fortyfikacje. Porastają je liczne brzozy. W dole widać brzeg rzeki.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:62,
        parkID:8,
        name:'Stawy w Topilcu',
        description:'Kompleks dawnych stawów hodowlanych o powierzchni około 36 ha położonych w pobliżu miejscowości Topilec. Zostały one wykupione przez Park w celu ograniczenia niekorzystnego wpływu hodowli ryb i poboru wody na ekosystemy Parku. Planuje się ukształtowanie zasobów przyrody na tym obszarze w taki sposób, aby to miejsce stało się „ptasim rajem”. Jest to miejsce żerowania wielu gatunków m.in czapli i bielika.',
        src:require('../assets/images/location/62.jpg'),
        alt:'Widok z powietrza na zarośnięte stawy. Mają one kształt prostokątów. Oddzielają je porośnięte trawą ścieżki.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:63,
        parkID:8,
        name:'Torfowisko Rynki',
        description:'Jedyne w Narwiańskim Parku Narodowym torfowisko przejściowe zasilane głównie przez wody opadowe. Jest ono zlokalizowane w północno-zachodniej części uroczyska Rynki. Porasta je zbiorowisko turzycy nitkowatej, której towarzyszą turzyca pospolita, siedmiopalecznik błotny i trzcinnik pospolity. Powierzchnia pokryta jest kobiercem mchów torfowców oraz płonnika sztywnego. Spotyka się tu gatunki charakterystyczne dla torfowisk wysokich takie jak rosiczka okrągłolistna i żurawina błotna.',
        src:require('../assets/images/location/63.jpg'),
        alt:'Niska trawa porasta rozległą łąkę. Gdzieniegdzie wyrastają nieliczne krzewy. Nad rozciąga się zachmurzone niebo.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:64,
        parkID:8,
        name:'Wieża widokowa w Łapach Szołajdach',
        description:'Jedna z kilku drewnianych wież widokowych ułatwiających obserwację przyrody Narwiańskiego Parku Narodowego. Znajduje się w południowej części parku. Jest zlokalizowana na nadrzecznych, użytkowanych łąkach. Doskonałe miejsce do obserwacji ptaków podczas przelotów. Regularne koszenie tego terenu sprawia, że jest to również miejsce żerowania lub gniazdowania ptaków wodno – błotnych m.in czajek, rycyków, brodźców, derkaczy.',
        src:require('../assets/images/location/64.jpg'),
        alt:'Drewniana platforma widokowa góruje nad podmokłym terenem. Na spokojnej tafli wody pływają ptaki. Za platformą rosną drzewa.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 9. Park Narodowy „Ujście Warty” */
    {
        idlocation:65,
        parkID:9,
        name:'Ośrodek Muzealno – Edukacyjny w Słońsku',
        description:'Jest to obowiązkowy punkt aby zacząć wizytę w Parku. Nowoczesna, multimedialna wystawa odkrywa przed zwiedzającymi tajemnice ptasiego życia i historię regionu. Interaktywne gry, projekcje i animacje przypadną do gustu zarówno starszym, jak i młodszym. Niesamowitym przeżyciem jest symulacja rejsu łodzią po nieudostępnionym turystycznie fragmencie Parku. Dużo emocji wzbudzają też wirtualny lot balonem i obserwacja ryb w okazałym akwarium. Aplikacje na wystawie pomogą zaplanować wycieczkę oraz odkryją interesujące miejsca w najbliższej okolicy.',
        src:require('../assets/images/location/65.jpg'),
        alt:'Nowoczesny budynek stoi wśród zadbanego trawnika i niskich drzew. Dookoła znajduje się chodnik oraz ławki. Elewacja jest w kolorach brązu i bieli.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:66,
        parkID:9,
        name:'Wieża widokowa na Czarnowskiej Górce',
        description:'Ta trzypiętrowa wieża to doskonały punkt, z którego zobaczysz południową część Parku. Jest ona dostępna bezpośrednio z drogi krajowej nr 22. Nie trzeba nigdzie chodzić aby zachwycić się nadwarciańskim krajobrazem. Wiosną, gdy poziom wody jest wysoki, oczom obserwatora ukażą się ogromne rozlewiska. Latem zastąpi je „morze” traw. Jesienią, z wieży możesz obserwować wylatujące z Parku o wschodzie słońca gęsi i żurawie. Wieży strzegą dwie wielkie czajki, które chętnie pozują do zdjęć. Po obserwacjach można odpocząć pod wiatą lub spędzić czas na placu edukacyjno - sprawnościowym.',
        src:require('../assets/images/location/66.jpg'),
        alt:'Wieża widokowa rozciąga się nad rozległymi łąkami. Prowadzi do niej nieutwardzona droga. Po prawej stronie znajduje się las.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:67,
        parkID:9,
        name:'Czatownia obserwacyjna na ścieżce przyrodniczej „Olszynki”',
        description:'Czatownia jest jednym w elementów ścieżki przyrodniczej „Olszynki”. Ścieżka prowadzi przez podmokły las – ols porzeczkowy oraz łąki. Część trasy pokonuje się drewnianymi kładkami. Kładka prowadzi również do czatowni, ukrytej na brzegu niewielkiej torfianki. Tam z ukrycia można podglądać codzienne życie łabędzi, kaczek, kokoszek czy perkozków. Wprawne oko wypatrzy przemykającego miedzy roślinami wodnika. Wiosną miejsce to rozbrzmiewa koncertem żab moczarowych i kumaków. Na ścieżce znajduje się także wieża widokowa, z której można podziwiać krajobraz Parku.',
        src:require('../assets/images/location/67.jpg'),
        alt:'Niewielka budowla postawiona jest na betonowej platformie. Ośmioboczna konstrukcja posiada wiele małych okienek. W tle rozciąga się woda.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:68,
        parkID:9,
        name:'Ścieżka przyrodnicza „Ptasim szlakiem”',
        description:'Ścieżka  przyrodnicza „Ptasim szlakiem” prowadzi przez jedne z najbardziej atrakcyjnych w Parku terenów lęgowych ptaków. Najlepszym terminem korzystania ze ścieżki jest wiosna i jesień. Na ścieżce przyda się: lornetka, luneta i atlas ptaków. Jest to teren zalewowy. Przy wysokim poziomie wody, może być czasowo niedostępna. Zdarza się to najczęściej zimą i wczesną wiosną. Latem spotkasz tam „ekologiczne kosiarki” czyli krowy i konie, które zjadają roślinność i dzięki temu przygotowują teren dla ptaków, na kolejny sezon lęgowy.',
        src:require('../assets/images/location/68.jpg'),
        alt:'Na niewielkim terenie na Postomii stoi wieża obserwacyjna. Woda rzeki jest spokojna i płynie leniwie. Na horyzoncie rozciąga się las, a ponad nim niebo z nielicznymi chmurami.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:69,
        parkID:9,
        name:'Ścieżka przyrodnicza „Mokradła”',
        description:'Ścieżka ta, choć krótka (1 km), jest bardzo ciekawa. Idąc groblą wśród mokradeł, można tam spotkać ptaki zamieszkujące trzcinowiska, pływające po starorzeczu i przelatujące na Parkowym niebie. Wiosną sprawni obserwatorzy wypatrzą tutaj gniazdo remiza. Nietrudno tutaj też znaleźć ślady obecności bobrów. Liczne zgryzy, bobrowe ścieżki a nawet bobrowe żeremie to stałe elementy ścieżki. Pod wieczór szczęśliwcy mają szanse spotkać członków bobrzej rodziny. Na końcu ścieżki rozpościera się piękny widok na Postomię – jedną z rzek płynących przez Park Narodowy „Ujście Warty”.',
        src:require('../assets/images/location/69.jpg'),
        alt:'Wydeptana ścieżka prowadzi nad brzegiem wody. Nad ziemią nisko przelatują ptaki. Część brzegu porośnięta jest gęstą trzciną.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:70,
        parkID:9,
        name:'Wieża widokowa na ścieżce spacerowej „Torfianka”',
        description:'Aby dostać się do wieży trzeba wybrać się na dłuższy spacer lub wycieczkę rowerową. Wiosną, gdy na łąkach pojawia się woda, stałymi bywalcami są tam czajki i kszyki. Pobliskie kępki trzcin rozbrzmiewają śpiewem podróżniczków i rokitniczek. Latem, gdy koszone są okoliczne łąki, wieża ta jest świetnym miejscem do obserwacji ptaków drapieżnych: kań, błotniaków, pustułek czy bielików. Jesienią i zimą w pobliżu zbierają się stada jeleni i saren. Jest to początek ścieżki spacerowej „Torfianka”, która prowadzi częściowo drewniana kładką na wał przeciwpowodziowy. Rozpościera się tam piękny widok na Wartę.',
        src:require('../assets/images/location/70.jpg'),
        alt:'Wśród traw stoi wieża widokowa. Za nią rośnie gęsty las. Po prawej stronie znajdują się drzewa. Do wieży prowadzi nieutwardzona droga.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:71,
        parkID:9,
        name:'„Przyrodniczy Ogród Zmysłów”',
        description:'„Przyrodniczy Ogród Zmysłów” w Chyrzynie to propozycja dla wszystkich ciekawych świata a zwłaszcza dla najmłodszych. Na niewielkiej powierzchni znajduje się ponad 10 przyrodniczych przystanków. Każdy z nich, pobudzając inne zmysły, pozwala nam odkryć tajemnice przyrodniczego świata. Wzrokiem i dotykiem można wyszukać tropy różnych zwierząt. Słuch pobudza specjalna maszyna do naśladowania dźwięków zwierząt. Można przymierzyć się do sylwetki bielika. Zaś centrum tego miejsca zajmuje niewielki ale bogaty w gatunki ogródek. Można w nim poczuć, powąchać i posmakować różnych roślin.',
        src:require('../assets/images/location/71.jpg'),
        alt:'Ogród otaczają liściaste drzewa. Przez środek trawnika przechodzi ogrodzona ścieżka. Otoczona jest kwiatami. Po prawo znajduje się drewniany tunel porośnięty roślinami.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:72,
        parkID:9,
        name:'Punkt widokowy „Strefa”',
        description:'„Strefa” to punkt widokowy położony na granicy Parku. Przez większą część dnia wydaje się dość niepozorny. Cuda zaczynają się tu dziać o świcie. To właśnie nad tym miejscem wylatują wędrujące ptaki. Jesienią i wiosną, podczas migracji, gęsi i żurawie śpią w Parku a o świcie przelatują na okoliczne łąki i pastwiska. Wielkie stada, podnoszą się z niedostępnych rozlewisk i powoli wylatują poza Park, właśnie nad „Strefą”. Warto tam zajrzeć również o zachodzie słońca, wtedy ptaki te wracają do Parku na swoje noclegowiska. Wystarczy wysiąść z samochodu, usiąść na ławeczce i poczekać aż natura rozpocznie swój spektakl.',
        src:require('../assets/images/location/72.jpg'),
        alt:'Stado ptaków przelatuje nad łąką. Za nimi rozciąga się zarys lasu.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 10. Kampinoski Park Narodowy */
    {
        idlocation:73,
        parkID:10,
        name:'Długie Bagno',
        description:'Długie Bagno – największe torfowisko wysokie na obszarze Kampinoskiego Parku Narodowego. Zajmuje powierzchnię 18,62 ha. Wykształciło się w bezodpływowej misie deflacyjnej, otoczone jest drzewostanem sosnowym znajdującym się w strefie ochrony czynnej i ścisłej. W przeszłości w wyniku działalności człowieka torfowisko zostało podzielone na mniejsze części przez wybudowanie lokalnych dróg oraz częściowo zdegradowane w wyniku wydobywania torfu. Obecnie, dzięki objęciu ochroną ścisłą trwa proces jego naturalizacji. W ostatnich kilkudziesięciu latach zaobserwowano dużą dynamikę zarastania torfowiska.',
        src:require('../assets/images/location/73.jpg'),
        alt:'Widok z powietrza na wyspę. Stoją na niej ruiny zamku. Drzewa nie mają liści. Woda dookoła pokryta jest lodem.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:74,
        parkID:10,
        name:'Obszar Ochrony Ścisłej Sieraków im. prof. Romana Kobendzy',
        description:'Obszar Ochrony Ścisłej Sieraków im. prof. Romana Kobendzy – utworzony jako rezerwat w 1937 roku. Powierzchnia 1204,91 ha. Obejmuje różnorodne środowisko bagien przeplatanych parabolicznymi wydmami śródlądowymi. Przewarzającą część obszaru porastają olsy z występującymi wyspowo grądami. Liczne gatunki flory: chamedafne północna (relikt epoki polodowcowej), lilia złotogłów, łuskiewnik różowy, kosaciec syberyjski, kruszczyk błotny, buławnik czerwony, kokorycz pełna, piżmaczek, zerwa kłosowa, żurawina błotna, miodownik melisowaty, orlik pospolity, prosienicznik plamisty, oraz ze względu na niedostępność terenu fauny: bocian czarny, trzmielojad, żuraw, bóbr, łoś, wilk.',
        src:require('../assets/images/location/74.jpg'),
        alt:'Widok z powietrza na zarośnięte w większości jezioro. Dookoła rośnie gęsty las. W dali rozciągają się pola uprawne.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:75,
        parkID:10,
        name:'Skansen budownictwa puszczańskiego w Granicy',
        description:'Skansen budownictwa puszczańskiego w Granicy – niewielki skansen we wsi Granica k. Kampinosu. Reprezentuje w dużej mierze zanikłe drewniane budownictwo ludowe Puszczy Kampinoskiej. Wchodzi w skład Ośrodka Dydaktyczno-Muzealnego KPN. Na zabudowania skansenu składają się trzy zagrody nazwane od ostatnich właścicieli budynków mieszkalnych zagrodami Widymajera, Wiejckiej i Połcia. Wewnątrz ekspozycje etnograficzne i historyczne.',
        src:require('../assets/images/location/75.jpg'),
        alt:'Widok z powietrza na wysoki wał. Podzielony jest on na pięć części. Dookoła rośnie las. Oddziela go od płynącej obok rzeki.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:76,
        parkID:10,
        name:'Łużowa Góra',
        description:'Łużowa Góra – jedna z najwyższych (100,6 m. n.p.m.) parabolicznych wydm w Puszczy Kampinoskiej. W latach sześćdziesiątych XX wieku na północnym ramieniu wydmy rozpoczęto budowę obiektów, stanowiących kwaterę dla dowództwa Układu Warszawskiego na wypadek wojny atomowej. Prace kontynuowano do późnych lat osiemdziesiątych. Powstał wówczas kompleks znany jako Zapasowe Dowództwo Układu Warszawskiego, Atomowa Kwatera Dowodzenia albo Kompleks 7215. Opuszczone przez wojsko i niszczejące obiekty w 2003 roku przekazano Kampinoskiemu Parkowi Narodowemu. W ramach realizowanego przez KPN projektu „Łużowa Góra” nadziemne obiekty powojskowe rozebrano. W ich miejsce odtworzono siedlisko rzadkich i cennych muraw napiaskowych. Natomiast podziemny budynek halowy przysposobiono do hibernujących tu nietoperzy. Zwiedzających to miejsce wita mural z wizerunkiem gacka brunatnego.',
        src:require('../assets/images/location/76.jpg'),
        alt:'W gładkiej wodzie jeziora odbijają się nadbrzeżny las. Liście drzew są zielone, pomarańczowe i żółte.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:77,
        parkID:10,
        name:'Sochaczewska Kolej Muzealna',
        description:'Sochaczewska Kolej Muzealna – wąskotorowa kolej turystyczna funkcjonująca dawniej jako Powiatowa Kolej Sochaczewska. Kolejka prowadziła przewozy pasażerskie w latach 1922 – 1984. W 1986 roku na stacji Sochaczew Wąskotorowy utworzono muzeum kolei wąskotorowej. Dziś jest to największe tego typu muzeum w Europie. W chwili obecnej na Sochaczewskiej Kolei Muzealnej kursują wyłącznie pociągi turystyczne na odcinku Sochaczew-Wilcze Tułowskie. Stacja końcowa leży na terenie Kampinoskiego Parku Narodowego.',
        src:require('../assets/images/location/77.jpg'),
        alt:'Drzewa porastają łagodne zbocze w lesie. Ich liście mają jesienne barwy - żółte, zielone i pomarańczowe. Część opadła już na ziemię.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:78,
        parkID:10,
        name:'Dąb Kobendzy',
        description:'Dąb Kobendzy – najbardziej znane drzewo Puszczy Kampinoskiej. Dąb szypułkowy rosnący przy czerwonym szlaku turystycznym, w obszarze ochrony ścisłej Krzywa Góra, na pograniczu wydm i bagien. Obwód drzewa w pierśnicy ok. 520 cm. Jego wiek ocenia się na około 400 lat. Wcześniej nazywany Starym Dębem albo Jagiellonem. Obecne imię otrzymał dla upamiętnienia prof. Romana Kobendzy, znakomitego botanika, badacza puszczy i orędownika utworzenia Kampinoskiego Parku Narodowego.',
        src:require('../assets/images/location/78.jpg'),
        alt:'Widok z powietrza na jezioro w kształcie fasolki. Z każdej strony otacza go gęsty las. Woda zbiornika jest gładka i wielu miejscach porośnięta roślinnością.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:79,
        parkID:10,
        name:'Cmentarz Mauzoleum Palmiry',
        description:'Cmentarz Mauzoleum Palmiry – cmentarz z grobami 2115 ofiar egzekucji dokonywanych przez hitlerowców w Puszczy Kampinoskiej, w latach 1939-41. Spoczywają tu wybitni działacze polityczni i społeczni, naukowcy i intelektualiści, sportowcy, twórcy kultury, m.in. Maciej Rataj, Janusz Kusociński. Egzekucje, o których świat nigdy nie miał się dowiedzieć wyszły na jaw dzięki leśnikom i miejscowej ludności. Obok Muzeum – Miejsce Pamięci Palmiry z ekspozycją poświęconą wydarzeniom wojennym w Puszczy Kampinoskiej.',
        src:require('../assets/images/location/79.jpg'),
        alt:'Kamienna studnia znajduje się pod spadzistym dachem. Oparty jest on na czterech drewnianych kolumnach. Teren wokół wyłożony jest płaskimi kamieniami. Za studnią rosną drzewa i krzewy.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:80,
        parkID:10,
        name:'Ścieżka dydaktyczna „Wokół Bieli”',
        description:'Ścieżka dydaktyczna „Wokół Bieli” – pętla długości ok. 3,5 km., wokół obszaru ochrony ścisłej Biela oraz skrajem obszaru ochrony ścisłej Wilków. Dziesięć przystanków opisanych tablicami. Trasa wspina się na wał wydmowy o jednym z większych przewyższeń – ponad 20 m. wysokości względnej. Prowadzi przez bory sosnowe z fragmentami starodrzewiu, brzeziny, lasy olchowe i zarośla wierzbowe. Przy szlaku stanowiska cennych roślin: widłak jałowcowaty, konwalia majowa. Przy ścieżce głaz upamiętniający dotarcie tutaj pierwszej na ziemiach polskich, zorganizowanej przez PTK w 1907 r. wycieczki nizinnej.',
        src:require('../assets/images/location/80.jpg'),
        alt:'Tafla rozległego jeziora jest oblodzona i odbija się od niej zimowe słońce. Zbiornik otoczony jest lasem. Na brzegu leży warstwa śniegu. Wyrastają z niej wysokie, suche trawy.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 11. Wielkopolski Park Narodowy */
    {
        idlocation:81,
        parkID:11,
        name:'Zamek',
        description:'Wizytówką Wielkopolskiego Parku Narodowego jest będąca kemowym pagórkiem Wyspa Zamkowa, wraz z ruinami neogotyckiego zamku wzniesionego w latach 1824 – 1825. Budowla była prezentem ślubnym Tytusa Działyńskiego dla swojej siostry Klaudyny Potockiej. W roku 1830 po wybuchu powstania listopadowego Potoccy wyjechali do Warszawy, nigdy już nie wracając. Podczas trwającej w 1848 roku Wiosny Ludów zamek został zniszczony pruską artylerią. Do dnia dzisiejszego budynek pozostaje w ruinie, gdyż Jezioro Góreckie na którym się znajduje, objęte jest obszarem ochrony ścisłej.',
        src:require('../assets/images/location/81.jpg'),
        alt:'Widok z powietrza na wyspę. Stoją na niej ruiny zamku. Drzewa nie mają liści. Woda dookoła pokryta jest lodem.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:82,
        parkID:11,
        name:'Trzcielin',
        description:'Obszarem ochrony ścisłej, chroniącym silnie zarastające jezioro Trzcielińskie, będące miejscem lęgowym wielu gatunków ptaków wodnych i błotnych, jest Trzcielińskie Bagno. Obszar ten jako wysunięta enklawa Parku został włączony do jego granic w 1996 roku. Największą jego powierzchnię zajmują szuwary złożone z trzciny pospolitej, pałki wąskolistnej oraz turzyc. Ponadto jest to ostoja dla ponad 60 gatunków ptaków, w tym tak wyjątkowych jak błotniak stawowy oraz kania ruda. Doskonałym punktem obserwacyjnym na ten teren jest wieża widokowa, stojąca na skraju lasu oraz jeziora.',
        src:require('../assets/images/location/82.jpg'),
        alt:'Widok z powietrza na zarośnięte w większości jezioro. Dookoła rośnie gęsty las. W dali rozciągają się pola uprawne.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:83,
        parkID:11,
        name:'Oz',
        description:'Oz Bukowsko – Mosiński, sięgający długości 37 km jest najdłuższą tego typu formą polodowcową w Polsce. Powstał on poprzez nagromadzenie piasku i żwiru w korycie rzeki podlodowcowej, które po ustąpieniu lądolodu utworzyły kręte i długie wały. Końcowy fragment ozu nazywany jest Szwedzkimi Górami. Został on w średniowieczu rozkopany przez tutejszą ludność w celach obronnych, tworząc pięć charakterystycznych wzniesień – gór.',
        src:require('../assets/images/location/83.jpg'),
        alt:'Widok z powietrza na wysoki wał. Podzielony jest on na pięć części. Dookoła rośnie las. Oddziela go od płynącej obok rzeki.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:84,
        parkID:11,
        name:'Kociołek',
        description:'Nazwa Jeziora Kociołek, będącego również formą polodowcową, nawiązuje do genezy jego powstania. Misa tego jeziora została wydrążona przez kamienie poruszane wirującą wodą w polodowcowej rzece, podczas procesu zwanego eworsją. Ze względu na stromo opadające brzegi, stosunkowo dużą głębokość (sięgającą 8 m.) oraz osłonięcie lasem występuje tu w dzisiejszych czasach zjawisko meromiksji. Proces ten polega na niepełnej cyrkulacji wody w jeziorze w ciągu jednego roku. W lesie rosnącym nad brzegiem jeziora rośnie wiele okazałych drzew, w tym dąb szypułkowy, będący pomnikiem przyrody.',
        src:require('../assets/images/location/84.jpg'),
        alt:'W gładkiej wodzie jeziora odbijają się nadbrzeżny las. Liście drzew są zielone, pomarańczowe i żółte.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:85,
        parkID:11,
        name:'Żabiak',
        description:'Oczko wodne Żabiak jest obniżeniem wytopiskowym, powstałym na skutek wytopienia się oderwanego fragmentu lądolodu, tzw. bryły martwego lodu. Szerokość Żabiaka sięga 60 metrów, zaś długość 110 m. Jego głębokość sięgająca 2 metrów nie jest duża, lecz należy zwrócić uwagę, iż głębokość całego obniżenia wytopiskowego jest znacznie większa, pokazując jak duży fragment lodu oderwał się w tym miejscu.',
        src:require('../assets/images/location/85.jpg'),
        alt:'Drzewa porastają łagodne zbocze w lesie. Ich liście mają jesienne barwy - żółte, zielone i pomarańczowe. Część opadła już na ziemię.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:86,
        parkID:11,
        name:'Skrzynka',
        description:'Jedną z osiemnastu obszarów ochrony ścisłej jest Jezioro Skrzynka, chroniące florę i faunę jedynego jeziora skąpożywnego (dystrofinczego), będącego w fazie silnego zarastania. Proces ten przebiega w charakterystyczny sposób: wokół tafli jeziora narasta pas torfowców, tworząc pło, czyli specyficzny kożuch. Jezioro porośnięte jest około 70 gatunkami roślin naczyniowych, w tym gatunkami chronionymi, takimi jak: rosiczka okrągłolistna oraz bagnica torfowa. Fauna jeziora reprezentowana jest głównie przez liczne gatunki bezkręgowców.',
        src:require('../assets/images/location/86.jpg'),
        alt:'Widok z powietrza na jezioro w kształcie fasolki. Z każdej strony otacza go gęsty las. Woda zbiornika jest gładka i wielu miejscach porośnięta roślinnością.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:87,
        parkID:11,
        name:'Studnia',
        description:'W okolicach najwyższego wzniesienia Wielkopolskiego Parku Narodowego, tj. Osowej Góry znajduje się charakterystyczny punkt – studnia Napoleona, z którą związana jest pewna legenda. Głosi ona, że w 1812 roku Napoleon wraz ze sobą armią zatrzymał się w tej okolicy przed wyprawą na Moskwę. By ugasić pragnienie francuskiego cesarza, ze studni zaczerpnięto wody, której smak żywo przypominał szampana. Legenda ta głosi również, iż raz do roku woda z tej studni zamienia się właśnie w szampana, lecz tylko wtedy, gdy pijący posiada czyste serce oraz szczere zamiary.',
        src:require('../assets/images/location/87.jpg'),
        alt:'Kamienna studnia znajduje się pod spadzistym dachem. Oparty jest on na czterech drewnianych kolumnach. Teren wokół wyłożony jest płaskimi kamieniami. Za studnią rosną drzewa i krzewy.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:88,
        parkID:11,
        name:'Szlak czerwony',
        description:'Wzdłuż brzegów najgłębszego jeziora w Wielkopolskim Parku Narodowym – Jeziora Góreckiego przebiega czerwony szlak turystyczny. Na jeziorze znajdują się dwa pagórki kemowe, dziś będące wyspami: Zamkową oraz Kopczyskiem. Wędrując wzdłuż porośniętych lasem brzegów mija się wiele dolinek erozyjnych – miejsc w których spływały szybkie, rwące rzeki zasilające ogromną rzekę lodowcową, po której dzisiaj pozostałością jest właśnie jezioro Góreckie. Brzegi jeziora są jednocześnie miejscem przez które przebiegają fragmenty tras wycieczkowych: Adama Wodziczki oraz Kosynierów.',
        src:require('../assets/images/location/88.jpg'),
        alt:'Tafla rozległego jeziora jest oblodzona i odbija się od niej zimowe słońce. Zbiornik otoczony jest lasem. Na brzegu leży warstwa śniegu. Wyrastają z niej wysokie, suche trawy.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 12. Poleski Park Narodowy */
    {
        idlocation:89,
        parkID:12,
        name:'Ośrodek edukacyjny „Poleskie Sioło”',
        description:'Ośrodek edukacyjny „Poleskie Sioło” został zbudowany na wzór tradycyjnych zagród poleskich sprzed wielu lat. Jego zadaniem jest uczyć o kulturze i zwyczajach dawnych mieszkańców. Znajduje się tu budynek mieszkalny, stodoła oraz spichlerz – wykonane z drewna i kryte strzechą – oraz piwnica typu ziemianka i studnia z „żurawiem” do pobierania wody. Zgromadzone w ośrodku eksponaty gospodarstwa domowego pozwalają przenieść się w czasie i poznać trudy życia na poleskiej wsi. To ważny punkt na mapie Poleskiego Parku Narodowego, pozwalający ochronić przed zapomnieniem wyjątkową kulturę Polesia.',
        src:require('../assets/images/location/89.jpg'),
        alt:'Wnętrze klasycznego drewnianego domku, przyozdobione licznymi eksponatami z minionych czasów. Na ścianach wisi lustro oraz liczne obrazy. Pod nimi stoją zaś drewniane skrzynie oraz wąskie łóżko. Na środku pokoju ulokowano zaś niewielki stolik i krzesło.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:90,
        parkID:12,
        name:'Ośrodek Ochrony Żółwia Błotnego',
        description:'W Ośrodku Ochrony Żółwia Błotnego poznamy z bliska jednego z najcenniejszych mieszkańców Polesia. Małe żółwie są tu przenoszone z lęgowisk znajdujących się na terenie Poleskiego Parku Narodowego. Żółwiki, które nie zdążą się wykluć przed ochłodzeniem, są przenoszone do ośrodka jeszcze w formie jaj. Tutaj, w specjalnych inkubatorach, rozwijają się aż do wyklucia. Wiosną wszystkie maluchy są wypuszczane na wolność i zaczynają wieść samodzielne życie, które może trwać nawet 100 lat. Te długowieczne gady to drapieżniki z krwi i kości. Polują na drobne owady i bezkręgowce, nie gardzą także padliną.',
        src:require('../assets/images/location/90.jpg'),
        alt:'Skromnych rozmiarów żółw, o wyprężonym w górę łebku. Jego skorupa, ciemna jak i reszta ciała, pokryta jest drobinkami zielonych liści.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:91,
        parkID:12,
        name:'Ścieżka przyrodnicza Dąb Dominik',
        description:'Ścieżka przyrodnicza „Dąb Dominik” to obowiązkowy przystanek podczas zwiedzania Poleskiego Parku Narodowego. Drewniane kładki prowadzą wprost do brzegu Jeziora Moszne, które stanowi dom dla wielu gatunków zwierząt, między innymi ptaków. Latem możemy tu obserwować tętniącą życiem kolonię lęgową mew i rybitw. Ścieżka zachwyca także bogactwem flory. Torfowisko przejściowe otaczające jezioro może poszczycić się obecnością m.in. polującej na owady rosiczki okrągłolistnej, pięknie kwitnących storczyków czy też reliktów polodowcowych – brzozy niskiej, wierzby lapońskiej oraz wierzby borówkolistnej.',
        src:require('../assets/images/location/91.jpg'),
        alt:'Drewniany mostek, lawirujący między leśnymi drzewami o wąskich pniach. Spomiędzy ich listowia przebiją się promienie zachodzącego w tle słońca.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:92,
        parkID:12,
        name:'Ścieżka przyrodnicza Spławy',
        description:'Ścieżka przyrodnicza „Spławy” prowadzi do Jeziora Łukie – największego jeziora na terenie Poleskiego Parku Narodowego. Jest to stosunkowo płytki zbiornik, o bogatej roślinności wodnej i szuwarowej. Jego przybrzeżna część stanowi dom dla wielu gatunków ptaków. Gnieżdżą się tu m.in. kaczki – krzyżówki, głowienki, czernice – a także wodniki czy zielonki. Wędrując ścieżką, znajdujemy się na terenie dawnego gospodarstwa rybackiego. Jego właściciel mieszkał wraz z rodziną w drewnianym dworze z widokiem na jezioro. Dzisiaj po dawnym gospodarstwie niemal nie ma śladu.',
        src:require('../assets/images/location/92.jpg'),
        alt:'Wąski, skręcający w lewo drewniany mostek pozbawiony poręczy. Umożliwia on przejście suchą nogą otaczającego go podmokłego, lesistego krajobrazu.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:93,
        parkID:12,
        name:'Ścieżka przyrodnicza Perehod',
        description:'Ścieżka przyrodnicza „Perehod” to raj dla ornitologów. Spotkamy tu czaple, perkozy, łabędzie nieme, a także jednego z największych ptasich drapieżników – bielika. Ścieżka prowadzi wokół kompleksu stawów, dlatego jest szczególnie atrakcyjna w okresie wiosennych czy jesiennych przelotów ptaków. Dla ich lepszej obserwacji przygotowano dwie wieże widokowe oraz schron ornitologiczny. Jednak nie tylko ptaki znalazły tu dogodne warunki do życia – nierzadko zaobserwujemy tu wygrzewające się żółwie błotne. Chętnie przesiadują na wystających z wody konarach, łapiąc promienie słońca.',
        src:require('../assets/images/location/93.jpg'),
        alt:'Biegnąca wzdłuż ściany lasu droga. Obok niej leży płaska polanka o niskiej trawie. Na niebie wisi słońce w pełnym blasku, przysłonięte jedynie przez kilka chmurek.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:94,
        parkID:12,
        name:'Ścieżka przyrodnicza Obóz Powstańczy',
        description:'Ścieżka „Obóz Powstańczy” jest miejscem, które poza walorami przyrodniczymi posiada także bardzo ważny aspekt historyczny. To właśnie tutaj znajdował się największy obóz Powstańców Styczniowych. Funkcjonował do marca 1864 roku, a jego założycielem był podpułkownik Karol Krysiński. Trasa prowadzi do wieży widokowej, z której rozpościera się widok na rozległe łąki. Przy odrobinie szczęścia zaobserwujemy tu największego mieszkańca Poleskiego Parku Narodowego – łosia.',
        src:require('../assets/images/location/94.jpg'),
        alt:'Leśna ścieżka otoczona przez smukłe drzewka, których gałęzie spowijają drogę liściastym baldachimem. Spomiędzy pni przebija się blask zachodzącego złocistego słońca.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:95,
        parkID:12,
        name:'Ścieżka przyrodnicza Czahary',
        description:'Ścieżka „Czahary” zachwyca bogactwem flory i fauny. Prowadzi przez Bagno Bubnów, największe torfowisko Poleskiego Parku Narodowego. Na tworzących się tu rozlewiskach swoje miejsce noclegowe mają żurawie. W sierpniowe i wrześniowe poranki całe bagno dudni ich głosem – klangorem. Usłyszymy tu także rzadką wodniczkę. Ten niepozorny ptak z rodziny trzciniaków jest najmniejszym migrującym ptakiem Europy. Oko wędrowca zachwycą kwitnące storczyki – podkolan biały, kruszczyk błotny czy kukułka krwista – oraz motyle, między innymi rzadka przeplatka aurinia.',
        src:require('../assets/images/location/95.jpg'),
        alt:'Ścieżka ułożona z desek, spomiędzy których przebijają się kępki zielonej trawy. Dróżka biegnie przez płaski, trawiasty krajobraz. W tle widać zachodzące słońce, pasmo chmur oraz unoszącą się też nad gruntem mgiełkę.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:96,
        parkID:12,
        name:'Ośrodek Dydaktyczno-Muzealny',
        description:'Ośrodek Dydaktyczno-Muzealny jest ważnym obiektem edukacyjnym Poleskiego Parku Narodowego. Zgromadzono tu eksponaty archeologiczne, etnograficzne, historyczne i przyrodnicze, a także prezentujące dziedzictwo kulturowe Polesia Zachodniego. W części zewnętrznej ośrodka znajduje się wiata na duże eksponaty, oczko wodne z żółwiami błotnymi, Ośrodek Rehabilitacji Zwierząt, zadaszenie oraz ognisko. Z myślą o najmłodszych powstała tu ścieżka „Żółwik” z zabawami i tablicami edukacyjnymi.',
        src:require('../assets/images/location/96.jpg'),
        alt:'Wnętrze pomieszczenia o dydaktyczno-muzealnym charakterze. Pełne jest ono najróżniejszych eksponatów , takich jak stojąca w jego centrum lalka. Na ścianie wisi półka z butelkami, w rogu zaś znajduje się kominek.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 13. Świętokrzyski Park Narodowy */
    {
        idlocation:97,
        parkID:13,
        name:'Gołoborze',
        description:'Wskutek wietrzenia piaskowca kwarcytowego powstały gołoborza. „Gołoborze” to ludowe określenie miejsc „gołych od boru”, czyli nieporośniętych lasem. Są to tereny pokryte głazami. W Świętokrzyskim Parku Narodowym zajmują około 20 ha, a największe o powierzchni około 4 ha, znajduje się na zboczu Łysej Góry. Znajduje się tam specjalna galeria widokowa, z której turyści mogą podziwiać najbardziej charakterystyczny element przyrody nieożywionej Parku oraz krajobraz świętokrzyskich pól, nazywanych pasiakami. Dotrzeć tam można wędrując szlakiem z Nowej Słupi lub z Huty Szklanej.',
        src:require('../assets/images/location/97.jpg'),
        alt:'Pełne drobnych kamieni zbocze otoczone przez ścianę lasu, który przesycony jest drzewami o listowiu przybierającym najróżniejsze jesienne barwy. W tle ulokowany poniżej, wieczorny równinny krajobraz.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:98,
        parkID:13,
        name:'Kamienny wał kultowy',
        description:'Wzniesienie kamiennego wału kultowego szacuje się na VIII-XI wiek. Ułożono go na Łysej Górze z głazów piaskowca kwarcytowego tworzącego miejscowe gołoborza. Wał został ułożony w miejscu dawnego ośrodka pogańskiego, gdzie wierzono w trzy bóstwa: Łada, Boda i Leli. Miał za zadanie oddzielić sacrum – miejsce święte od profanum – obszarów życia codziennego. Długość wału kultowego wynosi około 1,5 km, zaś jego wysokość osiąga miejscami aż 2 metry. Kamienny wał kultowy można zobaczyć przy szczycie Łysej Góry. Dotrzeć tam można wędrując szlakiem z Nowej Słupi lub z Huty Szklanej.',
        src:require('../assets/images/location/98.jpg'),
        alt:'Ciągnąca się między drzewami spiralna ścieżka, utworzona z sypkich kanciastych kamieni. Mimo swojej symetrii, nie stanowi ona efektu pracy człowieka, lecz dzieło natury.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:99,
        parkID:13,
        name:'Łysica',
        description:'Łysica to najwyższy, dwuwierzchołkowy szczyt Gór Świętokrzyskich. Uznawany dotąd za najwyższy wierzchołek zachodni mierzy 613,31 m n.p.m., zaś wschodni zwany Skałą Agaty 613,96 m n.p.m. Łysica jest najniższym z 28 szczytów tworzących Koronę Gór Polski. Legendy mówią, że to właśnie na szczycie Łysicy odbywały się sabaty czarownic. Góra jest prawie w całości porośnięta lasem, składającym się głównie z jodeł i buków. Na Łysicę najlepiej wybrać się ze Świętej Katarzyny lub nieco łatwiejszą trasą z Kakonina.',
        src:require('../assets/images/location/99.jpg'),
        alt:'Poranny, mglisty widok  z wnętrza lasu. Przez korony iglastych drzew przebijają się promienie słońca, padające na pokryte mchem kamienie.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:100,
        parkID:13,
        name:'Kapliczka Świętego Mikołaja',
        description:'Kapliczka wybudowana w XIX w. znajduje się na Przełęczy Świętego Mikołaja. Choć Święty Mikołaj kojarzy się z prezentami, jest on również patronem wielu osób, w tym również podróżników. Niegdyś miał chronić wędrowców przed dzikimi zwierzętami, zwłaszcza wilkami. Kapliczka usytuowana pośrodku rozległej leśnej puszczy, była w przeszłości traktowana jako bezpieczne miejsce do odpoczynku w czasie podróży z jednej wsi do drugiej. Do kapliczki można dojść idąc ze wsi Kakonin lub schodząc z Łysicy czerwonym szlakiem.',
        src:require('../assets/images/location/100.jpg'),
        alt:'Drewniana kapliczka przypominająca skromnych rozmiarów altankę. Jej wnętrze chronione jest przez pochyły dach, którego front pokryty jest przez ozdobny krzyż. Konstrukcja ulokowana jest wewnątrz lasu.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:101,
        parkID:13,
        name:'Kapliczka i źródełko Świętego Franciszka',
        description:'Kapliczka i źródełko Świętego Franciszka są położne przy szlaku czerwonym biegnącym ze Świętej Katarzyny na Łysicę. Są to jedne z najczęściej odwiedzanych miejsc w Świętokrzyskim Parku Narodowym. Dzieje kapliczki sięgają XVII w., kiedy bernardyńscy bracia z pobliskiego klasztoru postanowili wznieść kapliczkę pod wezwaniem św. Franciszka, przyjaciela braci mniejszych - patrona zwierząt, ekologii i ekologów. Poniżej kapliczki znajduje się źródełko. Woda w źródełku ma stałą temperaturę i nigdy nie zamarza, ponadto według miejscowych wierzeń ma moc leczenia oczu.',
        src:require('../assets/images/location/101.jpg'),
        alt:'Drewniana kapliczka ściśle okolona przez górujące nad nią drzewa. Konstrukcja jest w pełni zabudowana ścianami, z pojedynczymi frontalnymi drzwiczkami. W zewnętrznej wnęce nad wejściem stoi figura Świętego Franciszka, zaś tuż nad nim z dachu wyrasta krzyż.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:102,
        parkID:13,
        name:'Droga Królewska',
        description:'Droga Królewska to nazwa szlaku prowadzącego z Nowej Słupi do zespołu klasztornego na Łysej Górze. To właśnie tędy pielgrzymowali pieszo polscy królowie do Relikwii Drzewa Krzyża Świętego. Otoczenie tego miejsca szczególną opieką przez ówczesnych władców oraz religijna postawa stanowiło ogromne znaczenie dla promowania i utrzymania zwyczaju pielgrzymowania na Święty Krzyż. Co ciekawe, nawet Władysław Jagiełło odwiedził klasztor w czasie drogi na pole bitwy pod Grunwaldem z pobożną intencją wymodlenia zwycięstwa.',
        src:require('../assets/images/location/102.jpg'),
        alt:'Ciągnący się nad leśną doliną mostek o kamiennym podłożu, które tak samo jak i reszta krajobrazu przykryte jest opadłymi z drzew liśćmi.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:103,
        parkID:13,
        name:'Mamucie jodły',
        description:'Potężne, mierzące ponad 40 m wysokości i mające ponad 200 lat „mamucie jodły” to chluba Świętokrzyskiego Parku Narodowego. Do dziś wiele z tych okazałych drzew jest zdrowych i żadne mocniejsze podmuchy wiatru nie są im straszne. Największe okazy można obejrzeć podążając czerwonym szlakiem z Trzcianki na Łysą Górę. Idąc tym szlakiem mija się jedną z największych jodeł, która wywróciła się z powodu zalegającego mokrego śniegu w jej w koronie. Jednak nic w przyrodzie nie ginie, leżące drewno podczas rozkładu wzbogaca leśną różnorodność i jest schronieniem dla wielu organizmów.',
        src:require('../assets/images/location/103.jpg'),
        alt:'Powalone drzewo, leżące wśród innej leśnej flory. Ma ono gruby pień, częściowo odarty z kory, a w pojedynczych miejscach porośnięty przez grzyby.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:104,
        parkID:13,
        name:'Góra Chełmowa',
        description:'Chełmowa Góra mierząca 351 m n.p.m. to niewielka, kopulasta w kształcie, parkowa eksklawa. Mimo swej niepozorności jest wyjątkowa. Porastają ją relikty – około 200 letnie modrzewie polskie. Na starych modrzewiach występuje unikalny w skali Europy pniarek lekarski – grzyb wykorzystywany niegdyś w medycynie ludowej. Natomiast pod modrzewiami można zauważyć liczne mrowiska, na całej górze jest ich około 500! Unikatowość tego miejsca dostrzeżono już dawno, w 1920 roku utworzono tutaj jeden z pierwszych w Polsce i pierwszy w Górach Świętokrzyskich rezerwat przyrody.',
        src:require('../assets/images/location/104.jpg'),
        alt:'Drzewo o nienaturalnie zagiętym pniu, który u swojej podstawy jest bardzo pochyły, a dopiero jego wyższa część wraca do bardziej standardowej pionowej orientacji. Dookoła rosną inne pozbawione liści drzewa, z przodu zaś kwitną kwiaty o białych liściach.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 14. Roztoczański Park Narodowy */
    {
        idlocation:105,
        parkID:14,
        name:'Ośrodek Edukacyjno-Muzealny',
        description:'Wizytę w Roztoczańskim Parku Narodowym proponujemy rozpocząć od Ośrodka Edukacyjno-Muzealnego (OEM) usytuowanego w zabytkowej części Zwierzyńca, w sąsiedztwie  jednego z najstarszych w Polsce browarów z 1806 roku, obok dyrekcji  RPN  mieszczącej się również w zabytkowym budynku Domu Plenipotenta Ordynacji Zamojskiej. Zanim jednak udamy się do OEM należy wstąpić do położonego tuż obok Punktu Informacji Turystycznej gdzie możemy zakupić karty wstępu do OEM i na teren płatnych ścieżek do Parku. Tutaj również uzyskamy zarówno informacje o zasadach zwiedzania Parku, jego najciekawszych atrakcjach, jak i zaopatrzymy się w niezbędne mapy, przewodniki, wydawnictwa, pamiątki itp. materiały promocyjne.<br/>Ośrodek stanowi główną bazę edukacyjną Parku. Prowadzone interdyscyplinarne i atrakcyjne zajęcia edukacyjne oraz prezentowane wystawy czasowe i stałe, w tym wystawa stała „W krainie jodły, buka i tarpana” mają na celu m.in. pomoc w zrozumieniu funkcjonowania przyrody oraz zachodzących w niej procesów i przygotowanie zwiedzających do wizyty w Parku.',
        src:require('../assets/images/location/105.jpg'),
        alt:'Ścieżka prowadząca do nieodległego budynku. Pokryta przez ceglastą dachówkę oraz o ścianach w żółtym kolorze, jednokondygnacyjna konstrukcja otoczona jest przez  drzewka i przystrzyżony trawnik.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:106,
        parkID:14,
        name:'Ścieżka poznawcza na Bukową Górę',
        description:'Ścieżka przyrodniczo-edukacyjna na Bukową Górę jest doskonałym modelem dydaktycznym, na przykładzie którego możemy obserwować typowy dla tej części Roztocza układ roślinności leśnej. Wyraźnie widać tu naturalny związek pomiędzy glebami, ukształtowaniem terenu, a występowaniem poszczególnych zespołów leśnych. Ścieżka wiedzie ze Zwierzyńca – od Ośrodka Edukacyjno-Muzealnego do miejscowości Sochy przez ostępy obszaru ochrony ścisłej Bukowa Góra. Obszar ten został objęty ochroną jeszcze za czasów Ordynacji Zamojskiej w roku 1934 gdy nakazem XV Ordynata utworzono tu niewielki ok. 8 hektarowy rezerwat, a obecnie obejmuje ok. 200 ha. Ścieżka jest wyposażona w zestaw plansz edukacyjnych umożliwiających samodzielne, a zarazem poznawcze zwiedzanie. Znajduje się przy niej siedem przystanków z pulpitami edukacyjnymi opisującymi typy otaczających siedlisk leśnych oraz reprezentatywne gatunki roślin i zwierząt które możemy spotkać. W obszarze ochrony ścisłej możemy obserwować las o bardzo pierwotnym, puszczańskim wyglądzie, od wielu lat niemal nietknięty ludzką ręką. Dzięki temu wędrówka ścieżką daje zwiedzającym możliwość obcowania z unikalnym, naturalnym ekosystemem tak rzadko już spotykanym w przekształconym przez człowieka świecie. Na końcu ścieżki, tuż przy granicy Parku znajduje się punkt widokowy skąd możemy podziwiać charakterystyczny krajobraz Roztocza.',
        src:require('../assets/images/location/106.jpg'),
        alt:'Szeroka leśna droga, przysypana jesiennym listowiem. Z obydwu stron otoczona jest ona niewysokim płotem, za którym znajdują się tablice informacyjne. Ścieżka prowadzi do strzelistej drewnianej bramy.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:107,
        parkID:14,
        name:'Stawy Echo',
        description:'Położone ok. 1,5 km od Zwierzyńca Stawy Echo to niewielki śródleśny kompleks składający się z czterech stawów o powierzchni ok. 40 ha, z czego część jednego ze stawów tzw. stawu kąpielowego od strony północnej udostępniana jest do celów rekreacyjnych – z plażą i wydzielonym kąpieliskiem. Funkcjonujący obok stawu kąpielowego parking z nowoczesnym sanitariatem oraz infrastruktura z drewnianą kładką i pomostem oraz platformą widokową sprzyja udostępnianiu tego fragmentu dla rekreacji i pozwala ukierunkować odpowiednio ruch turystyczny w tym obszarze,   ograniczając zarazem jego negatywne oddziaływanie na otaczające środowisko. Rokrocznie w Parku z tej formy rekreacji korzysta kilkadziesiąt tysięcy osób.',
        src:require('../assets/images/location/107.jpg'),
        alt:'Piaszczysta plaża u brzegu stawu otoczonego przez wysokie zielone drzewa. Plaża jest niemal zupełnie pusta, tak samo zresztą jak i intensywnie niebieskie niebo, mącone jedynie przez delikatne chmury.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:108,
        parkID:14,
        name:'Wieża widokowa przy Rybakówce',
        description:'Kompleks Stawów Echo to największy zbiornik wody powierzchniowej w Parku stanowiący jednocześnie najważniejszą ostoję fauny oraz roślinności wodnej. Większość stawów wchodzi w obszar wygrodzonej ostoi gdzie prowadzona jest od roku 1982 hodowla rezerwatowa konika polskiego. Usytuowana tuż przy zabudowaniach Rybakówki wieża widokowa pozwala obserwować bez niepokojenia wiele gnieżdżących się na stawach gatunków ptaków, a w okresie wiosennym i jesiennym również ptaki wodno-błotne zatrzymujące się tutaj okresowo podczas wędrówek. W identyfikacji pomagają usytuowane na wieży kolorowe plansze z rysunkami najważniejszych zwierząt występujących na Stawach Echo. Atrakcją jest możliwość podziwiania żyjącego tutaj tabunu koników polskich dla których porośnięte trawiaste groble są ważną bazą pokarmową.',
        src:require('../assets/images/location/108.jpg'),
        alt:'Widok z góry na trawiastą polankę, na środku której usytowana została kilkumetrowa drewniana wieżyczka widokowa. Dostrzec można z niej nieodległe lesiste tereny.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:109,
        parkID:14,
        name:'Czarny Staw',
        description:'Udając się rowerem lub na pieszą wędrówkę trasą rowerową ze Zwierzyńca do Florianki i dalej do Górecka po drodze ok. 2,5 km od Zwierzyńca mijamy Czarny Staw. Wybudowany pomost ułatwia wejrzenie zarówno na śródleśny zbiornik jak i wpływający do niego strumień Świerszcz. Mocno ocieniony otaczającym lasem Czarny Staw, do którego wpływają dość chłodne i ubogie wody strumienia Świerszcz powodują, iż zarówno brzegi jak i toń wodna pozbawiona są praktycznie roślinności. Naniesione szczątki organiczne, w tym liście opadając na dno tworzą warstwę wolno rozkładającego się humusu co dodatkowo potęguje wrażenie, że woda jest ciemna.',
        src:require('../assets/images/location/109.jpg'),
        alt:'Podmokłe leśne tereny, pełne drzew  - zarówno tych stojących w pionie, jak i tych poprzewracanych, jedynie lekko wystających poza taflę wody, z której emanuje delikatna mgiełka.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:110,
        parkID:14,
        name:'Izba Leśna we Floriance',
        description:'Uzupełnieniem oferty dla osób odwiedzających Park jest otoczona lasem enklawa osady Florianka położona w zaciszu leśnych ostępów, niespełna 7 km od Zwierzyńca. W jej skład wchodzi kompleks budynków Izby Leśnej składający się z drewnianej leśniczówki tzw. Szklarzówki datowanej na rok 1830, gdzie odtworzono wnętrza domu leśniczego Ordynacji Zamojskiej (OZ) z okresu 20-lecia międzywojennego (stan na 1938 r.), spichlerza i drewnianej stodoły. Prezentowane w poszczególnych budynkach i pomieszczeniach ekspozycje ukazują warunki życia i pracy pracowników służby leśnej OZ oraz codziennego życia ich rodzin. W spichlerzu zaprezentowane są dwie wystawy, jedna pt.  „Aleksandra Wachniewska – malarka Roztocza” poświęcona postaci Aleksandry Wachniewskiej (1902-1989), artystki malującej roztoczańskie drzewa i krajobrazy ale również oddanej regionalistki i orędowniczki ochrony przyrody, która wniosła swój wkład w utworzenie Roztoczańskiego PN. Druga wystawa pt. „Florianka – bastion niepodległości” ukazuje Florianke na tle działań wojennych mających miejsce na Zamojszczyźnie we wrześniu 1939 r. i dramatycznych wydarzeń z tamtego okresu.',
        src:require('../assets/images/location/110.jpg'),
        alt:'Jednopiętrowy domek o drewnianych, malowanych na biało ścianach. Z czubka pochyłego dachu wynurzają się dwa kominy. Dookoła budynku rośnie kilka ogołoconych z liści drzew.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:111,
        parkID:14,
        name:'Ośrodek Hodowli Zachowawczej we Floriance',
        description:'Na bazie zachowanych XIX wiecznych zabudowań dawnego ordynackiego folwarku Florianka od roku 1996 funkcjonuje Ośrodek Hodowli Zachowawczej (OHZ) gdzie prowadzona jest hodowla stajenna konika polskiego – symbolu Roztoczańskiego Parku Narodowego. Stado koników ma do dyspozycji stajnie oraz wygrodzone pastwiska. Trzymane tutaj koniki przygotowuje się do pracy dla człowieka, są one użytkowane w zaprzęgach oraz do jazdy wierzchem. W okresie wakacyjnym w Ośrodku prowadzona jest szkółka jeździecka, gdzie pod opieką instruktora osoby początkujące mogą rozpocząć swą przygodę z jeździectwem, a osoby bardziej doświadczone doskonalić już posiadane umiejętności.',
        src:require('../assets/images/location/111.jpg'),
        alt:'Stadko stłoczonych blisko siebie niewielkich koników. Stoją one na tle budynku stadniny, ulokowanej w środku lasu o drzewach pozbawionych liści.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:112,
        parkID:14,
        name:'Wieża widokowa na Białej Górze',
        description:'Roztoczański Park Narodowy jest Parkiem typowo leśnym ok. 95,5% jego powierzchni porastają lasy a tylko niewielka część podlega ochronie krajobrazowej, w tym niewielka enklawa łąk położona na Białej Górze. Wybudowana na Białej Górze wysoka wieża widokowa pozwala na podziwianie panoramy Roztocza z widocznymi wzniesieniami i dolinkami porośniętymi lasami. Obserwując kolorystykę koron drzew możemy rozróżnić nawet z daleka jakie gatunki porastają poszczególne fragmenty krajobrazu leśnego – w obniżeniach szarozielone sosny, na skłonach ciemnozielone jodły, a na wierzchowinach intensywnie zielone buki. Umieszczone na ostatnim poziomie kolorowe plansze z panoramą Roztocza oraz ze zwierzętami ułatwiają identyfikację widocznych elementów krajobrazu oraz zwierząt, które możemy spotkać w okolicy. W sezonie wegetacyjnym z wieży możemy również obserwować pasące się na wygrodzonej łące koniki polskie oraz bydło rasy czerwonej i białogrzbietej.',
        src:require('../assets/images/location/112.jpg'),
        alt:'Kilka sztuk bydła pasącego się na otwartej przestrzeni łąki. W tle widać górujące nad ich sylwetkami drzewa o listowiu w barwie jesiennej rdzawej żółci.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 15. Karkonoski Park Narodowy */
    {
        idlocation:113,
        parkID:15,
        name:'Śnieżka',
        description:'Osiągająca 1602 m n.p.m. wysokości Śnieżka jest najwyższy szczyt Karkonoszy oraz całych Sudetów. Zbudowana jest z bardzo odpornych na wietrzenie skał metamorficznych, nazywanych hornfelsami. Na samym szczycie Śnieżki panują surowe warunki klimatyczne - średnia roczna temperatura waha się w okolicach 0,5°C, zaś pokrywa śnieżna zalega tam przez 176 dni w roku.',
        src:require('../assets/images/location/113.jpg'),
        alt:'Na pierwszym planie widać zielone, iglaste rośliny. W tle zaś widnieje wysoka, łysa góra o skalistym, pozbawionym flory czubku, kontrastującym z nieco pochmurnym niebem.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:114,
        parkID:15,
        name:'Kocioł Małego Stawu',
        description:'Kocioł Małego Stawu jest największym kotłem polodowcowym - osiąga blisko 1 km długości, 400-700 m szerokości i 200 m głębokości. Lodowiec wypływający z Kotłów Wielkiego i Małego Stawu był najdłuższym w polskich Karkonoszach - jego jęzor spływał na odległość 3,5 km. Wypełnione osadami morenowymi dno kotła częściowo zajęte jest przez Mały Staw.',
        src:require('../assets/images/location/114.jpg'),
        alt:'Ulokowany w zagłębieniu niewielki zbiornik wodny. Dookoła górują nad nim porośnięte roślinnością i okraszone pojedynczymi skałkami zbocza. W tle widać niewyraźne i odległe tereny, ulokowane znacznie niżej niż pierwszoplanowy zbiornik wodny.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:115,
        parkID:15,
        name:'Pielgrzymy',
        description:'Pielgrzymy tworzą trzy mury skalne, osiągające 100 m długości i 10-15 m szerokości. Są najwyższą grupę skalną w Karkonoszach o wysokości do 25 m. Zbudowane są z granitu porfirowatego. Na ich powierzchni występuje szereg ciekawych form mikrorzeźby - kociołki wietrzeniowe, rynny i żłobki.',
        src:require('../assets/images/location/115.jpg'),
        alt:'Czubki iglastych drzew, na tle nieba z chmurami płynącymi niewiele wyżej niż sięga sama roślinność. Spośród pni wynurzają się wysokie pionowe kamienne bloki.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:116,
        parkID:15,
        name:'Chojnik',
        description:'Jest jednym z bardziej znanych wzgórz Pogórza Karkonoskiego. Na szczycie (627 m n.p.m.) wznoszą się ruiny XIV - wiecznego zamku, wieńcząc 100 - metrowe urwisko. Atrakcją góry są również skałki - Zbójeckie Skały, Skalny Grzyb oraz Piekielna Dolina. Odwiedzając Chojnik można wstąpić do położonego w Sobieszowie Centrum Przyrodniczo Edukacyjnego Karkonoskiego Parku Narodowego – Pałac Sobieszów. Otwarcie zaplanowane jest na wiosnę 2023 roku.',
        src:require('../assets/images/location/116.jpg'),
        alt:'Jaskrawo zielony krajobraz. Na pierwszy planie polana z żółtymi kwiatami. W tyle strome wzgórze z kamienną konstrukcją na szczycie. Niebo w kolorze intensywnie niebieskim.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:117,
        parkID:15,
        name:'Mały Śnieżny Kocioł',
        description:'Dno Małego Śnieżnego Kotła leży na wysokości 1175 m n.p.m., jego głębokość wynosi 300 m, a wysokość samych ścian nie przekracza 100 m. Lodowce wypływające z Małego i Wielkiego Śnieżnego Kotła łączyły się w jeden jęzor lodowcowy, spływający po zboczu na odległość 2 km. W zachodniej ścianie Małego Śnieżnego Kotła odsłania się, zbudowany z bazaltu, dawny komin wulkaniczny.',
        src:require('../assets/images/location/117.jpg'),
        alt:'Górski krajobraz pełen cieni rzucanych przez przewijające się po niebie ciemne chmury. Na pierwszym planie widać niskie zielone zarośla, zaś w tle strome skalne zbocze, oprószone śniegiem.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:118,
        parkID:15,
        name:'Wielki Śnieżny Kocioł',
        description:'Wielki Śnieżny Kocioł ma głębokość 250 m, jego dno położone jest na wysokości 1244 m n.p.m., zaś zbocza skalne liczą 150 m wysokości. W tym kotle występuje najlepiej rozwinięta w Karkonoszach rzeźba wysokogórska, z licznymi żlebami oraz stożkami, usypiskowymi. Wielki i Mały Śnieżny Kocioł oddzielone są od siebie grzędą skalną, którą w czasach przedwojennych biegł szlak turystyczny.',
        src:require('../assets/images/location/118.jpg'),
        alt:'Górskie zagłębienie, skryte w cieniu, okraszone przez wątłą, wynurzającą się spomiędzy skład roślinność. Dookoła wysokie, niemal pionowe kamienne zbocza.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:119,
        parkID:15,
        name:'Szrenica',
        description:'Szczyt Szrenicy wznosi się na wysokość 1362 m n.p.m. i zbudowany jest z granitu porfirowatego. Swą nazwę Szrenica zawdzięcza często występującej tu szreni - rodzaj pokrywy śnieżnej. Na wschodnich i południowo- wschodnich stokach Szrenicy znajdują się rozległe pokrywy blokowe. Na samym szczycie funkcjonuje schronisko turystyczne.',
        src:require('../assets/images/location/119.jpg'),
        alt:'Lekko pochyłe wzniesienie, niemal w całości porośnięte przez gęste zielone krzaki. Dopiero u samego szczytu pośród flory widać również kamienne bloki, pośród których znajduje się pojedynczy acz pokaźny dom.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:120,
        parkID:15,
        name:'Końskie Łby',
        description:'Grupa skałek Końskie Łby położona jest na północnym zboczu Szrenicy. Jest to dość duża grupa skalna, której długość wynosi około 100 m. Wszystkie jej elementy charakteryzują się fantazyjnymi kształtami, a wśród bogatej mikrorzeźby wyróżniają się regularne kociołki wietrzeniowe. Znajduje się tu jedno z nielicznych stanowisk wierzby zielnej w polskich Karkonoszach.',
        src:require('../assets/images/location/120.jpg'),
        alt:'Dwie przylegające do siebie sterty pochyłych wyżłobionych kamieni. Dookoła nich rosną zielone krzaki. W tle widać górskie nierówności terenu.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 16. Park Narodowy Gór Stołowych */
    {
        idlocation:121,
        parkID:16,
        name:'Skalne Grzyby',
        description:'Znajdują się one we wschodnim fragmencie Gór Stołowych między Batorowem i Karłowem. Jest to grupa oryginalnie ukształtowanych form skalnych, rozrzuconych na powierzchni ok. 2 km2 i na wysokości ok. 680-700 m n.p.m. Skałki mają kształty grzybów, maczug, baszt i bram, a nawet całych murów skalnych. Szereg tych form skalnych posiada własne nazwy, są to na przykład: Żaba, Żółw, Głowa Lamy. Przez obszar Skalnych Grzybów przebiega ścieżka dydaktyczna, przedstawiająca najbardziej prawdopodobną historię powstania tych form skalnych w Górach Stołowych.',
        src:require('../assets/images/location/121.jpg'),
        alt:'Ulokowana wśród leśnych drzew skalista forma o kształcie przypominającym grzyba, którego wierzchołek przerasta nawet co poniektóre z otaczających go iglastych drzewek.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:122,
        parkID:16,
        name:'Łężyckie Skałki',
        description:'Jest to rozległa łąka górska leżąca na wysokości ok. 740-780 m n.p.m., na południowy-zachód od Lisiej Przełęczy. Z rzadka rozrzucone skały i pojedyncze drzewa stwarzają egzotyczne wrażenie. W połączeniu z rozległą łąką o stepowym charakterze sprawiają, że potocznie przyjęła się dla tego obszaru nazwa Sawanna Afrykańska. Ze względu na unikatowe walory krajobrazowe i florystyczne jest to obszar szczególnie cenny na terenie Parku Narodowego Gór Stołowych.',
        src:require('../assets/images/location/122.jpg'),
        alt:'Pojedyncze drzewo o zielonej koronie. Stoi ono samotnie na środku  ciągnącej się po horyzont złocistej łąki. Niebo przesłonięte jest przez gęste szare chmury.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:123,
        parkID:16,
        name:'Szczeliniec Wielki',
        description:'Szczeliniec Wielki (919 m n.p.m.) - najwyższy szczyt Gór Stołowych, na który prowadzi około 680 kamiennych stopni. Jest on pozostałością ogromnej piaskowcowej płyty, w której erozja wyrzeźbiła wspaniałe formy skalne. Do najbardziej znanych należą: Kwoka, Małpolud, Wielbłąd i Słoń. Niezmiernie ciekawym fragmentem Szczelińca Wielkiego jest Piekiełko, najgłębsza szczelina skalna o długości ok. 100 m i głębokości ponad 20 m. Z uwagi na panujące tu warunki klimatyczne, na dnie Piekiełka śnieg zalega nawet do czerwca. Przy północnych tarasach Szczelińca działa schronisko PTTK „Na Szczelińcu”.',
        src:require('../assets/images/location/123.jpg'),
        alt:'Kompleks ulokowanych na sobie skał, wspólnie sięgających na kilka metrów ponad grunt. W jego tle widać górskie tereny oraz połacie wielokolorowych lasów.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:124,
        parkID:16,
        name:'Skały Puchacza',
        description:'Skały Puchacza, nazywane niegdyś Sowimi Skałami, to pozostałości dawnego wyrobiska kamieniołomu piaskowca. Sięgające 30 metrów ściany skalne są efektem prowadzonego od II połowy XIX w. do połowy XX w. wydobycia. Kamieniołom wcina się w masyw Narożnika na południowym progu Gór Stołowych. Zachowały się tu konstrukcje związane z wydobyciem piaskowca, między innymi kamienna pochylnia, którą transportowano urobek oraz specjalna rampa załadowcza. W obszarze wyrobiska zobaczyć można między innymi niedokończone koła młyńskie, z których wytwarzania słynęły tutejsze kamieniołomy.',
        src:require('../assets/images/location/124.jpg'),
        alt:'Wielometrowa, gładka, skalista skarpa. U jej podnóża rosną silne iglaste drzewa, zaś na jej kamiennym szczycie wątłe  drzewka liściaste o chudych pniach.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:125,
        parkID:16,
        name:'Schronisko na Szczelińcu Wielkim',
        description:'W roku 1815, kilka lat po udostępnieniu wierzchowiny Szczelińca, w jego zachodniej części powstała niewielka altana służąca turystom. Z uwagi na rosnące zainteresowanie wśród zwiedzających w 1845 r. wzniesiono, w stylu szwajcarskim, istniejący do dziś budynek schroniska. Schronisko na Szczelińcu to najstarszy w polskich górach, nadal działający obiekt, który powstał specjalnie do celów związanych z turystyką. Do schroniska nie ma drogi dojazdowej. Transport zapewnia specjalny wyciąg linowy znajdujący się przy północnych tarasach Szczelińca Wielkiego.',
        src:require('../assets/images/location/125.jpg'),
        alt:'Samotna drewniana konstrukcja schroniska ulokowanego na skalistej skarpie górującej nad leśnym krajobrazem. Ciągnące się kilkadziesiąt metrów niżej połacie zielonych drzew w końcu spotykają się z horyzontem niebieskiego nieba.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:126,
        parkID:16,
        name:'Błędne Skały',
        description:'Błędne Skały to zapierający dech w piersiach labirynt, który powstał w sieci spękań i szczelin wyciętych w piaskowcowej płycie skalnej. Wiele skał ma własne nazwy na przykład: Skalne Siodło, Kurza Stopka, Wielka Sala, Kasa. Tuż przy wejściu do labiryntu Błędnych Skał znajduje się punkt widokowy o nazwie Skalne Czasze. Widoczny stąd jest m.in. Szczeliniec Wielki i Mały, Broumovska Vrchovina, miejscowość Machov, a przy dobrej widoczności na horyzoncie zobaczyć możemy także Karkonosze, Rudawy Janowickie i Góry Kamienne. Obszar Błędnych Skał ma powierzchnię ok. 22 ha.',
        src:require('../assets/images/location/126.jpg'),
        alt:'Wąski  przesmyk składający się na labiryntowy kompleks, naturalnie powstały ze spękań i szczelin wyciętych w piaskowej płycie skalnej. Wątła ścieżka otoczona jest przez górujące nad nią kamieniste ściany.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:127,
        parkID:16,
        name:'Niknąca Łąka',
        description:'Niknąca Łąka to torfowisko wysokie, czyli takie, które w całości zasilane jest wodami opadowymi. W XVIII i XIX w. podejmowano próby osuszania stołowogórskich torfowisk, poprzez wykopywanie systemu rowów odwadniających. Dzisiaj obszary te, uznawane za szczególnie cenne, są chronione i podejmuje się działania hamujące odpływ wód z tego terenu. Przez torfowisko Niknąca Łąka prowadzi ścieżka dydaktyczna o długości 400 m. Przedstawia florę i faunę obszarów torfowiskowych w Parku Narodowym Gór Stołowych.',
        src:require('../assets/images/location/127.jpg'),
        alt:'Niski drewniany mostek, ciągnący się tuż nad trawiastym gruntem. Otaczające go drzewa rosną dosyć rzadko, lecz widać, że w odległości zagęszczają się i przechodzą w gęstą ścianę lasu.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:128,
        parkID:16,
        name:'Pielgrzym',
        description:'Pielgrzym to skalna baszta stanowiąca fragment północnej krawędzi Gór Stołowych, ponad wsią Wambierzyce i doliną potoku Cedron. Na jej szczycie, na wysokości 672 m n.p.m., znajduje się punkt widokowy, z którego rozpościera się widok na wambierzycką bazylikę, górę Mnich oraz znajdujące się na horyzoncie Góry Kamienne, Góry Sowie oraz Góry Bardzkie. Poniżej Pielgrzyma widoczne są pozostałości jednego z kamieniołomów piaskowca działającego tutaj w XIX w.',
        src:require('../assets/images/location/128.jpg'),
        alt:'Skalisty punkt widokowy, górujący nad pełnym delikatnych wzniesień oraz dolinek krajobrazem, który można z niego podziwiać. Ze względu na swoją wysokość, skarpka otoczona jest metalowymi barierkami.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 17. Ojcowski Park Narodowy */
    {
        idlocation:129,
        parkID:17,
        name:'Brama Krakowska',
        description:'Wrota Bramy Krakowskiej prowadzą do Doliny Prądnika skąd niedaleko już do Krakowa. Ojców od średniowiecza znajdował się na uczęszczanym szlaku kupieckim  łączącym Kraków ze Śląskiem. Dlatego w tradycji utrwaliła się taka nazwa. Te skalne wrota zbudowane z odpornych na wietrzenie bloków wapieni skalistych o wysokości ok. 15-20 metrów, powstały w wyniku procesów erozyjnych, zachodzących na spękaniach, którymi spływała woda. Potem dołączyły się zjawiska krasowe i wietrzenie mechaniczne.',
        src:require('../assets/images/location/129.jpg'),
        alt:'Widok na dwie pionowe skały, opatulone zieloną naleciałością, przypominające kształtem bramę wejściową. Pomiędzy nimi rozciąga się ścieżka oraz wysokie zbocze góry na wierzchołku porośnięte pomarańczowo-złotymi drzewami iglastymi.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:130,
        parkID:17,
        name:'Jaskinia Ciemna',
        description:'Stanowi fragment dawnego systemu podziemnych korytarzy i komór, o łącznej długości 209 m, komora wstępna jest największa ze wszystkich znanych na Wyżynie Krakowsko-Częstochowskiej. Pierwszym oficjalnym turystą, zwiedzającym tę jaskinię, był król Stanisław August Poniatowski. Jaskinia Ciemna zalicza się do najcenniejszych stanowisk archeologicznych w Polsce. Prowadzone tu badania archeologiczne przyniosły wiele interesujących odkryć m.in. ślady obecności neandertalczyków sprzed 120 tys. lat. Jaskinia Ciemna jest również ulubionym miejscem nietoperzy, w zimie hibernuje tu około 800 osobników.',
        src:require('../assets/images/location/130.jpg'),
        alt:'Widok spod łuku skalnego o białej kolorystyce. Na dole widać niską zieloną roślinność. W dalszej części zdjęcia znajduje się drugi łuk a pod nim dwa złamane niskie drzewa.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:131,
        parkID:17,
        name:'Grota Łokietka',
        description:'Największa spośród wszystkich znanych na terenie OPN jaskiń, jej długość wynosi 320 m. Jaskinia położona ok. 125 m nad dnem Doliny Sąspowskiej została wyżłobiona przez wodę w skałach wapiennych. Wewnątrz na ścianach można zauważyć polewy kalcytowe i inne utwory naciekowe tworzące ciekawe kształty. Temperatura wewnątrz utrzymuje się na stałym poziomie przez cały rok i wynosi ok. 7-8°C. Legenda głosi o ukrywaniu się w niej Władysława Łokietka, którego przed wrogami ukryć miała gęsta sieć pajęcza zasłaniająca wejście. Do dnia dzisiejszego wejścia do groty strzegą pająki – sieciarze jaskiniowe.',
        src:require('../assets/images/location/131.jpg'),
        alt:'Zdjęcie przedstawia metalową bramę wejściową z pajęczym motywem na środku. Na górze jest zaostrzona i wystają z niej kolce ustawione w równej odległości od siebie. Brama jest usytuowana pomiędzy skałami i okryta śniegiem.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:132,
        parkID:17,
        name:'Ruiny zamku w Ojcowie',
        description:'Powstanie zamku jest związane z działalnością fortyfikacyjną króla Kazimierza Wielkiego w  XIV wieku. Król nazwał zamek Ociec u Skały, upamiętniając w ten sposób tułaczkę swego ojca, Władysława Łokietka. W średniowieczu zamek należał do najwybitniejszych dzieł architektonicznych w Małopolsce. Po III rozbiorze Polski zaczął się proces szybkiej dewastacji zamku, podejmowano próby odnowienia lub nawet odbudowy, ale ostatecznie z dawnej budowli zamkowej pozostały do dziś tylko malownicze ruiny. Z ruin zamku roztacza się malownicza panorama na Dolinę Prądnika.',
        src:require('../assets/images/location/132.jpg'),
        alt:'Widok na bramę, o kolorze kremowym, wkomponowaną w blok skalny. Wierzchołek ma kształt trójkąta i jest zakończony szpikulcem. Pod małym okienkiem widnieje herb Polski. Po bokach wieży znajdują się pojedyncze drzewa liściaste.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:133,
        parkID:17,
        name:'Kaplica „Na wodzie”',
        description:'Kaplica "Na Wodzie" p.w. św. Józefa Rzemieślnika znajduje się w miejscu dawnych łazienek zdrojowych, które przebudowano na obiekt sakralny w 1901 r. Kaplica usytuowana jest ponad potokiem na podporach. Lokalna tradycja mówi, że aby obejść ukaz cara Mikołaja II, zabraniający budowy obiektów sakralnych na ziemi ojcowskiej, kaplicę zbudowano "na wodzie". Kaplica ojcowska jest przykładem drewnianego budownictwa stylowego, opartego częściowo na miejscowych, a częściowo na zapożyczonych motywach zdobniczych tzw. styl szwajcarsko – ojcowski.',
        src:require('../assets/images/location/133.jpg'),
        alt:'Zdjęcie jasnobrązowej kapliczki postawionej nad potokiem. Na dachu kapliczki znajduje się wieżyczka. W tafli wody widzimy odbicie budynku. Z tyłu rozciągają się góry porośnięte drzewami. Całość jest w scenerii zimowej.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:134,
        parkID:17,
        name:'Ekspozycja Przyrodnicza',
        description:'Ekspozycja przyrodnicza OPN mieści się w budynku dawnego Hotelu pod Łokietkiem. Tu zapoznamy się z zagadnieniami geologicznymi, obejrzymy eksponaty przedstawiające charakterystyczne skały oraz znajdowane w nich odciski zwierząt jurajskich – gąbki, ramienionogi, amonity, belemnity i inne. W zrekonstruowanym wnętrzu jaskini, przy odgłosach „deszczu jaskiniowego”  lektor  opowie nam jak powstają jaskinie i szata naciekowa. Następnie obejrzymy makietę OPN i umieszczone w gablotach zabytki archeologiczne. Atrakcją ekspozycji jest diorama pokazująca przedstawicieli flory i fauny OPN.',
        src:require('../assets/images/location/134.jpg'),
        alt:'Widok od przodu prostokątnego budynku o trójkątnym granatowym dachu. Budynek przedstawia się w kolorze kremowym. przed nim widzimy flagę Polski oraz kilka ławeczek, a z tyłu pomarańczowy las liściasty.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:135,
        parkID:17,
        name:'Pieskowa Skała i Maczuga Herkulesa',
        description:'Zamek ten, był ważnym ogniwem w łańcuchu fortyfikacyjnym średniowiecznych warowni, stanowiących dzisiaj Szlak Orlich Gniazd. Bogata historia widoczna jest w elementach architektonicznych z różnych okresów. Gotyckie wieże, renesansowy dziedziniec, barokowa kaplica były świadkami wielu ważnych wydarzeń w historii Polski. W tej rezydencji gościli alchemicy, rozbójnicy, ludzie nauki i pióra, powstańcy i sieroty, a także koronowane głowy. Tuż obok zamku stoi olbrzymi ostaniec wapienny nazwany Maczugą  Herkulesa. Tajemnicza skała owiana legendą.',
        src:require('../assets/images/location/135.jpg'),
        alt:'Widok ze szczytu na zamek postawiony na krawędzi skały. Budowla ma odcień jasnego pomarańczu z dachem w kolorze czerwonym. Pod nim rozciąga się las mieszany z liśćmi w kolorze pomarańczu i zieleni przykryty mgłą.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:136,
        parkID:17,
        name:'Góra Koronna – Rękawica – murawy kserotermiczne',
        description:'Jedno z najbardziej malowniczych wzniesień na terenie OPN. Część stoków tej góry tworzy masyw skał wapiennych zwanych Skałami Koronnymi, wśród których najbardziej znana to Rękawica. Legenda głosi, że to dłoń Boga, która uratowała kiedyś mieszkańców. Z punktów widokowych na szczycie możemy podziwiać Bramę Krakowską i panoramę Doliny Prądnika. Skały koronne to również jedno z miejsc czynnej ochrony muraw kserotermicznych, czyli roślinności naskalnej, charakterystycznej dla OPN, szczególnie rzadkie to ostnica piórkowa, potocznie zwana sierocymi włosami; dziewanna austriacka i macierzanka wczesna.',
        src:require('../assets/images/location/136.jpg'),
        alt:'Widok z dołu na wysokie pionowe białe skały, leciutko porośnięte zielonymi roślinami skalnymi. Na ich szczycie można dostrzec las iglasty o wyrazistym zielonym kolorze. U podnóża skał pasą się owce o kremowym kolorze.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 18. Babiogórski Park Narodowy */
    {
        idlocation:137,
        parkID:18,
        name:'Czarna Hala lub Hala Czarnego',
        description:'Nazwa „Czarnego” wywodzi się prawdopodobnie od nazwiska jednego z baców, który wypasał na niej swoje owce. Jest to jedna z największych polan w obrębie Babiogórskiego Parku Narodowego. Położona jest na północno-wschodnich stokach Małej Babiej Góry i niegdyś rozpościerał się z niej majestatyczny widok na okoliczne pasma górskie w tym szczególnie na Jałowiec i Policę.<br/><br/>Po zaprzestaniu wypasu hala zaczęła zarastać, mimo tego występują tu takie rośliny jak śmiałek darniowy, mieczyk dachówkowaty, krokus, bliżniczka psia trawka oraz spore skupiska szczawiu alpejskiego. Obserwuje się tu obecność głuszca, również w trakcie toków.',
        src:require('../assets/images/location/137.jpg'),
        alt:'Niewielka leśna polanka pełna kęp zielonych traw. Otwarta przestrzeń od wszystkich stron odgrodzona jest leśną ścianą iglastych drzew.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:138,
        parkID:18,
        name:'Diablak',
        description:'Diablak jest kulminacją Babiej Góry. Wznosi się na 1725 m n.p.m. Spośród kilku znaczniejszych wierzchołków, wchodzących w skład Pasma Babiogórskiego, ten wyróżnia się nie tylko osobliwym kształtem przywodzącym na myśl pozostałości po potężnej budowli, ale również unikalną florą i fauną piętra halnego. Nazwa „Diablak” pochodzi od legendy, jakoby wznosił się tam niegdyś olbrzymi zamek wybudowany przez diabła a będący miejscem sabatów diabłów i czarownic. Pod względem geologicznym Diablak to olbrzymia wychodnia piaskowca magurskiego z którego w znacznej części cały masyw babiogórski jest zbudowany. Na uwagę wśród roślin występujących na Diablaku zasługuje rogownica alpejska, będące reliktem polodowcowym. W Polsce jej stanowiska obecne są jedynie na szczycie Babiej Góry. Roślina ta została również wpisana do Polskiej Czerwonej Księgi Roślin.',
        src:require('../assets/images/location/138.jpg'),
        alt:'Skalisty nasyp składający się z niezliczonych ostrych, zazębiających się ze sobą kamieni. Wspólnie formują one wzniesienie, na którego szczyt ciągnie się linia turystów.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:139,
        parkID:18,
        name:'Głodna Woda',
        description:'Głodna Woda wypływająca spod Diablaka na wysokości 1625 m n.p.m. to najwyżej położone, stałe źródło w Paśmie Babiej Góry oraz w całych Beskidach Zachodnich. Źródło mimo iż bije niewiele niżej niż sam szczyt Babiej Góry, nigdy nie wysycha a jego woda posiada charakterystyczny smak. Również jego temperatura w ciągu całego roku waha się pomiędzy 2- 3 st. C. Mówi się, że ten który napije się wody z tego źródła będzie odczuwał niebywały, trudny do zaspokojenia głód… Źródło stanowiło niegdyś ujęcie wody pitnej dla położonego opodal i nieistniejącego już dziś niemieckiego schroniska Beskidenverein.',
        src:require('../assets/images/location/139.jpg'),
        alt:'Skalisty strumyczek, skryty w zagłębieniu usytuowanym między kępami trawy. Spośród ułożonych na sobie kamieni wąskim ciurkiem wycieka strużka wody.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:140,
        parkID:18,
        name:'Mała Babia Góra',
        description:'Mała Babia Góra jest częścią pasma babiogórskiego i wznosi się na wysokość 1517 m n.p.m. Inną nazwę dla tego szczytu nadali miejscowi górale, którzy najwyższą kulminację tej części Babiej Góry nazwali „Cyl” co tłumaczy się jakoby „celował” swym wierzchołkiem w górę. Północne zbocza Małej Babiej Góry są bardzo strome i bywa że występują na nich potężne osuwiska. Południowe stoki są łagodne i przypominają z dalszej perspektywy bieszczadzką połoninę. Na stokach tych prowadzono niegdyś intensywny wypas owiec. Dziś pokrywają ją borówczyska oraz pnący się w górę las. Warto również wiedzieć, iż południowa część Małej Babiej Góry należy w większości do Słowacji.',
        src:require('../assets/images/location/140.jpg'),
        alt:'Pojedyncze wzniesienie wyróżniające się wśród otaczającego górskiego krajobrazu przez wzgląd na jego wysokość. Pokryte jest ono licznymi iglastymi drzewami i krzakami, chociaż na samym szczycie rosną już wyłącznie trawy.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:141,
        parkID:18,
        name:'Obelisk na Diabalku',
        description:'Wychodząc na szczyt Babiej Góry nie sposób nie zauważyć niewielkiego kamiennego obelisku z zatartym przez czas epitafium. To pamiątka po panowaniu na tym terenie monarchii austro-węgierskiej. Monarchia ta była olbrzymim państwem w skład którego wchodziła do 1918 roku również Babia Góra. Sam obelisk ustawiony w 1876 roku upamiętnia 70 lecie wejście na Diablak palatyna Węgier, arcyksięcia Józefa Habsburga, który dokonał tego w 1906 roku. Pierwotny napis był wykuty w języku węgierskim a jak mówią pamiętniki świadków – ustawieniu obelisku towarzyszyła orkiestra dęta…',
        src:require('../assets/images/location/141.jpg'),
        alt:'Pojedynczy skalisty obeliks o owalnej formie, zawężający się nieco u swego szczytu. Otaczają go najróżniejsze układy kamieni.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:142,
        parkID:18,
        name:'Schronisko na Markowych Szczawinach',
        description:'W 1906 roku na północnym zboczu Babiej Góry, powstało pierwsze polskie schronisko turystyczne w Beskidach. Stało się to z inicjatywy dr. Hugona Zapałowicza, ówczesnego prezesa Oddziału Babiogórskiego Towarzystwa Tatrzańskiego. Schronisko wybudowano w bardzo krótkim czasie, jako swoistą odpowiedź na postępującą w Beskidach ekspansję niemieckiego towarzystwa turystycznego Beskidenverein, które było właścicielem schroniska nieopodal szczytu Babiej Góry. Pierwotnie obiekt był niewielki i składał się z kuchni, jadalni i dwóch izb – osobno dla pań i panów. Wraz ze wzrostem popularności turystyki w masywie Babiej Góry, schronisko rozbudowywano. Obecnie stare schronisko już nie istnieje w pierwotnym położeniu – jako zabytek zostało rozebrane i odtworzone w niedalekim skansenie w Zawoi Markowe Rówienki. Na jego miejscu postawiono nowoczesny budynek otwarty dla turystów w 2010 roku.',
        src:require('../assets/images/location/142.jpg'),
        alt:'Nowoczesny budynek schroniska o pochyłym dachu przyozdobionym zestawem paneli słonecznych. Ną żwirowo-kamiennej ścieżce u wejścia do budynku kłębią się tłumy turystów.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:143,
        parkID:18,
        name:'Sokolica',
        description:'Sokolica jest jedną z kulminacji w pasmie Babiej Góry, wznoszącą się na wysokość 1367 m n.p.m. i wysuniętą najdalej na wschód. Na Sokolicę prowadzi szlak czerwony z przełęczy Lipnickiej. Zdobycie tego niewątpliwie najbardziej stromego odcinka wyprawy na szczyt, gwarantuje wejście na samą górę (o ile pozwoli kapryśna pogoda Królowej Beskidów). Północno-zachodnia strona Sokolicy jest wielkim skalnym obrywem powstałym w wyniku osunięcia się zbocza. U podnóża Sokolicy znajduje się jedno z najniżej położonych stanowisk kosodrzewiny w Beskidach (około 1100 m n.p.m.) a powstałe właśnie w wyniku tego osuwiska.',
        src:require('../assets/images/location/143.jpg'),
        alt:'Płaski, okolony przez jesiennie ubarwione drzewa, naturalnie uformowany podest ulokowany u krawędzi skarpy. Widać z niego olbrzymie lesiste połacie.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:144,
        parkID:18,
        name:'Akademicka Perć',
        description:'Szlak żółty prowadzący z trasy Górnego Płaju na Diablak. Uważany za najtrudniejszy szlak na Babiej Górze i również w całych Beskidach. Wędrówka zaczyna się w schronisku na Markowych Szczawinach i prowadzi pierwotnie wzdłuż Górnego Płaju, łagodną niemal równą drogą by po kilkuset metrach przejść do stromej wspinaczki. Swoją kulminację podejście to posiada w rejonie tzw. „Czarnego Dziobu” czyli stromej skalnej ściany o wysokości kilku metrów na którą trzeba się wspiąć po kilku klamrach i łańcuchach. Mimo sporego wysiłku jaki pochłania zdobycie szczytu tą drogą, jest to wyprawa szczególnie atrakcyjna z uwagi na niespotykane w Beskidach widoki oraz dziką przyrodę skalnych nisz i rozpadlin, szczególnie dobrze widoczną w okresie intensywnej wegetacji ziołorośli.',
        src:require('../assets/images/location/144.jpg'),
        alt:'Ciągnąca się w ramach górskiego zbocza skarpa. Rosną na niej przylegające do gruntu trawy, spośród których wyłania się ścieżka naturalnie uformowana ze skalistych podestów.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 19. Gorczański Park Narodowy */
    {
        idlocation:145,
        parkID:19,
        name:'Ośrodek Edukacyjny – Park Dworski',
        description:'Wizytę w Gorczańskim Parku Narodowym zacznij od zwiedzenia multimedialnej wystawy przyrodniczej w Ośrodku Edukacyjnym w Porębie Wielkiej. Będzie to Twoje osobiste doświadczenie, ponieważ sam wybierzesz drogę poznania prezentowanych tematów. Przechodząc przez kolejne sale, będziesz mieć wrażenie, że wędrujesz szlakiem turystycznym – od podnóża gór na najwyższy szczyt. Poznasz rzadkie gatunki roślin i zwierząt oraz ich siedliska. Zajrzysz na dno górskiego potoku, w dużym powiększeniu zobaczysz to, co jest bardzo małe i staniesz „oko w oko” z wilkiem. Potem, by ochłonąć, wybierz się na spacer do zabytkowego parku dworskiego, otaczającego Ośrodek Edukacyjny. Zobaczysz w nim pomnikowe drzewa, odpoczniesz nad niewielkimi stawami, a wiosną posłuchasz ptasiego koncertu.',
        src:require('../assets/images/location/145.jpg'),
        alt:'Budynek Ośrodka Edukacyjnego w Porębie Wielkiej. budynek ma dach w kształcie trapezu, pod dachem przebija się pas bieli a jego podstawa ma odcień kremowy. Naokoło budynku rosną pojedyncze drzewa zarówno iglaste jak i liściaste.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:146,
        parkID:19,
        name:'Rezerwat „Turbacz” im. Władysława Orkana',
        description:'Tutaj poczujesz pierwotne tętno karpackiej puszczy i zrozumiesz, o czym pisał Władysław Orkan: „…długo grube drzewa nie widziały siekier. Gazdowały tu kolejno niedźwiedzie i wilki i chowały się nieprzeliczone stada saren…”. Jest to obszar, który objęto ochroną rezerwatową już 1927 r. Możesz go zobaczyć, wędrując ścieżką edukacyjną „Dolina potoku Turbacz”. Nadal rosną tu wiekowe buki, jodły i jawory. U ich stóp, obrośnięte grzybami i mszakami, leżą martwe olbrzymy, które butwiejąc, dają życie milionom organizmów. Na miejscu starego drzewa młode drzewka uczestniczą w wielkim wyścigu do światła. To żywy obraz ochrony ścisłej, której celem jest badanie przyrody i zachowanie naturalnych procesów. Ten cel, już 100 lat temu rozumiał Władysław Orkan, opisując piękno i dramat gorczańskiej puszczy.',
        src:require('../assets/images/location/146.jpg'),
        alt:'Środek lasu liściastego. Zarówno drzewa jak i krzewy mają wyrazisty zielony kolor. Na samym środku zdjęcia widnieje kawałek powalonego drzewa zarośniętego zielonym nalotem na jego powierzchni możemy dostrzec dwie huby.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:147,
        parkID:19,
        name:'Hala Długa i Turbacz',
        description:'Jedna z największych i najpiękniejszych polan w bliskim sąsiedztwie Turbacza – najwyższego szczytu Gorców. Wyjątkowo atrakcyjne miejsce dla tych, którzy poszukują pięknych widoków. Na północnym wschodzie wyłaniają się szczyty Beskidu Wyspowego, a na południu panorama Tatr oraz Pieniny i Magura Spiska. W sezonie letnim na Hali Długiej prowadzony jest kulturowy wypas owiec. Dzięki tej czynnej ochronie utrzymuje się bogactwo łąkowej roślinności. Można je podziwiać wiosną i latem  – najpierw łany krokusów, później ciemiężycy zielonej, pełnika alpejskiego i wielu innych gatunków o kolorowych kwiatach. Turyści mogą też zajrzeć do bacówki, porozmawiać z bacą i spróbować wyrobów z owczego mleka. Dodatkową atrakcją wyprawy na Halę Długą i najwyższy szczyt  może być nocleg w schronisku na Turbaczu.',
        src:require('../assets/images/location/147.jpg'),
        alt:'Widok z polany porośniętej fioletowymi kwiatami. Nad polaną na drugim planie widnieje panorama przedstawiająca wzniesienia pokryte lasami iglastymi.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:148,
        parkID:19,
        name:'Kudłoński Baca',
        description:'Osobliwość przyrody nieożywionej – potężny ostaniec skalny na północnych zboczach Kudłonia, przy czarnym szlaku turystycznym z Lubomierza. Skała zbudowana jest z odpornych na wietrzenie piaskowców oraz zlepieńców. Oglądana z odpowiedniej perspektywy przypomina profil górala. Warto wybrać się na wycieczkę, by zobaczyć tę tajemniczą postać. O powstaniu skalnego tworu opowiadają legendy. Jedna z nich głosi, że jest to skamieniały baca, który niegdyś gospodarował na gorczańskich polanach. Był to jednak zły człowiek – źle traktował swoich juhasów i oszukiwał gospodarzy. Według innej opowieści kamień ten zostawił diabeł, który zamierzał zniszczyć kościół w Lubomierzu. Dzwon bijący na Anioł Pański osłabił jednak złe moce i diabeł musiał porzucić głaz w tym miejscu.',
        src:require('../assets/images/location/148.jpg'),
        alt:'Widok od dołu na skałę o nieregularnym wąskim kształcie, wznoszącej się pionowo w górę. Pod skałą znajdują się drzewa drzewa liściaste a za nią przebijają się drzewa iglaste.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:149,
        parkID:19,
        name:'Dolina Kamienicy – Papieżówka',
        description:'Dolina Kamienicy – najdłuższa i najdziksza wśród dolin Gorczańskiego Parku Narodowego. Odwiedzają ją turyści szukający wrażeń estetycznych i odpoczynku blisko natury. Kryje w sobie tajemnice pierwotnej przyrody oraz ciekawostki geologiczne i historyczne. Tutaj zobaczysz, jak wygląda flisz karpacki. Zachwycisz się dzikością górskiej rzeki, której czysta woda pokonuje progi skalne i potężne głazy. Wiosną możesz z bliska obserwować życie płazów w oczkach wodnych. Dotrzesz do miejsca, w którym zostawił swe ślady kardynał Karol Wojtyła. To Papieżówka – drewniany szałas służący niegdyś za schronienie robotnikom leśnym. Niezwykły gość przebywał tu w lipcu 1976 roku. Na pamiątkę we wnętrzu szałasu urządzono niewielką ekspozycję poświęconą temu wydarzeniu oraz wędrówkom Jana Pawła II po Gorcach.',
        src:require('../assets/images/location/149.jpg'),
        alt:'Widok na mały wodospad spływający segmentami w dół po płaskich kamiennych półkach. Za wodospadem znajduje się las mieszany. Z prawej strony zdjęcia widać strome skały.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:150,
        parkID:19,
        name:'Jaworzyna Kamienicka – Bulandowa Kapliczka',
        description:'Jaworzyna Kamienicka to drugi pod względem wysokości szczyt Gorców, a rozległa polana o tej samej nazwie jest jedną z najpiękniejszych. Z jej górnej części roztacza się wyjątkowy widok na dziką dolinę Kamienicy i otaczające ją grzbiety górskie.  Stoi tu nieduża, biała kapliczka pięknie wkomponowana w krajobraz. Warto poznać jej historię. W 1904 r. ufundował ją Tomasz Chlipała – najsłynniejszy gorczański baca, zwany Bulandą. Przed I wojną światową wypasał owce na Jaworzynie. Był znany, jako znachor i czarownik, który pomagał ludziom i zwierzętom gospodarskim. Dziś w gorczańskiej literaturze można znaleźć legendy o nadzwyczajnych zdolnościach Bulandy.',
        src:require('../assets/images/location/150.jpg'),
        alt:'Widok ze szczytu góry na wzniesienia bujnie zalesiona i pokryte śniegiem. Na pierwszym planie znajduje się kapliczka w kształcie budki. Obok budki widać tabliczkę informacyjną.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:151,
        parkID:19,
        name:'Gajówka Mikołaja',
        description:'Ukryty w dolinie Łopusznej drewniany budynek pełni funkcję terenowej bazy edukacyjnej. Zobaczysz tu wystawę przyrodniczo-historyczną, prezentującą gorczańską puszczę i jej mieszkańców oraz historię obiektu i związanych z nim ludzi. Poznasz zapisane w pamiętniku losy Mikołaja Kostkina – pierwszego gospodarza Gajówki, odkryjesz ślady partyzantów z okresu II wojny światowej, obejrzysz dawne sprzęty używane w gospodarce leśnej. Zwiedzając wystawę, zobaczysz zwierzęta i rośliny gorczańskich lasów. Od maja do października Gajówka Mikołaja oferuje warsztaty przyrodnicze, m.in.: „Wyprawę Odkrywców”, „Wakacyjne przygody na ścieżkach przyrody”. Jeśli wyruszysz na bliskie spotkania z przyrodą prowadzone przez pracowników Parku, spędzisz czas blisko natury i poznasz jej tajemnice.',
        src:require('../assets/images/location/151.jpg'),
        alt:'Widok na drewniany domek z ciemnoszarym dachem. Domek jest na tle leśnej scenerii z przewagą drzew liściastych. Z prawej strony domku znajdują się trzy paśniki.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:152,
        parkID:19,
        name:'Miejsce katastrofy lotniczej pod Przechybką',
        description:'Gorczańskie lasy noszą ślady wydarzeń z czasów II wojny światowej. Wędrując ścieżką edukacyjną „Dolina potoku Jaszcze”, poznasz historię katastrofy lotniczej z 1944 r. Nieopodal przełęczy Pańska Przechybka rozbił się amerykański bombowiec typu Liberator o imieniu własnym „California Rocket”, który  wykonywał kurs bojowy z bazy we Włoszech na cele niemieckie w Oświęcimiu. Z powodu awarii silników  załoga musiała zrezygnować z wykonania zadania i wyskoczyć na spadochronach. Dziewięciu lotników uratowali polscy partyzanci. Los pierwszego pilota, który nie wyskoczył z samolotu, jest nieznany.  W 50. rocznicę katastrofy otwarto „Uroczysko Pamięci pod Przechybką”. Zobaczysz tu makietę kabiny samolotu z fragmentami oryginalnej blachy pochodzącej ze szczątków bombowca.',
        src:require('../assets/images/location/152.jpg'),
        alt:'Część białego kadłubu samolotowego leżąca na zboczu skały pomiędzy drzewami liściastymi.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 20. Magurski Park Narodowy */
    {
        idlocation:153,
        parkID:20,
        name:'Nieznajowa',
        description:'Nieznajowa – nieistniejąca już wieś, założona w 1546 r. na prawie wołoskim. Przed wojną 60 gospodarstw, słynne w okolicy jarmarki, dwa tartaki, młyn, dwa sklepy, karczma, szkoła i letnie obozy przysposobienia wojskowego dla dziewcząt. Ostało się kilkanaście ciekawych nagrobków, częściowo zniszczone kapliczki i krzyże przydrożne z końca XIX w.',
        src:require('../assets/images/location/153.jpg'),
        alt:'Posąg rodziny z dzieckiem stojących na szarym piedestale nad rodziną rozpościera  się czerwony baldachim. Całość jest otoczona jasno drewnianym niskim płotkiem. Za pomnikiem widać lekko szarawe drzewa a z prawej trony kawałek zalesionego pagórka.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:154,
        parkID:20,
        name:'Wodospad Magurski',
        description:'Wodospad Magurski – blisko 7-metrowej wysokości próg skalny. Powstał w wyniku wieloletniej działalności wody spływającej po stoku z pobliskiego źródła. Znajduje się  na obszarze  Magurskiego Parku Narodowego. Największy wodospad w Beskidzie Niskim.',
        src:require('../assets/images/location/154.jpg'),
        alt:'Widok od przodu małego górskiego wodospadu, płynącego z zielonkawo ubarwionej niskiej skały. Całość otaczają drzewa. Na ziemi widać brązowo-żółte opadłe liście. Na tylnych drzewach widzimy ich duże korzenie.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:155,
        parkID:20,
        name:'Wychodnia skalna „Diabli Kamień”',
        description:'„Diabli Kamień” wychodnia skalna. Zbudowana jest z gruboziarnistego piaskowca magurskiego i zlepieńców, które powszechnie występują w warstwach skalnych budujących podłoże Beskidu Niskiego. Wychodnię tworzy sześć bloków skalnych o wysokości do 15 m, oddzielonych od siebie szerokimi szczelinami. Procesy erozyjne przez wiele lat kształtowały je w różne formy, jak na przykład kociołki skalne – widoczne z góry nieckowate zagłębienia w skale.',
        src:require('../assets/images/location/155.jpg'),
        alt:'Widok od boku lekko zaokrąglonych skał usytuowanych na pagórku. Między drzewami w komponowały się drzewa iglaste i liściaste. W lewym dolnym rogu widzimy dwa ścięte drzewa.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:156,
        parkID:20,
        name:'Świerzowa Ruska',
        description:'Świerzowa Ruska – malownicza dolina po łemkowskiej wsi założonej prawdopodobnie w 1574 r. Przed wojną istniało tam 66 gospodarstw i mieszkało około 430 osób. Obecnie pozostały jedynie figury: Matki Boskiej z 1894 r., świętego Mikołaja (odnowiona) i Świętej Rodziny z 1938 r. Ponadto ocalały trzy z końca XIX w. z postaciami Chrystusa i około 20 krzyży cmentarnych w większości kamiennych. Na cmentarzu znajduje się makieta cerkwi w skali 1:15, która tu kiedyś istniała.',
        src:require('../assets/images/location/156.jpg'),
        alt:'Dwie figurki przedstawiające oblicze Chrystusa stojące w leśnej scenerii. Figurki od dołu porośnięte zielonym nalotem. Na niższych strukturach widać opadłe brązowe liście oraz niskie rośliny.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:157,
        parkID:20,
        name:'Ośrodek Edukacyjno-Muzealny im. Jana Szafrańskiego w Krempnej',
        description:'Ośrodek Edukacyjno-Muzealny im. Jana Szafrańskiego w Krempnej – centrum edukacyjne Magurskiego Parku Narodowego, w którym prowadzone są działania edukacyjne, także znajdują się ekspozycje umożliwiające poznanie dziedzictwa przyrodniczego i kulturowego tego miejsca. Wśród nich m.in. sala ekspozycyjna z półgodzinnym spektaklem przyrodniczym obrazującym historią kształtowania się środowiska przyrodniczego tego terenu oraz interaktywna ekspozycja na trzech poziomach korytarzy, które pobudzają do poszukiwań i zachęcają do odkrywania przyrody wszystkimi zmysłami.',
        src:require('../assets/images/location/157.jpg'),
        alt:'Widok od frontu Ośrodka Edukacyjno-Muzealnego. Przed wejściem widzimy trzy wysokie drzewa dwa z nich to przedstawiciele z gatunku iglastych a trzeci jest drzewem liściastym, poniżej ich znajdują się dwa niższe krzewy liściaste, całość skalniaka ogrodzona jest niskim drewnianym płotkiem.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:158,
        parkID:20,
        name:'Wysokie',
        description:'Wysokie (657 m n.p.m.) – szczyt w Magurskim Parku Narodowym. Z wierzchołka roztacza się panorama 360 stopni. Przy dobrej widoczności możemy obserwować Tatry. Szczyt zaliczony do Korony Beskidu Niskiego.',
        src:require('../assets/images/location/158.jpg'),
        alt:'Widok ze szczytu góry na inne wzniesienia pokryte lasem. Pomiędzy górami stojącymi na dalszym planie  roztacza się szara otoczka mgły. Na dole zdjęcia widać jaśniejącą w słońcu wysuszoną trawę obok, której przebiega udeptana ścieżka.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:159,
        parkID:20,
        name:'Ferdel (wieża widokowa)',
        description:'Ferdel (648 m n.p.m.) – szczyt w Magurskim Parku Narodowym, nad Wapiennym. Na szczycie góry znajduje się wieża widokowa.  Przebiegają szlaki turystyczne: zielony (Gorlice- Ożenna), czarny (Wapienne- Folusz) oraz ścieżka przyrodnicza „Buczynowa”.',
        src:require('../assets/images/location/159.jpg'),
        alt:'Drewniana wieża widokowa stojąca na jałowej ziemi. Dookoła widać drzewa iglaste i liściaste. Z drzew liściastych opadły liście. Pod wieżą siedzi para turystów.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:160,
        parkID:20,
        name:'Wątkowa',
        description:'Wątkowa (846 m n.p.m.) – szczyt w Beskidzie Niskim nad Bartnem, najwyższy w paśmie Magury Wątkowskiej. Najwyższe wzniesienie na terenie Magurskiego Parku Narodowego.',
        src:require('../assets/images/location/160.jpg'),
        alt:'Widok ze szczytu góry na niższe wzniesienia. Z prawej dolnej strony zdjęcia zauważamy wioskę, a z tyłu góry porośnięte lasami.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 21. Pieniński Park Narodowy */
    {
        idlocation:161,
        parkID:21,
        name:'Trzy Korony',
        description:'Trzy Korony to najwyższy szczyt w Pienińskim Parku Narodowym i jeden z najpopularniejszych w Polsce. Masyw ma charakterystyczny kształt korony zbudowanej z kilku trójkątnych, wapiennych turni. Przyroda Trzech Koron odznacza się wysoką bioróżnorodnością. Dzięki urozmaiconej rzeźbie terenu i wpływowi człowieka powstało tu wiele różnorodnych siedlisk: lasy, łąki, ściany skalne i piargi.',
        src:require('../assets/images/location/161.jpg'),
        alt:'Widok z dołu na biało umaszczone skały o stromych zboczach. Pod nimi znajduje się bujny las iglasty. Drzewa te jednak w mniejszym stopniu porastają również ich szczyty.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:162,
        parkID:21,
        name:'Sokolica',
        description:'Sokolica jest pięknym szczytem, którego niemal pionowe wapienne ściany opadają 300 metrową przepaścią do przełomu Dunajca. Na szczycie można podziwiać liczne rośliny naskalne, w tym licznie rosnącą tu chryzantemę Zawadzkiego, której najbliższe stanowiska można znaleźć dopiero na Kaukazie. Na szczycie największą uwagę przykuwa sosenka stanowiąca część wyjątkowych reliktowych lasków sosnowych i będąca jednym z najbardziej znanych drzew w Polsce. W 2018 roku uległa znacznemu uszkodzeniu podczas akcji ratunkowej na szczycie.',
        src:require('../assets/images/location/162.jpg'),
        alt:'Widok z góry na powykręcane drzewo iglaste, rosnące na krawędzi skały. Pod nim rozciąga się bujny las iglasty. Nad drzewem widać trzy białe chmurki.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:163,
        parkID:21,
        name:'Przełom Dunajca',
        description:'Na odcinku Czerwony Klasztor – Szczawnica Dunajec tworzy słynny malowniczy przełom będący największą atrakcją Pienin. Ten jeden z najpiękniejszych przełomów rzecznych na świecie można zwiedzać na kilka sposobów. Większość odwiedzających wybiera tradycyjny spływ łodziami flisackimi. Osoby szukające bardziej emocjonującego spływu wybierają kajaki lub pontony. Wzdłuż całego przełomu prowadzi Droga Pienińska na której w sezonie letnim jest tłoczno od spacerowiczów i rowerzystów. Ściany przełomu tworzą strome, zalesione stoki i pionowe ściany skalne.',
        src:require('../assets/images/location/163.jpg'),
        alt:'Widok  dołu na dwie góry lekko porośnięte drzewami o odcieniach żółci i pomarańczu. U dołu skał widać ścieżkę prowadzącą wzdłuż płytkiej rzeki.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:164,
        parkID:21,
        name:'Zamek Czorsztyn',
        description:'Ruiny zamku czorsztyńskiego usytuowane są na skale, ok. 50 m nad taflą Zbiornika Czorsztyńskiego (pierwotnie ok. 95 m nad dnem doliny Dunajca). Wzniesienie fortyfikacji na trudnodostępnym wzgórzu spowodowało naturalne podniesienie jej walorów obronnych. Najstarsze struktury miały konstrukcję drewniano-ziemnego umocnienia założonego w XIII w. Fundatorem strażniczego grodu była prawdopodobnie księżna Kinga – żona Bolesława Wstydliwego.',
        src:require('../assets/images/location/164.jpg'),
        alt:'Widok z dołu na pozostałości zamku Czorsztyn wybudowanego na skale. Białe mury porasta zielona naleciałość. U podnóża zamku rosną bujno zielone drzewa liściaste.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:165,
        parkID:21,
        name:'Zamek Pieniny',
        description:'Ruiny zamku Pieniny położone są pod szczytem Góry Zamkowej w masywie Trzech Koron. Pierwsza wiarygodna wiadomość o zamku pochodzi z początku XIV w. Jest nią informacja, że schroniła się w nim księżna Kinga wraz z zakonnicami z klasztoru klarysek w Starym Sączu podczas trzeciego najazdu Mongołów w 1287 r. Kiedy Kinga na początku lat 80. XIII w. była rezydentką klasztoru, prawdopodobnie wybudowała ten zamek w samym środku niedostępnych wówczas Pienin jako miejsce schronienia (refugium).',
        src:require('../assets/images/location/165.jpg'),
        alt:'Na pierwszym planie widać kamienne schody łączące się z pozostałościami po murze. U dołu schodów, z prawej ich strony, znajduje się kapliczka z podobizną Matki Boskiej wyżłobiona w skale.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:166,
        parkID:21,
        name:'Wąwóz Szopczański',
        description:'Malowniczy wąwóz skalny oddzielający masyw Trzech Koron i Pienin Czorsztyńskich. Po obu stronach wąwozu można podziwiać strome, wapienne ściany skalne porośnięte naskalną roślinnością. Według legend w wąwozie miejscowa ludność chroniła się podczas najazdów mongolskich i potopu szwedzkiego. Przez wąwóz prowadzi znakowany na żółto szlak wiodący ze Sromowiec Niżnych do przełęczy Szopka, a dalej do Krościenka nad Dunajcem lub na Trzy Korony.',
        src:require('../assets/images/location/166.jpg'),
        alt:'Widok ze ścieżki prowadzącej przez środek wąwozu. Po obu stronach znajdują się wysokie, strome skały. Lewą skałę porasta las iglasty. Na ich dole znajduje się pomarańczowa skalna roślinność.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:167,
        parkID:21,
        name:'Hala Majerz',
        description:'Hala Majerz jest rozległym pastwiskiem obejmującym wierzchołek wzgórza o bardzo łagodnych stokach. Na hali prowadzony jest kulturowy wypas owiec, w bacówce w sposób tradycyjny przygotowywane są oscypki. Ze wzgórza rozciąga się szeroka panorama między innymi na Pieniny Spiskie, Tatry, Gorce i Zbiornik Czorsztyński. Przy dobrej widoczności w oddali widać Babią Górę.',
        src:require('../assets/images/location/167.jpg'),
        alt:'Widok na małą chatę góralską z otwartymi drzwiami. Przed chatą znajdują się cztery metalowe pojemniki na mleko. Przed budynkiem rośnie małe liściaste drzewko. Chata jest ogrodzona małym drewnianym płotem, za którym widnieją drzewa liściaste.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:168,
        parkID:21,
        name:'Wystawa w Krościenku nad Dunajcem',
        description:'W Krościenku nad Dunajcem znajduje się wystawa przyrodnicza pt. „Jak powstał współczesny krajobraz Pienin, dlaczego i jak go chronimy” prezentująca zagadnienia dotyczące ochrony przyrody, geologii, architektury, etnografii i ekologii Pienin. Na wystawie można podziwiać dużą makietę obejmującą swym zasięgiem Pieniny Spiskie, Właściwe i Małe. Na wystawie zaprezentowane są liczne okazy zwierząt zamieszkujących Pieniński Park Narodowy.',
        src:require('../assets/images/location/168.jpg'),
        alt:'Wystawa przyrodnicza przedstawiająca cztery makiety współczesnego krajobrazu Pienin. Za makietami na ścianach widnieją opisy miejsc oraz ciekawostki na ich temat. Z prawej strony zdjęcia widać schody prowadzące w dół.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 22. Bieszczadzki Park Narodowy */
    {
        idlocation:169,
        parkID:22,
        name:'Tarnica',
        description:'Tarnica (1346 m n.p.m.) jest najwyższym szczytem polskich Bieszczadów, wznoszącym się ponad 600 m nad dolinę Wołosatki. Od sąsiedniego masywu Krzemienia, szczyt Tarnicy oddzielony jest głęboką Przełęczą Goprowską. Z Szerokim Wierchem łączy się charakterystyczną, ostro wciętą w grzbiet przełęczą o wysokości 1275 m n.p.m. W 1987 roku na szczycie Tarnicy ustawiono 7-metrowy krzyż, upamiętniający pobyt ks. Karola Wojtyły - 5 sierpnia 1953 roku. Przy dobrej pogodzie z Tarnicy rozciąga się szeroka, dookolna panorama gór. Tarnica jest najczęściej odwiedzanym przez turystów bieszczadzkim szczytem.',
        src:require('../assets/images/location/169.jpg'),
        alt:'Miejsce widokowe otoczone płotem zabezpieczającym przed upadkiem. Widzimy krzyż oraz trzema turystami. W głębi  rozpościera się zimowy krajobraz z górami i dolinami pokrytymi śniegiem.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:170,
        parkID:22,
        name:'Zachowawcza Hodowla Konia Huculskiego w Wołosatem',
        description:'Od 1993 roku Bieszczadzki Park Narodowy prowadzi hodowlę konia huculskiego. Jest to rodzima dla Karpat rasa koni, ukształtowana przez ostry górski klimat. Hucuły wykorzystywane są w turystyce, ale również spełniają ważną rolę w ochronie przyrody Parku – zgryzają trawę i siewki drzew powstrzymując zarastanie łąk w dolinach. W Zachowawczej Hodowli Konia Huculskiego w Wołosatem można konie obejrzeć i pogłaskać, a także pojeździć - oczywiście pod opieką wykwalifikowanego instruktora. Dla wprawnych jeźdźców organizowane są wycieczki terenowe.',
        src:require('../assets/images/location/170.jpg'),
        alt:'Turyści cieszą się z przejażdżki powozem konnym, za nimi pasą się konie. Całość jest na tle lasu, za którym rozpościerają się góry.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:171,
        parkID:22,
        name:'Torfowisko wysokie',
        description:'Torfowisko wysokie, to zbiorowisko roślin bagiennych i błotnych, w którym z obumarłych części roślin tworzy się torf. Największe z nich - „Tarnawa” - można zwiedzić, poruszając się po drewnianych kładkach. Część torfowiska porośnięta jest sosnowym borem bagiennym, a z drugiej - bezleśnej rozciąga się szeroka panorama na połoniny. Wędrując kładkami poznamy charakterystyczne gatunki roślin, przystosowane do występowania w podmokłych miejscach, np. bagno zwyczajne, borówkę bagienną, mchy torfowce oraz żyjące tu zwierzęta.',
        src:require('../assets/images/location/171.jpg'),
        alt:'Drewniana kładka zawieszona nisko nad ziemią poprowadzona przez sam środek lasu mieszanego. Na dole możemy zaobserwować niskie rośliny.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:172,
        parkID:22,
        name:'Kremenaros',
        description:'Kremenaros (Krzemieniec, 1221 m n.p.m.) jest szczytem, na którym zbiegają się granice trzech państw – Polski, Słowacji i Ukrainy. Prowadzą na niego szlaki: z Wielkiej Rawki - przecinką wzdłuż granicy polsko-ukraińskiej, ze słowackiej Novej Sedlicy oraz z ukraińskiej Stużycy. Szczyt jest niewielką śródleśną polaną otoczoną lasem bukowym. Trójstyk granic oznaczono trójgraniastym słupem z napisami w trzech językach i godłami państwowymi. W jego pobliżu, w 2016 roku, zakopano kapsułę czasu.',
        src:require('../assets/images/location/172.jpg'),
        alt:'Widok Trójgraniastego słupa, w górnej części słupa widnieją godła Polski i Słowacji. Na drugim planie widzimy drzewa, a nad nimi białe obłoki.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:173,
        parkID:22,
        name:'Bukowe Berdo',
        description:'Bukowe Berdo to jeden z najpiękniejszych bieszczadzkich grzbietów górskich z licznie występującymi na nim skałkami i laskami jarzębinowymi. Atutem wędrówki granią na najwyższe wzniesienie Bukowego Berda (1313 m n.p.m.) są wspaniałe panoramy na cztery strony świata. W kierunku wschodnim widoczny jest Krzemień, Halicz, Tarnica i szczyty po stronie ukraińskiej. Patrząc na południe zobaczymy Szeroki Wierch, a na drugim planie Rawki, z kolei na zachodzie górują Połonina Caryńska i Wetlińska. Najszybciej na Bukowe Berdo dotrzemy ze wsi Muczne.',
        src:require('../assets/images/location/173.jpg'),
        alt:'Widok z górskiego szlaku z widokiem na dolinę porośniętą lasem.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:174,
        parkID:22,
        name:'Ścieżka przyrodniczo-historyczna „W dolinie górnego Sanu”',
        description:'Ścieżka przyrodniczo-historyczna „W dolinie górnego Sanu” prowadzi rozległą wyludnioną doliną Sanu, od parkingu w dawnej wsi Bukowiec, aż do źródeł Sanu w rejonie Przełęczy Użockiej. Podczas wędrówki bieszczadzką „krainą dolin” możemy poznawać ciekawą przyrodę oraz historię tego regionu. Atrakcją ścieżki jest zrekonstruowana podmurówka dworu Stroińskich w Siankach wraz z jego wizualizacją, odnowione nagrobki Franciszka i Klary Stroińskich - tzw. „Grób Hrabiny”, a także liczne ślady bytowania bobrów europejskich, które zostały tutaj reintrodukowane w 1992 roku.',
        src:require('../assets/images/location/174.jpg'),
        alt:'Tabliczka informacyjna, przedstawiająca leśny teren. Za nią widnieją drzewa iglaste i liściaste. W oddali miasto na tle gór.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:175,
        parkID:22,
        name:'Muzeum Przyrodnicze w Ustrzykach Dolnych',
        description:'Muzeum Przyrodnicze znajduje się w Ustrzykach Dolnych, w Ośrodku Edukacji Ekologicznej Bieszczadzkiego PN. Sale ekspozycyjne w atrakcyjny sposób przedstawiają historię oraz przyrodę regionu. Ekspozycja historyczna zapoznaje z życiem Bojków oraz stopniowym zagospodarowaniem Bieszczadów. Ekspozycja przyrodnicza, wyposażona w dioramy ze zwierzętami i roślinami, przedstawia różne ekosystemy – od bieszczadzkich dolin po połoniny. W specjalnie przygotowanej salce dzieci mają okazję poznać dzikie królestwo herbowego rysia, przy pomocy interaktywnych i ruchowych zabaw.',
        src:require('../assets/images/location/175.jpg'),
        alt:'Wnętrze Muzeum Przyrodniczego, przedstawiające zwierzęta, które występują w parku: wilka, lisa i sowę na drzewie. Z lewej strony widać dwa drzewa liściaste a z tyłu obraz z lasem liściastym.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:176,
        parkID:22,
        name:'Schron turystyczny BdPN',
        description:'Schron turystyczny BdPN położony jest we wschodniej części Połoniny Wetlińskiej, na wysokości 1228 m n.p.m. Najszybciej dotrzemy do niego wędrując z Przełęczy Wyżnej lub z Berehów Górnych. Obiekt posiada dwie sale dla turystów, dyżurkę GOPR, pomieszczenie dla Straży Parku oraz toalety. Można w nim odpocząć po trudach wędrówki i ogrzać się w chłodny dzień. W otoczeniu schronu znajdują się tarasy widokowe z ławeczkami oraz punkt widokowy na grani. Roztaczają się stąd przepiękne widoki na Połoninę Caryńską, Tarnicę, Rawki i pasmo Działu. Przy dobrej widoczności można nawet zobaczyć Tatry.',
        src:require('../assets/images/location/176.jpg'),
        alt:'Widok z góry przedstawia schron turystyczny. Z prawej strony widać zboczę górskie. Z tyłu przebijają się góry na tle niebiesko-różowego nieba.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:8
    },
    /** 23. Tatrzański Park Narodowy */
    {
        idlocation:177,
        parkID:23,
        name:'Morskie Oko',
        description:'To największe jezioro tatrzańskie pod względem powierzchni (ponad 34 ha), głębokie na 51 metrów i mieszczące w sobie prawie 10 milionów metrów sześciennych kryształowo czystej wody. Leży u stóp Rysów, Mięguszowieckich Szczytów i Mnicha na wysokości 1395 m. npm. Wystarczy tej statystyki. Morskie Oko to najpiękniejszy klejnot, jedno z najczęściej odwiedzanych przez turystów miejsc w Tatrach a zarazem miejsce życia żyjących tam naszych rodzimych pstrągów potokowych (jedyne w Polskich Tatrach jezioro o naturalnym zarybieniu).',
        src:require('../assets/images/location/177.jpg'),
        alt:'Widok na jezioro. Z prawej strony stoi mały szary mostek. W tafli wody odbija się las rosnący na drugim brzegu jeziora. W lewym górnym rogu widać wysokie skały, których górna część jest oświetlona przez słońce.',
        x:100,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:1
    },
    {
        idlocation:178,
        parkID:23,
        name:'Wodospad Siklawa',
        description:'To najwyższy wodospad w Polsce. Woda Potoku Roztoka opuszczając Wielki Staw Polski spada tu w Dolinę Roztoki kaskadą o wysokości ponad 65 metrów. W zależności od ilości spływającej wody Siklawa może mieć 2 lub trzy ramiona. Przy wysokich stanach wody huk wodospadu słychać już z daleka. W słoneczny dzień kropelki wody znad wodospadu tworzą malownicze tęcze. Zimą woda spływa tu pod grubą pokrywą śniegu. Pamiętajcie, szlak turystyczny prowadzący z Doliny Roztoki nad Wielki Staw Polski wzdłuż Siklawy może być niebezpieczny w warunkach oblodzenia!',
        src:require('../assets/images/location/178.jpg'),
        alt:'Widok z góry na wodospad. Przejrzysto biała woda spada w dól szarych skał. Cały wodospad jest otoczony drzewami, wchodzącymi gdzieniegdzie na skały.',
        x:175,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:2
    },
    {
        idlocation:179,
        parkID:23,
        name:'Kasprowy Wierch',
        description:'Kasprowy Wierch ma wysokość 1987 m. npm. i znajduje się w grani głównej Tatr. To jeden z najczęściej odwiedzanych szczytów tatrzańskich. Poza szlakami turystycznymi można się w jego pobliże dostać wybudowaną w 1936 roku kolejką linową. Na samym wierzchołku znajduje się obserwatorium meteorologiczne, które jest najwyżej położonym budynkiem w Polsce. Kasprowy Wierch to również „święta góra” dla narciarzy zjazdowych, którzy korzystają z wyciągów krzesełkowych i zjeżdżają z niego do dolin Gąsienicowej i Goryczkowej. Ostatnio ta góra przyciąga wielu narciarzy skiturowych.',
        src:require('../assets/images/location/179.jpg'),
        alt:'Widok z dołu na Kasprowy Wierch. Poniżej góry widać kolejkę linową prowadzącą na sam szczyt do punktu widokowego. Prawą stronę góry oświetlają złote promienie słońca.',
        x:250,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:3
    },
    {
        idlocation:180,
        parkID:23,
        name:'Czarny Staw Gąsienicowy',
        description:'Czarny Staw Gąsienicowy (Czarny Staw pod Kościelcem) to piąte co do powierzchni jezioro w Tatrach, ma głębokość 51 metrów. Swoją nazwę zawdzięcza kolorowi ciemnej toni wody i głazów na dnie. Zimna i bardzo przeźroczysta woda wypełnia tu głęboką misę wyoraną przez lodowiec. Niedaleko od wypływu Czarnego Potoku z jeziora znajduje się niewielka porośnięta kosodrzewiną wysepka. Jezioro to było kiedyś całkowicie bezrybne, zostało jednak niestety zarybione sztucznie przez ludzi w 1881 roku obcym dla Tatr gatunkiem pstrąga źródlanego.',
        src:require('../assets/images/location/180.jpg'),
        alt:'Widok z góry na Czarny Staw Gąsienicowy. Dookoła jeziora rozpościerają się wysokie góry. Na szczytach widać śnieg a u nasady lekkie zalesinie. Woda jeziora ma odcień ciemno lazurowy.',
        x:325,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:4
    },
    {
        idlocation:181,
        parkID:23,
        name:'Pustelnia Brata Alberta na Kalatówkach',
        description:'Znajduje się nie daleko szlaku i drogi prowadzącej z Kuźnic na Kalatówki. Ten prosty drewniany budynek wybudowany w 1901 roku przez Braci Albertynów i cieśli góralskich według projektu Stanisława Witkiewicza był miejscem modlitwy Świętego Brata Alberta czyli Adama Chmielowskiego i schronieniem dla księży odprawiających msze w pobliskiej kaplicy. Znajduje się tu dawna cela, w której mieszkał Święty oraz izba jego pamięci. Pustelnia jest częścią zespołu klasztornego Sióstr Albertynek. To miejsce odwiedził w czerwcu 1997 Jan Paweł II.',
        src:require('../assets/images/location/181.jpg'),
        alt:'Widok od frontu na drewnianą bramę wjazdową do Pustelni Brata Alberta. Po bokach bramy rozciąga się drewniany płot. Z prawej strony widnieje tabliczka informacyjna. Za bramą znajdują się drzewa ilaste. Całość przedstawia się w scenerii zimowej.',
        x:400,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:5
    },
    {
        idlocation:182,
        parkID:23,
        name:'Dolina Białego',
        description:'Dolina Białego to niewielka, lecz bardzo malownicza dolinka reglowa w pobliżu Zakopanego. Wciśnięta pomiędzy masywy Krokwi i Sarniej Skały dolina wycięta została przez wody Białego Potoku w skałach osadowych, wśród których najpiękniejsze formy przybierają dolomitowe turnie i skałki. Po II Wojnie Światowej przez pewien czas działała tu kopalnia uranu, po której pozostały dawne sztolnie.  Porasta ją w dolnej części mieszany las regla dolnego, w górnej zaś świerczyny regla górnego, ale nawet w dolnej części trafiają się zarośla kosodrzewiny, którą możemy spotkać na najniższym poziomie w Tatrach.',
        src:require('../assets/images/location/182.jpg'),
        alt:'Widok na dwie drewniane kładki, po bokach których leżą szaro-niebieskie kamienie. Za nimi rozpościera się las z drzewami liściastymi, których liście już opadły oraz z mniejszą ilością drzew iglastych. Pomiędzy drzewami możemy zauważyć wcześniej opadłe liście.',
        x:475,
        y:400,
        maxpoints:200,
        roleplaying:false,
        order:6
    },
    {
        idlocation:183,
        parkID:23,
        name:'Lodowe Źródło w Dolinie Kościeliskiej',
        description:'Lodowe Źródło to wywierzysko, czyli obfite źródła krasowe odwadniające Dolinę Miętusią i masyw Czerwonych Wierchów, wypływające u podnóża wschodnich zboczy Doliny Kościeliskiej poniżej Bramy Kraszewskiego. Przez cały czas wypływa tam kilkaset litrów na sekundę zimnej (temperatura pomiędzy 4-5 stopni Celsjusza) wody, która następnie rozlewa się na trzy strony świata odnogami, które następnie trafiają do Potoku Kościeliskiego. W Lodowym Źródle żyje niewielki skorupiak studniczek tatrzański, mieszkaniec jaskiń, studni i najgłębszych jezior tatrzańskich, będący reliktem z czasów trzeciorzędu.',
        src:require('../assets/images/location/183.jpg'),
        alt:'Widok na teren podmokły. Z wody wynurzają się trawy i inne połacie niskiej zieleni. Na wodzie również możemy zauważyć zwalone drzewo wyrwane z korzeniami. Całość jest na tle lasu iglastego.',
        x:550,
        y:400,
        maxpoints:200,
        roleplaying:true,
        order:7
    },
    {
        idlocation:184,
        parkID:23,
        name:'Schronisko PTTK na Polanie Chochołowskiej',
        description:'Schronisko PTTK na Polanie Chochołowskiej nosi imię Jana Pawła II, który spotkał się tu w 1983 roku z Lechem Wałęsą. Zostało zbudowane w latach 1951-53 w miejscu wcześniej istniejącego schroniska spalonego przez Niemców w 1945 roku. To największe schronisko w Polskich Tatrach. Znajduje się na wysokości 1148 m. npm, jest doskonałą bazą wypadową na wycieczki zarówno letnie, jak i narciarskie w Tatry Zachodnie. Niedaleko schroniska stoi Kaplica Św. Jana Chrzciciela oraz szałasy pasterskie, a na Polanie Chochołowskiej od wiosny do jesieni prowadzony jest wypas kulturowy owiec.',
        src:require('../assets/images/location/184.jpg'),
        alt:'Widok od przodu na schronisko. Budynek jest obłożony kamieniami. U jego podstawy znajdują się kamienne schody ze ścieżką. Schronisko jest usytuowane w lesie iglastym.',
        x:625,
        y:400,
        maxpoints:200,
        roleplaying: false,
        order:8
    },
    
]
export default locations;