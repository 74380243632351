/**
 * Komponent umożliwiający odtwarzanie dźwięku
 */
import { CSSProperties, KeyboardEvent, useCallback, useEffect } from 'react';
import img_play from '../assets/icons/play.svg';
import img_pause from '../assets/icons/pause.svg';
import img_play_contrast from '../assets/icons/play_white.svg';
import img_pause_contrast from '../assets/icons/pause_white.svg';
import { useMemo, useState } from 'react';
import styles from '../styles/components/AudioPlayer.module.css';
import { useSelector } from 'react-redux';

var _interval:any;

const AudioPlayer = (props:{file:any,style:CSSProperties,getCurrentTime?:Function}) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
    */
    const contrast = useSelector((state:any) => state.contrast.value);
    const audio = useMemo(() => new Audio(props.file),[props.file]);
    const [playStatus, setPlayStatus] = useState(false);
    /**
     * Funkcja odtwarzająca dźwięk
     */
    const play = () => {
        if(audio){
            setPlayStatus(true);
            audio.play();
            if(props.getCurrentTime !== undefined) {
                _interval = setInterval(()=>{
                    if(props.getCurrentTime !== undefined) props.getCurrentTime(audio.currentTime);
                },1000);
            }
            audio.addEventListener('ended',()=>setPlayStatus(false));
        }
    }
    /**
     * Funkcja zatrzymująca odtwrzanie dźwięku
     */
    const pause = useCallback(() => {
        if(audio){
            setPlayStatus(false);
            audio.pause();
            audio.currentTime = 0;
            if(_interval !== undefined && _interval !== null) clearInterval(_interval);
        }
    },[audio]);
    /**
     * Funkcja odtwarzająca lub zatrzymująca dźwięk
     * @param {KeyboardEvent} event 
     */
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            playStatus?pause():play();
        }
    }
    /**
     * Funkcja zatrzymująca odtwarzanie dźwięku po zmianie widoku
     */
    useEffect(()=>{
        return()=>{
            pause();
            audio.removeEventListener('ended',()=>setPlayStatus(false));
        }
    },[audio,pause]);

    return (
        <button className={styles.button} onKeyDown={onKeyDown} onClick={playStatus?pause:play} style={{...props.style,outlineColor:contrast==='on'?'#ffff00':'#f08733'}}>
            <img className={styles.play_pause} src={playStatus?contrast==='on'?img_pause_contrast:img_pause:contrast==='on'?img_play_contrast:img_play} alt={playStatus?'Przycisk umożliwiający odtworzenie dźwięku':'Przycisk umożliwiający zatrzymanie odtwarzania dźwięku'}/>
        </button>
    )
 }

 export default AudioPlayer;