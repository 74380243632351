/**
 * Komponent elementu menu
 */
import { KeyboardEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from '../styles/components/MenuItem.module.css';

const MenuItem = (props:{name:string, navigate:string, fontSize:number}) => {
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
     const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych, zmienych wykorzystywanych w ekranie Parków
     */
    const navigate = useNavigate();
    /**
     *Funkcja przekierowuje do wybranego ekranu menu
     */ 
    const onClick = () => {
        navigate(props.navigate);
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            event.preventDefault(); 
            navigate(props.navigate); 
        }      
    }
    return (
        <li>
            <h3 style={{margin:0}}>
                <button className={styles.container} onClick={onClick} onKeyDown={onKeyDown}>
                    <div className={contrast==='on'?styles.circleContrast:styles.circle}></div>
                    <p className={contrast==='on'?styles.itemNameContrast:styles.itemName} style={{fontSize:props.fontSize}}>{props.name}</p>
                </button>
            </h3>
        </li>
    )
}

export default MenuItem;