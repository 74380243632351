/**
 * Ekran Menu
 */
import { useCallback, useEffect, useState } from "react";
import Container from "../components/Container";
import styles from '../styles/screens/Menu.module.css';
import MenuItem from "../components/MenuItem";
import MenuHeader from "../components/MenuHeader";
import Sizes from '../constans/sizes';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const dataList:{id:number,name:string,navigate:string}[] = [
    {
        id:1,
        name:'O PROJEKCIE',
        navigate:'aboutapplication'
    },
    /*{
        id:2,
        name:'POSTAĆ',
        navigate:'character'
    },*/
    {
        id:3,
        name:'O GRZE',
        navigate:'aboutgame'
    },
    {
        id:4,
        name:'USTAWIENIA GRY',
        navigate:'gamesettings'
    },
    {
        id:5,
        name:'RESETOWANIE GRY',
        navigate:'resetgame'
    },
    {
        id:6,
        name:'POLITYKA PRYWATNOŚCI',
        navigate:'privatepolicy'
    },
    {
        id:8,
        name:'REGULAMIN',
        navigate:'statute'
    },
    {
        id:7,
        name:'FAQ',
        navigate:'faq'
    }
];

const Menu = () => {
    const navigate = useNavigate(); 
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
    * Funkcja zwraca zapamiętana czcionke
    */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Ustawienie stanów początkowych zmienych 
     */
    const [menuList, setMenuList] = useState<any>([]);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = useCallback(() => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    },[_fontsize]);
    /**
     * Funkcja tworzy menu
     */
    useEffect(()=>{
        const _m:any[] = dataList.map((item,index)=>
            <MenuItem key={item.id} name={item.name} navigate={item.navigate} fontSize={getFontSize()+4}/>
        )
        setMenuList(_m);
    },[getFontSize]);
    /**
     * Funkcja wychodzi z ekranu
     */
    const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}>
            <div className={styles.menuContainer}>
                <MenuHeader/>
                <div className={styles.menuContent}>
                    <h2 style={{marginBottom:30,fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>MENU</h2>
                    <ul>
                    {
                        menuList
                    }
                    </ul>
                </div>
            </div>
        </Container>
    )
}

export default Menu;