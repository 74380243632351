/**
 * Obiekt zarządzający komunikatem zakończenia gry
 */
 import { createSlice } from "@reduxjs/toolkit";
 
 const endgameSlice = createSlice({
     name: 'endgame',
     initialState: { 
         values: [],
         showAlert:1
     },
     reducers: {
         setParks: (state, action) => {
            state.values = action.payload.values;
         },
         setShowAlert: (state, action) => {
            state.showAlert = action.payload.showAlert;
         }
     }
 });
 
 export const setParks = endgameSlice.actions.setParks;
 export const setShowAlert = endgameSlice.actions.setShowAlert;
 export default endgameSlice.reducer;