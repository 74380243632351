/**
 * Ekran wyświetlający politykę prywatności
 */
import Container from "../../components/Container";
import styles from "../../styles/screens/menu/PrivatePolicy.module.css";
import MenuHeader from "../../components/MenuHeader";
import { useSelector } from "react-redux";
import Sizes from '../../constans/sizes';
import { useNavigate } from "react-router-dom";

const PrivatePolicy = () => {
    const navigate = useNavigate(); 
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja wychodzi z ekranu
     */
    const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}>
            <div className={styles.policyContainer}>
                <MenuHeader/>
                <div className={styles.policyContent}>
                    <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>POLITYKA PRYWATNOŚCI</p>
                    <div className={styles.description} style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>
                        <p><strong>Polityka Prywatności komponentu Gra „Odkrywca Parków Narodowych”</strong></p>
                        <p><b>1. CEL</b></p>
                        <p>1.1. Aktualne regulacje dotyczące ochrony danych osobowych uregulowane zostały na gruncie Rozporządzenia Parlamentu Europejskiego i Rady Unii Europejskiej 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: „Ogólne Rozporządzenie” lub „RODO”), oraz ustawy o ochronie danych osobowych z dnia 10 maja 2018 roku, (Dz.U.2019.1781 t.j. z dnia 2019.09.19).</p>
                        <p>1.2. Przedmiotowa Polityka Prywatności to zbiór zasad, których celem jest poinformowanie Użytkownika o aspektach procesu odnoszącego się do pozyskania, przetwarzania oraz zabezpieczenia danych osobowych.</p>
                        <p>1.3. Zgodnie z obowiązującymi przepisami dotyczącymi ochrony danych osobowych, celem zapewnienia właściwej ochrony danych osobowych, Administrator, kierując się naczelnymi zasadami przetwarzania danych osobowych, zapewnia poszanowanie oraz respektowanie praw osób, których dane dotyczą, w tym prawa do ochrony prywatności.</p>
                        <p>1.4. Przetwarzanie danych osobowych jest prawnie dozwolone jeżeli jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy.</p>
                        <p><b>2. OBSZAR ODDZIAŁYWANIA POLITYKI PRYWATNOŚCI</b></p>
                        <p>2.1. Niniejsza Polityka prywatności określa zasady przetwarzania danych pozyskiwanych za pośrednictwem Aplikacji Odkrywca Parków Narodowych (dalej: Aplikacja) podczas wykonywania przez osoby fizyczne lub prawne czynności takich jak: przeglądanie zawartości, pobieranie zdjęć, dokumentów lub utworów audiowizualnych zamieszczonych w Aplikacji oraz podczas korzystania z Aplikacji Mobilnej. Polityka Prywatności obejmuje również przetwarzanie danych osobowych administratorów obsługujących Aplikację.</p>
                        <p>2.2. Wszelkie działania podejmowane przez Administratora podlegają przepisom prawa polskiego powszechnie obowiązującego na terenie Polski, w tym odnoszącego się do zakresu ochrony danych osobowych, w szczególności Ogólnego Rozporządzenia.</p>
                        <p>2.3. Administrator zapewnia transparentność przetwarzania danych oraz kładzie szczególny nacisk na to, aby dane były zbierane jedynie w tym zakresie, jaki jest niezbędny dla wskazanego celu i przetwarzane były tylko przez okres, w jakim jest to konieczne.</p>
                        <p><b>3. ZARZĄDZANIE BEZPIECZEŃSTWEM</b></p>
                        <p>3.1. Administrator – uwzględniając stan wiedzy technicznej, koszt wdrażania oraz charakter, zakres, kontekst i cele przetwarzania oraz ryzyko naruszenia praw lub wolności osób fizycznych o różnym prawdopodobieństwie wystąpienia i wadze zagrożenia – stosuje odpowiednie środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.</p>
                        <p><b>4. ADMINISTRATOR DANYCH OSOBOWYCH</b></p>
                        <p>4.1. Administratorem danych osobowych, tj. podmiotem, który samodzielnie ustala cele i sposoby przetwarzania danych osobowych jest Minister Klimatu i Środowiska, ul. Wawelska 52/54, 00-922 Warszawa.</p>
                        <p>4.2. Kontakt z Administratorem:</p>
                        <ul>
                            <li>a) listownie na adres: ul. Wawelska 52/54, 00-922 Warszawa</li>
                            <li>b) przez e-mail: <a href="mailto:info@klimat.gov.pl">info@klimat.gov.pl</a></li>
                            <li>c) za pośrednictwem powołanego przez niego Inspektora Ochrony Danych (pkt. 5)</li>
                        </ul>
                        <p><b>5. INSPEKTOR OCHRONY DANYCH OSOBOWYCH</b></p>
                        <p>5.1. Administrator wyznaczył Inspektora Ochrony Danych Osobowych: kontakt z inspektorem ochrony danych osobowych w Ministerstwie Klimatu i Środowiska - adres e-mail: <a href="mailto:inspektor.ochrony.danych@klimat.gov.pl">inspektor.ochrony.danych@klimat.gov.pl</a>.</p>
                        <p><b>6. ŹRÓDŁA DANYCH/INFORMACJI ORAZ CEL ICH PRZETWARZANIA</b></p>
                        <p>W ramach Aplikacji mobilnej nie są przetwarzane żadne dane osobowe Użytkownika Aplikacji. Część informacji pozyskiwana jest do celów statystycznych oraz poprawnego działania Aplikacji. Dane te nie pozwalają na identyfikację Użytkownika jako osoby fizycznej i nie są zapisywane z żadnymi innymi danymi, które to umożliwiają. W celu maksymalnej transparencji składamy wyjaśnienia jakie informacje są zbierane, do czego są wykorzystywane i dlaczego nie należy ich traktować jako danych osobowych.</p>
                        <p>6.1. Źródła danych / informacji oraz cel ich przetwarzania:</p>
                        <ul>
                            <li>a) dane statystyczne (szczegółowo opisane pkt. 7) pobierane i przetwarzane na podstawie zgody, jakiej Użytkownik urządzenia z systemem Android, IOS, HarmonyOS udzielił firmie Google Inc. w ramach akceptacji Polityki Prywatności tej firmy i akceptacji licencji na korzystanie z systemu operacyjnego lub komponentów Googla na urządzeniu iOS. Firma Google Inc, przekazuje natomiast Administratorowi Danych Osobowych tylko część z tych danych i są to dane pozbawione kontekstu Użytkownika (pozbawione unikalnego identyfikatora Użytkownika). W ten sposób firma Google Inc przeprowadza proces anonimizacji tych danych i dlatego dane te przekazywane do Administratora Danych Osobowych nie są traktowane jako dane osobowe.</li>
                            <li>b) pliki cookies – to małe pliki tekstowe przechowywane m.in. na urządzeniu mobilnym, w czasie wykazywanej przez nie aktywności w sieci Internet. Administrator nie wykorzystuje plików cookies w celu zapamiętywania preferencji Użytkownika  i personalizowania Aplikacji w zakresie dopasowywania treści i wyświetlania reklam.  Każdy Użytkownik Aplikacji może w dowolnym momencie wstrzymać przesyłanie danych do Serwera. W tym celu należy wykasować pliki cookie, jakie zostały zapisane   na urządzeniach końcowych. Wykasowanie plików cookie należy wykonać poprzez wprowadzenie zmian w aktualnych ustawieniach użytkowanej przeglądarki internetowej.</li>
                        </ul>
                        <p>6.2. Wykorzystywane narzędzia:</p>
                        <ul><li>a) Nie dotyczy ze względu na brak przetwarzania danych osobowych Użytkowników Aplikacji.</li></ul>
                        <p>6.3. Podstawa prawna przetwarzania danych osobowych:</p>
                        <ul><li>a) Nie dotyczy ze względu na brak przetwarzania danych osobowych Użytkowników Aplikacji.</li></ul>
                        <p>6.4. Okres przechowywania danych osobowych:</p>
                        <ul><li>a) Nie dotyczy ze względu na brak przetwarzania danych osobowych Użytkowników Aplikacji.</li></ul>
                        <p>6.5. Dobrowolność podania danych:</p>
                        <ul><li>a) Nie dotyczy ze względu na brak przetwarzania danych osobowych Użytkowników Aplikacji.</li></ul>
                        
                        
                        
                        <p><b>7. INFORMACJE POZYSKIWANE DO CELÓW STATYSTYCZNYCH ORAZ POPRAWNEGO ZARZĄDZANIA APLIKACJĄ</b></p>
                        <p>7.1. Informacje pozyskiwane do celów statystycznych i właściwego działania Aplikacji:</p>
                        <ul>
                            <li>a) adres IP urządzenia (tylko w ramach komunikacji klient-serwer), adres URL żądania, nazwa domeny, identyfikator urządzenia, typ przeglądarki, język przeglądarki, typ i wersja systemu operacyjnego, dane zbierane w dziennikach serwera, a także inne podobne informacje. Powyższe dane nie są powiązane z osobą Użytkownika Systemu Zarządzania Aplikacją w żaden sposób, stąd też z punktu widzenia przepisów prawa nie stanowią one danych osobowych.</li>
                        </ul>
                        <p>7.2.  Źródła danych oraz cel ich przetwarzania:</p>
                        <ul>
                            <li>a) Pliki cookies:<br/>cookies (tzw. „ciasteczka”) to małe pliki tekstowe przechowywane na komputerze Użytkownika lub innym urządzeniu mobilnym, w czasie wykazywanej przez niego aktywności w sieci Internet;<br/>
                            pliki te wykorzystywane są w celach technicznych, do celów bezpieczeństwa oraz pozwalają na parametryzację, np. zapamiętanie odwiedzin na stronie i preferencji użytkownika dotyczących tej strony (m.in. język strony, jej kolor, rozmiar czcionki, układ, rozmieszczenie treści i innych tego typu właściwości ułatwiających pracę ze stroną). Za pomocą plików cookies dostarczane są m.in. informacje statystyczne o ruchu Użytkowników, ich aktywności oraz sposobie wykorzystania Aplikacji. Informacje pozyskane w ten sposób pozwalają ustalić częstotliwość odwiedzin na stronie, a także jakie elementy najbardziej interesują Użytkowników. Wszystkie dane wykorzystywane są na potrzeby lepszego dostosowania serwisów www do potrzeb Użytkowników, poprawy funkcjonalności Aplikacji, a także w celach statystycznych oraz reklamowych. Wykorzystywane pliki nie mogą stanowić źródła wirusów lub innych złośliwych oprogramowań (malware), które mogłyby stanowić zagrożenie dla urządzeń użytkowników;<br/>
                            - nie wykorzystujemy plików cookies w celu zapamiętywania preferencji użytkownika<br/>
                            - pliki cookies pozwalają na gromadzenie takich danych, jak np. adres IP, rodzaj systemu operacyjnego, rodzaj przeglądarki;<br/>
                            - pliki cookies nie są szkodliwe dla komputerów czy smartfonów – nie wpływają na sposób ich działania, nie powodują zmian konfiguracyjnych w urządzeniach końcowych, ani w oprogramowaniu zainstalowanym na tych urządzeniach.
                            </li>
                        </ul>
                        <p>Każdy odrębny cookie tworzony jest przez cztery podstawowe części:</p>
                        <ul>
                            <li>a. nazwa strony internetowej, czyli nazwa domeny bądź też subdomeny, która ustawiła cookie;</li>
                            <li>b. unikatowa nazwa cookie, jaka została mu przypisana na generującej go stronie www;</li>
                            <li>c. terminy ważności plików cookie – niektóre z plików wygasają równocześnie z zamknięciem przeglądarki (są to tzw. cookie sesje), natomiast inne pliki usuwane są w sposób automatyczny, w chwili osiągnięcia ustalonej daty ważności (tzw. trwałe cookie);</li>
                            <li>d. wartość, czyli informacje zawarte w pliku cookie, jakie wykorzystywane są przez stronę www do prowadzenia rejestru poprzednich wizyt.</li>
                        </ul>
                        <p>Pliki cookie zawierają podstawowe informacje na temat użytkowników (takie jak identyfikatory), a także różnego typu dane umożliwiające optymalizację i prawidłowe prezentowanie treści zawartych na stronach internetowych. Informacje przechowywane w plikach cookie wykorzystywane są do celów takich jak:</p>
                        <ul>
                            <li>a) stworzenie rejestru preferencji użytkowników, czyli formatu przeglądania oraz przeglądanych zawartości, bez konieczności ustawiania indywidualnych preferencji przy każdej wizycie na danej stronie www;</li>
                            <li>b) przygotowanie rejestru stron, jakie odwiedzają użytkownicy – na tej podstawie pozyskiwane są dane, które ułatwiają wprowadzanie usprawnień w treściach widocznych na stronach, a także w sposobie nawigacji strony.</li>
                        </ul>
                        <p>Wszyscy Użytkownicy Aplikacji mogą w każdym, dowolnym momencie wstrzymać przesyłanie danych do serwerów. W tym celu należy wykasować pliki cookie, jakie zostały zapisane przez serwis na ich urządzeniach końcowych. Wykasowanie plików cookie należy wykonać poprzez wprowadzenie zmian w aktualnych ustawieniach użytkowanej przeglądarki internetowej.</p>
                        <p>Więcej informacji na temat plików cookie i zmian ustawień odnaleźć można przy poszczególnych przeglądarkach, a w tym: Chrome, Firefox, Safari, Opera, Edge, Internet Explorer.</p>
                        <p>Każdy Użytkownik ma prawo do odmowy zapisywania oraz odczytywania plików cookie na użytkowanych przez niego urządzeniach, takich jak komputer czy telefon. W tym celu należy wybrać odpowiednie ustawienia w opcjach przeglądarki internetowej, bądź też nie wyrażać zgody na zapytanie przesyłane przez aplikację związaną z danym serwisem internetowym.</p>
                        <p>W przypadku skonfigurowania przeglądarki w taki sposób, aby zablokować instalację plików cookie dla wszystkich lub wybranych przez użytkownika stron internetowych, niektóre funkcjonalności mogą zostać utracone, a tym samym użytkowanie strony internetowej w pełnym jej zakresie będzie uniemożliwione.</p>
                        <p>Zgodnie z obowiązującymi przepisami ustawy z dnia 16 lipca 2004 roku - Prawo telekomunikacyjne (Dz.U.2021.576 t.j. z dnia 2021.03.30), a także RODO, jako formę potwierdzenia zgody na wykorzystywanie plików cookie uznaje się taką konfigurację przeglądarki internetowej, która umożliwia instalację plików cookie na urządzeniach użytkownika.</p>
                        <p>Więcej informacji dotyczących plików cookie można odnaleźć na stronach:</p>
                        <ul>
                            <li>a. <a href="http://www.allaboutcookie.org/manage-cookie">http://www.allaboutcookie.org/manage-cookie;</a></li>
                            <li>b. <a href="http://wszystkoociasteczkach.pl/">http://wszystkoociasteczkach.pl/.</a></li>
                        </ul>
                        <p>7.3. Wykorzystywane narzędzia:</p>
                        <ul>
                            <li>a) Nie dotyczy.</li>
                        </ul>
                        <p>7.4. Podstawa prawna przetwarzania danych osobowych:</p>
                        <p>Nie dotyczy.</p>
                        <p>7.5.  Okres przechowywania danych osobowych:</p>
                        <p>Nie dotyczy.</p>
                        <p>7.6.  Dobrowolność podania danych:</p>
                        <p>Nie dotyczy.</p>
                        <p><b>8. ODNOŚNIKI DO STRON INTERNETOWYCH INNYCH PODMIOTÓW</b></p>
                        <p>Strony internetowe podmiotów współpracujących z Administratorem, do których odnośniki umieszczone są w Aplikacji, podlegają ich własnej polityce prywatności (w tym w zakresie wykorzystywania plików cookies). Zachęcamy do zapoznawania się z treścią zapisów dotyczących prywatności, a umieszczonych na stronach, do których prowadzą odwołania.</p>
                        <p><b>9. KATALOG ODBIORCÓW INFORMACJI</b></p>
                        <p>Każdorazowo katalog odbiorców informacji przetwarzanych przez Administratora wynika przede wszystkim z zakresu usług, z jakich korzysta Użytkownik. Katalog odbiorców informacji wynika również z zgody Użytkownika lub z przepisów prawa oraz doprecyzowywany jest w wyniku działań podejmowanych przez Użytkownika w trakcie korzystania z Aplikacji.</p>
                        <ul>
                            <li>a) informacje przetwarzane są na zlecenie Administratora przez podmioty świadczące na rzecz Administratora usługi doradcze, konsultacyjne, audytowe, pomoc prawną, marketingową, usługi hostingu lub usługi teleinformatyczne, usługi serwisowania oprogramowania lub sprzętu, z którego korzysta Administrator, a także podmioty dostarczające oprogramowanie, w tym systemy do analizy ruchu w Aplikacji;</li>
                            <li>b) w związku z korzystaniem przez Administratora z narzędzia Google Analytics informacje mogą być przekazywane Google Inc. z siedzibą w USA;</li>
                            <li>c) w zakresie, w jakim jest to niezbędne i w sytuacji, w której wynika to z treści bezwzględnie obowiązujących przepisów prawa i w sposób zgodny z tymi przepisami, informacje w szczególności adres IP, są udostępniane organom nadzorującym przestrzeganie prawa, organom regulacyjnym i innym organom administracji publicznej (np. Prokuraturze, Prezesowi Urzędu Ochrony Danych Osobowych, Prezesowi Urzędu Ochrony Konkurencji i Konsumentów).</li>
                        </ul>
                        <p><b>10. PRZEKAZYWANIE INFORMACJI POZA EUROPEJSKI OBSZAR GOSPODARCZY</b></p>
                        <p>Informacje będą przekazywane poza Europejski Obszar Gospodarczy do Google Inc. z siedzibą w USA. Przekazywanie odbywać się będzie na postawie regulacji zabezpieczających ochronę danych osobowych, zatwierdzonych przez Komisję Europejską. Jednocześnie informujemy, iż Państwa informacje mogą być przetwarzane przez Google Inc. oraz Microsoft Corporation, zgodnie z art. 45 RODO, w zakresie korzystania z oprogramowania komputerowego w Firmie.</p>
                        <p><b>11. ZAUTOMATYZOWANE PODEJMOWANIE DECYZJI</b></p>
                        <ul>
                            <li>a) Informacje, które gromadzi Administrator w związku z aktywnością Użytkownika w Aplikacji, mogą być przetwarzane w sposób zautomatyzowany (w tym w formie profilowania), jednakże nie będzie to wywoływać wobec Użytkownika jako osoby fizycznej żadnych skutków prawnych lub w podobny sposób istotnie wpływać na Użytkownika sytuację.</li>
                            <li>b) Administrator może podejmować, w oparciu o autorów zdjęć , zautomatyzowane decyzje, w tym dokonywać profilowania, o którym mowa w art. 22 ust. 1 i 4 RODO.</li>
                            <li>c) Informacje Użytkownika są przetwarzane elektronicznie i ręcznie, zgodnie z metodami i procedurami związanymi z celami przetwarzania, o których mowa powyżej.</li>
                        </ul>
                        <p><b>12. PRAWA AUTORÓW ZDJĘĆ, KTÓRYCH DANE DOTYCZĄ</b></p>
                        <ul>
                            <li>a) Aby skorzystać z poniższych praw, a także w przypadku wszelkich pytań dotyczących zakresu i realizacji praw, skontaktuj się z nami (dane kontaktowe Administratora i Inspektora Ochrony Danych Osobowych w pkt. 4 i 5 Polityki prywatności). Zastrzegamy sobie prawo realizacji poniższych uprawnień po pozytywnym zweryfikowaniu tożsamości osoby ubiegającej się o dokonanie danej czynności.</li>
                            <li>b) Informacji o działaniach podjętych zgodnie z żądaniem osoby, której dane dotyczą, Administrator udziela w ciągu miesiąca od jego otrzymania. W razie konieczności przedłużenia tego terminu Administrator informuje ww. osobę o przyczynach opóźnienia.</li>
                        </ul>
                        <p>12.1. Prawo dostępu do danych osobowych autorów zdjęć (art. 15 RODO):</p>
                        <p>Jesteś uprawniony do uzyskania od Administratora potwierdzenia, czy przetwarzane są Twoje dane osobowe, a jeżeli ma to miejsce, jesteś uprawniony do uzyskania:</p>
                        <ul>
                            <li>a) dostępu do swoich danych osobowych;</li>
                            <li>b) informacji o celach przetwarzania, kategoriach przetwarzanych danych osobowych, o odbiorcach lub kategoriach odbiorców tych danych, planowanym okresie przechowywania danych, a jeżeli nie jest to możliwe o kryteriach ustalania tego okresu, o prawach jej przysługujących na mocy RODO oraz o prawie wniesienia skargi do organu nadzorczego, o źródle tych danych, zautomatyzowanym podejmowaniu decyzji, w tym o profilowaniu oraz o zabezpieczeniach stosowanych w związku z przekazaniem tych danych poza Unię Europejską;</li>
                            <li>c) kopii danych osobowych podlegających przetwarzaniu.</li>
                        </ul>
                        <p>12.2. Prawo do żądania sprostowania Twoich danych osobowych (art. 16 RODO):</p>
                        <p>Uprawnienie obejmuje swym zakresem Twoje prawo do:</p>
                        <ul>
                            <li>a) żądania od Administratora niezwłocznego sprostowania danych osobowych, które są nieprawidłowe;</li>
                            <li>b) żądania uzupełnienia niekompletnych danych osobowych, w tym poprzez przedstawienie dodatkowego oświadczenia (żądanie musi uwzględniać cele przetwarzania).</li>
                        </ul>
                        <p>12.3. Prawo do żądania usunięcia Twoich danych osobowych (art. 17 RODO):</p>
                        <ul>
                            <li>a) masz prawo żądania od Administratora usunięcia Twoich danych osobowych, a Administrator ma obowiązek bez zbędnej zwłoki usunąć dane osobowe, jeżeli zachodzi jedna z następujących okoliczności:<br/>
                            dane osobowe nie są już niezbędne do celów, w których zostały zebrane lub w inny sposób przetwarzane;<br/>
                            została cofnięta zgoda, na której opiera się przetwarzanie, zgodnie z art. 6 ust. 1 lit. a lub art. 9 ust. 2 lit. a RODO i nie ma innej podstawy prawnej przetwarzania;<br/>
                            dane osobowe były przetwarzane niezgodnie z prawem;<br/>
                            dane osobowe muszą być usunięte w celu wywiązania się z obowiązku prawnego przewidzianego w prawie Unii lub prawie państwa członkowskiego, któremu podlega Administrator;<br/>
                            dane osobowe zostały zebrane w związku z oferowaniem usług społeczeństwa informacyjnego, o których mowa w art. 8 ust. 1. RODO (usługi oferowane bezpośrednio dziecku);
                            </li>
                            <li>b) prawo do bycia zapomnianym nie ma charakteru bezwzględnego – jest ograniczone w zakresie wskazanym w art. 17 ust. 3 RODO</li>                   
                        </ul>
                        <p>12.4. Prawo do żądania ograniczenia przetwarzania Twoich danych osobowych (art. 18 RODO):</p>
                        <ul>
                            <li>a) ograniczenie przetwarzania danych osobowych polega na konieczności ograniczenia przetwarzania danych wyłącznie do ich przechowywania. Przetwarzanie danych wykraczające poza ich przechowywanie jest możliwe tylko, jeżeli zaistnieje jedna z następujących przesłanek:<br/>
                            wyraziłeś na to zgodę;<br/>
                            w celu ustalenia, dochodzenia lub obrony roszczeń;<br/>
                            w celu ochrony praw innej osoby fizycznej lub prawnej;<br/>
                            z uwagi na ważne względy interesu publicznego Unii lub państwa członkowskiego;
                            </li>
                            <li>b) Administrator danych osobowych jest zobowiązany do ograniczenia przetwarzania danych, jeżeli zaistnieje jedna z następujących przesłanek:<br/>
                            osoba, której dane dotyczą, kwestionuje prawidłowość danych osobowych, zgodnie z art. 16 RODO; w takim przypadku ograniczenie przetwarzania następuje automatycznie, na okres pozwalający Administratorowi sprawdzić prawidłowość tych danych;<br/>
                            osoba, której dane dotyczą, sprzeciwia się usunięciu danych osobowych przetwarzanych niezgodnie z prawem (nie istnieje żadna podstawa przetwarzania danych z art. 6 albo art. 9 RODO), żądając w zamian ograniczenia ich wykorzystywania;<br/>
                            osoba, której dane dotyczą, zażąda od Administratora danych zastosowania ograniczenia przetwarzania w stosunku do danych, które zgodnie z zasadą ograniczenia przechowywania danych powinny zostać usunięte, ale które są potrzebne osobie, której dane dotyczą, do ustalenia, dochodzenia lub obrony roszczeń przysługujących tej osobie lub wobec niej;<br/>
                            został wniesiony sprzeciw wobec przetwarzania danych osobowych zgodnie z art. 21 ust. 1 RODO; w takim przypadku ograniczenie przetwarzania następuje automatycznie, na okres pozwalający administratorowi stwierdzić, czy prawnie uzasadnione podstawy po stronie administratora są nadrzędne wobec podstaw sprzeciwu osoby, której dane dotyczą, tj. na czas potrzebny do ustalenia, czy sprzeciw jest zasadny.
                            </li>
                        </ul>
                        <p>12.5. Prawo do przenoszenia danych (art. 20 RODO):</p>
                        <ul>
                            <li>a) możesz skorzystać z prawa do przenoszenia danych osobowych, jeżeli łącznie spełnione są dwa warunki:<br/>
                            przetwarzanie danych odbywa się na podstawie zgody (art. 6 ust. 1 lit. a lub art. 9 ust. 2 lit. a RODO) lub w celu wykonania umowy (art. 6 ust. 1 lit. b RODO);<br/>
                            przetwarzanie danych odbywa się w sposób zautomatyzowany;
                            </li>
                            <li>b) Administrator wydaje dane dostarczone przez Ciebie w formie pozwalającej na ich odczyt przez komputer;</li>
                            <li>c) możliwe jest również żądanie przesłania tych danych innemu podmiotowi, jednakże pod warunkiem, że istnieją w tym zakresie techniczne możliwości zarówno po stronie Administratora, jak również tego innego podmiotu. Bezpośrednie przesłanie danych przez jednego administratora do innego może mieć miejsce, gdy możliwa jest komunikacja między dwoma systemami, w zabezpieczony sposób oraz gdy system otrzymujący ma techniczną możliwość otrzymania przychodzących danych.</li>
                        </ul>
                        <p>12.6. Prawo do wniesienia sprzeciwu wobec przetwarzania Twoich danych osobowych (art. 21 RODO):</p>
                        <ul>
                            <li>a) masz prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania Twoich danych osobowych.</li>
                            <li>b) konsekwencją wniesienia sprzeciwu wobec przetwarzania danych osobowych, które odbywa się na podstawie uzasadnionego interesu Administratora jest zakaz dalszego przetwarzania danych. Jednak Administrator jest uprawniony do dokonania oceny, czy istnieją ważne prawnie uzasadnione podstawy do przetwarzania, nadrzędne wobec interesów, praw i wolności osoby, której dane dotyczą, lub podstawy do ustalenia, dochodzenia lub obrony roszczeń. Jeżeli zachodzą ww. sytuacje, Administrator może nadal przetwarzać dane objęte sprzeciwem. Jeśli nie zgadzasz się z oceną przeprowadzoną przez Administratora, możesz skorzystać z prawa do wniesienia skargi do organu nadzorczego;</li>
                            <li>c) następstwem wniesienia sprzeciwu wobec przetwarzania danych osobowych do celów marketingu bezpośredniego jest zakaz dalszego przetwarzania danych w tym celu. Zatem mimo wniesienia sprzeciwu dopuszczalne jest dalsze przetwarzanie danych w innych celach, takich jak w szczególności dochodzenie roszczeń wobec osoby, której dane dotyczą.</li>
                        </ul>
                        <p>12.7. Prawo do cofnięcia zgody (art. 7 ust. 3 RODO):</p>
                        <ul>
                            <li>a) masz prawo w dowolnym momencie odwołać zgodę;</li>
                            <li>b) skutkiem wycofania zgody jest brak możliwości przetwarzania w przyszłości danych osobowych z powołaniem się na tę podstawę. Jednak cofnięcie zgody nie wpływa na zgodność z prawem przetwarzania danych osobowych, którego dokonano na podstawie zgody przed jej wycofaniem.</li>
                        </ul>
                        <p>12.8. Prawo do wniesienia skargi do organu nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych:</p>
                        <p>Przysługuje Ci prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych tj. Prezesa Urzędu Ochrony Danych Osobowych ul. Stawki 2, 00-193 Warszawa.</p>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default PrivatePolicy;

