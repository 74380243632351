/**
 * Obiekt zarządzający stanami zmiennych w aplikacji 
 */
import { configureStore } from "@reduxjs/toolkit";
import character, { setCharacter } from "./character";
import { loadFromLocalStorage, saveToLocalStorage } from "../localStorage";
import sound, { setSound } from "./sound";
import levelgame, { setLevelGame } from "./levelgame";
import gamemode, { setGameMode } from "./gamemode";
import points, { setAllPoints, setLocationsPoints, setParksPoints, setPointsSpent } from "./points";
import animals, { setLocalStorageAnimals } from "./animals";
import soundsanimals, { setLocalStorageSoundsAnimals } from "./soundsAnimals";
import badges, { setBadges } from "./badges";
import postcards, { setPostcards } from "./postcards";
import endgame, { setParks, setShowAlert } from "./endgame"
import fontsize, { setFontSize } from "./fontsize";
import contrast, { setContrast } from "./contrast";
import info, { setInfo } from "./info";
import regulamin, { setRegulamin } from "./regulamin";
/**
 * Funkcja ładująca stany zmienyych z pamięci lokalnej
 */
const loadState:any = () => {
    loadFromLocalStorage().then(state=>{
        if(state!==undefined && 'character' in state) store.dispatch(setCharacter({value:state.character.value}));
        if(state!==undefined && 'sound' in state) store.dispatch(setSound({value:state.sound.value}));
        if(state!==undefined && 'levelgame' in state) store.dispatch(setLevelGame({value:state.levelgame.value}));
        if(state!==undefined && 'gamemode' in state) store.dispatch(setGameMode({value:state.gamemode.value}));
        if(state!==undefined && 'points' in state) store.dispatch(setLocationsPoints({locationspoints:state.points.locationspoints}));
        if(state!==undefined && 'points' in state) store.dispatch(setParksPoints({parkspoints:state.points.parkspoints}));
        if(state!==undefined && 'points' in state) store.dispatch(setAllPoints({points:state.points.points}));
        if(state!==undefined && 'points' in state) store.dispatch(setPointsSpent({pointsspent:state.points.pointsspent}));
        if(state!==undefined && 'animals' in state) store.dispatch(setLocalStorageAnimals({values:state.animals.values}));
        if(state!==undefined && 'soundsanimals' in state) store.dispatch(setLocalStorageSoundsAnimals({values:state.soundsanimals.values}));
        if(state!==undefined && 'badges' in state) store.dispatch(setBadges({values:state.badges.values}));
        if(state!==undefined && 'postcards' in state) store.dispatch(setPostcards({values:state.postcards.values}));
        if(state!==undefined && 'endgame' in state) store.dispatch(setParks({values:state.endgame.values}));
        if(state!==undefined && 'endgame' in state) store.dispatch(setShowAlert({showAlert:state.endgame.showAlert}));
        if(state!==undefined && 'fontsize' in state) store.dispatch(setFontSize({value:state.fontsize.value}));
        if(state!==undefined && 'contrast' in state) store.dispatch(setContrast({value:state.contrast.value}));
        if(state!==undefined && 'info' in state) store.dispatch(setInfo({value:state.info.value}));
        else store.dispatch(setInfo({value:1}));
        if(state!==undefined && 'regulamin' in state) store.dispatch(setRegulamin({value:state.regulamin.value}));

        return state===undefined?{}:state;
    })
};
/**
 * Konfiguracja przechowalni stanów zmiennych
 */  
export const store = configureStore({
    reducer:{
        character: character, 
        sound:sound,
        levelgame:levelgame,
        gamemode:gamemode,
        points:points,
        animals:animals,
        soundsanimals:soundsanimals,
        badges:badges,
        postcards:postcards,
        endgame:endgame,
        fontsize:fontsize,
        contrast:contrast,
        info:info,
        regulamin:regulamin
    },
    preloadedState:loadState()
});
/**
 * Funkcja zapisująca stany zmienyych do pamięci lokalnej
 */
store.subscribe(() => saveToLocalStorage(store.getState()));

 