/**
 * Ekran wyświetlający informacje o postaci
 */
import Container from "../../components/Container";
import styles from "../../styles/screens/menu/Character.module.css";
import MenuHeader from "../../components/MenuHeader";
import { useDispatch, useSelector } from "react-redux";
import { setCharacter } from "../../store/redux/character";
import Sizes from '../../constans/sizes';
import { useNavigate } from "react-router-dom";

const Character = () => {
    const navigate = useNavigate(); 
    /**
     * Ustawienie stanów początkowych, zmienych wykorzystywanych w ekranie
     */
    const dispatch = useDispatch();
    /**
     * Funkcja zwraca zapamiętaną postać
     */
    const _character = useSelector((state:any) => state.character.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
    * Funkcja ustawia postać
    */
    const onChangeCharacter = (event:any) => {
        if(event!==undefined && event!==null) dispatch(setCharacter({value:event.target.value}));
    }
    /**
     * Funkcja wychodzi z ekranu
     */
     const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}>
            <div className={styles.characterContainer}>
                <MenuHeader/>
                <div className={styles.characterContent}>
                    <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>POSTAĆ</p>
                    <div className={contrast==='on'?styles.descriptionContrast:styles.description} style={{fontSize:getFontSize()+4}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
                    <div className={styles.radioGroup}>
                        <label className={styles.radio}>
                            <input className={contrast==='on'?styles.circleContrast:styles.circle}  type="radio" id="1" value="FEMALE" name="character" checked={_character==='FEMALE'} onChange={onChangeCharacter}/>
                            <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>KOBIETA</span>
                        </label>
                        <label className={styles.radio}>
                            <input className={contrast==='on'?styles.circleContrast:styles.circle}  type="radio" id="2" value="MALE" name="character" checked={_character==='MALE'} onChange={onChangeCharacter}/>
                            <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>MĘŻCZYZNA</span>
                        </label>
                    </div>
                </div>
            </div>
            
        </Container>
    )
}

export default Character;

