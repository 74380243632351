/**
 * Obiekt przechowujący inofrmację o wybranej postaci w aplikacji
 */
import { createSlice } from "@reduxjs/toolkit";

const characterSlice = createSlice({
    name: 'character',
    initialState: { 
        value: undefined
    },
    reducers: {
        setCharacter: (state, action) => {
            state.value = action.payload.value;
        }
    }
});

export const setCharacter = characterSlice.actions.setCharacter;
export default characterSlice.reducer;
