/**
 * Ekran wyświetlający odpowiedź FAQ
 */
import Container from "../../components/Container";
import styles from "../../styles/screens/menu/FAQDescription.module.css";
import MenuHeader from "../../components/MenuHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Sizes from '../../constans/sizes';
import { useSelector } from "react-redux";

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

const FAQDescription = () => {
    const navigate = useNavigate(); 
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych zmiennych
     */
    const params:any = useLocation();
    /**
     * Funkcja zwraca rozmiar czcionki
     */
     const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja wychodzi z ekranu
     */
    const goBack = () => {
        navigate(-1);
    }
    return (
        <Container goBack={goBack}>
            <div className={styles.descriptionContainer}>
                <MenuHeader/>
                <div className={styles.descriptionContent}>
                    <p style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}}>{params.state.name}</p>
                    <div className={styles.description} style={{fontSize:getFontSize()+4,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : parser(params.state.description) }}/>
                </div>
            </div>
        </Container>
    )
}

export default FAQDescription;

