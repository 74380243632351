/**
 * Statusy parków
 * scale[50,75] - 40*{max_ilość_punktów_w_parku}/100<{aktualna_ilość_punktów_w_parku} && 60*{max_ilość_punktów_w_parku}/100>={aktualna_ilość_punktów_w_parku}
 */
const parkstatussettings = [
    {
        name:'nieodwiedzony',
        color:'#ffffff',//'#c0070d',
        scale:[0,59],
        badge: false
    },
    {
        name:'brązowy',
        color:'#facc3d',
        scale:[59,79],
        badge: true,
        badgeName: 'brown',
        badgeDescription: 'Żółta odznaka odkrywcy parków'
    },
    {
        name:'srebrny',
        color:'#2c7187',
        scale:[79,99],
        badge: true,
        badgeName: 'silver',
        badgeDescription: 'Niebieska odznaka odkrywcy parków'
    },
    {
        name:'złoty',
        color:'#2b604a',
        scale:[99,1000],
        badge: true,
        badgeName: 'gold',
        badgeDescription: 'Zielona odznaka odkrywcy parków'
    }
];

export default parkstatussettings;