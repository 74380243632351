import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from '../styles/components/Container.module.css';

const Container = (props:any) => {
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    
    const escFunction = useCallback((event: { key: string; }) => {
        if (event.key === "Escape" && props!==undefined && props.goBack!==undefined) props.goBack();
    }, [props]);
    
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);
    /**
     * Funkcja wywoływana po zmianie wielkości elementu
     */
    const resizeObserver = useRef<ResizeObserver>(new ResizeObserver((entries:ResizeObserverEntry[]) => {
        if(props.changeSize!==undefined) props.changeSize();
    }));
    const basicContainer = useCallback((container: HTMLDivElement) => {
        if (container !== null) {
            resizeObserver.current.observe(container);
        }
        else {
            if (resizeObserver.current)
                resizeObserver.current.disconnect();
        }
    }, [resizeObserver]);

    return (
        <div ref={basicContainer} className={styles.container} onKeyDown={props.onKeyDown}>
            <div id="backgroundContainer" className={styles.background} style={{...props.style,background:contrast==='on'?'#000':'linear-gradient(#ffffff,#e6e6e6)'}}>
                {props.children}
            </div>
        </div>
    )
}

export default Container;