/**
 * Obiekt przechowujący informację o poziomie trudności
 */
import { createSlice } from "@reduxjs/toolkit";

const levelGameSlice = createSlice({
    name: 'levelgame',
    initialState: { 
        value: 0
    },
    reducers: {
        setLevelGame: (state, action) => {
            state.value = action.payload.value;
        }
    }
});

export const setLevelGame = levelGameSlice.actions.setLevelGame;
export default levelGameSlice.reducer;
