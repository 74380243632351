import { useEffect, useMemo, useState, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../styles/screens/questions/Pairs.module.css';
import { AnswersConfigProps, LocationPointsProps, PairsQuestionItemProps, PairsQuestionProps } from '../../types';
import Questions from '../../data/questions';
import SoundEffect from '../../components/SoundEffect';
import LevelPoints from '../../constans/levelpoints';
import { setPoints } from '../../store/redux/points';
import Container from '../../components/Container';
import LeftTabBar from '../../components/LeftTabBar';
import Header from '../../components/Header';
import next from '../../assets/icons/next.png';
import DraggableList from '../../components/DraggableList';
import Sizes from '../../constans/sizes';
import AlertNoTitle from '../../components/AlertNoTitle';

function shuffle(array:any) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

const parser = (str:string) => {
    [' a ',' i ',' o ',' u ',' w ',' z '].forEach(item=>{
        str = str.replaceAll(item,` ${item.trim()}&nbsp;`);
        str = str.replaceAll(item.toUpperCase(),` ${item.toUpperCase().trim()}&nbsp;`);
    });
    return str;
}

const Pairs = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie poziomu trudności
     */
    const levelgame = useSelector((state:any) => state.levelgame.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie trybu gry
     */
    const gamemode = useSelector((state:any) => state.gamemode.value);
    /**
     * Funkcja zwraca zapamiętane punkty
     */
    const locationspoints:LocationPointsProps[] = useSelector((state:any) => state.points.locationspoints);
    const dispatch = useDispatch();
    const params:any = useLocation();
    const navigate = useNavigate();
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [title,setTitle] = useState('');
    const [questionLeft, setQuestionLeft] = useState<any>([]);
    const [questionRight, setQuestionRight] = useState<any>([]);
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [badAnswerMessage, setBadAnswerMessage] = useState('');
    const [goodAnswerMessage, setGoodAnswerMessage] = useState('');
    const [resultAnswer, setResultAnswer] = useState(false);
    const [showBackAlert, setShowBackAlert] = useState(false);
    const [showEndAlert, setShowEndAlert] = useState(false);
    const [leavePage, setLeavePage] = useState(false);
    const [addPoints, setAddPoints] = useState(true);
    const [answersconfig, setAnswersconfig] = useState<AnswersConfigProps[] | undefined>([]);
    const [nextquestion, setNextQuestion] = useState<{idquestion:number | undefined, type:string | undefined} | undefined>();
    const totalPoints:number = useMemo(()=>(params.state.points!==undefined?params.state.points:0),[params.state.points]);
    const nextquestions:{idquestion:number,type:string}[] = useMemo(()=>(params.state.nextquestion),[params.state.nextquestion]);
    const idpark:number = useMemo(()=>(params.state.idpark),[params.state.idpark]);
    const idlocation:number = useMemo(()=>(params.state.idlocation),[params.state.idlocation]);
    const parkName:string = useMemo(()=>(params.state.parkName),[params.state.parkName]);
    const logo:any = useMemo<any>(()=>(params.state.parkLogo),[params.state.parkLogo]);
    /**
     * Funkcja ustawiąjąca parametry dla wybranego pytania
     */
     useEffect(()=>{
        const pairsquestion:PairsQuestionProps | undefined = Questions.find((item:any)=>item.idquestion===params.state.idquestion) as PairsQuestionProps | undefined;
        if(pairsquestion!==undefined){
            setTitle(pairsquestion.title);
            if(levelgame===2){
                shuffle(pairsquestion.question.left);
                shuffle(pairsquestion.question.right);
                setQuestionLeft(pairsquestion.question.left);
                setQuestionRight(pairsquestion.question.right);
            }
            else{
                if(pairsquestion.question.left!==undefined){
                    var newquestionsleft:PairsQuestionItemProps[] = [];
                    pairsquestion.question.left.some((item:PairsQuestionItemProps)=>{
                        newquestionsleft.push(item);
                        return (levelgame+3)===newquestionsleft.length;
                    });
                    const tmpLeft:PairsQuestionItemProps[] = [...newquestionsleft];
                    shuffle(tmpLeft);
                    setQuestionLeft(tmpLeft);
                    var newquestionsright:PairsQuestionItemProps[] = [];
                    tmpLeft.forEach((item:PairsQuestionItemProps)=>{
                        const exist = pairsquestion.question.right.find((val:PairsQuestionItemProps)=>val.order===item.order);
                        if(exist!==undefined) newquestionsright.push(exist);
                    });
                    const tmpRight:PairsQuestionItemProps[] = [...newquestionsright];
                    shuffle(tmpRight);
                    setQuestionRight(tmpRight);
                }
            }
            setBadAnswerMessage(pairsquestion.badanswermessage);
            setGoodAnswerMessage(pairsquestion.goodanswermessage);
            setAnswersconfig(pairsquestion.answersconfig);
            setAddPoints(pairsquestion.addPoints===undefined?true:pairsquestion.addPoints);
        }
        
    },[params.state.idquestion,levelgame]);
    /**
     * Funkcja wywoływana po potwierdzeniu wyjścia z pytania, przekierowywuje do ekranu lokacji 
     */
    const back = () => {
        setLeavePage(true);
        setShowBackAlert(false);
        navigate(-1);
    }
    /**
     * Funkcja porównuje poprawność uporządkowania
     */
    const checkAnswer = () => {
        const _listLeft:HTMLDivElement = document.getElementById('listLeft') as HTMLDivElement;
        var orders_left = [];
        for(let i = 0; i < _listLeft.children.length; i++) {
            const _neworder = (_listLeft.children[i] as HTMLDivElement).getAttribute('data-neworder')!;
            (_listLeft.children[i] as HTMLDivElement).setAttribute('data-order',_neworder);
            orders_left.push(parseInt(_neworder));
        }
        const _listRight:HTMLDivElement = document.getElementById('listRight') as HTMLDivElement;
        var orders_right = [];
        for(let i = 0; i < _listRight.children.length; i++) {
            const _neworder = (_listRight.children[i] as HTMLDivElement).getAttribute('data-neworder')!;
            (_listRight.children[i] as HTMLDivElement).setAttribute('data-order',_neworder);
            orders_right.push(parseInt(_neworder));
        }
        return orders_left.join(',')===orders_right.join(',');
    }
    /**
     * Funkcja wywoływana po naciśnięciu guzika "Sprawdź/Dalej", sprawdza czy odpowiedź jest prawidłowa
     */
    const check = () => {
        const result:boolean = checkAnswer();
        const answerconfig:AnswersConfigProps | undefined = answersconfig?.find((item:AnswersConfigProps)=>item.correct===result);
        const _nextquestion = {idquestion:answerconfig?.idquestion,type:answerconfig?.type};
        setNextQuestion(_nextquestion);
        setResultAnswer(result);
        setMessageAlert(result?goodAnswerMessage:badAnswerMessage);
        if(sound!==undefined && sound==='on') SoundEffect(result?'good':'bad');
        setShowAlert(true);
    }
    const onKeyDown = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            check();
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu poprawnej lub błędnej odpowiedzi
     */
     const confirmResult = () =>{
        setShowAlert(false);
        if(resultAnswer) nextStep();
        else if(!resultAnswer && nextquestion?.idquestion!==undefined) nextStep(false);
    }
    /**
     * Funkcja ustawia kolejne pytania lub wywołuje funkcję zapisująca punkty i wychodzącą z wyzwania
     */
     const nextStep = (_goodanswer:boolean=true) => {
        const points = LevelPoints && LevelPoints.length>0 && levelgame!==undefined?addPoints?_goodanswer?LevelPoints[levelgame]+totalPoints:totalPoints:totalPoints:totalPoints;
        if(nextquestion!==undefined && nextquestion.idquestion!==undefined && nextquestion.type!==undefined) gotoNextQuestion(nextquestion.idquestion,nextquestion.type,nextquestions,points);
        else if(nextquestions!==undefined){
            let firstquestion:{idquestion:number,type:string} = nextquestions[0];
            let _nextquestions:{idquestion:number,type:string}[] = [];
            nextquestions.forEach((item:{idquestion:number,type:string},index:number)=>{
                if(index!==0) _nextquestions.push(item);
            });
            if(firstquestion!==undefined && firstquestion!==null) gotoNextQuestion(firstquestion.idquestion,firstquestion.type,_nextquestions,points);
            else savePoints(points);
        }
        else savePoints(points);
    }
    /**
     * Funkcja przekierowywuje do następnego pytania
     * @param {number} _idquestion ID pytania
     * @param {string} _type typ pytania
     * @param {{idquestion:number,type:string}[]} _nextquestions następne pytania
     * @param {number} _points zdobyte punkty
     */
     const gotoNextQuestion = (_idquestion:number,_type:string,_nextquestions:{idquestion:number,type:string}[],_points:number) => {
        if(sound!==undefined && sound==='on') SoundEffect('nextQuestion');
        const pathname = params.pathname.replace("pairs","") + _type;
        navigate(pathname,{
            replace:true,
            state:{
                idquestion:_idquestion,nextquestion:_nextquestions,idlocation:idlocation,points:_points,idpark:idpark,parkName:parkName,parkLogo:logo
            }
        });
    }
    /**
     * Funkcja aktualizuje zdobyte przez gracza punkty
     * @param {number} points punkty zdobyte dotej pory przez gracza 
     */
     const savePoints = (points:number) => {
        setShowEndAlert(true);
        if(points){
            const _locationpoints:LocationPointsProps[] = locationspoints!==undefined?[...locationspoints]:[];
            const index:number = _locationpoints.findIndex((item:LocationPointsProps) => item.idlocation === idlocation);
            if (index !== -1) _locationpoints[index] = { idlocation:idlocation, points:_locationpoints[index].points+points, parkID:idpark,gamemode:gamemode};
            else _locationpoints.push({idlocation,points,parkID:idpark,gamemode})
            dispatch(setPoints({locationspoints:_locationpoints}));
        }
    }
    /**
     * Funkcja wywoływana po potwierdzeniu komunikatu o ukończeniu wyzwania, przekierowywuje do ekranu parku
     */
    const goPark = () => {
        setLeavePage(true);
        setShowEndAlert(false);
        navigate(-2);
    }
    /**
     * Funkcja przechwytująca naciśnięcie przycisku wstecz w przeglądarce
     */
    window.onpopstate = () => {
        if(!leavePage && params.pathname==='/park/location/pairs'){
             navigate(1);
             setShowBackAlert(true);
        }
    };
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    return (
        <Container style={{flexDirection:'row',alignItems:'flex-start'}}>
            {showBackAlert && <AlertNoTitle show={showBackAlert} cancel={()=>{setShowBackAlert(false)}} confirm={back} 
             message='Chcesz opuścić wyzwanie?' buttonCancel='Anuluj' buttonConfirm='Opuść'/>}
            {showAlert && <AlertNoTitle show={showAlert} cancel={()=>{confirmResult()}} message={messageAlert} buttonCancel='OK'/>}
            {showEndAlert && <AlertNoTitle show={showEndAlert} confirm={goPark} message='Gratulacje! Wyzwanie zakończone!' buttonConfirm='Wróć na szlak'/>}
            <LeftTabBar idpark={idpark} logo={logo} visibilityButtons='hidden'/>
            <div className={styles.questionContainer}>  
                <Header name="POŁĄCZ W PARY" menuVisibility='hidden'/>
                <div className={styles.questionContent}>
                    <div className={styles.pairsQuestionContainer}>
                        {title && title!==''?<div className={styles.title} style={{fontSize:getFontSize()+10,color:contrast==='on'?'#fff':'#000'}} dangerouslySetInnerHTML = {{ __html : parser(title) }}/>:null}
                        <div className={styles.lists}>
                            <DraggableList elements={questionLeft} style={{width:'50%'}} id="listLeft" fontSize={getFontSize()+4}/>
                            <DraggableList elements={questionRight} style={{width:'50%'}} id="listRight" fontSize={getFontSize()+4}/>
                        </div>
                        <button className={contrast==='on'?styles.checkContrast:styles.check} onClick={check} onKeyDown={onKeyDown}>
                            <p className={styles.checkText} style={{fontSize:getFontSize()+10}}>SPRAWDŹ / DALEJ</p>
                            <img className={styles.next} src={next} alt=""/>
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Pairs;
