/**
 * Obiekt przechowujący informację o pokazywaniu alertu początkowego aplikacji
 */
import { createSlice } from "@reduxjs/toolkit";

const regulaminSlice = createSlice({
    name: 'regulamin',
    initialState: { 
        value: 0
    },
    reducers: {
        setRegulamin: (state, action) => {
            state.value = action.payload.value;
        }
    }
});

export const setRegulamin = regulaminSlice.actions.setRegulamin;
export default regulaminSlice.reducer;
