import { useEffect } from "react";
import { ParkInfoAlertProps } from "../types";
import styles from '../styles/components/ParkInfoAlert.module.css';
import Sizes from '../constans/sizes';
import { useSelector } from "react-redux";
/**
 * Komponent okna Alertu Parku
 * @param {ParkInfoAlertProps} props
 * @returns zwraca okno Alertu Parku
 */
const CustomAlert = (props:ParkInfoAlertProps) => {
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja ustawia focus na guziku zatwierdzającym lub anulujący
     */
    useEffect(()=>{
        const _cancel:any = document.getElementById('cancel');
        const _confirm:any = document.getElementById('confirm');
        _cancel.addEventListener('focusout', (event:any) => {
            if(_confirm) _confirm.focus();
        });
        const bt_confirm:HTMLButtonElement = document.getElementById('confirm') as HTMLButtonElement;
        if(bt_confirm!==undefined && bt_confirm!==null) bt_confirm.focus();
        else{
            const bt_cancel:HTMLButtonElement = document.getElementById('cancel') as HTMLButtonElement;
            if(bt_cancel!==undefined && bt_cancel!==null) bt_cancel.focus();
        }
        return () => {
            _cancel.removeEventListener('focusout', (event:any) => {
                if(_confirm) _confirm.focus();
            });
        }
    },[]);
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }

    return (
        <div>
            <div className={styles.containerModal} role='dialog' aria-labelledby="dialog_label" aria-modal="true" aria-describedby="dialog_description">
                <div id="dialog_description" className="sronly">
                        Okno umożliwia przejście do wybranego parku.
                </div>
                <div className={styles.modal} style={{background:contrast==='on'?'#000':'#fff',borderWidth:contrast==='on'?2:0,borderStyle:'solid',borderColor:contrast==='on'?'#fff':'#000'}}>
                    <div className={styles.containerLogo}>
                        <img className={contrast==='on'?styles.logoContrast:styles.logo} src={props.logo} alt='Logo parku'/>
                    </div>
                    <h2 className={styles.containerTitle} style={{background:contrast==='on'?'#000':'#fff'}}>
                        <p id='dialog_label' className={styles.textTitle} style={{fontSize:getFontSize()+6,color:contrast==='on'?'#FFF':'#000'}}>{props.name}</p>
                    </h2>
                    <div className={styles.containerActions}>
                        <div className={styles.containerButtons}>
                            {props.buttonConfirm!==undefined && <button id="confirm" autoFocus={true} style={{fontSize:getFontSize()}} className={contrast==='on'?styles.confirmButtonContrast:styles.confirmButton} onClick={()=>props.confirm?.()}>
                                {props.buttonConfirm}
                            </button>}
                            {props.buttonCancel!==undefined && <button id="cancel" style={{fontSize:getFontSize()}} className={contrast==='on'?styles.confirmButtonContrast:styles.cancelButton} onClick={()=>props.cancel?.()}>
                                {props.buttonCancel}
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.undermodal} onClick={()=>props.cancel?.()} />
        </div>
    )
}

export default CustomAlert;