import { useDispatch, useSelector } from "react-redux";
import { LevelGameAlertProps } from "../types";
import styles from '../styles/components/LevelGameAlert.module.css';
import { setLevelGame } from "../store/redux/levelgame";
import Sizes from '../constans/sizes';
import { useEffect } from "react";
/**
 * Komponent okna Alertu
 * @param {LevelGameAlertProps} props
 * @returns zwraca okno Alertu
 */
const LevelGameAlert = (props:LevelGameAlertProps) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
    const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie poziomu trudności
     */
    const levelgame = useSelector((state:any) => state.levelgame.value);
    const dispach = useDispatch();
    useEffect(()=>{
        const _cancel:any = document.getElementById('cancel');
        const _dialogwindowany:any = document.getElementById('dialogwindow');
        _cancel.addEventListener('focusout', (event:any) => {
            if(_dialogwindowany) _dialogwindowany.focus();
        });
        return () => {
            _cancel.removeEventListener('focusout', (event:any) => {
                if(_dialogwindowany) _dialogwindowany.focus();
            });
        }
    },[]);
    /**
     * Funkcja ustawia poziom trudności
     */
    const onChangeLevelGame = (event:any) => {
        if(event!==undefined && event!==null) dispach(setLevelGame({value:parseInt(event.target.value)}));
    }
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
   return (
    <div>
            <div className={styles.containerModal}>
                <div className={styles.modal} style={{outlineColor:contrast==='on'?'#ffff00':'#f08733',background:contrast==='on'?'#000':'#fff',borderWidth:contrast==='on'?2:0,borderStyle:'solid',borderColor:contrast==='on'?'#fff':'#000'}}
                role='dialog' aria-labelledby="dialog_label" aria-modal="true" aria-describedby="dialog_description" tabIndex={0} id='dialogwindow'>
                    <div id="dialog_description" className="sronly">
                        Okno umożliwiające wybór poziomu trudności
                    </div>
                    <h2 className={styles.containerTitle} style={{background:contrast==='on'?'#000':'#fff'}}>
                        <p id="dialog_label" className={styles.textTitle} style={{fontSize:getFontSize()+6,color:contrast==='on'?'#fff':'#000'}}>Wybierz poziom trudności</p>
                    </h2>
                    <div className={styles.containerMessage}>
                        <fieldset style={{textAlign:'left',padding:10}}>
                            <legend style={{fontSize:getFontSize()+2,color:contrast==='on'?'#fff':'#000'}}>Poziom</legend>
                            <div className={styles.radioGroup}>
                                <label className={styles.radio}>
                                    <input className={contrast==='on'?styles.circleContrast:styles.circle}  type="radio" id="1" value="0" name="levelgame" checked={levelgame===0} onChange={onChangeLevelGame}/>
                                    <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>podstawowy</span>
                                </label>
                                <label className={styles.radio}>
                                    <input className={contrast==='on'?styles.circleContrast:styles.circle}  type="radio" id="2" value="1" name="levelgame" checked={levelgame===1} onChange={onChangeLevelGame}/>
                                    <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>średniozaawansowany</span>
                                </label>
                                <label className={styles.radio}>
                                    <input className={contrast==='on'?styles.circleContrast:styles.circle} type="radio" id="3" value="2" name="levelgame" checked={levelgame===2} onChange={onChangeLevelGame}/>
                                    <span style={{fontSize:getFontSize(),color:contrast==='on'?'#fff':'#000'}}>zaawansowany</span>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                    <div className={styles.containerActions}>
                        <div className={styles.containerButtons}>
                            {props.buttonConfirm!==undefined && <button id="confirm" style={{fontSize:getFontSize()}} className={contrast==='on'?styles.confirmButtonContrast:styles.confirmButton} onClick={()=>props.confirm?.()}>
                                {props.buttonConfirm}
                            </button>}
                            {props.buttonCancel!==undefined && <button id="cancel" style={{fontSize:getFontSize()}} className={contrast==='on'?styles.cancelButtonContrast:styles.cancelButton} onClick={()=>props.cancel?.()}>
                                {props.buttonCancel}
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.undermodal} onClick={()=>props.cancel?.()} />
        </div>
   );
}

export default LevelGameAlert;