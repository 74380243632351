import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ParkPointsProps, ParkProps } from "../types";
import ParksData from '../data/parks';
import PointsItem from "./PointsItem";
import styles from '../styles/components/Points.module.css';

const Points = (props:any) => {
    /**
     * Funkcja zwraca zapamiętany kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Funkcja zwraca zapamiętane ilości punktów dla parków
     */
    const parkspoints:ParkPointsProps[] = useSelector((state:any) => state.points.parkspoints);
    /**
     * Ustawienie stanów początkowych zmienych
     */
    const [list, setList] = useState<any>([]);

    useEffect(()=>{
        const _list:any[] = [];
        if(parkspoints!==undefined){
            parkspoints.forEach((item:ParkPointsProps)=>{
                const park:ParkProps = ParksData.find((element:ParkProps)=>element.idpark===item.idpark) as ParkProps;
                if(park!==undefined){
                    _list.push(<PointsItem key={park.idpark} idpark={park.idpark} name={park.name} logo={park.logo}/>);
                }
            });
            setList(_list)
        }
    },[parkspoints]);

    return (
        <div id="points" className={styles.container} style={{outlineColor:contrast==='on'?'#ffff00':'#f08733'}} tabIndex={0}>
            {
                list
            }
        </div>
    )
}

export default Points;