/**
 * Obiekt przechowujący inofrmację o wybranym kontraście
 */
import { createSlice } from "@reduxjs/toolkit";

const contrastSlice = createSlice({
    name: 'contrast',
    initialState: { 
        value: 'off'
    },
    reducers: {
        setContrast: (state, action) => {
            state.value = action.payload.value;
        }
    }
});

export const setContrast = contrastSlice.actions.setContrast;
export default contrastSlice.reducer;
