/**
 * Obiekt przechowujący informacje o punktach w lokacji, punktach w parkach i punktach ogólnych
 */
import { createSlice } from "@reduxjs/toolkit";
import { LocationPointsProps, ParkPointsProps } from "../../types";

const pointsSlice = createSlice({
    name: 'points',
    initialState: { 
        locationspoints: [],
        parkspoints: [],
        points: 0,
        pointsspent: 0
    },
    reducers: {
        setPoints: (state, action) => {
            if(action.payload.locationspoints!==undefined){
                action.payload.locationspoints.forEach((item:LocationPointsProps) => {
                    const _parkspoints:ParkPointsProps[] =  state.parkspoints!==undefined?[...state.parkspoints]:[];
                    const index:number = _parkspoints.findIndex((element:ParkPointsProps) => element.idpark === item.parkID);
                    if (index !== -1) _parkspoints[index] = { idpark:item.parkID, points:_parkspoints[index].points+item.points, gamemode:item.gamemode};
                    else _parkspoints.push({ idpark:item.parkID, points:item.points, gamemode:item.gamemode});
                    action.payload.parkspoints = [..._parkspoints];
                });
            }
            state.locationspoints = action.payload.locationspoints;
            state.parkspoints = action.payload.parkspoints;
            if(state.parkspoints!==undefined){
                action.payload.points = state.parkspoints.reduce((n, {points}) => n + points, 0);
            }
            state.points = action.payload.points;
        },
        setLocationsPoints: (state, action) => {
            state.locationspoints = action.payload.locationspoints;
        },
        setParksPoints: (state, action) => {
            state.parkspoints = action.payload.parkspoints;
        },
        setAllPoints: (state, action) => {
            state.points = action.payload.points;
        },
        setPointsSpent: (state, action) => {
            state.pointsspent = action.payload.pointsspent<0?0:action.payload.pointsspent;
        }
    }
});

export const setPoints = pointsSlice.actions.setPoints;
export const setLocationsPoints = pointsSlice.actions.setLocationsPoints;
export const setParksPoints = pointsSlice.actions.setParksPoints;
export const setAllPoints = pointsSlice.actions.setAllPoints;
export const setPointsSpent = pointsSlice.actions.setPointsSpent;
export default pointsSlice.reducer;
