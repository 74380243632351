/**
 * Komponent nagłowka menu
 */
import { KeyboardEvent } from 'react';
import styles from '../styles/components/Header.module.css';
import close from '../assets/icons/close_white.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SoundEffect from './SoundEffect';
import Sizes from '../constans/sizes';

const HeaderWithCloseButton = (props:{name:string,color?:string}) => {
    /**
     * Funkcja zwraca zapamiętana czcionke
     */
     const _fontsize = useSelector((state:any) => state.fontsize.value);
    /**
     * Funkcja zwraca zapamiętane ustawienie dzwięku
     */
    const sound = useSelector((state:any) => state.sound.value);
    /**
     * Funkcja zwraca zapamiętana kontrast
     */
    const contrast = useSelector((state:any) => state.contrast.value);
    /**
     * Ustawienie stanów początkowych
     */
    const navigate = useNavigate();
    /**
     * Funkcja zwraca rozmiar czcionki
     */
    const getFontSize = () => {
        var value:number = 12;
        switch(_fontsize){
            case 'normal':
                value = Sizes.defaultNormalFontSize;
                break;
            case 'medium':
                value = Sizes.defaultMediumFontSize;
                break;
            case 'big':
                value = Sizes.defaultBigFontSize;
                break;
        }
        return value;
    }
    /**
     * Funkcja wywołyjąca strone poprzednią
     */
     const goBack = () => {
        if(sound!==undefined && sound==='on') SoundEffect('menuButton');
        navigate(-1);
    }
    const onKeyDownBack = (event:KeyboardEvent<HTMLElement>) => {
        if(event.key === 'Enter'){
            if(sound!==undefined && sound==='on') SoundEffect('menuButton');
            event.preventDefault(); 
            navigate(-1);    
        } 
    }
    return (
        <div className={styles.conatiner} style={{backgroundColor:contrast==='on'?'#1a1919':props?.color===undefined?'#3d836d':props.color}}>
           <p className={styles.name} style={{fontSize:getFontSize()+10,color:contrast==='on'||props.color==='#2c7187'?'#fff':'#000'}}>{props.name}</p>
           <div>
                <button className={contrast==='on'?styles.menuContrast:styles.menu} onClick={goBack} onKeyDown={onKeyDownBack}>
                    <h2 style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <img className={styles.menuImage} src={close} alt="ZAMKNIJ"/>
                    </h2>
                </button>
           </div>
        </div>
    );
}

export default HeaderWithCloseButton;