/**
 * Dostępne pocztówki w aplikacji
 */
 const postcards = [
    {
        id: 'p1',
        src: require('../assets/images/postcards/Słowiński_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Słowińskiego Parku Narodowego',
        parkID:1,
        price:600
    },
    {
        id: 'pp1',
        src: require('../assets/images/postcards/Słowiński_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Słowińskiego Parku Narodowego',
        parkID:1,
        price:1000
    },
    {
        id: 'ppp1',
        src: require('../assets/images/postcards/Słowiński_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Słowińskiego Parku Narodowego',
        parkID:1,
        price:1600
    },

    {
        id: 'p2',
        src: require('../assets/images/postcards/Wigierski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Wigierskiego Parku Narodowego',
        parkID:2,
        price:600
    },
    {
        id: 'pp2',
        src: require('../assets/images/postcards/Wigierski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Wigierskiego Parku Narodowego',
        parkID:2,
        price:1000
    },
    {
        id: 'ppp2',
        src: require('../assets/images/postcards/Wigierski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Wigierskiego Parku Narodowego',
        parkID:2,
        price:1600
    },

    {
        id: 'p3',
        src: require('../assets/images/postcards/Woliński_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Wolińskiego Parku Narodowego',
        parkID:3,
        price:600
    },
    {
        id: 'pp3',
        src: require('../assets/images/postcards/Woliński_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Wolińskiego Parku Narodowego',
        parkID:3,
        price:1000
    },
    {
        id: 'ppp3',
        src: require('../assets/images/postcards/Woliński_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Wolińskiego Parku Narodowego',
        parkID:3,
        price:1600
    },

    {
        id: 'p4',
        src: require('../assets/images/postcards/BoryTucholskie_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Parku Narodowego Bory Tucholskie',
        parkID:4,
        price:600
    },
    {
        id: 'pp4',
        src: require('../assets/images/postcards/BoryTucholskie_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Parku Narodowego Bory Tucholskie',
        parkID:4,
        price:1000
    },
    {
        id: 'ppp4',
        src: require('../assets/images/postcards/BoryTucholskie_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Parku Narodowego Bory Tucholskie',
        parkID:4,
        price:1600 
    },

    {
        id: 'p5',
        src: require('../assets/images/postcards/Biebrzański_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Biebrzańskiego Parku Narodowego',
        parkID:5,
        price:600
    },
    {
        id: 'pp5',
        src: require('../assets/images/postcards/Biebrzański_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Biebrzańskiego Parku Narodowego',
        parkID:5,
        price:1000
    },
    {
        id: 'ppp5',
        src: require('../assets/images/postcards/Biebrzański_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Biebrzańskiego Parku Narodowego',
        parkID:5,
        price:1600
    },

    {
        id: 'p6',
        src: require('../assets/images/postcards/Białowieski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Białowieskiego Parku Narodowego',
        parkID:6,
        price:600
    },
    {
        id: 'pp6',
        src: require('../assets/images/postcards/Białowieski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Białowieskiego Parku Narodowego',
        parkID:6,
        price:1000
    },
    {
        id: 'ppp6',
        src: require('../assets/images/postcards/Białowieski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Białowieskiego Parku Narodowego',
        parkID:6,
        price:1600
    },

    {
        id: 'p7',
        src: require('../assets/images/postcards/Drawieński_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Drawieńskiego Parku Narodowego',
        parkID:7,
        price:600
    },
    {
        id: 'pp7',
        src: require('../assets/images/postcards/Drawieński_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Drawieńskiego Parku Narodowego',
        parkID:7,
        price:1000
    },
    {
        id: 'ppp7',
        src: require('../assets/images/postcards/Drawieński_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Drawieńskiego Parku Narodowego',
        parkID:7,
        price:1600
    },

    {
        id: 'p8',
        src: require('../assets/images/postcards/Narwiański_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Narwiańskiego Parku Narodowego',
        parkID:8,
        price:600
    },
    {
        id: 'pp8',
        src: require('../assets/images/postcards/Narwiański_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Narwiańskiego Parku Narodowego',
        parkID:8,
        price:1000
    },
    {
        id: 'ppp8',
        src: require('../assets/images/postcards/Narwiański_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Narwiańskiego Parku Narodowego',
        parkID:8,
        price:1600
    },

    {
        id: 'p9',
        src: require('../assets/images/postcards/UjścieWarty_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Parku Narodowego Ujście Warty',
        parkID:9,
        price:600
    },
    {
        id: 'pp9',
        src: require('../assets/images/postcards/UjścieWarty_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Parku Narodowego Ujście Warty',
        parkID:9,
        price:1000
    },
    {
        id: 'ppp9',
        src: require('../assets/images/postcards/UjścieWarty_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Parku Narodowego Ujście Warty',
        parkID:9,
        price:1600
    },

    {
        id: 'p10',
        src: require('../assets/images/postcards/Kampinoski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Kampinowskiego Parku Narodowego',
        parkID:10,
        price:600
    },
    {
        id: 'pp10',
        src: require('../assets/images/postcards/Kampinoski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Kampinowskiego Parku Narodowego',
        parkID:10,
        price:1000
    },
    {
        id: 'ppp10',
        src: require('../assets/images/postcards/Kampinoski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Kampinowskiego Parku Narodowego',
        parkID:10,
        price:1600
    },

    {
        id: 'p11',
        src: require('../assets/images/postcards/Wielkopolski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Wielkopolskiego Parku Narodowego',
        parkID:11,
        price:600
    },
    {
        id: 'pp11',
        src: require('../assets/images/postcards/Wielkopolski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Wielkopolskiego Parku Narodowego',
        parkID:11,
        price:1000
    },
    {
        id: 'ppp11',
        src: require('../assets/images/postcards/Wielkopolski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Wielkopolskiego Parku Narodowego',
        parkID:11,
        price:1600
    },

    {
        id: 'p12',
        src: require('../assets/images/postcards/Poleski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Poleskiego Parku Narodowego',
        parkID:12,
        price:600
    },
    {
        id: 'pp12',
        src: require('../assets/images/postcards/Poleski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Poleskiego Parku Narodowego',
        parkID:12,
        price:1000
    },
    {
        id: 'ppp12',
        src: require('../assets/images/postcards/Poleski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Poleskiego Parku Narodowego',
        parkID:12,
        price:1600
    },

    {
        id: 'p13',
        src: require('../assets/images/postcards/Świętokrzyski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Świętokrzyskiego Parku Narodowego',
        parkID:13,
        price:600
    },
    {
        id: 'pp13',
        src: require('../assets/images/postcards/Świętokrzyski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Świętokrzyskiego Parku Narodowego',
        parkID:13,
        price:1000
    },
    {
        id: 'ppp13',
        src: require('../assets/images/postcards/Świętokrzyski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Świętokrzyskiego Parku Narodowego',
        parkID:13,
        price:1600
    },

    {
        id: 'p14',
        src: require('../assets/images/postcards/Roztoczański_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Roztoczańskiego Parku Narodowego',
        parkID:14,
        price:600
    },
    {
        id: 'pp14',
        src: require('../assets/images/postcards/Roztoczański_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Roztoczańskiego Parku Narodowego',
        parkID:14,
        price:1000
    },
    {
        id: 'ppp14',
        src: require('../assets/images/postcards/Roztoczański_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Roztoczańskiego Parku Narodowego',
        parkID:14,
        price:1600
    },

    {
        id: 'p15',
        src: require('../assets/images/postcards/Karkonoski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Karkonowskiego Parku Narodowego',
        parkID:15,
        price:600
    },
    {
        id: 'pp15',
        src: require('../assets/images/postcards/Karkonoski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Karkonowskiego Parku Narodowego',
        parkID:15,
        price:1000
    },
    {
        id: 'ppp15',
        src: require('../assets/images/postcards/Karkonoski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Karkonowskiego Parku Narodowego',
        parkID:15,
        price:1600
    },

    {
        id: 'p16',
        src: require('../assets/images/postcards/GórStołowych_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Parku Narodowego Gór Stołowych',
        parkID:16,
        price:600
    },
    {
        id: 'pp16',
        src: require('../assets/images/postcards/GórStołowych_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Parku Narodowego Gór Stołowych',
        parkID:16,
        price:1000
    },
    {
        id: 'ppp16',
        src: require('../assets/images/postcards/GórStołowych_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Parku Narodowego Gór Stołowych',
        parkID:16,
        price:1600
    },

    {
        id: 'p17',
        src: require('../assets/images/postcards/Ojcowski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Ojcowskiego Parku Narodowego',
        parkID:17,
        price:600
    },
    {
        id: 'pp17',
        src: require('../assets/images/postcards/Ojcowski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Ojcowskiego Parku Narodowego',
        parkID:17,
        price:1000
    },
    {
        id: 'ppp17',
        src: require('../assets/images/postcards/Ojcowski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Ojcowskiego Parku Narodowego',
        parkID:17,
        price:1600
    },

    {
        id: 'p18',
        src: require('../assets/images/postcards/Babiogórski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Babiogórskiego Parku Narodowego',
        parkID:18,
        price:600
    },
    {
        id: 'pp18',
        src: require('../assets/images/postcards/Babiogórski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Babiogórskiego Parku Narodowego',
        parkID:18,
        price:1000
    },
    {
        id: 'ppp18',
        src: require('../assets/images/postcards/Babiogórski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Babiogórskiego Parku Narodowego',
        parkID:18,
        price:1600
    },

    {
        id: 'p19',
        src: require('../assets/images/postcards/Gorczański_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Gorczańskiego Parku Narodowego',
        parkID:19,
        price:600
    },
    {
        id: 'pp19',
        src: require('../assets/images/postcards/Gorczański_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Gorczańskiego Parku Narodowego',
        parkID:19,
        price:1000
    },
    {
        id: 'ppp19',
        src: require('../assets/images/postcards/Gorczański_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Gorczańskiego Parku Narodowego',
        parkID:19,
        price:1600
    },

    {
        id: 'p20',
        src: require('../assets/images/postcards/Magurski_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Magurskiego Parku Narodowego',
        parkID:20,
        price:600
    },
    {
        id: 'pp20',
        src: require('../assets/images/postcards/Magurski_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Magurskiego Parku Narodowego',
        parkID:20,
        price:1000
    },
    {
        id: 'ppp20',
        src: require('../assets/images/postcards/Magurski_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Magurskiego Parku Narodowego',
        parkID:20,
        price:1600
    },

    {
        id: 'p21',
        src: require('../assets/images/postcards/Pieniński_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Pienińskiego Parku Narodowego',
        parkID:21,
        price:600
    },
    {
        id: 'pp21',
        src: require('../assets/images/postcards/Pieniński_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Pienińskiego Parku Narodowego',
        parkID:21,
        price:1000
    },
    {
        id: 'ppp21',
        src: require('../assets/images/postcards/Pieniński_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Pienińskiego Parku Narodowego',
        parkID:21,
        price:1600
    },

    {
        id: 'p22',
        src: require('../assets/images/postcards/Bieszczadzki_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Bieszczadzkiego Parku Narodowego',
        parkID:22,
        price:600
    },
    {
        id: 'pp22',
        src: require('../assets/images/postcards/Bieszczadzki_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Bieszczadzkiego Parku Narodowego',
        parkID:22,
        price:1000
    },
    {
        id: 'ppp22',
        src: require('../assets/images/postcards/Bieszczadzki_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Bieszczadzkiego Parku Narodowego',
        parkID:22,
        price:1600
    },

    {
        id: 'p23',
        src: require('../assets/images/postcards/Tatrzański_KARTA_yellow.jpg'),
        alt: 'Żółta pocztówka Tatrzańskiego Parku Narodowego',
        parkID:23,
        price:600
    },
    {
        id: 'pp23',
        src: require('../assets/images/postcards/Tatrzański_KARTA_blue.jpg'),
        alt: 'Niebieska pocztówka Tatrzańskiego Parku Narodowego',
        parkID:23,
        price:1000
    },
    {
        id: 'ppp23',
        src: require('../assets/images/postcards/Tatrzański_KARTA_green.jpg'),
        alt: 'Zielona pocztówka Tatrzańskiego Parku Narodowego',
        parkID:23,
        price:1600
    },
];

export default postcards;